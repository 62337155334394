import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { warn } from '@ember/debug';

import { ref } from 'ember-ref-bucket';
import type IntlService from 'ember-intl/services/intl';
import type { IBulkAction, IBulkActionSection } from '..';

const MAX_PROMOTED_ACTIONS = 2;

interface IArgs {
  /**
   * Label for the bulk actions
   */
  label?: string;
  /**
   * Visually hidden text for screen readers
   */
  accessibilityLabel?: string;

  /**
   * State of the bulk actions checkbox
   */
  selected?: boolean | string;

  /**
   * List is in a selectable state
   */
  selectMode: boolean;
  /**
   * Disables bulk actions
   */
  disabled: boolean;
  /**
   * Text to select all across pages
   */
  paginatedSelectAllText?: string;
  /**
   * Action for selecting all across pages
   */
  paginatedSelectAllAction?: { onClick: () => void; content: string | Component };
  /**
   * List of actions
   */
  bulkActions?: (IBulkAction | IBulkActionSection)[];
  /**
   * Actions that will be given more prominence
   */
  promotedBulkActions?: IBulkAction[];

  /**
   * Callback when selectable state of list is changed
   */
  onSelectModeToggle?: (val: boolean | string) => void;

  /**
   * Callback when the select all checkbox is clicked
   */
  onToggleAll?: () => void;
}

export default class BvResourceListBulkActions extends Component<IArgs> {
  @service intl!: IntlService;

  /* Getters */

  get label() {
    return this.args.label ?? '';
  }

  get promotedBulkActions() {
    return (this.args.promotedBulkActions ?? []).filter(Boolean);
  }

  get bulkActions() {
    return (this.args.bulkActions ?? []).filter(Boolean);
  }

  //onSelectModeToggle() {}

  /* Private State */

  @tracked containerWidth = 0;
  @tracked measuring = true;

  bulkActionsWidth = 0;
  addedMoreActionsWidthForMeasuring = 0;
  @ref('containerNode') containerNode?: HTMLElement;
  @ref('largeScreenButtonsNode') largeScreenButtonsNode?: HTMLElement;
  @ref('moreActionsNode') moreActionsNode?: HTMLElement;
  promotedActionsWidths: number[] = [];

  /* Computed Properties */

  get promotedBulkActionsToRender() {
    if (this.promotedBulkActions.length && this.numberOfPromotedActionsToRender > 0) {
      return this.promotedBulkActions.slice(0, this.numberOfPromotedActionsToRender);
    }
    return [];
  }

  get numberOfPromotedActionsToRender() {
    let { measuring, containerWidth } = this;

    if (isEmpty(this.promotedBulkActions)) {
      return 0;
    }

    if (containerWidth >= this.bulkActionsWidth || measuring) {
      return this.promotedBulkActions.length;
    }

    let sufficientSpace = false;
    let counter = this.promotedBulkActions.length - 1;
    let totalWidth = 0;

    while (!sufficientSpace && counter >= 0) {
      totalWidth += this.promotedActionsWidths[counter];
      const widthWithRemovedAction =
        this.bulkActionsWidth - totalWidth + this.addedMoreActionsWidthForMeasuring;
      if (containerWidth >= widthWithRemovedAction) {
        sufficientSpace = true;
      } else {
        counter--;
      }
    }

    return counter;
  }

  get hasActions() {
    return this.promotedBulkActions.length || this.bulkActions.length;
  }

  get actionSections(): IBulkActionSection[] | undefined {
    let { bulkActions } = this;
    if (instanceOfBulkActionListSectionArray(bulkActions)) {
      return bulkActions as IBulkActionSection[];
    }

    if (instanceOfBulkActionArray(bulkActions)) {
      return [
        {
          items: bulkActions as IBulkAction[]
        }
      ];
    }
    return undefined;
  }

  get rolledInPromotedActions() {
    return this.promotedBulkActions &&
    this.numberOfPromotedActionsToRender < this.promotedBulkActions.length
      ? [...this.promotedBulkActions].slice(this.numberOfPromotedActionsToRender)
      : [];
  }

  get combinedActions() {
    let actions: IBulkActionSection[] = [];

    if (this.actionSections && this.rolledInPromotedActions.length > 0) {
      actions = [{ items: this.rolledInPromotedActions }, ...this.actionSections];
    } else if (this.actionSections) {
      actions = this.actionSections;
    } else if (this.rolledInPromotedActions.length > 0) {
      actions = [{ items: this.rolledInPromotedActions }];
    }

    return actions;
  }

  get activatorLabel() {
    return !this.promotedBulkActions ||
    (this.promotedBulkActions && this.numberOfPromotedActionsToRender === 0 && !this.measuring)
      ? this.intl.t('bvList.bulkActions.actionsActivatorLabel')
      : this.intl.t('bvList.bulkActions.moreActionsActivatorLabel');
  }

  /* Lifecycle Hooks */

  @action
  handleDidUpdate(_containerNode: HTMLElement, [promotedBulkActions]: [IBulkAction[]]) {
    if (promotedBulkActions && promotedBulkActions.length > MAX_PROMOTED_ACTIONS) {
      warn(
        `To provide a better user experience. There should only be a maximum of ${MAX_PROMOTED_ACTIONS} promoted actions.`,
        {
          id: 'bevolta.bv-resource-list.bulk-actions.max-promoted-actions'
        }
      );
    }
  }

  @action
  handleDidInsert(containerNode: HTMLElement) {
    let { moreActionsNode, largeScreenButtonsNode } = this;

    if (this.promotedBulkActions.length && !this.bulkActions.length && moreActionsNode) {
      this.addedMoreActionsWidthForMeasuring = moreActionsNode.getBoundingClientRect().width;
    }

    this.bulkActionsWidth = largeScreenButtonsNode
      ? largeScreenButtonsNode.getBoundingClientRect().width -
      this.addedMoreActionsWidthForMeasuring
      : 0;

    if (containerNode) {
      this.containerWidth = containerNode.getBoundingClientRect().width;
      this.measuring = false;
    }
  }

  /* Actions */

  @action
  setSelectMode(val: boolean | string) {
    return this.args.onSelectModeToggle?.(val);
  }

  @action
  handleMeasurement(width: number) {
    if (this.measuring) {
      this.promotedActionsWidths.push(width);
    }
  }

  @action
  handleDidResize(/*element*/) {
    if (this.containerNode) {
      let containerWidth = this.containerNode.getBoundingClientRect().width;

      if (containerWidth > 0) {
        this.containerWidth = containerWidth;
      }
    }
  }

  /* Private functions */
}

function instanceOfBulkActionListSectionArray(bulkActions: (IBulkAction | IBulkActionSection)[]) {
  const validList = bulkActions.filter((action: IBulkActionSection) => action?.items);
  return bulkActions.length === validList.length;
}

function instanceOfBulkActionArray(bulkActions: (IBulkAction | IBulkActionSection)[]) {
  const validList = bulkActions.filter((action: IBulkActionSection) => !action?.items);
  return bulkActions.length === validList.length;
}
