const addOneClass = (node: HTMLElement, className: string) =>
  node && className && node.classList.add(className);

const removeOneClass = (node: HTMLElement, className: string) =>
  node && className && node.classList.remove(className);

/**
 * Add class names to a DOM node
 * @param {Node} node DOM node
 * @param {Array<String>} classes CSS class names
 */
export function addClass(node: HTMLElement, classes: string) {
  return node && classes?.split(' ').forEach((c) => addOneClass(node, c));
}

/**
 * Remove class names from a DOM node
 * @param {Node} node Dom node
 * @param {Array<String>} classes CSS class names
 */
export function removeClass(node: HTMLElement, classes: string) {
  return node && classes?.split(' ').forEach((c) => removeOneClass(node, c));
}

/**
 * Get the window size (width x height)
 */
export function windowSize() {
  const win = window;
  const doc = document;
  const docElem = doc.documentElement;
  const body = doc.getElementsByTagName('body')[0];

  const w = win.innerWidth || docElem.clientWidth || body.clientWidth;
  const h = win.innerHeight || docElem.clientHeight || body.clientHeight;

  return {
    width: w,
    height: h
  };
}

/**
 * Get the height of as HTML element
 * @param {Node} el DOM Node
 */
export function height(el: HTMLElement) {
  return parseFloat(getComputedStyle(el, null).height.replace('px', ''));
}

/**
 * Get the width of as HTML element
 * @param {Node} el DOM Node
 */
export function width(el?: HTMLElement) {
  return el?.getBoundingClientRect()?.width;
}

/**
 * Get the outer height of as HTML element
 * @param {Node} el DOM Node
 * @param {boolean} withMargin Should the margin be accounted for in the height calculation
 */
export function outerHeight(el: HTMLElement, withMargin: boolean = false) {
  let elHeight = el.offsetHeight;
  if (withMargin) {
    const style = getComputedStyle(el);
    elHeight += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
  }

  return elHeight;
}

/**
 * Get the offset top and left of a DOM node related to the body position 0
 * @param {Node} el DOM node
 */
export function offset(el: HTMLElement) {
  const rect = el.getBoundingClientRect();

  return {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft
  };
}
/**
 * Get the closes element from another element
 * @param {Node} el DOM node
 * @param {string} s
 */
export function closest(el: HTMLElement, s: string): HTMLElement | null {
  if (!Element.prototype.closest) {
    if (!document.documentElement.contains(el)) {
      return null;
    }
    do {
      if (el.matches(s)) {
        return el;
      }
      el = el.parentElement || (el.parentNode as HTMLElement);
    } while (el !== null && +el.nodeType === 1);
    return null;
  } else if (el) {
    return el.closest(s);
  }
  return null;
}

export const isTouchDevice = !!self.window && 'ontouchstart' in self.window;

/**
 * Create a file with data, filename and mimetype given in arguments
 * It will open a dialog modal for selecting download location
 * @param {any} data Content of the file
 * @param {string} filename Displayed file name
 * @param {string} mimetype
 */
export function openSaveFileDialog(data: any, filename: string, mimetype: string) {
  if (!data) {
    return;
  }

  const blob =
    data.constructor !== Blob
      ? new Blob([data], { type: mimetype || 'application/octet-stream' })
      : data;

  // @ts-ignore
  if (navigator.msSaveBlob) {
    // @ts-ignore
    navigator.msSaveBlob(blob, filename);
    return;
  }

  const lnk = document.createElement('a');
  const url = window.URL;
  let objectURL;

  if (mimetype) {
    lnk.type = mimetype;
  }

  lnk.download = filename || 'untitled';
  lnk.href = objectURL = url.createObjectURL(blob);
  lnk.dispatchEvent(new MouseEvent('click'));
  setTimeout(url.revokeObjectURL.bind(url, objectURL));
}

/**
 * Normalizes a boolean as a HTML auto-complete value
 */
export function normalizeAutoComplete(autoComplete?: boolean | string | null) {
  if (autoComplete === null || autoComplete === undefined || typeof autoComplete === 'string') {
    return autoComplete;
  }

  return autoComplete ? 'on' : 'off';
}

export function getScrollParent(node: HTMLElement | SVGElement): Element | undefined {
  const regex = /(auto|scroll)/;

  const parents = (_node: Element | null, ps: Element[]): Element[] => {
    if (!_node?.parentElement) {
      return ps;
    }
    return parents(_node.parentElement, ps.concat([_node]));
  };

  const style = (_node: Element, prop: string) =>
    getComputedStyle(_node, null).getPropertyValue(prop);
  const overflow = (_node: Element) =>
    style(_node, 'overflow') + style(_node, 'overflow-y') + style(_node, 'overflow-x');
  const scroll = (_node: Element) => regex.test(overflow(_node));

  /* eslint-disable consistent-return */
  const scrollParent = (_node: Node) => {
    if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
      return;
    }

    const ps = parents(_node.parentElement, []);
    for (const parent of ps) {
      if (scroll(parent)) {
        return parent;
      }
    }

    return document.scrollingElement || document.documentElement;
  };

  return scrollParent(node);
  /* eslint-enable consistent-return */
}

export function onceAnimationEnd(el: HTMLElement, className: string): Promise<void> {
  return new Promise((resolve) => {
    const onAnimationEndCb = () => {
      el.removeEventListener('animationend', onAnimationEndCb);
      removeClass(el, className);
      resolve();
    };
    el.addEventListener('animationend', onAnimationEndCb);
    addClass(el, className);
  });
}

export const documentOrBodyContains = (element: HTMLElement) => {
  if (typeof document.contains === 'function') {
    return document.contains(element);
  } else {
    return document.body.contains(element);
  }
};
