import BaseModel from 'volta/models/base-model';

import { attr } from '@ember-data/model';

import { getDisplayName, getFullName } from './user';

export default class UserProfile extends BaseModel {
  /**
   * Attributes
   */

  @attr('string') login!: string;
  @attr('string') firstName?: string;
  @attr('string') middleName?: string;
  @attr('string') lastName?: string;
  @attr('string') email?: string;
  @attr('boolean', { defaultValue: false }) connected!: boolean;
  @attr('boolean', { defaultValue: true }) isActive!: boolean;

  /**
   * Getters
   */

  get userId() {
    return this.id;
  }

  get fullName() {
    return getFullName(this);
  }

  get displayName() {
    return getDisplayName(this);
  }

  get isInactive() {
    return !this.isActive
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-profile': UserProfile;
  }
}
