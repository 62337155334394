import type IntlService from 'ember-intl/services/intl';

import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export interface IArgs {
  id?: string;
  options: any;
  renderInPlace: boolean;
  // Disable input
  disabled: boolean;
  // Callback when selection is changed
  onChange?: () => void;
  // Callback when select is focused
  onFocus?: () => void;
  // Callback when focus is removed
  onBlur?: () => void;
  // Trigger btn color
  color: string;
  // Trigger btn size
  size?: 'md' | 'sm' | 'xs';
  // Fill container
  fill?: boolean;
  // Trigger btn is subtle ?
  subtle?: boolean;
  // Trigger btn icon before
  iconLeft?: boolean;
  // Trigger prefix label (Like "Sort by" for example)
  prefix?: string | Component;
  // If set; get the value from the option with `get`
  selectedPath?: string;
  optionPath?: string;
  searchEnabled?: boolean;
  matchTriggerWidth?: boolean;
  searchAutofocus?: boolean;
  isRequired?: boolean;
}

export default class BvSelect extends Component<IArgs> {
  @service intl!: IntlService;

  id = this.args.id ?? `BvSelect-${guidFor(this)}`;
}
