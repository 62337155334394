import _isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';
import { IDateFilter } from 'volta/_pods/protected/bom/controller';

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-ignore
import type Range from 'ember-power-calendar/components/power-calendar-range';
interface IArgs {
  value?: IDateFilter;
  onChange?: (filter: IDateFilter) => void;
}

export default class BvDateRangeFieldFlat extends Component<IArgs> {
  @tracked selectedStartDate?: Date | string = this.args.value?.startDate;
  @tracked selectedEndDate?: Date | string = this.args.value?.endDate;
  @tracked calCenter = this.selectedStartDate ?? this.selectedEndDate ?? new Date();
  @tracked focusedOn?: 'start' | 'end';

  get selection() {
    return { startDate: this.selectedStartDate, endDate: this.selectedEndDate };
  }

  get selectedRange() {
    return { start: this.selectedStartDate, end: this.selectedEndDate };
  }

  @action
  handleCenterChange(center: { date: Date }) {
    this.calCenter = center.date;
  }
  @action
  handleStartDateChange(date?: Date) {
    this.selectedStartDate = date;
    if (date) {
      this.calCenter = date;
    }

    this.args.onChange?.(this.selection);
  }

  @action
  onCalendarSelect(days: Range) {
    const { start, end } = days.date;
    // When the end date has the focus and no start date is defined
    // It seems better to give the value to the end date
    // It might not be the case in all situation
    const newEndDate = end ?? start;
    if (
      this.focusedOn === 'end' &&
      (!this.selectedStartDate || moment(newEndDate).isAfter(this.selectedStartDate))
    ) {
      this.selectedEndDate = newEndDate;
    } else {
      this.selectedStartDate = start;
      this.selectedEndDate = end;

      if (start && !end) {
        this.focusedOn = 'end';
      }
    }
    this.args.onChange?.(this.selection);
  }

  @action
  handleEndDateChange(date?: Date) {
    this.selectedEndDate = date;
    if (date) {
      this.calCenter = date;
    }
    this.args.onChange?.(this.selection);
  }

  /**
   * This handler reset the start and end date values when the dateFilter is updated externally
   */
  @action
  handleValueChange(_el: HTMLElement, [value]: [IDateFilter]) {
    const { selection } = this;
    if (!_isEqual(selection, value)) {
      this.selectedStartDate = value.startDate;
      this.selectedEndDate = value.endDate;
    }
  }
}
