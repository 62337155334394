import { validateNumber } from 'ember-changeset-validations/validators';
import { TCustomColumn } from 'volta/utils/column-utils';

export const CAMEL_CASE_REGEX = /^[a-z]+([A-Z][a-z]+)*$/;
export type ICustomColumnErrors = Array<{ index: number; message: string }>;
const checkCustomColumns = () => {
  return (_: string, columns: TCustomColumn[]) => {
    const checks: ICustomColumnErrors = [];

    if (columns?.length) {
      columns.forEach((c: TCustomColumn, index: number) => {
        if (!c.label || !c.path || !c.type) {
          checks.push({ index, message: 'empty' });
        }
        if (!CAMEL_CASE_REGEX.test(c.path)) {
          checks.push({ index, message: 'invalid' });
        }
      });
    }

    return !checks.length || checks;
  };
};

export default {
  execution: {
    customColumns: [checkCustomColumns()],
    projectionDltCount: [validateNumber({ positive: true, allowBlank: true })]
  }
};
