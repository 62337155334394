import type { ICollectionSortOption } from 'volta/models/types/collection-view';

import Component from '@glimmer/component';

export interface ICollectionViewSortListArgs {
  options: ICollectionSortOption[];
  sortLimit?: number;
  onDirectionChange?: (sort: ICollectionSortOption) => void;
  onSelectionChange?: (sort: ICollectionSortOption) => void;
  onReorderOptions?: (sortOptions: ICollectionSortOption[]) => void;
}

export default class CollectionViewSortList extends Component<ICollectionViewSortListArgs> {
  resourceName = { singular: 'sort', plural: 'sorts' };

  handleSortEndAction = ([sortOptions]: Array<ICollectionSortOption[]>) => {
    this.args.onReorderOptions?.([...sortOptions]);
  };
}
