import { dropTask, restartableTask } from 'ember-concurrency';
import moment from 'moment-timezone';
import Task from 'volta/models/task';
import { defaultErrorHandler } from 'volta/utils/error-utils';
import { dateCriticality } from 'volta/utils/style-utils';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import type { IResourceName } from 'volta/models/base-model';
import type { IAggTasksViewMap, TTaskType, UTask } from 'volta/models/types/tasks';
import type TaskService from 'volta/services/task-service';
import type StoreService from 'volta/services/store';

interface IArgs {
  taskType: TTaskType;
  entityId: string;
  resourceName: IResourceName;
  aggStats?: IAggTasksViewMap;
  disabled?: boolean;
  disabledEdition?: boolean;
  minimized?: boolean;
  inPage?: boolean;
  groupingField?: 'dueDate';
  isAutoResolvable?: boolean;
  scrollToDate?: string | Date;
  onTaskUpdated?: (task: Task[]) => void;
  onClose?: () => void;
}

export default class Tasks extends Component<IArgs> {
  @service store!: StoreService;
  @service taskService!: TaskService;

  @tracked tasks: Task[] = [];
  @tracked isLoading: boolean = false;

  get allowedDuedTaskTypes(): string[] {
    return [this.args.taskType];
  }

  get tasksCount(): number {
    const { aggStats } = this.args;

    if (!aggStats && !this.tasks.filter((task) => !task.resolvedAt).length) {
      return 0;
    }

    return Object.entries(aggStats ?? {}).reduce((acc, [taskType, aggStats]) => {
      if (!aggStats) {
        return acc;
      }
      if (this.allowedDuedTaskTypes.includes(taskType)) {
        acc += aggStats.total;
      }
      return acc;
    }, 0);
  }

  get aggStatsMinDueDate() {
    const { aggStats } = this.args;
    if (!aggStats) {
      return undefined;
    }

    return Object.entries(aggStats).reduce((acc: string | undefined, [taskType, aggStats]) => {
      if (!aggStats) {
        return acc;
      }
      if (this.allowedDuedTaskTypes.includes(taskType) && aggStats.minDueDate) {
        if (!acc) {
          acc = aggStats.minDueDate;
        } else if (moment(aggStats.minDueDate).isBefore(acc)) {
          acc = aggStats.minDueDate;
        }
      }
      return acc;
    }, undefined);
  }

  get criticality() {
    const { aggStats } = this.args;

    if (!aggStats) {
      return undefined;
    }
    return dateCriticality(this.aggStatsMinDueDate);
  }

  fetchTasks = restartableTask(async () => {
    try {
      this.isLoading = true;

      switch (this.args.taskType) {
        case 'PLANNING_TASK':
          this.tasks = await this.taskService.fetchPlanningTasks.perform(this.args.entityId);
          return;
        case 'EXECUTION_TASK':
          this.tasks = await this.taskService.fetchExecutionTasks.perform(this.args.entityId);
          return;
        case 'SKU_TASK':
          this.tasks = await this.taskService.fetchSkuTasks.perform(this.args.entityId);
          return;
        default:
          return [];
      }
    } catch (e) {
      return defaultErrorHandler(e);
    } finally {
      this.isLoading = false;
    }
  });

  @action
  onDraftTask(task?: Task) {
    if (task) {
      this.tasks = [...this.tasks, task];
      this.args.onTaskUpdated?.(this.tasks);
    }
  }

  @action
  onSaveTask(task?: Task) {
    this.tasks = this.tasks
      .filter((t) => t?.id && t.id !== task?.id)
      .concat(task?.id ? [task] : []);
    this.args.onTaskUpdated?.(this.tasks);
  }

  @action
  onDeleteTask(task?: Task) {
    const withoutDeleted = this.tasks.filter((t) => t?.id && t.id !== task?.id);
    if (withoutDeleted.length !== this.tasks.length) {
      this.tasks = withoutDeleted;
      this.args.onTaskUpdated?.(this.tasks);
    }
  }

  onCloseTasks = dropTask(async (task?: UTask) => {
    if (!task) {
      return;
    }
    if (task.newWithoutComment) {
      this.onDeleteTask(task);
    } else {
      await this.taskService.saveTask.perform(task);
    }
  });
}
