import BaseModel, { IResourceName } from 'volta/models/base-model';
import {
  _normalize,
  collectionCommand,
  resourceCommand as command
} from 'volta/utils/api';

import { attr, belongsTo } from '@ember-data/model';

import { IUpdateManyWorkshopsCmd, IWorkshopCapacitySettings, TWorkshopType } from './types/workshop';
import Warehouse, { WarehouseResourceName } from './warehouse';
import { HookClass } from "volta/utils/api/types";


export const WorkshopResourceName: IResourceName = {
  singular: 'workshop',
  plural: 'workshops',
  table: 'workshops'
};

const DEFAULT_CAPACITY_SETTINGS: IWorkshopCapacitySettings = {
  capacityUnitFrequency: 'WEEKLY',
  capacityBufferRatio: 1.0
};

export const WORKSHOP_TYPES: Array<{ key: TWorkshopType; label: string }> = [
  { key: 'DELIVERY', label: 'workshopTypes.DELIVERY' },
  { key: 'WORKSHOP', label: 'workshopTypes.WORKSHOP' }
];

export enum WorkshopEvents {
  WorkshopCreated = 'WorkshopCreated',
  WorkshopUpdated = 'WorkshopUpdated',
  WorkshopsUpdated = 'WorkshopsUpdated'
}

enum TWorkshopCommands {
  CreateWorkshop = 'CreateWorkshop',
  UpdateWorkshop = 'UpdateWorkshop',
  DeleteWorkshop = 'DeleteWorkshop',
  UpdateManyWorkshops = 'UpdateManyWorkshops',
  UpdateWorkshopCapacityExceptions = 'UpdateWorkshopCapacityExceptions'
}

export enum TWorkshopQueries {
  CapacityExceptions = 'capacityExceptions'
}

/**
 * Workshop ember data model
 * @class Workshop
 * @extends BaseModel
 */
export default class Workshop extends BaseModel {
  static createWorkshop = collectionCommand(TWorkshopCommands.CreateWorkshop, {
    after: _normalize
  });

  static updateManyWorkshops = collectionCommand<IUpdateManyWorkshopsCmd, Workshop[]>(TWorkshopCommands.UpdateManyWorkshops, {
    after: _normalize as HookClass<any, Workshop[], typeof Workshop>
  });

  deleteWorkshop = command(TWorkshopCommands.DeleteWorkshop);
  updateWorkshop = command(TWorkshopCommands.UpdateWorkshop);
  updateWorkshopCapacityException = command(TWorkshopCommands.UpdateWorkshopCapacityExceptions);

  @attr('string') declare code: string;
  @attr('string') description?: string;

  /**
   * The workshop is optionally linked to a warehouse
   */
  @attr('string') warehouseId?: string;
  @attr('string') workshopType?: TWorkshopType;
  @attr('string') externalId?: string;
  @attr('boolean', { defaultValue: false }) isControlPoint!: boolean;
  @attr('string', { defaultValue: () => 'P3D' }) timeBuffer!: string;

  @attr({
    defaultValue: () => {
    }
  }) customFields?: Record<string, any>;

  /**
   * Workshop capacity settings
   */
  @attr({ defaultValue: () => DEFAULT_CAPACITY_SETTINGS })
  capacitySettings!: IWorkshopCapacitySettings;

  /**
   * Optional warehouse
   */
  @belongsTo(WarehouseResourceName.singular, { async: false })
  warehouse?: Warehouse;

  @attr('array') closedWeekDays?: number[];

  /**
   * Alias for id
   */
  get workshopId() {
    return this.id;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    workshop: Workshop;
  }
}
