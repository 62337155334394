import {
  validateInclusion,
  validateNumber,
  validatePresence
} from 'ember-changeset-validations/validators';
import { ADU_TYPES } from 'volta/models/constants/adu';

export default {
  code: validatePresence(true),
  description: [],
  varCategory: [],
  leadTimeCategory: [],
  acqCode: [],
  aduType: validateInclusion({ list: Object.keys(ADU_TYPES) }),
  aduHorizon: validateNumber({ positive: true, allowBlank: true }),
  aduHorizonOffset: validateNumber({ positive: true, allowBlank: true }),
  aduOverride: validateNumber({ positive: true, allowBlank: true }),
  discontinuedDemand: validatePresence(true),
  tags: [],
  isDefault: validatePresence(true)
};
