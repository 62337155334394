import { set } from '@ember/object';
import Days from 'ember-power-calendar/components/power-calendar/days';

import { action } from '@ember/object';
export default class extends Days {
  _updateFocused(id) {
    if (!this.isDestroyed) {
      set(this, 'focusedId', id);
    }
  }

  @action
  handleClick(e) {
    let dayEl = e.target.closest('[data-date]');
    if (dayEl) {
      let dateStr = dayEl.dataset.date;
      let day = this.days.find((d) => d.id === dateStr);
      if (day) {
        if (this.calendar.actions.select && !day.isDisabled) {
          this.calendar.actions.select(day, this.calendar, e);
        }
      }
    }
  }
}
