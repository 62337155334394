import { guid } from 'volta/helpers/guid';
import { argOrDefault } from 'volta/helpers/or-else';

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

type AccordionPaddingSize = 'none' | 'sm' | 'md' | 'lg';

interface IArgs {
  id: string;
  /**
   * Class that will apply to the trigger for the accordion.
   */
  buttonClassName?: string;
  /**
   * Class that will apply to the trigger content for the accordion.
   */
  buttonContentClassName?: string;
  /**
   * The content of the clickable trigger
   */
  buttonContent?: Component;
  /**
   * Will appear right aligned against the button. Useful for separate actions like deletions.
   */
  extraAction?: Component;
  /**
   * The accordion will start in the open state.
   */
  initialIsOpen: boolean;
  /**
   * Optional callback method called on open and close with a single `isOpen` parameter
   */
  onToggle?: (isOpen: boolean) => void;
  /**
   * The padding around the exposed accordion content.
   */
  paddingSize?: AccordionPaddingSize;
  /**
   * Placement of the arrow indicator, or 'none' to hide it.
   */
  arrowDisplay?: 'left' | 'right' | 'none';
  /**
   * Control the opening of accordion via prop
   */
  forceState?: 'closed' | 'open';
  /**
   * Change `extraAction` and children into a loading spinner
   */
  isLoading?: boolean;
  /**
   * Choose whether the loading message replaces the content. Customize the message by passing a node
   */
  isLoadingMessage?: boolean | Component;
}

export default class BvAccordionAccordion extends Component<IArgs> {
  // Defaults
  @argOrDefault(false) isLoading!: boolean;
  @argOrDefault(false) isLoadingMessage!: boolean;
  @argOrDefault(false) initialIsOpen!: boolean;
  @argOrDefault('none') paddingSize!: AccordionPaddingSize;
  @argOrDefault('left') arrowDisplay!: IArgs['arrowDisplay'];

  @tracked _opened;

  buttonId: string = guid();

  constructor(owner: unknown, args: IArgs) {
    super(owner, args);

    this._opened = this.args.forceState ? this.args.forceState === 'open' : this.args.initialIsOpen;
  }

  get isOpen(): boolean {
    return this.args.forceState ? this.args.forceState === 'open' : this._opened;
  }

  get hasIconButton(): boolean | undefined {
    return this.args.extraAction && this.arrowDisplay === 'right';
  }

  get hasArrowDisplay(): boolean {
    return this.arrowDisplay !== 'none';
  }

  get buttonReverse(): boolean {
    return !this.args.extraAction && this.arrowDisplay === 'right';
  }

  get hasLoadingMessage(): boolean {
    return this.isLoadingMessage && !this.isLoadingMessage;
  }

  @action
  onToggle(): void {
    const _onToggle = this.args.onToggle;
    if (this.args.forceState && _onToggle) {
      _onToggle(this.args.forceState !== 'open');
    } else {
      this._opened = !this._opened;
      if (_onToggle) {
        _onToggle(this._opened);
      }
    }
  }
}
