import { validatePresence, validateNumber } from 'ember-changeset-validations/validators';

export default {
  acqCode: [validatePresence(true)],
  leadTime: validateNumber({ gt: 0, allowBlank: false, integer: true }),
  internalLeadTime: [validateNumber({ positive: true, allowBlank: true, integer: true })],
  minOrderQty: validateNumber({ positive: true, allowBlank: true }),
  maxPOQty: validateNumber({ positive: true, allowBlank: true }),
  orderMultiple: validateNumber({ positive: true, allowBlank: true }),
  inventoryUnitPrice: validateNumber({ positive: true, allowBlank: true }),
  salesUnitPrice: validateNumber({ positive: true, allowBlank: true }),
  tags: [],
  warehouse: { warehouseId: validatePresence(true) },
  part: {
    partId: [],
    unitMeasure: validatePresence(true),
    code: validatePresence(true)
  },
  partType: []
};
