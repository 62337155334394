
import { task } from 'ember-concurrency';
import { defaultErrorHandler } from 'volta/utils/error-utils';

import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type StoreService from './store';
export interface TPermissionsByEntity {
  [entityType: string]: string[];
}

export default class PermissionsCacheService extends Service {
  @service store!: StoreService;

  @tracked permissions: string[] = [];
  @tracked permissionsByEntity: TPermissionsByEntity = {};

  constructor() {
    super(...arguments);
    this.task.perform();
  }

  promise() {
    return this.task.perform();
  }

  task = task(async () => {
    try {
      if (this.permissions.length) {
        return this.permissions;
      }

      const permissions = (await this.store.normalGet('userRoles/permissions')) as string[];
      this.permissions = permissions;
      return permissions;
    } catch (reason) {
      return defaultErrorHandler(reason);
    }
  });

  fetchPermissionsByEntity = task(async () => {
    try {
      const permissions = await this.store.normalGet('userRoles/permissionsByEntity');
      this.permissionsByEntity = permissions;
      return permissions;
    } catch (error) {
      defaultErrorHandler(error);
    }
  });
}

declare module '@ember/service' {
  interface Registry {
    'permissions-cache': PermissionsCacheService;
  }
}
