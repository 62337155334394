import { restartableTask } from 'ember-concurrency';
import moment from 'moment-timezone';
import { CALENDAR_CONFIG_BY_RESOURCENAME } from 'volta/components/forms/calendar-editor';
import { IResourceName } from 'volta/models/base-model';
import SupplierCalendarDay, { SupplierCalendarDayResourceName } from 'volta/models/supplier-calendar-day';
import WarehouseCalendarDay, {
  WarehouseCalendarDayEvents,
  WarehouseCalendarDayResourceName
} from 'volta/models/warehouse-calendar-day';
import WorkshopCalendarDay, {
  WorkshopCalendarDayEvents,
  WorkshopCalendarDayResourceName
} from 'volta/models/workshop-calendar-day';
import { ICmdResult } from 'volta/utils/api/jsonapi-types';
import { normalize } from 'volta/utils/api/serialize-and-push';
import { distinct, indexBy } from 'volta/utils/array-utils';
import { isWeekendDay } from 'volta/utils/date-utils';
import { defaultErrorHandler } from 'volta/utils/error-utils';
import { btFilter, eqFilter } from 'volta/utils/filters-utils';

import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { SupplierCalendarDayEvents } from '../models/supplier-calendar-day';
import EventStreamService from './event-stream';

import type StoreService from './store';
import type BvFlashService from './bv-flash';
export type TCalendarDay = WarehouseCalendarDay & SupplierCalendarDay & WorkshopCalendarDay;

export default class CalendarService extends Service {
  @service store!: StoreService;
  @service bvFlash!: BvFlashService;
  @service eventStream!: EventStreamService;

  @tracked cache: Record<string, Record<string, TCalendarDay[]>> = {};

  constructor() {
    super(...arguments);

    this.eventStream.addHandler(
      WarehouseCalendarDayResourceName.plural,
      WarehouseCalendarDayEvents.CalendarUpdated,
      this.onWarehouseCalendarUpdated,
      this
    );

    this.eventStream.addHandler(
      WarehouseCalendarDayResourceName.plural,
      WarehouseCalendarDayEvents.DaysReset,
      this.onWarehouseDaysReset,
      this
    );

    this.eventStream.addHandler(
      WarehouseCalendarDayResourceName.plural,
      WarehouseCalendarDayEvents.WarehouseCalendarDayReset,
      this.onWarehouseCalendarDayReset,
      this
    );

    this.eventStream.addHandler(
      SupplierCalendarDayResourceName.plural,
      SupplierCalendarDayEvents.CalendarUpdated,
      this.onSupplierCalendarUpdated,
      this
    );

    this.eventStream.addHandler(
      SupplierCalendarDayResourceName.plural,
      SupplierCalendarDayEvents.DaysReset,
      this.onSupplierDaysReset,
      this
    );

    this.eventStream.addHandler(
      WorkshopCalendarDayResourceName.plural,
      WorkshopCalendarDayEvents.DaysReset,
      this.onWorkshopDaysReset,
      this
    );

    this.eventStream.addHandler(
      SupplierCalendarDayResourceName.plural,
      SupplierCalendarDayEvents.SupplierCalendarDayReset,
      this.onSupplierCalendarDayReset,
      this
    );
    this.eventStream.addHandler(
      WorkshopCalendarDayResourceName.plural,
      WorkshopCalendarDayEvents.CalendarUpdated,
      this.onWorkshopCalendarUpdated,
      this
    );
    this.eventStream.addHandler(
      WorkshopCalendarDayResourceName.plural,
      WorkshopCalendarDayEvents.WorkshopCalendarDayReset,
      this.onWorkshopCalendarDayReset,
      this
    );
  }

  willDestroy() {
    this.eventStream.removeHandlers(this);
  }

  buildDefaultState(date: Date | moment.Moment | string, weekendDays?: number[]) {
    return {
      openForDemand: !isWeekendDay(date, weekendDays),
      openForReception: !isWeekendDay(date, weekendDays),
      isDefault: true
    };
  }

  fetchCalendarDays = restartableTask(
    async (
      resourceName: IResourceName,
      entityId?: string,
      year: number = new Date().getFullYear()
    ) => {
      const filter: Record<string, string> = {};
      const { singular } = resourceName;
      const entityKey = CALENDAR_CONFIG_BY_RESOURCENAME[singular].entityId;

      if (entityId) {
        filter[entityKey] = eqFilter(entityId);
      }

      filter.date = btFilter([
        moment().year(year).startOf('year').format('YYYY-MM-DD'),
        moment().year(year).endOf('year').format('YYYY-MM-DD')
      ]);

      try {
        let days: TCalendarDay[];

        if (entityId && this.cache[entityId] && this.cache[entityId][year]) {
          return this.cache[entityId][year];
        }

        days = (
          await this.store.query(resourceName.singular, {
            filter,
            page: { offset: 0, limit: 366 }
          })
        ).toArray() as TCalendarDay[];

        // we only cache the result if query by entityId
        // with on specified dates
        if (entityId) {
          const cache = this.updateCache(entityId, year, days);
          return cache[entityId][year];
        }
        return days;
      } catch (e) {
        defaultErrorHandler(e);
        return;
      }
    }
  );

  private onWarehouseCalendarUpdated(payload: any) {
    this.onCalendarUpdated(WarehouseCalendarDayResourceName, payload);
  }

  private onWarehouseDaysReset(payload: { warehouseId: string; dates: string[] }) {
    this.onDaysRemove(payload.warehouseId, payload.dates);
  }

  private onWarehouseCalendarDayReset(
    payload: ICmdResult<{ attributes: { date: string; warehouseId: string } }>
  ) {
    this.onDayReset(WarehouseCalendarDayResourceName, payload);
  }

  private onWorkshopCalendarUpdated(
    payload: ICmdResult<{ attributes: { date: string; workshopId: string } }>
  ) {
    this.onCalendarUpdated(WorkshopCalendarDayResourceName, payload);
  }

  private onWorkshopDaysReset(payload: { workshopId: string; dates: string[] }) {
    this.onDaysRemove(payload.workshopId, payload.dates);
  }

  private onWorkshopCalendarDayReset(
    payload: ICmdResult<{ attributes: { date: string; workshopId: string } }>
  ) {
    this.onDayReset(WorkshopCalendarDayResourceName, payload);
  }

  private onSupplierCalendarUpdated(payload: any) {
    this.onCalendarUpdated(SupplierCalendarDayResourceName, payload);
  }

  private onSupplierDaysReset(payload: { supplierId: string; dates: string[] }) {
    this.onDaysRemove(payload.supplierId, payload.dates);
  }

  private onSupplierCalendarDayReset(
    payload: ICmdResult<{ attributes: { date: string; supplierId: string } }>
  ) {
    this.onDayReset(SupplierCalendarDayResourceName, payload);
  }

  private onDaysUpdate(entityId: string, days: TCalendarDay[]) {
    const years = distinct(days.map((day) => day.date.getFullYear()));

    years.forEach((year) => {
      const yearDays = days.filter((day) => day.date.getFullYear() === year);
      const cached: TCalendarDay[] = this.cache[entityId][year];
      if (!cached) {
        return yearDays;
      }
      const existing = indexBy(cached ?? [], 'formattedDate');
      yearDays.forEach((d) => {
        existing[d.formattedDate] = d;
      });
      const cache = this.updateCache(entityId, year, Object.values(existing));
      return cache[entityId][year];
    });
  }

  private onDaysRemove(entityId: string, dates: string[]) {
    const years = distinct(dates.map((day) => moment(day).year()));

    years.forEach((year) => {
      const yDates = dates.filter((day) => moment(day).year() === year);
      const cached = this.cache[entityId][year];
      if (!cached) {
        return [];
      }
      const days = cached.filter((d) => !yDates.includes(d.formattedDate));
      const cache = this.updateCache(entityId, year, days);
      return cache[entityId][year];
    });
  }

  private onCalendarUpdated(resourceName: IResourceName, payload: any) {
    const days = normalize(resourceName.singular, payload) as TCalendarDay[];
    if (days && days.length) {
      const { singular } = resourceName;
      const entityKey = CALENDAR_CONFIG_BY_RESOURCENAME[singular].entityId;
      this.onDaysUpdate(days[0][entityKey], days);
    }
  }

  private onDayReset(resourceName: IResourceName, payload: any) {
    const { attributes } = payload.data;
    const { singular } = resourceName;
    const entityKey = CALENDAR_CONFIG_BY_RESOURCENAME[singular].entityId;
    if (attributes.date && attributes[entityKey]) {
      this.onDaysRemove(attributes[entityKey], [attributes.date]);
    }
  }

  private updateCache(entityId: string, year: number, days: TCalendarDay[]) {
    const { cache } = this;
    if (!cache[entityId]) {
      cache[entityId] = { [year]: days };
    }
    cache[entityId][year] = days;
    this.cache = cache;
    return cache;
  }
}

declare module '@ember/service' {
  interface Registry {
    'calendar-service': CalendarService;
  }
}
