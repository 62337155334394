import { IBvMenuItem } from 'volta/components/bv-menu';

import { action } from '@ember/object';
import Component from '@glimmer/component';

interface IArgs {
  // Collection of action items
  section?: any;
  // Whether the parent action list has multiple sections
  hasMultipleSections: boolean;
  onAnyItemAction?: (event: Event) => void;
}

export default class BvMenuSection extends Component<IArgs> {
  @action
  onItemAction(item: IBvMenuItem, event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    item.onClick?.(event);
    this.args.onAnyItemAction?.(event);
  }
}
