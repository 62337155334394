import moment from 'moment-timezone';
import { validDateOrUndefined } from 'volta/utils/date-utils';

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export interface IBvDateFieldArgs {
  value?: Date | moment.Moment;
  minDate?: moment.MomentInput;
  maxDate?: moment.MomentInput;
  closeOnSelect?: boolean;
  withoutCalendar?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (value: Date | null) => void;
  onEnter?: (value: Date | null) => void;
}

export default class BvDateField extends Component<IBvDateFieldArgs> {
  @tracked calCenter = this.args.value ?? new Date();
  @tracked inputError: boolean = false;

  get validatedDate() {
    return this.validateInputDate(this.args.value);
  }

  get disabled() {
    return this.args.disabled || this.args.readOnly
  }

  @action
  onChange(day: any, _datepicker: any, e: InputEvent, afterSelect?: (e: InputEvent) => void) {
    e.stopPropagation();
    const { closeOnSelect = true } = this.args;
    const value = moment(day.id, 'YYYY-MM-DD').toDate();
    this.inputError = false;
    this.args.onChange?.(value);

    if (closeOnSelect && typeof afterSelect === 'function') {
      afterSelect(e);
    }
  }

  @action
  onInputChange(value: string) {
    const mDate = this.validateInputDate(value);
    if (mDate) {
      this.inputError = false;
      this.calCenter = mDate;

      if (this.args.withoutCalendar) {
        this.args.onChange?.(mDate ?? null);
      }
    } else {
      this.inputError = true;
    }
  }

  @action
  handleInlineEditSave(event: KeyboardEvent) {
    if (!event?.target) {
      return;
    }
    const { value } = event.target as HTMLInputElement;
    this.handleTextFieldEnter(value);
  }

  @action
  handleTextFieldEnter(value: string) {
    const { onEnter, onChange } = this.args;
    const validDate = this.validateInputDate(value);

    if (validDate) {
      this.inputError = false;
      if (typeof onEnter === 'function') {
        onEnter?.(validDate);
      } else {
        onChange?.(validDate);
      }
    }
  }

  @action
  handleCenterChange(center: { date: any }) {
    this.calCenter = center.date;
  }

  @action
  handleClear(_id: string, _e: InputEvent) {
    this.calCenter = new Date();
    this.inputError = false;
    this.args.onChange?.(null);
  }

  /**
   * This function validate the date value input.
   * It checks the min and max date constraints as weel as general parsing validity.
   *
   * @param date Input date
   * @returns The parse Date or undefined if invalid or undefined
   */
  validateInputDate(date: moment.MomentInput) {
    const { minDate, maxDate } = this.args;
    const vMinDate = validDateOrUndefined(minDate);
    const vMaxDate = validDateOrUndefined(maxDate);
    const vDate = validDateOrUndefined(date);

    if (
      vDate &&
      ((vMinDate && moment(vDate).isBefore(vMinDate)) ||
        (vMaxDate && moment(vDate).isAfter(vMaxDate)))
    ) {
      return undefined;
    } else return vDate;
  }
}
