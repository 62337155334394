import BaseModel, { IResourceName } from 'volta/models/base-model';
import { _normalize, collectionCommand, resourceCommand as command } from 'volta/utils/api';

import { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

import type { TPlanningMethodology } from './types/planning-settings';

import type { TAcquisitionCode } from 'volta/models/constants/acquisition-codes';
import type { TUpdatePayload } from 'volta/components/skus-update-modal';
import type { ISupplierIdentityInfo } from 'volta/models/types/supplier';
import type { IWarehouseIdentityInfo } from 'volta/models/types/warehouse';
import type { HookClass } from 'volta/utils/api/types';

import type {
  IPartInfo,
  IPartTypeInfo,
  ISkuReplacementInfo,
  ISkuStats,
  ISkuTransactionStats
} from './types/sku';
import type { IAggTasksViewMap } from './types/tasks';
import type { IWorkshopIdentityInfo } from './types/workshop';
import type IntlService from 'ember-intl/services/intl';
import type AppSettings from 'volta/services/app-settings';

export const SkuResourceName: IResourceName = {
  singular: 'sku',
  plural: 'skus',
  table: 'inventories'
};

export const SkuQueries = {
  Locations: 'locations',
  DemandForecastTs: 'demandForecastTs',
  ImpactedFP: 'impactedFP',
  Parents: 'parents',
  FinishedProducts: 'finishedProducts'
};

export const SkuCommands = {
  CreateSku: 'CreateSku',
  UpdateSku: 'UpdateSku',
  ActivateSku: 'ActivateSku',
  DeactivateSku: 'DeactivateSku',
  UpdateManySku: 'UpdateManySku',
  GenerateDAF: 'GenerateDAF',
  CalculateVoltaLeadTime: 'CalculateVoltaLeadTime',
  CalculateAutoDaf: 'CalculateAutoDaf',
  CalculateVLT: 'CalculateVLT'
};

export const SkuEvents = {
  SkuCreated: 'SkuCreated',
  SkuUpdated: 'SkuUpdated',
  SkuActivated: 'SkuActivated',
  SkuDeactivated: 'SkuDeactivated',
  ManySkuUpdated: 'ManySkuUpdated',
  DAFGenerated: 'DAFGenerated',
  VLTCalculationStarted: 'VLTCalculationStarted',
  VLTCalculationDone: 'VLTCalculationDone'
};

/**
 * @class SkuModel
 * @extends BaseModel
 */
export default class SkuModel extends BaseModel {
  // TODO - Remove when investigation on
  // why it only disappears on Sku model is done
  static modelName = 'sku';

  /**
   * Collection commands
   */
  static updateManySku = collectionCommand<TUpdatePayload, SkuModel[]>(SkuCommands.UpdateManySku, {
    after: _normalize as HookClass<any, SkuModel[], typeof SkuModel>
  });
  static calculateAutoDaf = collectionCommand(SkuCommands.CalculateAutoDaf);
  static createSku = collectionCommand(SkuCommands.CreateSku, { after: _normalize });
  static calculateVLT = collectionCommand(SkuCommands.CalculateVLT);

  // Services

  @service appSettings!: AppSettings;
  @service intl!: IntlService;

  // Attributes

  /**
   * acqCode Acquisition code
   */
  @attr('string') acqCode!: TAcquisitionCode;

  /**
   * leadTime Lead time in days
   */
  @attr('number', { defaultValue: 1 }) leadTime!: number;

  /**
   * Decoupled lead time calculated by VOLTA
   */
  @attr('number') voltaLeadTime?: number;

  /**
   * Internal lead time
   */
  @attr('number') internalLeadTime?: number;

  /**
   * Planning methodology (comes from planning settings)
   */
  @attr('string') methodology?: TPlanningMethodology;

  /**
   * Is the SKU protected (comes from the planning settings)
   */
  @attr('boolean', { defaultValue: false }) isProtected!: boolean;

  /**
   * Minimum order quantity
   */
  @attr('number') minOrderQty?: number;

  /**
   * Maximum PO quantity
   */
  @attr('number') maxPOQty?: number;

  /**
   * Order multiple
   */
  @attr('number') orderMultiple?: number;

  /**
   * Default PO order type
   */
  @attr('string') orderType?: string;

  /**
   * Inventory unit price
   */
  @attr('number') inventoryUnitPrice?: number;

  /**
   * Sales unit price
   */
  @attr('number') salesUnitPrice?: number;

  /**
   * Planner name
   */
  @attr('string') planner?: string;

  /**
   * Buyer name
   */
  @attr('string') buyer?: string;

  /**
   * SKU display name
   */
  @attr('string') description?: string;

  /**
   * Warehouse currency code
   */
  @attr('string') currency?: string;

  /**
   * Tags SKU free-form tags
   */
  @attr('array', {
    defaultValue: () => []
  })
  tags!: string[];

  /**
   * Warehouse information
   */
  @attr() warehouse!: IWarehouseIdentityInfo;

  /**
   * Part type information
   */
  @attr() partType?: IPartTypeInfo;

  /**
   * Part information
   */
  @attr({
    defaultValue: () => {
      return { unitMeasure: 'UNIT' };
    }
  })
  part!: IPartInfo;

  /**
   * Supplier information
   */
  @attr() supplier?: ISupplierIdentityInfo;

  /**
   * Delivery Workshop information
   */
  @attr() deliveryWorkshop?: IWorkshopIdentityInfo;

  /**
   * Workshop information
   */
  @attr() workshop?: IWorkshopIdentityInfo;

  /**
   * Transactions stats
   */
  @attr() transactionStats?: ISkuTransactionStats;

  /**
   * Aggregated data about each task type
   */
  @attr() tasks?: IAggTasksViewMap;

  /**
   * SKU stats
   */
  @attr() skuStats?: ISkuStats;

  @attr() replacing?: ISkuReplacementInfo[];
  @attr() replacedBy?: ISkuReplacementInfo[];
  @attr('boolean') isReplaced!: boolean;
  @attr('boolean') isActive!: boolean;
  /**
   * Custom fields values
   */
  @attr() customFields?: Record<string, any>;

  /**
   * Activation state
   */
  @attr('date') deactivatedAt?: Date;

  // Getters

  get skuId() {
    return this.id;
  }

  get partCode() {
    return this.part?.code;
  }

  get skuDescription() {
    return this.description ?? this.part?.description;
  }

  /**
   * The 1 year demand Coefficient of Variability
   */
  get variability() {
    return this.transactionStats?.['1yDemandCov'];
  }

  /**
   * Concatenated tags and part tags
   */
  get allTags() {
    const { tags, part } = this;
    return (part?.tags ?? [])
      .map((tag: string) => {
        return { tag };
      })
      .concat(
        (tags ?? []).map((tag) => {
          return { tag, removable: true };
        })
      )
      .sortBy('tag');
  }

  /**
   * Is the SKU managed with a BUFFER
   */
  get isBuffered() {
    return this.methodology === 'BUFFER';
  }

  /**
   * Methodology is BUFFER or MRPIs the SKU managed
   */
  get isManaged() {
    return this.methodology && this.methodology !== 'NONE';
  }

  /**
   * Decoupled Lead-time or Lead-time
   */
  get actualLeadTime() {
    return this.voltaLeadTime ?? this.leadTime;
  }

  /**
   * Application settings for the SKU warehouse or the default one
   */
  get config() {
    return this.appSettings.byWarehouseOrDefault(this.warehouse?.warehouseId);
  }

  /**
   * Volta lead-time or lead-time display
   *
   * @return {string} DLT / LT
   */
  get vltDisplay() {
    const { voltaLeadTime, leadTime } = this;
    return (voltaLeadTime ?? leadTime ?? 'NA') + '/' + (leadTime ?? 'NA');
  }

  get iltLabel() {
    const { internalLeadTime } = this;
    return internalLeadTime
      ? this.intl.t('internalLeadTimeValue', { lt: internalLeadTime })
      : undefined;
  }

  /**
   * Computed variability category depending ono the business rules
   * in the application settings by warehouse
   *
   * @return {string} low, medium, high or undefined
   */
  get varCategory() {
    return this.skuStats?.varCategory;
  }

  /**
   * Lead time category computed from the business rules
   * in the application settings by warehouse (or default)
   *
   * @return {string} short, medium, long or null
   */
  get leadTimeCategory() {
    return this.skuStats?.leadTimeCategory;
  }

  /**
   * List of authorized PO types from the warehouse config
   */
  get authorizedOrderTypes() {
    return this.config?.settings.poWorkbench.authorizedOrderTypes ?? [];
  }

  /**
   * Default PO Type from the warehouse config
   */
  get defaultSKUOrderType() {
    return this.config?.settings.poWorkbench.defaultSKUOrderType;
  }

  get customFieldsCount() {
    return Object.keys(this.customFields ?? {}).length;
  }

  validateSearch(search?: string) {
    return (
      !search ||
      search === '' ||
      this.partCode.toLowerCase().includes(search.toLowerCase()) ||
      (this.skuDescription && this.skuDescription.toLowerCase().includes(search.toLowerCase()))
    );
  }

  get sourcedFrom() {
    const { /*acqCode, supplier, workshop,*/ warehouse } = this;
    return { code: warehouse.code, description: warehouse.description, color: 'green' };
    /*
    if (acqCode === 'BUY' && supplier) {
      return { code: supplier.code, description: supplier.description, color: 'teal' };
    } else if (acqCode === 'MAKE' && workshop) {
      return { code: workshop.code, description: workshop.description, color: 'orange' };
    } else {
      return { code: warehouse.code, description: warehouse.description, color: 'green' };
    }*/
  }

  // Commands

  /**
   * Command for updating a SKU
   */
  updateSku = command(SkuCommands.UpdateSku);
  activateSku = command(SkuCommands.ActivateSku);
  deactivateSku = command(SkuCommands.DeactivateSku);

  // Helpers
}
