import { validateNumber, validatePresence } from 'ember-changeset-validations/validators';
import validateOnCheck from 'volta/validators/on-check';

import { isPresent } from '@ember/utils';

const workshopValidation = {
  code: [validatePresence(true)],
  description: [],
  isControlPoint: [],
  timeBuffer: [],
  capacitySettings: {
    capacityUnitFrequency: [validatePresence(true)],
    capacity: [],
    capacityUnit: [
      validateOnCheck({
        checkKeys: ['capacitySettings.capacity'],
        checkCondition: (capacityUnit: string, capacity: number) => {
          return isPresent(capacity) ? isPresent(capacityUnit) : true;
        },
        i18nKey: 'workshopsPage.capacityUnit',
        i18nType: 'present'
      })
    ],
    capacityBufferRatio: [validateNumber({ lte: 1, allowBlank: true })]
  }
};

export default workshopValidation;

export const bulkWorkshopsValidation = {
  capacitySettings: { ...workshopValidation.capacitySettings, capacityUnitFrequency: [] }
};
