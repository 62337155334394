import { TWorkshopType } from 'volta/models/types/workshop.d';
import { IJsonApiQuery } from 'volta/utils/api/jsonapi-types';
import Component from '@glimmer/component';

export interface ISkusWorkshopFilterDefinitionArgs {
  skuId?: string;
  warehouseId?: string;
  simulationId?: string;
  workshopType?: TWorkshopType;
  noMatchesMessage?: string;
  placeholder?: string;
  renderInPlace?: boolean;
  beforeQuery?: (query: IJsonApiQuery) => IJsonApiQuery;
}

export default class SkusWorkshopFilter extends Component<
  IFilterArgs<ISkusWorkshopFilterDefinitionArgs>
> {}
