import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export interface IBvBtnGroupContent {
  disabled?: boolean;
  action?: () => void;
  hidden?: boolean;
}

interface IArgs {
  activeValue?: string;
  content: any[];
  labelPath?: string;
  keyPath?: string;
  nullable?: boolean;
  onChange?: (value?: string | null) => void;
}
export default class BvBtnGroup extends Component<IArgs> {
  @tracked activeValue?: string | null = this.args.activeValue;

  segmented = true;

  get content() {
    return this.args.content ?? [];
  }

  get labelPath() {
    return this.args.labelPath ?? 'label';
  }

  get keyPath() {
    return this.args.keyPath ?? 'key';
  }

  @action
  handleDidUpdate(_el: HTMLElement, [key]: [string]) {
    this.setActiveValue(key);
  }

  @action
  handleChange(key: string) {
    this.setActiveValue(key);
    this.args.onChange?.(this.activeValue);
  }

  @action
  handleClick(evt: InputEvent) {
    evt.stopPropagation();
  }

  setActiveValue(value: string) {
    if (this.activeValue !== value) {
      const { nullable, activeValue } = this.args;
      const shouldBeNull = nullable && activeValue === value;
      this.activeValue = shouldBeNull ? null : value;
    }
  }
}
