import BaseModel from 'volta/models/base-model';
import {
  _normalize,
  collectionCommand,
  resourceCommand as command
} from 'volta/utils/api';

import { attr } from '@ember-data/model';
import { WarehouseCalendarDayCommands, WarehouseCalendarDayResourceName } from "volta/models/warehouse-calendar-day";
import { normalize } from "volta/utils/api/serialize-and-push";
import { ICreateWarehouseCmd, IResetDaysCmd, IUpdateCalendarCmd } from "volta/models/types/warehouse";

export const WarehouseResourceName = {
  singular: 'warehouse',
  plural: 'warehouses'
};

export enum WarehouseEvents {
  WarehouseCreated = 'WarehouseCreated',
  WarehouseUpdated = 'WarehouseUpdated',
  WarehouseDeleted = 'WarehouseDeleted'
}

enum TWarehouseCommands {
  CreateWarehouse = 'CreateWarehouse',
  UpdateWarehouse = 'UpdateWarehouse'
}

export interface IWarehouse {
  code?: string;
  description?: string;
}

export default class Warehouse extends BaseModel {
  static createWarehouse = collectionCommand<ICreateWarehouseCmd>(TWarehouseCommands.CreateWarehouse, {
    after: _normalize
  });

  /**
   * Create or update the warehouse calendar days
   * @return The list of created or updated WarehouseCalendarDay models
   */
  static updateCalendar = collectionCommand<IUpdateCalendarCmd>(WarehouseCalendarDayCommands.UpdateCalendar, {
    after: (payload) => normalize(WarehouseCalendarDayResourceName.singular, payload)
  });

  /**
   * Effectively deletes the models identified by the warehouse id and dates
   * @return Done
   */
  static resetCalendarDays = collectionCommand<IResetDaysCmd>(WarehouseCalendarDayCommands.ResetDays);

  updateWarehouse = command(TWarehouseCommands.UpdateWarehouse);

  @attr('string') code!: string;
  @attr('string') description?: string;
  @attr('string') currency?: string;
  @attr('string') externalId?: string;

  get warehouseId() {
    return this.id;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    warehouse: Warehouse;
  }
}
