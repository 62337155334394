import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type SessionUserService from './session-user';

const NAMESPACE = 'volta';
const defaultBuildKey = (userId: string, key: string) => `${NAMESPACE}:${userId}:${key}`;
export const formatBvTableKey = (id: string) => `bv-table:${id}`;

export default class LocalPreferencesService extends Service {
  @service sessionUser!: SessionUserService;

  @tracked inMemoryStorage: Record<string, any> = {};

  getItem<T = any>(key: string): T | undefined {
    const userKey = this.buildKey(key);
    if (!userKey) {
      return undefined;
    }
    const value = localStorage && localStorage.getItem(userKey);
    return value ? JSON.parse(value) : this.inMemoryStorage[userKey];
  }

  setItem(key: string, item: any): void {
    const userKey = this.buildKey(key);
    if (!userKey) {
      return undefined;
    }
    if (localStorage) {
      localStorage.setItem(userKey, JSON.stringify(item));
    } else {
      this.inMemoryStorage[userKey] = item;
    }
  }

  clearItem(key: string) {
    const { user } = this.sessionUser;
    if (localStorage && user && user.id) {
      localStorage.removeItem(defaultBuildKey(user.id, key));
    }
  }

  clear() {
    this.inMemoryStorage = {};
    const { user } = this.sessionUser;
    if (localStorage && user && user.id) {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith(`${NAMESPACE}:${user.id}:`)) {
          localStorage.removeItem(key);
        }
      }
    }
  }

  buildKey(key?: string) {
    const { user } = this.sessionUser;
    return user?.id && key ? defaultBuildKey(user.id, key) : undefined;
  }
}

declare module '@ember/service' {
  interface Registry {
    'local-preferences': LocalPreferencesService;
  }
}
