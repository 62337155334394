import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface BvConnectedItemAgs {
  focused?: boolean;

  /**
   * The position of the item.
   */
  position?: 'left' | 'right' | 'primary';
}

export default class BvConnectedItem extends Component<BvConnectedItemAgs> {
  /**
   * Whether or not the item is focused.
   *
   * @property focused
   * @private
   * @type {Boolean}
   * @default false
   */
  @tracked focused: boolean = this.args.focused ?? false;

  @action
  handleFocusIn() {
    this.focused = true;
  }

  @action
  handleFocusOut() {
    this.focused = false;
  }
}
