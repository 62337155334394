import { restartableTask } from 'ember-concurrency';
import moment from 'moment-timezone';
import { SupplierResourceName } from 'volta/models/supplier';
import { SupplierCalendarDayResourceName } from 'volta/models/supplier-calendar-day';
import { WarehouseResourceName } from 'volta/models/warehouse';
import { WarehouseCalendarDayResourceName } from 'volta/models/warehouse-calendar-day';
import { WorkshopResourceName } from 'volta/models/workshop';
import { WorkshopCalendarDayResourceName } from 'volta/models/workshop-calendar-day';
import { indexBy } from 'volta/utils/array-utils';
import { isWeekendDay } from 'volta/utils/date-utils';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import type { ICalendar } from './nav';

import type CalendarService from 'volta/services/calendar-service';
import type { TCalendarDay } from 'volta/services/calendar-service';
import type IntlService from 'ember-intl/services/intl';
import type StoreService from 'volta/services/store';
import type AppSettings from 'volta/services/app-settings';
import type { IWorkshopIdentityInfo } from 'volta/models/types/workshop';
import type { IWarehouseIdentityInfo } from 'volta/models/types/warehouse';
import type { ISupplierIdentityInfo } from 'volta/models/types/supplier';

interface IArgs {
  warehouse: IWarehouseIdentityInfo;
  supplier?: ISupplierIdentityInfo;
  workshop?: IWorkshopIdentityInfo;
  calendar: ICalendar;
}

export default class BvCalendarClosedReceptionDays extends Component<IArgs> {
  @service calendarService!: CalendarService;
  @service appSettings!: AppSettings;
  @service store!: StoreService;
  @service intl!: IntlService;

  @tracked supplierWeekendDays?: number[];
  @tracked workshopWeekendDays?: number[];
  @tracked selectedCloseDays: string[] = [
    WarehouseResourceName.singular,
    SupplierResourceName.singular,
    WorkshopResourceName.singular
  ];

  get calYear() {
    return moment(this.args.calendar.center).year();
  }

  get closedDaysMenu() {
    return [
      this.args.supplier && {
        content: this.args.supplier?.description ?? this.args.supplier?.code,
        onClick: () => this.selectClosedDays(SupplierResourceName.singular),
        icon: 'ellipse',
        color: 'teal',
        selected: this.selectedCloseDays.includes(SupplierResourceName.singular)
      },
      this.args.workshop && {
        content: this.args.workshop?.description ?? this.args.workshop?.code,
        onClick: () => this.selectClosedDays(WorkshopResourceName.singular),
        icon: 'ellipse',
        color: 'orange',
        selected: this.selectedCloseDays.includes(WorkshopResourceName.singular)
      },
      this.args.warehouse && {
        content: this.args.warehouse.description ?? this.args.warehouse.code,
        onClick: () => this.selectClosedDays(WarehouseResourceName.singular),
        icon: 'ellipse',
        color: 'green',
        selected: this.selectedCloseDays.includes(WarehouseResourceName.singular)
      }
    ];
  }

  get weekendDays() {
    const closedCalDays: Record<string, number[]> = { workshop: [] };

    if (this.closeDaysIncludes(WarehouseResourceName.singular)) {
      closedCalDays.warehouse =
        this.appSettings.byWarehouseOrDefault(this.args.warehouse.warehouseId)?.settings
          .weekendDays ?? [];
    }
    if (this.closeDaysIncludes(SupplierResourceName.singular)) {
      closedCalDays.supplier = this.supplierWeekendDays ?? [];
    }
    if (this.closeDaysIncludes(WorkshopResourceName.singular)) {
      closedCalDays.workshop = this.workshopWeekendDays ?? [];
    }

    return closedCalDays;
  }

  @cached
  get closedCalDays() {
    const closedCalDays: Record<string, TCalendarDay[]> = {};
    const { warehouse, supplier, workshop } = this.args;
    const getCache = (id?: string) =>
      (id ? this.calendarService.cache[id] ?? {} : {})[this.calYear] ?? [];

    if (this.closeDaysIncludes(WarehouseResourceName.singular)) {
      closedCalDays.warehouse = getCache(warehouse.warehouseId);
    }

    if (this.closeDaysIncludes(SupplierResourceName.singular)) {
      closedCalDays.supplier = getCache(supplier?.supplierId);
    }

    if (this.closeDaysIncludes(WorkshopResourceName.singular)) {
      closedCalDays.workshop = getCache(workshop?.workshopId);
    }

    return closedCalDays;
  }

  get indexedClosedCalDays() {
    return {
      [WarehouseResourceName.singular]: indexBy(
        this.closedCalDays.warehouse as TCalendarDay[],
        'formattedDate'
      ),
      [SupplierResourceName.singular]: indexBy(
        this.closedCalDays.supplier as TCalendarDay[],
        'formattedDate'
      ),
      [WorkshopResourceName.singular]: indexBy(
        this.closedCalDays.workshop as TCalendarDay[],
        'formattedDate'
      )
    };
  }

  @action
  selectClosedDays(type: string) {
    if (this.closeDaysIncludes(type)) {
      this.selectedCloseDays = this.selectedCloseDays.filter((t) => t !== type);
    } else {
      this.selectedCloseDays = [...this.selectedCloseDays, type];
    }
  }

  fetchCalendarDays = restartableTask(async () => {
    if (this.args.warehouse.warehouseId) {
      await this.calendarService.fetchCalendarDays.perform(
        WarehouseCalendarDayResourceName,
        this.args.warehouse.warehouseId
      );
    }
    if (this.args.supplier?.supplierId) {
      await this.calendarService.fetchCalendarDays.perform(
        SupplierCalendarDayResourceName,
        this.args.supplier.supplierId
      );
      await this.fetchSupplierWeekendDays.perform(this.args.supplier.supplierId);
    }
    if (this.args.workshop?.workshopId) {
      await this.calendarService.fetchCalendarDays.perform(
        WorkshopCalendarDayResourceName,
        this.args.workshop.workshopId
      );
      await this.fetchWorkshopWeekendDays.perform(this.args.workshop.workshopId);
    }
  });

  fetchSupplierWeekendDays = restartableTask(async (supplierId: string) => {
    const supplier = await this.store.findRecord(SupplierResourceName.singular, supplierId);
    this.supplierWeekendDays = supplier.closedWeekDays;
  });

  fetchWorkshopWeekendDays = restartableTask(async (workshopId: string) => {
    const workshop = await this.store.findRecord(WorkshopResourceName.singular, workshopId);
    this.workshopWeekendDays = workshop.closedWeekDays;
  });

  private static formattedDate(date?: moment.Moment | Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  // Template Helpers
  // ~~~~~~

  findCalDate = (date: Date) => {
    return {
      supplier:
        this.indexedClosedCalDays.supplier[BvCalendarClosedReceptionDays.formattedDate(date)] ??
        this.calendarService.buildDefaultState(date, this.weekendDays.supplier as number[]),
      warehouse:
        this.indexedClosedCalDays.warehouse[BvCalendarClosedReceptionDays.formattedDate(date)] ??
        this.calendarService.buildDefaultState(date, this.weekendDays.warehouse as number[]),
      workshop:
        this.indexedClosedCalDays.workshop[BvCalendarClosedReceptionDays.formattedDate(date)] ??
        this.calendarService.buildDefaultState(date, this.weekendDays.workshop as number[])
    };
  };

  isAWeekendDay = (date: Date, entity: string) => {
    return isWeekendDay(date, this.weekendDays[entity]);
  };

  closeDaysIncludes = (entity: string) => {
    return this.selectedCloseDays.includes(entity);
  };
}
