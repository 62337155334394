import Ember from 'ember';
import { restartableTask, Task } from 'ember-concurrency';
import Infinity from 'ember-infinity/services/infinity';
import { buildCFApiParamPrefix } from 'volta/models/custom-field-definition';
import JsonApiInfinityModel, { decorateApiQuery } from 'volta/models/jsonapi-infinity-model';
import { defaultErrorHandler } from 'volta/utils/error-utils';
import {
  filtersCount,
  getFiltersFromQueryParams,
  getFilterValuesFromQueryParams,
  offsetFromPage,
  pageLimit,
  resetFilterValues,
  serializeFilterValues
} from 'volta/utils/filters-utils';
import { parseNumber } from 'volta/utils/math-utils';
import { deepEqual, getOrElse, isFunction, withoutAttributes } from 'volta/utils/object-utils';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isNone, isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import type { IResourceName } from 'volta/models/base-model';
import type StoreService from 'volta/services/store';
import type BvMetricsService from 'volta/services/bv-metrics';
import type { IJsonApiQuery } from 'volta/utils/api/jsonapi-types';
import type RouterService from '@ember/routing/router-service';
import type { IBvTableSort } from '../bv-table';
type TTaskReturn = Ember.ArrayProxy<unknown> & { meta?: { totalCount: number } };

interface IArgs {
  resourceName: IResourceName;
  infiniteScroll: boolean;
  filterDefinitions: TFilterDefinition[];
  filterValues: TFilterValues;
  defaultFilters: string[];
  queryParams: TQueryParams;
  selectedItems?: any[];
  searchKey?: string;
  include?: string;
  groupBy?: string[];
  tableSorts?: IBvTableSort[];
  sort?: string;
  page?: number;
  pageSize?: number;
  serializeQueryFn?: (query: IJsonApiQuery) => IJsonApiQuery;
  onPageChanged?: (page: number) => void;
  onPageSizeChanged?: (size: number) => void;
  onResetFilters?: (filterValues: TFilterValues) => void;
  onParamsChange?: (qp: TQueryParams) => void;
  onFilterValuesChange?: (filterValues: TFilterValues) => void;
  onOverrideFilters?: (filters?: Array<{ key: string; value: string }>) => TFilterValues;
  customTask?: Task<TTaskReturn | undefined, [IJsonApiQuery]>;
}

const DEFAULT_PAGE_SIZE = 50;
const DEFAULT_PAGE_SIZES = [25, 50, 100];
export const WITHOUT_ATTRIBUTES_ARRAY = [
  'v',
  'offset',
  'limit',
  'sort',
  'page',
  'pageSize',
  'lastId'
];

const searchFilterValue = (q: string) => {
  return {
    comparator: 'lk' as TComparator,
    values: [q],
    enabled: true
  };
};

export default class JsonapiCollection extends Component<IArgs> {
  // Services
  // ~~~~~

  @service store!: StoreService;
  @service router!: RouterService;
  @service infinity!: Infinity;
  @service bvMetrics!: BvMetricsService;

  // State
  // ~~~~~

  @tracked filterValues: TFilterValues = this.args.filterValues ?? {};
  @tracked sort?: string;
  @tracked tableSorts?: IBvTableSort[];
  @tracked page = this.args.page || 1;
  @tracked pageSize = this.args.pageSize || DEFAULT_PAGE_SIZE;
  @tracked selectedItems = this.args.selectedItems || [];
  searchKey = this.args.searchKey || 'q';

  infiniteScroll = getOrElse(this.args.infiniteScroll, false);
  defaultPageSizes = DEFAULT_PAGE_SIZES;
  cachedQueryParams?: TQueryParams;
  @tracked domElement!: HTMLElement;

  // Getters
  // ~~~~~~

  get filterDefinitions() {
    return this.args.filterDefinitions || [];
  }

  get filterCount() {
    return filtersCount(this.filterValues, this.args.defaultFilters);
  }

  get searchQuery() {
    const { filterValues = {} } = this;
    const q = filterValues[this.searchKey]?.values;
    return q && q.length ? q[0] : undefined;
  }

  get pageLimit() {
    return pageLimit(this.pageSize);
  }

  get pageOffset() {
    return offsetFromPage(this.page, this.pageLimit);
  }

  /**
   * Is there a next page
   */
  get hasNextPage() {
    return (this.page + 1) * this.pageSize < this.totalCount;
  }

  /**
   * Is there a previous page ?
   */
  get hasPrevPage() {
    return this.page > 1;
  }

  /**
   * Is the current page the first page
   */
  get isFirstPage() {
    return this.page === 1;
  }

  /**
   * Is the current page the last page
   */
  get isLastPage() {
    return (this.page + 1) * this.pageSize > this.totalCount;
  }

  /**
   * Get the total number of pages
   */
  get totalPages() {
    return Math.ceil(this.totalCount / this.pageSize);
  }

  get sortAscending() {
    return !`${this.args.sort}`.startsWith('-');
  }

  get totalCount() {
    const items: TTaskReturn | null | undefined = this.task.lastSuccessful?.value;
    return (items?.meta?.totalCount ?? items?.length ?? 0) as number;
  }

  get apiQuery() {
    let query: IJsonApiQuery = {};
    const { include, serializeQueryFn } = this.args;
    const { sort, pageOffset, pageLimit, filterValues, filterDefinitions } = this;
    const queryParams = serializeFilterValues(filterValues, filterDefinitions);
    const filters = getFiltersFromQueryParams(queryParams, filterDefinitions);

    if (sort) {
      query.sort = sort;
    }

    if (include) {
      query.include = include;
    }

    query.page = {
      offset: pageOffset,
      limit: pageLimit
    };

    query.filter = filters;
    if (serializeQueryFn && isFunction(serializeQueryFn)) {
      query = serializeQueryFn(query);
    }

    if (this.args.queryParams?.groupBy) {
      query.groupBy = this.args.queryParams?.groupBy;
    }

    return query;
  }

  get task() {
    const { customTask } = this.args;
    return customTask && typeof customTask.perform === 'function'
      ? customTask
      : this.jsonapiCollectionTask;
  }

  // Actions
  // ~~~~~~~

  @action
  handleInsert(el: HTMLElement) {
    this.domElement = el;
    this.initSort();
    this.initWithQueryParams(this.args.queryParams);
  }

  @action
  handleDidUpdate(_node: HTMLElement, [queryParams]: [TQueryParams]) {
    // If the queryParams change, we need to re-fetch the API
    if (!deepEqual(this.cachedQueryParams, queryParams)) {
      this.initWithQueryParams(queryParams);
    }
  }

  @action
  handlePageChange(page: number) {
    this.bvMetrics.trackAction(this, 'handlePageChange', `${page}`);
    this.page = page;
    this.args.onPageChanged?.(page);
    this.refreshModel();
  }

  @action
  handlePageSizeChange(size: number) {
    this.bvMetrics.trackAction(this, 'handlePageSizeChange', `${size}`);
    this.pageSize = size;
    this.args.onPageSizeChanged?.(size);
    this.refreshModel();
  }

  /**
   * Transition to the next page
   */
  @action
  handleNextPage() {
    this.bvMetrics.trackAction(this, 'handleNextPage');
    if (this.page + 1 <= this.totalPages) {
      this.page = this.page + 1;
    }
    this.args.onPageChanged?.(this.page);
    this.refreshModel();
  }

  /**
   * Transition to the previous page if any
   */
  @action
  handlePreviousPage() {
    this.bvMetrics.trackAction(this, 'handlePreviousPage');
    if (this.page - 1 >= 1) {
      this.page = this.page - 1;
    }
    this.args.onPageChanged?.(this.page);
    this.refreshModel();
  }

  @action
  handleSelectionChange(selected = []) {
    this.bvMetrics.trackAction(this, 'handleSelectionChange', undefined, selected.length);
    this.selectedItems = selected;
  }

  @action
  handleSortChange(sort: string) {
    this.bvMetrics.trackAction(this, 'handleSortChange', sort);
    this.sort = sort;
    this.page = 1;
    this.refreshModel();
  }

  @action
  handleTableSortsChange(sorts: IBvTableSort[]) {
    this.tableSorts = sorts || [];
    const sortQs = this.tableSorts
      .map((sort) => {
        return `${sort.isAscending ? '' : '-'}${sort.sortKey || sort.valuePath}`;
      })
      .join(',');
    this.bvMetrics.trackAction(this, 'handleTableSortsChange', sortQs);
    this.sort = sortQs;

    this.page = 1;
    this.refreshModel();
  }

  @action
  handleSearch(filters: TFilterValues, query: string) {
    this.bvMetrics.trackAction(this, 'handleSearch', query);
    this.applyFilters(filters, query);
    this.refreshModel();
  }

  @action
  handleApplyFilters(filters: TFilterValues) {
    this.bvMetrics.trackAction(this, 'handleApplyFilters');
    this.applyFilters(filters);
    this.refreshModel();
  }

  @action
  handleResetFilters() {
    this.bvMetrics.trackAction(this, 'handleResetFilters');
    this.filterValues = resetFilterValues(this.filterDefinitions);
    this.args.onResetFilters?.(this.filterValues);
    this.args.onFilterValuesChange?.(this.filterValues);
    this.refreshModel();
  }

  @action
  handleOverrideFilters(filters: Array<{ key: string; value: string }>) {
    this.bvMetrics.trackAction(this, 'handleOverrideFilters');
    let filtersToApply: TFilterValues = {};
    const { onOverrideFilters } = this.args;

    if (typeof onOverrideFilters === 'function') {
      filtersToApply = onOverrideFilters(filters);
    }

    (filters || []).forEach((filter: { key: string; value: string }) => {
      if (filter.value) {
        filtersToApply[filter.key] = {
          comparator: 'in',
          enabled: true,
          values: [filter.value]
        };
      }
    });

    if (filtersToApply) {
      this.applyFilters(filtersToApply, '');
      this.refreshModel();
    }
  }

  // Helpers
  // ~~~~~~

  initSort() {
    const { tableSorts, sort } = this.args;
    if (sort) {
      this.sort = sort;
      this.tableSorts = tableSorts
        ? tableSorts
        : [{ valuePath: sort.replace('-', ''), isAscending: !sort.startsWith('-') }];
    } else {
      this.tableSorts = tableSorts || [];
      this.sort =
        this.tableSorts && this.tableSorts.length
          ? this.tableSorts
              .map((s) => `${s.isAscending ? '' : '-'}${s.sortKey ?? s.valuePath}`)
              .join(',')
          : undefined;
    }
  }

  refreshModel() {
    const { onParamsChange, queryParams } = this.args;
    if (typeof onParamsChange === 'function' && queryParams) {
      onParamsChange(this.buildFiltersQueryParams());
    } else {
      this.performTask();
    }
  }

  initWithQueryParams(queryParams: TQueryParams) {
    if (queryParams) {
      let pagingMustBeReset = false;
      const { page, pageSize } = queryParams;
      let { sort } = queryParams;

      if (sort && sort !== this.sort) {
        if (page && parseInt(page) !== 1) {
          pagingMustBeReset = true;
        }
      }

      this.sort = sort ?? this.args.sort;

      const oldFilterParams = this.cachedQueryParams
        ? withoutAttributes(this.cachedQueryParams, WITHOUT_ATTRIBUTES_ARRAY)
        : undefined;
      const filtersParams = withoutAttributes(queryParams, WITHOUT_ATTRIBUTES_ARRAY);
      const withCustomFields = Object.entries(filtersParams).reduce(
        (acc: TQueryParams, [paramKey, filterValue]) => {
          if (paramKey.includes(buildCFApiParamPrefix())) {
            const cfKeys = paramKey.split(buildCFApiParamPrefix());
            const cfKey = cfKeys[cfKeys.length - 1];
            //@ts-ignore
            acc = { ...acc, customFields: { ...(acc.customFields ?? {}), [cfKey]: filterValue } };
          } else {
            acc[paramKey] = filterValue;
          }
          return acc;
        },
        {}
      );

      const filterValues = getFilterValuesFromQueryParams(withCustomFields, this.filterDefinitions);
      const parsedPage = parseNumber(page);

      if (oldFilterParams && !deepEqual(oldFilterParams, filtersParams) && parsedPage !== 1) {
        pagingMustBeReset = true;
      }

      if (pagingMustBeReset) {
        this.page = 1;
      } else if (isPresent(parsedPage)) {
        this.page = parsedPage as number;
      }

      const parsedPageSize = parseNumber(pageSize);

      if (isPresent(parsedPageSize)) {
        this.pageSize = parsedPageSize as number;
      }

      this.filterValues = filterValues;
      this.args.onFilterValuesChange?.(this.filterValues);
    }
    this.cachedQueryParams = queryParams;
    this.performTask();
  }

  buildFiltersQueryParams() {
    const { filterValues, page, pageSize, sort } = this;

    const values = serializeFilterValues(filterValues, this.filterDefinitions);

    values.customFields = values.customFields ? JSON.stringify(values.customFields) : undefined;
    const queryParams: TQueryParams = values || {};
    if (isPresent(page)) {
      queryParams.page = `${page}`;
    }
    if (isPresent(pageSize)) {
      queryParams.pageSize = `${pageSize}`;
    }
    if (isPresent(sort)) {
      queryParams.sort = sort;
    }

    return queryParams;
  }

  applyFilters(filters: TFilterValues, searchQuery?: string) {
    if (!isNone(searchQuery)) {
      filters[this.searchKey as string] =
        searchQuery === '' ? undefined : searchFilterValue(searchQuery);
    }
    this.page = 1;
    this.filterValues = filters;
    this.args.onFilterValuesChange?.(this.filterValues);

    return filters;
  }

  @action
  performTask() {
    const { pageSize, infiniteScroll } = this;
    let { apiQuery } = this;

    if (infiniteScroll) {
      apiQuery = decorateApiQuery(apiQuery, pageSize);
    }
    this.task.perform(apiQuery);
  }

  jsonapiCollectionTask = restartableTask(async (apiQuery: IJsonApiQuery & {}) => {
    try {
      return this.infiniteScroll
        ? await this.infinity.model(this.args.resourceName.singular, apiQuery, JsonApiInfinityModel)
        : await this.store.query(this.args.resourceName.singular, apiQuery);
    } catch (error) {
      defaultErrorHandler(error);
      return;
    }
  });
}
