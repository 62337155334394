import type IntlService from 'ember-intl/services/intl';
import type { IBvTableColumn } from 'volta/components/bv-table';
// @ts-ignore
import BufferIconCell from 'volta/components/cell/buffer-icon-cell';
// @ts-ignore
import CodeDescCell from 'volta/components/cell/code-desc-cell';
// @ts-ignore
import DueDateCell from 'volta/components/cell/due-date-cell';
// @ts-ignore
import LabelCell from 'volta/components/cell/label-cell';
// @ts-ignore
import PlanningPriorityCell from 'volta/components/cell/planning-priority-cell';
import DateFilter from 'volta/components/filters/date-filter';
import InputFilter from 'volta/components/filters/input-filter';
import SelectFilter from 'volta/components/filters/select-filter';
import SkusPartTypeFilter from 'volta/components/filters/skus-part-type-filter';
import SkusSupplierFilter from 'volta/components/filters/skus-supplier-filter';
import SkusWarehouseFilter from 'volta/components/filters/skus-warehouse-filter';
import SkusWorkshopFilter from 'volta/components/filters/skus-workshop-filter';
import TagsFilter from 'volta/components/filters/tags-filter';
import TokenFilter from 'volta/components/filters/token-filter';
import Config from 'volta/config/environment';
import { columnsToProps, columnsToSortOptions } from 'volta/models/collection-view';
import { AcquisitionCodesOptions } from 'volta/models/constants/acquisition-codes';
import { BufferZonesOptions } from 'volta/models/constants/buffer-zones';
import { OrderStatusesOptions } from 'volta/models/constants/order-statuses';
import { PlanningMethodologiesOptions } from 'volta/models/constants/planning-methodologies';
import { TimeBufferAlertOptions } from 'volta/models/constants/time-buffer-alerts';
import { buildCustomFieldColumn, parseCFApiParamKey } from 'volta/models/custom-field-definition';
import OrderModel, { ResourceName } from 'volta/models/order';
import { IJsonApiQuery } from 'volta/utils/api/jsonapi-types';
import { emberTableCustomColumns, getCustomColumns } from 'volta/utils/column-utils';
import { beforeQueryWorkshop, clearFilter, stringListToArray } from 'volta/utils/filters-utils';
import { colorForCategory, getBufferZoneCriticality } from 'volta/utils/style-utils';

import Service, { service } from '@ember/service';
import { isPresent } from '@ember/utils';

import type {
  ICollectionDefinitionBuilder,
  ICollectionSortOption,
  ICollectionViewDefinition,
  ICreateCollectionViewCmd
} from 'volta/models/types/collection-view';
import type CustomFieldDefinitionsService from 'volta/services/custom-field-definitions-service';
import type LabelService from 'volta/services/label-service';
import type SessionUserService from 'volta/services/session-user';
import type StoreService from 'volta/services/store';
import type RouterService from '@ember/routing/router-service';
const CUSTOM_COLUMNS = Config.APP.customColumns.openOrders;

export default class OpenOrdersCollectionDefinitionBuilder
  extends Service
  implements ICollectionDefinitionBuilder
{
  @service store!: StoreService;
  @service router!: RouterService;
  @service customFieldDefinitionsService!: CustomFieldDefinitionsService;
  @service intl!: IntlService;
  @service labelService!: LabelService;
  @service sessionUser!: SessionUserService;

  buildColumns() {
    const { intl } = this;
    const columns: IBvTableColumn[] = [
      {
        columnName: intl.t('part'),
        sortKey: 'sku.partCode',
        valuePath: 'sku.part',
        width: 250,
        isReorderable: true,
        isResizable: true,
        cellComponent: CodeDescCell,
        isFixed: 'left',
        format: (order: OrderModel) => {
          return {
            code: order.sku.part.code,
            description: order.sku.skuDescription,
            link: 'protected.open-orders.sku',
            linkParam: order.sku.id
          };
        }
      },
      {
        columnName: this.intl.t('warehouse'),
        sortKey: 'sku.warehouseCode',
        valuePath: 'sku.warehouse',
        width: 100,
        isReorderable: true,
        isResizable: true,
        isFixed: 'left',
        cellComponent: LabelCell,
        format: (order: OrderModel) => {
          return {
            label: order.sku.warehouse.code,
            color: 'green',
            iconRight: 'link-outline',
            tooltipText: order.sku.warehouse.description,
            onClick: () =>
              this.router.transitionTo('protected.warehouse', order.sku.warehouse.warehouseId)
          };
        }
      },
      {
        columnName: intl.t('partType'),
        sortKey: 'sku.partType.code',
        valuePath: 'sku.partType.code',
        width: 100,
        minWidth: 50,
        isReorderable: true,
        isResizable: true,
        cellComponent: LabelCell,
        format: (order: OrderModel) => {
          return {
            label: order.sku.partType?.code,
            tooltipText: order.sku.partType?.description
          };
        }
      },
      {
        columnName: intl.t('supplier'),
        sortKey: 'supplierCode',
        valuePath: 'supplier.code',
        width: 100,
        minWidth: 50,
        isReorderable: true,
        isResizable: true,
        cellComponent: LabelCell,
        format: (order: OrderModel) => {
          return {
            label: order.supplier?.code,
            color: 'primary',
            iconRight: 'link-outline',
            tooltipText: order.supplier?.description,
            onClick: () =>
              order.supplier &&
              this.router.transitionTo('protected.supplier', order.supplier.supplierId)
          };
        }
      },
      {
        columnName: intl.t('workshop'),
        sortKey: 'workshopCode',
        valuePath: 'workshop.code',
        width: 100,
        minWidth: 50,
        isReorderable: true,
        isResizable: true,
        cellComponent: LabelCell,
        format: (order: OrderModel) => {
          return {
            label: order.workshop?.code,
            color: 'severe',
            iconRight: 'link-outline',
            tooltipText: order.workshop?.description,
            onClick: () =>
              order.workshop &&
              this.router.transitionTo('protected.workshop', order.workshop.workshopId)
          };
        }
      },
      {
        columnName: intl.t('deliveryWorkshop'),
        sortKey: 'deliveryWorkshopCode',
        valuePath: 'deliveryWorkshop.code',
        width: 100,
        minWidth: 50,
        isReorderable: true,
        isResizable: true,
        disabled: true,
        cellComponent: LabelCell,
        format: (order: OrderModel) => {
          return {
            label: order.deliveryWorkshop?.code,
            color: 'purple',
            iconRight: 'link-outline',
            tooltipText: order.deliveryWorkshop?.description,
            onClick: () =>
              order.deliveryWorkshop &&
              this.router.transitionTo('protected.workshop', order.deliveryWorkshop.workshopId)
          };
        }
      },
      {
        columnName: intl.t('status'),
        sortKey: 'status',
        valuePath: 'status',
        width: 80,
        isReorderable: true,
        isResizable: true,
        cellComponent: LabelCell,
        format: (order: OrderModel) => {
          const { status } = order;
          return {
            label: intl.t(`ordersStatuses.${status || 'UNKNOWN'}`),
            color: colorForCategory(status)
          };
        }
      },
      {
        columnName: intl.t('planningMethodology'),
        valuePath: 'sku.methodology',
        sortKey: 'sku.methodology',
        isResizable: true,
        isReorderable: true,
        minWidth: 25,
        width: 80,
        cellComponent: BufferIconCell,
        format: (order: OrderModel) => {
          return {
            methodology: order.sku.methodology,
            isProtected: order.sku.isProtected
          };
        }
      },
      {
        columnName: intl.t('onHandAlert'),
        sortKey: 'executionAlert.priority',
        valuePath: 'executionAlert.onHandAlertZone',
        width: 130,
        isReorderable: true,
        isResizable: true,
        cellComponent: PlanningPriorityCell,
        format: (order: OrderModel) => {
          return {
            priorityName: getBufferZoneCriticality(order.executionAlert?.ohaZone as number),
            bufferPenetration: order.executionAlert?.oha,
            route: 'protected.open-orders.sku',
            routeModel: order.sku.id,
            routeQuery: { tab: 'execution' },
            displayEmptyText: true
          };
        }
      },
      {
        columnName: intl.t('dueDate'),
        sortKey: 'dueDate',
        valuePath: 'dueDate',
        width: 100,
        isReorderable: true,
        isResizable: true,
        cellComponent: DueDateCell,
        format: (model: OrderModel) => {
          return { model };
        }
      },
      {
        columnName: intl.t('qty'),
        sortKey: 'qty',
        valuePath: 'qty',
        width: 75,
        textAlign: 'right',
        isReorderable: true,
        isResizable: true,
        classNames: 'u-text-mono',
        format: (order: OrderModel) => {
          return this.intl.formatNumber(order.qty);
        }
      },
      {
        columnName: intl.t('unit'),
        sortKey: 'sku.unitMeasure',
        valuePath: 'sku.partUnitMeasure',
        width: 40,
        isReorderable: true,
        isResizable: true,
        format: (order: OrderModel) => {
          return this.labelService.unitMeasure(order.sku.part.unitMeasure);
        }
      },
      {
        columnName: intl.t('orderType'),
        sortKey: 'orderType',
        valuePath: 'orderType',
        width: 75,
        isReorderable: true,
        isResizable: true
      },
      {
        columnName: intl.t('orderNum'),
        sortKey: 'orderNum',
        valuePath: 'orderNum',
        width: 75,
        isReorderable: true,
        isResizable: true,
        cellComponent: LabelCell,
        format: (model: OrderModel) => {
          return {
            label: model.orderNum,
            color: 'teal',
            tooltipText: model.orderLine
          };
        }
      },
      {
        columnName: intl.t('voltaOrderNum'),
        sortKey: 'voltaOrderNum',
        valuePath: 'voltaOrderNum',
        width: 75,
        isReorderable: true,
        isResizable: true,
        cellComponent: LabelCell,
        format: (model: OrderModel) => {
          return {
            label: model.voltaOrderNum,
            color: 'warning',
            tooltipText: model.voltaOrderLine
          };
        }
      },
      {
        columnName: intl.t('parent'),
        sortKey: 'parentSku.partCode',
        valuePath: 'parentSku.partCode',
        width: 100,
        isReorderable: true,
        isResizable: true,
        cellComponent: CodeDescCell,
        format: (model: OrderModel) => {
          return {
            code: model.parentSku?.warehouseCode,
            description: model.parentSku?.partCode,
            link: model.parentSku ? 'protected.sku' : undefined,
            linkParam: model.parentSku?.id
          };
        }
      },
      {
        columnName: intl.t('vendorCode'),
        sortKey: 'vendorCode',
        valuePath: 'vendorCode',
        width: 80,
        isReorderable: true,
        isResizable: true,
        cellComponent: CodeDescCell,
        format: (order: OrderModel) => {
          return {
            code: order.vendorCode,
            description: order.vendorName
          };
        }
      },
      {
        columnName: intl.t('planner'),
        sortKey: 'sku.planner',
        valuePath: 'sku.planner',
        width: 80,
        isReorderable: true,
        isResizable: true
      }
    ];

    if (isPresent(CUSTOM_COLUMNS)) {
      emberTableCustomColumns(getCustomColumns(CUSTOM_COLUMNS), intl).forEach((c: IBvTableColumn) =>
        columns.push(c)
      );
    }

    const customFieldProps: Array<IBvTableColumn | undefined> = (
      this.customFieldDefinitionsService.skusCustomProperties() ?? []
    ).map((def) =>
      buildCustomFieldColumn(def, (model: OrderModel) => {
        const dataType = def.dataType ? def.dataType.toLowerCase() : undefined;
        const { customFields } = model.sku;
        const key = parseCFApiParamKey(def.property);

        const value = customFields ? customFields[key] : undefined;
        return {
          value,
          dataType
        };
      })
    );

    return columns.concat(customFieldProps.filter(Boolean) as IBvTableColumn[]);
  }

  buildFilters(queryParamsValues: TQueryParams) {
    const warehouseIds = queryParamsValues.warehouse
      ? stringListToArray(clearFilter(queryParamsValues.warehouse))
      : [];
    const normalDefs: Array<TFilterDefinition> = [
      {
        queryParam: 'partType',
        apiParam: 'sku.partTypeId',
        comparators: ['in', 'ni'],
        component: SkusPartTypeFilter,
        componentArgs: {}
      },
      {
        queryParam: 'warehouse',
        apiParam: 'sku.warehouseId',
        comparators: ['in', 'ni'],
        component: SkusWarehouseFilter,
        componentArgs: {}
      },
      {
        queryParam: 'deliveryWorkshop',
        apiParam: 'deliveryWorkshopId',
        comparators: ['in', 'ni'],
        component: SkusWorkshopFilter,
        componentArgs: { beforeQuery: beforeQueryWorkshop('DELIVERY', queryParamsValues.warehouse) }
      },
      {
        queryParam: 'workshop',
        apiParam: 'workshopId',
        comparators: ['in', 'ni'],
        component: SkusWorkshopFilter,
        componentArgs: { beforeQuery: beforeQueryWorkshop('WORKSHOP', queryParamsValues.warehouse) }
      },
      {
        queryParam: 'supplier',
        apiParam: 'supplierId',
        comparators: ['in', 'ni'],
        component: SkusSupplierFilter,
        componentArgs: {}
      },
      {
        queryParam: 'q',
        apiParam: 'sku.q',
        comparators: ['lk'],
        component: InputFilter,
        hidden: true,
        componentArgs: {}
      },
      {
        queryParam: 'acqCode',
        apiParam: 'sku.acqCode',
        comparators: ['in'],
        component: SelectFilter,
        componentArgs: { possibleValues: AcquisitionCodesOptions }
      },
      {
        queryParam: 'methodology',
        apiParam: 'sku.methodology',
        comparators: ['in'],
        component: SelectFilter,
        componentArgs: { possibleValues: PlanningMethodologiesOptions }
      },
      {
        queryParam: 'tags',
        apiParam: 'sku.tags',
        comparators: ['in', 'ni'],
        component: TagsFilter,
        componentArgs: {
          entityTypes: ['skus', 'parts'],
          warehouseIds
        }
      },
      {
        queryParam: 'status',
        apiParam: 'status',
        comparators: ['in'],
        component: SelectFilter,
        componentArgs: { possibleValues: OrderStatusesOptions }
      },
      {
        queryParam: 'orderType',
        apiParam: 'orderType',
        comparators: ['in'],
        component: TokenFilter,
        componentArgs: {
          warehouseIds,
          searchFn: (query: IJsonApiQuery) => {
            return this.store.customQuery('orders', 'orderTypes', undefined, {
              ...query
            });
          }
        }
      },
      {
        queryParam: 'ohaZone',
        apiParam: 'ohaZone',
        comparators: ['in'],
        component: SelectFilter,
        componentArgs: { possibleValues: BufferZonesOptions }
      },
      {
        queryParam: 'timeBuffer',
        apiParam: 'timeBuffer',
        comparators: ['eq'],
        component: SelectFilter,
        componentArgs: { possibleValues: TimeBufferAlertOptions }
      },
      {
        queryParam: 'dueDate',
        apiParam: 'dueDate',
        comparators: ['eq', 'lt', 'gt'],
        component: DateFilter,
        componentArgs: {}
      },
      {
        queryParam: 'qty',
        apiParam: 'qty',
        comparators: ['gt', 'lt', 'bt'],
        component: InputFilter,
        componentArgs: { inputType: 'number' }
      },
      {
        queryParam: 'voltaOrderNum',
        apiParam: 'voltaOrderNum',
        comparators: ['lk'],
        component: InputFilter,
        componentArgs: { inputType: 'text' }
      },
      {
        queryParam: 'orderNum',
        apiParam: 'orderNum',
        comparators: ['lk'],
        component: InputFilter,
        componentArgs: { inputType: 'text' }
      },
      {
        queryParam: 'planner',
        apiParam: 'sku.planner',
        comparators: ['in', 'ni'],
        component: TokenFilter,
        componentArgs: {
          warehouseIds,
          searchFn: (query: object) => {
            return this.store.customQuery('sku', 'planners', undefined, query);
          }
        }
      },
      {
        queryParam: 'buyer',
        apiParam: 'sku.buyer',
        comparators: ['in', 'ni'],
        component: TokenFilter,
        componentArgs: {
          warehouseIds,
          searchFn: (query: object) => {
            return this.store.customQuery('sku', 'buyers', undefined, query);
          }
        }
      },
      {
        queryParam: 'vendorCode',
        apiParam: 'vendorCode',
        comparators: ['lk'],
        component: InputFilter,
        componentArgs: {}
      }
    ];

    return normalDefs.concat(this.customFieldDefinitionsService.skusCustomFieldsFilters('sku'));
  }

  buildDefaultView(columns: IBvTableColumn[]): ICreateCollectionViewCmd {
    const { intl } = this;
    const properties = columnsToProps(columns).filter((c) => c.property !== 'sku.partType.code');

    return {
      name: intl.t('collectionViewsPage.default.name', {
        resourceNamePlural: intl.t(ResourceName.plural)
      }),
      description: intl.t('collectionViewsPage.default.description', {
        resourceNamePlural: intl.t(ResourceName.plural)
      }),
      sortBy: [{ property: 'dueDate', asc: true }],
      collectionType: 'TABLE',
      entityType: ResourceName.plural,
      properties,
      createdBy: this.sessionUser.userId
    };
  }

  build(queryParams: TQueryParams, sorts: ICollectionSortOption[] = []) {
    const filters = this.buildFilters(queryParams);
    const columns = this.buildColumns();
    const defaultView = this.buildDefaultView(columns);

    const view: ICollectionViewDefinition = {
      resourceName: ResourceName,
      sortLimit: 0,
      sorts: columnsToSortOptions(columns).concat(sorts),
      properties: columnsToProps(columns),
      filters,
      definitions: columns,
      types: ['TABLE'],
      defaultView
    };

    return view;
  }
}

declare module '@ember/service' {
  interface Registry {
    'collection-builder-open-orders': OpenOrdersCollectionDefinitionBuilder;
  }
}
