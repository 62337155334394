import dragula, { DragulaOptions, Drake } from 'dragula';
import Ember from 'ember';
import { modifier } from 'ember-modifier';
import { indexBy } from 'volta/utils/array-utils';

import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const { keys } = Object;

interface IArgs {
  onReady?: (drake: Drake) => void;
  options?: DragulaOptions;
  onDrag?: (el: HTMLElement, source: HTMLElement) => void;
  onDragEnd?: (el: HTMLElement) => void;
  onDrop?: (items: Array<object[]>) => void;
  onCancel?: (el: HTMLElement, container: HTMLElement, source: HTMLElement) => void;
  onRemove?: (item: object) => void;
  onShadow?: (el: HTMLElement, container: HTMLElement, source: HTMLElement) => void;
  onOver?: (el: HTMLElement, container: HTMLElement, source: HTMLElement) => void;
  onOut?: (el: HTMLElement, container: HTMLElement, source: HTMLElement) => void;
  onCloned?: (clone: HTMLElement, original: HTMLElement, type: 'mirror' | 'copy') => void;
  dragIdProperty?: string;
  disabled?: boolean;
  noHandle?: boolean;
  accepts?: (el: HTMLElement, target: HTMLElement) => boolean;
  invalid?: (el: HTMLElement, target: HTMLElement) => boolean;
  removeOnSpill?: boolean;
  direction?: 'vertical' | 'horizontal';
}

const events: Record<string, keyof IArgs> = {
  drag: 'onDrag',
  dragend: 'onDragEnd',
  drop: 'onDrop',
  cancel: 'onCancel',
  remove: 'onRemove',
  shadow: 'onShadow',
  over: 'onOver',
  out: 'onOut',
  cloned: 'onCloned'
};

export const DRAG_ATTRIBUTE = 'data-drag-id';
export const DEFAULT_HANDLE_CLASSNAME = 'bv-dnd-handle';
export const DRAG_ITEM_CLASSNAME = 'bv-dnd-drag-item';

type DragData = Array<{ container: HTMLElement; items: object[] }>;
type CustomDrake = Drake & { data: DragData };

// Thanks to https://github.com/zestia/ember-dragula
export default class EmberDragula extends Component<IArgs> {
  static events = events;

  @tracked drake!: CustomDrake;

  get dataIdAttr() {
    return this.args.dragIdProperty ?? 'id';
  }

  constructor(owner: any, args: IArgs) {
    super(owner, args);
    const options = {
      ...(this.args.options ?? {}),
      removeOnSpill: !!this.args.onRemove ?? false
    };

    if (!args.noHandle) {
      options.moves = function (_el: HTMLElement, _container: HTMLElement, handle: HTMLElement) {
        return handle
          ? (handle.classList.contains(DEFAULT_HANDLE_CLASSNAME) ||
              handle.parentElement?.classList.contains(DEFAULT_HANDLE_CLASSNAME)) ??
              false
          : true;
      };
    }

    if (args.disabled) {
      options.invalid = function (_el: HTMLElement, _handle: HTMLElement) {
        return args.disabled ?? false;
      };
    } else if (args.invalid) {
      options.invalid = args.invalid;
    }

    if (args.accepts) {
      options.accepts = args.accepts;
    }

    this.drake = dragula(options) as CustomDrake;
    this._setupHandlers();
    this.args.onReady?.(this.drake);
  }

  dragItem = modifier(
    (element: HTMLElement, [id]: [string]) => {
      element.setAttribute(DRAG_ATTRIBUTE, id);
      element.classList.add(DRAG_ITEM_CLASSNAME);
      return () => {
        element.removeAttribute(DRAG_ATTRIBUTE);
        element.classList.remove(DRAG_ITEM_CLASSNAME);
      };
    },
    {
      eager: false
    }
  );

  handle = modifier(
    (element: HTMLElement, [_id]: [string], { disabled }: { disabled: boolean }) => {
      if (disabled) {
        element.classList.remove(DEFAULT_HANDLE_CLASSNAME);
        element.removeEventListener('touchmove', this.preventDefault);
      } else {
        element.classList.add(DEFAULT_HANDLE_CLASSNAME);
        element.addEventListener('touchmove', this.preventDefault);
      }
      () => {
        element.classList.remove(DEFAULT_HANDLE_CLASSNAME);
        element.removeEventListener('touchmove', this.preventDefault);
      };
    },
    {
      eager: false
    }
  );

  @action
  addContainer(element: HTMLElement, items: object[]) {
    this.drake.containers.push(element);
    const dataItems = this.deserialize(items);

    this.drake.data = [...(this.drake.data ?? []), { container: element, items: dataItems }];
  }

  @action
  removeContainer(element: HTMLElement) {
    this.drake.containers.splice(this.drake.containers.indexOf(element), 1);
    this.drake.data = this.drake.data.filter((data) => data.container !== element);
  }

  @action
  updateContainer(element: HTMLElement, items: object[]) {
    const { data } = this.drake;
    const containerIndex = data.findIndex((d) => d.container === element);
    if (data[containerIndex].container === element && isPresent(containerIndex)) {
      this.drake.data[containerIndex].items = items;
    }
  }

  @action
  async onDrop(el: HTMLElement, target: HTMLElement, source: HTMLElement, _sibling: HTMLElement) {
    await this.args.onDrop?.(this.getUpdatedItems());
    if (target !== source) {
      el.remove();
    }
  }

  @action
  onRemove(el: HTMLElement, _container: HTMLElement, _source: HTMLElement) {
    const { data } = this.drake;
    const flatItems = data.map((d) => d.items).flat(2);
    const allItems = indexBy<object>(flatItems, this.dataIdAttr as keyof object);
    const id = el.getAttribute(DRAG_ATTRIBUTE);
    if (id && allItems[id]) {
      this.args.onRemove?.(allItems[id]);
    }
  }

  @action
  onCancel(el: HTMLElement, _container: HTMLElement, _source: HTMLElement) {
    el.classList.add('dnd-on-cancel');
    later(() => {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      el.classList.remove('dnd-on-cancel');
    }, 1000);
  }

  getUpdatedItems() {
    const { data } = this.drake;
    const flatItems = this.deserialize(data.map((d) => d.items).flat(2));

    const allItems = indexBy<object>(flatItems, this.dataIdAttr as keyof object);

    const sortedItems = [];
    for (const containerData of data) {
      const { container } = containerData;
      const containerItems = [];
      const children = container.querySelectorAll(`[${DRAG_ATTRIBUTE}]`);

      for (const child of children) {
        const id = child.getAttribute(DRAG_ATTRIBUTE);
        if (id) {
          containerItems.push(allItems[id]);
        }
      }

      sortedItems.push(containerItems);
    }

    return sortedItems;
  }

  deserialize(items: object[]) {
    let _items = items;
    if (_items.length && _items[0] && 'toArray' in (_items as [Ember.Array<object>])[0]) {
      _items = (_items as [Ember.Array<object>])[0].toArray();
    }
    return _items;
  }

  willDestroy() {
    this.drake?.destroy();
  }

  preventDefault(e: TouchEvent) {
    e.preventDefault();
  }

  private _setupHandlers() {
    keys(events).forEach((name) => {
      const handler = this[events[name] as keyof this] ?? this.args[events[name]];
      if (typeof handler === 'function') {
        // @ts-ignore
        this.drake.on(name, handler);
      }
    });
  }
}
