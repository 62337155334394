import InputFilter from 'volta/components/filters/input-filter';
import SelectFilter from 'volta/components/filters/select-filter';
import SkusSupplierFilter from 'volta/components/filters/skus-supplier-filter';
import SkusWarehouseFilter from 'volta/components/filters/skus-warehouse-filter';
import SkusWorkshopFilter from 'volta/components/filters/skus-workshop-filter';
import TagsFilter from 'volta/components/filters/tags-filter';
import TokenFilter from 'volta/components/filters/token-filter';
import StoreService from 'volta/services/store';
import { beforeQueryWorkshop, clearFilter, stringListToArray } from 'volta/utils/filters-utils';

import { AcquisitionCodesOptions } from '../acquisition-codes';
import { PlanningMethodologiesOptions } from '../planning-methodologies';

import type CustomFieldDefinitionsService from 'volta/services/custom-field-definitions-service';

export default function BomFilterDefinitions(
  store: StoreService,
  customFieldDefinitionsService: CustomFieldDefinitionsService,
  queryParamsValues: TQueryParams
): Array<TFilterDefinition> {
  const warehouseIds = queryParamsValues.warehouse
    ? stringListToArray(clearFilter(queryParamsValues.warehouse))
    : [];
  const normalDefs: Array<TFilterDefinition> = [
    {
      queryParam: 'warehouse',
      apiParam: 'sku.warehouseId',
      comparators: ['in', 'ni'],
      component: SkusWarehouseFilter,
      componentArgs: { horizontalPosition: 'right' }
    },
    {
      queryParam: 'deliveryWorkshop',
      apiParam: 'sku.deliveryWorkshopId',
      comparators: ['in', 'ni'],
      component: SkusWorkshopFilter,
      componentArgs: { beforeQuery: beforeQueryWorkshop('DELIVERY', queryParamsValues.warehouse) }
    },
    {
      queryParam: 'workshop',
      apiParam: 'sku.workshopId',
      comparators: ['in', 'ni'],
      component: SkusWorkshopFilter,
      componentArgs: { beforeQuery: beforeQueryWorkshop('WORKSHOP', queryParamsValues.warehouse) }
    },
    {
      queryParam: 'supplier',
      apiParam: 'sku.supplierId',
      comparators: ['in', 'ni'],
      component: SkusSupplierFilter,
      componentArgs: {}
    },
    {
      queryParam: 'q',
      apiParam: 'sku.q',
      comparators: ['lk'],
      component: InputFilter,
      hidden: true,
      componentArgs: { inputType: 'text' }
    },
    {
      queryParam: 'acqCode',
      apiParam: 'sku.acqCode',
      comparators: ['in'],
      component: SelectFilter,
      componentArgs: {
        horizontalPosition: 'right',
        possibleValues: AcquisitionCodesOptions
      }
    },
    {
      queryParam: 'methodology',
      apiParam: 'sku.methodology',
      comparators: ['in'],
      component: SelectFilter,
      componentArgs: {
        horizontalPosition: 'right',
        possibleValues: PlanningMethodologiesOptions
      }
    },
    {
      queryParam: 'tags',
      apiParam: 'sku.tags',
      comparators: ['any', 'all', 'ni'],
      component: TagsFilter,
      componentArgs: {
        horizontalPosition: 'right',
        entityTypes: ['skus', 'parts'],
        warehouseIds
      }
    },
    {
      queryParam: 'partsCount',
      apiParam: 'partsCount',
      comparators: ['lt', 'eq', 'gt'],
      component: InputFilter,
      componentArgs: { inputType: 'number' }
    },
    {
      queryParam: 'maxLevel',
      apiParam: 'maxLevel',
      comparators: ['lt', 'eq', 'gt'],
      component: InputFilter,
      componentArgs: { inputType: 'number' }
    },
    {
      queryParam: 'planner',
      apiParam: 'sku.planner',
      comparators: ['in', 'ni'],
      component: TokenFilter,
      componentArgs: {
        horizontalPosition: 'left',
        warehouseIds,
        searchFn: (query: any) => {
          return store.customQuery('sku', 'planners', undefined, query);
        }
      }
    },
    {
      queryParam: 'buyer',
      apiParam: 'sku.buyer',
      comparators: ['in', 'ni'],
      component: TokenFilter,
      componentArgs: {
        horizontalPosition: 'left',
        warehouseIds,
        searchFn: (query: any) => {
          return store.customQuery('sku', 'buyers', undefined, query);
        }
      }
    }
  ];

  return normalDefs.concat(customFieldDefinitionsService.skusCustomFieldsFilters('sku'));
}
