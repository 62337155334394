import { attr, belongsTo } from '@ember-data/model';
import BaseModel from 'volta/models/base-model';
import AppFeature from './app-feature';

export const TenantResourceName = {
  singular: 'tenant',
  plural: 'tenants'
};

/**
 * @class Tenant model
 * @extends BaseModel
 */
export default class Tenant extends BaseModel {
  @attr('string')
  declare tenantId: string;

  @attr('string')
  declare env: string;

  @attr('array', { defaultValue: () => [] })
  declare authorizedProviders: string[];

  @belongsTo('app-feature', { async: false })
  declare features: AppFeature;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tenant: Tenant;
  }
}
