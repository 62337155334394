import { handleMouseUpByBlurring } from 'volta/utils/focus';

import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

interface IArgs {
  /**
   * Content the action displays
   */
  content?: string | Component | object;
  /**
   * Visually hidden text for screen readers
   */
  accessibilityLabel?: string;
  /**
   * A destination to link to, rendered in the action
   */
  url?: string;
  /**
   * Forces url to open in a new tab
   */
  external: boolean;
  /*
   * Optional icon glyph
   */
  icon?: string;
  /**
   * Optional tooltip text
   */
  tooltip?: string;
  /**
   * Should the action be disabled
   */
  disabled: boolean;
  /**
   * Should we show a disclosure icon indicating a popover behaviour
   */
  disclosure: boolean;
  /**
   *
   */
  handleMeasurement: (width: number) => void;
  /**
   * Callback when an action takes place
   */
  onClick?: (event: MouseEvent) => void;
}

export default class BvResourceListBulkActionBtn extends Component<IArgs> {
  handleMouseUpByBlurring = handleMouseUpByBlurring;

  /**
   * ID used to identify our wrapper element from other instances
   */
  get wrapperId() {
    return guidFor(this);
  }

  @action
  handleDidInsert(/*element*/) {
    if (this.args.handleMeasurement) {
      const bulkActionButtonNode = document.getElementById(this.wrapperId);
      const width =
        (bulkActionButtonNode instanceof Element &&
          bulkActionButtonNode.getBoundingClientRect().width) ||
        0;
      this.args.handleMeasurement?.(width);
    }
  }

  @action
  handleMouseUp(event: { currentTarget: HTMLOrSVGElement }) {
    if(!event) {
      return;
    }
    const { currentTarget } = event;
    handleMouseUpByBlurring({ currentTarget });
  }

  @action
  handleClick(event: MouseEvent) {
    return this.args.onClick?.(event);
  }
}
