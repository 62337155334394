import { TFieldType } from '../types/customFieldDefinition';

export const CustomFieldTypes: Record<TFieldType, string> = {
  NUMBER: 'customFieldsDefinitionsPage.fieldTypes.NUMBER',
  TEXT: 'customFieldsDefinitionsPage.fieldTypes.TEXT',
  FORMULA: 'customFieldsDefinitionsPage.fieldTypes.FORMULA',
  BOOLEAN: 'customFieldsDefinitionsPage.fieldTypes.BOOLEAN',
  ARRAY: 'customFieldsDefinitionsPage.fieldTypes.ARRAY',
  TABLE: 'customFieldsDefinitionsPage.fieldTypes.TABLE',
  DATE: 'customFieldsDefinitionsPage.fieldTypes.DATE'
};
