import { TPlanningMethodology } from '../types/planning-settings';

const PlanningMethodologies: Record<TPlanningMethodology, string> = {
  BUFFER: 'planningMethodologies.BUFFER',
  MRP: 'planningMethodologies.MRP',
  NONE: 'planningMethodologies.NONE'
};

export const PlanningMethodologiesOptions = Object.keys(PlanningMethodologies).map(
  (key: TPlanningMethodology) => {
    return { key, label: PlanningMethodologies[key] };
  }
);

export const PlanningMethodologiesManagedOptions = PlanningMethodologiesOptions.filter(
  (m) => m.key !== 'NONE'
);
export const PlanningMethodologiesManagedOptionsArray = PlanningMethodologiesManagedOptions.map(
  (m) => m.key
);
export default PlanningMethodologies;
