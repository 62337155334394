import { ModelRegistry } from 'ember-data/model';
import IntlService from 'ember-intl/services/intl';
import moment from 'moment-timezone';
import { IBvTableColumn } from 'volta/components/bv-table';

import { get } from '@ember/object';
import { isPresent } from '@ember/utils';
import _camelCase from 'lodash/camelCase';

type TCustomColumnType = 'number' | 'date' | 'percent' | 'timestamp' | 'text';

export interface TCustomColumn {
  label: string;
  path: string;
  type: TCustomColumnType;
}

/* getCustomColumns prend en paramètre une chaine de caractère du type :
'sku.skuStats.custom.brand:text:Brand,custom_qty:number,custom.started_date:date'

Chaque section de la chaîne (de part et d'autres des virgules) représente une colonne personnalisée.
Pour chaque colonne personnalisée, on a :
- le chemin pour accéder à la donnée situé avant le caractère ":"
- le nom de la colonne, soit la dernière partie du chemin
- le type de données situé après le caractère ":"

A partir de la chaine de caractère initiale, et à l'aide des expressions régulières :
on renvoie un tableau d'objets (les colonnes) avec les propriétés path, label, type
Résultat de l'exemple :
customColumns = [
  {
    path: 'sku.skuStats.custom.brand',
    label: 'brand',
    type: 'text'
  },
    {
    path: 'custom_qty,
    label: 'custom_qty',
    type: 'number'
  },
    {
    path: 'custom.started_date',
    label: 'started_date',
    type: 'date'
  },
]
*/

export function getCustomColumns(stringConfig?: string): TCustomColumn[] {
  if (typeof stringConfig !== 'string' || stringConfig === '') {
    return [];
  }

  return stringConfig.split(',').map((conf: string): TCustomColumn => {
    const { 0: path, 1: type, 2: label } = conf.split(':');
    const pathArray = path.split('.');
    return {
      path,
      type: type as TCustomColumnType,
      label: label || pathArray[pathArray.length - 1]
    };
  });
}

/*
  Transform a custom column array to a string configuration

  For instance:
  from:
  [
    {
      label: 'Prochaine rupture',
      path: 'nextExecutionAlert',
      type: 'date'
    },
    {
      label: 'Durée rupture',
      path: 'stockoverDuration',
      type: 'number'
    },
    {
      label: 'Priorité ADV',
      path: 'advPriority',
      type: 'string'
    }
  ]

   to: "sku.skuStats.custom.nextExecutionAlert:date:Prochaine rupture,sku.skuStats.custom.stockoverDuration:number:Durée rupture,sku.skuStats.custom.advPriority:string:Priorité ADV"

*/

export function parseCustomColumns(columns: TCustomColumn[], pathPrefix: string = ''): string {
  return columns.reduce((acc: string, column: TCustomColumn) => {
    const config = `${pathPrefix}${column.path}:${column.type}:${column.label}`;
    if (!acc) {
      acc = config;
    } else {
      acc = acc.concat(',', config);
    }
    return acc;
  }, '');
}

export function emberTableCustomColumns(
  cols: TCustomColumn[],
  intl: IntlService,
  options = {}
): IBvTableColumn[] {
  return cols.map((col) => {
    const column: IBvTableColumn = {
      columnName: col.label,
      sortKey: col.path,
      valuePath: col.path,
      width: 80,
      textAlign: ['number', 'date'].includes(col.type) ? 'right' : 'left',
      isResizable: true,
      isReorderable: true,
      classNames: col.type === 'number' ? 'u-text-mono' : undefined,
      format: (model: ModelRegistry) => {
        const value = get(model, col.path);
        if (!value) {
          return null;
        }
        switch (col.type) {
          case 'number':
            return isPresent(value) ? intl.formatNumber(value) : '－';
          case 'percent':
            return isPresent(value) ? intl.formatNumber(value, { style: 'percent' }) : '－';
          case 'date':
            return moment(value).format('L');
          case 'timestamp':
            return moment(value).format();
          default:
            return value;
        }
      }
    };

    return Object.assign(column, options);
  });
}
