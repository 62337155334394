import Component from '@glimmer/component';

export const ComparatorsSigns = {
  eq: '=',
  in: '∈',
  ni: '∉',
  ne: '≠',
  gt: '>',
  lt: '<',
  bt: '[]',
  lk: '≈',
  any: '∈',
  all: '='
};

export type TComparatorOption = {
  sign: string;
  label: string;
  key: TComparator;
};

interface IArgs {
  comparators: TComparator[];
  selected: TComparator;
  onChange?: (comparator: TComparator) => void;
}

export default class ComparatorSelect extends Component<IArgs> {
  get comparatorOptions(): TComparatorOption[] {
    const { comparators } = this.args;
    return comparators.map((comparator) => {
      return {
        key: comparator,
        sign: ComparatorsSigns[comparator],
        label: `comparators.${comparator}`
      };
    });
  }

  get selectedComparatorOption() {
    return this.comparatorOptions.find((c) => c.key === this.args.selected);
  }
}
