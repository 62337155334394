import { attr, belongsTo } from '@ember-data/model';

import SimulationItemPlanning from 'volta/models/simulation-item-planning';
import SimulationScenario from 'volta/models/simulation-scenario';
import SkuModel from 'volta/models/sku';
import { IPlanningSettingData } from 'volta/models/types/planning-settings';
import { IStalloneInfo } from 'volta/models/types/simulation';
import { collectionCommand, resourceCommand as command } from 'volta/utils/api';

import BaseModel from './base-model';

export const ResourceName = {
  singular: 'simulationItemSetting',
  plural: 'simulationItemSettings'
};

export const Events = {
  Created: 'Created',
  Updated: 'Updated',
  StalloneCalculationDone: 'StalloneCalculationDone'
};

export const Commands = {
  UpdateSimulationItemSetting: 'UpdateSimulationItemSetting',
  UpdateManySimulationItemSettings: 'UpdateManySimulationItemSettings',
  ApplyMultipleItemSettings: 'ApplyMultipleItemSettings',
  RunStallone: 'RunStallone',
  ApplyAutoItemSettings: 'ApplyAutoItemSettings'
};

export default class SimulationItemSetting extends BaseModel {
  // Collection commands
  // ~~~~~~

  static runStallone = collectionCommand(Commands.RunStallone);
  static applyMultipleItemSettings = collectionCommand(Commands.ApplyMultipleItemSettings);
  static applyAutoItemSettings = collectionCommand(Commands.ApplyAutoItemSettings);
  static updateManySimulationItemSettings = collectionCommand(
    Commands.UpdateManySimulationItemSettings
  );

  // Resource commands
  // ~~~~~~

  updateSimulationItemSetting = command(Commands.UpdateSimulationItemSetting);

  // Properties
  // ~~~~~~

  @attr('string') scenarioId!: string;
  @attr('string') leadTime?: number;
  @attr('string') minOrderQty?: number;
  @attr('string') orderMultiple?: number;
  @attr() settings!: IPlanningSettingData;
  @attr() stallone!: IStalloneInfo;
  @belongsTo('simulation-item-planning', { async: false }) planning!: SimulationItemPlanning;
  @belongsTo('sku', { async: false }) sku!: SkuModel;
  @belongsTo('simulation-scenario', { async: false }) scenario!: SimulationScenario;

  // Getters & CPs
  // ~~~~~~

  get stalloneStatus() {
    return this.stallone.status;
  }

  get stalloneLastSuggestion() {
    return this.stallone.lastSuggestion;
  }

  get stalloneLastStats() {
    return this.stallone.lastStats;
  }

  get skuId() {
    return this.sku.id;
  }

  get partCode() {
    return this.sku.part.code;
  }

  get skuDescription() {
    return this.sku.skuDescription;
  }

  get isBuffered() {
    return this.settings.methodology === 'BUFFER';
  }

  get isManaged() {
    return this.settings.methodology && this.settings.methodology !== 'NONE';
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'simulation-item-setting': SimulationItemSetting;
  }
}
