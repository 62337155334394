import moment, { Moment } from 'moment-timezone';
import { parseNumber } from 'volta/utils/math-utils';

type TDate = moment.Moment | Date | string;

export const TIMESTAMPS_FORMATS = [
  moment.defaultFormatUtc,
  moment.defaultFormat,
  'YYYY-MM-DD HH:mm:ss',
  'YYYY-MM-DD HH:mm:ssZ',
  'YYYY-MM-DD HH:mm:ss.SSS',
  'YYYY-MM-DD HH:mm:ss.SSSZ',
  'YYYY-MM-DD HH:mm:ss.SSSSSS',
  'YYYY-MM-DD HH:mm:ss.SSSSSSZ',
  'YYYY-MM-DD h:mm:ss A',
  'YYYY-MM-DD h:mm:ss a',
  'YYYY-MM-DD h:mm:ss A Z',
  'YYYY-MM-DD h:mm:ss a Z',
  'YYYY-MM-DDTHH:mm:ss',
  'YYYY-MM-DDTHH:mm:ssZ',
  'YYYY-MM-DDTHH:mm:ss.SSS',
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'YYYY-MM-DDTHH:mm:ss.SSSSSS',
  'YYYY-MM-DDTHH:mm:ss.SSSSSSZ'
];

/**
 * Get all weeks from a given month and year
 *
 * @param year Year
 * @param month Month from 0 to 11
 * @returns A list of week numbers
 */
export function getWeeksOfYearMonth(year: string | number, month: string | number) {
  const parsedMonth = parseNumber(month);
  if (typeof parsedMonth === 'undefined') {
    return [];
  }

  const weeksByMonths = getEveryYearWeek(year).reduce((acc: Record<number, number[]>, w) => {
    const month = moment(w).month();
    acc[month] = [...(acc[month] ?? []), moment(w).utc().isoWeek()];
    return acc;
  }, {});

  return weeksByMonths[+month] ?? [];
}

export function getEveryYearWeek(year: string | number) {
  const parsedYear = parseNumber(year);
  const weeks: Date[] = [];
  const frMoment = moment();
  frMoment.locale('fr');
  if (typeof parsedYear !== 'undefined') {
    const lastWeek = frMoment
      .utc()
      .year(+year)
      .weeksInYear();

    for (let week = 1; week <= lastWeek; week++) {
      let date = frMoment
        .utc()
        .year(+year)
        .isoWeek(week)
        .isoWeekday(1);

      if (week === 1) {
        while (date.year() !== +year) {
          date.add(1, 'day');
        }
      }

      weeks.push(date.toDate());
    }
  }

  return weeks;
}

export function getDuration(
  start: Date | Moment | undefined,
  end: Date | Moment | undefined,
  symbolNull?: string | number
) {
  if (!start || !end) {
    return symbolNull ? `${symbolNull}` : '';
  }

  const ms = moment(end).diff(moment(start));
  const timeImport = moment.duration(ms);

  if (ms < 1000) {
    return '< 1s.';
  }
  if (ms >= 86400000) {
    return '> 24h.';
  }

  const hours = timeImport.hours() < 10 ? '0' + timeImport.hours() : timeImport.hours();
  const mn = timeImport.minutes() < 10 ? '0' + timeImport.minutes() : timeImport.minutes();
  const sec = timeImport.seconds() < 10 ? '0' + timeImport.seconds() : timeImport.seconds();

  return hours + ':' + mn + ':' + sec;
}

export function getGreetingTime(date: Date | string | Moment = new Date()) {
  const m = moment(date);
  let g = null; // return g

  if (!m || !m.isValid()) {
    return;
  } // if we can't find a valid or filled moment, we return.

  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  const currentHour = parseFloat(m.format('HH'));

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    g = 'afternoon';
  } else if (currentHour >= splitEvening) {
    g = 'evening';
  } else {
    g = 'morning';
  }

  return g;
}

/**
 * Checks if a string value is a valid JS date
 * @param date String date value to check
 * @param formats The strict formats to check, by default it will check 'YYYY-MM-DD'
 * @returns true if valid
 */
export function isDate(date: moment.MomentInput, formats: string[] = ['YYYY-MM-DD', 'L']): boolean {
  return moment(date, formats, true).isValid();
}

/**
 * This function validates a date by parsing usually a string value and trying to match on given formats
 * When the value cannot be parsed, undefined is returned, otherwise the Date value.
 *
 * @param value Date value to parse, it can be any type readable by Moment (string, number, Date)
 * @param formats Formats hints to try given to the Moment parsing function
 * @returns The value as a Date object if valid, undefined otherwise.
 */
export const validDateOrUndefined = (
  value?: moment.MomentInput,
  formats: string[] = ['YYYY-MM-DD', 'L']
): Date | undefined => {
  const m = moment(value, formats, true);
  return value && m.isValid() ? m.toDate() : undefined;
};

/**
 * This function evaluates whether a given date can be considered a weekend day.
 * The list of week-end days must be supplied otherwise it will always return false as no week-end days is considered.
 *
 * @param date Value to be parsed as date
 * @param weekendDays 1-indexed array of weekday number that are considered week-end days (eg. [6,7] for Saturday and Sunday)
 * @returns True if the date is a week-end, false otherwise
 */
export const isWeekendDay = (date: TDate, weekendDays?: number[]): boolean => {
  return (weekendDays && weekendDays.includes(moment(date).isoWeekday())) ?? false;
};

/**
 * From: https://github.com/moment/moment/issues/3341
 * This function format a localized format with days and month.
 * Also, it removes the year in the formatting if it's current.
 *
 * @param date Value to format
 * @returns A localized format with days, months, and year when not current
 * @example '23/03'
 * @example '02/12/2024'
 */
export const localizedFormatMD = (date: moment.Moment) =>
  date.format('L').replace(new RegExp('[^.]?' + date.format('YYYY') + '.?'), '');
