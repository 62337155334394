import PlanningSetting from './planning-setting';
import { IPlanningSettingData } from './types/planning-settings';

const ZERO: IPlanningSettingData = {
  leadTimeFactor: 1.0,
  varFactor: 1.0,
  aduType: 'ROLLING',
  aduHorizon: 0,
  discontinuedDemand: false,
  orderSpikeHorizon: 0,
  orderSpikeHorizonUnit: 'DAYS',
  orderSpikeLtHorizon: 0,
  orderSpikeLtHorizonUnit: 'DAYS',
  demandHorizon: 0.0,
  isProtected: false,
  dafGreenMonthly: {},
  dafGreenWeekly: {},
  dafLtMonthly: {},
  dafLtWeekly: {},
  dafMonthly: {},
  dafRedBaseMonthly: {},
  dafRedBaseWeekly: {},
  dafRedSafetyMonthly: {},
  dafRedSafetyWeekly: {},
  dafWeekly: {},
  dafYellowMonthly: {},
  dafYellowWeekly: {},
  dafUnit: 'PERCENT',
  dafRedSafetyUnit: 'PERCENT',
  dafRedBaseUnit: 'PERCENT',
  dafYellowUnit: 'PERCENT',
  dafGreenUnit: 'PERCENT',
  dafLtUnit: 'PERCENT',
};
export default class UpdatePlanningSettingsCmd {
  skuId?: string;
  skuIds?: string[];
  settings: IPlanningSettingData = ZERO;

  constructor(ps?: PlanningSetting) {
    if (!ps) {
      return this;
    }

    this.skuId = ps.skuId;
    this.settings = {
      ...ps.settings,
      varFactor: ps.bufferProfile?.varFactor ?? ps.settings.varFactor,
      leadTimeFactor: ps.bufferProfile?.leadTimeFactor ?? ps.settings.leadTimeFactor,
      discontinuedDemand: ps.actualDiscontinuedDemand,
      dafUnit: ps.dafProfile?.dafUnit ?? ps.settings.dafUnit,
      dafRedBaseUnit: ps.dafProfile?.dafRedBaseUnit ?? ps.settings.dafRedBaseUnit,
      dafRedSafetyUnit: ps.dafProfile?.dafRedSafetyUnit ?? ps.settings.dafRedSafetyUnit,
      dafYellowUnit: ps.dafProfile?.dafYellowUnit ?? ps.settings.dafYellowUnit,
      dafGreenUnit: ps.dafProfile?.dafGreenUnit ?? ps.settings.dafGreenUnit,
      dafLtUnit: ps.dafProfile?.dafLtUnit ?? ps.settings.dafLtUnit,
      dafGreenMonthly: ps.dafProfile?.dafGreenMonthly ?? ps.settings.dafGreenMonthly,
      dafGreenWeekly: ps.dafProfile?.dafGreenWeekly ?? ps.settings.dafGreenWeekly,
      dafLtMonthly: ps.dafProfile?.dafLtMonthly ?? ps.settings.dafLtMonthly,
      dafLtWeekly: ps.dafProfile?.dafLtWeekly ?? ps.settings.dafLtWeekly,
      dafMonthly: ps.dafProfile?.dafMonthly ?? ps.settings.dafMonthly,
      dafRedBaseMonthly: ps.dafProfile?.dafRedBaseMonthly ?? ps.settings.dafRedBaseMonthly,
      dafRedBaseWeekly: ps.dafProfile?.dafRedBaseWeekly ?? ps.settings.dafRedBaseWeekly,
      dafRedSafetyMonthly: ps.dafProfile?.dafRedSafetyMonthly ?? ps.settings.dafRedSafetyMonthly,
      dafRedSafetyWeekly: ps.dafProfile?.dafRedSafetyWeekly ?? ps.settings.dafRedSafetyWeekly,
      dafWeekly: ps.dafProfile?.dafWeekly ?? ps.settings.dafWeekly,
      dafYellowMonthly: ps.dafProfile?.dafYellowMonthly ?? ps.settings.dafYellowMonthly,
      dafYellowWeekly: ps.dafProfile?.dafYellowWeekly ?? ps.settings.dafYellowWeekly
    };
  }

  toNative(): object {
    return JSON.parse(JSON.stringify(this));
  }
}
