import { attr, belongsTo, hasMany } from '@ember-data/model';

import BaseModel from '../models/base-model';
import type { TAcquisitionCode } from './constants/acquisition-codes';
import type { TPlanningMethodology } from './types/planning-settings';
import type { IAggTasksViewMap } from './types/tasks';

export const ResourceName = {
  singular: 'orderSyncAlert',
  plural: 'orderSyncAlerts'
};

type TOrderSyncAlertSku = {
  skuId: string;
  warehouseId: string;
  warehouseCode: string;
  warehouseDescription?: string;
  partCode: string;
  skuDescription?: string;
  partUnitMeasure: string;
  acqCode: TAcquisitionCode;
  methodology: TPlanningMethodology;
  isProtected: boolean;
  leadTime: number;
  voltaLeadTime?: number;
};

export default class OrderSyncAlert extends BaseModel {
  @attr('array') orderIds!: string[];
  @attr('array') planningOrderId!: string[];
  @attr('string') parentSkuId?: string;
  @attr() sku!: TOrderSyncAlertSku;
  @attr('date') dueDate!: Date;
  @attr('number') qty!: number;
  @attr('number') demandQty?: number;
  @attr('number') supplyQty?: number;
  @attr('number') balance?: number;
  @attr('number') bufferPenetration?: number;
  @attr('number') bufferZone?: number;
  @attr() tasks?: IAggTasksViewMap;

  @belongsTo('order-sync-alert', { async: false, inverse: 'childNodes' })
  parentNode?: OrderSyncAlert;

  @hasMany('order-sync-alert', { async: false, inverse: 'parentNode' })
  childNodes!: OrderSyncAlert[];

  get children() {
    return this.childNodes ? this.childNodes.toArray() : [];
  }

  get hasChildren() {
    const { childNodes = [] } = this;
    return childNodes.length > 0;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'order-sync-alert': OrderSyncAlert;
  }
}
