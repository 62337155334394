import { Queries } from 'volta/models/execution-alert';
import TsBaseService from 'volta/services/ts-base-service';

export default class ExecutionAlertsTsData extends TsBaseService {
  domain = 'execution-alert';
  method = Queries.AlertsTs;
  domainIdKey = 'skuId';
}

declare module '@ember/service' {
  interface Registry {
    'execution-alerts-ts-data': ExecutionAlertsTsData;
  }
}
