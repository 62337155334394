import moment from 'moment-timezone';
import StoreService from 'volta/services/store';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import type Task from 'volta/models/task';
import type { IBvTableBaseCellArgs } from 'volta/components/cell/base-cell';

export default class BvTableTaskCell extends Component<IBvTableBaseCellArgs> {
  @service store!: StoreService;

  @tracked scrollToDate?: string;
  @tracked tasks: Task[] = [];

  get count() {
    return this.tasksWithSameDueDate.length;
  }

  get criticality() {
    const ranks = ['success', undefined, 'warning', 'danger'];
    const bestCriticality = this.tasksWithSameDueDate.reduce((acc, task) => {
      const rank = ranks.indexOf(task.todoCriticality || undefined);
      if (rank > acc) {
        acc = rank;
      }
      return acc;
    }, 0);
    return [undefined, undefined, 'danger', 'danger'][bestCriticality] as 'hud' | 'danger';
  }

  get tasksWithSameDueDate() {
    if (this.scrollToDate) {
      const formattedDueDate = moment(this.scrollToDate).format('L');
      return this.tasks.filter((task) => {
        if (!task) {
          return false;
        } else {
          if (!task.dueDate || task.resolvedAt) {
            return false;
          }
          return moment(task.dueDate).format('L') === formattedDueDate;
        }
      });
    }
    return [];
  }

  @action
  update(tasks: Task[], date: string) {
    this.tasks = tasks;
    this.scrollToDate = date;
  }
}
