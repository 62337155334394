import move from 'ember-animated/motions/move';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import fade from 'ember-animated/transitions/fade';
import { toLeft, toRight, toDown, toUp } from 'ember-animated/transitions/move-over';
import { easeOut, easeIn } from 'ember-animated/easings/cosine';
import { parallel } from 'ember-animated';

import Component from '@glimmer/component';

import type TransitionContext from 'ember-animated/-private/transition-context';
import { Transition } from 'ember-animated/-private/transition';

interface IArgs {
  contained?: boolean;
  tag?: string;
}

interface IRulesArgs<I = unknown> {
  firstTime: boolean;
  oldItems: I[];
  newItems: I[];
}

export default class BvAnimated extends Component<IArgs> {
  get transitions() {
    return {
      fade,
      toLeft,
      toRight,
      toDown,
      toUp,
      horizontalList: this.horizontalList,
      verticalList: this.verticalList
    };
  }

  get rules(): Record<string, (args: IRulesArgs) => Transition> {
    return {
      horizontalSlide: this.horizontalSlideRules,
      counter: this.counterRules
    };
  }

  horizontalSlideRules({ newItems }: IRulesArgs) {
    if (newItems[0]) {
      return toRight;
    } else {
      return toLeft;
    }
  }

  counterRules({ oldItems, newItems }: IRulesArgs<number>) {
    if (oldItems[0] < newItems[0]) {
      return toUp;
    } else {
      return toDown;
    }
  }

  *horizontalList({ keptSprites, removedSprites, insertedSprites }: TransitionContext) {
    for (const sprite of insertedSprites) {
      sprite.startTranslatedBy(-sprite.finalBounds!.width / 2, 0);
      // @ts-ignore
      parallel(move(sprite, { easing: easeOut }), fadeIn(sprite, { easing: easeOut }));
    }

    for (const sprite of keptSprites) {
      move(sprite, { easing: easeIn });
    }

    for (const sprite of removedSprites) {
      fadeOut(sprite, { easing: easeIn });
    }
  }

  *verticalList({ keptSprites, insertedSprites, removedSprites }: TransitionContext) {
    for (const sprite of insertedSprites) {
      sprite.startTranslatedBy(0, -sprite.finalBounds!.height / 2);
      // @ts-ignore
      parallel(move(sprite, { easing: easeOut }), fadeIn(sprite, { easing: easeOut }));
    }

    for (const sprite of keptSprites) {
      move(sprite, { easing: easeIn });
    }

    for (const sprite of removedSprites) {
      fadeOut(sprite, { easing: easeIn });
    }
  }
}
