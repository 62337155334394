import { get } from '@ember/object';
import Component from '@glimmer/component';

import type { IBvTableColumn, IBvTableRow } from 'volta/components/bv-table';
export interface IBvTableBaseCellArgs {
  cell: any;
  row: IBvTableRow;
  column: IBvTableColumn;
  isFooter?: boolean;
}

export interface IBvTableBaseCellApi<T> {
  cell: any;
  model: T;
  value: unknown;
  column: IBvTableColumn;
  isSelected: boolean;
  scrollToRow: (rowModelId: string) => void;
}

export default class BvTableBaseCell extends Component<IBvTableBaseCellArgs> {
  get cell() {
    return this.args.cell;
  }

  get model() {
    return this.args.row.api.rowValue;
  }

  get isSelected() {
    return Boolean(this.args.row.api.rowMeta?.isSelected);
  }

  get value(): unknown {
    const { format, footerFormat, valuePath } = this.args.column;

    if (this.args.isFooter && typeof footerFormat === 'function') {
      return footerFormat(this.model, this.args.row);
    }

    if (!this.args.isFooter && typeof format === 'function') {
      return format(this.model, this.args.row);
    }

    return get(this.model, valuePath);
  }
}
