import { TScheduleGroup, TScheduleService } from '../schedule';

export const SCHEDULE_SERVICES: Array<{
  key: TScheduleService;
  label: string;
  disableEdit?: boolean;
}> = [
  { key: 'PlanningCalculation', label: 'schedulesPage.services.PlanningCalculation' },
  { key: 'OrderExport', label: 'schedulesPage.services.OrderExport' },
  { key: 'ImportConnector', label: 'schedulesPage.services.ImportConnector', disableEdit: true},
  { key: 'LambdaRunner', label: 'schedulesPage.services.LambdaRunner' }
];

export const ARGS_BY_SERVICE = {
  PlanningCalculation: [{ label: 'warehouses', value: 'warehouseIds' }],
  OrderExport: [{ label: 'nbHoursBack', value: 'nbHoursBack' }],
  ImportConnector: [{ label: 'steps', value: 'steps' }],
  LambdaRunner: [
    { label: 'functionName', value: 'functionName' },
    { label: 'payload', value: 'payload' }
  ]
};

export const GROUPS_BY_SERVICE: Record<TScheduleService, TScheduleGroup> = {
  PlanningCalculation: 'Planning',
  OrderExport: 'Export',
  ImportConnector: 'Import',
  LambdaRunner: 'Custom'
};
