import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

type TBoolStr = 'true' | 'false';

interface IBooleanFilterArgs extends IFilterArgs<undefined> {}
export default class BooleanFilter extends Component<IBooleanFilterArgs> {
  @tracked value?: TBoolStr = this.getValueAsString();

  getValueAsString(): TBoolStr | undefined {
    const { values = [] } = this.args;
    return values.length ? (values[0].toString() as TBoolStr) : undefined;
  }

  @action
  valueDidChange(newValue: TBoolStr) {
    this.value = newValue === this.value ? undefined : `${newValue}`;
    this.args.onChange?.(this.value ? [this.value] : []);
  }

  @action
  setValue() {
    const val = this.getValueAsString();
    if (val !== this.value) {
      this.value = val;
    }
  }
}
