export type TLeadTimeCategory = 'SHORT' | 'MEDIUM' | 'LONG';

const LeadTimeCategories: Record<TLeadTimeCategory, string> = {
  SHORT: 'leadTimeCategories.short',
  MEDIUM: 'leadTimeCategories.medium',
  LONG: 'leadTimeCategories.long'
};

export const LeadTimeCategoriesOptions = Object.keys(LeadTimeCategories).map(
  (key: TLeadTimeCategory) => {
    return { value: key, key, label: LeadTimeCategories[key] };
  }
);

export default LeadTimeCategories;
