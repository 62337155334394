import { getResponsiveProps, ResponsiveProp, SpaceScale } from '@bevolta/design-tokens';
import Component from '@glimmer/component';

type Align = 'start' | 'center' | 'end' | 'space-around' | 'space-between' | 'space-evenly';

type BlockAlign = 'start' | 'center' | 'end' | 'baseline' | 'stretch';

type Gap = ResponsiveProp<SpaceScale>;

interface IArgs {
  /** Horizontal alignment of children */
  align?: Align;
  /** Vertical alignment of children */
  blockAlign?: BlockAlign;
  /** The spacing between elements. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * @example
   * gap='2'
   * gap={{xs: '2', sm: '3', md: '4', lg: '5', xl: '6'}}
   */
  gap?: Gap;
  /** Wrap stack elements to additional rows as needed on small screens
   * @default true
   */
  wrap?: boolean;
}

export default class BvInline extends Component<IArgs> {
  get style() {
    const { align = 'start', blockAlign, wrap = true, gap } = this.args;
    return {
      '--bv-inline-align': align,
      '--bv-inline-block-align': blockAlign,
      '--bv-inline-wrap': wrap ? 'wrap' : 'nowrap',
      ...getResponsiveProps('inline', 'gap', 'spacing', gap)
    };
  }
}
