import config from 'volta/config/environment';

import * as Sentry from '@sentry/ember';

const { enabled, dsn, environment } = config.sentry;

export const initSentry = () => {
  Sentry.init({
    enabled,
    dsn,
    environment,
    release: 'volta@' + config.APP.version.split('+').shift(),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production,
    tracesSampleRate: 1.0,
    beforeBreadcrumb(breadcrumb) {
      // To avoid too long JSON messages (Error 412)
      if (breadcrumb.message?.startsWith('DEPRECATION:')) {
        return null;
      }
      return breadcrumb;
    },

    beforeSend(event, hint) {
      const error = hint?.originalException;
      // ignore aborted route transitions from the Ember.js router
      if ((error as Error)?.name === 'TransitionAborted') {
        return null;
      }

      return event;
    }
  });
};
