import moment from 'moment-timezone';
import BaseModel from 'volta/models/base-model';
import { _normalize, collectionCommand, resourceCommand as command } from 'volta/utils/api';

import { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

import SimulationScenario from './simulation-scenario';
import {
  ICreateSimulationCmd,
  IDuplicateSimulationCmd,
  IUpdateSimulationName,
  TScenarioStatus
} from './types/simulation';
import User from './user';

export const ResourceName = {
  singular: 'simulation',
  plural: 'simulations'
};

export const SimulationCommands = {
  UpdateSimulationName: 'UpdateSimulationName',
  CreateSimulation: 'CreateSimulation',
  DuplicateSimulation: 'DuplicateSimulation'
};

const START_DATE = () => moment().subtract(1, 'years').toDate();
const END_DATE = () => new Date();

export default class Simulation extends BaseModel {

  // TODO - Remove when investigation on
  // why it only disappears on Simulation model is done
  static modelName = 'simulation';

  /**
   * Collection commands
   */
  static createSimulation = collectionCommand<ICreateSimulationCmd>(
    SimulationCommands.CreateSimulation,
    {
      after: _normalize
    }
  );

  static duplicateSimulation = collectionCommand<IDuplicateSimulationCmd>(
    SimulationCommands.DuplicateSimulation,
    {
      after: _normalize
    }
  );

  @attr('string') name!: string;
  @attr('string') description?: string;
  @attr('string', { defaultValue: 'READY' }) status?: TScenarioStatus;

  @attr('date', { defaultValue: START_DATE })
  startDate!: Date;

  @attr('date', { defaultValue: END_DATE })
  endDate!: Date;

  @attr('number', { defaultValue: () => 0 })
  skuCount!: number;

  @attr('array') forecastTypes?: string[];

  @belongsTo('user', { async: false })
  user!: User;

  // Here we're using a tracked property instead of a @hasMany
  // relationship because we intend to manage scenarios
  // manually
  @tracked scenarios: SimulationScenario[] = [];

  get refScenario() {
    return this.scenarios.find((item) => item.isRef);
  }

  get actualScenarios() {
    return this.scenarios.filter((scenario) => !scenario.isRef);
  }

  get isCalculating() {
    return this.status === 'CALCULATING';
  }

  get isReady() {
    return this.status === 'READY';
  }

  /**
   * Commands
   */
  updateSimulationName = command<IUpdateSimulationName>(SimulationCommands.UpdateSimulationName);
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    simulation: Simulation;
  }
}
