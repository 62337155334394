import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Ember from 'ember';
import BvFlashInstance, { TFlashMessageType } from 'volta/models/bv-flash';
import BvFlashService from 'volta/services/bv-flash';

// @ts-ignore
const escapeExpression = Ember.Handlebars.Utils.escapeExpression;

const TYPES: TFlashMessageType[] = ['info', 'success', 'warning', 'error'];

interface IArgs {
  flash: BvFlashInstance;
}

export default class BvFlash extends Component<IArgs> {
  @service declare bvFlash: BvFlashService;

  @tracked paused = false;

  // Set icon depending on notification type
  get flashIcon() {
    const { flash } = this.args;

    switch (flash.type) {
      case 'info':
        return 'information';
      case 'success':
        return 'checkmark';
      case 'warning':
        return 'alert';
      case 'error':
        return 'close';
    }
  }

  get iconColor() {
    return this.args.flash.type === 'error' ? 'danger' : this.args.flash.type;
  }

  get processedType() {
    const { flash } = this.args;
    return flash && flash.type && TYPES.includes(flash.type)
      ? `bv-flash--${flash.type}`
      : undefined;
  }

  @action
  handleRemoveFlash() {
    this.bvFlash.remove(this.args.flash);
  }

  @action
  handleMouseDown() {
    const { flash } = this.args;
    flash.onClick?.(flash);
  }

  @action
  handleMouseEnter() {
    const { flash } = this.args;
    if (flash.autoClear) {
      this.paused = true;
      this.bvFlash.pauseAutoClear(flash);
    }
  }

  @action
  handleMouseLeave() {
    const { flash } = this.args;
    if (flash.autoClear) {
      this.paused = false;
      this.bvFlash.setupAutoClear(flash);
    }
  }
}
