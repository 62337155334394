// @ts-ignore
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import moment from 'moment-timezone';
import application from 'volta/utils/application';
import IntlService from "ember-intl/services/intl";

interface TDateOptions {
  isIsoFormat: boolean;
  isNotInPast: boolean;
  allowEmpty: boolean;
}
const intl = application?.instance?.lookup('service:intl') as IntlService;
export default function checkDate({ isIsoFormat, isNotInPast, allowEmpty }: TDateOptions) {
  return (key: string, newValue: any) => {
    const description = intl.t(key);
    const errors = [];
    if (!newValue) {
      errors.push(buildMessage('empty', { type: 'empty', context: { description } }));
      return allowEmpty || errors;
    }
    if (isIsoFormat && !moment(newValue, moment.ISO_8601).isValid()) {
      errors.push(
        buildMessage('wrongDateFormat', {
          type: 'wrongDateFormat',
          context: { description, format: 'ISO' }
        })
      );
    }

    if (
      isNotInPast &&
      moment().isAfter(moment(newValue)) &&
      moment(newValue).dayOfYear() !== moment().dayOfYear()
    ) {
      errors.push(buildMessage('notInPast', { type: 'notInPast', context: { description } }));
    }

    return !errors.length || errors;
  };
}
