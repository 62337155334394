import _deepEqual from 'lodash/isEqual';
import {
  ICvComponentArgs,
  IPOWorkbenchCollectionArgs,
  TCodeDescMode
} from 'volta/models/types/collection-view';
import { TDateField } from 'volta/models/types/planning-order';
import { DefaultSettings } from 'volta/services/collection-builder-po-groups';

import { action } from '@ember/object';
import Component from '@glimmer/component';

interface IPOWorkbenchCvArgsEditorArgs extends ICvComponentArgs<IPOWorkbenchCollectionArgs> {}

export default class POWorkbenchCvArgsEditor extends Component<IPOWorkbenchCvArgsEditorArgs> {
  get settings(): IPOWorkbenchCollectionArgs {
    return {
      showCapacity: this.args.settings.showCapacity ?? DefaultSettings.showCapacity,
      showCapacityChart: this.args.settings.showCapacityChart ?? DefaultSettings.showCapacityChart,
      dateField: this.args.settings.dateField ?? DefaultSettings.dateField,
      codeDescMode: this.args.settings.codeDescMode ?? DefaultSettings.codeDescMode,
      showSkuDesc: this.args.settings.showSkuDesc ?? DefaultSettings.showSkuDesc
    };
  }

  get showCode() {
    const { codeDescMode = ['desc'] } = this.args.settings;
    return codeDescMode.includes('code');
  }

  get showDesc() {
    const { codeDescMode = ['desc'] } = this.args.settings;
    return codeDescMode.includes('desc');
  }

  @action
  handleDateFieldChange(dateField: TDateField) {
    this.args.onChange?.({ ...this.settings, dateField });
  }

  @action
  handleShowCapacityChange(showCapacity: boolean) {
    this.args.onChange?.({
      ...this.settings,
      showCapacity
    });
  }

  @action
  handleShowCapacityChartChange(showCapacityChart: boolean) {
    this.args.onChange?.({
      ...this.settings,
      showCapacityChart
    });
  }

  @action
  handleShowSkuDescChange(showSkuDesc: boolean) {
    this.args.onChange?.({ ...this.settings, showSkuDesc });
  }

  @action
  handleCodeDescChange(codeDescMode: TCodeDescMode[]) {
    this.args.onChange?.({ ...this.settings, codeDescMode });
  }

  cdmChecked = (value: TCodeDescMode[]) => {
    return _deepEqual(value, this.settings.codeDescMode);
  };
}
