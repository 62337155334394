import { TImportTableName } from '../types/data-import';

export const ImportTableName: TImportTableName[] = [
  'part_types',
  'warehouses',
  'warehouse_calendar',
  'parts',
  'unit_conversions',
  'suppliers',
  'supplier_calendar',
  'workshops',
  'workshop_calendar',
  'workshop_capacity_exceptions',
  'inventories',
  'skus_suppliers',
  'sku_replacements',
  'sku_capacity_settings',
  'boms',
  'single_level_boms',
  'orders',
  'sku_locations',
  'transactions',
  'inventories_forecast'
];
