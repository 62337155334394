import { strNormalizeAccent } from 'volta/utils/text-utils';

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import type { TCollectionPropertyForUI } from 'volta/models/types/collection-view';

const sanitized = (str: string) => strNormalizeAccent(str).toLowerCase();
interface IArgs {
  properties: TCollectionPropertyForUI[];
  groupBy?: string[];
  onChange?: (props: TCollectionPropertyForUI[]) => void;
}

export default class CollectionViewProperties extends Component<IArgs> {
  @tracked search?: string;

  get displayedProperties() {
    const toCheck = new Set([...(this.args.groupBy ?? [])]);
    return this.args.properties.filter((p) => !p.isStatic && !toCheck.has(p.property));
  }

  get filteredProperties() {
    const { search } = this;

    if (!search || search === '') {
      return this.displayedProperties;
    }
    const _search = sanitized(search);
    return this.displayedProperties.filter((p) => p.title && sanitized(p.title).includes(_search));
  }

  checkPropertyEnabled = (
    el: HTMLElement,
    _target: HTMLElement,
    _source: HTMLElement,
    sibling: HTMLElement
  ) => {
    return (
      !el.querySelector('.collection-view-property[disabled]') &&
      !sibling.querySelector('.collection-view-property[disabled]')
    );
  };

  toggleProperty = (item: TCollectionPropertyForUI) => {
    const newProps = this.args.properties.reduce((acc, prop) => {
      const isDisabled = item.property === prop.property ? !prop.disabled : prop.disabled;
      if (!isDisabled) {
        acc.push(prop);
      }
      return acc;
    }, [] as TCollectionPropertyForUI[]);

    this.args.onChange?.(newProps);
  };

  toggleIsFixed = (item: TCollectionPropertyForUI) => {
    const newProps = this.args.properties.reduce((acc, prop) => {
      if (!prop.disabled) {
        const isFixed =
          item.property === prop.property
            ? Boolean(prop.isFixed)
              ? undefined
              : 'left'
            : prop.isFixed;
        acc.push({
          ...prop,
          isFixed
        });
      }
      return acc;
    }, [] as TCollectionPropertyForUI[]);

    this.args.onChange?.(
      newProps.sort((a, b) => {
        return Number(!!b.isFixed) - Number(!!a.isFixed);
      })
    );
  };

  reorderProperties = ([properties]: [TCollectionPropertyForUI[]]) => {
    const newProps = properties.reduce((acc, prop) => {
      if (!prop.disabled) {
        acc.push(prop);
      }
      return acc;
    }, [] as TCollectionPropertyForUI[]);

    const reorderedSet = new Set(newProps.map((p) => p.property));
    const propsWithoutReordered = this.args.properties.filter(
      (p) => !reorderedSet.has(p.property) && !p.disabled
    );

    this.args.onChange?.(newProps.concat(propsWithoutReordered));
  };
}
