import { timeout } from 'ember-concurrency';
import { restartableTask } from 'ember-concurrency';
import StoreService from 'volta/services/store';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface ITokenFilterDefinitionArgs {
  options?: string[];
  warehouseIds?: string[] | string;
  renderInplace?: string;
  horizontalPosition?: 'left' | 'right' | 'auto';
  matchTriggerWidth?: boolean;
  searchFn?: (query: object, filterValues: TFilterValues) => Promise<string[]>;
}

type ITokenFilterArgs = IFilterArgs<ITokenFilterDefinitionArgs> &
  ITokenFilterDefinitionArgs & {
    label?: string;
    placeholder?: string;
    noMatchesMessage?: string;
  };

export default class TokenFilter extends Component<ITokenFilterArgs> {
  // Services
  // ~~~~~

  @service store!: StoreService;

  // Properties
  // ~~~~~

  @tracked options = this.args.componentArgs?.options ?? [];

  // Computed Properties
  // ~~~~~

  get values() {
    return this.args.values ?? [];
  }

  get searchFn() {
    const { componentArgs, searchFn } = this.args;
    return componentArgs?.searchFn ?? searchFn;
  }

  get warehouseIds(): string[] {
    const { warehouseIds, filterValues = {} } = this.args;
    if (warehouseIds && warehouseIds.length) {
      return typeof warehouseIds === 'string' ? warehouseIds.split(',') : warehouseIds;
    } else {
      return (filterValues.warehouse?.values as string[]) ?? [];
    }
  }

  // Lifecycle Hooks
  // ~~~~~

  // Actions
  // ~~~~~

  @action
  valuesDidChange(values: string[]) {
    return this.args.onChange?.(values);
  }

  @action
  singleValueDidChange(option?: string) {
    this.args.onChange?.(option ? [option] : []);
  }

  // Methods
  // ~~~~~
  searchTask = restartableTask(async (term?: string) => {
    await timeout(600);
    const { warehouseIds, searchFn } = this;
    let query: Record<string, any> = { search: term, pageSize: 100 };

    if (warehouseIds.length) {
      query.warehouses = warehouseIds.join(',');
    }

    const items: string[] =
      typeof searchFn === 'function' ? await searchFn(query, this.args.filterValues) : [];
    this.options = items;
    return items;
  });
}
