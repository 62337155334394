import RSVP, { resolve } from 'rsvp';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action, get } from '@ember/object';
import { filterOptions, defaultMatcher } from 'ember-power-select/utils/group-utils';
import { isThenable, isFunction } from 'volta/utils/object-utils';
import { toArray } from 'volta/utils/array-utils';
import { isArray } from '@ember/array';

export default class BvSelectCreate extends Component {
  @service intl;

  @tracked showCreateOption = false;
  @tracked searchTerm;
  @tracked suggestionLabel;
  @tracked inElement;

  select;

  matcher = this.args.matcher || defaultMatcher;

  // Getters & CPs
  // ~~~~~~

  get optionsArray() {
    let { options, infiniteScroll } = this.args;
    // Because we use ember-infinity for infinite scrolling
    // we don't want to change the type of the response
    if (infiniteScroll) {
      return options;
    }
    if (!options) {
      return [];
    }
    if (isThenable(options)) {
      return options.then((value) => toArray(value));
    } else {
      return toArray(options);
    }
  }

  // Lifecycle hooks
  // ~~~~~~

  // Actions
  // ~~~~~~

  @action
  selectSearchInputWrapper() {
    this.inElement = document.querySelector('.ember-power-select-search');
  }

  @action
  handleRegisterAPI(select) {
    this.select = select;
    this.args.registerAPI?.(...arguments);
  }

  @action
  onKeydown(select, event) {
    if (event.key === 'Enter' && this.showCreateOption && !select.results.length) {
      this.args.onCreate?.(select.searchText, this.select, event);
    }

    this.args.onKeydown?.(...args);
  }

  @action
  searchAndSuggest(term, select) {
    let { infiniteScroll } = this.args;

    return RSVP.resolve(this.optionsArray).then((newOptions) => {
      this.selectSearchInputWrapper();
      if (!term || term.length === 0) {
        return newOptions;
      }

      let searchAction = this.args.search;

      if (isFunction(searchAction)) {
        return resolve(searchAction(term, select)).then((results) => {
          results = infiniteScroll ? results : toArray(results);
          this.addCreateOption(term, results);
          return results;
        });
      }

      newOptions = this.filter(newOptions, term);
      this.addCreateOption(term, newOptions);

      return newOptions;
    });
  }

  @action
  handleCreateOption(event) {
    const { searchTerm } = this;
    return this.args.onCreate?.(searchTerm, this.select, event);
  }

  @action
  onChange(selected, select, e) {
    const { loading, searchText } = select;
    if (select.loading) {
      return;
    }

    // When there are no selected options, takes this form in multiple: [[]]
    const optionsAreSelected = isArray(selected) ? !![...selected].flat().length : selected;

    if (!optionsAreSelected && searchText && this.showCreateOption && this.args.onCreate) {
      this.args.onCreate?.(searchText, select, e);
    } else {
      this.args.onChange(selected, select, e);
    }
  }

  @action
  handleClose() {
    this.searchTerm = null;
    this.suggestionLabel = null;
    this.args.onClose?.(...arguments);
  }

  // Methods
  // ~~~~~~

  shouldShowCreateOption(term, options) {
    return this.args.showCreateWhen?.(term, options) ?? true;
  }

  addCreateOption(term, results) {
    this.searchTerm = term;
    if (this.shouldShowCreateOption(term, results)) {
      this.showCreateOption = true;
      this.suggestionLabel = this.buildSuggestionLabel(term);
    } else {
      this.suggestionLabel = null;
      this.showCreateOption = false;
    }
  }

  filter(options, searchText) {
    let matcher;
    if (this.args.searchField) {
      matcher = (option, text) => this.matcher(get(option, this.searchField), text);
    } else {
      matcher = (option, text) => this.matcher(option, text);
    }
    return filterOptions(options || [], searchText, matcher);
  }

  buildSuggestionLabel(term) {
    return this.args.buildSuggestion?.(term) ?? `${this.intl.t('create')} "${term}"...`;
  }
}
