import { ModelRegistry } from 'ember-data/model';
import ApplicationAdapter from 'volta/_pods/application/adapter';

export default class SkuTaskAdapter extends ApplicationAdapter {
  pathForType<K extends keyof ModelRegistry>(_modelName: K): string {
    return 'skus/tasks';
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'sku-task': SkuTaskAdapter;
  }
}
