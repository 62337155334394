import { attr, belongsTo, hasMany } from '@ember-data/model';
import { validatePresence } from 'ember-changeset-validations/validators';
import Simulation from 'volta/models/simulation';
import SimulationItemSetting from 'volta/models/simulation-item-setting';
import { TScenarioReport, TScenarioStatus } from 'volta/models/types/simulation';
import { _normalize, collectionCommand, resourceCommand as command } from 'volta/utils/api';

import BaseModel from './base-model';

export const Validations = {
  name: [validatePresence(true)]
};

export const ScenarioResourceName = {
  singular: 'simulationScenario',
  plural: 'simulationScenarios'
};

export const Commands = {
  RunScenario: 'RunScenario',
  DuplicateScenario: 'DuplicateScenario',
  CreateScenario: 'CreateScenario',
  UpdateScenario: 'UpdateScenario'
};

export const Events = {
  SimulationScenarioCalculationDone: 'SimulationScenarioCalculationDone',
  SimulationScenarioCalculationFailed: 'SimulationScenarioCalculationFailed',
  SimulationScenarioProgress: 'SimulationScenarioProgress',
  SimulationScenarioCalculationStarted: 'SimulationScenarioCalculationStarted'
};

export default class SimulationScenario extends BaseModel {
  // Collection Commands
  // ~~~~
  static createScenario = collectionCommand(Commands.CreateScenario, { after: _normalize });
  static duplicateScenario = collectionCommand(Commands.DuplicateScenario, {
    after: _normalize
  });

  // Resource Commands
  // ~~~~

  runScenario = command(Commands.RunScenario);
  updateScenario = command(Commands.UpdateScenario);

  // Properties
  // ~~~~

  @attr('string') simulationId!: string;
  @attr('string') name!: string;
  @attr('string') description?: string;
  @attr('boolean') isRef!: boolean;
  @attr('string', { defaultValue: 'READY' }) status?: TScenarioStatus;
  @attr('boolean', { defaultValue: false }) useExplosion!: boolean;
  @attr('array') forecastTypes?: string[];

  @belongsTo('simulation', { async: false }) simulation!: Simulation;
  @hasMany('simulation-item-setting', { async: false }) itemSettings?: SimulationItemSetting[];
  @attr() stats?: TScenarioReport;

  // Getters & CPs
  // ~~~~

  get isScenarioLoading() {
    return this.isWaiting || this.isCalculating;
  }

  get cannotRun() {
    return this.isScenarioLoading || this.isDone || this.isRef;
  }

  get isCalculating() {
    return this.status === 'CALCULATING';
  }

  get isWaiting() {
    return this.status === 'WAITING';
  }

  get isReady() {
    return this.status === 'READY';
  }

  get isDone() {
    return this.status === 'DONE';
  }

  get isCalculated() {
    return this.isDone && !this.isRef;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'simulation-scenario': SimulationScenario;
  }
}
