import Component from '@glimmer/component';
import { THue } from 'volta/utils/style-utils';

type TSize = 'sm' | 'md' | 'lg';
interface IArgs {
  color?: THue;
  size?: TSize;
  subtle?: boolean;
}

const Sizes = ['sm', 'md', 'lg'];

export default class BvBadge extends Component<IArgs> {
  get colorClass() {
    const { color } = this.args;
    return color ? `bv-badge--${color}` : undefined;
  }

  get sizeClass() {
    const { size } = this.args;
    return size && Sizes.includes(size) ? `bv-badge--${size}` : undefined;
  }
}
