import { restartableTask } from 'ember-concurrency';
import moment from 'moment-timezone';
import { defaultErrorHandler } from 'volta/utils/error-utils';

import { assert } from '@ember/debug';
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type StoreService from './store';

export default class TsBaseService extends Service {
  // Services
  // ~~~~~

  @service store!: StoreService;

  // Properties
  // ~~~~~

  @tracked cache: Map<string, unknown> = new Map();
  @tracked isLoading: boolean = false;
  @tracked timePeriod: string = '1M';

  domain!: string;
  method!: string;
  data?: unknown;
  apiDateFormat: string = 'YYYY-MM-DD';
  domainIdKey?: string;

  // Computed Properties
  // ~~~~~

  get timePeriodForMoment() {
    const timePeriod = this.timePeriod;
    const period = timePeriod.slice(timePeriod.length - 1);
    const value = timePeriod.substr(0, timePeriod.length - 1);
    const valueMoment: moment.DurationInputArg1 = parseInt(value);
    let periodMoment: moment.DurationInputArg2 = 'months';
    switch (period) {
      case 'M':
        periodMoment = 'months';
        break;
      case 'Y':
        periodMoment = 'years';
        break;
      case 'D':
        periodMoment = 'days';
        break;
      case 'W':
        periodMoment = 'weeks';
        break;
    }

    return { value: valueMoment, period: periodMoment };
  }

  get startDate() {
    return moment
      .utc()
      .subtract(this.timePeriodForMoment.value, this.timePeriodForMoment.period)
      .format(this.apiDateFormat);
  }

  get endDate() {
    return moment.utc().format(this.apiDateFormat);
  }

  // Public Functions
  // ~~~~~

  load = restartableTask(async (domainId: string, timePeriod?: string, forceReload?: boolean) => {
    const { domain, method } = this;

    if (!method || !domain || !domainId) {
      assert('Prop "domain" must be defined', this.domain);
      assert('Prop "method" must be defined, it should either be "POST" or "GET"', this.method);
      return undefined;
    }

    if (timePeriod) {
      this.timePeriod = timePeriod;
    }

    const { startDate, endDate, domainIdKey } = this;
    const key = `${domainId}_${startDate}_${endDate}`;
    let cachedData = this.cache.get(key);

    if (forceReload || !cachedData) {
      const attrs: Record<string, string> = {
        startDate,
        endDate
      };

      if (domainIdKey && domainId) {
        attrs[domainIdKey] = domainId;
      }

      try {
        cachedData = await this.store.customQuery(
          domain,
          method,
          domainIdKey ? undefined : domainId,
          attrs
        ) as unknown;

        this.cache.set(key, cachedData);
      } catch (error) {
        defaultErrorHandler(error);
        throw error;
      }
    }
    this.data = cachedData;
    return this.data;
  });
}

declare module '@ember/service' {
  interface Registry {
    'ts-base-service': TsBaseService;
  }
}
