import Component from '@glimmer/component';

import { ICollectionGroupOption } from '../../../models/types/collection-view.d';

export interface ICollectionViewGroupsArgs {
  options: ICollectionGroupOption[];
  groupLimit?: number;
  groupMin?: number;
  onChange?: (groupBy: string[]) => void;
  onReorder?: (groupBy: string[]) => void;
}

export default class CollectionViewGroups extends Component<ICollectionViewGroupsArgs> {
  resourceName = { singular: 'attribute', plural: 'attributes' };

  handleSortEndAction = ([sortOptions]: Array<string[]>) => {
    this.args.onReorder?.([...sortOptions]);
  };

  get isGroupMin() {
    if (!this.args.groupMin) {
      return false;
    }

    return this.args.options.filter((option) => option.selected).length <= this.args.groupMin;
  }
}
