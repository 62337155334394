import BaseModel, { IResourceName } from 'volta/models/base-model';
import { _normalize, collectionCommand, resourceCommand as command } from 'volta/utils/api';

import { attr, hasMany } from '@ember-data/model';
import { TCustomFieldValues } from 'volta/models/types/customFieldDefinition';
import { ICreateSupplierCmd, IUpdateSupplierCmd } from 'volta/models/types/supplier';
import UserProfile from 'volta/models/user-profile';

export const SupplierResourceName: IResourceName = {
  singular: 'supplier',
  plural: 'suppliers',
  table: 'suppliers'
};

export enum SupplierEvents {
  SupplierCreated = 'SupplierCreated',
  SupplierUpdated = 'SupplierUpdated',
  SupplierDeleted = 'SupplierDeleted'
}

enum TSupplierCommands {
  CreateSupplier = 'CreateSupplier',
  UpdateSupplier = 'UpdateSupplier',
  DeleteSupplier = 'DeleteSupplier'
}

export default class Supplier extends BaseModel {
  static createSupplier = collectionCommand<ICreateSupplierCmd>(TSupplierCommands.CreateSupplier, {
    after: _normalize
  });

  deleteSupplier = command(TSupplierCommands.DeleteSupplier);
  updateSupplier = command<IUpdateSupplierCmd>(TSupplierCommands.UpdateSupplier);

  @attr('string')
  code!: string;

  @attr('string')
  description?: string;

  @attr('string')
  currency?: string;

  @attr('string')
  externalId?: string;

  @attr('array')
  closedWeekDays?: number[];

  @hasMany('user-profile', { async: false })
  supplierPortalUsers!: UserProfile[];

  /**
   * Custom fields values
   */
  @attr({
    defaultValue: function () {
      return {};
    }
  })
  customFields?: TCustomFieldValues;

  get supplierId() {
    return this.id;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    supplier: Supplier;
  }
}
