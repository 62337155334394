import { attr } from '@ember-data/model';
import { collectionCommand } from 'volta/utils/api';
import { ICmdResult } from 'volta/utils/api/jsonapi-types';

import BaseModel, { IResourceName } from './base-model';
import { IUpdateQtyForPeriodInput } from './types/forecasts';

export const DemandForecastResourceName: IResourceName = {
  singular: 'demandForecast',
  plural: 'demandForecasts'
};

export enum DemandForecastQueries {
  tsBySku = 'tsBySku'
}

export enum DemandForecastCommands {
  UpdateQtyForPeriod = 'UpdateQtyForPeriod'
}

export default class DemandForecast extends BaseModel {
  // TODO - Remove when investigation on
  // why it only disappears on Planning model is done
  static modelName = 'demandForecast';

  /**
   * Collection commands
   */
  static updateQtyForPeriod = collectionCommand<
    IUpdateQtyForPeriodInput,
    ICmdResult<{ updatedIds: string[] }>
  >(DemandForecastCommands.UpdateQtyForPeriod);

  @attr('string') inventoryId!: string; // = SKU ID
  @attr('string') forecastDate!: string;
  @attr('number') demandQty!: number;
  @attr('string') forecastType?: string;
  @attr('string') parentSkuId?: string;
  @attr('string') planningOrderId?: string;
}
// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'demand-forecast': DemandForecast;
  }
}
