import { PlanningEvents, Queries, PlanningResourceName } from 'volta/models/planning';
import { defaultErrorHandler } from 'volta/utils/error-utils';

import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type EventStreamService from './event-stream';
import type StoreService from './store';

export default class PlanningCounts extends Service {
  // Services
  // ~~~~~

  @service store!: StoreService;
  @service eventStream!: EventStreamService;

  // Properties
  // ~~~~~

  @tracked counts?: number[] = [];

  // Computed Properties
  // ~~~~~

  get critical() {
    return this.counts?.[0] ?? 0;
  }

  get high() {
    return this.counts?.[1] ?? 0;
  }

  get medium() {
    return this.counts?.[2] ?? 0;
  }

  get low() {
    return this.counts?.[3] ?? 0;
  }

  get otog() {
    return this.counts?.[4] ?? 0;
  }

  /**
   * Total number of plannings
   */
  get total() {
    return this.critical + this.high + this.medium + this.low + this.otog;
  }

  // Lifecycle Hooks
  // ~~~~~

  /**
   * On init get the plannings counts
   */
  constructor() {
    super(...arguments);
    this.eventStream.addHandler(
      PlanningResourceName.singular,
      [PlanningEvents.PlanningsCalculationDone, PlanningEvents.PlanningsGlobalCalculationDone],
      this.refresh,
      this
    );
    this.refresh();
  }

  // Helper Function
  // ~~~~~

  /**
   * Get the plannings counts by status from the backend
   * and refreshes the counts property
   * @return {Promise} A promise returned by the store
   */
  async refresh() {
    try {
      this.counts = await this.store.customQuery(PlanningResourceName.singular, Queries.CountsByBufferZone);
      return this.counts;
    } catch (error) {
      return defaultErrorHandler(error);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'planning-counts': PlanningCounts;
  }
}
