import AuthorizationService from 'volta/services/authorization';

import Helper from '@ember/component/helper';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CannotStrict extends Helper {
  @service authorization!: AuthorizationService;

  onPermissionsInit = observer('authorization.permissions', () => {
    this.recompute();
  });
  compute([right, entityType]: [string, string]) {
    return this.authorization.cannotStrict(entityType, right);
  }
}
