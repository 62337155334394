import CronValidator from 'cron-expression-validator';
import { distinct } from 'volta/utils/array-utils';

import { isArray } from '@ember/array';

export default function validateCron() {
  return (_key: string, newValue: string, _oldValue: string, _changes: any, _content: any) => {
    const expression = CronValidator.isValidCronExpression(newValue, { error: true });
    if (expression.isValid) {
      return true;
    }
    if (isArray(expression.errorMessage)) {
      return distinct(expression.errorMessage);
    }

    return expression.errorMessage;
  };
}
