import Model from 'ember-data/model';
import EmberArray from "@ember/array";
import { IJsonApiQuery } from './jsonapi-types';

export type StrictHTTPVerb = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS' | 'HEAD';
export type HTTPVerb =
  | 'GET'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'PATCH'
  | 'OPTIONS'
  | 'HEAD'
  | 'get'
  | 'post'
  | 'put'
  | 'delete'
  | 'patch'
  | 'options'
  | 'head';

export interface HTTPVerbStrictMap {
  GET: 'GET';
  POST: 'POST';
  PUT: 'PUT';
  DELETE: 'DELETE';
  PATCH: 'PATCH';
  OPTIONS: 'OPTIONS';
  HEAD: 'HEAD';
  get: 'GET';
  post: 'POST';
  put: 'PUT';
  delete: 'DELETE';
  patch: 'PATCH';
  options: 'OPTIONS';
  head: 'HEAD';
}

export function strictifyHttpVerb<K extends keyof HTTPVerbStrictMap>(
  notStrict: K
): HTTPVerbStrictMap[K] {
  return `${notStrict}`.toUpperCase() as HTTPVerbStrictMap[K];
}

export type EmberDataRequestType =
  | 'findRecord'
  | 'findAll'
  | 'query'
  | 'queryRecord'
  | 'findMany'
  | 'findHasMany'
  | 'findBelongsTo'
  | 'createRecord'
  | 'updateRecord'
  | 'deleteRecord';

export type Hook<IN, OUT> = (this: Model, payload: IN) => OUT;
export type HookClass<IN, OUT, M> = (this: M, payload: IN) => OUT | EmberArray<OUT>

export interface ApiCommand {
  _cmd: string;

  [key: string]: any;
}

export interface ResourceApiCommand extends ApiCommand {
  entityId: string;
  version: number;
}

export interface ApiCommandOptions<IN, OUT> {
  ajaxOptions?: any;
  before?: Hook<IN, any>;
  after?: Hook<any, OUT>;
  query?: IJsonApiQuery;
}

export interface ApiCollectionCommandOptions<IN, OUT, M extends typeof Model> {
  ajaxOptions?: any;
  before?: HookClass<IN, any, M>;
  after?: HookClass<any, OUT, M>;
}

export interface IHeaders {
  'X-Requested-With': string;

  [key: string]: string;
}
