import IntlService from 'ember-intl/services/intl';
import { IResourceName } from 'volta/models/base-model';
import BvMetricsService from 'volta/services/bv-metrics';
import SkuModalService from 'volta/services/sku-modal-service';
import { defaultErrorHandler, TError } from 'volta/utils/error-utils';

import Controller from '@ember/controller';
import { action } from '@ember/object';
import Transition from '@ember/routing/-private/transition';
import { inject as service } from '@ember/service';

import type { BaseRoute } from 'volta/routes/base';
export default class ProtectedController extends Controller {
  @service intl!: IntlService;
  @service bvMetrics!: BvMetricsService;
  @service skuModalService!: SkuModalService;

  route!: BaseRoute;
  resourceName!: IResourceName;

  // Query params will be set from the BaseModel model hook
  get queryParamsValues() {
    return this.route.queryParamsValues;
  }

  _onDefaultError(error: TError) {
    defaultErrorHandler(error);
  }

  trackEvent(actionName: string, label?: string, value?: any) {
    this.bvMetrics.trackAction(this, actionName, label, value);
  }

  @action
  refreshRoute(): Transition | Promise<unknown> {
    return this.route.refresh();
  }

  @action
  refreshModel(params?: any) {
    this.route.transitionTo({ queryParams: params ?? this.queryParamsValues });
  }
}
