import moment from 'moment-timezone';
import BaseModel, { IResourceName } from 'volta/models/base-model';
import {
  _normalize,
  collectionCommand,
  resourceCommand as command
} from 'volta/utils/api';

import { attr } from '@ember-data/model';

export const WorkshopCalendarDayResourceName: IResourceName = {
  singular: 'workshopCalendarDay',
  plural: 'workshopCalendarDays'
};

export enum WorkshopCalendarDayEvents {
  CalendarUpdated = 'CalendarUpdated',
  DaysReset = 'DaysReset',
  WorkshopCalendarDayReset = 'WorkshopCalendarDayReset'
}

export enum WorkshopCalendarDayCommands {
  UpdateCalendar = 'UpdateCalendar',
  ResetDays = 'ResetDays',
  ResetOne = 'ResetOne'
}

export default class WorkshopCalendarDay extends BaseModel {
  /**
   * Create or update the workshop calendar days
   * @return The list of created or updated WorkshopCalendarDay models
   */
  static updateCalendar = collectionCommand(WorkshopCalendarDayCommands.UpdateCalendar, {
    after: _normalize
  });

  /**
   * Effectively deletes the models identified by the workshop id and dates
   * @return Done
   */
  static resetDays = collectionCommand(WorkshopCalendarDayCommands.ResetDays);

  /**
   * Effectively deletes the model identified by the entityId
   * @return Done
   */
  resetOne = command(WorkshopCalendarDayCommands.ResetOne);

  @attr('string') workshopId!: string;
  @attr('date') date!: Date;
  @attr('boolean') openForDemand!: boolean;
  @attr('boolean') openForReception!: boolean;

  get formattedDate() {
    return moment(this.date).format('YYYY-MM-DD');
  }

  get indexedDay() {
    const { openForDemand, openForReception } = this;
    return {
      [moment(this.date).format('YYYY-MM-DD')]: {
        openForDemand,
        openForReception
      }
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'workshop-calendar-day': WorkshopCalendarDay;
  }
}
