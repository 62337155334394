import { guidFor } from '@ember/object/internals';
import { EmberRunTimer } from '@ember/runloop/types';
import { tracked } from '@glimmer/tracking';

export type TFlashMessageType = 'info' | 'success' | 'warning' | 'error';

export interface IFlashMessageOptions {
  message?: string;
  type?: TFlashMessageType;
  autoClear?: boolean;
  clearDuration?: number;
  onClick?: (flash: BvFlashInstance) => void;
  htmlContent?: boolean;
  component?: any;
}

export interface IFlashInstanceOptions extends IFlashMessageOptions {
  dismiss?: boolean;
  remaining?: number;
  startTime?: number;
  timer?: EmberRunTimer;
}

export default class BvFlashInstance {
  type: TFlashMessageType;
  id: string;
  @tracked message?: string;
  @tracked dismiss?: boolean;
  @tracked autoClear?: boolean;
  @tracked clearDuration?: number;
  @tracked remaining?: number;
  @tracked startTime?: number;
  @tracked timer?: EmberRunTimer;
  @tracked onClick?: (flash: BvFlashInstance) => void;
  @tracked htmlContent?: boolean;

  constructor(attrs?: IFlashInstanceOptions) {
    this.id = guidFor(this);
    this.message = attrs?.message;
    this.type = attrs?.type ?? 'info';
    this.dismiss = attrs?.dismiss;
    this.autoClear = attrs?.autoClear;
    this.clearDuration = attrs?.clearDuration;
    this.remaining = attrs?.remaining;
    this.startTime = attrs?.startTime;
    this.timer = attrs?.timer;
    this.onClick = attrs?.onClick;
    this.htmlContent = attrs?.htmlContent;
  }
}
