export type TVarCategory = 'LOW' | 'MEDIUM' | 'HIGH' | 'NONE';

const VarCategories: Record<TVarCategory, string> = {
  LOW: 'varCategories.low',
  MEDIUM: 'varCategories.medium',
  HIGH: 'varCategories.high',
  NONE: 'none'
};

export const VarCategoriesNullableOptions = Object.keys(VarCategories).map((key: TVarCategory) => {
  return { value: key, key, label: VarCategories[key] };
});

export const VarCategoriesOptions = VarCategoriesNullableOptions.filter((o) => o.value !== 'NONE');

export default VarCategories;
