import Ember from 'ember';
import { task } from 'ember-concurrency';
import groupBy from 'lodash/groupBy';
import CustomFieldDefinition, {
  buildCFApiParamPrefix,
  buildCustomFieldFilter,
  CustomFieldDefinitionResourceName
} from 'volta/models/custom-field-definition';
import { defaultErrorHandler } from 'volta/utils/error-utils';
import { inFilter } from 'volta/utils/filters-utils';

import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type { ICollectionProperty } from 'volta/models/types/collection-view';
import type { CFTblName } from 'volta/models/types/customFieldDefinition';
import type EventStreamService from 'volta/services/event-stream';
import type StoreService from 'volta/services/store';
import type SessionUserService from 'volta/services/session-user';

const EntityTables: CFTblName[] = ['inventories', 'suppliers', 'workshops', 'planning_orders'];

export default class CustomFieldDefinitionsService extends Service {
  // Services
  @service store!: StoreService;
  @service eventStream!: EventStreamService;
  @service sessionUser!: SessionUserService;

  @tracked definitions: Partial<Record<CFTblName, CustomFieldDefinition[] | undefined>> = {};

  constructor() {
    super(...arguments);

    // Init definitions state
    EntityTables.forEach((e: CFTblName) => (this.definitions[e] = undefined));
  }

  async fetchSkusDefinitions() {
    return await this.task.perform(['inventories'], false);
  }

  async fetchSuppliersDefinitions() {
    return await this.task.perform(['suppliers'], false);
  }

  async fetchWorkshopsDefinitions() {
    return await this.task.perform(['workshops'], false);
  }

  async fetchPODefinitions() {
    return await this.task.perform(['planning_orders', 'inventories'], false);
  }

  get skusDefinitions() {
    return getAuthorisedDefinitions(
      this.definitions['inventories'],
      this.sessionUser.user.warehouseIds
    );
  }

  get suppliersDefinitions() {
    return this.definitions['suppliers'] ?? [];
  }

  get workshopsDefinitions() {
    return this.definitions['workshops'] ?? [];
  }

  get poDefinitions() {
    return getAuthorisedDefinitions(
      this.definitions['planning_orders'],
      this.sessionUser.user.warehouseIds
    );
  }

  skusCustomProperties(apiPrefix?: string) {
    return customFieldsColumns(this.skusDefinitions, apiPrefix);
  }

  skusCustomFieldsFilters(apiPrefix?: string) {
    return customFieldsFilters(this.skusDefinitions, apiPrefix);
  }

  suppliersCustomFieldsFilters(apiPrefix?: string) {
    return customFieldsFilters(this.suppliersDefinitions, apiPrefix);
  }

  workshopsCustomFieldsFilters(apiPrefix?: string) {
    return customFieldsFilters(this.workshopsDefinitions, apiPrefix);
  }

  workshopsCustomProperties(apiPrefix?: string) {
    return customFieldsColumns(this.workshopsDefinitions, apiPrefix);
  }

  poCustomFieldsFilters(apiPrefix?: string) {
    return customFieldsFilters(this.poDefinitions, apiPrefix);
  }

  poCustomProperties(apiPrefix?: string) {
    return customFieldsColumns(this.poDefinitions, apiPrefix);
  }

  addOne(def: CustomFieldDefinition) {
    const tableDefinition = this.definitions[def.tblName];
    const defExists = tableDefinition?.find((d: CustomFieldDefinition) => d.id === def.id);
    if (defExists) {
      return;
    }
    this.definitions[def.tblName] = tableDefinition ? [...tableDefinition, def] : [def];
  }

  removeOne(def: CustomFieldDefinition) {
    const tableDefinition = this.definitions[def.tblName];
    if (tableDefinition?.length) {
      this.definitions[def.tblName] = tableDefinition.filter(
        (d: CustomFieldDefinition) => d.id !== def.id
      );
    }
  }

  task = task(async (tblNames: CFTblName[] = [], force: boolean = false) => {
    try {
      const tblNamesValues = tblNames.reduce(
        (acc: { cached: CFTblName[]; notCached: CFTblName[] }, tblName: CFTblName) => {
          const name = `${tblName}`.toLowerCase() as CFTblName;
          if (EntityTables.includes(name)) {
            if (!this.definitions[name]) {
              acc.notCached.push(name);
            } else {
              acc.cached.push(name);
            }
          }
          return acc;
        },
        { cached: [], notCached: [] }
      );

      if (!tblNamesValues.cached.length && !tblNamesValues.notCached.length) {
        return;
      }

      if (tblNamesValues.notCached.length) {
        const query = {
          filter: {
            tblName: inFilter(force ? tblNames : tblNamesValues.notCached)
          },
          page: { offset: 0, limit: 1000 },
          sort: 'order',
          include: 'warehouses'
        };

        const definitions: Ember.Array<CustomFieldDefinition> = await this.store.query(
          CustomFieldDefinitionResourceName.singular,
          query
        );
        this.definitions = {
          ...this.definitions,
          ...groupBy(definitions.toArray(), 'tblName')
        };
      }

      return Object.fromEntries(
        tblNames.filter((key) => key in this.definitions).map((key) => [key, this.definitions[key]])
      );
    } catch (error) {
      return defaultErrorHandler(error);
    }
  });
}

/**
 * Build custom fields filters from custom field definitions
 * @param definitions Custom field definitions
 * @param apiPrefix Api prefix
 * @returns Custom fields filters
 */
const customFieldsFilters = (definitions?: CustomFieldDefinition[], apiPrefix?: string) => {
  return (definitions ?? []).reduce((acc, def) => {
    const filter = buildCustomFieldFilter(def, apiPrefix);
    if (filter) {
      acc.push(filter);
    }
    return acc;
  }, [] as TFilterDefinition[]);
};

/**
 * Build BvTable columns from custom field definitions
 * @param definitions Custom field definitions
 * @param apiPrefix Api prefix
 * @returns Custom fields BvTable columns
 */
const customFieldsColumns = (definitions?: CustomFieldDefinition[], apiPrefix?: string) => {
  return (definitions ?? []).reduce((acc, def) => {
    if (!def) {
      return undefined;
    }

    const { name, slug, fieldType } = def;
    const propDef = {
      property: buildCFApiParamPrefix(apiPrefix) + slug,
      width: 80,
      title: name,
      dataType: fieldType,
      isCustomField: true
    };
    if (propDef) {
      acc.push(propDef);
    }

    return acc;
  }, [] as ICollectionProperty[]);
};

/**
 * Get authorised definitions checking constraints
 * @param definitions Custom field definitions
 * @param warehouseIds Warehouse ids
 * @returns  Authorised definitions
 */
const getAuthorisedDefinitions = (
  definitions: CustomFieldDefinition[] = [],
  warehouseIds: string[] = []
) => {
  if (!warehouseIds.length) {
    return definitions;
  }

  return definitions.filter((d) => {
    if ((d.warehouseIds ?? []).length) {
      return d.warehouseIds.some((id) => warehouseIds.includes(id));
    }
    return true;
  });
};

declare module '@ember/service' {
  interface Registry {
    'custom-field-definitions-service': CustomFieldDefinitionsService;
  }
}
