import { pluralize } from 'ember-inflector';
import param from 'jquery-param';
import RSVP from 'rsvp';
import Config from 'volta/config/environment';

import JSONAPIAdapter from '@ember-data/adapter/json-api';
import Store from '@ember-data/store';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';

import type DS from 'ember-data';
import type { ModelRegistry } from 'ember-data/model';
import type SessionService from 'ember-simple-auth/services/session';
import type { IHeaders } from 'volta/utils/api/types';
const DEFAULT_HEADERS: IHeaders = {
  'X-Requested-With': 'XMLHttpRequest'
};

interface UpdateRecordSnapshot<K extends keyof ModelRegistry> extends DS.Snapshot<K> {
  adapterOptions: { rehydrate?: boolean };
}

export default class ApplicationAdapter extends JSONAPIAdapter {
  // Services
  // ~~~~~

  @service declare session: SessionService;

  // Properties
  // ~~~~~

  // API config
  host = Config.api.host;
  namespace = Config.api.namespace;
  authConfig = Config['ember-simple-auth-token'];

  useFetch = true;

  // Computed Properties
  // ~~~~~

  get headers() {
    const defaultHeaders = DEFAULT_HEADERS;
    if (this.session.isAuthenticated) {
      defaultHeaders[this.authConfig.authorizationHeaderName] = this.getSessionToken();
    }
    return defaultHeaders;
  }

  // Helper Functions
  // ~~~~~

  getSessionToken() {
    return this.session.data?.authenticated?.[this.authConfig.tokenPropertyName];
  }

  getAuthenticatedHeaders() {
    const authHeaders: Record<string, string> = {};
    authHeaders[this.authConfig.authorizationHeaderName] = this.getSessionToken();
    return { ...this.headers, ...authHeaders };
  }

  buildRawURL(url: string) {
    return `${this.host}/${this.namespace}/${url}`;
  }

  buildNestedUrl(parentModelName: string, parentEntityId: string, childModelName: string) {
    return this.buildRawURL(`${parentModelName}/${parentEntityId}/${childModelName}`);
  }

  /**
   * Because dataType:json is hardcoded we are looking
   * for either a blob:true or arraybuffer:true ajax option
   * in order to set the proper data type to jQuery.
   *
   * @param url
   * @param type
   * @param options
   * @return {*}
   */
  ajaxOptions(url: string, type: string, options: any) {
    const opts: any = super.ajaxOptions(url, type, options);
    if (options) {
      if (options.arraybuffer) {
        opts.dataType = 'arraybuffer';
        opts.processData = false;
      } else if (options.blob) {
        opts.dataType = 'blob';
        opts.processData = false;
      } else if (options.binary) {
        opts.dataType = 'binary';
        opts.processData = false;
      }
    }
    return opts;
  }

  // Ember Data Adapter Hooks
  // ~~~~~

  normalizeModelName(modelName: string) {
    return modelName;
  }

  pathForType<K extends keyof ModelRegistry>(modelName: K): string {
    return pluralize(camelize(modelName as string));
  }

  urlForFindRecord<K extends keyof ModelRegistry>(
    id: string,
    modelName: K,
    snapshot: DS.Snapshot<K>
  ): string {
    let url = super.urlForFindRecord(id, modelName, snapshot);
    if (snapshot) {
      const query = (snapshot.adapterOptions as Record<string, any> | undefined)?.query;
      if (query) {
        url += '?' + param(query); // assumes no query params are present already
      }
    }
    return url;
  }

  updateRecord<K extends keyof ModelRegistry>(
    store: Store,
    type: ModelRegistry[K],
    snapshot: UpdateRecordSnapshot<K>
  ): RSVP.Promise<any> {
    if (snapshot.adapterOptions.rehydrate) {
      // @ts-ignore
      const serializer = store.serializerFor(snapshot.modelName);
      let data;
      if (typeof serializer.serializeIntoHash === 'function') {
        data = {};
        serializer.serializeIntoHash(data, type, snapshot);
      }
      data = serializer.serialize(snapshot, {});

      return RSVP.resolve({ data });
    } else {
      return super.updateRecord(store, type, snapshot);
    }
  }

  /**
   * Takes an ajax response, and returns the json payload or an error.
   */
  handleResponse(status: number, headers: {}, payload: {}, requestData: {}) {
    if (status === 401 && this.session.isAuthenticated) {
      return this.session.invalidate();
    }
    return super.handleResponse(status, headers, payload, requestData);
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    application: ApplicationAdapter;
  }
}
