import BaseModel from 'volta/models/base-model';

import { attr } from '@ember-data/model';

export const ReportResourceName = {
  singular: 'report',
  plural: 'reports'
};

export default class Report extends BaseModel {
  @attr('string') declare name: string;
  @attr('string') declare projectName: string;
  @attr('string') declare workbookName: string;
  @attr('string') declare previewImageUrl: string;
  @attr('string') declare contentUrl: string;
  @attr('string') declare createdBy: string;
  @attr('string') embeddedUrl?: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    report: Report;
  }
}
