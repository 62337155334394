import BaseModel from 'volta/models/base-model';
import SkuModel from 'volta/models/sku';
import { _normalize, collectionCommand } from 'volta/utils/api';

import { attr, belongsTo } from '@ember-data/model';
import { HookClass } from 'volta/utils/api/types';

export const TransactionResourceName = {
  singular: 'transaction',
  plural: 'transactions'
};

export const Events = {
  TransactionsByDayUpdated: 'TransactionsByDayUpdated'
};

export const Queries = {
  OrderTypes: 'orderTypes'
};

export const Commands = {
  CopyTransactionsTo: 'CopyTransactionsTo',
  UpdateManyTransactions: 'UpdateManyTransactions'
};
export default class Transaction extends BaseModel {
  // TODO - Remove when investigation on
  // why it only disappears on Planning/Transaction model is done
  static modelName = 'transaction';

  // Collection Commands
  // ~~~~

  static updateManyTransactions = collectionCommand<any, Transaction[]>(
    Commands.UpdateManyTransactions,
    {
      after: _normalize as HookClass<any, Transaction[], typeof Transaction>
    }
  );

  static copyTransactionsTo = collectionCommand(Commands.CopyTransactionsTo);

  // Attributes
  // ~~~~

  @attr('date') declare transactionTs: Date;
  @attr('string') transactionType?: string;
  @attr('number') declare qty: number;
  @attr('number') declare onHandAfter: number;
  @attr('number') skuUnitAccountingPrice?: number;
  @attr('string') orderType?: string;
  @attr('string') orderNum?: string;
  @attr('string') orderLine?: string;
  @attr('string') transactionRef?: string;
  @attr('string') externalId?: string;
  @belongsTo('sku', { async: false }) declare sku: SkuModel;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    transaction: Transaction;
  }
}
