import { TReorderStrategy } from "volta/models/types/planning-settings";

const ReorderStrategies: Record<TReorderStrategy, string> = {
  MTO: 'reorderStrategies.MTO',
  MTS: 'reorderStrategies.MTS'
}

export const ReorderStrategiesOptions = Object.keys(ReorderStrategies).map(
  (key: TReorderStrategy) => {
    return { key, label: ReorderStrategies[key] }
  }
);

export default ReorderStrategies;
