import type IntlService from 'ember-intl/services/intl';

import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import isEqual from 'lodash/isEqual';

interface IBvSelectMultiArgs {
  options: any[];
  selectedPath?: string;
  id?: string;
  searchEnabled?: boolean;
  focused?: boolean;

  renderInPlace?: boolean;
  // Disable input
  disabled?: boolean;
  // Callback when selection is changed
  onChange?: (options: any[], select: any[], event: MouseEvent) => void;
  // Callback when select is focused
  onFocus?: (event: MouseEvent) => void;
  // Callback when focus is removed
  onBlur?: (event: MouseEvent) => void;
  // Trigger btn size
  defaultSize?: 'md';
  isRequired?: boolean;
}

export default class BvSelectMulti extends Component<IBvSelectMultiArgs> {
  @service intl!: IntlService;

  id = this.args.id || `BvSelectMulti-${guidFor(this)}`;

  get searchEnabled() {
    return this.args.searchEnabled ?? true;
  }

  @tracked focused = Boolean(this.args.focused);

  // Actions
  // ~~~~~

  @action
  onChange(options: any[], select: any, event: MouseEvent) {
    const { selected = [], highlighted = [] } = select;

    this.args.onChange?.(
      // Because we force the inclusion if selectedIds some times, we need to filter out the highlighted
      isEqual(selected, highlighted) ? options.filter((o) => !isEqual(highlighted, o)) : options,
      select,
      event
    );
  }

  @action
  onFocus(event: MouseEvent) {
    this.focused = true;
    this.args.onFocus?.(event);
  }

  @action
  onBlur(event: MouseEvent) {
    this.focused = false;
    this.args.onBlur?.(event);
  }
}
