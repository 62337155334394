import { dropTask } from 'ember-concurrency';
import _isNil from 'lodash/isNil';
import moment from 'moment-timezone';
import User from 'volta/models/user';
import UserProfile from 'volta/models/user-profile';
import { localizedFormatMD } from 'volta/utils/date-utils';
import { niFilter } from 'volta/utils/filters-utils';

import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import type { TTaskResolveIf, TTaskType, UTask } from 'volta/models/types/tasks';
import type StoreService from 'volta/services/store';
import type AuthorizationService from 'volta/services/authorization';
import type { IJsonApiQuery } from 'volta/utils/api/jsonapi-types';
import type SessionUserService from 'volta/services/session-user';
import type IntlService from 'ember-intl/services/intl';
import type { IResourceName } from 'volta/models/base-model';
interface IBvTaskArgs {
  task: UTask;
  resourceName?: IResourceName;
  isSelected?: boolean;
  disabled?: boolean;
  isResolvable?: boolean;
  isDue?: boolean;
  isAssignable?: boolean;
  taskType?: TTaskType;
  /* Actions */
  onSelect?: (task?: UTask, forceDelete?: boolean) => void;
  onSubmit?: (task: UTask) => void;
  onResolve?: (task: UTask) => void;
  onDelete?: (task: UTask) => void;
}

const ResolveIfIcons = {
  MANUAL: 'checkbox-outline',
  OUT_OF_BUFFER_ALERT: 'timer-outline'
};

export default class BvTask extends Component<IBvTaskArgs> {
  @service sessionUser!: SessionUserService;
  @service intl!: IntlService;
  @service store!: StoreService;
  @service authorization!: AuthorizationService;

  @tracked assignableUsers: Array<User | UserProfile> = this.args.task.assignees;

  ResolveIfIcons = ResolveIfIcons;

  get resolveIf() {
    return this.args.task.resolveIf ?? 'MANUAL';
  }

  get readOnly() {
    return (
      this.args.disabled || !_isNil(this.args.task.resolvedAt) || _isNil(this.args.resourceName)
    );
  }

  get canUpdate() {
    return this.canCmd('UpdateTask');
  }

  get canDelete() {
    return this.canCmd('DeleteTask');
  }

  get canResolve() {
    return this.canCmd('ResolveTask');
  }

  get canReopen() {
    return this.canCmd('ReopenTask');
  }

  get badgeColor() {
    const { taskType } = this.args.task;
    switch (taskType) {
      case 'EXECUTION_TASK':
        return 'green';
      case 'PLANNING_TASK':
        return 'blue';
      default:
        return 'primary';
    }
  }

  get isOwn() {
    return this.createdBy?.id === this.sessionUser.userId;
  }

  get createdBy() {
    return this.args.task.createdBy ?? this.sessionUser.user;
  }

  get createdAtDisplay() {
    const { createdAt } = this.args.task;
    return this.displayTimeAt(createdAt);
  }

  get resolvedAtDisplay() {
    const { resolvedAt } = this.args.task;
    return this.displayTimeAt(resolvedAt);
  }

  get formattedDueDate(): string | undefined {
    const dueDate = this.args.task.dueDateAsDate;

    if (dueDate) {
      const mDueDate = moment(dueDate);

      if (!mDueDate.isSame(moment(), 'year')) {
        return mDueDate.format('MM/YYYY');
      } else if (mDueDate.isSame(moment(), 'day')) {
        return this.intl.t('today');
      } else if (mDueDate.isSame(moment().add(1, 'day'), 'day')) {
        return this.intl.t('tomorrow');
      } else if (
        mDueDate.dayOfYear() > moment().dayOfYear() &&
        mDueDate.dayOfYear() < moment().dayOfYear() + 15
      ) {
        // We can not use 'fromNow' as it will add one more day diff because of the hour difference
        return mDueDate.from(moment().startOf('day'), !this.args.isSelected);
      } else {
        return localizedFormatMD(mDueDate);
      }
    }
    return undefined;
  }

  handleBodyEnter = dropTask(async (_value: string, _id: string, event: KeyboardEvent) => {
    event.preventDefault();
    return await this.args.onSubmit?.(this.args.task);
  });

  @action
  decorateUserQuery(query: IJsonApiQuery) {
    const { filter = {} } = query;
    filter.userType = niFilter(['API', 'SUPPLIER']);
    query.filter = filter;
    return query;
  }

  @action
  changeAssignees(assigneeIds: string[], assignees: User[]) {
    this.args.task.assigneeIds = assigneeIds;
    this.assignableUsers = assignees;
  }

  @action
  changeResolution(resolveIf: TTaskResolveIf | undefined) {
    this.args.task.resolveIf = resolveIf;
    return this.args.onSubmit?.(this.args.task);
  }

  @action
  changeDueDate(dueDate: Date | undefined) {
    this.args.task.dueDate = dueDate ? moment(dueDate).format('YYYY-MM-DD') : null;
  }

  @action
  handleResolve(checked: boolean, e: InputEvent) {
    e.stopPropagation();

    later(
      () => {
        this.args.task.resolvedAt = this.args.task.resolvedAt ? null : new Date();
        this.args.onResolve?.(this.args.task);
      },
      checked ? 1000 : 500
    );
  }

  handleDelete = dropTask(async () => {
    return await this.args.onDelete?.(this.args.task);
  });

  @action
  handleClickOutside(forceDelete: boolean = false, _event: MouseEvent) {
    if (this.args.isSelected) {
      this.args.onSelect?.(undefined, forceDelete);
    }
  }

  private canCmd(cmd: string) {
    return (
      this.args.resourceName && this.authorization.canStrict(this.args.resourceName.plural, cmd)
    );
  }

  private displayTimeAt(time?: Date | null): string | undefined {
    if (!time) {
      return undefined;
    }
    const mTime = moment(time);
    const isToday = mTime.isSame(moment(), 'day');
    const isYesterday = mTime.isSame(moment().subtract(1, 'day'));
    if (isToday || isYesterday) {
      return this.intl.t(isToday ? 'today' : 'yesterday');
    } else {
      return mTime.format('L');
    }
  }
}
