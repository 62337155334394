import IntlService from 'ember-intl/services/intl';
import LabelService from 'volta/services/label-service';

import Helper from '@ember/component/helper';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';

export default class UnitMeasure extends Helper {
  @service intl!: IntlService;
  @service labelService!: LabelService;

  onLocaleChange = observer('intl.locale', () => {
    this.recompute();
  });

  compute([unit]: [string]) {
    return this.labelService.unitMeasure(unit);
  }
}
