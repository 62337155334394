import BaseModel from 'volta/models/base-model';
import { _normalize, collectionCmd } from 'volta/utils/api';

import { attr } from '@ember-data/model';

import {
  IFeatureExecution,
  IFeatureExperimental,
  IFeatureExport,
  IFeaturePlanning,
  IFeaturePOWorkbench,
  IFeatureSimulation,
  IFeatureSku,
  IFeatureWorkshop,
  THiddenFields
} from './types/app-features';

export const HIDDEN_FIELDS: THiddenFields[] = [
  'part',
  'unitMeasure',
  'partType',
  'acqCode',
  'tags',
  'customFields',
  'leadTime',
  'internalLeadTime',
  'minOrderQty',
  'orderMultiple',
  'maxPOQty',
  'warehouse',
  'supplier',
  'workshop',
  'deliveryWorkshop',
  'orderType',
  'inventoryUnitPrice',
  'salesUnitPrice',
  'leadTimeCategory',
  'varCategory'
];

export const SKU_KEYS = [
  'part_code',
  'part_description',
  'warehouse_id',
  'warehouse_code',
  'warehouse_description',
  'part_unit_measure',
  'acq_code',
  'lead_time',
  'volta_lead_time',
  'min_order_qty',
  'order_multiple',
  'inventory_unit_price',
  'sales_unit_price',
  'planner',
  'buyer',
  'internal_lead_time',
  'description',
  'max_po_qty',
  'order_type'
];

export const DEFAULT_SKU_STATS = ['criticalCount'];

export type TItemStat = 'title' | 'accepted' | 'rejected' | 'minThreshold' | 'capacity' | 'gauge';

export const StockBlockItemStats: TItemStat[] = [
  'title',
  'accepted',
  'rejected',
  'minThreshold',
  'capacity',
  'gauge'
];

export const AppFeatureResourceName = {
  singular: 'appFeature',
  plural: 'appFeatures'
};

export const AppFeatureEvents = {
  AppFeatureUpdated: 'AppFeatureUpdated'
};

enum TAppFeatureCommands {
  UpdateAppFeature = 'UpdateAppFeature'
}

export default class AppFeature extends BaseModel {
  static updateAppFeature = collectionCmd(TAppFeatureCommands.UpdateAppFeature, {
    after: _normalize
  });

  @attr()
  experimental!: IFeatureExperimental;

  @attr()
  export!: IFeatureExport;

  @attr()
  poWorkbench!: IFeaturePOWorkbench;

  @attr()
  planning!: IFeaturePlanning;

  @attr()
  execution!: IFeatureExecution;

  @attr()
  simulation!: IFeatureSimulation;

  @attr()
  sku!: IFeatureSku;

  @attr()
  workshop!: IFeatureWorkshop;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'app-feature': AppFeature;
  }
}
