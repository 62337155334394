import BaseModel from 'volta/models/base-model';
import Supplier from 'volta/models/supplier';
import { TLoginInfos } from 'volta/models/types/user';
import { TUserType } from "volta/models/constants/user-types";
import UserRole from 'volta/models/user-role';
import Warehouse from 'volta/models/warehouse';
import {
  _normalize,
  collectionCommand,
  resourceCommand as command
} from 'volta/utils/api';

import { attr, belongsTo, hasMany } from '@ember-data/model';
import EmberArray from '@ember/array';
import { isBlank } from '@ember/utils';

import type UserPreferences from 'volta/models/user-preference';
type TUserName = Pick<User, 'firstName' | 'middleName' | 'lastName' | 'login' | 'email'>;

export const getFullName = (user: TUserName) => {
  const middleName = `${user.middleName ? ' ' + user.middleName + ' ' : ''}`;
  return `${user.firstName ?? ''}${middleName} ${user.lastName ?? ''}`.trim();
};

export const getDisplayName = (user: TUserName) => {
  const fullName = getFullName(user);
  return isBlank(fullName) ? user.login : fullName;
};

export const UserCommands = {
  CreateUser: 'CreateUser',
  ResetUserPassword: 'ResetUserPassword',
  UpdateUserProfile: 'UpdateUserProfile',
  UpdateUserPassword: 'UpdateUserPassword',
  UpdateUserPreferences: 'UpdateUserPreferences',
  UpdateUser: 'UpdateUser',
  ActivateUser: 'ActivateUser',
  DeactivateUser: 'DeactivateUser',
  ResetUserPersonalAccessToken: 'ResetUserPersonalAccessToken'
};

export const UserResourceName = {
  singular: 'user',
  plural: 'users'
};

export interface IpersonalAccessToken {
  expirationDate?: string; // Format YYYY-MM-DD
  createdAt: string; // Format YYYY-MM-DD
}
export default class User extends BaseModel {
  /**
   * Collection commands
   */

  static createUser = collectionCommand(UserCommands.CreateUser, { after: _normalize });
  static resetUserPassword = collectionCommand(UserCommands.ResetUserPassword);
  static activateUser = collectionCommand(UserCommands.ActivateUser, { after: _normalize });
  static deactivateUser = collectionCommand(UserCommands.DeactivateUser, { after: _normalize });
  static resetUserPersonalAccessToken = collectionCommand(
    UserCommands.ResetUserPersonalAccessToken
  );

  /**
   * Attributes
   */

  @attr('string') login!: string;
  @attr('string') firstName?: string;
  @attr('string') middleName?: string;
  @attr('string') lastName?: string;
  @attr('string') email?: string;
  @attr('boolean', { defaultValue: false }) connected!: boolean;
  @attr('date') deletedAt: Date | null | undefined;
  @attr('string', { defaultValue: () => 'REGULAR' }) userType!: TUserType;

  @attr({
    defaultValue: () => {
      return {};
    }
  })
  loginInfos!: TLoginInfos;

  @attr()
  personalAccessToken!: IpersonalAccessToken;

  @hasMany('warehouse', { async: false })
  warehouses!: EmberArray<Warehouse>;

  @hasMany('supplier', { async: false })
  suppliers!: EmberArray<Supplier>;

  @hasMany('user-role', { async: false })
  roles!: EmberArray<UserRole>;

  @belongsTo('user-preference', { async: false })
  preferences!: UserPreferences;

  /**
   * Getters
   */

  get userId() {
    return this.id;
  }

  /**
   * Aggregated user roles permissions
   */
  get permissions() {
    return this.roles.reduce((acc: string[], role: UserRole) => {
      role.permissions.forEach((permission: string) => {
        if (!acc.includes(permission)) {
          acc.push(permission);
        }
      });
      return acc;
    }, []);
  }

  get fullName() {
    return getFullName(this);
  }

  get displayName() {
    return getDisplayName(this);
  }

  get warehouseIds() {
    return this.warehouses.map((s) => s.id).sort();
  }

  get supplierIds() {
    return this.suppliers.map((s) => s.id).sort();
  }

  get roleIds() {
    return this.roles.map((s) => s.id).sort();
  }

  get isStaff() {
    return this.userType === 'STAFF';
  }

  get isSupplier() {
    return this.userType === 'SUPPLIER';
  }

  get isActive() {
    return this.deletedAt === undefined || this.deletedAt === null;
  }

  get isInactive() {
    return !this.isActive;
  }

  /**
   * Resource commands
   */

  updateProfile = command(UserCommands.UpdateUserProfile);
  updatePassword = command(UserCommands.UpdateUserPassword);
  updateUser = command(UserCommands.UpdateUser);
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    user: User;
  }
}
