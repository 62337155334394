export type TUserType = 'REGULAR' | 'STAFF' | 'SUPPLIER' | 'API';

const UserTypes: Record<TUserType, string> = {
  REGULAR: 'userTypes.REGULAR',
  STAFF: 'userTypes.STAFF',
  SUPPLIER: 'userTypes.SUPPLIER',
  API: 'userTypes.API'
};

export const UserTypesIcons: Record<TUserType, string> = {
  REGULAR: 'person-outline',
  STAFF: 'key-outline',
  SUPPLIER: 'storefront-outline',
  API: 'hardware-chip-outline'
};

export const UserTypesOptions: TSelectOption[] = Object.keys(UserTypes).map((key: TUserType) => {
  return { key, label: UserTypes[key], icon: UserTypesIcons[key] };
});

export default UserTypes;
