import BaseModel, { IResourceName } from 'volta/models/base-model';
import { collectionCommand } from 'volta/utils/api';
import { round } from 'volta/utils/math-utils';
import { getBufferZoneCriticality } from 'volta/utils/style-utils';

import { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

import type IntlService from 'ember-intl/services/intl';
import type {
  IPlanningBuffer,
  IPlanningSku,
  IPlanningSyncAlert
} from 'volta/models/types/planning';
import type LabelService from 'volta/services/label-service';
import { tasksToAgg } from './task';
import type { TPlanningMethodology } from './types/planning-settings';
import type { IAggTasksViewMap } from './types/tasks';
import Task from './task';
import { IWorkshopIdentityInfo } from 'volta/models/types/workshop';

export const PlanningResourceName: IResourceName = {
  singular: 'planning',
  plural: 'plannings'
};

export const PlanningEvents = {
  PlanningsCalculationDone: 'PlanningsCalculationDone',
  PlanningsGlobalCalculationStarted: 'PlanningsGlobalCalculationStarted',
  PlanningsGlobalCalculationProgress: 'PlanningsGlobalCalculationProgress',
  PlanningsGlobalCalculationDone: 'PlanningsGlobalCalculationDone',
  PlanningsCalculationStarted: 'PlanningsCalculationStarted'
};

export const Commands = {
  GenerateMany: 'GenerateMany',
  SetManualReceptions: 'SetManualReceptions',
  SetReceptionsMode: 'SetReceptionsMode',
  SimulateBuffer: 'SimulateBuffer'
};

export const Queries = {
  CountsByBufferZone: 'countsByBufferZone',
  Projection: 'projection'
};

function normalizeUnitMeasure(qty?: number, unitMeasure?: string) {
  if (qty === null || qty === undefined) {
    return;
  }
  return unitMeasure && unitMeasure === 'UNIT' ? Math.ceil(qty) : qty;
}

function normalizeOrderMultiple(qty?: number, orderMultiple?: number) {
  if (qty === null || qty === undefined) {
    return;
  }
  return orderMultiple && orderMultiple > 0 ? Math.ceil(qty / orderMultiple) * orderMultiple : qty;
}

/*
 *  Calculate the overridden Reorder Quantity proposed by the user
 */
export function calculateReorderQty(
  userInput: number,
  orderMultiple: number,
  partUnitMeasure: string
) {
  return normalizeOrderMultiple(
    normalizeUnitMeasure(round(userInput, partUnitMeasure === 'UNIT' ? 0 : 3), partUnitMeasure),
    orderMultiple
  );
}

export default class PlanningModel extends BaseModel {
  // TODO - Remove when investigation on
  // why it only disappears on Planning model is done
  static modelName = 'planning';

  // Collection commands

  static generateMany = collectionCommand(Commands.GenerateMany);
  static simulateBuffer = collectionCommand(Commands.SimulateBuffer);
  static setReceptionsMode = collectionCommand(Commands.SetReceptionsMode);
  static setManualReceptions = collectionCommand(Commands.SetManualReceptions);

  @service intl!: IntlService;
  @service labelService!: LabelService;

  // Attributes
  // ~~~~

  @attr('date') inventoryDate!: Date;
  @attr('date') dueDate?: Date;
  @attr('date') deliveryDate?: Date;
  @attr('date') demandHorizonDate?: Date;
  @attr('number') adu!: number;
  @attr('number') netFlow!: number;
  @attr('number') bufferZone!: number;
  @attr('number') bufferPenetration!: number;
  @attr('number') demandQty!: number;
  @attr('number') onHand!: number;
  @attr('number') openSupplyQty!: number;
  @attr('number') demandSpikeQty!: number;
  @attr('number') demandSpikeThresholdQty!: number;
  @attr('number') coverage?: number;
  @attr('date') demandSpikeThresholdDate!: Date;
  @attr('number') reorderQty!: number;
  @attr('number') avgOnHand!: number;
  @attr('number') avgOnHandRange!: number;
  @attr('number', { defaultValue: () => 1.0 }) daf!: number;
  @attr('string') status!: string;
  @attr('string') methodology!: TPlanningMethodology;
  @attr() skuInfo!: IPlanningSku;
  @attr() deliveryWorkshopInfo?: IWorkshopIdentityInfo;
  @attr() workshopInfo?: IWorkshopIdentityInfo;
  @attr() buffer!: IPlanningBuffer;
  @attr() tasks?: IAggTasksViewMap;
  /**
   * Condensed alerts info
   */
  @attr() syncAlert?: IPlanningSyncAlert;

  // Computed Properties
  // ~~~~

  get partCode() {
    return this.skuInfo?.part.code;
  }

  get skuDescription() {
    return this.skuInfo?.part.description;
  }

  get moq() {
    return this.skuInfo?.moq;
  }

  get orderMultiple() {
    return this.skuInfo?.orderMultiple;
  }

  get isBuffered() {
    return this.methodology === 'BUFFER';
  }

  get isMRP() {
    return this.methodology === 'MRP';
  }

  get isManaged() {
    return ['BUFFER', 'MRP'].includes(this.methodology);
  }

  get priorityName() {
    return getBufferZoneCriticality(this.bufferZone);
  }

  get hasDemandSpike() {
    return Math.abs(this.demandSpikeQty) > 0;
  }

  get hasReorderQty() {
    return this.reorderQty > 0;
  }

  get leadTimes() {
    const { lt, vlt } = this.skuInfo ?? {};
    return (vlt ?? lt) + '/' + (lt ?? 'NA');
  }

  get actualVLT() {
    return this.skuInfo?.vlt ?? this.skuInfo?.lt ?? 0;
  }

  get priorityPercent() {
    return this.bufferPenetration ? this.bufferPenetration * 100 : 0;
  }

  get coverageOption() {
    const { coverage, actualVLT } = this;

    if (coverage !== undefined && coverage !== null) {
      const maxDLT = actualVLT * 3;
      let alert =
        coverage! > maxDLT ? 'orange' : coverage < 0 ? 'red' : coverage === 0 ? 'gray' : 'blue';
      const dltCount = round(actualVLT === 0 ? 0 : coverage / actualVLT, 2);
      return {
        coverage,
        alert,
        dltCount
      };
    } else {
      return undefined;
    }
  }

  /**
   * DO NOT REMOVE, it's used by the sku-details planning dashboard
   */
  get iltLabel() {
    const { ilt } = this.skuInfo;
    return ilt ? this.intl.t('internalLeadTimeValue', { lt: ilt }) : undefined;
  }

  /**
   * DO NOT REMOVE, it's used by the sku-details planning dashboard
   */
  get deliveryDateLabel() {
    const { deliveryDate, skuInfo } = this;
    return deliveryDate && skuInfo.ilt && skuInfo.ilt > 0
      ? `${this.intl.t('deliveryDate')}: ${this.intl.formatDate(deliveryDate, { timeZone: 'UTC' })}`
      : undefined;
  }

  /**
   * DO NOT REMOVE, it's used by the sku-details planning dashboard
   */
  get demandHorizonDateLabel() {
    const { demandHorizonDate } = this;
    return demandHorizonDate
      ? `${this.intl.t('demandHorizonDate')}: ${this.intl.formatDate(demandHorizonDate, {
          timeZone: 'UTC'
        })}`
      : undefined;
  }

  /**
   * DO NOT REMOVE, it's used by the sku-details planning dashboard
   */
  get unitMeasureLabel() {
    return this.labelService.unitMeasure(this.skuInfo.unitMeasure);
  }

  setTasks(tasks: Task[]) {
    this.tasks = tasksToAgg(tasks);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    planning: PlanningModel;
  }
}
