import { IJsonApiQuery } from 'volta/utils/api/jsonapi-types';
import Component from '@glimmer/component';

export interface ISkusPartTypeFilterDefinitionArgs {
  skuId?: string;
  horizontalPosition?: 'left' | 'right' | 'auto';
  beforeQuery?: (query: IJsonApiQuery) => IJsonApiQuery;
}

export default class SkusPartTypeFilter extends Component<
  IFilterArgs<ISkusPartTypeFilterDefinitionArgs>
> {}
