import { IActivePlanner } from 'volta/models/types/planning-order';
import { getDisplayName } from 'volta/models/user';
import PlanningOrderApi from 'volta/services/planning-order-api';

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';

export interface IPOUserFilterDefinitionArgs {
  multiple?: boolean;
  required?: boolean;
}

type TFilterUser = IActivePlanner & { displayName?: string };

export default class POUserFilter extends Component<IFilterArgs<IPOUserFilterDefinitionArgs>> {
  /**
   * Services
   */

  @service planningOrderApi!: PlanningOrderApi;

  /**
   * Getters
   */
  get users(): TFilterUser[] {
    return this.planningOrderApi.users.map((user: TFilterUser) => {
      user.displayName = getDisplayName(user);
      return user;
    });
  }

  get selectedUser() {
    const { values = [] } = this.args;

    if (values.length > 0) {
      return this.users.find((u) => u.userId === values[0]);
    } else {
      return undefined;
    }
  }

  get selectedUsers() {
    const { values = [] } = this.args;
    return this.users.filter((u) => values.includes(u.userId));
  }

  /**
   * Lifecycle events
   */

  constructor(owner: any, args: IFilterArgs<IPOUserFilterDefinitionArgs>) {
    super(owner, args);

    if (!this.users.length) {
      this.planningOrderApi.usersTask.perform();
    }
  }

  /**
   * Actions
   */
  @action
  handleChangeUser(value?: TFilterUser) {
    this.args.onChange?.(value ? [value.userId] : []);
  }

  @action
  handleChangeManyUsers(values: TFilterUser[]) {
    this.args.onChange?.(values.map((v) => v.userId));
  }
}
