import Helper from '@ember/component/helper';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';

import type { TOptions } from 'ember-intl/services/intl';
import type LocalizationService from 'volta/services/localization';

export default class TElseHelper extends Helper {
  onLocaleChange = observer('localization.intl.locale', () => {
    this.recompute();
  });

  @service localization!: LocalizationService;

  compute(
    [translation, fallback]: [translation: string, fallback?: string],
    options: TOptions & { htmlSafe?: false }
  ) {
    return this.localization.tOrElse(translation, fallback, options);
  }
}
