import Transform from '@ember-data/serializer/transform';
import { typeOf } from '@ember/utils';

export default class ArrayTransform extends Transform {
  deserialize(serialized: any): any[] {
    return typeOf(serialized) === 'array' ? serialized : [];
  }

  serialize(deserialized: string | any[]): any[] {
    const type: string = typeOf(deserialized);

    if (type === 'array') {
      return deserialized as any[];
    } else if (type === 'string') {
      return (deserialized as string).split(',').map((item: string) => item.trim());
    } else {
      return [];
    }
  }
}

declare module 'ember-data/types/registries/transform' {
  export default interface TransformRegistry {
    array: ArrayTransform;
  }
}
