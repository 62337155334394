import { action } from '@ember/object';
import Component from '@glimmer/component';
import { Select } from 'ember-power-select/components/power-select';
interface IArgs {
  select: Select;
}
export default class BvSelectTrigger extends Component<IArgs> {
  @action
  clear(e: MouseEvent) {
    e.stopPropagation();

    this.args.select && this.args.select.actions.select(null);

    if (e.type === 'touchstart') {
      return false;
    }
    return true;
  }
}
