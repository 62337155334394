import { strNormalizeAccent } from 'volta/utils/text-utils';

import { action } from '@ember/object';
import Component from '@glimmer/component';

import type User from 'volta/models/user';

interface IArgs {}

export default class UserSelect extends Component<IArgs> {
  @action
  matchName(person: User, term: string) {
    const sanitized = (str?: string) => strNormalizeAccent(str ?? '').toLowerCase();
    return `${sanitized(person.firstName)} ${sanitized(person.middleName)} ${sanitized(
      person.lastName
    )} ${sanitized(person.login)} ${sanitized(person.email)}`.includes(sanitized(term))
      ? 1
      : -1;
  }
}
