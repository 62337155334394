import moment from 'moment-timezone';
import { SkuQueries } from 'volta/models/sku';

import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type { TAduFrequency } from 'volta/models/types/planning-settings';
import type StoreService from 'volta/services/store';

export interface IChartOptions {
  skuId: string;
  timePeriod: string;
  aduHorizon: number;
  aduFrequency: TAduFrequency;
  aduHorizonOffset: number;
  startDate: string | moment.Moment | Date;
  endDate: string | moment.Moment | Date;
  forceReload: boolean;
}

export interface IDemandForecastTs {
  dates: string[];
  demandQty: number[];
  forecastQty: number[];
  adu: number[];
  demandVolatility: number;
}

export default class AduTsData extends Service {
  // Services
  // ~~~~~

  @service store!: StoreService;

  // Properties
  // ~~~~~

  @tracked data?: Promise<IDemandForecastTs>;
  @tracked cache: Record<string, Promise<IDemandForecastTs>> = {};
  @tracked isLoading: boolean = false;
  @tracked timePeriod: string = '12M';
  @tracked apiDateFormat: string = 'YYYY-MM-DD';
  @tracked aduFrequency: TAduFrequency = 'DAILY';
  @tracked aduHorizon: number = 1;
  @tracked aduHorizonOffset: number = 1;

  // Computed Properties
  // ~~~~~

  get timePeriodForMoment() {
    const timePeriod = this.timePeriod;
    const period = timePeriod.slice(timePeriod.length - 1);
    const value = timePeriod.substring(0, timePeriod.length - 1);
    const valueMoment = parseInt(value);
    let periodMoment = 'months';
    switch (period) {
      case 'M':
        periodMoment = 'months';
        break;
      case 'Y':
        periodMoment = 'years';
        break;
      case 'D':
        periodMoment = 'days';
        break;
      case 'W':
        periodMoment = 'weeks';
        break;
    }

    return { value: valueMoment, period: periodMoment as moment.unitOfTime.DurationConstructor };
  }

  get defaultStartDate() {
    return moment
      .utc()
      .subtract(this.timePeriodForMoment.value, this.timePeriodForMoment.period)
      .format(this.apiDateFormat);
  }

  get defaultEndDate() {
    return moment.utc().format(this.apiDateFormat);
  }

  // Lifecycle Hooks
  // ~~~~~

  // Helper Function
  // ~~~~~

  load({
    skuId,
    timePeriod,
    aduHorizon,
    aduFrequency,
    aduHorizonOffset,
    startDate,
    endDate,
    forceReload = true
  }: IChartOptions) {
    this.timePeriod = timePeriod;
    this.aduFrequency = aduFrequency;
    this.aduHorizon = aduHorizon;
    this.aduHorizonOffset = aduHorizonOffset;
    const key = `${skuId}_${timePeriod}_${aduHorizon}_${aduFrequency}_${aduHorizonOffset}`;
    let promise = this.cache[key];

    if (forceReload || !promise) {
      this.isLoading = true;
      promise = this.store
        .customQuery('sku', SkuQueries.DemandForecastTs, skuId, {
          startDate: startDate || this.defaultStartDate,
          endDate: endDate || this.defaultEndDate,
          aduHorizon,
          aduFrequency,
          aduHorizonOffset
        })
        .then((planningTs) => this._onLoadSuccess(planningTs));
    }

    this.cache[key] = promise;
    this.data = promise;
    return promise;
  }

  // Private Callbacks
  // ~~~~~

  _onLoadSuccess(planningTs: IDemandForecastTs) {
    this.isLoading = false;
    return planningTs;
  }
}

declare module '@ember/service' {
  interface Registry {
    'adu-ts-data': AduTsData;
  }
}
