import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

const SIZES = ['sm', 'md'];

interface IArgs {
  size?: 'sm' | 'md';
  toggled: boolean;
  loading: boolean;
  disabled: boolean;
  tooltip?: string;
  prefix?: string | Component;
  postfix?: string | Component;
  onToggle: () => void;
}

export default class BvToggle extends Component<IArgs> {
  // Services
  // ~~~~~

  // Properties
  // ~~~~~

  forId = `${guidFor(this)}-bv-toggle`;

  // Computed Properties
  // ~~~~~

  get sizeClass() {
    const { size = 'md' } = this.args;
    return (
      size && SIZES.includes(`${size}`.toLowerCase()) && `bv-toggle--${`${size}`.toLowerCase()}`
    );
  }
}
