import { tracked } from '@glimmer/tracking';
import Buffer from 'volta/models/buffer';
import SimulationItemPlanning from './simulation-item-planning';
import { IBuffer } from './types/planning';

export default class SimulationItemPlanningReport {
  @tracked itemPlanning!: SimulationItemPlanning;

  constructor(itemPlanning: SimulationItemPlanning) {
    this.itemPlanning = itemPlanning;
  }

  get dates() {
    return this.itemPlanning.inventoryDate;
  }
  get sku() {
    return this.itemPlanning.itemSetting.sku;
  }
  get vlt() {
    return this.itemPlanning.itemSetting.leadTime;
  }
  get stats() {
    return this.itemPlanning.stats;
  }
  get weekDays() {
    return this.stats?.weekDays;
  }

  // On hand related stats
  // ~~~~

  get onHandAvg() {
    return this.stats?.skuPerformance.avgOnHandReal;
  }

  get avgCoverage() {
    return this.stats?.skuPerformance.avgCoverageReal;
  }

  get onHandOosCount() {
    return this.stats?.supplyPerformance.stockoutsCountReal;
  }

  // On hand simulated related stats
  // ~~~~

  get onHandSimulatedAvg() {
    return this.stats?.skuPerformance.avgOnHandSimulated;
  }

  get avgCoverageSimulated() {
    return this.stats?.skuPerformance.avgCoverageSimulated;
  }

  get onHandSimulatedOosCount() {
    return this.stats?.supplyPerformance.stockoutsCountSimulated;
  }

  // Demand qty related stats
  // ~~~~

  get serviceLevelSimulated() {
    return this.stats?.demandStatistics.availabilityRate;
  }

  get serviceLevelSimulatedWithDemand() {
    return this.stats?.demandStatistics.availabilityRateWithDemand;
  }

  get demandQtySum() {
    return this.stats?.demandStatistics.totalSalesOrder;
  }
  get totalSalesOrders() {
    return this.demandQtySum;
  }

  get demandQtyAvg() {
    return this.stats?.demandStatistics.avgDemand;
  }

  get demandQtyVar() {
    return this.stats?.demandStatistics.demandVar;
  }

  get demandSpikeQtyCount() {
    return this.stats?.demandStatistics.nbOfSpikes;
  }

  // Flow Performances
  // ~~~~

  get otogCount() {
    return this.stats?.flowPerformance.nbDaysOTOG;
  }
  get targetAvg() {
    return this.stats?.flowPerformance.avgTargetOnHand;
  }
  get targetLowAvg() {
    return this.stats?.flowPerformance.avgTargetOnHandLow;
  }
  get targetHighAvg() {
    return this.stats?.flowPerformance.avgTargetOnHandHigh;
  }

  // Financial Performances
  // ~~~~

  get avgSavings() {
    return this.stats?.financialPerformance.avgSavingsValue;
  }
  get avgSavingsRatio() {
    return this.stats?.financialPerformance.avgSavingsPercent;
  }
  get cogs() {
    return this.stats?.financialPerformance.cogs;
  }

  // Sku Performances
  // ~~~~

  get skuTurnoverRatio() {
    return this.stats?.skuPerformance.turnoverRatioReal;
  }
  get skuTurnoverRatioSimulated() {
    return this.stats?.skuPerformance.turnoverRatioSimulated;
  }

  // Supply Performances
  // ~~~~

  get supplyCount() {
    return this.stats?.supplyPerformance.supplyCountReal;
  }
  get supplySimulatedCount() {
    return this.stats?.supplyPerformance.supplyCountSimulated;
  }
  get avgOrderCycle() {
    return this.stats?.supplyPerformance.avgOrderCycleReal;
  }
  get avgOrderCycleSimulated() {
    return this.stats?.supplyPerformance.avgOrderCycleSimulated;
  }

  // Buffer avg
  // ~~~~

  get bufferTOGAvg() {
    return this.stats?.demandStatistics.bufferAvg.TOG;
  }
  get bufferTOYAvg() {
    return this.stats?.demandStatistics.bufferAvg.TOY;
  }
  get bufferTORAvg() {
    return this.stats?.demandStatistics.bufferAvg.TOR;
  }
  get bufferGreenAvg() {
    return this.stats?.demandStatistics.bufferAvg.green;
  }
  get bufferYellowAvg() {
    return this.stats?.demandStatistics.bufferAvg.yellow;
  }
  get bufferRedSafetyAvg() {
    return this.stats?.demandStatistics.bufferAvg.redSafety;
  }
  get bufferRedBaseAvg() {
    return this.stats?.demandStatistics.bufferAvg.redBase;
  }

  get avgBuffer() {
    if (this.stats) {
      return new Buffer({
        green: this.bufferGreenAvg!,
        yellow: this.bufferYellowAvg!,
        redBase: this.bufferRedBaseAvg!,
        redSafety: this.bufferRedSafetyAvg!,
        topOfGreen: this.bufferTOGAvg!,
        topOfRed: this.bufferTORAvg!,
        topOfYellow: this.bufferTOYAvg!
      } as IBuffer);
    }
    return undefined;
  }
}
