import { getOwner } from '@ember/application';
import DS from 'ember-data';
import { AdapterRegistry } from 'ember-data/adapter';
import Model, { ModelRegistry } from 'ember-data/model';
import { EmberDataRequestType } from 'volta/utils/api/types';
import application from 'volta/utils/application';
import ApplicationInstance from '@ember/application/instance';
import StoreService from 'volta/services/store';

/**
 * Given a record, obtain the ember-data model class
 * @param record
 */
export function _getModelClass<M extends typeof Model>(record: InstanceType<M>): M {
  return record.constructor as M;
}

/**
 * Given an ember-data model class, obtain its name
 * @param clazz
 */
export function _getModelName(clazz: typeof Model): keyof ModelRegistry {
  return clazz.modelName;
}

/**
 * Given an ember-data-record, obtain the related Store
 * @param record
 */
export function _getStoreFromRecord(record: Model) {
  const owner = getOwner(record) as ApplicationInstance;
  return owner.lookup('service:store') as StoreService;
}

/**
 * Lookup and return the application wide store
 */
export function _getStore() {
  return application.instance?.lookup('service:store') as StoreService;
}

/**
 * Given an ember-data-model, obtain the related snapshot
 * @param model
 */
export function _snapshotFromRecord(model: Model): DS.Snapshot {
  return (model as any)._createSnapshot();
}

/**
 *
 * @param record
 * @param opPath
 * @param urlType
 * @param instance
 */
export function buildOperationUrl<M extends Model>(
  record: M,
  opPath: string,
  urlType: EmberDataRequestType,
  instance = true
) {
  const modelClass = _getModelClass(record);
  const modelName = _getModelName(modelClass);
  const store = _getStoreFromRecord(record);
  const adapter = store.adapterFor(modelName as keyof AdapterRegistry);
  const path = opPath;
  const snapshot = _snapshotFromRecord(record);
  const baseUrl = adapter.buildURL(
    modelName,
    instance ? record.get('id') : null,
    snapshot,
    urlType
  );

  if (!path) {
    return baseUrl;
  }

  if (baseUrl.charAt(baseUrl.length - 1) === '/') {
    return `${baseUrl}${path}`;
  } else {
    return `${baseUrl}/${path}`;
  }
}

export default buildOperationUrl;
