// @ts-ignore
import { trackedRef } from 'ember-ref-bucket';
import { idVariation } from 'volta/utils/id';
import { isFunction, safeGet } from 'volta/utils/object-utils';

import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import type IntlService from 'ember-intl/services/intl';
import type { IResourceName } from 'volta/models/base-model';

export const SELECT_ALL_ITEMS = 'All';

const SMALL_SCREEN_WIDTH = 425;
const SMALL_SPINNER_HEIGHT = 28;
const LARGE_SPINNER_HEIGHT = 45;
const DEFAULT_SPINNER_PADDING = 8;

export interface IBvResourceListContext {
  selectable: boolean;
  selectedItems?: 'All' | string[];
  selectMode: boolean;
  resourceName?: IResourceName;
  loading: boolean;
  onSelectionChange: (
    ...args: [selected: boolean, id: string | undefined, item: unknown, selectOnClick?: boolean]
  ) => void;
}

export interface IBulkAction {
  icon: string;
  content: string;
  onClick: (...args: any[]) => any;
  id: string;
  accessibilityLabel: string;
  url: string;
  external: boolean;
  disabled: boolean;
}

export interface IBulkActionSection {
  title?: string;
  items: IBulkAction[];
}

interface ISortOptions {
  key: string;
  label: string;
}

interface IArgs {
  // ITEMS
  items: unknown[];
  itemIdPath?: string;
  resourceName?: IResourceName;
  loading: boolean;

  // STYLE
  estimateHeight?: number;
  containerSelector?: string;
  showHeader?: boolean;

  // SCROLL MANAGEMENT
  totalItemsCount?: number;
  infiniteScroll?: boolean;

  // SELECTION
  selectedItems?: 'All' | string[];
  selectable: boolean;
  hasMoreItems?: boolean;

  // ACTIONS
  promotedBulkActions: IBulkAction[];
  bulkActions: IBulkAction[] | IBulkActionSection;
  paginatedSelectAllAction: IBulkAction;

  // FILTER
  filterControl?: typeof Component;

  // SORT
  sortOptions: ISortOptions[];
  sortComponent?: string;
  sortValue?: string;

  // HANDLERS
  onDragSort?: (e: unknown) => void;
  onSelectionChange: (items: unknown[] | 'All', selectMode?: boolean) => void;
  onLastItemReached?: (item: unknown, index: number) => void;
  onFirstItemReached?: (item: unknown, index: number) => void;
  onSortChange?: (key: string) => void;
}

export default class BvResourceList extends Component<IArgs> {
  // Services
  // ~~~~~

  @service intl!: IntlService;

  // Properties
  // ~~~~~

  /**
   * ID used to identify our wrapper element from other instances
   */
  wrapperId = guidFor(this);

  get itemIdPath() {
    return this.args.itemIdPath ?? 'id';
  }

  get dndEnabled() {
    return Boolean(this.args.onDragSort);
  }

  /**
   * Private state
   */

  @tracked selectMode: boolean = Boolean(this.args.selectedItems?.length ?? 0 > 0);
  @tracked loadingPosition: number = 0;
  @tracked wrapperIsSmallSize: boolean = false;

  @trackedRef('listNode') listNode?: HTMLElement;
  estimateHeight = this.args.estimateHeight ?? 65;
  containerSelector = this.args.containerSelector ?? 'body';
  idForFirstItem?: string;

  // Callbacks
  // ~~~~~

  // Computed Properties
  // ~~~~~

  get context(): IBvResourceListContext {
    const { resourceName, loading, selectedItems } = this.args;
    const { selectable, selectMode } = this;
    return {
      selectable,
      selectedItems,
      selectMode,
      resourceName,
      loading,
      onSelectionChange: (
        ...args: [selected: boolean, id: string | undefined, item: unknown, selectOnClick?: boolean]
      ) => {
        return this.handleSelectionChange(...args);
      }
    };
  }

  get selectId() {
    return idVariation(this.wrapperId, 'Select');
  }

  get selectable(): boolean {
    const { promotedBulkActions, bulkActions, selectable } = this.args;
    return (
      (promotedBulkActions && promotedBulkActions.filter(Boolean).length > 0) ||
      (bulkActions && (bulkActions as IBulkAction[]).filter(Boolean).length > 0) ||
      selectable
    );
  }

  get bulkSelectState() {
    let selectState: 'indeterminate' | boolean = 'indeterminate';
    const { items, selectedItems } = this.args;

    if (!selectedItems || (Array.isArray(selectedItems) && selectedItems.length === 0)) {
      selectState = false;
    } else if (
      selectedItems === SELECT_ALL_ITEMS ||
      (Array.isArray(selectedItems) && selectedItems.length === items.length)
    ) {
      selectState = true;
    }
    return selectState;
  }

  get headerTitle() {
    const { loading, items, resourceName, totalItemsCount } = this.args;
    const itemsCount = (items && items.length) || 0;
    const isFiltered = itemsCount !== totalItemsCount;
    const resourceNamePlural = this.intl.t(
      (itemsCount === 1 && !loading ? resourceName?.singular : resourceName?.plural) ?? 'items'
    );

    return loading
      ? this.intl.t('bvList.loading', { resourceNamePlural })
      : isFiltered
      ? this.intl.t('bvList.showingOf', {
          itemsCount,
          totalItemsCount
        })
      : this.intl.t('bvList.showing', {
          itemsCount,
          resourceNameSingular: this.intl.t(resourceName?.singular ?? 'item'),
          resourceNamePlural: this.intl.t(resourceName?.plural ?? 'items')
        });
  }

  get selectedItemsCountLabel() {
    const { items = [], selectedItems = [] } = this.args;
    return selectedItems === SELECT_ALL_ITEMS ? `${items.length}+` : selectedItems.length;
  }

  get bulkActionsAccessibilityLabel() {
    const { resourceName, items = [], selectedItems = [] } = this.args;
    const selectedItemsCount = selectedItems.length;
    const totalItemsCount = items.length;
    const allSelected = selectedItemsCount === totalItemsCount;

    if (totalItemsCount === 1 && allSelected) {
      return this.intl.t('bvList.a11yCheckboxDeselectAllSingle', {
        resourceNameSingular: resourceName?.singular
      });
    } else if (totalItemsCount === 1) {
      return this.intl.t('bvList.a11yCheckboxSelectAllSingle', {
        resourceNameSingular: resourceName?.singular
      });
    } else if (allSelected) {
      return this.intl.t('bvList.a11yCheckboxDeselectAllMultiple', {
        itemsLength: items.length,
        resourceNamePlural: resourceName?.plural
      });
    } else {
      return this.intl.t('bvList.a11yCheckboxSelectAllMultiple', {
        itemsLength: items.length,
        resourceNamePlural: resourceName?.plural
      });
    }
  }

  get paginatedSelectAllText() {
    const { hasMoreItems, items, resourceName, selectedItems = [] } = this.args;

    if (!this.selectable || !hasMoreItems) {
      return undefined;
    }

    if (selectedItems === SELECT_ALL_ITEMS) {
      return this.intl.t('bvList.allItemsSelected', {
        itemsLength: items.length,
        resourceNamePlural: resourceName?.plural
      });
    }

    return undefined;
  }

  get paginatedSelectAllAction() {
    const {
      hasMoreItems,
      items,
      resourceName,
      paginatedSelectAllAction,
      selectedItems = []
    } = this.args;

    if (!this.selectable || !hasMoreItems) {
      return undefined;
    }

    const actionText =
      selectedItems === SELECT_ALL_ITEMS
        ? this.intl.t('undo')
        : paginatedSelectAllAction?.content ??
          this.intl.t('bvList.selectAllItems', {
            itemsLength: items.length,
            resourceNamePlural: this.intl.t(resourceName?.plural ?? '')
          });

    return {
      content: actionText,
      onClick: this.handleSelectAllItemsInStore
    };
  }

  get emptySearchResultText() {
    return {
      title: this.intl.t('bvList.emptySearchResultTitle', {
        resourceNamePlural: this.intl.t(this.args.resourceName?.plural ?? 'items')
      }),
      description: this.intl.t('bvList.emptySearchResultDescription')
    };
  }

  get showEmptyState() {
    const { items, loading } = this.args;

    return (!items || !items.length) && !loading;
  }

  get needsHeader() {
    const { sortOptions, sortComponent } = this.args;
    return this.selectable || (sortOptions && sortOptions.length) || sortComponent;
  }

  get spinnerStyle() {
    const loadingPosition = this.loadingPosition;
    return htmlSafe(
      `padding-top: ${loadingPosition > 0 ? loadingPosition : DEFAULT_SPINNER_PADDING}px;`
    );
  }

  get spinnerSize() {
    const { items } = this.args;
    return items.length < 2 ? 'xs' : 'lg';
  }

  get actualSortOptions() {
    const options: ISortOptions[] = [];
    const sort = `${this.args.sortValue}`;
    const sortKey = sort.replace('-', '');
    (this.args.sortOptions || []).forEach((opt) => {
      const { key, label } = opt;

      if (sortKey === key) {
        options[sort === key ? 'push' : 'unshift'](opt);
        options[sort === `-${key}` ? 'push' : 'unshift']({ key: `-${key}`, label });
      } else {
        options.push(opt);
      }
    });

    return options;
  }

  get showSortingSelect() {
    const { sortOptions, sortComponent } = this.args;
    return sortOptions && sortOptions.length && !sortComponent;
  }

  get selectedSortValue() {
    const sortValue = this.args.sortValue;
    const sortOptions = this.actualSortOptions || [];
    return sortOptions.find((opt) => opt.key === sortValue) || sortOptions[0];
  }

  get showInfinityLoader() {
    const { infiniteScroll, items, loading } = this.args;

    return infiniteScroll && items && items.length && !loading;
  }

  // Lifecycle Hooks
  // ~~~~~

  @action
  handleDidUpdate(_element: HTMLElement, [loading, selectedItems]: [boolean, unknown[]]) {
    if ((!selectedItems || selectedItems.length === 0) && !isSmallScreen()) {
      this.selectMode = false;
    } else if (selectedItems.length) {
      this.selectMode = true;
    }

    if (loading !== undefined) {
      this.setLoadingPosition();
    }
  }

  @action
  handleDidInsert(/*element*/) {
    if (this.args.loading) {
      this.setLoadingPosition();
    }
  }

  @action
  handleResize(wrapperElement: HTMLElement) {
    const { selectedItems = [] } = this.args;
    this.wrapperIsSmallSize = wrapperElement.offsetWidth < SMALL_SCREEN_WIDTH;

    if (!this.selectable) {
      return;
    }
    if (
      selectedItems.length === 0 &&
      this.selectMode &&
      !isSmallScreen() &&
      !this.wrapperIsSmallSize
    ) {
      this.handleSelectMode(false);
    }
  }

  @action
  handleSelectMode(selectMode: boolean) {
    this.selectMode = selectMode;

    if (!selectMode) {
      this.args.onSelectionChange?.([]);
    }
  }

  @action
  handleToggleAll() {
    const { onSelectionChange, items, selectedItems } = this.args;

    const itemsArray = items && typeof items.toArray === 'function' ? items.toArray() : items;

    let newlySelectedItems: unknown[] = [];

    if (
      (Array.isArray(selectedItems) && selectedItems.length === itemsArray.length) ||
      selectedItems === SELECT_ALL_ITEMS
    ) {
      newlySelectedItems = [];
    } else {
      newlySelectedItems = [...itemsArray];
    }

    if (newlySelectedItems.length === 0 && !isSmallScreen()) {
      this.handleSelectMode(false);
    } else if (newlySelectedItems.length > 0) {
      this.handleSelectMode(true);
    }

    onSelectionChange?.(newlySelectedItems);
  }

  @action
  handleSortValueChange(option: ISortOptions) {
    this.args.onSortChange?.(option.key);
  }

  @action
  handleLastItemReached(item: unknown, index: number) {
    this.args.onLastItemReached?.(item, index);
  }

  @action
  handleFirstItemReached(item: unknown, index: number) {
    this.args.onFirstItemReached?.(item, index);
  }

  @action
  handleLoadNextPage() {
    return;
  }

  // Helper Functions
  // ~~~~~

  handleSelectionChange(
    selected: boolean,
    id: string | undefined,
    item: unknown,
    selectOnClick?: boolean
  ) {
    const { onSelectionChange, items, selectedItems } = this.args;

    if (!selectedItems || !onSelectionChange) {
      return;
    }

    const newlySelectedItems =
      selectedItems === SELECT_ALL_ITEMS
        ? getAllItemsOnPage(items /*, this.itemIdPath*/)
        : selectOnClick
        ? []
        : [...selectedItems];

    if (selected) {
      newlySelectedItems.push(item);
    } else {
      const index = newlySelectedItems.findIndex(
        (selectedItem: {}) => safeGet(selectedItem, this.itemIdPath) === id
      );
      if (index > -1) {
        newlySelectedItems.splice(index, 1);
      }
    }

    if (newlySelectedItems.length === 0 && !isSmallScreen()) {
      this.handleSelectMode(false);
    } else if (newlySelectedItems.length > 0) {
      this.handleSelectMode(true);
    }

    onSelectionChange?.(newlySelectedItems);
  }

  @action
  handleSelectAllItemsInStore() {
    const newlySelectedItems =
      this.args.selectedItems === SELECT_ALL_ITEMS
        ? getAllItemsOnPage(this.args.items)
        : SELECT_ALL_ITEMS;

    this.args.onSelectionChange?.(newlySelectedItems);
  }

  setLoadingPosition() {
    if (this.listNode != null) {
      if (typeof window === 'undefined') {
        return;
      }

      const overlay = this.listNode.getBoundingClientRect();
      const viewportHeight = Math.max(
        document.documentElement ? document.documentElement.clientHeight : 0,
        window.innerHeight || 0
      );

      const overflow = viewportHeight - overlay.height;

      const spinnerHeight =
        this.args.items && this.args.items.length === 1
          ? SMALL_SPINNER_HEIGHT
          : LARGE_SPINNER_HEIGHT;

      const spinnerPosition =
        overflow > 0
          ? (overlay.height - spinnerHeight) / 2
          : (viewportHeight - overlay.top - spinnerHeight) / 2;

      this.loadingPosition = spinnerPosition;
    }
  }

  // Private Callbacks
  // ~~~~~
}

export function isSmallScreen() {
  return typeof window === 'undefined' ? false : window.innerWidth <= SMALL_SCREEN_WIDTH;
}

function getAllItemsOnPage(items: unknown[] /*, itemIdPath*/) {
  const itemsArray = items && isFunction(items.toArray) ? items.toArray() : items;
  return [...itemsArray];
}
