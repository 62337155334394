import BaseModel from 'volta/models/base-model';
import {
  _normalize,
  collectionCommand,
  resourceCommand
} from 'volta/utils/api';

import { attr } from '@ember-data/model';

export const ResourceName = {
  singular: 'userRole',
  plural: 'userRoles'
};

export const Commands = {
  UpdateUserRole: 'UpdateUserRole',
  DeleteUserRole: 'DeleteUserRole',
  CreateUserRole: 'CreateUserRole'
};

export default class UserRole extends BaseModel {
  /**
   * Collection commands
   */

  static createUserRole = collectionCommand(Commands.CreateUserRole, { after: _normalize });

  updateUserRole = resourceCommand(Commands.UpdateUserRole);
  deleteUserRole = resourceCommand(Commands.DeleteUserRole);

  @attr('string')
  declare name?: string;

  @attr('array', { defaultValue: () => [] })
  declare permissions: string[];

  @attr('boolean', { defaultValue: false })
  declare isDefault: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-role': UserRole;
  }
}
