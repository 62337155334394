import { validateFormat, validatePresence } from 'ember-changeset-validations/validators';
import Supplier from 'volta/models/supplier';
import validateOnCheck from 'volta/validators/on-check';
import type { TUserType } from "volta/models/constants/user-types";

export default {
  firstName: [],
  lastName: [],
  email: [validateFormat({ type: 'email', allowBlank: true })],
  login: validatePresence(true),
  roles: validatePresence(true),
  userType: [validatePresence(true)],
  suppliers: [
    validateOnCheck({
      checkKeys: ['userType'],
      checkCondition: (suppliers: Supplier[], userType: TUserType) => {
        return userType === 'SUPPLIER' ? suppliers.length > 0 : true;
      },
      i18nType: 'empty'
    })
  ]
};
