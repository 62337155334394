import { validatePresence } from 'ember-changeset-validations/validators';
import { validateNumber } from 'ember-changeset-validations/validators';

export default {
  reorderQty: [validatePresence(true), validateNumber({ positive: true })],
  unitPrice: [validateNumber({ positive: true, allowBlank: true })],
  proposedValues: {
    reorderQty: [validateNumber({ positive: true, allowBlank: true })],
    unitPrice: [validateNumber({ positive: true, allowBlank: true })]
  }
};
