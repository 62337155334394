import { IJsonApiQuery } from './../../../utils/api/jsonapi-types';
import Component from '@glimmer/component';

export interface ISkusSupplierFilterDefinitionArgs {
  skuId?: string;
  beforeQuery?: (query: IJsonApiQuery) => IJsonApiQuery;
}

export default class SkusSupplierFilter extends Component<
  IFilterArgs<ISkusSupplierFilterDefinitionArgs>
> {}
