// @ts-ignore
import { ref } from 'ember-ref-bucket';

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

const FILTER_FIELD_MIN_WIDTH = 150;

interface IArgs {
  filterDefinitions: TFilterDefinition[];
  filterValues: TFilterValues;
  forceShowMorefiltersButton?: boolean;
  onFiltersApply?: (filterValues: TFilterValues) => void;
  onFilterValueChange?: (filterValue?: TFilterValue) => void;
  onSearchChange?: (filterValues: TFilterValues, query: string) => Promise<unknown>;
  onClose?: () => void;
  searchValue: string;
}

export default class BvFilters extends Component<IArgs> {
  @tracked availableWidth = 0;
  @tracked proxyButtonsWidth: Record<string, number> = {};
  @tracked isMoreFiltersContainerVisible = false;
  @tracked customFiltersOpen = false;

  searchOnInput = true;

  @ref('container') container?: HTMLElement;
  @ref('proxyButtonContainer') proxyButtonContainer?: HTMLElement;
  @ref('moreFiltersButtonContainerToggle') moreFiltersButtonContainerToggle?: HTMLElement;
  @ref('moreFiltersButtonContainer') moreFiltersButtonContainer?: HTMLElement;

  // Getters
  // ~~~~~

  @cached
  get definitionsDict() {
    const dict: Record<string, TFilterDefinition> = {};
    this.args.filterDefinitions.forEach((def) => (dict[def.queryParam] = def));
    return dict;
  }

  get popoverableFilterDefinitions() {
    const { filterDefinitions } = this.args;
    return filterDefinitions ? filterDefinitions.filter((def) => def.isPrimary && !def.hidden) : [];
  }

  get filtersToRender() {
    const { availableWidth, proxyButtonsWidth, popoverableFilterDefinitions } = this;
    const filtersToReturn = [];
    let remainingWidth = availableWidth;
    for (let i = 0; remainingWidth > 0 && i < popoverableFilterDefinitions.length; i++) {
      const filter = popoverableFilterDefinitions[i];
      const actionWidth = proxyButtonsWidth[filter.queryParam];
      if (actionWidth <= remainingWidth) {
        filtersToReturn.push(filter);
        remainingWidth -= actionWidth;
      } else {
        // When we can't fit an action, we break the loop.
        // The ones that didn't fit will be accessible through the "More filters" button
        break;
      }
    }
    return filtersToReturn;
  }

  get enabledFilters() {
    const { filterValues } = this.args;
    const filters: Record<string, TFilterValue> = {};

    for (const field in filterValues) {
      if (filterValues.hasOwnProperty(field) && field !== 'q') {
        const filter = filterValues[field];
        if (filter && filter.enabled) {
          filters[field] = filter;
        }
      }
    }

    return filters;
  }

  get enabledFiltersCount() {
    return Object.keys(this.enabledFilters).length;
  }

  get shouldRenderMoreFiltersButton() {
    const { forceShowMorefiltersButton, filterDefinitions } = this.args;
    const { filtersToRender } = this;
    const filtersWithoutSearch = filterDefinitions
      ? filterDefinitions.filter((f) => !f.hidden).length
      : 0;
    return (
      forceShowMorefiltersButton ||
      (filtersToRender && filtersToRender.length !== filtersWithoutSearch)
    );
  }

  get customFilterDefinitions() {
    return (this.args.filterDefinitions ?? []).filter((f) => f.isCustomField);
  }

  // Actions
  // ~~~~~~

  @action
  handleResize() {
    this.measureProxyButtons(this.proxyButtonContainer);
    this.measureAvailableWidth();
  }

  @action
  handleProxyContainerInsert(container: HTMLElement) {
    this.measureProxyButtons(container);
    this.measureAvailableWidth();
  }

  @action
  handleDidInsert(_container: HTMLElement) {
    this.handleResize();
  }

  @action
  toggleFilters() {
    this.isMoreFiltersContainerVisible = !this.isMoreFiltersContainerVisible;
    if (!this.isMoreFiltersContainerVisible) {
      this.args.onClose?.();
    }
  }

  @action
  closeFilters() {
    this.isMoreFiltersContainerVisible = false;
    this.args.onClose?.();
  }

  @action
  filterValuesDidChange(value: TFilterValue, field: string) {
    const { filterValues, onFiltersApply, onFilterValueChange } = this.args;
    const updatedFilterValues = { ...filterValues };
    let filterValueForField: TFilterValue = updatedFilterValues?.[field] ?? ({} as TFilterValue);
    filterValueForField = { ...filterValueForField, ...value };
    updatedFilterValues[field] = filterValueForField.values.length
      ? filterValueForField
      : undefined;

    onFilterValueChange?.(filterValueForField);

    if (Object.keys(updatedFilterValues).length === 0) {
      // no filter added
      return false;
    }

    return onFiltersApply?.(updatedFilterValues);
  }

  // Helpers
  // ~~~~~~

  measureProxyButtons(proxyButtonContainer?: HTMLElement) {
    if (proxyButtonContainer) {
      const proxyButtonsWidth: Record<string, number> = {};
      // this number is magical, but tweaking it solved the problem of items overlapping
      const tolerance = 52;
      Array.from(proxyButtonContainer.children).forEach((element) => {
        const buttonWidth = element.getBoundingClientRect().width + tolerance;
        // @ts-ignore
        const buttonKey = element.dataset.id as string;
        if (buttonKey) {
          proxyButtonsWidth[buttonKey] = buttonWidth;
        }
      });

      this.proxyButtonsWidth = proxyButtonsWidth;
    }
  }

  measureAvailableWidth() {
    if (this.container && this.moreFiltersButtonContainer) {
      const containerWidth = this.container.getBoundingClientRect().width;
      const moreFiltersButtonWidth = this.moreFiltersButtonContainer.getBoundingClientRect().width;
      const filtersActionWidth = 0;
      this.availableWidth =
        containerWidth - FILTER_FIELD_MIN_WIDTH - moreFiltersButtonWidth - filtersActionWidth;
    }
  }
}
