// @ts-ignore
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import TIntlService from 'ember-intl/services/intl';
import moment from 'moment-timezone';
import application from 'volta/utils/application';
import { safeGet } from 'volta/utils/object-utils';

const IntlService = application?.instance?.lookup('service:intl') as TIntlService;

function checkValidation(check: boolean, type: string, description: string, date: string) {
  return check || buildMessage(type, { type, context: { description, [type]: date } });
}

// To use if you have to get a specific date
export default function validateCompareDate({
  comparedKey,
  direction = 'BEFORE',
  i18nKey,
  canBeEqual = false,
  allowBlankDates = false
}: {
  comparedKey: string;
  direction?: 'BEFORE' | 'AFTER';
  i18nKey?: string;
  canBeEqual?: boolean;
  allowBlankDates?: boolean;
}) {
  return (key: string, newValue: Date, __: Date, changes: any, content: any) => {
    const values = {
      ...content,
      ...changes
    };

    const comparedDate = safeGet(values, comparedKey);

    if (!comparedDate || !newValue) {
      return checkValidation(
        !!allowBlankDates,
        'empty',
        i18nKey ? IntlService.t(i18nKey) : key,
        comparedDate
      );
    }

    const date = moment(newValue);
    const formattedComparedDate = moment(comparedDate).format('L');

    if (direction === 'AFTER') {
      return checkValidation(
        canBeEqual ? date.isSameOrAfter(comparedDate) : date.isAfter(comparedDate),
        'after',
        i18nKey ? IntlService.t(i18nKey) : key,
        formattedComparedDate
      );
    }

    return checkValidation(
      canBeEqual ? date.isSameOrBefore(comparedDate) : date.isBefore(comparedDate),
      'before',
      i18nKey ? IntlService.t(i18nKey) : key,
      formattedComparedDate
    );
  };
}
