import { isArray } from '@ember/array';
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import isEqual from "lodash/isEqual";

export interface IChoiceItem {
  icon?: string;
  iconColor?: string;
  disabled?: boolean;
  title: string;
  description?: string;
  hoveredDescription?: string;
  value: any;
}

export interface IChoiceSection {
  title?: string;
  items: IChoiceItem[];
}
interface IArgs {
  isGrouped?: boolean;
  checked: any;
  auto?: boolean;
  onChange?: (value: any, e: Event) => any;
  items: IChoiceItem[];
  sections?: IChoiceSection[];
  allowDeselection?: boolean;
}

export default class BvChoiceBlocks extends Component<IArgs> {
  @tracked checked = this.args.checked;

  @tracked hoveredItem?: IChoiceItem;

  get finalSections() {
    const { items = [], sections = [] } = this.args;
    const validItems = items.filter(Boolean);
    const validSections = sections.filter((s) => s && s.items.length);
    const finalSections: IChoiceSection[] = [];

    if (validItems.length > 0) {
      finalSections.push({ items: validItems });
    }

    assert(
      `bevolta::bv-choice-blocks - sections must be an array, you passed ${sections}`,
      isArray(sections)
    );

    if (validSections.length > 0) {
      finalSections.push(...validSections);
    }

    return finalSections;
  }

  get hasMultipleSections() {
    return this.finalSections.length > 1;
  }

  @action
  onChange(value: any, e: InputEvent) {
    if (this.args.allowDeselection) {
      const deepEqual = isEqual(this.checked, value);
      if (deepEqual) {
        this.changeCheck(null, e);
      } else {
        this.changeCheck(value, e);
      }
    } else {
      this.changeCheck(value, e);
    }
  }

  @action
  handleCheckedChange(_el: HTMLElement, [value]: [any]) {
    if (this.args.auto && !isEqual(this.checked, value)) {
      this.checked = value;
    }
  }

  changeCheck(value: any, e: InputEvent) {
    this.checked = value;
    if (this.args.onChange) {
      this.args.onChange(value, e);
    }
  }

  showDescriptionOnMouseLeave = (): string | undefined => {
    const { auto, checked } = this.args;
    const items = this.finalSections.flatMap((s) => s.items);
    const isChecked = (auto && this.checked) || checked;
    if (isChecked) {
      return items.find((item: IChoiceItem) => item.value === isChecked)?.hoveredDescription;
    }
    return undefined;
  };
}
