import { IResourceName } from 'volta/models/base-model';
import { indexByFn } from 'volta/utils/array-utils';

import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

interface IArgs {
  resourceName: IResourceName;
  filterDefinitions: TFilterDefinition[];
  filterValues: TFilterValues;
  onFilterValuesChange: (value: TFilterValue, field: string) => void;
}

export default class BvFiltersFilterTags extends Component<IArgs> {
  @cached
  get filters() {
    return Object.entries(this.args.filterValues).map(([field, f]: [string, TFilterValue]) => {
      return {
        ...f,
        field
      };
    });
  }

  @cached
  get definitionsDict() {
    return indexByFn(this.args.filterDefinitions, (d) => d.queryParam);
  }

  getDef = (key: string) => this.definitionsDict[key];

  getFilter = (key: string) => this.args.filterValues[key];
}
