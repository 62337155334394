// @ts-ignore
import { CalculatePositionResult } from 'ember-basic-dropdown/utils/calculate-position';
import { ref } from 'ember-ref-bucket';
import { defaultErrorHandler } from 'volta/utils/error-utils';

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

// @ts-ignore
import type { Dropdown } from 'ember-basic-dropdown/components/basic-dropdown';
type FVoid = () => void;

export interface IBvInlineEditCommonArgs {
  onCancel?: FVoid;
  fullWidth?: boolean;
  disabled: boolean;
}
interface IArgs extends IBvInlineEditCommonArgs {
  onSave?: (e: Event) => void | Promise<void>;
}

export default class BvInlineEdit extends Component<IArgs> {
  @tracked isLoading: boolean = false;
  @tracked popover!: Dropdown;

  @ref('displayElement')
  displayElement: HTMLInputElement | null = null;

  @action
  handlePopoverInit(api: Dropdown) {
    this.popover = api;
  }

  @action
  handlePopoverClose(_api: Dropdown, event?: MouseEvent) {
    if ((event?.target as HTMLElement) === document.body) {
      return false;
    }

    if (!this.args.disabled) {
      return this.args.onCancel?.();
    }
  }

  @action
  handleEscape(event: KeyboardEvent) {
    event.stopImmediatePropagation();
    this.popover?.actions.close(event, false);
  }

  @action
  setPositionCorrectly(pos: CalculatePositionResult): CalculatePositionResult {
    const padding = 5;
    const offset =
      ((this.displayElement?.offsetHeight ?? 0) + padding) *
      (pos.verticalPosition == 'above' ? 1 : -1);

    pos.style.top = pos.style.top + offset;

    return pos;
  }

  @action
  async handleConfirmation(event: KeyboardEvent | MouseEvent) {
    const { onSave } = this.args;

    if (!this.args.disabled) {
      if (onSave) {
        this.isLoading = true;
        try {
          await onSave(event);
          this.popover?.actions.close(event, false);
        } catch (error) {
          defaultErrorHandler(error);
        } finally {
          this.isLoading = false;
        }
      }
    }
  }
}
