import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import SessionUserService from 'volta/services/session-user';

export default class MeFeatureHelper extends Helper {
  @service sessionUser!: SessionUserService;

  compute([]: [string]) {
    return this.sessionUser.user;
  }
}
