import { tracked } from '@glimmer/tracking';
import { IDashboardSummary, IPriorityCount } from './types/stats';

function createFromArray(array?: number[]): IPriorityCount {
  if (array && array.length === 5) {
    return {
      critical: array[0],
      high: array[1],
      medium: array[2],
      low: array[3],
      otog: array[4]
    };
  } else {
    return {
      critical: 0,
      high: 0,
      medium: 0,
      low: 0,
      otog: 0
    };
  }
}

export default class DashboardSummary {
  @tracked data!: IDashboardSummary;

  get planningCounts() {
    return createFromArray(this.data?.planningCounts);
  }

  get prevProjectedOhaCounts() {
    return createFromArray(this.data?.prevProjectedOhaCounts);
  }

  get ohaCounts() {
    return createFromArray(this.data?.ohaCounts);
  }

  get prevOhaCounts() {
    return createFromArray(this.data?.prevOhaCounts);
  }

  get projectedOhaCounts() {
    return createFromArray(this.data?.projectedOhaCounts);
  }

  get timeBuffer() {
    return createFromArray([]);
  }

  get prevTimeBuffer() {
    return createFromArray([]);
  }

  get planningMediumAndAbove() {
    return this.planningCounts.critical + this.planningCounts.high + this.planningCounts.medium;
  }

  get ohaHighAndAbove() {
    return this.ohaCounts.critical + this.ohaCounts.high;
  }

  get executionCriticalCount() {
    return this.ohaCounts.critical + this.timeBuffer.critical + this.projectedOhaCounts.critical;
  }

  get executionHighCount() {
    return this.ohaCounts.high + this.projectedOhaCounts.high + this.timeBuffer.high;
  }

  get executionMediumCount() {
    return this.ohaCounts.medium + this.projectedOhaCounts.medium + this.timeBuffer.medium;
  }

  get executionLowCount() {
    return this.ohaCounts.low + this.projectedOhaCounts.low + this.timeBuffer.low;
  }

  get executionOtogCount() {
    return this.ohaCounts.otog + this.projectedOhaCounts.otog + this.timeBuffer.otog;
  }

  get executionCriticalCountPrev() {
    return (
      this.prevOhaCounts.critical +
      this.prevTimeBuffer.critical +
      this.prevProjectedOhaCounts.critical
    );
  }

  get executionHighCountPrev() {
    return this.prevOhaCounts.high + this.prevProjectedOhaCounts.high + this.prevTimeBuffer.high;
  }

  get executionMediumCountPrev() {
    return (
      this.prevOhaCounts.medium + this.prevProjectedOhaCounts.medium + this.prevTimeBuffer.medium
    );
  }

  get executionLowCountPrev() {
    return this.prevOhaCounts.low + this.prevProjectedOhaCounts.low + this.prevTimeBuffer.low;
  }

  get executionOtogCountPrev() {
    return this.prevOhaCounts.otog + this.prevProjectedOhaCounts.otog + this.prevTimeBuffer.otog;
  }

  get executionOverallCount() {
    return (
      this.executionOtogCount +
      this.executionLowCount +
      this.executionMediumCount +
      this.executionHighCount +
      this.executionCriticalCount
    );
  }

  constructor(data: IDashboardSummary) {
    this.data = data;
  }
}
