import { AdapterRegistry } from 'ember-data/adapter';
import fetch from 'fetch';
import { IResourceName } from 'volta/models/base-model';

import Store from '@ember-data/store';

import type { UploadFile } from 'ember-file-upload';
import { ModelRegistry } from "ember-data/model";

export default class StoreService extends Store {
  // Properties
  // ~~~~~

  applicationAdapter = this.adapterFor('application');

  // Helpers
  // ~~~~~

  rawQuery(modelName: string, args: string[], params?: object): Promise<any> {
    const query = [modelName].concat(args).join('/');
    const adapter = this.adapterFor(modelName as keyof AdapterRegistry);
    const url = adapter.buildRawURL(query.replace(/\/$/, ''));
    return adapter.ajax(url, 'GET', { data: params });
  }

  /**
   * Generates a normal authenticated GET request to a given API end-point
   *
   * @param url API end-point full URL
   * @param params Optional query parameters
   */
  normalGet(url: string, params?: object) {
    const adapter = this.applicationAdapter;
    const finalUrl = adapter.buildRawURL(url);
    return adapter.ajax(finalUrl, 'GET', { data: params });
  }

  /**
   * Generates a normal authenticated POST request to a given API end-point
   *
   * @param url API end-point full URL
   * @param attrs Optional query parameters
   */
  normalPost(url: string, attrs: any = {}) {
    const adapter = this.applicationAdapter;
    const finalUrl = adapter.buildRawURL(url);
    const params = { data: attrs };
    return adapter.ajax(finalUrl, 'POST', params);
  }

  /**
   * Uploads a file with multipart form authenticated POST request to an API end-point
   *
   * @param url URL of the file uploading API end-point
   * @param file File object
   * @param attrs Optional params
   */
  uploadFile(url: string, file?: UploadFile, attrs?: any) {
    if (!file) {
      return;
    }
    const data = attrs?.data ?? attrs;
    if (attrs?.data) {
      delete attrs.data;
    }
    const adapter = this.applicationAdapter;
    const finalUrl = adapter.buildRawURL(url);
    const headers = adapter.getAuthenticatedHeaders();
    const options = {
      data,
      withCredentials: true,
      headers,
      ...attrs
    };
    return file.upload(finalUrl, options).then((response: any) => response.json());
  }

  /**
   * GET a file as a `blob`
   *
   * @param url URL of the file to download
   */
  downloadFile(url: string) {
    const adapter = this.applicationAdapter;
    const finalUrl = adapter.buildRawURL(url);
    return fetch(finalUrl, { method: 'GET', headers: adapter.headers })
      .then((response: any) => response.blob())
      .then((response) => this._onDownloadFileSuccess(response, url));
  }

  normalGetFile(url: string) {
    const adapter = this.applicationAdapter;
    const finalUrl = adapter.buildRawURL(url);
    return fetch(finalUrl, { method: 'GET', headers: adapter.headers })
      .then((response: any) => response.blob())
      .then((response) => URL.createObjectURL(response));
  }

  /**
   * Trigger a collection level non rest query
   *
   * @param modelName The model name
   * @param customQueryName The customQuery name
   * @param recordId The model instance optinal id
   * @param params Optional query parameters
   */
  customQuery(
    modelName: string | keyof ModelRegistry,
    customQueryName: string,
    recordId?: string,
    params?: object
  ): Promise<any> {
    const adapter = this.adapterFor(modelName as keyof AdapterRegistry);
    const url =
      adapter.buildURL(modelName, recordId, null, recordId ? 'findRecord' : 'query') +
      `/${customQueryName}`;
    return adapter.ajax(url, 'GET', { data: params });
  }

  nestedQuery(
    modelName: IResourceName,
    modelId: string,
    nestedModelName: IResourceName,
    params?: object
  ): Promise<any> {
    const adapter = this.adapterFor(modelName.plural as keyof AdapterRegistry);
    const url = adapter.buildNestedUrl(modelName.plural, modelId, nestedModelName.plural);
    return adapter.ajax(url, 'GET', { data: params });
  }

  // Private Callback
  // ~~~~~

  /**
   * Creates a link to fool the browser into downloading the file
   * Thus displaying a "save as..." prompt
   *
   * @param data File data
   * @param url URL of the file
   */
  _onDownloadFileSuccess(data: any, url: string) {
    const a = document.createElement('a');
    const browserUrl = window.URL.createObjectURL(data);
    a.href = browserUrl;
    a.download = url;
    a.click();
    window.URL.revokeObjectURL(browserUrl);
  }
}
