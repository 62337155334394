
import config from 'volta/config/environment';

import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
// @ts-ignore
import GoogleAnalytics from 'ember-metrics/metrics-adapters/google-analytics';
// @ts-ignore
import type Metrics from 'ember-metrics/services/metrics';
import type GlimmerComponent from '@glimmer/component';
import type EmberComponent from '@ember/component';
import type Controller from '@ember/controller';
import type Route from '@ember/routing/route';
import type SessionUserService from 'volta/services/session-user';
export type Trackable = GlimmerComponent | EmberComponent | Controller | Route;

export default class BvMetricsService extends Service {
  @service metrics!: Metrics;
  @service sessionUser!: SessionUserService;

  @service router!: RouterService;

  initialized: boolean = false;

  constructor() {
    super(...arguments);
    this.setContext();
  }

  /**
   * Clean the service state and metrics context
   */
  cleanup() {
    this.initialized = false;
    this.metrics.context = {};
  }

  /**
   * Set the metrics context with google analytics dimensions
   * @param optionalContext Other dimensions a user would want to add to the default ones (userId, userName)
   */
  setContext(optionalContext: any[] = []) {
    if (!this.initialized) {
      this.metrics.context = {};

      const context: string[] = [config.api.domain];
      const { user, userId } = this.sessionUser;

      if (userId) {
        context.push(userId);
      }

      if (user?.login) {
        context.push(user.login);
      }

      const contextArray = context.concat(optionalContext || []);
      contextArray.forEach((item, i) => {
        if (item) {
          this.metrics.context[`dimension${i + 1}`] = item;
        }
      });
      this.initialized = true;
    }
  }

  /**
   * Track a component's action
   *
   * @param component The Ember or Glimmer component instance
   * @param action The action name
   * @param label The action intent label
   * @param value The action intent value
   */
  trackAction(component: any, action: string, label?: string, value?: any) {
    if (!component) {
      return;
    }
    const componentName = component.constructor?.name ?? component.toString();

    return this.trackEvent(componentName, action, label, value);
  }

  /**
   * Track a page transition
   *
   * @param page The page url
   * @param title The page title
   */
  trackPage(page: string, title: string) {
    this.setContext();
    this.metrics.trackPage({ page, title });
  }

  /**
   * Track an application event
   *
   * @param category The event category
   * @param action The event action
   * @param label The event label
   * @param value The event value
   */
  trackEvent(category: string, action: string, label?: string, value?: any) {
    this.setContext();
    this.metrics.trackEvent({
      category,
      action,
      label,
      value
    });
  }

  buildTrackingFunction() {
    const self = this;
    return function trackImpl(this: Trackable, action: string, label?: string, value?: any) {
      return self.trackAction(this, action, label, value);
    };
  }
}

declare module '@ember/service' {
  interface Registry {
    'bv-metrics': SessionUserService;
  }
}
