import { enqueueTask, restartableTask } from 'ember-concurrency';
import groupBy from 'lodash/groupBy';
import moment from 'moment-timezone';
import PoTask from 'volta/models/po-task';
import { SkuResourceName } from 'volta/models/sku';
import SkuTask from 'volta/models/sku-task';
import Task, {
  SKU_TASK_TYPES,
  SkuTaskResourceName,
  TaskResourceName,
  TaskResourceNameFromParentRecordResourceName,
  taskToCommand
} from 'volta/models/task';
import { normalize } from 'volta/utils/api/serialize-and-push';
import { defaultErrorHandler } from 'volta/utils/error-utils';
import { eqFilter, inFilter, niFilter } from 'volta/utils/filters-utils';

import Service, { service } from '@ember/service';
import { isPresent } from '@ember/utils';

import type { IResourceName } from 'volta/models/base-model';
import type SessionUserService from './session-user';

import type { IJsonApiQuery } from 'volta/utils/api/jsonapi-types';
import type StoreService from './store';
import { TAssignTo, TTaskType, UTask } from 'volta/models/types/tasks';

const DEFAULT_INCLUDE: string = 'createdBy,updatedBy,archivedBy,resolvedBy,assignees';
const DEFAULT_SORT: string = 'dueDate,resolvedAt';

type FetchTaskQuery = {
  assigneeIds?: string[];
  createdById?: string;
  isAssigned?: boolean;
  assigneeIdsToExclude?: string[];
};
export default class TaskService extends Service {
  @service store!: StoreService;
  @service sessionUser!: SessionUserService;

  get isSaving() {
    return this.saveTask.isRunning;
  }

  fetchTasks = enqueueTask(async (query: IJsonApiQuery = {}) => {
    if (!query.include) {
      query.include = DEFAULT_INCLUDE;
    }

    if (!query.sort) {
      query.sort = DEFAULT_SORT;
    }

    if (!query.page) {
      query.page = {
        offset: 0,
        limit: 50
      };
    }

    try {
      return await this.store.query(TaskResourceName.singular, query);
    } catch (error) {
      defaultErrorHandler(error);
      throw error;
    }
  });

  // MAIN FETCH TASK
  fetchTasksTask = restartableTask(
    async (
      modelName: IResourceName,
      entityId: string,
      taskTypes: TTaskType[],
      include: string = DEFAULT_INCLUDE,
      sort: string = DEFAULT_SORT
    ) => {
      try {
        const tasksQuery: IJsonApiQuery = {
          page: {
            offset: 0,
            limit: 300
          },
          sort,
          include,
          filter: {
            taskType: inFilter(taskTypes)
          }
        };

        const response = await this.store.nestedQuery(
          modelName,
          entityId,
          TaskResourceName,
          tasksQuery
        );

        const SkuOrPoTaskResourceName = TaskResourceNameFromParentRecordResourceName(modelName);
        if (SkuOrPoTaskResourceName) {
          return normalize(SkuOrPoTaskResourceName.singular, response) as UTask[];
        }

        return normalize(TaskResourceName.singular, response) as UTask[];
      } catch (error) {
        defaultErrorHandler(error);
        throw error;
      }
    }
  );

  // SPECIALIZED FETCHES
  fetchPlanningTasks = restartableTask(async (skuId: string) => {
    return this.fetchTasksTask.perform(SkuResourceName, skuId, ['PLANNING_TASK' as TTaskType]);
  });

  fetchExecutionTasks = restartableTask(async (skuId: string) => {
    return this.fetchTasksTask.perform(SkuResourceName, skuId, ['EXECUTION_TASK' as TTaskType]);
  });

  fetchCommentTasks = restartableTask(async (entityId: string, resourceName: IResourceName) => {
    return this.fetchTasksTask.perform(
      resourceName,
      entityId,
      ['COMMENT' as TTaskType],
      'createdBy',
      'createdAt'
    );
  });

  fetchSkuTasks = restartableTask(async (entityId: string, includeComments: boolean = false) => {
    const taskTypes: TTaskType[] = ['SKU_TASK'];
    if (includeComments) {
      taskTypes.push('COMMENT');
    }
    return this.fetchTasksTask.perform(SkuResourceName, entityId, taskTypes);
  });

  fetchSkusTasks = enqueueTask(
    async ({
      entitiesId,
      assigneeIds,
      assigneeIdsToExclude,
      createdById,
      taskType = SKU_TASK_TYPES,
      isAssigned
    }: {
      entitiesId?: string[];
      assigneeIds?: string[];
      assigneeIdsToExclude?: string[];
      createdById?: string;
      taskType?: TTaskType[];
      isAssigned?: boolean;
    } = {}) => {
      try {
        const tasksQuery: IJsonApiQuery = {
          page: {
            offset: 0,
            limit: 20
          },
          filter: {
            isResolved: eqFilter(false),
            taskType: inFilter(taskType)
          },
          include: DEFAULT_INCLUDE,
          sort: 'dueDate'
        };

        if (entitiesId) {
          tasksQuery.filter = {
            ...tasksQuery.filter,
            linkedEntityId: inFilter(entitiesId)
          };
        }
        if (assigneeIds) {
          tasksQuery.filter = {
            ...tasksQuery.filter,
            assigneeIds: inFilter(assigneeIds)
          };
        }
        if (assigneeIdsToExclude) {
          tasksQuery.filter = {
            ...tasksQuery.filter,
            assigneeIds: niFilter(assigneeIdsToExclude)
          };
        }
        if (createdById) {
          tasksQuery.filter = {
            ...tasksQuery.filter,
            createdById: eqFilter(createdById)
          };
        }

        if (isPresent(isAssigned)) {
          tasksQuery.filter = {
            ...tasksQuery.filter,
            isAssigned: eqFilter(isAssigned!)
          };
        }

        const response = await this.store.customQuery(
          SkuResourceName.singular,
          TaskResourceName.plural,
          undefined,
          tasksQuery
        );

        return normalize(SkuTaskResourceName.singular, response) as UTask[];
      } catch (error) {
        defaultErrorHandler(error);
        throw error;
      }
    }
  );

  // CRUD METHODS
  saveTask = enqueueTask(async (task: UTask) => {
    try {
      if (task.get('hasDirtyAttributes')) {
        const payload = taskToCommand(task);
        const TaskModel = TaskService.TaskModel(task);

        if (TaskModel) {
          if (!task.id) {
            return (await TaskModel.createTask(payload)) as UTask;
          } else {
            return (await TaskModel.updateTask(payload)) as UTask;
          }
        }
      }

      return task;
    } catch (error) {
      defaultErrorHandler(error);
      throw error;
    }
  });

  resolveTask = enqueueTask(async (task: UTask) => {
    return this.onResolve.perform(task, false);
  });

  reopenTask = enqueueTask(async (task: UTask) => {
    return this.onResolve.perform(task, true);
  });

  onResolve = enqueueTask(async (task: UTask, isResolved: boolean) => {
    try {
      if (task.get('hasDirtyAttributes')) {
        const payload = {
          taskId: task.id,
          linkedEntityId: task.linkedEntityId
        };

        if (task instanceof SkuTask) {
          return isResolved
            ? ((await SkuTask.reopenTask(payload)) as UTask)
            : ((await SkuTask.resolveTask(payload)) as UTask);
        }
      }
      return task;
    } catch (error) {
      defaultErrorHandler(error);
      throw error;
    }
  });

  deleteTask = enqueueTask(async (task: UTask) => {
    try {
      const payload = taskToCommand(task);
      const TaskModel = TaskService.TaskModel(task);

      if (task.id) {
        if (TaskModel) {
          const savedTask = (await TaskModel.deleteTask(payload)) as UTask;
          task.deleteRecord();
          return savedTask;
        }
      } else {
        task.deleteRecord();
      }
      return task;
    } catch (error) {
      defaultErrorHandler(error);
      throw error;
    }
  });

  /**
   * Returns a query filter that can be used to filter tasks
   * @param assignTo AssignTo is a string that can be 'assignments', 'assignings' or 'unassigned'
   * @returns A query filter that can be used to filter tasks
   */
  assignQueryFilter(assignTo?: TAssignTo): FetchTaskQuery {
    switch (assignTo) {
      case 'assignments':
        return { assigneeIds: [this.sessionUser.userId] };
      case 'assignings':
        return {
          createdById: this.sessionUser.userId,
          assigneeIdsToExclude: [this.sessionUser.userId],
          isAssigned: true
        };
      case 'unassigned':
        return { createdById: this.sessionUser.userId, isAssigned: false };
      default:
        return {};
    }
  }

  private static TaskModel(task: UTask) {
    let TaskModel: typeof PoTask | typeof SkuTask | undefined;

    if (task instanceof SkuTask) {
      TaskModel = SkuTask;
    } else {
      TaskModel = PoTask;
    }
    return TaskModel;
  }

  /**
   * Returns an API query filter that can be used to filter tasks
   * @param queryFilters Fetch task query filters
   * @returns An API query filter that can be used to filter tasks
   */
  static queryFiltersToApiFilters(queryFilters: FetchTaskQuery) {
    const apiFilters: IJsonApiQuery['filter'] = {};

    Object.entries(queryFilters).forEach(([key, values]) => {
      if (values) {
        if (Array.isArray(values)) {
          apiFilters[key] = inFilter(values);
        } else {
          apiFilters[key] = eqFilter(values);
        }
      }
    });

    return apiFilters;
  }

  static indexTasksByEntity(sortedTasks: UTask[]) {
    return groupBy(sortedTasks, (t: UTask) => t.linkedEntityId);
  }

  static indexTasksByDate(
    sortedTasks: UTask[],
    noDateId: string,
    groupingField: keyof Task = 'dueDate'
  ) {
    return groupBy(sortedTasks, (t: UTask) =>
      groupingField === 'dueDate'
        ? ((t.dueDate ? moment(t.dueDate, 'YYYY-MM-DD').format('L') : noDateId) as string)
        : moment(t.createdAt, 'YYYY-MM-DD').format('L')
    );
  }

  static indexTasksByDateAndEntity(
    sortedTasks: UTask[],
    noDateId: string,
    groupingField: keyof Task = 'dueDate'
  ) {
    if (!sortedTasks.length) {
      return {};
    }
    const dates: string[] = [];
    return sortedTasks.reduce((acc: Record<string, UTask[]>, t: UTask) => {
      let formattedDate =
        groupingField === 'dueDate'
          ? t.dueDate
            ? moment(t.dueDate, 'YYYY-MM-DD').format('L')
            : noDateId
          : moment(t.createdAt, 'YYYY-MM-DD').format('L');
      let alreadyExists = dates.includes(formattedDate);

      if (!alreadyExists) {
        dates.push(formattedDate);
        formattedDate = formattedDate + '|' + t.linkedEntityId;
      } else {
        formattedDate = t.linkedEntityId;
      }

      if (acc[formattedDate]) {
        acc[formattedDate].push(t);
      } else {
        acc[formattedDate] = [t];
      }

      return acc;
    }, {});
  }
}

declare module '@ember/service' {
  interface Registry {
    'task-service': TaskService;
  }
}
