import { BufferedChangeset } from 'ember-changeset/types';
import { restartableTask } from 'ember-concurrency';
import { ModelRegistry } from 'ember-data/model';
import CollectionView, {
  CollectionViewResourceName,
  viewIsLocked
} from 'volta/models/collection-view';
import User from 'volta/models/user';
import UserProfile from 'volta/models/user-profile';
import UserRole from 'volta/models/user-role';
import { IJsonApiQuery } from 'volta/utils/api/jsonapi-types';
import { normalize } from 'volta/utils/api/serialize-and-push';
import { distinct, indexByFn } from 'volta/utils/array-utils';
import { defaultErrorHandler } from 'volta/utils/error-utils';
import { niFilter } from 'volta/utils/filters-utils';

import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import { ICollectionArgs } from '../../../models/types/collection-view.d';
import { ICollectionViewSortListArgs } from '../sort-list';

import type IntlService from 'ember-intl/services/intl';
import type StoreService from 'volta/services/store';
import type CustomFieldDefinitionsService from 'volta/services/custom-field-definitions-service';
import type SessionUserService from 'volta/services/session-user';
import type {
  ICollectionFilter,
  ICollectionProperty,
  ICollectionViewDefinition,
  ICollectionViewEditCmd,
  TCollectionPropertyForUI,
  TCollectionType,
  TCreationMode
} from 'volta/models/types/collection-view';

interface IArgs extends ICollectionViewSortListArgs {
  isCreateMode: boolean;
  view?: CollectionView;
  filterDefinitions: TFilterDefinition[];
  collectionDefinition: ICollectionViewDefinition;
  settingsSection: string;
  metadata?: object;
  onFilterChange?: (value: TFilterValue, field: string) => void;
  onPropertyChange?: (props: ICollectionProperty[]) => void;
  onGroupByChange?: (fields: string[]) => void;
  onComponentArgsChange?: (settings: ICollectionArgs) => void;
  onCreate?: () => void;
  onEdit?: () => void;
  onClose: () => void;
  changeset: BufferedChangeset & ICollectionViewEditCmd;
}

export default class CollectionViewSettings extends Component<IArgs> {
  @service intl!: IntlService;
  @service store!: StoreService;
  @service sessionUser!: SessionUserService;
  @service customFieldDefinitionsService!: CustomFieldDefinitionsService;

  @tracked creationMode?: TCreationMode;
  @tracked changeset!: BufferedChangeset;
  @tracked templates: Partial<CollectionView>[] = [this.args.collectionDefinition.defaultView];
  @tracked selectedTemplateId?: string | 'default';
  @tracked settingsSection: string | undefined = this.args.settingsSection;
  @tracked selectedUsers: UserProfile[] = [];
  @tracked usersList: UserProfile[] = this.args.view?.showForUsers?.toArray?.() ?? [];
  @tracked lockedIsChecked = false;
  @tracked lockedIsDirty = false;

  resourceName = CollectionViewResourceName;
  elementId = guidFor(this);

  get lockedError() {
    if (!this.lockedIsChecked) {
      return undefined;
    }

    return !!(this.cs.get('showForRoles') ?? []).length ||
      !!(this.cs.get('showForUsers') ?? []).length
      ? undefined
      : this.intl.t('collectionViewsPage.errorDefaultTemplate');
  }

  get cs() {
    return this.args.changeset;
  }

  get selectedTemplate() {
    return this.templates.find(
      (template) => (template.id ?? 'default') === this.selectedTemplateId
    );
  }

  get isLocked() {
    if (this.args.isCreateMode || !this.args.view) {
      return false;
    }
    return viewIsLocked(this.args.view, this.sessionUser.userId);
  }

  get showViewPanel() {
    return Boolean(this.args.collectionDefinition.argsComponent);
  }

  get typeBlocks() {
    type TTypeBlock = { value: TCollectionType; title: string; icon: string };
    return [
      { value: 'TABLE', title: this.intl.t('collectionViewsPage.types.table'), icon: 'table-view' },
      {
        value: 'KANBAN',
        title: this.intl.t('collectionViewsPage.types.kanban'),
        icon: 'kanban-view'
      },
      {
        value: 'TIMELINE',
        title: this.intl.t('collectionViewsPage.types.timeline'),
        icon: 'timeline-view'
      },
      { value: 'LIST', title: this.intl.t('collectionViewsPage.types.list'), icon: 'list-view' }
    ].filter((type: TTypeBlock) => this.args.collectionDefinition.types.includes(type.value));
  }

  @cached
  get indexedDefinitions() {
    return indexByFn(this.args.collectionDefinition.definitions, (d) => d.property ?? d.valuePath);
  }

  @cached
  get indexedCurrentProperties() {
    return indexByFn(
      this.args.changeset.get('properties') as ICollectionProperty[],
      ({ property }) => property
    );
  }

  @cached
  get properties() {
    const props = this.indexedCurrentProperties;
    const defs = this.indexedDefinitions;
    const properties: TCollectionPropertyForUI[] = [];

    Object.values(props).forEach((p) => {
      const def = defs[p.property];
      if (def) {
        properties.push({ ...p, color: def.color, disabled: false, isStatic: def.isStatic });
      }
    });

    Object.values(defs).forEach((d) => {
      const notPresent = Boolean(!props[d.property ?? d.valuePath]);
      if (this.args.isCreateMode || notPresent) {
        properties.push({
          property: d.property ?? d.valuePath,
          title: d.columnName,
          isCustomField: d.isCustomField ?? false,
          disabled: this.args.isCreateMode ? false : notPresent,
          isFixed: d.isFixed,
          isGroupable: d.isGroupable,
          color: d.color
        });
      }
    });
    return properties;
  }

  @cached
  get groupableProperties() {
    const groupBy = (this.args.changeset.get('groupBy') ?? []) as string[];
    const props = Object.entries(this.indexedCurrentProperties).reduce(
      (acc: Record<string, ICollectionProperty>, [key, p]) => {
        if (groupBy.includes(key)) {
          acc[key] = p;
        }
        return acc;
      },
      {}
    );
    const defs = this.indexedDefinitions;
    const properties: TCollectionPropertyForUI[] = [];

    Object.values(props).forEach((p) => {
      const def = defs[p.property];
      if (def && def.isGroupable) {
        properties.push({ ...p, disabled: false });
      }
    });

    Object.values(defs).forEach((d) => {
      const notPresent = Boolean(!props[d.property ?? d.valuePath]);
      if ((this.args.isCreateMode || notPresent) && d.isGroupable) {
        properties.push({
          property: d.property ?? d.valuePath,
          title: d.columnName,
          isCustomField: d.isCustomField ?? false,
          disabled: this.args.isCreateMode ? false : notPresent,
          color: d.color
        });
      }
    });
    return properties;
  }

  constructor(owner: any, args: IArgs) {
    super(owner, args);
    const { view } = this.args;
    this.lockedIsChecked = view
      ? (!!(view.showForUsers ?? []).length || !!(view.showForRoles ?? []).length) &&
        !view.isTemplate
      : false;
  }

  @action
  selectTemplate(selectedTemplate: CollectionView) {
    let template: ICollectionViewEditCmd | undefined = undefined;
    this.selectedTemplateId = selectedTemplate.id ?? 'default';
    template = { ...selectedTemplate.toCreateCmd(), ...selectedTemplate.toShareCmd() };
    Object.entries(template).forEach(([key, value]) => {
      this.args.changeset.set(key, value);
    });
  }

  @action
  toggleAccordeon(id: string, checked: boolean) {
    if (checked) {
      this.settingsSection = id;
    } else {
      this.settingsSection = undefined;
    }
  }

  @action
  changeCreationMode(mode: TCreationMode) {
    this.creationMode = mode;
    if (mode === 'manual') {
      this.args.changeset.rollback();
    }
  }

  @action
  decorateUserQuery(query: IJsonApiQuery) {
    const { filter = {} } = query;
    filter.userType = niFilter(['SUPPLIER', 'API']);
    query.filter = filter;
    return query;
  }

  @action
  setUser(userId: string, [_user]: [User]) {
    this.args.changeset.set(
      'showForUsers',
      distinct([...(this.args.changeset.get('showForUsers') ?? []), userId]).flat()
    );
  }

  @action
  removeUsers() {
    const users = indexByFn(this.selectedUsers, (u) => u.id);
    this.usersList = this.usersList.filter((u) => !users[u.id]);
    this.args.changeset.set(
      'showForUsers',
      this.usersList.map((u) => u.id)
    );
    this.selectedUsers = [];
  }

  @action
  changeShowForRoles(ids: string[], _roles: UserRole[]) {
    this.args.changeset.set('showForRoles', ids);
    this.args.changeset.set('isTemplate', !this.lockedIsChecked);
  }

  @action
  changeShowForUsers(ids: string[], users: UserProfile[]) {
    this.usersList = users;
    this.args.changeset.set('showForUsers', ids);
    this.args.changeset.set('isTemplate', !this.lockedIsChecked);
  }

  @action
  changeLocked(checked: boolean) {
    this.lockedIsChecked = checked;
    this.lockedIsDirty = true;
    this.args.changeset.set('isTemplate', !this.lockedIsChecked);
  }

  @action
  onCreate() {
    if (this.creationMode === 'template') {
      this.args.changeset.set(
        'name',
        this.args.changeset.get('name') + ` (${this.intl.t('copySingular')})`
      );
    }

    this.args.onCreate?.();

    if (this.args.changeset.isValid) {
      this.args.onClose();
    }
  }

  @action
  onEdit() {
    this.args.onEdit?.();
    this.args.onClose();
  }

  @action
  scrollToSection(section: string = 'general') {
    const elementToScrollTo = document.getElementById(this.sectionId(section));

    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  // Tasks

  fetchTemplates = restartableTask(async () => {
    try {
      const defaultView = this.store.createRecord(CollectionViewResourceName.singular, {
        ...this.args.collectionDefinition.defaultView
      });
      const data = await this.store.rawQuery(
        CollectionViewResourceName.plural,
        ['mine', defaultView.entityType as string, 'templates'],
        { include: 'showForUsers,showForRoles' }
      );
      const templates = normalize(
        CollectionViewResourceName.singular as keyof ModelRegistry,
        data
      ) as CollectionView[];
      this.templates = [defaultView, ...templates];
      this.selectTemplate(defaultView);
      return templates;
    } catch (e) {
      defaultErrorHandler(e);
      return;
    }
  });

  // helpers
  initialValue(filters: ICollectionFilter[] = [], queryParam: string) {
    return filters.find((filter: ICollectionFilter) => filter.property === queryParam);
  }

  sectionId = (id: string) => `${this.elementId}${id}`;
}
