// @ts-ignore
import calculatePosition from 'ember-basic-dropdown/utils/calculate-position';
import { closest, outerHeight, windowSize } from 'volta/utils/dom-utils';

import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';

// @ts-ignore
import type { CalculatePositionResult } from 'ember-basic-dropdown/utils/calculate-position';
// @ts-ignore
import type { Dropdown } from 'ember-basic-dropdown/components/basic-dropdown';
import type { TSize } from 'volta/utils/style-utils';

const SIZES: TSize[] = ['sm', 'md', 'xl', 'lg'];

interface IArgs {
  size?: TSize;
  renderInPlace?: boolean;
  triggerRole?: string;
  required?: string;
  tabindex?: string;
  disabled?: boolean;
  applyCustomPosition?: (calcResult: CalculatePositionResult) => CalculatePositionResult;
  onClose?: (ddApi: Dropdown, e?: PointerEvent | KeyboardEvent) => void;
  onOpen?: (ddApi: Dropdown, e?: PointerEvent) => void;
  onInit?: (ddApi: Dropdown) => void;
}

/**
 * @typedef {Object} BvPopoverArgs
 * @property {string} popover content width (see SIZES)
 * @property {boolean} isMenu Is the popover a menu
 * @property {string} verticalPosition
 * @property {string} horizontalPosition
 * @property {boolean} matchTriggerWidth
 * @property {boolean} renderInPlace
 * @property {boolean} showCloseButton
 */
export default class BvPopover extends Component<IArgs> {
  uniqueId = `${guidFor(this)}BvPopover`;
  dropdownId!: string;
  triggerId!: string;

  get size() {
    const { size } = this.args;
    return size && SIZES.includes(size) ? size : undefined;
  }

  @action
  onInit(publicAPI: Dropdown) {
    this.uniqueId = publicAPI.uniqueId;
    this.dropdownId = `ember-basic-dropdown-content-${this.uniqueId}`;
    this.triggerId = `${this.uniqueId}-trigger`;
    return this.args.onInit?.(publicAPI);
  }

  /**
   * Makes sure the popover is not taller than the available height
   * @param {HTMLElement} content The .bv-popover DOM element
   */
  @action
  calculatePosition(_trigger: HTMLElement, content: HTMLElement) {
    // @ts-ignore
    const pos: CalculatePositionResult = calculatePosition(...arguments);

    if (content && !this.args.renderInPlace) {
      // Here we have to use height property and not max-height because of
      // how IE 11 behaves when a container only has a max-height value and
      // children elements use flexbox layout
      content.style.height = 'auto';
      const windowHeight = windowSize().height;
      const elHeight = outerHeight(content);
      const maxHeight = windowHeight - (pos.style.top ?? 0);
      pos.style.height = elHeight >= maxHeight ? maxHeight : undefined;
      pos.style['max-height'] = maxHeight + 'px';

      if (this.args.applyCustomPosition) {
        return this.args.applyCustomPosition(pos);
      }
    }

    return pos;
  }

  @action
  handleOpen(ddApi: Dropdown, e?: PointerEvent) {
    return this.args.onOpen?.(ddApi, e);
  }

  @action
  handleClose(ddApi: Dropdown, e?: PointerEvent | KeyboardEvent) {
    if (e?.target && elementIsInChild(e.target as HTMLElement, this.dropdownId)) {
      return false;
    }
    return this.args.onClose?.(ddApi, e);
  }

  /**
   * Helpers
   */

  triggerModifier = (element: HTMLElement) => {
    element.setAttribute('data-ebd-id', this.triggerId);
    element.setAttribute('aria-owns', this.dropdownId);
    element.setAttribute('role', this.args.triggerRole ?? 'button');
    element.setAttribute('aria-required', `${this.args.required ?? false}`);

    if (!this.args.disabled) {
      element.setAttribute('tabindex', this.args.tabindex || '0');
    }

    return () => {};
  };
}

/**
 * Evaluates if the given element is in a child dropdown.
 *
 * @param {HTMLElement} el
 * @param {String} dropdownId
 */
function elementIsInChild(el: HTMLElement, dropdownId: string): boolean {
  const currentDD = document.getElementById(dropdownId);
  return elementIsInDropDown(el, currentDD);
}

function elementIsInDropDown(el?: HTMLElement | null, currentDD?: HTMLElement | null): boolean {
  if (!currentDD || !el) {
    return false;
  }

  // 1. Get closest parent drop down
  const parentDD = closest(el, '.ember-basic-dropdown-content');
  // 2. Get the parent trigger
  const parentTrigger = parentDD?.id
    ? document.querySelector(`[aria-owns=${parentDD?.id}]`)
    : undefined;
  // 3. Is in current Dropdown
  const isInCurrentDD = parentTrigger && currentDD?.contains(parentTrigger);

  if (isInCurrentDD) {
    return true;
  } else if (parentTrigger) {
    return elementIsInDropDown(parentTrigger as HTMLElement, currentDD);
  } else return false;
}
