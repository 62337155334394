import { classNames, sanitizeCustomProperties } from 'volta/utils/style-utils';

import { getResponsiveProps, ResponsiveProp, SpaceScale } from '@bevolta/design-tokens';
import Component from '@glimmer/component';

type Align = 'start' | 'end' | 'center' | 'baseline';

type Element = 'div' | 'ul' | 'ol' | 'fieldset';

type Gap = ResponsiveProp<SpaceScale>;

interface IArgs {
  /** HTML Element type
   * @default 'div'
   */
  as?: Element;
  /** Horizontal alignment of children */
  align?: Align;
  /** The spacing between children */
  gap?: Gap;
  /** Reverse the render order of child items
   * @default false
   */
  reverseOrder?: boolean;
}

export default class BvStack extends Component<IArgs> {
  get style() {
    const { align, gap, reverseOrder = false } = this.args;
    return sanitizeCustomProperties({
      '--bv-stack-align': align ? `${align}` : '',
      '--bv-stack-order': reverseOrder ? 'column-reverse' : 'column',
      ...getResponsiveProps('stack', 'gap', 'spacing', gap)
    });
  }
  get classnames() {
    const { as } = this.args;

    return classNames([
      'bv-stack',
      (as === 'ul' || as === 'ol') && 'bv-stack--list-reset',
      as === 'fieldset' && 'bv-stack--fieldset-reset'
    ]);
  }
}
