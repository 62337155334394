import ApplicationSerializer from 'volta/_pods/application/serializer';

import { normalizeModelName } from '@ember-data/store';

export default class PoTaskSerializer extends ApplicationSerializer {
  modelNameFromPayloadKey(key: string) {
    if (key === 'tasks') {
      return normalizeModelName('po-task');
    }
    return super.modelNameFromPayloadKey(key);
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'po-task': PoTaskSerializer;
  }
}
