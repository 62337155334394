import _isNil from 'lodash/isNil';

import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';

export interface IInputFilterDefinitionArgs {
  inputType?: 'text' | 'number' | 'date';
}
export default class InputFilter extends Component<IFilterArgs<IInputFilterDefinitionArgs>> {
  get values() {
    return this.args.values ?? [];
  }

  get inputType() {
    return this.args.componentArgs?.inputType ?? 'text';
  }

  get leftValue() {
    return this.value;
  }

  get value() {
    return this.values?.length ? this.values[0] : undefined;
  }

  get rightValue() {
    return this.values?.length === 2 ? this.values[1] : undefined;
  }

  get isFaulty() {
    if (_isNil(this.leftValue) || _isNil(this.rightValue) || this.inputType !== 'number') {
      return false;
    }
    return this.leftValue > this.rightValue;
  }

  @action
  valueDidChange(newValue?: string) {
    this.args.onChange?.(isPresent(newValue) ? [newValue as string] : []);
  }

  @action
  valueLeftDidChange(leftValue?: string) {
    const { values = [] } = this;
    if (isPresent(leftValue)) {
      values[0] = leftValue as string;
      if (values.length === 2) {
        this.args.onChange?.(values);
      }
    }
  }

  @action
  valueRightDidChange(rightValue?: string) {
    const { values = [] } = this;
    if (isPresent(rightValue)) {
      values[1] = rightValue as string;
      if (values.length === 2) {
        this.args.onChange?.(values);
      }
    }
  }
}
