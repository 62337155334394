import { tracked } from '@glimmer/tracking';

import type PlanningModel from './planning';
import type { IBuffer, IPlanningBuffer } from './types/planning';

export default class Buffer {
  @tracked green!: number;
  @tracked yellow!: number;
  @tracked redBase!: number;
  @tracked redSafety!: number;
  @tracked topOfGreen!: number;
  @tracked topOfRed!: number;
  @tracked topOfYellow!: number;
  @tracked adu?: number;

  get avgOnHand() {
    return this.topOfRed + this.green * 0.5;
  }

  constructor(args: IBuffer | IPlanningBuffer) {
    if (args) {
      this.green = args.green;
      this.yellow = args.yellow;
      this.redBase = args.redBase;
      this.redSafety = args.redSafety;
      this.topOfGreen = args.topOfGreen;
      this.topOfYellow = args.topOfYellow;
      this.topOfRed = args.topOfRed;
    }
  }

  static createFromPlanning(planning: PlanningModel) {
    const newBuffer = new Buffer(planning.buffer);
    newBuffer.adu = planning.adu;
    return newBuffer;
  }
}
