import { A } from '@ember/array';
import ArrayProxy from '@ember/array/proxy';
import { typeOf } from '@ember/utils';

import InfinityModel from 'ember-infinity/lib/infinity-model';
import { resolve } from 'rsvp';
import { IJsonApiQuery } from 'volta/utils/api/jsonapi-types';

export function decorateApiQuery(query: IJsonApiQuery = {}, perPage = 50) {
  const decoratedQuery = {
    ...query,
    perPage,
    startingPage: 0,
    perPageParam: 'page[limit]',
    pageParam: 'page[offset]',
    countParam: 'meta.totalCount',
    totalPagesParam: 'meta.totalPages'
  };
  delete decoratedQuery.page;

  return decoratedQuery;
}
export default class JsonApiInfinityModel extends InfinityModel {
  buildParams(increment: number) {
    const pageParams = super.buildParams(increment);

    if (typeOf(this.pageParam) === 'string' && this.pageParam.indexOf('offset') > -1) {
      pageParams[this.pageParam] = (this.currentPage + increment) * this.perPage;
    }

    return pageParams;
  }

  afterInfinityModel(newObjects: ArrayProxy<any> & { meta: any }, infinityModel: InfinityModel) {
    return resolve(
      ArrayProxy.create({
        content: A(newObjects.filter((o) => !infinityModel.includes(o))),
        meta: newObjects.get('meta')
      })
    );
  }
}
