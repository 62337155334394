import moment from 'moment-timezone';
import BaseModel, { IResourceName } from 'volta/models/base-model';
import { _normalize, collectionCommand, resourceCommand as command } from 'volta/utils/api';
import { indexByFn } from 'volta/utils/array-utils';
import { parseNumber } from 'volta/utils/math-utils';
import {
  colorForAlertCriticality,
  colorForCategory,
  getBufferZoneCriticality,
  THue
} from 'volta/utils/style-utils';
import { hashCode } from 'volta/utils/text-utils';

import { attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

import type { TAcquisitionCode } from './constants/acquisition-codes';
import type {
  ICommitManyCmd,
  IPOCmd,
  TPOPriorityOption,
  IChangeManyRoadmaps,
  IChangeManyStatus,
  IChangeOneStatus,
  IChangeRoadmap,
  ICreateDraftOrder,
  ICreateDraftOrderFromSku,
  IDraftPlanningProjections,
  IManyPlanningOrdersUpdatedResult,
  IPlanningOrderSKU,
  ISchedulingAlertSummary,
  ISyncAlertSummary,
  IUpdateExternalData,
  IUpdateManyCmd,
  IUpdateOrderNum,
  IUpdatePlanningOrder,
  TPOProposedValues,
  TPOStatus,
  TPOStatusOption,
  TPOValidationStatus,
  TPOValidationStatusOption,
  TSyncAlerts
} from './types/planning-order';
import type { IAggTasksViewMap } from './types/tasks';
import type { IWorkshopIdentityInfo } from './types/workshop';
import type { ISupplierIdentityInfo } from 'volta/models/types/supplier';
import type { IWarehouseIdentityInfo } from 'volta/models/types/warehouse';
import type { HookClass } from 'volta/utils/api/types';
import type { ICmdResult } from 'volta/utils/api/jsonapi-types';
import type { TPOField } from './po-group';
import type { TCustomFieldValues } from 'volta/models/types/customFieldDefinition';
export interface IPOKeyObject {
  orderHash: string;
  orderKey: string;
  num?: string;
  warehouse: IWarehouseIdentityInfo;
  acqCode: TAcquisitionCode;
  supplier?: ISupplierIdentityInfo;
  workshop?: IWorkshopIdentityInfo;
  deliveryWorkshop?: IWorkshopIdentityInfo;
  externalOrderNum?: string;
}

export const SyncAlerts: Record<TSyncAlerts, number> = {
  CRITICAL: 0,
  WARNING: 1,
  NONE: 2
};

export const SyncAlertOptions = Object.keys(SyncAlerts).map((k: TSyncAlerts) => {
  return {
    key: k,
    value: SyncAlerts[k],
    label: `syncAlerts.${k}`,
    icon: 'sync-outline',
    color: colorForCategory(k)
  };
});

export const SchedulingAlertOptions = [0, 1, 2, 3].map((k: number) => {
  return {
    key: k,
    value: k,
    label: `poWorkbench.alerts.scheduling.${k}.title`,
    icon: 'time-outline',
    color: colorForAlertCriticality(k)
  };
});

export const StatusesArray: TPOStatusOption[] = [
  ['NEW', 'gray', 0],
  ['DRAFT', 'orange', 0],
  ['LAUNCHED', 'blue', 25],
  ['COMMITTED', 'indigo', 50],
  ['SENT', 'purple', 75],
  ['FINISHED', 'gray', 100],
  ['CANCELLED', 'red', 0],
  ['PROJECTED', 'teal', 0],
  ['PROJECTED_FIRM', 'blue', 0]
].map(([value, color, progress]: [TPOStatus, THue, number], index: number) => ({
  index: index + 1,
  value,
  key: value,
  color,
  progress,
  label: `planningOrderStatuses.${value}`
}));

export const Statuses: Record<TPOStatus, TPOStatusOption> = indexByFn(
  StatusesArray,
  (s) => s.value
);

const poValidationStatuses = (status: TPOValidationStatus, isSup: boolean) =>
  `poValidationStatuses.${isSup ? 'supplier' : 'customer'}.${status}`;

export const POValidationStatusesArray: TPOValidationStatusOption[] = [
  ['CUSTOMER_PROPOSAL', 'red', 0, false],
  ['CUSTOMER_REFUSAL', 'red', undefined, true],
  ['CUSTOMER_APPROVAL', 'green', 100, false],
  ['SUPPLIER_DRAFT', 'orange', 25, false],
  ['SUPPLIER_PROPOSAL', 'purple', 50, false],
  ['AUTO_VALIDATED', 'green', 100, false]
].map(
  (
    [value, color, progress, isError]: [TPOValidationStatus, THue, number, boolean],
    index: number
  ) => ({
    index: index + 1,
    key: value,
    value,
    color,
    progress,
    isError,
    label: undefined,
    customerLabel: poValidationStatuses(value, false),
    supplierLabel: poValidationStatuses(value, true)
  })
);

export const POValidationStatuses: Record<TPOValidationStatus, TPOValidationStatusOption> =
  indexByFn(POValidationStatusesArray, (a) => a.value);

export const HiddenStatuses = [
  Statuses.NEW.value,
  Statuses.FINISHED.value,
  Statuses.CANCELLED.value,
  Statuses.PROJECTED.value,
  Statuses.PROJECTED_FIRM.value
];

export const FirmPOStatuses: TPOStatus[] = [
  Statuses.LAUNCHED.value,
  Statuses.FINISHED.value,
  Statuses.COMMITTED.value,
  Statuses.CANCELLED.value,
  Statuses.SENT.value
];

export const SupplierPortalStatuses: TPOStatus[] = [
  Statuses.LAUNCHED.value,
  Statuses.COMMITTED.value,
  Statuses.SENT.value
];

export const VisibleStatusOptions: TPOStatusOption[] = StatusesArray.filter(
  (s) => !HiddenStatuses.includes(s.value)
);

export const POResourceName: IResourceName = {
  singular: 'planningOrder',
  plural: 'planningOrders'
};

export const Commands = {
  // One
  AcceptOneSupplierProposal: 'AcceptOneSupplierProposal',
  ChangeRoadmap: 'ChangeRoadmap',
  CommitOne: 'CommitOne',
  CreateDraftOrder: 'CreateDraftOrder',
  CreateDraftOrderFromSku: 'CreateDraftOrderFromSku',
  DeleteOne: 'DeleteOne',
  DraftOne: 'DraftOne',
  FinishOne: 'FinishOne',
  LaunchOne: 'LaunchOne',
  RefuseOneSupplierProposal: 'RefuseOneSupplierProposal',
  SendOne: 'SendOne',
  SendOneSupplierProposal: 'SendOneSupplierProposal',
  SplitOne: 'SplitOne',
  UpdateExternalData: 'UpdateExternalData',
  UpdateOrderNum: 'UpdateOrderNum',
  UpdatePlanningOrder: 'UpdatePlanningOrder',
  // Many
  AcceptManySupplierProposals: 'AcceptManySupplierProposals',
  RefuseManySupplierProposals: 'RefuseManySupplierProposals',
  ChangeManyRoadmaps: 'ChangeManyRoadmaps',
  CommitMany: 'CommitMany',
  DeleteMany: 'DeleteMany',
  DraftPlanningProjections: 'DraftPlanningProjections',
  DraftProjections: 'DraftProjections',
  ExplodeMany: 'ExplodeMany',
  FinishMany: 'FinishMany',
  LaunchMany: 'LaunchMany',
  SendMany: 'SendMany',
  SendManySupplierProposals: 'SendManySupplierProposals',
  UpdateMany: 'UpdateMany'
};

export const POEvents = {
  // One
  PlanningOrderCancelled: 'PlanningOrderCancelled',
  PlanningOrderCreated: 'PlanningOrderCreated',
  PlanningOrderDeleted: 'PlanningOrderDeleted',
  PlanningOrderExternalDataChanged: 'PlanningOrderExternalDataChanged',
  PlanningOrderFinished: 'PlanningOrderFinished',
  PlanningOrderRoadmapChanged: 'PlanningOrderRoadmapChanged',
  PlanningOrderCommitted: 'PlanningOrderCommitted',
  PlanningOrderSent: 'PlanningOrderSent',
  PlanningOrderLaunched: 'PlanningOrderLaunched',
  PlanningOrderSplit: 'PlanningOrderSplit',
  PlanningOrderSupplierProposed: 'PlanningOrderSupplierProposed',
  PlanningOrderSupplierDrafted: 'PlanningOrderSupplierDrafted',
  PlanningOrderSupplierSplit: 'PlanningOrderSupplierSplit',
  PlanningOrderSupplierProposalRefused: 'PlanningOrderSupplierProposalRefused',
  PlanningOrderUpdated: 'PlanningOrderUpdated',
  // Many
  PlanningOrdersCommitted: 'PlanningOrdersCommitted',
  PlanningOrdersDeleted: 'PlanningOrdersDeleted',
  PlanningOrdersDrafted: 'PlanningOrdersDrafted',
  PlanningOrdersSplit: 'PlanningOrdersSplit',
  PlanningOrderRoadmapsChanged: 'PlanningOrderRoadmapsChanged',
  PlanningOrdersExternalDataChanged: 'PlanningOrdersExternalDataChanged',
  PlanningOrdersFinished: 'PlanningOrdersFinished',
  PlanningOrdersLaunched: 'PlanningOrdersLaunched',
  PlanningOrdersOrderNumChanged: 'PlanningOrdersOrderNumChanged',
  PlanningOrdersSent: 'PlanningOrdersSent',
  PlanningOrdersSupplierDrafted: 'PlanningOrdersSupplierDrafted',
  PlanningOrdersSupplierProposed: 'PlanningOrdersSupplierProposed',
  PlanningOrdersSupplierProposalsRefused: 'PlanningOrdersSupplierProposalsRefused',
  PlanningOrdersUpdated: 'PlanningOrdersUpdated'
};

export const POLifecycleEvents = [
  POEvents.PlanningOrderCommitted,
  POEvents.PlanningOrderSplit,
  POEvents.PlanningOrderExternalDataChanged,
  POEvents.PlanningOrderLaunched,
  POEvents.PlanningOrderSent,
  POEvents.PlanningOrderSupplierDrafted,
  POEvents.PlanningOrderSupplierProposed,
  POEvents.PlanningOrderSupplierProposalRefused,
  POEvents.PlanningOrderSupplierSplit,
  POEvents.PlanningOrderUpdated,
  POEvents.PlanningOrderRoadmapChanged
];

export const POsLifecycleEvents = [
  POEvents.PlanningOrdersCommitted,
  POEvents.PlanningOrdersDrafted,
  POEvents.PlanningOrdersSplit,
  POEvents.PlanningOrdersExternalDataChanged,
  POEvents.PlanningOrdersLaunched,
  POEvents.PlanningOrdersOrderNumChanged,
  POEvents.PlanningOrdersSent,
  POEvents.PlanningOrdersSupplierDrafted,
  POEvents.PlanningOrdersSupplierProposed,
  POEvents.PlanningOrdersSupplierProposalsRefused,
  POEvents.PlanningOrdersUpdated,
  POEvents.PlanningOrderRoadmapsChanged
];

export const POsSupplierEvents = [
  POEvents.PlanningOrdersSupplierDrafted,
  POEvents.PlanningOrdersSupplierProposed,
  POEvents.PlanningOrdersSupplierProposalsRefused
];

export const POSupplierEvents = [
  POEvents.PlanningOrderSupplierProposed,
  POEvents.PlanningOrderSupplierDrafted,
  POEvents.PlanningOrderSupplierSplit,
  POEvents.PlanningOrderSupplierProposalRefused
];

export const PODeletedEvents = [
  POEvents.PlanningOrderDeleted,
  POEvents.PlanningOrderCancelled,
  POEvents.PlanningOrderFinished
];

export const POCreatedEvents = [
  POEvents.PlanningOrderCreated,
  POEvents.PlanningOrderRoadmapChanged,
  POEvents.PlanningOrderSent,
  POEvents.PlanningOrderSplit,
  POEvents.PlanningOrderCommitted,
  POEvents.PlanningOrderUpdated
];

export const POQueries = {
  Users: 'users',
  WorkshopsCapacity: 'workshopsCapacity'
};

export const ExportableColumns = [
  'voltaOrderNum',
  'voltaOrderLine',
  'orderNum',
  'orderLine',
  'orderType',
  'warehouseCode',
  'partCode',
  'acqCode',
  'status',
  'reorderQty',
  'dueDate',
  'reorderDate',
  'deliveryDate',
  'supplierCode',
  'workshopCode',
  'deliveryWorkshopCode',
  'planningPriority',
  'executionPriority',
  'externalOrderNum',
  'unitPrice',
  'priority',
  'validationStatus',
  'currency'
];

export const POPriorityOptions: TPOPriorityOption[] = [
  {
    key: 'blocker',
    icon: 'remove-circle',
    iconColor: 'red',
    value: 5
  },
  {
    key: 'critical',
    icon: 'reorder-four',
    iconColor: 'red',
    value: 4
  },
  {
    key: 'high',
    icon: 'reorder-three',
    iconColor: 'orange',
    value: 3
  },
  {
    key: 'medium',
    icon: 'reorder-two',
    iconColor: 'yellow',
    value: 2
  },
  {
    key: 'low',
    icon: 'remove',
    iconColor: 'green',
    value: 1
  },
  {
    key: 'trivial',
    icon: 'ellipse-outline',
    iconColor: 'gray',
    value: 0
  },
  {
    key: 'none',
    icon: 'flag-outline',
    iconColor: 'gray',
    value: 'none'
  }
];

export const POSortMapping: Partial<Record<TPOField, string[]>> = {
  orderNum: ['num'],
  executionBuffer: ['executionInfo.ohaZone', 'executionInfo.oha'],
  planningBuffer: ['planningInfo.bufferZone', 'planningInfo.bufferPenetration'],
  schedulingAlert: ['alerts.scheduling.criticality'],
  syncAlert: ['alerts.synchronisation.criticality'],
  sku: ['skuInfo.part.description'],
  supplier: ['supplierInfo.code'],
  warehouse: ['skuInfo.warehouse.code'],
  workshop: ['workshopInfo.code'],
  partType: ['skuInfo.partType.code'],
  deliveryWorkshop: ['deliveryWorkshopInfo.code']
};

const POFieldMapping: Record<TPOField, (po: PlanningOrder) => unknown> = {
  acqCode: (po) => po.acqCode,
  parentPoId: (po) => po.parentPoId,
  planningId: (po) => po.planningId,
  createdBy: (po) => po.createdBy,
  deliveryDate: (po) => po.deliveryDate,
  dueDate: (po) => po.dueDate,
  reorderDate: (po) => po.reorderDate,
  groupId: (po) => po.groupId,
  id: (po) => po.id,
  orderNum: (po) => po.num,
  externalOrderNum: (po) => po.externalOrderNum,
  orderType: (po) => po.orderType,
  executionBuffer: (po) => po.executionInfo.ohaZone,
  planningBuffer: (po) => po.planningInfo.bufferZone,
  priority: (po) => po.priority,
  reorderQty: (po) => po.reorderQty,
  schedulingAlert: (po) => po.alerts.scheduling?.criticality,
  syncAlert: (po) => po.alerts.synchronisation?.criticality,
  sku: (po) => po.skuId,
  'sku.customFields': (po) => po.skuInfo.customFields,
  customFields: (po: PlanningOrder) => po.customFields,
  status: (po) => po.status,
  validationStatus: (po) => po.validationStatus,
  supplier: (po) => po.supplierId,
  warehouse: (po) => po.warehouseId,
  workshop: (po) => po.workshopId,
  partType: (po) => po.skuInfo.partType?.partTypeId,
  deliveryWorkshop: (po) => po.deliveryWorkshopId,
  g: (po) =>
    po.skuInfo.supplierSkuDescription +
    '__' +
    po.skuInfo.part.description +
    '__' +
    po.skuInfo.part.code
};

export const POPriorityOptionsDict = indexByFn(POPriorityOptions, (o) => o.value.toString());

const formatDate = (d: string | Date | moment.Moment) => moment(d).format('YYYY-MM-DD');

const noId = 'zzzzzzzz-zzzz-zzzz-zzzz-zzzzzzzzzzzz';
const noOrderNum = '00000000';

type NormalizeHook = HookClass<any, PlanningOrder[], typeof PlanningOrder>;
type NormalizeHookSingle = HookClass<any, PlanningOrder, typeof PlanningOrder>;

export default class PlanningOrder extends BaseModel {
  // TODO - Remove when investigation on
  // why it only disappears on Planning Order model is done
  static modelName = 'planning-order';

  /**
   * Collection commands
   */

  static createDraftOrderFromSku = collectionCommand<ICreateDraftOrderFromSku, PlanningOrder>(
    Commands.CreateDraftOrderFromSku,
    {
      after: _normalize as NormalizeHookSingle
    }
  );
  static draftProjections = collectionCommand<IChangeManyStatus, PlanningOrder[]>(
    Commands.DraftProjections,
    {
      after: _normalize as NormalizeHook
    }
  );
  static draftPlanningProjections = collectionCommand<IDraftPlanningProjections, PlanningOrder[]>(
    Commands.DraftPlanningProjections,
    {
      after: _normalize as NormalizeHook
    }
  );
  static createDraftOrder = collectionCommand<
    ICreateDraftOrder,
    ICmdResult<IManyPlanningOrdersUpdatedResult>
  >(Commands.CreateDraftOrder);
  static changeManyRoadmaps = collectionCommand<
    IChangeManyRoadmaps,
    ICmdResult<IManyPlanningOrdersUpdatedResult>
  >(Commands.ChangeManyRoadmaps);
  static launchMany = collectionCommand<
    IChangeManyStatus,
    ICmdResult<IManyPlanningOrdersUpdatedResult>
  >(Commands.LaunchMany);
  static commitMany = collectionCommand<
    ICommitManyCmd,
    ICmdResult<IManyPlanningOrdersUpdatedResult>
  >(Commands.CommitMany);
  static sendMany = collectionCommand<
    IChangeManyStatus,
    ICmdResult<IManyPlanningOrdersUpdatedResult>
  >(Commands.SendMany);
  static finishMany = collectionCommand<
    IChangeManyStatus,
    ICmdResult<IManyPlanningOrdersUpdatedResult>
  >(Commands.FinishMany);
  static deleteMany = collectionCommand<
    IChangeManyStatus,
    ICmdResult<IManyPlanningOrdersUpdatedResult>
  >(Commands.DeleteMany);
  static explodeMany = collectionCommand<
    IChangeManyStatus,
    ICmdResult<IManyPlanningOrdersUpdatedResult>
  >(Commands.ExplodeMany);
  static updateOrderNum = collectionCommand<
    IUpdateOrderNum,
    ICmdResult<IManyPlanningOrdersUpdatedResult>
  >(Commands.UpdateOrderNum);
  static updateExternalData = collectionCommand<
    IUpdateExternalData,
    ICmdResult<IManyPlanningOrdersUpdatedResult>
  >(Commands.UpdateExternalData);
  static acceptManySupplierProposals = collectionCommand<
    IChangeManyStatus,
    ICmdResult<IManyPlanningOrdersUpdatedResult>
  >(Commands.AcceptManySupplierProposals);
  static refuseManySupplierProposals = collectionCommand<
    IChangeManyStatus,
    ICmdResult<IManyPlanningOrdersUpdatedResult>
  >(Commands.RefuseManySupplierProposals);
  static sendManySupplierProposals = collectionCommand<
    IChangeManyStatus,
    ICmdResult<IManyPlanningOrdersUpdatedResult>
  >(Commands.SendManySupplierProposals);

  static updateMany = collectionCommand<
    IUpdateManyCmd,
    ICmdResult<IManyPlanningOrdersUpdatedResult>
  >(Commands.UpdateMany);

  /**
   * Resource commands
   */

  updatePlanningOrder = command<IUpdatePlanningOrder, PlanningOrder>(Commands.UpdatePlanningOrder);
  launchOne = command<IChangeOneStatus, PlanningOrder>(Commands.LaunchOne);
  draftOne = command<IChangeOneStatus, PlanningOrder>(Commands.DraftOne);
  commitOne = command<IChangeOneStatus, PlanningOrder>(Commands.CommitOne);
  sendOne = command<IChangeOneStatus, PlanningOrder>(Commands.SendOne);
  finishOne = command<IChangeOneStatus, PlanningOrder>(Commands.FinishOne);
  deleteOne = command<IPOCmd, PlanningOrder>(Commands.DeleteOne);
  split = command<IChangeOneStatus, PlanningOrder>(Commands.SplitOne);
  changeRoadmap = command<IChangeRoadmap, PlanningOrder>(Commands.ChangeRoadmap);
  acceptOneSupplierProposal = command<IPOCmd, PlanningOrder>(Commands.AcceptOneSupplierProposal);
  refuseOneSupplierProposal = command<IPOCmd, PlanningOrder>(Commands.RefuseOneSupplierProposal);
  sendOneSupplierProposal = command<IPOCmd, PlanningOrder>(Commands.SendOneSupplierProposal);

  /**
   * Planning id
   */
  @attr('string') planningId?: string;
  @attr('string') groupId?: string;
  @attr('string') parentPoId?: string;

  /**
   * Reorder quantity
   */
  @attr('number') reorderQty!: number;

  /**
   * Due date
   */
  @attr('date') dueDate!: Date;

  /**
   * Reorder date (due date - dlt)
   */
  @attr('date') reorderDate!: Date;

  /**
   * Internal due date (due date - ilt)
   */
  @attr('date') deliveryDate!: Date;

  /**
   * Optimal Flow Date (Next stock out date - 1)
   */
  @attr('date') optimalFlowDate?: Date;

  /**
   *  acqCode Acquisition code
   */
  @attr('string') acqCode!: TAcquisitionCode;

  /**
   * Status
   */
  @attr('string') status!: TPOStatus;

  /**
   *  PO order type
   */
  @attr('string') orderType?: string | 'STANDARD';

  /**
   *  VOLTA generated order num
   */
  @attr('string') voltaOrderNum?: string;

  /**
   *  VOLTA generated order line
   */
  @attr('number') voltaOrderLine?: number;

  /**
   *  Internal order num
   */
  @attr('string') orderNum?: string;

  /**
   *  Internal order line
   */
  @attr('string') orderLine?: string;

  /**
   *  External order num
   */
  @attr('string') externalOrderNum?: string;

  /**
   * Overridden unit price
   */
  @attr('number') unitPrice?: number;

  /**
   * Workshop capacity production cadence
   */
  @attr('number') cadence?: number;

  /**
   * User id of the creator
   */
  @attr('string') createdBy?: string;

  /**
   * User id of the last updator
   */
  @attr('string') updatedBy?: string;

  @attr('string') validationStatus?: TPOValidationStatus;

  @attr('boolean') forceValidation?: boolean;

  @attr('number') priority?: number;

  @attr('string') currency?: string;

  @attr() initialValues?: TPOProposedValues;

  @attr() proposedValues?: TPOProposedValues;

  /**
   * Condensed Planning data
   */
  @attr() planningInfo!: { bufferPenetration?: number; bufferZone?: number };

  /**
   * Condensed Execution data
   */
  @attr() executionInfo!: { oha?: number; ohaZone?: number };

  /**
   * Condensed SKU data
   */
  @attr() skuInfo!: IPlanningOrderSKU;

  /**
   * Reorder quantity converted in multiple units
   */
  @attr() convertedQties!: Record<string, number>;

  /**
   * Aggregated stats like comments count
   */
  @attr() stats!: { commentsCount: number };

  /**
   * Condensed delivery Workshop data
   */
  @attr({
    defaultValue: () => {
      return {};
    }
  })
  deliveryWorkshopInfo?: IWorkshopIdentityInfo;

  /**
   * Condensed supplier data
   */
  @attr({
    defaultValue: () => {
      return {};
    }
  })
  supplierInfo?: ISupplierIdentityInfo;

  /**
   * Condensed workshop data
   */
  @attr({
    defaultValue: () => {
      return {};
    }
  })
  workshopInfo?: IWorkshopIdentityInfo;

  /**
   * Condensed alerts info
   */
  @attr() alerts!: { scheduling?: ISchedulingAlertSummary; synchronisation?: ISyncAlertSummary };

  /**
   * Aggregated data about each task type
   */
  @attr() tasks?: IAggTasksViewMap;

  /**
   * Custom fields values
   */
  @attr({
    defaultValue: () => {}
  })
  customFields?: TCustomFieldValues;

  @tracked startPeriod?: string;

  // Computed Properties
  // ~~~~

  get validationStatusOption() {
    return this.validationStatus ? POValidationStatuses[this.validationStatus] : undefined;
  }

  get statusOption() {
    return Statuses[this.status];
  }

  get targetStatusOption() {
    return this.proposedValues?.targetStatus && this.validationStatus === 'SUPPLIER_PROPOSAL'
      ? Statuses[this.proposedValues?.targetStatus]
      : undefined;
  }

  get hasStatusChangeProposal() {
    const target = this.targetStatusOption?.value;
    return target && target !== this.status;
  }

  get dueDateOptimal() {
    return this.optimalFlowDate;
  }

  get reorderDateOptimal() {
    return this.optimalFlowDate
      ? moment(this.optimalFlowDate).subtract(this.skuInfo.dlt, 'days').toDate()
      : undefined;
  }

  get deliveryDateOptimal() {
    return this.optimalFlowDate
      ? moment(this.optimalFlowDate)
          .subtract(this.skuInfo.ilt ?? 0.0, 'days')
          .toDate()
      : undefined;
  }

  get dueDateIsOptimal() {
    return moment(this.dueDate).isSame(this.optimalFlowDate, 'days');
  }

  get stockOutDate() {
    return this.optimalFlowDate ? moment(this.optimalFlowDate).add(1, 'days').toDate() : undefined;
  }

  /**
   * Comments count
   */
  get commentsCount() {
    return this.tasks?.COMMENT?.total;
  }

  /**
   * Aggregated alerts count
   */
  get alertsCount() {
    const { scheduling, synchronisation } = this.alerts;
    const a = (parseNumber(scheduling?.criticality) as number) < 1;
    const b = (parseNumber(synchronisation?.criticality) as number) < 1;

    return (a ? 1 : 0) + (b ? 1 : 0);
  }

  get isManaged() {
    const { methodology = 'NONE' } = this.skuInfo;
    return methodology !== 'NONE';
  }

  /**
   * Sku id
   */
  get skuId() {
    return this.skuInfo.skuId;
  }

  /**
   * Delivery workshop id
   */
  get deliveryWorkshopId() {
    return this.deliveryWorkshopInfo?.workshopId;
  }

  /**
   * Supplier id
   */
  get supplierId() {
    return this.supplierInfo?.supplierId;
  }

  /**
   * Workshop id
   */
  get workshopId() {
    return this.workshopInfo?.workshopId;
  }

  /**
   * Warehouse id
   */
  get warehouseId() {
    return this.skuInfo.warehouse.warehouseId;
  }

  /**
   * Is the PO in DRAFT
   */
  get isDraft() {
    return this.status === 'DRAFT';
  }

  get isFirm() {
    return FirmPOStatuses.includes(this.status);
  }

  get atLeastDraft() {
    return !HiddenStatuses.includes(this.status);
  }

  /**
   * Planning priority name
   */
  get priorityName() {
    const { bufferZone } = this.planningInfo;
    return isPresent(bufferZone) ? getBufferZoneCriticality(bufferZone) : undefined;
  }

  /**
   * Execution priority name
   */
  get executionPriorityName() {
    const { ohaZone } = this.executionInfo;
    return isPresent(ohaZone) ? getBufferZoneCriticality(ohaZone) : undefined;
  }

  /**
   * Reorder quantity as a monetary value
   */
  get reorderQtyValue() {
    return this.reorderQty * (this.actualUnitPrice ?? 0);
  }

  /**
   * Actual order number
   */
  get num() {
    return this.orderNum ?? this.voltaOrderNum;
  }

  /**
   * Actual order line
   */
  get line() {
    return this.orderLine ?? this.voltaOrderLine;
  }

  /**
   * Is the PO a Distribution Order
   */
  get isDistribute() {
    return this.acqCode === 'DISTRIBUTE';
  }

  /**
   * Generated Key used for grouping PO as logical blocks
   */
  get orderKey(): string {
    const { num, warehouseId, acqCode, supplierId, deliveryWorkshopId, workshopId, isDistribute } =
      this;

    return `${num || noOrderNum}_${warehouseId}_${acqCode}_${supplierId || noId}_${
      workshopId || noId
    }_${(isDistribute && deliveryWorkshopId) || noId}`;
  }

  get orderHash() {
    return `${hashCode(this.orderKey)}`;
  }

  get displayName() {
    const { num, line } = this;
    const display = num && ` (${num}${line ? ` - ${line}` : ''})`;
    return display;
  }

  get skuDisplayName() {
    const {
      skuInfo: { part }
    } = this;
    return `#${part.code} - ${part.description}`;
  }

  /**
   * Object containing the grouping key with all its constituents
   */
  get orderKeyObject(): IPOKeyObject {
    return {
      orderHash: this.orderHash,
      orderKey: this.orderKey,
      num: this.num,
      warehouse: this.skuInfo.warehouse,
      acqCode: this.acqCode,
      supplier: this.supplierInfo,
      workshop: this.workshopInfo,
      deliveryWorkshop: this.isDistribute ? this.deliveryWorkshopInfo : undefined,
      externalOrderNum: this.externalOrderNum
    };
  }

  get actualUnitPrice() {
    return this.unitPrice ?? this.skuInfo.unitPrice;
  }

  get isSupplierDraft() {
    return this.validationStatus === 'SUPPLIER_DRAFT';
  }

  get isSupplierProposal() {
    return this.validationStatus === 'SUPPLIER_PROPOSAL';
  }

  get isSupplierProposalAccepted() {
    return this.validationStatus === 'CUSTOMER_APPROVAL';
  }

  get isSupplierProposalRefused() {
    return this.validationStatus === 'CUSTOMER_REFUSAL';
  }

  get supplierProposalCanBeSent() {
    return this.isSupplierDraft && this.status !== 'LAUNCHED';
  }

  get isTerminated() {
    const { status } = this;
    return status === 'CANCELLED' || status === 'FINISHED';
  }

  get isSupplierSplitDraft() {
    return Boolean(
      this.reorderQty === 0 &&
        (this.proposedValues?.reorderQty ?? 0) > 0 &&
        this.parentPoId &&
        this.isSupplierDraft
    );
  }

  get parentChildOrder() {
    return this.parentPoId ?? this.id;
  }

  searchSku(term: string, isSupplier: boolean): boolean {
    const lcTerm = term.toLowerCase();
    const { part, supplierSkuDescription } = this.skuInfo;
    const { code, description } = part;
    return (
      code.toLowerCase().indexOf(lcTerm) > -1 ||
      (!isSupplier && description ? description.toLowerCase().indexOf(lcTerm) > -1 : false) ||
      (isSupplier && supplierSkuDescription
        ? supplierSkuDescription.toLowerCase().indexOf(lcTerm) > -1
        : false)
    );
  }

  getFieldValue(field: TPOField | string) {
    if (field.includes('sku.customFields')) {
      return this.skuInfo.customFields[field.replace('sku.customFields.', '')];
    } else if (field.includes('customFields')) {
      return this.customFields?.[field.replace('customFields.', '')];
    } else {
      return POFieldMapping[field as TPOField](this);
    }
  }

  /**
   * Builds a flat data structure exportable as a csv
   */
  toExportable(): Record<string, any> {
    const {
      skuInfo,
      deliveryWorkshopInfo,
      workshopInfo,
      supplierInfo,
      executionPriorityName,
      priorityName,
      reorderQty,
      dueDate,
      acqCode,
      status,
      orderType,
      voltaOrderNum,
      voltaOrderLine,
      orderNum,
      orderLine,
      reorderDate,
      deliveryDate,
      optimalFlowDate,
      externalOrderNum,
      actualUnitPrice,
      customFields
    } = this;
    return {
      orderType,
      voltaOrderNum,
      voltaOrderLine,
      orderNum,
      orderLine,
      warehouseCode: skuInfo.warehouse.code,
      partCode: skuInfo.part.code,
      acqCode,
      status,
      reorderQty,
      dueDate: formatDate(dueDate),
      reorderDate: formatDate(reorderDate),
      deliveryDate: formatDate(deliveryDate),
      optimalFlowDate: optimalFlowDate ? formatDate(optimalFlowDate) : undefined,
      supplierCode: supplierInfo?.code,
      workshopCode: workshopInfo?.code,
      deliveryWorkshopCode: deliveryWorkshopInfo?.code,
      planningPriority: priorityName,
      executionPriority: executionPriorityName,
      externalOrderNum,
      unitPrice: actualUnitPrice,
      ...customFields
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'planning-order': PlanningOrder;
  }
}
