import { isArray as isEmberArray } from '@ember/array';
import { helper } from '@ember/component/helper';
import isEqual from 'lodash/isEqual';

export function isSelected([item, selected]: [unknown, unknown[] | unknown] /* , hash*/) {
  if (selected === undefined || selected === null) {
    return false;
  }
  if (isEmberArray(selected)) {
    for (const sItem of selected as unknown[]) {
      if (isEqual(sItem, item)) {
        return true;
      }
    }
    return false;
  } else {
    return isEqual(item, selected);
  }
}

export default helper(isSelected);
