import IntlService from 'ember-intl/services/intl';
import moment from 'moment-timezone';
import BaseModel from 'volta/models/base-model';
import Task, { tasksToAgg } from 'volta/models/task';
import { IAggTasksViewMap } from 'volta/models/types/tasks';
import { getBufferZoneCriticality } from 'volta/utils/style-utils';

import { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';

import type SkuModel from './sku';
import type { IExecutionBuffer } from './types/planning';
export const ResourceName = {
  singular: 'executionAlert',
  plural: 'executionAlerts'
};

export const Queries = {
  AlertsTs: 'alertsTs'
};

export default class ExecutionAlertModel extends BaseModel {
  @service intl!: IntlService;

  /**
   * Attributes
   */
  @attr('date') inventoryDate!: Date;
  @attr('date') dueDate?: Date;
  @attr('number') onHand?: number;
  @attr('number') openSupply?: number;
  @attr('number') demand?: number;
  @attr('number') avgUsage?: number;
  @attr('number') demandSpikeQty?: number;
  @attr('number') oha?: number;
  @attr('number') ohaZone?: number;
  @attr('number') projectedOha?: number;
  @attr('number') projectedOhaZone?: number;
  @attr('date') projectedOhaDate?: Date;
  @attr('number') projectedOhaSimulated?: boolean;
  @attr() buffer?: IExecutionBuffer;
  @attr() tasks?: IAggTasksViewMap;

  @belongsTo('sku', { async: false }) sku!: SkuModel;

  /**
   * Checks if the product has a demand spike
   */
  get hasDemandSpike() {
    return this.demandSpikeQty && this.demandSpikeQty < 0;
  }

  get partCode() {
    return this.sku.part.code;
  }

  get skuDescription() {
    return this.sku.skuDescription;
  }

  get isMRP() {
    return this.sku.methodology === 'MRP';
  }

  /**
   * DO NOT REMOVE, it's used by the sku-details execution dashboard
   */
  get dueDateLabel() {
    const { dueDate } = this;
    return moment(dueDate)
      .add(dueDate ? 0 : this.sku.actualLeadTime ?? 0, 'd')
      .format('L');
  }

  /**
   * Get the priority name from the buffer zone and penetration
   */
  get priorityName() {
    return getBufferZoneCriticality(this.ohaZone);
  }

  /**
   * Get the priority name from the buffer zone and penetration
   */
  get projectedPriorityName() {
    return getBufferZoneCriticality(this.projectedOhaZone);
  }

  get priorityPercent() {
    return this.oha && this.oha * 100;
  }

  setTasks(tasks: Task[]) {
    this.tasks = tasksToAgg(tasks);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'execution-alert': ExecutionAlertModel;
  }
}
