import { defaultErrorHandler, TError } from 'volta/utils/error-utils';

import { action, get, setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { instrumentRoutePerformance } from '@sentry/ember';

import type LocalizationService from 'volta/services/localization';
import type IntlService from 'ember-intl/services/intl';
import type SessionService from 'ember-simple-auth/services/session';
import type { IResourceName } from 'volta/models/base-model';
import type AppNavState from 'volta/services/app-nav-state';
import type AuthorizationService from 'volta/services/authorization';
import type BvFlashService from 'volta/services/bv-flash';
import type LocalPreferencesService from 'volta/services/local-preferences';
import type SessionUserService from 'volta/services/session-user';
import type StoreService from 'volta/services/store';
import type ProtectedController from 'volta/controllers/protected-controller';
import type RouterService from '@ember/routing/router-service';

export const NAVIGATION_HISTORY_KEY = 'NAVIGATION_HISTORY';
export interface IHistoryRecord {
  title: string;
  tab?: string;
  route: string;
  visitCount: number;
  lastVisitedAt: Date;
}

const DefaultResourceName: IResourceName = {
  singular: 'item',
  plural: 'items'
};

export class BaseRoute extends Route {
  // Services
  // ~~~~~
  @service session!: SessionService;
  @service intl!: IntlService;
  @service localization!: LocalizationService;

  @service bvFlash!: BvFlashService;
  @service sessionUser!: SessionUserService;
  @service authorization!: AuthorizationService;
  @service localPreferences!: LocalPreferencesService;
  @service appNavState!: AppNavState;
  @service store!: StoreService;
  @service router!: RouterService;

  // Properties
  // ~~~~~

  @tracked queryParamsValues: TQueryParams = {};
  @tracked documentTitle!: string[];

  resourceName: IResourceName = DefaultResourceName;

  // Lifecycle Hooks
  // ~~~~~

  async beforeModel(transition: Transition) {
    try {
      const user = await this.sessionUser.reloadUser();
      await this.checkAccessRight(
        transition,
        this.resourceName.plural ?? this.resourceName.singular
      );
      return user;
    } catch (e) {
      return this._onDefaultError(e);
    }
  }

  model(params: any, _transition: Transition) {
    this.queryParamsValues = params;
    this.notifyPropertyChange('queryParamsValues');
  }

  setupController(controller: ProtectedController, model: {}, transition: Transition) {
    super.setupController(controller, model, transition);

    setProperties(controller, {
      resourceName: this.resourceName,
      route: this
    });
  }

  afterModel(model: {}, transition: Transition) {
    transition.then(() => {
      this.setDocumentTitle(model, transition, this);
      this.createHistory(transition);
    });
  }

  setDocumentTitle(_model: {}, _transition: Transition, _route: Route) {
    // empty
  }

  // Actions
  // ~~~~~

  @action
  refreshRoute() {
    this.refresh();
  }

  @action
  refreshModel(params?: any) {
    this.router.transitionTo({ queryParams: params ?? this.queryParamsValues });
  }

  // Private Callbacks
  // ~~~~~

  protected _onDefaultError(error: TError) {
    defaultErrorHandler(error);
    this.bvFlash.error(
      this.intl.t('errors.route', {
        route: this.routeName ?? this.intl.t(DefaultResourceName.singular)
      })
    );
  }

  /**
   * Checks if the user has access rights to view the requested route.
   * If the user is a supplier and the requested route is not in the list of authorized routes,
   * redirects to the PO workbench.
   * If the user is not a supplier and does not have access rights to view the requested route,
   * redirects to the protected index.
   */
  protected checkAccessRight(transition: Transition, canView: string, ...rest: string[]) {
    const authRoutes = [
      'protected.account',
      'protected.po-workbench',
      'protected.po-workbench.line',
      'protected.po-workbench.line.index',
      'protected.po-workbench.line.tasks',
      'protected.po-workbench.line.activities',
      'protected.reports',
      'protected.report'
    ];
    const user = this.sessionUser.user;
    const unauthorizedEntities = this.authorization.cannotViewAnyList(...[canView, ...rest]);
    if (
      user?.isSupplier &&
      !authRoutes.some((r) => r === transition.to.name || r === transition.to.parent?.name)
    ) {
      this.router.transitionTo('protected.po-workbench');
    } else if (!user?.isSupplier && canView && unauthorizedEntities.length) {
      // Do not pass by ProtectedRoute.beforeModel at this point since it's already been initialized.
      this.router.transitionTo(this.appNavState.homeLink);
      this.bvFlash.error(this.intl.t('errors.permissions.route.unauthorized'));
      defaultErrorHandler(
        `Missing Permissions: ${unauthorizedEntities
          .map((e) =>
            this.localization.tOrElse(`entities.${e}`, this.localization.tOrElse(e) as string)
          )
          .join(', ')} `
      );
    }
  }

  protected generateCreationTitle() {
    this.documentTitle = [
      this.intl.t('bvList.create', {
        resourceNameSingular: this.intl.t(this.resourceName.singular).toLowerCase()
      })
    ];
  }
  protected generateItemTitle(model: any, key: string = 'code') {
    this.documentTitle = [
      this.intl.t('bvList.singleTitle', {
        resourceNameSingular: this.intl.t(this.resourceName.singular),
        code: get(model, key)
      })
    ];
  }

  private concatTitleParams(arr: string[] = [], separator: string) {
    let title = '';
    for (const w of arr) {
      const translated = this.intl.exists(w) ? this.intl.t(w) : w;
      if (!title) {
        title = translated;
      } else {
        title = title.concat(separator, translated);
      }
    }
    return title.trim();
  }

  private createHistory(transition: Transition) {
    const { documentTitle } = this;

    if (!documentTitle || !documentTitle.length) {
      return;
    }
    const history: { [key: string]: IHistoryRecord } =
      this.localPreferences.getItem(NAVIGATION_HISTORY_KEY) ?? {};

    const page = window.location.href.split(window.origin)[1];
    let tab;

    if (transition.to.queryParams) {
      for (const [key, value] of Object.entries(transition.to.queryParams)) {
        if (key === 'tab' && value) {
          tab = value;
        }
      }
    }

    const navName = Object.values(this.appNavState.searchMenuContent).find(
      (nav) => nav.route === transition.to.name
    );

    const title = navName?.label ?? this.concatTitleParams(documentTitle, ' - ');

    const historyRecord = {
      title,
      tab,
      route: page,
      visitCount: 1,
      lastVisitedAt: new Date()
    };

    if (history[title]) {
      historyRecord.visitCount = history[title].visitCount + 1;
    }
    history[title] = historyRecord;

    this.localPreferences.setItem(NAVIGATION_HISTORY_KEY, history);
    this.appNavState.fillHistory(history);
  }
}

// For Sentry
export default instrumentRoutePerformance(BaseRoute) as unknown as typeof BaseRoute;
