import User from 'volta/models/user';

import { assert } from '@ember/debug';
import Service from '@ember/service';
import { classify } from '@ember/string';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

export default class AuthorizationService extends Service {
  /**
   * Builds a permissions array with default more general rights (All, entityTypeAll...)
   *
   * @param entityType The resource type
   * @param right The user right (eg. RunScenario)
   * @return An array of permissions
   */
  static getPermissions(entityType: string, right: string) {
    assert(`entityType should be defined, with ${right}`, entityType);
    if (!entityType) {
      return [];
    }

    const type = classify(entityType);
    const perms = this.getPermissionsStrict(entityType, right);

    if (isPresent(right)) {
      const rightLc = right.toLowerCase();
      if (rightLc.startsWith('view') && rightLc !== 'view') {
        perms.push(`${type}View`);
      } else {
        // We're in the case of a detailed update or a command
        perms.push(`${type}Update`);
      }
    }
    return perms;
  }

  /**
   * Builds a permissions array with default more general rights (All, AllRead...)
   * Does not take account of type CRUD permissions
   *
   * @param entityType The resource type
   * @param right The user right (eg. RunScenario)
   * @return An array of permissions
   */
  static getPermissionsStrict(entityType: string, right: string) {
    const type = classify(entityType);
    const perms = ['All', `${type}All`];

    if (isPresent(right)) {
      perms.push(`${type}${classify(right)}`);
      const rightLc = right.toLowerCase();

      if (rightLc === 'read') {
        perms.push('AllRead');
      } else if (rightLc.startsWith('view')) {
        perms.push('AllView');
      } else {
        perms.push('AllUpdate');
      }
    }
    return perms;
  }

  /**
   * Array of session user permissions
   */
  @tracked permissions: string[] = [];

  /**
   * This setup function should be called as soon as the session user is fetched
   * The best place to call that function for the first time is from the protected route
   * @param user Session user
   */
  setup(user: User) {
    if (!user) {
      return;
    }
    const userPermissions = user.permissions ?? [];
    this.permissions = [...userPermissions];
  }

  /**
   * If the user has any of the supplied permissions, it's allowed to perform
   * an hypothetical action
   * @param  {Array} permissions A list of permissions
   * @return {boolean} Whether the user is allowed or not
   */
  anyPermissionOf(permissions: string[]) {
    if (this.permissions?.length > 0) {
      return permissions.some((perm) => this.permissions.includes(perm));
    } else {
      return false;
    }
  }

  /**
   * Can the current user view that resource type
   *
   * @param entityType The type of resource
   * @param detail An optional detail within the entity type that the user can or cannot view (ex: PlanningSettingsViewMethodlogy, here Methodology will be the detail)
   * @return Whether the user is allowed to view the resource or not
   */
  canView(entityType: string, detail?: string) {
    return (
      this.canRead(entityType) &&
      this.anyPermissionOf(
        AuthorizationService.getPermissions(entityType, `View${detail ? classify(detail) : ''}`)
      )
    );
  }

  canViewLoose(entityType: string, detail?: string, readEntity?: string) {
    return readEntity
      ? this.canRead(readEntity)
      : true &&
          this.anyPermissionOf(
            AuthorizationService.getPermissions(entityType, `View${detail ? classify(detail) : ''}`)
          );
  }

  canViewAny(...entityTypes: string[]) {
    return entityTypes.some((e) => this.canView(e));
  }

  canViewAnyList(...entityTypes: string[]) {
    return entityTypes.filter((e) => this.canView(e));
  }

  cannotViewAnyList(...entityTypes: string[]) {
    return entityTypes.filter((e) => !this.canView(e));
  }

  canViewAnyDetails(entityType: string, ...details: string[]) {
    return details.some((d) => this.canView(entityType, d));
  }

  /**
   * Can the current user read that resource type
   *
   * @param entityType The type of resource
   * @return Whether the user is allowed to read the resource or not
   */
  canRead(entityType: string) {
    return this.anyPermissionOf(AuthorizationService.getPermissions(entityType, 'Read'));
  }

  /**
   * Can the current user update that resource type
   *
   * @param entityType The type of resource
   * @return Whether the user is allowed to update the resource or not
   */
  canUpdate(entityType: string) {
    return this.anyPermissionOf(AuthorizationService.getPermissions(entityType, 'Update'));
  }

  /**
   * Can the current user create that resource type
   *
   * @param entityType The type of resource
   * @return Whether the user is allowed to create the resource or not
   */
  canCreate(entityType: string) {
    return this.anyPermissionOf(AuthorizationService.getPermissions(entityType, 'Create'));
  }

  /**
   * Can the current user delete that resource type
   *
   * @param entityType The type of resource
   * @return Whether the user is allowed to delete the resource or not
   */
  canDelete(entityType: string) {
    return this.anyPermissionOf(AuthorizationService.getPermissions(entityType, 'Delete'));
  }

  /**
   * Does the user have the right to do whatever
   * A permission will be in the for of "[EntityType][PermissionType][OptionalDetailed]"
   *
   * @param entityType The type of resource
   * @param right The permission key
   * @return Whether the user is allowed or not
   */
  can(entityType: string, right: string) {
    return this.anyPermissionOf(AuthorizationService.getPermissions(entityType, right));
  }

  cannot(entityType: string, right: string) {
    return !this.can(entityType, right);
  }

  canStrict(entityType: string, right: string) {
    return this.anyPermissionOf(AuthorizationService.getPermissionsStrict(entityType, right));
  }

  cannotStrict(entityType: string, right: string) {
    return !this.canStrict(entityType, right);
  }
}

declare module '@ember/service' {
  interface Registry {
    authorization: AuthorizationService;
  }
}
