import { restartableTask } from 'ember-concurrency';
import AppFeature, { AppFeatureResourceName } from 'volta/models/app-feature';
import Config from 'volta/models/config';
import { TenantResourceName } from 'volta/models/tenant';
import { ISettings } from 'volta/models/types/settings';
import { normalize } from 'volta/utils/api/serialize-and-push';
import { defaultErrorHandler, TError } from 'volta/utils/error-utils';

import ArrayProxy from '@ember/array/proxy';
import Service, { inject as service } from '@ember/service';
import { classify } from '@ember/string';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

import BvMetricsService from './bv-metrics';

import type Tenant from 'volta/models/tenant';

import type IntlService from 'ember-intl/services/intl';
import type StoreService from 'volta/services/store';
import type { DocWithData } from 'volta/utils/api/jsonapi-types';
import type { IAuthProvider } from 'volta/models/types/user';
interface ITenantBase {
  tenantId: string;
  env: string;
  authorizedProviders: IAuthProvider[];
}

/**
 * Service that manages the application warehouse settings as well as the instance/tenant basic parameters
 */
export default class AppSettings extends Service {
  // Services
  // ~~~~~

  @service store!: StoreService;
  @service intl!: IntlService;
  @service bvMetrics!: BvMetricsService;

  // Properties
  // ~~~~~

  @tracked configs: Config[] = [];
  @tracked selectedConfig?: Config;
  @tracked tenant?: Tenant;
  @tracked tenantBase?: ITenantBase;
  @tracked customizedSettings: any = {};

  promise?: Promise<any>;

  // Computed Properties
  // ~~~~~

  get settings() {
    return this.selectedConfig?.settings;
  }

  get selectedIsOverridden() {
    return Boolean(this.selectedConfig?.warehouse);
  }

  get defaultConfig() {
    const defaults = this.configs.filter((c) => {
      return !c.warehouse;
    });
    return defaults.length ? defaults[0] : undefined;
  }

  get warehouses() {
    const warehouses: string[] = [];

    this.configs.forEach((config) => {
      const warehouseId = config.warehouse?.id;
      if (warehouseId) {
        warehouses.push(warehouseId);
      }
    });
    return warehouses;
  }

  get overriddenConfigs() {
    return (this.configs ?? []).filter((c) => isPresent(c.warehouse?.id));
  }

  get features() {
    return this.tenant?.features as AppFeature | undefined;
  }

  // Lifecycle Hooks
  // ~~~~~

  // Helper Functions
  // ~~~~~

  /**
   * Initialise the data structure used to monitor whether a setting overridden
   * in a particluar warehouse is different from default config
   */
  initCustomizedSettings() {
    const diffObject: Record<string, boolean> = {};
    [
      'locale',
      'timeZone',
      'weekendDays',
      'poWorkbench',
      'demandCalculation',
      'planningProjection',
      'defaultBuffer',
      'alert',
      'ltCategoryMake',
      'ltCategoryBuy',
      'ltCategoryDistribute',
      'varCategoryMake',
      'varCategoryBuy',
      'varCategoryDistribute',
      'supplyOrderGeneration'
    ].forEach((path) => {
      diffObject[path] = Boolean(this.diffSettings(path));
    });
    this.customizedSettings = diffObject;
  }

  /**
   * Triggers the `OverrideForWarehouse` collection command
   * It creates an overridden config by copying the default one
   *
   * @param warehouseId Warehouse id of the new config
   */
  async overrideConfig(warehouseId?: string) {
    if (!warehouseId) {
      return;
    }
    try {
      const config = (await Config.overrideForWarehouse({ warehouseId })) as Config;
      return this._onOverrideConfigSuccess(config);
    } catch (error) {
      return this._onDefaultError(error);
    }
  }

  /**
   * Finds a config by its id
   *
   * @param id Id of the config to find
   */
  findSelectedConfig(id?: string) {
    if (id) {
      const found = this.configs.find((c) => {
        return c.id === id;
      });
      return found || this.defaultConfig;
    }
    return this.defaultConfig;
  }

  /**
   * Finds an overridden config for the given warehouse.
   * It returns the default config if not found.
   *
   * @param warehouseId Warehouse id of the config to find
   */
  byWarehouseOrDefault(warehouseId?: string) {
    if (warehouseId) {
      const found = this.configs.find((c) => {
        return c.warehouse && c.warehouse.id === warehouseId;
      });
      return found || this.defaultConfig;
    }
    return this.defaultConfig;
  }

  /**
   * Finds versions of a setting by its key and a list of warehouses
   *
   * @param key Setting path
   * @param warehouseIds List of warehouse id
   */
  findSettingsByWarehouses<T = unknown>(
    warehouseIds: string[] = [],
    getterFn: (settings: ISettings) => T | undefined
  ) {
    const configs = this.overriddenConfigs.reduce((acc: T[], c: Config) => {
      if (warehouseIds.includes(c.warehouse.id)) {
        const value = getterFn(c.settings);
        if (value) {
          acc.push(value);
        }
      }
      return acc;
    }, []);

    const defaultValue = this.defaultConfig?.settings
      ? getterFn(this.defaultConfig?.settings)
      : undefined;

    return (defaultValue ? configs.concat([defaultValue]) : configs).uniq();
  }

  /**
   * Save a setting to the backend given its key and a value.
   * The function will generate the appropriate command and trigger it
   *
   * @param settingKey Setting key
   * @param value Setting value
   */
  async saveSetting(settingKey: string, value?: any) {
    const command = `update${classify(settingKey)}`;
    const payload = {
      value: value ?? this.selectedConfig?.settings?.[settingKey as keyof ISettings]
    };
    // @ts-ignore
    const commandFn: (config: Config, payload: any) => Promise<any> =
      this.selectedConfig?.[command as keyof Config];
    if (commandFn) {
      try {
        const result = await commandFn.call(this.selectedConfig, payload);
        return this._onSaveConfigSuccess(settingKey, result);
      } catch (error) {
        this._onDefaultError(error);
        throw error;
      }
    } else {
      defaultErrorHandler(
        `service:app-settings | Command ${command} not available, please create it on the config model file first`
      );
    }
  }

  /**
   * Checks whether the selected setting with the given path differs from the default one
   *
   * @param path Full path of the setting in the confg structure
   */
  diffSettings(path: string) {
    const defaultSetting = this.defaultConfig?.settings[path as keyof ISettings];
    const selectedSetting = this.selectedConfig?.settings[path as keyof ISettings];
    const { stringify } = JSON;

    switch (typeof selectedSetting) {
      case 'object':
        if (Array.isArray(selectedSetting)) {
          return (
            stringify((defaultSetting as Array<unknown>).sort()) !==
            stringify(selectedSetting.sort())
          );
        } else {
          return stringify(defaultSetting as object) !== stringify(selectedSetting);
        }
      default:
        return defaultSetting !== selectedSetting;
    }
  }

  /**
   * Gets the feature given a key
   *
   * @param feature Feature key
   */
  getFeature(feature: string) {
    return this.tenant?.features?.get(feature as keyof AppFeature);
  }

  // Tasks
  // ~~~~~

  /**
   * Task to GET the instance Tenant data (tenant, features, auth...)
   * This request is authenticated
   */
  fetchTenant = restartableTask(async () => {
    try {
      const response: DocWithData = await this.store.normalGet('tenant', {
        include: AppFeatureResourceName.singular
      });
      this.tenant = normalize(TenantResourceName.singular, response) as Tenant;
      return this.tenant;
    } catch (error) {
      return defaultErrorHandler(error);
    }
  });

  /**
   * Task to GET the instance TenantBase data (tenant, auth...)
   * Used before login, to generate the providers buttons and application version caption
   * This request is not authenticated.
   */
  fetchBaseTenant = restartableTask(async () => {
    try {
      if (!this.tenantBase) {
        this.tenantBase = await this.store.normalGet('tenantBase');
      }
      return this.tenantBase;
    } catch (error) {
      return defaultErrorHandler(error);
    }
  });

  /** Task to fetch config information from the API */
  fetchConfigsTask = restartableTask(async (selectedConfigId?: string) => {
    const configsQuery = {
      include: 'warehouse',
      page: {
        offset: 0,
        limit: 500
      },
      sort: '-warehouse.description'
    };
    try {
      this.configs = (
        (await this.store.query('config', configsQuery)) as ArrayProxy<Config>
      ).toArray();
      this.selectedConfig = this.findSelectedConfig(selectedConfigId);
      this.initCustomizedSettings();
      return this.configs;
    } catch (error) {
      return defaultErrorHandler(error);
    }
  });

  saveAppFeatures = restartableTask(async (payload: any) => {
    try {
      if (!this.tenant?.features) {
        throw new Error('No app features found');
      }
      // We first unload the record from the store before requesting
      // and pushing back into the store, that way we're sure the nested
      // data within the model has been updated in the store as well

      this.store.unloadRecord(this.tenant.features);
      this.tenant.features = (await AppFeature.updateAppFeature(payload)) as AppFeature;
      return this.tenant.features;
    } catch (error) {
      throw error;
    }
  });

  // Private Callbacks
  // ~~~~~

  /**
   * After saving the setting, we check if the value is different
   * from the default one and updates the state holding diffs
   *
   * @param settingKey Setting key
   * @param result Result of the save command
   */
  _onSaveConfigSuccess(settingKey: string, result: any) {
    const newCustomizedSettings = { ...this.customizedSettings };
    newCustomizedSettings[settingKey] = this.diffSettings(settingKey);
    this.customizedSettings = newCustomizedSettings;
    return result;
  }

  _onDefaultError(error: TError) {
    defaultErrorHandler(error);
  }

  /**
   * When a config has been created from a command we GET it to update the store
   * (TODO: Check it's really needed)
   *
   * @param config Config id
   */
  _onOverrideConfigSuccess(config: Config) {
    this.fetchConfigsTask.perform(config.id);
    return config;
  }
}

declare module '@ember/service' {
  interface Registry {
    'app-settings': AppSettings;
  }
}
