import { validatePresence } from 'ember-changeset-validations/validators';

export default {
  code: validatePresence(true),
  description: [],
  varFactor: validatePresence(true),
  varFactorMin: validatePresence(true),
  varFactorMax: validatePresence(true),
  leadTimeFactor: validatePresence(true),
  leadTimeFactorMin: validatePresence(true),
  leadTimeFactorMax: validatePresence(true),
  isDefault: validatePresence(true),
  tags: []
};
