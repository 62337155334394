import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';

export interface IStatusFilterComponentArgs {
  possibleValues: IStatusOption[];
  renderInPlace?: boolean;
  horizontalPosition?: 'right' | 'left' | 'auto';
  matchTriggerWidth?: boolean;
  hideCount?: boolean;
}

interface IStatusFilterProps extends IFilterArgs<IStatusFilterComponentArgs> {}

export default class StatusFilter extends Component<IStatusFilterProps> {
  get selected() {
    const { values = [], multiple, componentArgs } = this.args;

    if (multiple) {
      return componentArgs.possibleValues?.filter((o) => o && values.includes(o.key));
    } else {
      if (values.length > 0) {
        return componentArgs.possibleValues?.find((o) => o.key === values[0]);
      } else {
        return undefined;
      }
    }
  }

  private getValues(options: (IStatusOption | undefined)[]) {
    return options.reduce((acc: string[], o) => {
      const v = o?.['key'];
      if (isPresent(v)) {
        acc.push(v as string);
      }
      return acc;
    }, []);
  }

  @action
  valuesDidChange(values: IStatusOption[]) {
    this.args.onChange?.(this.getValues(values));
  }

  @action
  singleValueDidChange(value?: IStatusOption) {
    this.args.onChange?.(value ? [value.key] : []);
  }
}
