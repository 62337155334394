import { validatePresence } from 'ember-changeset-validations/validators';
import { TScheduleService } from 'volta/models/schedule';
import validateOnCheck from 'volta/validators/on-check';
import validateCron from 'volta/validators/validate-cron';

import { isNone, isPresent } from '@ember/utils';

export default {
  group: [],
  timezone: validatePresence(true),
  service: validatePresence(true),
  cron: [validatePresence(true), validateCron()],
  description: validatePresence(true),
  arg: {
    payload: validateOnCheck({
      checkKeys: ['service'],
      checkCondition: (payload: string, service: TScheduleService) => {
        if (service !== 'LambdaRunner') {
          return true;
        }
        if (!isPresent(payload)) {
          return false;
        }
        try {
          const parsed = JSON.parse(payload);
          return !!parsed;
        } catch (e) {
          return false;
        }
      },
      i18nKey: 'payload'
    }),
    functionName: validateOnCheck({
      checkKeys: ['service'],
      checkCondition: (functionName: string, service: TScheduleService) => {
        return service === 'LambdaRunner' ? isPresent(functionName) : true;
      },
      i18nKey: 'name',
      i18nType: 'empty'
    }),
    warehouseIds: validateOnCheck({
      checkKeys: ['service'],
      checkCondition: (warehouseIds: string[], service: TScheduleService) => {
        return service === 'PlanningCalculation' ? !isNone(warehouseIds) : true;
      },
      i18nKey: 'warehouses',
      i18nType: 'empty'
    }),
    nbHoursBack: validateOnCheck({
      checkKeys: ['service'],
      checkCondition: (nbHoursBack: number, service: TScheduleService) => {
        return service === 'OrderExport' ? isPresent(nbHoursBack) : true;
      },
      i18nKey: 'hours',
      i18nType: 'empty'
    })
  }
};
