import moment from 'moment-timezone';
import BaseModel, { IResourceName } from 'volta/models/base-model';
import {
  IResetDaysCmd,
  IUpdateCalendarCmd
} from 'volta/models/types/supplier';
import {
  _normalize,
  collectionCommand,
  resourceCommand as command
} from 'volta/utils/api';

import { attr } from '@ember-data/model';

export const SupplierCalendarDayResourceName: IResourceName = {
  singular: 'supplierCalendarDay',
  plural: 'supplierCalendarDays'
};

export enum SupplierCalendarDayEvents {
  CalendarUpdated = 'CalendarUpdated',
  DaysReset = 'DaysReset',
  SupplierCalendarDayReset = 'SupplierCalendarDayReset'
}

export enum SupplierCalendarDayCommands {
  UpdateCalendar = 'UpdateCalendar',
  ResetDays = 'ResetDays',
  ResetOne = 'ResetOne'
}

export default class SupplierCalendarDay extends BaseModel {
  /**
   * Create or update the supplier calendar days
   * @return The list of created or updated SupplierCalendarDay models
   */
  static updateCalendar = collectionCommand<IUpdateCalendarCmd>(
    SupplierCalendarDayCommands.UpdateCalendar,
    {
      after: _normalize
    }
  );

  /**
   * Effectively deletes the models identified by the supplier id and dates
   * @return Done
   */
  static resetDays = collectionCommand<IResetDaysCmd>(
    SupplierCalendarDayCommands.ResetDays
  );

  /**
   * Effectively deletes the model identified by the entityId
   * @return Done
   */
  resetOne = command(SupplierCalendarDayCommands.ResetOne);

  @attr('string') supplierId!: string;
  @attr('date') date!: Date;
  @attr('boolean') openForReception!: boolean;

  get formattedDate() {
    return moment(this.date).format('YYYY-MM-DD');
  }

  get indexedDay() {
    const { openForReception } = this;
    return {
      [moment(this.date).format('YYYY-MM-DD')]: {
        openForReception
      }
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'supplier-calendar-day': SupplierCalendarDay;
  }
}
