// @ts-ignore
import BufferIconCell from 'volta/components/cell/buffer-icon-cell';
// @ts-ignore
import CodeDescCell from 'volta/components/cell/code-desc-cell';
// @ts-ignore
import LabelCell from 'volta/components/cell/label-cell';
import BooleanFilter from 'volta/components/filters/boolean-filter';
// @ts-ignore
import BufferZoneOptionComponent from 'volta/components/filters/buffer-zone-option';
import InputFilter from 'volta/components/filters/input-filter';
import SelectFilter from 'volta/components/filters/select-filter';
import SkusPartTypeFilter from 'volta/components/filters/skus-part-type-filter';
import SkusSupplierFilter from 'volta/components/filters/skus-supplier-filter';
import SkusWarehouseFilter from 'volta/components/filters/skus-warehouse-filter';
import SkusWorkshopFilter from 'volta/components/filters/skus-workshop-filter';
import TagsFilter from 'volta/components/filters/tags-filter';
import TokenFilter from 'volta/components/filters/token-filter';
import { columnsToProps, columnsToSortOptions } from 'volta/models/collection-view';
import { AcquisitionCodesOptions } from 'volta/models/constants/acquisition-codes';
import { LeadTimeCategoriesOptions } from 'volta/models/constants/lead-time-categories';
import { PlanningMethodologiesOptions } from 'volta/models/constants/planning-methodologies';
import { VarCategoriesNullableOptions } from 'volta/models/constants/var-categories';
import { buildCustomFieldColumn, parseCFApiParamKey } from 'volta/models/custom-field-definition';
import { SkuResourceName } from 'volta/models/sku';
import { indexByFn } from 'volta/utils/array-utils';
import { beforeQueryWorkshop, collectionFiltersFromQueryParams } from 'volta/utils/filters-utils';
import { colorForCategory } from 'volta/utils/style-utils';

import Service, { service } from '@ember/service';
import { isPresent } from '@ember/utils';

import type {
  ICollectionDefinitionBuilder,
  ICollectionSortOption,
  ICollectionViewDefinition,
  ICreateCollectionViewCmd
} from 'volta/models/types/collection-view';
import type { IBvTableColumn } from 'volta/components/bv-table';
import type SessionUserService from 'volta/services/session-user';
import type SkuModel from 'volta/models/sku';
import type CustomFieldDefinitionsService from 'volta/services/custom-field-definitions-service';
import type IntlService from 'ember-intl/services/intl';
import type LabelService from 'volta/services/label-service';
import type StoreService from 'volta/services/store';
import type { IJsonApiQuery } from 'volta/utils/api/jsonapi-types';
import type RouterService from '@ember/routing/router-service';
export default class SkusCollectionDefinitionBuilder
  extends Service
  implements ICollectionDefinitionBuilder
{
  @service store!: StoreService;
  @service customFieldDefinitionsService!: CustomFieldDefinitionsService;
  @service intl!: IntlService;
  @service labelService!: LabelService;
  @service sessionUser!: SessionUserService;
  @service router!: RouterService;

  buildColumns() {
    const { intl, labelService, customFieldDefinitionsService } = this;
    const defs: IBvTableColumn[] = [
      {
        columnName: intl.t('part'),
        sortKey: 'skuDescription',
        valuePath: 'part.code',
        width: 250,
        minWidth: 100,
        cellComponent: CodeDescCell,
        isFixed: 'left',
        format: (model: SkuModel) => {
          return {
            link: 'protected.skus.sku',
            linkParam: model.id,
            code: model.part.code,
            description: model.skuDescription,
            cellClasses: model.isActive ? undefined : 'u-text-strike'
          };
        }
      },

      {
        columnName: intl.t('warehouse'),
        sortKey: 'warehouseCode',
        valuePath: 'warehouse.code',
        width: 100,
        minWidth: 50,
        isReorderable: true,
        isResizable: true,
        isFixed: 'left',
        cellComponent: LabelCell,
        format: (sku: SkuModel) => {
          return {
            label: sku.warehouse.code,
            color: 'green',
            iconRight: 'link-outline',
            tooltipText: sku.warehouse.description,
            onClick: () =>
              this.router.transitionTo('protected.warehouse', sku.warehouse.warehouseId)
          };
        }
      },
      {
        columnName: intl.t('partType'),
        sortKey: 'partType',
        valuePath: 'partType.code',
        width: 100,
        minWidth: 50,
        isReorderable: true,
        isResizable: true,
        cellComponent: LabelCell,
        format: (sku: SkuModel) => {
          return {
            label: sku.partType?.code,
            tooltipText: sku.partType?.description
          };
        }
      },
      {
        columnName: intl.t('supplier'),
        sortKey: 'supplierCode',
        valuePath: 'supplier.code',
        width: 100,
        minWidth: 50,
        isReorderable: true,
        isResizable: true,
        disabled: true,
        cellComponent: LabelCell,
        format: (sku: SkuModel) => {
          return {
            label: sku.supplier?.code,
            color: 'primary',
            iconRight: 'link-outline',
            tooltipText: sku.supplier?.description,
            onClick: () =>
              sku.supplier &&
              this.router.transitionTo('protected.supplier', sku.supplier.supplierId)
          };
        }
      },
      {
        columnName: intl.t('workshop'),
        sortKey: 'workshopCode',
        valuePath: 'workshop.code',
        width: 100,
        minWidth: 50,
        isReorderable: true,
        isResizable: true,
        disabled: true,
        cellComponent: LabelCell,
        format: (sku: SkuModel) => {
          return {
            label: sku.workshop?.code,
            color: 'severe',
            iconRight: 'link-outline',
            tooltipText: sku.workshop?.description,
            onClick: () =>
              sku.workshop &&
              this.router.transitionTo('protected.workshop', sku.workshop.workshopId)
          };
        }
      },
      {
        columnName: intl.t('deliveryWorkshop'),
        sortKey: 'deliveryWorkshopCode',
        valuePath: 'deliveryWorkshop.code',
        width: 100,
        minWidth: 50,
        isReorderable: true,
        isResizable: true,
        disabled: true,
        cellComponent: LabelCell,
        format: (sku: SkuModel) => {
          return {
            label: sku.deliveryWorkshop?.code,
            color: 'purple',
            iconRight: 'link-outline',
            tooltipText: sku.deliveryWorkshop?.description,
            onClick: () =>
              sku.deliveryWorkshop &&
              this.router.transitionTo('protected.workshop', sku.deliveryWorkshop.workshopId)
          };
        }
      },
      {
        columnName: intl.t('planningMethodology'),
        valuePath: 'methodology',
        sortKey: 'methodology',
        width: 60,
        cellComponent: BufferIconCell,
        isReorderable: true,
        isResizable: true,
        format: (model: SkuModel) => {
          return model;
        }
      },
      {
        columnName: intl.t('acqCodeShort'),
        valuePath: 'acqCode',
        sortKey: 'acqCode',
        width: 80,
        isReorderable: true,
        isResizable: true,
        cellComponent: LabelCell,
        format: (model: SkuModel) => {
          return {
            label: intl.t(`acqCodes.${model.acqCode || 'UNKNOWN'}`)
          };
        }
      },
      {
        columnName: intl.t('leadTimes'),
        sortKey: 'voltaLeadTime',
        valuePath: 'voltaLeadTime',
        classNames: 'u-text-mono',
        width: 65,
        textAlign: 'right',
        isReorderable: true,
        isResizable: true,
        format: (model: SkuModel) => {
          return model.vltDisplay;
        }
      },
      {
        columnName: intl.t('moq'),
        sortKey: 'minOrderQty',
        valuePath: 'minOrderQty',
        classNames: 'u-text-mono',
        width: 70,
        textAlign: 'right',
        isReorderable: true,
        isResizable: true,
        format: (model: SkuModel) => {
          return isPresent(model.minOrderQty)
            ? intl.formatNumber(model.minOrderQty as number)
            : null;
        }
      },
      {
        columnName: intl.t('orderMultiple'),
        sortKey: 'orderMultiple',
        valuePath: 'orderMultiple',
        classNames: 'u-text-mono',
        width: 80,
        textAlign: 'right',
        isReorderable: true,
        isResizable: true,
        format: (model: SkuModel) => {
          return isPresent(model.orderMultiple)
            ? intl.formatNumber(model.orderMultiple as number)
            : null;
        }
      },
      {
        columnName: intl.t('unit'),
        sortKey: 'partUnitMeasure',
        valuePath: 'part.unitMeasure',
        width: 40,
        isReorderable: true,
        isResizable: true,
        format: (model: SkuModel) => {
          return labelService.unitMeasure(model.part.unitMeasure);
        }
      },
      {
        columnName: intl.t('inventoryUnitPrice'),
        sortKey: 'inventoryUnitPrice',
        valuePath: 'inventoryUnitPrice',
        classNames: 'u-text-mono',
        width: 80,
        textAlign: 'right',
        isReorderable: true,
        isResizable: true,
        format: (model: SkuModel) => {
          return model.inventoryUnitPrice ? intl.formatNumber(model.inventoryUnitPrice) : null;
        }
      },
      {
        columnName: intl.t('planner'),
        valuePath: 'planner',
        sortKey: 'planner',
        width: 80,
        isReorderable: true,
        isResizable: true
      },
      {
        columnName: intl.t('buyer'),
        sortKey: 'buyer',
        valuePath: 'buyer',
        width: 80,
        isReorderable: true,
        isResizable: true
      },
      {
        columnName: intl.t('partCode'),
        sortKey: 'partCode',
        valuePath: 'partCode',
        width: 40,
        isReorderable: true,
        isResizable: true,
        disabled: true
      },
      {
        columnName: intl.t('skuDescription'),
        sortKey: 'skuDescription',
        valuePath: 'skuDescription',
        width: 80,
        isReorderable: true,
        isResizable: true,
        disabled: true
      },
      {
        columnName: intl.t('leadTimeCategory'),
        sortKey: 'leadTimeCategory',
        valuePath: 'leadTimeCategory',
        width: 80,
        isReorderable: true,
        isResizable: true,
        cellComponent: LabelCell,
        format: (sku: SkuModel) => {
          const { leadTimeCategory } = sku.skuStats ?? {};
          return leadTimeCategory
            ? {
                label: intl.t(`leadTimeCategories.${leadTimeCategory}`),
                color: colorForCategory(leadTimeCategory)
              }
            : undefined;
        }
      },
      {
        columnName: intl.t('varCategory'),
        sortKey: 'varCategory',
        valuePath: 'varCategory',
        width: 80,
        isReorderable: true,
        isResizable: true,
        cellComponent: LabelCell,
        format: (sku: SkuModel) => {
          const { varCategory } = sku.skuStats ?? {};
          return varCategory
            ? {
                label: intl.t(`varCategories.${varCategory}`),
                color: colorForCategory(varCategory)
              }
            : undefined;
        }
      },
      {
        columnName: intl.t('criticalPathCount'),
        valuePath: 'skuStats.criticalCount',
        sortKey: 'skuStats.criticalCount',
        classNames: 'u-text-mono',
        textAlign: 'right',
        isResizable: true,
        isReorderable: true,
        minWidth: 25,
        width: 80
      }
    ];

    const customFieldProps: Array<IBvTableColumn | undefined> = (
      customFieldDefinitionsService.skusCustomProperties() ?? []
    ).map((def) =>
      buildCustomFieldColumn(def, (model: SkuModel) => {
        const dataType = def.dataType ? def.dataType.toLowerCase() : undefined;
        const key = parseCFApiParamKey(def.property);
        const { customFields } = model;

        const value = customFields ? customFields[key] : undefined;
        return {
          value,
          dataType
        };
      })
    );

    return defs.concat(customFieldProps.filter(Boolean) as IBvTableColumn[]);
  }

  buildFilters(queryParamsValues: TQueryParams) {
    const { store, customFieldDefinitionsService } = this;
    const filters = indexByFn(
      collectionFiltersFromQueryParams(queryParamsValues['filters'] as string),
      (f) => f.property
    );
    const warehouseIds = filters.warehouse?.values;
    const normalDefs: Array<TFilterDefinition> = [
      {
        queryParam: 'partType',
        apiParam: 'partTypeId',
        comparators: ['in', 'ni'],
        component: SkusPartTypeFilter,
        componentArgs: {}
      },
      {
        queryParam: 'warehouse',
        apiParam: 'warehouseId',
        comparators: ['in', 'ni'],
        component: SkusWarehouseFilter,
        componentArgs: {}
      },
      {
        queryParam: 'deliveryWorkshop',
        apiParam: 'deliveryWorkshopId',
        comparators: ['in', 'ni'],
        component: SkusWorkshopFilter,
        componentArgs: { beforeQuery: beforeQueryWorkshop('DELIVERY', queryParamsValues.warehouse) }
      },
      {
        queryParam: 'workshop',
        apiParam: 'workshopId',
        comparators: ['in', 'ni'],
        component: SkusWorkshopFilter,
        componentArgs: { beforeQuery: beforeQueryWorkshop('WORKSHOP', queryParamsValues.warehouse) }
      },
      {
        queryParam: 'supplier',
        apiParam: 'supplierId',
        comparators: ['in', 'ni'],
        component: SkusSupplierFilter,
        componentArgs: {}
      },
      {
        queryParam: 'q',
        apiParam: 'q',
        comparators: ['lk'],
        component: InputFilter,
        hidden: true,
        componentArgs: {}
      },
      {
        queryParam: 'acqCode',
        apiParam: 'acqCode',
        comparators: ['in'],
        component: SelectFilter,
        componentArgs: { possibleValues: AcquisitionCodesOptions }
      },
      {
        queryParam: 'methodology',
        apiParam: 'methodology',
        comparators: ['in'],
        component: SelectFilter,
        componentArgs: { possibleValues: PlanningMethodologiesOptions }
      },
      {
        queryParam: 'tags',
        apiParam: 'tags',
        comparators: ['any', 'all', 'ni'],
        component: TagsFilter,
        componentArgs: {
          entityTypes: ['skus', 'parts'],
          warehouseIds
        }
      },
      {
        queryParam: 'isActive',
        apiParam: 'isActive',
        comparators: ['eq'],
        component: BooleanFilter,
        clearDefaultValue: ['true'],
        componentArgs: {}
      },
      {
        queryParam: 'planner',
        apiParam: 'planner',
        comparators: ['in', 'ni'],
        component: TokenFilter,
        componentArgs: {
          warehouseIds,
          searchFn: (query: IJsonApiQuery) => {
            return store.customQuery('sku', 'planners', undefined, query);
          }
        }
      },
      {
        queryParam: 'buyer',
        apiParam: 'buyer',
        comparators: ['in', 'ni'],
        component: TokenFilter,
        componentArgs: {
          warehouseIds,
          searchFn: (query: IJsonApiQuery) => {
            return store.customQuery('sku', 'buyers', undefined, query);
          }
        }
      },
      {
        queryParam: 'varCategory',
        apiParam: 'varCategory',
        comparators: ['in'],
        component: SelectFilter,
        componentArgs: {
          possibleValues: VarCategoriesNullableOptions,
          itemComponent: BufferZoneOptionComponent
        }
      },
      {
        queryParam: 'leadTimeCategory',
        apiParam: 'leadTimeCategory',
        comparators: ['in'],
        component: SelectFilter,
        componentArgs: {
          possibleValues: LeadTimeCategoriesOptions,
          itemComponent: BufferZoneOptionComponent
        }
      }
    ];

    return normalDefs.concat(customFieldDefinitionsService.skusCustomFieldsFilters());
  }

  buildDefaultView(columns: IBvTableColumn[]): ICreateCollectionViewCmd {
    const { intl } = this;
    const properties = columnsToProps(columns).filter((c) => c.property !== 'partType.code');
    return {
      name: intl.t('collectionViewsPage.default.name', {
        resourceNamePlural: intl.t(SkuResourceName.plural)
      }),
      description: intl.t('collectionViewsPage.default.description', {
        resourceNamePlural: intl.t(SkuResourceName.plural)
      }),
      collectionType: 'TABLE',
      entityType: SkuResourceName.plural,
      properties,
      sortBy: [{ property: 'part.code', asc: true }],
      createdBy: this.sessionUser.userId,
      filters: [{ property: 'isActive', comparator: 'eq', values: ['true'] }]
    };
  }

  build(queryParams: TQueryParams, sorts: ICollectionSortOption[] = []) {
    const filterDefinitions = this.buildFilters(queryParams);
    const columns = this.buildColumns();
    const defaultView = this.buildDefaultView(columns);

    const view: ICollectionViewDefinition = {
      resourceName: SkuResourceName,
      sortLimit: 0,
      sorts: columnsToSortOptions(columns).concat(sorts) as ICollectionSortOption[],
      properties: columnsToProps(columns),
      filters: filterDefinitions,
      definitions: columns,
      types: ['TABLE'],
      defaultView
    };

    return view;
  }
}

declare module '@ember/service' {
  interface Registry {
    'collection-builder-skus': SkusCollectionDefinitionBuilder;
  }
}
