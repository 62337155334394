import _drop from 'lodash/drop';
import _take from 'lodash/take';
import User from 'volta/models/user';
import UserProfile from 'volta/models/user-profile';
import { userName } from 'volta/utils/text-utils';

import ArrayProxy from '@ember/array/proxy';
import Component from '@glimmer/component';
import { inject as service } from "@ember/service";
import SessionUserService from "volta/services/session-user";

const SHOW_USERS_LIMIT = 2;

interface IArgs {
  users: Array<UserProfile | User>;
}

export default class BvAvatarGroup extends Component<IArgs> {
  @service sessionUser!: SessionUserService;
  get users() {
    return Array.isArray(this.args.users)
      ? this.args.users
      : 'toArray' in this.args.users
      ? (this.args.users as ArrayProxy<UserProfile | User>).toArray()
      : [];
  }
  get showedUsers() {
    const { users } = this;
    return _take(
      users,
      users.length !== SHOW_USERS_LIMIT + 1 ? SHOW_USERS_LIMIT : SHOW_USERS_LIMIT + 1
    );
  }

  get hiddenUserNames() {
    const { users } = this;
    return _drop<User | UserProfile>(users, 2)
      .map((user) => userName(user.firstName, user.lastName, user.login) ?? '')
      .join(', ');
  }

  get showCount() {
    return this.users.length > SHOW_USERS_LIMIT + 1;
  }

  get count() {
    return this.users.length - SHOW_USERS_LIMIT;
  }

  username = (user: User | UserProfile) =>
    userName(user.firstName, user.lastName, user.login) ?? '';
}
