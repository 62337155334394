import { helper } from '@ember/component/helper';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { deepEqual } from 'volta/utils/object-utils';

/**
 * @param state - initial state to use, undefined if not provided.
 *
 * @returns - Object with a `state` property and `setState` method to set the
 *            `state`
 */
export class SingleUseState<T> {
  @tracked state: T;
  @tracked isDirty: boolean = false;
  private _initialState: T;

  constructor(state: T) {
    this.state = state;
    this._initialState = state;
  }

  @action
  setState(state: T): T {
    this.isDirty = !deepEqual(state, this._initialState);
    this.state = state;
    return state;
  }

  @action
  set(fieldPath: keyof T | undefined, value: any) {
    if (!fieldPath || typeof this.state !== 'object') {
      this.setState(value as T);
    } else {
      const newState = Object.assign({}, this.state);
      set(newState, fieldPath, value);
      this.setState(newState);
    }
    return this.state;
  }
}

export function useState<T>([initialValue]: [T]): SingleUseState<T> {
  return new SingleUseState(initialValue);
}

export default helper(useState);
