import { keepLatestTask, task, timeout } from 'ember-concurrency';
import { UserPreferencesResourceName } from 'volta/models/user-preference';
import { defaultErrorHandler } from 'volta/utils/error-utils';

import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type SessionService from 'ember-simple-auth/services/session';
import type User from 'volta/models/user';
import type UserPreferences from 'volta/models/user-preference';
import type StoreService from 'volta/services/store';

import type {
  IDashboardWidget,
  IMenuPreferences,
  IPagesPreferences,
  IUpdatePreferencesCmd
} from 'volta/models/types/user';

export default class SessionUserService extends Service {
  @service session!: SessionService;
  @service store!: StoreService;

  /**
   * Session user model loaded from the store by the reloadUser async function
   */
  @tracked
  declare user: User;

  @tracked
  declare preferences: UserPreferences;

  /**
   * Alias getter for the session user id
   */
  get userId() {
    return this.session.data.authenticated.userId as string;
  }

  get isSupplierUser() {
    return Boolean(this.user?.isSupplier);
  }

  get suppliers() {
    return this.user?.suppliers.map((s) => s.id);
  }

  initPreferences = task(async () => {
    try {
      this.preferences = await this.store.findRecord(
        UserPreferencesResourceName.singular,
        this.user.id
      );
      this.preferences.user = this.user;
      return this.preferences;
    } catch (error) {
      defaultErrorHandler(error);
      throw error;
    }
  });

  setPreferences(cmd: IUpdatePreferencesCmd) {
    if (cmd.theme) {
      this.preferences.theme = cmd.theme;
    }
    if (cmd.locale) {
      this.preferences.locale = cmd.locale;
    }
    if (cmd.avatar) {
      this.preferences.avatar = cmd.avatar;
    }
    if (cmd.timeZone) {
      this.preferences.timeZone = cmd.timeZone;
    }
    this._updatePreferences.perform();
  }

  setDashboard(dashboard: IDashboardWidget[]) {
    this.preferences.dashboard = dashboard;
    this._updateDashboard.perform();
  }

  setMenuProperty(key: keyof IMenuPreferences, prop: unknown) {
    const { menu } = this.preferences;
    this.preferences.menu = { ...menu, [key]: prop };
    this._updateInterface.perform();
  }

  async setPageProperty<T = unknown>(key: string, pref: IPagesPreferences<T>) {
    const { pages = {} } = this.preferences;
    this.preferences.pages = { ...pages, [key]: pref };
    return  this._updateInterface.perform();
  }

  /**
   * Reload the session user model from the store
   */
  async reloadUser() {
    if (!this.userId) {
      return;
    }

    if (!this.user) {
      const adapterOptions = {
        query: {
          include: 'roles,warehouses,suppliers'
        }
      };

      this.user = await this.store.findRecord('user', this.userId, { adapterOptions });
      await this.initPreferences.perform();
    }

    return this.user;
  }

  _updateInterface = keepLatestTask(async () => {
    try {
      const { menu, pages = {} } = this.preferences;

      // Avoid rerender on other preferences (i.e dashboard)
      const updatedPreferences = await this.preferences.updateInterface({ menu, pages });
      this.preferences.menu = { ...updatedPreferences.menu };
      this.preferences.pages = { ...updatedPreferences.pages };
      await timeout(2000);
      return this.preferences;
    } catch (e) {
      defaultErrorHandler(e);
      return;
    }
  });

  _updateDashboard = keepLatestTask(async () => {
    try {
      const { dashboard } = this.preferences;
      this.preferences = await this.preferences.updateDashboard({ dashboard });
      await timeout(2000);
      return this.preferences;
    } catch (e) {
      defaultErrorHandler(e);
      return;
    }
  });

  _updatePreferences = keepLatestTask(async () => {
    try {
      const cmd = this.preferences.profilePrefs;
      this.preferences = await this.preferences.updatePreferences(cmd);
      await timeout(2000);
      return this.preferences;
    } catch (e) {
      defaultErrorHandler(e);
      return;
    }
  });
}

declare module '@ember/service' {
  interface Registry {
    'session-user': SessionUserService;
  }
}
