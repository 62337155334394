export type TLocaleKey = 'en-gb' | 'en-us' | 'fr-fr' | 'es-es' | 'de-de';

export interface ILocale {
  value: TLocaleKey;
  moment: string;
  label: string;
  title: string;
  description: string;
}

export const Locales: Record<TLocaleKey, ILocale> = {
  'en-gb': {
    value: 'en-gb',
    moment: 'en-gb',
    label: '🇬🇧 English UK',
    title: '🇬🇧',
    description: 'English UK'
  },
  'en-us': {
    value: 'en-us',
    moment: 'en',
    label: '🇺🇸 English US',
    title: '🇺🇸',
    description: 'English US'
  },
  'fr-fr': {
    value: 'fr-fr',
    moment: 'fr',
    label: '🇫🇷 Français',
    title: '🇫🇷',
    description: 'Français'
  },
  'es-es': {
    value: 'es-es',
    moment: 'es',
    label: '🇪🇸 Español',
    title: '🇪🇸',
    description: 'Español'
  },
  'de-de': {
    value: 'de-de',
    moment: 'de',
    label: '🇩🇪 Deutsch',
    title: '🇩🇪',
    description: 'Deutsch'
  }
};

export const LocalesArray = Object.keys(Locales).map((key: string) => Locales[key as TLocaleKey]);

export function localeFromCode(value?: TLocaleKey) {
  if (!value) {
    return undefined;
  }

  let locale: ILocale | undefined;
  Object.keys(Locales).forEach((k) => {
    const l = Locales[k as TLocaleKey];
    if (l.value === value) {
      locale = l;
    }
  });
  return locale;
}
