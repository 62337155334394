import BaseModel from 'volta/models/base-model';
import { resourceCommand as command } from 'volta/utils/api';

import { attr } from '@ember-data/model';
import { isPresent } from "@ember/utils";

export const ScheduleResourceName = {
  singular: 'schedule',
  plural: 'schedules'
};

interface TPlanningCalculationArgs {
  warehouseIds: string[];
}

interface TLambdaRunnerArgs {
  payload: JSON | string;
  functionName: string;
}

interface TOrderExportArgs {
  nbHoursBack: number;
}

export enum ScheduleCommands {
  CreateSchedule = 'CreateSchedule',
  UpdateSchedule = 'UpdateSchedule',
  LaunchSchedule = 'LaunchSchedule',
  DeleteSchedule = 'DeleteSchedule'
}

export type TScheduleGroup = 'Planning' | 'Export' | 'Import' | 'Custom';
export type TScheduleService =
  | 'PlanningCalculation'
  | 'OrderExport'
  | 'ImportConnector'
  | 'LambdaRunner';

export default class Schedule extends BaseModel {
  @attr('string') group!: TScheduleGroup;
  @attr('string') service!: TScheduleService;
  @attr('string') cron?: string;
  @attr('string') timezone?: string;
  @attr('string') description!: string;
  @attr('string') calendar?: string;
  @attr('date') nextTrigger?: Date;
  @attr('boolean') enabled!: boolean;
  @attr('string') context?: string;

  @attr() arg?: TPlanningCalculationArgs | TOrderExportArgs | TLambdaRunnerArgs;

  createSchedule = command(ScheduleCommands.CreateSchedule);
  updateSchedule = command(ScheduleCommands.UpdateSchedule);
  launchSchedule = command(ScheduleCommands.LaunchSchedule);
  deleteSchedule = command(ScheduleCommands.DeleteSchedule);

  toCreateCmd() {
    return this.toUpdateCmd();
  }

  toUpdateCmd() {
    const arg = this.arg as TLambdaRunnerArgs;
    if (arg?.payload) {
      arg.payload = typeof arg.payload === 'string' ? JSON.parse(arg.payload) : arg.payload;
    }

    return {
      group: this.group,
      service: this.service,
      cron: this.cron,
      timezone: this.timezone,
      description: this.description,
      calendar: this.calendar,
      nextTrigger: this.nextTrigger,
      enabled: this.enabled,
      context: isPresent(this.context) ? this.context : null,
      arg
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    schedule: Schedule;
  }
}
