import { computed } from '@ember/object';

const NOOP = () => {};

function noopIfUndefined() {
  return computed({
    get() {
      return NOOP;
    },
    set(_, v) {
      return v === undefined ? NOOP : v;
    }
  });
}
export default noopIfUndefined;
