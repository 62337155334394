export type TTimeBufferAlert = 'OK' | 'EARLY' | 'WARNING' | 'DANGER' | 'LATE';

const TimeBufferAlerts: Record<TTimeBufferAlert, string> = {
  OK: 'timeBufferAlertOptions.OK',
  EARLY: 'timeBufferAlertOptions.EARLY',
  WARNING: 'timeBufferAlertOptions.WARNING',
  DANGER: 'timeBufferAlertOptions.DANGER',
  LATE: 'timeBufferAlertOptions.LATE'
};

export const TimeBufferAlertOptions = Object.keys(TimeBufferAlerts).map((key: TTimeBufferAlert) => {
  return { key, label: TimeBufferAlerts[key] };
});

export default TimeBufferAlerts;
