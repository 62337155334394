// @ts-ignore
import Nav from 'ember-power-calendar/components/power-calendar/nav';
import moment from 'moment-timezone';

import { action } from '@ember/object';

import type BvSelect from 'volta/components/bv-select';

export interface ICalendar {
  uniqueId: string;
  selected: Date;
  loading: boolean;
  center: Date;
  locale: string;
  type: 'multiple' | 'range' | 'single';
  actions: {
    changeCenter: (
      newCenter: number | moment.Moment,
      calendar: ICalendar,
      event: MouseEvent
    ) => void;
    moveCenter: (
      step: number,
      unit: 'year' | 'month',
      calendar: ICalendar,
      event: MouseEvent
    ) => void;
    select: (day: Date, calendar: ICalendar, event: MouseEvent) => void;
  };
}

export default class BvCalendarNav extends Nav {
  get months() {
    return moment.months();
  }

  get years() {
    const nbYears = 10;
    let years = [];
    for (var i = -nbYears; i < nbYears * 2; i++) {
      years.push(`${i + moment().year()}`);
    }
    return years;
  }

  @action
  changeCalCenter(
    unit: 'year' | 'month',
    calendar: ICalendar,
    value: string,
    _bvSelect: BvSelect,
    e: MouseEvent
  ) {
    let newCenter = moment(calendar.center).clone()[unit](value);
    calendar.actions.changeCenter(newCenter, calendar, e);
  }
}
