import { enqueueTask } from 'ember-concurrency';
import moment from 'moment-timezone';
import Task, {
  indexedEntities,
  TaskEvents,
  TaskResourceName,
  TaskResourceNameFromParentRecordResourceName
} from 'volta/models/task';
import TaskService from 'volta/services/task-service';
import { normalize } from 'volta/utils/api/serialize-and-push';
import { defaultErrorHandler } from 'volta/utils/error-utils';

import { action } from '@ember/object';
import { sort } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import type SessionUserService from 'volta/services/session-user';
import type IntlService from 'ember-intl/services/intl';
import type StoreService from 'volta/services/store';
import type BvFlashService from 'volta/services/bv-flash';
import type EventStreamService from 'volta/services/event-stream';
import type { TTaskType, UTask } from 'volta/models/types/tasks';
import type { IResourceName } from 'volta/models/base-model';

export type TSelectedView = 'open' | 'resolved';
export type TTaskListVariation = 'entity' | 'date-entity';

interface IArgs {
  tasks: UTask[];
  resourceName?: IResourceName;
  scrollToDate: string;
  groupingField?: 'dueDate' | 'createdAt';
  entityId: string;
  taskType?: TTaskType;
  isAutoResolvable?: boolean;
  listType?: TTaskListVariation;
  onFilterChange?: (filters: Array<TTaskType>) => void;
  onDraftTask?: (task: UTask) => void;
  onSaveTask?: (task: UTask) => void;
  onDeleteTask?: (task: UTask) => void;
  onDestroy?: (task: UTask | undefined) => void;
}

export default class TasksKit extends Component<IArgs> {
  @service intl!: IntlService;
  @service store!: StoreService;
  @service bvFlash!: BvFlashService;
  @service eventStream!: EventStreamService;
  @service taskService!: TaskService;
  @service sessionUser!: SessionUserService;

  resourceName = TaskResourceNameFromParentRecordResourceName(this.args.resourceName);
  taskResourceName = TaskResourceName;
  noDateId = this.intl.t('tasksPage.noDueDate');
  dueDateIdPrefix = 'task-';
  sortProperties = ['dueDate:asc', 'createdAt:asc'];

  @tracked selectedTask: UTask | undefined;
  @tracked selectedView: TSelectedView = 'open';
  @tracked taskFilters: string[] = [];

  @sort('filteredTasks', 'sortProperties')
  sortedTasks!: UTask[];

  get filteredTasks() {
    const isArchiveView = this.selectedView === 'resolved';
    const filteredTasks = (this.args.tasks ?? []).filter(
      (t) => this.filterFn(t) && (isArchiveView ? !!t.resolvedAt : !t.resolvedAt)
    );
    if (this.selectedTask) {
      filteredTasks.push(this.selectedTask);
    }

    return filteredTasks;
  }

  get resolvedTaskCount() {
    return (this.args.tasks ?? []).filter((t) => this.filterFn(t) && t.resolvedAt).length;
  }

  get unresolvedTaskCount() {
    return (this.args.tasks ?? []).filter((t) => this.filterFn(t) && !t.resolvedAt).length;
  }

  get indexedEntities() {
    return indexedEntities(this.args.tasks);
  }

  @cached
  get tasksTs(): Record<string, UTask[]> {
    if (this.args.listType === 'entity') {
      return TaskService.indexTasksByEntity(this.sortedTasks);
    }
    return TaskService.indexTasksByDate(this.sortedTasks, this.noDateId, this.groupingField);
  }

  get addTaskIsDisabled() {
    return this.args.tasks.some((t) => !t?.id) || !this.args.onDraftTask;
  }

  get groupingField() {
    return this.args.groupingField ?? 'createdAt';
  }

  constructor(owner: any, args: IArgs) {
    super(owner, args);
    this.addEventHandlers();
  }

  willDestroy() {
    this.eventStream.removeHandlers(this);
    this.args.onDestroy?.(this.selectedTask);
  }

  @action
  filterFn(t?: UTask) {
    return (
      t &&
      (!this.taskFilters.length || (this.taskFilters && this.taskFilters.includes(t.taskType))) &&
      t.id !== this.selectedTask?.id
    );
  }

  @action
  initScroll() {
    const { scrollToDate } = this.args;

    if (!this.selectedTask) {
      const formattedSelectedDate = scrollToDate ? moment(scrollToDate).format('L') : undefined;

      if (
        !formattedSelectedDate ||
        (formattedSelectedDate && !this.tasksTs[formattedSelectedDate]) ||
        (this.args.isAutoResolvable &&
          (this.args.taskType === 'PLANNING_TASK' || this.args.taskType === 'EXECUTION_TASK'))
      ) {
        this.createNewTaskDraft(formattedSelectedDate);
      }
      this.scrollTo(formattedSelectedDate);
    }
  }

  @action
  scrollToTask() {
    if (!this.selectedTask) {
      return;
    }
    if (!this.selectedTask.id && !this.selectedTask[this.groupingField]) {
      this.scrollTo(this.noDateId);
    } else if (this.selectedTask.dueDate) {
      const formattedDueDate = moment(this.selectedTask.dueDate).format('L');
      this.scrollTo(formattedDueDate);
    }
  }

  @action
  selectTask(task?: UTask, forceDelete = false): void {
    if (this.selectedTask && !task) {
      if (forceDelete && !this.selectedTask.id) {
        this.args.onDeleteTask?.(this.selectedTask);
      } else {
        this.handleSubmit.perform(this.selectedTask);
      }
    } else {
      if (this.selectedTask?.newWithoutComment) {
        this.args.onDeleteTask?.(this.selectedTask);
      }

      this.selectedTask = task;
    }
  }

  @action
  createNewTaskDraft(date?: string) {
    if (!this.resourceName) {
      return;
    }

    const newTask: UTask = this.store.createRecord(this.resourceName.singular, {
      linkedEntityId: this.args.entityId,
      createdAt: moment().toDate(),
      dueDate:
        date && moment(date, 'L').isValid() ? moment(date, 'L').format('YYYY-MM-DD') : undefined,
      taskType: this.args.taskType,
      resolveIf: 'MANUAL'
    });

    this.selectTask(newTask);
    this.scrollToTask();
    this.args.onDraftTask?.(newTask);
  }

  @action
  filterTasks(taskFilters: Array<TTaskType>) {
    this.taskFilters = taskFilters;
    this.args.onFilterChange?.(taskFilters);
  }

  handleSubmit = enqueueTask(async (task: UTask) => {
    try {
      // We delete remove the task if it has been added in the form
      // but not touched at all
      if (task.newWithoutComment) {
        return;
      }
      this.selectedTask = undefined;
      const saved = (await this.taskService.saveTask.perform(task)) as UTask;
      return this.args.onSaveTask?.(saved);
    } catch (e) {
      this._onError(e);
    }
  });

  handleResolve = enqueueTask(async (task: UTask) => {
    try {
      if (task.newWithoutComment) {
        return;
      }
      this.selectedTask = undefined;

      const resolvedTask = (
        task.resolvedAt
          ? await this.taskService.resolveTask.perform(task)
          : await this.taskService.reopenTask.perform(task)
      ) as UTask;
      return this.args.onSaveTask?.(resolvedTask);
    } catch (e) {
      this._onError(e);
    }
  });

  handleDelete = enqueueTask(async (task: UTask) => {
    if (!this.resourceName) {
      return;
    }
    try {
      await this.taskService.deleteTask.perform(task);
      this.selectedTask = undefined;
      this.args.onDeleteTask?.(task);
    } catch (e) {
      this._onError(e);
    }
  });

  private _onError(error: string): void {
    defaultErrorHandler(error);
    this.bvFlash.error(error);
  }

  private addEventHandlers() {
    if (this.args.resourceName) {
      this.eventStream.addHandler(
        this.args.resourceName.plural,
        TaskEvents.TaskCreated,
        this.onTaskCreated,
        this
      );
      this.eventStream.addHandler(
        this.args.resourceName.plural,
        TaskEvents.TaskUpdated,
        this.onTaskUpdated,
        this
      );
      this.eventStream.addHandler(
        this.args.resourceName.plural,
        TaskEvents.TaskDeleted,
        this.onTaskDeleted,
        this
      );
    }
  }

  private async onTaskCreated(payload: { [key: string]: any }) {
    await this.triggerOnEvent(payload, async (task: UTask) => {
      return this.args.onDraftTask?.(task);
    });
  }

  private async onTaskUpdated(payload: { [key: string]: any }) {
    await this.triggerOnEvent(payload, async (updatedTask: UTask) => {
      const task = this.args.tasks.find((t) => t.id === updatedTask.id);

      if (task) {
        task.comment = updatedTask.comment;
        task.dueDate = updatedTask.dueDate;
        task.resolvedAt = updatedTask.resolvedAt;
      }
    });
  }

  private async onTaskDeleted(payload: { [key: string]: any }) {
    await this.triggerOnEvent(payload, (task: UTask) => {
      return this.args.onDeleteTask?.(task);
    });
  }

  private async triggerOnEvent(payload: { [key: string]: any }, action: (task: Task) => void) {
    const task = normalize(TaskResourceName.singular, payload) as Task;
    const { createdBy, taskType, linkedEntityId } = task;

    if (
      createdBy &&
      createdBy.id !== this.sessionUser.userId &&
      this.args.taskType === taskType &&
      linkedEntityId === this.args.entityId
    ) {
      await action(task);
    }
  }

  private scrollTo(id?: string) {
    const elementToScrollTo = document.getElementById(`${this.dueDateIdPrefix}${id ?? 'New'}`);

    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest'
      });
    }
  }
}
