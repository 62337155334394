import { service } from '@ember/service';
import Component from '@glimmer/component';

import type { IResourceName } from 'volta/models/base-model';
import type { TTaskType, UTask } from 'volta/models/types/tasks';
import type EventStreamService from 'volta/services/event-stream';
import { TSelectedView } from '..';

interface IArgs {
  resourceName?: IResourceName;
  entityResourceName?: IResourceName;
  tasks: Record<string, UTask[]>;
  selectedTask?: UTask;
  taskType?: TTaskType;
  showTaskType?: boolean;
  isAutoResolvable?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  newDisabled?: boolean;
  hideAddButton?: boolean;
  tasksReadonly?: boolean;
  dueDateIdPrefix?: string;
  taskWrapperClass?: string;
  selectedView?: TSelectedView;
  onSubmit?: (task: UTask) => void;
  onDelete?: (task: UTask) => void;
  onResolve?: (task: UTask) => void;
  onSelect?: (task: UTask) => void;
  onDraft?: (task: UTask) => void;
}

export default class TaskList extends Component<IArgs> {
  @service eventStream!: EventStreamService;

  get tasks() {
    return Object.entries(this.args.tasks).map(([groupId, value]) => {
      return {
        groupId,
        tasks: value
      };
    });
  }
}
