
import {
  validateDate,
  validateNumber,
  validatePresence
} from 'ember-changeset-validations/validators';
import moment from 'moment-timezone';
import { IBvTableColumn } from 'volta/components/bv-table';
// @ts-ignore
import FormatCell from 'volta/components/cell/format-cell';
// @ts-ignore
import TagsCell from 'volta/components/cell/tags-cell';
import BooleanFilter from 'volta/components/filters/boolean-filter';
import DateFilter from 'volta/components/filters/date-filter';
import InputFilter from 'volta/components/filters/input-filter';
import SelectFilter from 'volta/components/filters/select-filter';
import BaseModel from 'volta/models/base-model';
import Warehouse from 'volta/models/warehouse';
import { _normalize, collectionCommand, resourceCommand as command } from 'volta/utils/api';
import { parseNumber } from 'volta/utils/math-utils';

import { attr, hasMany } from '@ember-data/model';
import { isPresent } from '@ember/utils';

import SkuModel from './sku';
import type { ICollectionProperty } from './types/collection-view';

import type {
  CFEntityType,
  CFTblName,
  ICustomFieldConstraints,
  ICustomFieldDefinition,
  TCustomFieldNumberFormat,
  TCustomFieldTableValue,
  TCustomFieldValues,
  TFieldType
} from './types/customFieldDefinition';

export const CustomFieldDefinitionResourceName = {
  singular: 'customFieldDefinition',
  plural: 'customFieldDefinitions'
};

enum TCustomFieldCommands {
  CreateCustomFieldDefinition = 'CreateCustomFieldDefinition',
  UpdateCustomFieldDefinition = 'UpdateCustomFieldDefinition',
  DeleteCustomFieldDefinition = 'DeleteCustomFieldDefinition',
  ReorderCustomFieldDefinitions = 'ReorderCustomFieldDefinitions'
}

export const CustomFieldDefinitionEvents = {
  CustomFieldDefinitionCreated: 'CustomFieldDefinitionCreated',
  CustomFieldDefinitionUpdated: 'CustomFieldDefinitionUpdated',
  CustomFieldDefinitionDeleted: 'CustomFieldDefinitionDeleted'
};

export const numberFormatsList = [
  {
    groupName: 'customFieldsDefinitionsPage.format',
    options: ['DECIMAL', 'INTEGER', 'PERCENT']
  },
  {
    groupName: 'customFieldsDefinitionsPage.currency',
    options: ['EUR', 'GBP', 'USD', 'JPY', 'CNY']
  }
];

export const Entity2Table: Record<CFEntityType, CFTblName> = {
  skus: 'inventories',
  suppliers: 'suppliers',
  workshops: 'workshops',
  planningOrders: 'planning_orders'
};

export const Table2Entity: Record<CFTblName, CFEntityType> = Object.keys(Entity2Table).reduce(
  (acc, v: CFEntityType) => {
    acc[Entity2Table[v]] = v;
    return acc;
  },
  {} as Record<CFTblName, CFEntityType>
);


export default class CustomFieldDefinition extends BaseModel implements ICustomFieldDefinition {
  static createCustomFields = collectionCommand(TCustomFieldCommands.CreateCustomFieldDefinition, {
    after: _normalize
  });

  static reorderCustomFields = collectionCommand(
    TCustomFieldCommands.ReorderCustomFieldDefinitions,
    {
      after: _normalize
    }
  );

  @attr('string') tblName!: CFTblName;
  @attr('string') name!: string;
  @attr('string') slug!: string;
  @attr('string') userId!: string;
  @attr('string') fieldType!: TFieldType;
  @attr('number') order!: number;
  @attr() defaultValue?: TCustomFieldTableValue;

  @attr('array', { defaultValue: () => [] }) acqCodes!: string[];
  @attr() fieldConstraints?: ICustomFieldConstraints;
  @attr('string') helpText?: string;
  @attr('string') numberFormat?: TCustomFieldNumberFormat;
  @attr('string') dateFormat?: string;
  @attr('boolean', { defaultValue: () => false }) required!: boolean;
  @attr('boolean', { defaultValue: () => false }) computed!: boolean;

  @hasMany('warehouse', { async: false })
  warehouses!: Array<Warehouse>;

  get warehouseIds() {
    return this.warehouses.mapBy('id');
  }

  /**
   * Checks if the custom field definition can be displayed
   *
   * @param warehouseId Warehouse id
   * @param acqCode Entity acquisition code
   */
  isAuthorized(warehouseId?: string, acqCode?: string): boolean {
    return (
      this.tblName === 'workshops' ||
      this.tblName === 'suppliers' ||
      ((!warehouseId ||
        this.warehouses.length === 0 ||
        this.warehouses.map((w: Warehouse) => w.id).includes(warehouseId)) &&
        (!acqCode || this.acqCodes.length === 0 || this.acqCodes.includes(acqCode)))
    );
  }

  deleteCustomField = command(TCustomFieldCommands.DeleteCustomFieldDefinition);
  updateCustomField = command(TCustomFieldCommands.UpdateCustomFieldDefinition);
}


export function buildCustomFieldColumn(def: ICollectionProperty, format: IBvTableColumn['format']) {
  const column: IBvTableColumn = {
    columnName: def.title!,
    valuePath: def.property,
    sortKey: def.property,
    width: 80,
    isReorderable: true,
    isResizable: true,
    isCustomField: true,
    cellComponent: FormatCell,
    format
  };

  if (def.dataType === 'ARRAY') {
    column.cellComponent = TagsCell;
    column.format = (model: SkuModel) => {
      const { customFields } = model;
      const key = parseCFApiParamKey(def.property);
      const value = customFields ? customFields[key] : undefined;

      return value;
    };
  }

  if (def.dataType === 'TABLE') {
    return undefined;
  }

  if (def.dataType === 'FORMULA') {
    return undefined;
  }

  return column;
}

export const CUSTOM_FIELD_PREFIX = 'customFields';

/**
 * Build a values structure where the key is the name not the slug
 * We do that to ease the use of ember-changeset-validations, so that
 * error message use the correct field name and not the slug
 *
 * @param values Def slug (or id) / value pairs
 * @param definitions List of custom field definitions
 */
export function buildValues(
  values: TCustomFieldValues = {},
  definitions: CustomFieldDefinition[] = []
) {
  const returnValues: TCustomFieldValues = {};

  definitions.forEach((d) => {
    let value = values[d.slug] ?? values[d.id];

    // Avoid NaN if empty
    if (d.fieldType === 'NUMBER' && typeof value === 'number') {
      value = parseNumber(value);
    }

    if (isPresent(value)) {
      // We need to do parse the value as a Javascript Date because
      // ember-changeset-validation won't recognize a date formatted as a String
      if (d.fieldType === 'DATE') {
        value = moment(value as string).toDate();
      }

      if (d.fieldType === 'BOOLEAN') {
        value = `${value}`.toLowerCase() === 'true';
      }
    }

    returnValues[d.slug] = value;
  });
  return returnValues;
}

/**
 * Builds the fields validations
 *
 * @param definitions List of definitions
 */
export function buildValidations(definitions: CustomFieldDefinition[] = []) {
  const validations: Record<string, any> = {};
  definitions.forEach((def) => {
    const defValidations = [];
    const { fieldConstraints, fieldType, slug, required, computed } = def;
    if (!computed) {
      if (required && fieldType !== 'BOOLEAN') {
        defValidations.push(validatePresence(true));
      }

      if (fieldConstraints) {
        const { gte, lte, onOrBefore, onOrAfter } = fieldConstraints;

        if (fieldType === 'NUMBER' && (lte || gte)) {
          defValidations.push(
            validateNumber({
              lte: lte && parseNumber(lte),
              gte: gte && parseNumber(gte),
              allowBlank: !required
            })
          );
        }

        if (fieldType === 'DATE' && (onOrBefore || onOrAfter)) {
          defValidations.push(
            validateDate({
              onOrAfter: onOrAfter && moment(onOrAfter).toDate(),
              onOrBefore: onOrBefore && moment(onOrBefore).toDate(),
              allowBlank: !required
            })
          );
        }
      }
    }

    validations[slug] = defValidations;
  });

  return validations;
}

export function buildCFApiParamPrefix(apiPrefix?: string) {
  return `${apiPrefix ? apiPrefix + '.' : ''}${CUSTOM_FIELD_PREFIX}.`;
}

export function parseCFApiParamKey(param: string) {
  return param.split('.').pop()!;
}

export function buildCustomFieldFilter(
  def?: CustomFieldDefinition,
  apiPrefix?: string
): TFilterDefinition | undefined {
  if (!def) {
    return undefined;
  }

  const { fieldType, name, slug } = def;
  const fieldConstraints = def.fieldConstraints ? def.fieldConstraints : {};
  const queryParam = `${buildCFApiParamPrefix(apiPrefix)}${slug}`;
  const filterDef = {
    queryParam,
    apiParam: queryParam,
    title: name,
    isCustomField: true
  };

  switch (fieldType) {
    case 'TEXT':
      return {
        ...filterDef,
        comparators: fieldConstraints.list ? ['in', 'ni'] : ['lk'],
        component: fieldConstraints.list ? SelectFilter : InputFilter,
        componentArgs: {
          possibleValues: fieldConstraints.list?.map((key: string) => {
            return { key, value: key };
          })
        }
      };
    case 'NUMBER':
      return {
        ...filterDef,
        comparators: ['eq', 'gt', 'lt', 'bt'],
        component: InputFilter,
        componentArgs: { inputType: 'number' }
      };
    case 'BOOLEAN':
      return {
        ...filterDef,
        comparators: ['eq'],
        component: BooleanFilter,
        componentArgs: {}
      };

    case 'DATE':
      return {
        ...filterDef,
        comparators: ['eq', 'gt', 'lt', 'bt'],
        component: DateFilter,
        componentArgs: {}
      };

    case 'ARRAY':
      return {
        ...filterDef,
        comparators: ['any', 'all'],
        component: SelectFilter,
        componentArgs: {
          possibleValues: fieldConstraints.list?.map((key: string) => {
            return { key, value: key };
          })
        }
      };
    default:
      return undefined;
  }
}

export const buildCustomFieldValues = (
  defs: CustomFieldDefinition[],
  values: Record<string, any>
) => {
  return defs.reduce((agg: any[], cf: CustomFieldDefinition) => {
    let value = values[cf.slug] || values[cf.id];
    value = formatCustomFieldValue(value, cf.fieldType);

    agg.push({
      customFieldId: cf.id,
      value: value
    });

    return agg;
  }, [] as any[]);
};

export const formatCustomFieldValue = (value: unknown, fieldType: TFieldType) => {
  if (fieldType !== 'FORMULA') {
    if (isPresent(value) && fieldType === 'NUMBER') {
      return parseNumber(value);
    } else if (fieldType === 'DATE' && value) {
      return moment(value).format('YYYY-MM-DD');
    } else if (isPresent(value) && fieldType === 'BOOLEAN') {
      return `${value}`.toLowerCase() === 'true';
    }
  }
  return value;
};

export const initialiseFields = (fields: CustomFieldDefinition[]): Record<string, any> => {
  return fields.reduce((acc, field: CustomFieldDefinition) => {
    if (field.required) {
      acc[field.id] = field.defaultValue;
    }
    return acc;
  }, {} as { [id: string]: any });
};

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'custom-field-definition': CustomFieldDefinition;
  }
}
