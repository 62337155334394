// @ts-ignore
import { ref } from 'ember-ref-bucket';
import { IBvResourceListContext, SELECT_ALL_ITEMS } from 'volta/components/bv-resource-list';
import { idVariation } from 'volta/utils/id';

import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

function stopPropagation(event: Event) {
  event.stopPropagation();
}

function getIdentity(item?: any) {
  return item && item.id;
}

interface IArgs {
  /**
   * Unique identifier for the item
   */
  itemId?: string;

  /**
   * Visually hidden text for screen readers
   */
  accessibilityLabel?: string;

  /**
   * Id of the element the item onClick controls
   */
  ariaControls?: string;

  /**
   * Tells screen reader the controlled element is expanded
   */
  ariaExpanded: boolean;

  /**
   * Media component or text
   */
  media?: string | Component;

  /**
   * @property persistActions
   */
  persistActions: boolean;

  /**
   * @property shortcutActions
   */
  shortcutActions?: any[];

  /**
   * @property url
   */
  route?: string;

  /**
   * @property onClick
   */
  onClick?: (itemId?: string) => void;

  context: IBvResourceListContext;

  item: unknown;

  selectOnClick: boolean;
}

export default class BvResourceListItem extends Component<IArgs> {
  @ref('containerNode') containerNode?: HTMLElement;
  @ref('buttonOverlay') buttonOverlay?: HTMLElement;

  @tracked focused = false;
  @tracked focusedInner = false;

  'data-test-id' = 'item-wrapper';

  stopPropagation = stopPropagation;
  elementId = guidFor(this);
  checkboxId = idVariation(this.elementId, 'ResourceListItemCheckbox');
  overlayId = idVariation(this.elementId, 'OverlayId');

  get isSelected() {
    const { context, itemId } = this.args;
    const selectedItems = (context && context.selectedItems) || [];
    return (
      selectedItems &&
      ((Array.isArray(selectedItems) && selectedItems.some((i) => getIdentity(i) === itemId)) ||
        selectedItems === SELECT_ALL_ITEMS)
    );
  }

  @action
  handleAnchorFocus() {
    this.focused = true;
    this.focusedInner = true;
  }

  @action
  handleFocusedBlur() {
    this.focused = false;
    this.focusedInner = false;
  }

  @action
  handleFocus(event: FocusEvent) {
    if (
      event.target === this.buttonOverlay ||
      (this.containerNode &&
        event.target === this.containerNode.querySelector(`#${this.overlayId}`))
    ) {
      this.focused = true;
      this.focusedInner = false;
    } else if (this.containerNode && this.containerNode.contains(event.target as Node)) {
      this.focused = true;
      this.focusedInner = true;
    }
  }

  @action
  handleBlur({ relatedTarget }: FocusEvent) {
    if (
      this.containerNode &&
      relatedTarget instanceof Element &&
      this.containerNode.contains(relatedTarget)
    ) {
      return;
    }

    this.focused = false;
    this.focusedInner = false;
  }

  @action
  handleMouseOut() {
    if (this.focused) {
      this.focused = false;
      this.focusedInner = false;
    }
  }

  @action
  handleLargerSelectionArea(event: Event) {
    stopPropagation(event);
    this.handleSelection(!this.isSelected);
  }

  @action
  handleSelection(value: boolean) {
    const { itemId, context, item } = this.args;
    if (!itemId || !context.onSelectionChange) {
      return;
    }
    this.focused = value;
    this.focusedInner = value;
    context.onSelectionChange(value, itemId, item);
  }

  @action
  handleClick(event: Event) {
    const { item, itemId, onClick, route, context, selectOnClick } = this.args;
    const anchor = this.containerNode && this.containerNode.querySelector('a');

    if (context.selectMode && !selectOnClick) {
      this.handleLargerSelectionArea(event);
      return;
    }

    if (anchor === event.target) {
      return;
    }

    if (route && anchor) {
      anchor.click();
    }

    selectOnClick
      ? context.onSelectionChange(Boolean(item), itemId, item, selectOnClick)
      : onClick?.(itemId);
  }

  @action
  handleKeypress(event: KeyboardEvent) {
    const { onClick, context } = this.args;
    const { key } = event;

    if (onClick && key === 'Enter' && !context.selectMode) {
      onClick();
    }
  }

  compareEventNode(event: Event) {
    return this.args.onClick
      ? event.target === this.containerNode
      : (event.target as Element).tagName.toLowerCase() === 'a';
  }
}
