import moment from 'moment-timezone';

import { task } from 'ember-concurrency';
import { localeFromCode, Locales, LocalesArray, TLocaleKey } from 'volta/locales';
import { defaultErrorHandler } from 'volta/utils/error-utils';

import { computed } from '@ember/object';
import Service, { inject as service } from '@ember/service';

import type IntlService from 'ember-intl/services/intl';
import type { TOptions } from 'ember-intl/services/intl';
// @ts-ignore
import type MomentService from 'ember-moment/services/moment';
import type Translation from 'volta/models/translation';
import type StoreService from 'volta/services/store';
export default class LocalizationService extends Service {
  // Services
  // ~~~~~
  @service declare store: StoreService;
  @service declare intl: IntlService;
  @service declare moment: MomentService;

  initialTranslations?: object;

  get guessedTimeZone() {
    return moment.tz?.guess();
  }
  // @ts-ignore

  get availableTimeZones() {
    return moment.tz?.names() ?? [];
  }

  @computed('moment.locale')
  get weekDays() {
    const shortFormat = new Intl.DateTimeFormat(moment.locale(), { weekday: 'short' }).format;
    const longFormat = new Intl.DateTimeFormat(moment.locale(), { weekday: 'long' }).format;
    return [...Array(7).keys()].map((day) => {
      return {
        key: day + 1,
        label: longFormat(new Date(Date.UTC(2021, 5, day))),
        abbrv: shortFormat(new Date(Date.UTC(2021, 5, day)))
      };
    });
  }

  get defaultLocale() {
    const lang = this.browserLanguage;
    const existing = LocalesArray.find((l) => l.value.toLowerCase() === lang.toLowerCase());
    return existing ?? Locales['en-gb'];
  }

  setLocale(locale?: TLocaleKey) {
    this.intl.setLocale([locale ?? this.defaultLocale.value]);
    const momentLocale = (localeFromCode(locale) ?? this.defaultLocale).moment;
    this.moment.changeLocale(momentLocale);
  }

  setTimeZone(tz?: string) {
    this.moment.changeTimeZone(tz ?? this.guessedTimeZone);
  }

  tOrElse(translation: string, fallback?: string, options?: TOptions & { htmlSafe?: boolean }) {
    return this.intl.exists(translation)
      ? this.intl.t(translation, options)
      : fallback ?? translation;
  }

  async setTranslation(locale = this.browserLanguage, forceUpdate = false) {
    if (!this.intl.translationsFor(locale) || forceUpdate) {
      const translation = await this.task.perform(locale);
      if (translation) {
        this.intl.addTranslations(translation.locale.toLowerCase(), translation.messages);
        this.setLocale(locale.toLowerCase() as TLocaleKey);
      }
    } else {
      this.setLocale(locale.toLowerCase() as TLocaleKey);
    }
  }

  get browserLanguage(): string {
    return navigator.language;
  }

  task = task(async (locale: string) => {
    try {
      const translation: Translation = await this.store.findRecord('translation', locale);
      return translation;
    } catch (error) {
      return defaultErrorHandler(error);
    }
  });
}

declare module '@ember/service' {
  interface Registry {
    localization: LocalizationService;
  }
}
