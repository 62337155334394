import { TAduFrequency } from 'volta/models/types/planning-settings';

import { assert } from '@ember/debug';
import { get } from '@ember/object';
import { isPresent } from '@ember/utils';

import {
  ADU_FREQUENCIES,
  ADU_FREQUENCIES_CONVERSIONS,
  ADU_HORIZON_RANGES
} from '../models/constants/adu';
import { isNaN } from './math-utils';

/**
 * Convert an ADU horizon from an ADU frequency to another
 *
 * @param {number} horizon The ADU horizon to convert
 * @param {ADU_FREQUENCIES} fromFrequency ADU frequency to convert from
 * @param {ADU_FREQUENCIES} toFrequency ADU frequency to convert to
 * @return {number} The converted ADU horizon
 */
export function convertAduFrequencies(
  horizon: number | undefined,
  fromFrequency: TAduFrequency,
  toFrequency: TAduFrequency
) {
  if (!isPresent(horizon)) {
    return undefined;
  }

  if (fromFrequency === toFrequency) {
    return horizon;
  }

  assert(
    `fromFrequency [${fromFrequency}] is not an ADU frequency (${Object.keys(ADU_FREQUENCIES).join(
      ', '
    )})`,
    isPresent(ADU_FREQUENCIES[fromFrequency])
  );

  assert(
    `toFrequency [${toFrequency}] is not an ADU frequency (${Object.keys(ADU_FREQUENCIES).join(
      ', '
    )})`,
    isPresent(ADU_FREQUENCIES[toFrequency])
  );

  const toRange = get(ADU_HORIZON_RANGES, toFrequency);
  const ratio = get(
    ADU_FREQUENCIES_CONVERSIONS,
    `${fromFrequency}.${toFrequency}` as TAduFrequency
  );
  const result = Math.round((horizon as number) * +(ratio || 0));
  const max = toRange?.max || 0;
  const min = toRange?.min || 0;

  return result > max ? max : result < min ? min : result;
}

export function convertDailyAdu(adu: number, toFrequency: TAduFrequency) {
  if (!isPresent(adu)) {
    return undefined;
  }

  if ('DAILY' === toFrequency) {
    return adu;
  }
  assert(
    `toFrequency [${toFrequency}] is not an ADU frequency (${Object.keys(ADU_FREQUENCIES).join(
      ', '
    )})`,
    isPresent(ADU_FREQUENCIES[toFrequency])
  );
  const ratio = get(ADU_FREQUENCIES_CONVERSIONS, `DAILY.${toFrequency}` as TAduFrequency);
  return Math.round(adu / +(ratio || 0));
}

/**
 * Calculate the ADU horizon to ADU horizon offset (for blending) ratio
 *
 * @param {number} horizon The ADU Horizon
 * @param {number} offset The ADU horizon offset
 * @return {number} ADU Horizon to Offset ratio
 */
export function aduHorizonOffsetRatio(horizon: number, offset: number): number {
  const result = horizon !== 0 ? offset / horizon : 0;
  return isNaN(result) ? 1 : result;
}
