import config from 'volta/config/environment';

import Transition from '@ember/routing/-private/transition';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import EmberRouter from '@embroider/router';

import type BvMetricsService from './services/bv-metrics';

export default class Router extends EmberRouter {
  @service router!: RouterService;
  @service bvMetrics!: BvMetricsService;

  location = config.locationType;
  rootURL = config.rootURL;

  constructor() {
    super(...arguments);

    this.on('routeDidChange', (transition: Transition & { to: { attributes: any } }) => {
      const { to } = transition;
      if (to && to.name) {
        this._trackPage(to.name);
      }
    });
  }

  _trackPage(page?: string) {
    const _page = page ?? this.router.currentURL;
    const title = this.router.currentRouteName ?? 'unknown';
    this.bvMetrics.trackPage(_page, title);
  }
}

Router.map(function () {
  this.route('login');

  this.route('protected', { path: '/' }, function () {
    // Dashboard
    this.route('dashboard');

    // Plannings
    this.route('plannings', function () {
      this.route('sku', { path: '/sku/:sku_id' });
    });

    // PO Workbench

    this.route('po-workbench', function () {
      this.route('line', { path: '/:planningOrder_id' }, function () {
        this.route('index', { path: '/' });
        this.route('activities', { path: '/activities' });
        this.route('tasks', { path: '/tasks' });
      });
      this.route('sku', { path: '/sku/:sku_id' });
    });
    this.route('po-workbench-validation-rules');
    this.route('po-workbench-validation-rules-create', { path: '/po-workbench/rules/create' });
    this.route('po-workbench-validation-rule', {
      path: '/po-workbench/rules/:po_validation_rule_id'
    });

    // Alerts
    this.route('alerts', function () {
      this.route('sku', { path: '/sku/:sku_id' });
    });

    // SKUs
    this.route('skus', function () {
      this.route('sku', { path: '/:sku_id' });
    });
    this.route('sku', { path: '/sku/:sku_id' });
    this.route('sku-create', { path: '/skus/create' });
    this.route('sku-duplicate', { path: '/skus/:sku_id/duplicate' });

    // BOMs
    this.route('boms', function () {
      this.route('sku', { path: '/sku/:sku_id' });
    });
    this.route('bom', { path: '/boms/:bom_id' }, function () {
      this.route('sku', { path: '/sku/:sku_id' });
    });

    // Open orders
    this.route('open-orders', function () {
      this.route('sku', { path: '/sku/:sku_id' });
    });

    // Buffer Profiles
    this.route('buffer-profiles');
    this.route('buffer-profile', { path: '/buffer-profiles/:buffer_profile_id' });
    this.route('buffer-profile-create', { path: 'buffer-profiles/create' });

    // Daf Profiles
    this.route('daf-profiles');
    this.route('daf-profile', { path: '/daf-profiles/:daf_profile_id' });
    this.route('daf-profile-create', { path: '/daf-profiles/create' });

    // ADU Profiles
    this.route('adu-profiles');
    this.route('adu-profile', { path: '/adu-profiles/:adu_profile_id' });
    this.route('adu-profile-create', { path: '/adu-profiles/create' });

    // Custom Field Definitions
    this.route('custom-field-definitions');
    this.route('custom-field-definitions-create', {
      path: '/custom-field-definitions/create'
    });
    this.route('custom-field-definition', {
      path: '/custom-field-definitions/:custom_field_definition_id'
    });

    // Simulations
    this.route('simulations');
    this.route('simulation-create', { path: '/simulations/create' });
    this.route('simulation-duplicate', { path: '/simulations/:simulation_id/duplicate' });
    this.route('simulation', { path: '/simulation/:simulation_id' }, function () {
      this.route('simulation-scenario', { path: '/scenario/:scenario_id' });
    });

    // Reports
    this.route('reports');
    this.route('report', { path: '/reports/:report_id' });

    // Master Data

    // Suppliers
    this.route('suppliers');
    this.route('supplier-create', { path: '/suppliers/create' });
    this.route('supplier', { path: '/suppliers/:supplier_id' });

    // Warehouses
    this.route('warehouses', { path: '/warehouses' });
    this.route('warehouse-create', { path: '/warehouses/create' });
    this.route('warehouse', { path: '/warehouses/:warehouse_id' });

    // Workshops
    this.route('workshops', { path: '/workshops' });
    this.route('workshop-create', { path: '/workshops/create' });
    this.route('workshop', { path: '/workshops/:workshop_id' });

    // Settings
    this.route('settings', function () {
      this.route('index', { path: '/' });
      this.route('general');
      this.route('rules');
      this.route('admin');
      this.route('import');
      this.route('features');
    });
    this.route('import');

    // User Account
    this.route('account', function () {
      this.route('index', { path: '/' });
      this.route('activities');
      this.route('profile');
      this.route('preferences');
    });

    this.route('my-tasks', { path: '/my/tasks' });

    // User Roles
    this.route('user-roles');
    this.route('user-role', { path: '/user-roles/:user_role_id' });
    this.route('user-role-create', { path: '/user-roles/create' });

    // Users
    this.route('users');
    this.route('user', { path: '/users/:user_id' });
    this.route('user-create', { path: '/users/create' });

    // Schedules
    this.route('schedules');
    this.route('schedule', { path: '/schedules/:schedule_id' });
    this.route('schedule-create', { path: '/schedules/create' });
    this.route('schedule-duplicate', { path: '/schedules/duplicate/:schedule_id' });
  });

  this.route('unexpected-error', { path: '500' });

  // Needs to be the last route to catch all paths
  // that don't match previous route
  this.route('not-found', { path: '/*path' });
});
