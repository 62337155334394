import Ember from 'ember';
import Model, { ModelRegistry } from 'ember-data/model';
import JSONSerializer from 'ember-data/serializers/json';
import SerializerRegistry from 'ember-data/types/registries/serializer';
import { _getModelName, _getStore } from 'volta/utils/api/build-url';
import {
  CollectionResourceDoc,
  Document as JSONApiDoc,
  DocWithData,
  SingleResourceDoc
} from 'volta/utils/api/jsonapi-types';

import Store from '@ember-data/store';
import { isArray } from '@ember/array';
import { typeOf } from '@ember/utils';

export function isJsonApi(raw: any): raw is JSONApiDoc {
  return raw.jsonapi && raw.jsonapi.version;
}

export function isDocWithData(doc: any): doc is DocWithData {
  return isJsonApi(doc) && ['object', 'array'].indexOf(typeOf((doc as DocWithData).data)) >= 0;
}

export function normalize<M extends Model>(
  modelName: keyof ModelRegistry,
  response: any
): M | Ember.Array<M> {
  if (!isDocWithData(response)) {
    // tslint:disable-next-line:no-console
    console.warn(
      '"normalize" may only be used with a JSON API document. Ignoring response. ' +
        'Document must have a mandatory JSON API object. See https://jsonapi.org/format/#document-jsonapi-object.'
    );
    return response;
  }
  const store = _getStore() as Store;
  const modelClass = store.modelFor(modelName);
  const serializer: JSONSerializer = store.serializerFor(modelName as keyof SerializerRegistry);

  if (isArray(response.data)) {
    const doc = response as CollectionResourceDoc;
    const normalized: Ember.Array<M> = serializer.normalizeArrayResponse(
      store,
      modelClass as any,
      doc,
      null as any,
      'query'
    ) as Ember.Array<M>;
    return store.push(normalized) as Ember.Array<M>;
  } else {
    const doc = response as SingleResourceDoc;
    const normalized: M = serializer.normalizeSingleResponse(
      store,
      modelClass as any,
      doc,
      `${doc.data.id || '(unknown)'}`,
      'findRecord'
    ) as M;
    return store.push(normalized) as M;
  }
}

export function _normalize<M extends typeof Model>(this: M, response: any) {
  const modelName = _getModelName(this);
  return normalize(modelName, response);
}
