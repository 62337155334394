import moment from 'moment-timezone';
import { BufferedChangeset } from 'validated-changeset';
import { IDAFMonthly, IDAFWeekly } from 'volta/models/types/planning-settings';
import {
  IWorkshop,
  IWorkshopCapacitySettings,
  TCapacityFormat,
  TCapacityUnitFrequency
} from 'volta/models/types/workshop';
import Workshop from 'volta/models/workshop';

import { distinct } from './array-utils';

import type { IPOWorkshopCapacity } from 'volta/models/types/planning-order';

interface TChangesetValues {
  capacityUnitFrequency: TCapacityUnitFrequency;
  capacity: number;
}

export type TPOWorkshopCapacity = IPOWorkshopCapacity & {
  _startPeriod?: string;
  _endPeriod?: string;
  period?: string;
};

interface TEarliestMonthDate {
  earliest: string;
}

interface TEarliestWeekDate {
  [month: number]: TPOWorkshopCapacity[];
}

interface ICapacityExceptions {
  [year: number]: { weekly: IDAFWeekly; monthly: IDAFMonthly };
}

const DATE_FORMAT = 'YYYY-MM-DD';
export function workshopProjectionsByExceptions(
  poProjections: TPOWorkshopCapacity[] = [],
  changeset: BufferedChangeset & IWorkshopCapacitySettings,
  capacityExceptions: ICapacityExceptions,
  selectedYear: number,
  capacityFormat?: TCapacityFormat,
  workshop?: Workshop
): TPOWorkshopCapacity[] {
  if (!workshop) {
    return [];
  }

  const currentYear = new Date().getFullYear();
  const capacityUnitFrequency = changeset.get('capacitySettings.capacityUnitFrequency');
  // Set the capacity
  const capa = capacityByFormat(changeset, capacityFormat);
  const capacityUnitFrequencyIsUnchanged =
    workshop && capacityUnitFrequency === workshop.capacitySettings.capacityUnitFrequency;

  const workshopProjections = poProjections
    .filter((w) => {
      return w.workshopId === workshop.id;
    })
    .sortBy('startPeriod');

  const indexedCapacities = workshopProjections.reduce(
    (acc: { [key: string]: TPOWorkshopCapacity }, capa: TPOWorkshopCapacity) => {
      const mStartPeriod = moment(capa.startPeriod);
      if (
        capacityUnitFrequency === 'WEEKLY' &&
        mStartPeriod.month() === 0 &&
        !capacityUnitFrequencyIsUnchanged
      ) {
        mStartPeriod.add(1, 'w');
      }

      const key = mStartPeriod.format(capacityUnitFrequency === 'WEEKLY' ? 'GGGG-W' : 'YYYY-M');
      acc[key] = capa;

      return acc;
    },
    {}
  );

  const capacitiesPeriods = Object.keys(indexedCapacities).map((i) => i.split('-')[0]);
  capacitiesPeriods.push(`${currentYear - 1}`, `${currentYear}`, `${selectedYear}`);
  const yearsToShow = distinct(capacitiesPeriods).sort();

  const projections = yearsToShow.flatMap((yearToShow) => {
    const exceptions = capacityExceptions[yearToShow] ?? { weekly: {}, monthly: {} };

    if (capacityUnitFrequency === 'WEEKLY') {
      const weeklyYearProjs: TPOWorkshopCapacity[] = projectionsByWeekYear(
        indexedCapacities,
        yearToShow,
        capa,
        exceptions,
        capacityUnitFrequency
      );

      if (capacityUnitFrequencyIsUnchanged) {
        return weeklyYearProjs;
      }

      const workshopsByWeeks = weeklyYearProjs.reduce(getCapasByWeek, {});

      return Object.values(workshopsByWeeks)
        .flat()
        .map((w) => updateCapacity(w, capa, exceptions.weekly));
    } else if (capacityUnitFrequency === 'MONTHLY') {
      const monthlyYearProjs: TPOWorkshopCapacity[] = projectionsByMonthYear(
        indexedCapacities,
        yearToShow,
        capa,
        exceptions,
        capacityUnitFrequency
      );

      if (capacityUnitFrequencyIsUnchanged) {
        return monthlyYearProjs;
      }
      const earliestDates = monthlyYearProjs.reduce(getEarliestMonthDates, []);

      return earliestDates.reduce(
        (acc: Array<TPOWorkshopCapacity & IWorkshop>, month: TEarliestMonthDate) => {
          const yearCapa = monthlyYearProjs.find((w) => w.startPeriod === month.earliest);

          if (yearCapa) {
            yearCapa._startPeriod = moment(yearCapa.startPeriod)
              .startOf('month')
              .format(DATE_FORMAT);
            yearCapa._endPeriod = moment(yearCapa.startPeriod).endOf('month').format(DATE_FORMAT);
            const updatedCapa = updateCapacity(yearCapa, capa, exceptions.monthly, false);
            acc.push(updatedCapa);
          }
          return acc;
        },
        [] as Array<TPOWorkshopCapacity & IWorkshop>
      );
    }
    return [];
  });

  return projections;
}

const capacityByFormat = (
  changeset: BufferedChangeset,
  capacityFormat: TCapacityFormat | undefined
): TChangesetValues => {
  // Set the capacity
  const capacityUnitFrequency = changeset.get('capacitySettings.capacityUnitFrequency');
  const cs = {
    capacityUnitFrequency,
    capacity: 0
  };
  if (capacityFormat === 'HOUR') {
    cs.capacity = changeset.get('capacitySettings.capacityHours');
  } else {
    cs.capacity = changeset.get('capacitySettings.capacity');
  }

  return cs;
};

const updateCapacity = (
  capa: TPOWorkshopCapacity,
  cs: TChangesetValues,
  capacityExceptions: IDAFMonthly | IDAFWeekly,
  keepInitialPeriods: boolean = true
): TPOWorkshopCapacity & IWorkshop => {
  const startPeriod = capa.startPeriod;
  const capacityUnitFrequency = cs.capacityUnitFrequency;
  const workshop = { ...capa, ...cs };

  if (!keepInitialPeriods && workshop._startPeriod && workshop._endPeriod) {
    workshop.startPeriod = workshop._startPeriod;
    workshop.endPeriod = workshop._endPeriod;
  }

  if (capacityUnitFrequency === 'WEEKLY') {
    const weekException = getCapaException(startPeriod, capacityExceptions, 'isoWeek');

    workshop.period = `${moment(workshop.startPeriod).format('Wo')}`;

    if (typeof weekException === 'number') {
      workshop.capacity = weekException;
      return workshop as TPOWorkshopCapacity & IWorkshop;
    }
  } else if (capacityUnitFrequency === 'MONTHLY') {
    const monthException = getCapaException(startPeriod, capacityExceptions, 'month');

    workshop.period = `${moment(workshop.startPeriod).format('MMMM')}`;

    if (typeof monthException === 'number') {
      workshop.capacity = monthException;
      return workshop as TPOWorkshopCapacity & IWorkshop;
    }
  }

  return workshop as TPOWorkshopCapacity & IWorkshop;
};

const projectionsByWeekYear = (
  indexedCapacities: {
    [key: string]: TPOWorkshopCapacity;
  },
  year: number,
  capa: TChangesetValues,
  exceptions: {
    weekly: IDAFWeekly;
    monthly: IDAFMonthly;
  },
  capacityUnitFrequency: TCapacityUnitFrequency
) => {
  const lastWeek = moment().utc().year(year).isoWeeksInYear();

  const yearProjections = [];

  for (let week = 1; week <= lastWeek; week++) {
    const projection = indexedCapacities[`${year}-${week}`];

    const startPeriod = moment()
      .isoWeekYear(year)
      .isoWeek(week)
      .startOf('isoWeek')
      .format(DATE_FORMAT);
    const endPeriod = moment(startPeriod).endOf('isoWeek').format(DATE_FORMAT);

    let updatedProjection: IPOWorkshopCapacity | undefined = undefined;

    if (projection) {
      updatedProjection = updateCapacity(projection, capa, exceptions.weekly);
    } else {
      updatedProjection = generateEmptyProjection(
        {
          ...capa,
          capacity: getCapaException(startPeriod, exceptions.weekly, 'isoWeek') ?? capa.capacity,
          startPeriod,
          endPeriod
        },
        capacityUnitFrequency
      ) as IPOWorkshopCapacity;
    }

    yearProjections.push(updatedProjection);
  }

  return yearProjections;
};

const projectionsByMonthYear = (
  indexedCapacities: {
    [key: string]: TPOWorkshopCapacity;
  },
  year: number,
  capa: TChangesetValues,
  exceptions: {
    weekly: IDAFWeekly;
    monthly: IDAFMonthly;
  },
  capacityUnitFrequency: TCapacityUnitFrequency
) => {
  const yearProjections = [];

  for (let month = 0; month < 12; month++) {
    const projection = indexedCapacities[`${year}-${month + 1}`];

    let updatedProjection: IPOWorkshopCapacity | undefined = undefined;
    if (projection) {
      updatedProjection = updateCapacity(projection, capa, exceptions.monthly);
    } else {
      const startPeriod = moment().year(year).month(month).startOf('month').format(DATE_FORMAT);

      updatedProjection = generateEmptyProjection(
        {
          ...capa,
          capacity: getCapaException(startPeriod, exceptions.monthly, 'month') ?? capa.capacity,
          startPeriod,
          endPeriod: moment(startPeriod).endOf('month').format(DATE_FORMAT)
        },
        capacityUnitFrequency
      ) as IPOWorkshopCapacity;
    }

    if (updatedProjection) {
      yearProjections.push(updatedProjection);
    }
  }

  return yearProjections;
};

const getCapaException = (
  capaStartPeriod: string,
  exceptions: IDAFWeekly | IDAFMonthly,
  frequency: 'month' | 'isoWeek'
) => {
  const start = moment(capaStartPeriod)[frequency]();

  if (frequency === 'month') {
    // month starts at 0 for moment
    return exceptions[`${start + 1}` as keyof (IDAFMonthly | IDAFWeekly)];
  }

  return exceptions[`${start}` as keyof (IDAFMonthly | IDAFWeekly)];
};

const getEarliestMonthDates = (acc: TEarliestMonthDate[], w: TPOWorkshopCapacity) => {
  const startPeriod = w.startPeriod;
  const month = moment(startPeriod).month();
  if (!acc[month]) {
    acc[month] = { earliest: startPeriod };
    return acc;
  }

  if (acc[month].earliest && moment(startPeriod).isBefore(acc[month].earliest)) {
    acc[month].earliest = startPeriod;
    return acc;
  }

  return acc;
};

const getCapasByWeek = (acc: TEarliestWeekDate, w: TPOWorkshopCapacity) => {
  const startPeriod = w.startPeriod;
  const momentStartPeriod = moment(startPeriod, DATE_FORMAT);
  const month = momentStartPeriod.month();
  let firstMonday = momentStartPeriod;

  if (w.workshopId) {
    firstMonday.add(1, 'month').startOf('month');
  }

  firstMonday = firstMonday.startOf('isoWeek');

  const weeks = [];

  while (firstMonday.month() === month) {
    const firstWeek = firstMonday.format(DATE_FORMAT);
    weeks.push({
      ...w,
      startPeriod: firstWeek,
      endPeriod: moment(firstWeek).add(1, 'w').format(DATE_FORMAT)
    });

    firstMonday.add(1, 'w');
  }
  if (!acc[month]) {
    acc[month] = weeks;
  }

  return acc;
};

const generateEmptyProjection = (
  workshopCapa: Partial<TPOWorkshopCapacity>,
  capacityUnitFrequency: TCapacityUnitFrequency
) => {
  return {
    ...workshopCapa,
    period:
      capacityUnitFrequency === 'WEEKLY'
        ? `${moment(workshopCapa.startPeriod).format('Wo')}`
        : `${moment(workshopCapa.startPeriod).format('MMMM')}`,
    buffer: {
      avgOnHand: 0,
      topOfYellow: 0,
      green: 0,
      avgOnHandRange: 0,
      topOfGreen: 0,
      topOfRed: 0,
      redBase: 0,
      redSafety: 0,
      yellow: 0,
      greenWoMOQ: 0
    },
    projectedBuffer: {
      avgOnHand: 0,
      topOfYellow: 0,
      green: 0,
      avgOnHandRange: 0,
      topOfGreen: 0,
      topOfRed: 0,
      redBase: 0,
      redSafety: 0,
      yellow: 0,
      greenWoMOQ: 0
    }
  };
};
