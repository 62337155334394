import type RouteInfo from '@ember/routing/route-info';
import type RouterService from '@ember/routing/router-service';

import { splitToLines, tryParseRouteInfo, tryParseURL } from 'volta/utils/text-utils';

import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

interface IArgs {
  text?: string;
  blockClassName?: string;
}

type TTextPartType = 'text' | 'internal-link' | 'external-link';
type TPart = { type: TTextPartType; value?: string; routeInfo?: RouteInfo; url?: URL };
type TParts = TPart[][];
export default class BvRichText extends Component<IArgs> {
  @service router!: RouterService;

  @cached
  get textParts(): TParts {
    return this.args.text
      ? splitToLines(this.args.text).map((p) => {
          const parts: TPart[] = [];
          let text: string[] = [];
          const words = p.trim().split(' ');
          words.forEach((w, i) => {
            const word = w.trim();

            // We first check if the word is a URL,
            // and if it is, we check if it's an internal link

            const url = tryParseURL(word);
            const routeInfo =
              url?.origin === document.location.origin
                ? tryParseRouteInfo(url, this.router)
                : undefined;
            const type = url ? (routeInfo ? 'internal-link' : 'external-link') : 'text';

            // We accumulate text parts until we find a link
            // or we reach the end of the line

            if (type === 'text') {
              text.push(word);
            } else {
              if (text.length) {
                parts.push({
                  type: 'text',
                  value: text.join(' ')
                });
                text = [];
              }

              parts.push({
                type,
                routeInfo,
                value: word,
                url
              });
            }

            if (i === words.length - 1 && text.length) {
              parts.push({
                type: 'text',
                value: text.join(' ')
              });
            }
          });
          return parts;
        })
      : [];
  }
}
