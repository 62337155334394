import { ref } from 'ember-ref-bucket';

import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const DefaultAriaRoleType = 'status';

const TypeMapping = {
  success: {
    color: 'green-5',
    defaultIcon: 'checkmark-circle'
  },
  info: {
    color: 'teal-5',
    defaultIcon: 'information-circle'
  },
  warning: {
    color: 'yellow-5',
    defaultIcon: 'alert-circle'
  },
  error: {
    color: 'red-5',
    defaultIcon: 'close-circle'
  },
  default: {
    color: 'gray-5',
    defaultIcon: 'information-circle'
  }
};

const Statuses = Object.keys(TypeMapping);

interface IArgs {
  status: 'success' | 'info' | 'warning' | 'error' | 'default';
}

export default class BvBanner extends Component<IArgs> {
  @tracked showFocus = false;

  @ref('wrapper') wrapper: Node | null = null;
  id = `${guidFor(this)}BvBanner`;

  get headingID() {
    return `${this.id}Heading`;
  }

  get contentID() {
    return `${this.id}Content`;
  }

  get statusMapping() {
    const { status } = this.args;
    return TypeMapping[Statuses.includes(status) ? status : 'default'];
  }

  get ariaRoleType() {
    return Statuses.includes(this.args.status) ? this.args.status : DefaultAriaRoleType;
  }

  get iconName() {
    return this.statusMapping.defaultIcon;
  }

  get color() {
    return this.statusMapping.color;
  }

  get statusClass() {
    const { status } = this.args;
    return Statuses.includes(status) ? `bv-banner--${status}` : 'bv-banner--default';
  }

  @action
  handleKeyUp(evt: KeyboardEvent) {
    if (evt.target === (this.wrapper as Node & { current: HTMLElement }).current) {
      this.showFocus = true;
    }
  }

  @action
  handleBlur() {
    this.showFocus = false;
  }

  @action
  handleMouseUp(event?: MouseEvent) {
    if(!event) {
      return;
    }
    const { currentTarget } = event;
    const { showFocus } = this;
    const element = currentTarget as (EventTarget & { blur: () => void }) | null;
    if (element) {
      element.blur();
    }
    if (showFocus) {
      this.showFocus = false;
    }
  }
}
