import { helper } from '@ember/component/helper';

export function getStrict([obj, index]: [
  Record<string, unknown | undefined> | Array<unknown>,
  string | number
]) {
  if (obj === undefined) {
    return;
  }

  if ((Array.isArray(obj) || typeof obj === 'string') && typeof index === 'number') {
    return obj[index];
  }

  if (typeof obj === 'object' && typeof index === 'string') {
    return (obj as any)[index];
  }

  if (typeof obj === 'number') {
    return obj;
  }
}

export default helper(getStrict);
