import Ember from 'ember';
import { restartableTask } from 'ember-concurrency';
import InfinityService from 'ember-infinity/services/infinity';
import ProtectedController from 'volta/controllers/protected-controller';
import { IResourceName } from 'volta/models/base-model';
import JsonApiInfinityModel from 'volta/models/jsonapi-infinity-model';
import StoreService from 'volta/services/store';
import { defaultErrorHandler } from 'volta/utils/error-utils';

import { inject as service } from '@ember/service';

export default class ResourceController extends ProtectedController {
  // Services
  // ~~~~~

  @service declare store: StoreService;
  @service declare infinity: InfinityService;

  // Properties
  // ~~~~~

  declare resourceName: IResourceName;
  infiniteScroll: boolean = false;

  // Helper functions
  // ~~~~~

  // Here we use a task on the controller so the result is persisted between pages transitions
  resourceCollectionTask = restartableTask(async (apiQuery = {}) => {
    const query = this.formatApiQuery(apiQuery);
    try {
      const response: Ember.Array<any> = this.infiniteScroll
        ? await this.infinity.model(this.resourceName.singular, query, JsonApiInfinityModel)
        : await this.store.query(this.resourceName.singular, query);
      return response;
    } catch (error) {
      return defaultErrorHandler(error);
    }
  });

  formatApiQuery(apiQuery: any) {
    return apiQuery;
  }
}
