import { attr } from '@ember-data/model';
import BaseModel from 'volta/models/base-model';

export const TagResourceName = {
  singular: 'tag',
  plural: 'tags'
};

/**
 * @class Tag Tag model
 * @extends BaseModel
 */
export default class Tag extends BaseModel {
  /**
   * The tag value
   */
  @attr('string') tag?: string;

  /**
   * The number of times the tag appears on the different entities
   */
  @attr('number') count?: number;

  /**
   * The warehouse ids the tag appears. An empty array is equivalent to all warehouses.
   */
  @attr('array', { defaultValue: () => [] })
  declare warehouseIds: string[];

  /**
   * The entity types where the tag can be found
   */
  @attr('array', { defaultValue: () => [] })
  declare entityTypes: string[];

  @attr('boolean', { defaultValue: false })
  declare removable: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tag: Tag;
  }
}
