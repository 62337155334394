import moment from 'moment-timezone';
import BaseModel, { IResourceName } from 'volta/models/base-model';
import { IResetDaysCmd, IUpdateCalendarCmd } from 'volta/models/types/warehouse';
import {
  _normalize,
  collectionCommand,
  resourceCommand as command
} from 'volta/utils/api';

import { attr } from '@ember-data/model';

export const WarehouseCalendarDayResourceName: IResourceName = {
  singular: 'warehouseCalendarDay',
  plural: 'warehouseCalendarDays'
};

export enum WarehouseCalendarDayEvents {
  CalendarUpdated = 'CalendarUpdated',
  DaysReset = 'DaysReset',
  WarehouseCalendarDayReset = 'WarehouseCalendarDayReset'
}

export enum WarehouseCalendarDayCommands {
  UpdateCalendar = 'UpdateCalendar',
  ResetDays = 'ResetDays',
  ResetOne = 'ResetOne'
}

export default class WarehouseCalendarDay extends BaseModel {
  /**
   * Create or update the warehouse calendar days
   * @return The list of created or updated WarehouseCalendarDay models
   */
  static updateCalendar = collectionCommand<IUpdateCalendarCmd>(WarehouseCalendarDayCommands.UpdateCalendar, {
    after: _normalize
  });

  /**
   * Effectively deletes the models identified by the warehouse id and dates
   * @return Done
   */
  static resetDays = collectionCommand<IResetDaysCmd>(WarehouseCalendarDayCommands.ResetDays);

  /**
   * Effectively deletes the model identified by the entityId
   * @return Done
   */
  resetOne = command(WarehouseCalendarDayCommands.ResetOne);

  @attr('string') warehouseId!: string;
  @attr('date') date!: Date;
  @attr('boolean') openForDemand!: boolean;
  @attr('boolean') openForReception!: boolean;
  @attr('boolean') openForProduction!: boolean;

  get formattedDate() {
    return moment(this.date).format('YYYY-MM-DD');
  }

  get indexedDay() {
    const { openForProduction, openForDemand, openForReception } = this;
    return {
      [moment(this.date).format('YYYY-MM-DD')]: {
        openForProduction,
        openForDemand,
        openForReception
      }
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'warehouse-calendar-day': WarehouseCalendarDay;
  }
}
