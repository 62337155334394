import { attr } from '@ember-data/model';
import BaseModel from 'volta/models/base-model';
import { IResourceName } from 'volta/models/base-model';

export const PartTypeResourceName: IResourceName = {
  singular: 'partType',
  plural: 'partTypes'
};

export default class PartType extends BaseModel {
  @attr('string') declare code: string;
  @attr('string') description?: string;
  @attr('string') externalId?: string;

  get partTypeId() {
    return this.id;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'part-type': PartType;
  }
}
