import config from 'volta/config/environment';
import { IJSONAPIErrors, TError } from 'volta/utils/error-utils';

import Service, { inject as service } from '@ember/service';
import * as Sentry from '@sentry/browser';

import AppSettings from './app-settings';
import SessionUserService from './session-user';

export default class SentryService extends Service {
  @service appSettings!: AppSettings;
  @service sessionUser!: SessionUserService;

  logError(error: TError | string) {
    Sentry.captureException(error, this.buildSentryCaptureContext(error));
  }

  buildSentryCaptureContext(error: TError | string) {
    const scope = new Sentry.Scope();

    const apiError = (error as IJSONAPIErrors)?.errors;
    if (apiError?.length) {
      scope.setContext('ApiError', {
        reason: apiError[0].title,
        ...apiError[0],
        title: 'API ERROR'
      });
    }

    if (this.appSettings.tenant) {
      scope.setTag('tenantId', this.appSettings.tenant!.tenantId);
      scope.setTag('tenantEnv', this.appSettings.tenant!.env);
      scope.setTag('version', config.APP.version);
    }

    if (this.sessionUser.user) {
      const { id, login, email } = this.sessionUser.user!;
      scope.setTag('login', login);
      scope.setUser({
        id,
        username: login,
        email
      });
    }

    return scope;
  }
}

declare module '@ember/service' {
  interface Registry {
    'sentry-service': SentryService;
  }
}
