import application from 'volta/utils/application';

import {
  IFormulaArgumentSelectorName as ISelector,
  TFormulaArgumentType,
  TFormulaResultType
} from '../types/customFieldDefinition';
import type IntlService from "ember-intl/services/intl";

const intl = application?.instance?.lookup('service:intl') as IntlService;
export interface IRecipe {
  name: ISelector;
  type: TFormulaArgumentType;
  resultType?: TFormulaResultType;
}

export type IBook = Record<ISelector, IRecipe>;

export type ISubstitute =
  | 'comparisonItem'
  | 'trueResult'
  | 'falseResult'
  | 'leftEqual'
  | 'rightEqual'
  | 'leftTextItem'
  | 'rightTextItem'
  | 'textItem'
  | 'leftProperty'
  | 'rightProperty';

// PROPERTIES

const comparisonBook: Partial<IBook> = {
  equal: {
    name: 'equal',
    type: 'OPERATOR'
  },
  notEqual: {
    name: 'notEqual',
    type: 'OPERATOR'
  }
};

const ifRecipe: Partial<IBook> = {
  if: {
    name: 'if',
    type: 'OPERATOR'
  }
};

const textRecipe: Partial<IBook> = {
  text: {
    name: 'text',
    type: 'PROPERTY',
    resultType: 'TEXT'
  }
};

const numberRecipe: Partial<IBook> = {
  number: {
    name: 'number',
    type: 'PROPERTY',
    resultType: 'NUMBER'
  }
};

const dateRecipe: Partial<IBook> = {
  date: {
    name: 'date',
    type: 'PROPERTY',
    resultType: 'DATE'
  }
};

export const propertiesBook: Partial<IBook> = {
  ...dateRecipe,
  ...numberRecipe,
  ...textRecipe
};

export const functionsBook: Partial<IBook> = {
  join: {
    name: 'join',
    type: 'FUNCTION',
    resultType: 'TEXT'
  },
  upper: {
    name: 'upper',
    type: 'FUNCTION',
    resultType: 'TEXT'
  },
  lower: {
    name: 'lower',
    type: 'FUNCTION',
    resultType: 'TEXT'
  },
  now: {
    name: 'now',
    type: 'FUNCTION',
    resultType: 'DATE'
  },
  multiply: {
    name: 'multiply',
    type: 'OPERATOR',
    resultType: 'NUMBER'
  },
  add: {
    name: 'add',
    type: 'OPERATOR',
    resultType: 'NUMBER'
  },
  subtract: {
    name: 'subtract',
    type: 'OPERATOR',
    resultType: 'NUMBER'
  }
};

// STARTERS
export const starterRecipeBook: Partial<IBook> = {
  ...functionsBook,
  ...comparisonBook,
  ...ifRecipe
};
export const allRecipesBook: Partial<IBook> = { ...propertiesBook, ...starterRecipeBook };

export const formulaStarters = [
  {
    title: intl.t('customFieldsDefinitionsPage.formula.operators'),
    items: [
      { value: 'if', title: intl.t('customFieldsDefinitionsPage.formula.arguments.if') },
      {
        value: 'equal',
        title: intl.t('customFieldsDefinitionsPage.formula.arguments.equal')
      },
      {
        value: 'notEqual',
        title: intl.t('customFieldsDefinitionsPage.formula.arguments.notEqual')
      }
    ]
  },
  {
    title: intl.t('customFieldsDefinitionsPage.formula.textFunctions'),
    items: [
      { value: 'join', title: intl.t('customFieldsDefinitionsPage.formula.arguments.join') },
      {
        value: 'lower',
        title: intl.t('customFieldsDefinitionsPage.formula.arguments.lower')
      },
      {
        value: 'upper',
        title: intl.t('customFieldsDefinitionsPage.formula.arguments.upper')
      }
    ]
  },
  {
    title: intl.t('customFieldsDefinitionsPage.formula.mathFunctions'),
    items: [
      { value: 'add', title: intl.t('customFieldsDefinitionsPage.formula.arguments.add') },
      {
        value: 'multiply',
        title: intl.t('customFieldsDefinitionsPage.formula.arguments.multiply')
      },
      {
        value: 'subtract',
        title: intl.t('customFieldsDefinitionsPage.formula.arguments.subtract')
      }
    ]
  },
  {
    title: intl.t('customFieldsDefinitionsPage.formula.dateUtilities'),
    items: [
      { value: 'now', title: intl.t('customFieldsDefinitionsPage.formula.arguments.now') }
    ]
  }
];

// FORMATTED ARGUMENTS

const formatBooks = (book: Partial<IBook>) => {
  return Object.values(book).map((recipe) => ({
    title: recipe
      ? intl.t(`customFieldsDefinitionsPage.formula.arguments.${recipe.name}`)
      : '',
    value: recipe
  }));
};

export const formattedComparisonRecipes = formatBooks(comparisonBook);

export const formattedFunctionsRecipes = formatBooks(functionsBook);

const formattedText = formatBooks(textRecipe);
const formattedNumber = formatBooks(numberRecipe);
const formattedDate = formatBooks(dateRecipe);

export const formattedPropertiesRecipes = formatBooks(propertiesBook);

const formattedIf = formatBooks(ifRecipe);

const formattedStarters = [
  {
    title: intl.t(`customFieldsDefinitionsPage.formula.functions`),
    items: [...formattedFunctionsRecipes]
  },
  {
    title: intl.t(`customFieldsDefinitionsPage.formula.operators`),
    items: [...formattedComparisonRecipes, ...formattedIf]
  }
];

export const argumentsRecipes = {
  if: formattedStarters,
  equal: formattedStarters,
  notEqual: formattedStarters,
  join: formattedStarters,
  upper: formattedStarters,
  lower: formattedStarters,
  now: formattedStarters,
  multiply: formattedStarters,
  add: formattedStarters,
  subtract: formattedStarters,
  comparison: [{ items: [...formattedComparisonRecipes] }],
  universal: [
    {
      title: intl.t(`customFieldsDefinitionsPage.formula.properties`),
      items: [...formattedPropertiesRecipes]
    }
  ].concat([...formattedStarters]),
  text: [{ items: [...formattedText] }],
  number: [{ items: [...formattedNumber] }],
  date: [{ items: [...formattedDate] }]
};

export const substituteBook: { [key: string]: Array<Partial<ISubstitute>> } = {
  if: ['comparisonItem', 'trueResult', 'falseResult'],
  equal: ['leftEqual', 'rightEqual'],
  notEqual: ['leftEqual', 'rightEqual'],
  join: ['leftTextItem', 'rightTextItem'],
  upper: ['textItem'],
  lower: ['textItem'],
  now: [],
  multiply: ['leftProperty', 'rightProperty'],
  add: ['leftProperty', 'rightProperty'],
  subtract: ['leftProperty', 'rightProperty']
};
