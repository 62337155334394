
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


d("volta/_pods/application/adapter", function(){ return i("../_pods/application/adapter.ts");});
d("volta/_pods/application/loading", function(){ return i("../_pods/application/loading.hbs");});
d("volta/_pods/application/serializer", function(){ return i("../_pods/application/serializer.ts");});
d("volta/adapters/-json-api", function(){ return i("../adapters/-json-api.js");});
d("volta/adapters/po-task", function(){ return i("../adapters/po-task.ts");});
d("volta/adapters/sku-task", function(){ return i("../adapters/sku-task.ts");});
d("volta/app", function(){ return i("../app.ts");});
d("volta/authenticators/session", function(){ return i("../authenticators/session.js");});
d("volta/breakpoints", function(){ return i("../breakpoints.js");});
d("volta/component-managers/glimmer", function(){ return i("../component-managers/glimmer.js");});
d("volta/config/environment", function(){ return i("../config/environment.js");});
d("volta/data-adapter", function(){ return i("../data-adapter.js");});
d("volta/formats", function(){ return i("../formats.js");});
d("volta/initializers/app-version", function(){ return i("../initializers/app-version.js");});
d("volta/initializers/container-debug-adapter", function(){ return i("../initializers/container-debug-adapter.js");});
d("volta/initializers/ember-data-data-adapter", function(){ return i("../initializers/ember-data-data-adapter.js");});
d("volta/initializers/ember-data", function(){ return i("../initializers/ember-data.js");});
d("volta/initializers/ember-responsive-breakpoints", function(){ return i("../initializers/ember-responsive-breakpoints.js");});
d("volta/initializers/ember-simple-auth", function(){ return i("../initializers/ember-simple-auth.js");});
d("volta/initializers/install-function-modifier-manager", function(){ return i("../initializers/install-function-modifier-manager.js");});
d("volta/initializers/simple-auth-token", function(){ return i("../initializers/simple-auth-token.js");});
d("volta/initializers/viewport-config", function(){ return i("../initializers/viewport-config.js");});
d("volta/instance-initializers/application", function(){ return i("../instance-initializers/application.ts");});
d("volta/instance-initializers/ember-data", function(){ return i("../instance-initializers/ember-data.js");});
d("volta/instance-initializers/ember-simple-auth", function(){ return i("../instance-initializers/ember-simple-auth.js");});
d("volta/instance-initializers/sentry-performance", function(){ return i("../instance-initializers/sentry-performance.js");});
d("volta/locales", function(){ return i("../locales.ts");});
d("volta/macros/if-undefined", function(){ return i("../macros/if-undefined.js");});
d("volta/macros/noop-if-undefined", function(){ return i("../macros/noop-if-undefined.js");});
d("volta/metrics-adapters/base", function(){ return i("../metrics-adapters/base.js");});
d("volta/metrics-adapters/google-analytics", function(){ return i("../metrics-adapters/google-analytics.js");});
d("volta/models/activity", function(){ return i("../models/activity.ts");});
d("volta/models/adu-profile", function(){ return i("../models/adu-profile.ts");});
d("volta/models/app-feature", function(){ return i("../models/app-feature.ts");});
d("volta/models/base-model", function(){ return i("../models/base-model.ts");});
d("volta/models/bom-node", function(){ return i("../models/bom-node.ts");});
d("volta/models/bom", function(){ return i("../models/bom.ts");});
d("volta/models/buffer-profile", function(){ return i("../models/buffer-profile.ts");});
d("volta/models/buffer", function(){ return i("../models/buffer.ts");});
d("volta/models/bv-flash", function(){ return i("../models/bv-flash.ts");});
d("volta/models/collection-view", function(){ return i("../models/collection-view.ts");});
d("volta/models/config", function(){ return i("../models/config.ts");});
d("volta/models/constants/acquisition-codes", function(){ return i("../models/constants/acquisition-codes.ts");});
d("volta/models/constants/adu", function(){ return i("../models/constants/adu.ts");});
d("volta/models/constants/app-features-columns", function(){ return i("../models/constants/app-features-columns.ts");});
d("volta/models/constants/buffer-zones", function(){ return i("../models/constants/buffer-zones.ts");});
d("volta/models/constants/custom-fields", function(){ return i("../models/constants/custom-fields.ts");});
d("volta/models/constants/filters/boms", function(){ return i("../models/constants/filters/boms.ts");});
d("volta/models/constants/formula-recipes", function(){ return i("../models/constants/formula-recipes.ts");});
d("volta/models/constants/import", function(){ return i("../models/constants/import.ts");});
d("volta/models/constants/lead-time-categories", function(){ return i("../models/constants/lead-time-categories.ts");});
d("volta/models/constants/order-statuses", function(){ return i("../models/constants/order-statuses.ts");});
d("volta/models/constants/planning-methodologies", function(){ return i("../models/constants/planning-methodologies.ts");});
d("volta/models/constants/reorder-strategies", function(){ return i("../models/constants/reorder-strategies.ts");});
d("volta/models/constants/schedule", function(){ return i("../models/constants/schedule.ts");});
d("volta/models/constants/time-buffer-alerts", function(){ return i("../models/constants/time-buffer-alerts.ts");});
d("volta/models/constants/user-types", function(){ return i("../models/constants/user-types.ts");});
d("volta/models/constants/var-categories", function(){ return i("../models/constants/var-categories.ts");});
d("volta/models/constants/widgets", function(){ return i("../models/constants/widgets.ts");});
d("volta/models/custom-field-definition", function(){ return i("../models/custom-field-definition.ts");});
d("volta/models/daf-monthly", function(){ return i("../models/daf-monthly.js");});
d("volta/models/daf-profile", function(){ return i("../models/daf-profile.ts");});
d("volta/models/daf-weekly", function(){ return i("../models/daf-weekly.js");});
d("volta/models/dashboard-summary", function(){ return i("../models/dashboard-summary.ts");});
d("volta/models/demand-forecast", function(){ return i("../models/demand-forecast.ts");});
d("volta/models/execution-alert", function(){ return i("../models/execution-alert.ts");});
d("volta/models/import-histo", function(){ return i("../models/import-histo.ts");});
d("volta/models/jsonapi-infinity-model", function(){ return i("../models/jsonapi-infinity-model.ts");});
d("volta/models/order-sync-alert", function(){ return i("../models/order-sync-alert.ts");});
d("volta/models/order", function(){ return i("../models/order.ts");});
d("volta/models/part-type", function(){ return i("../models/part-type.ts");});
d("volta/models/part", function(){ return i("../models/part.ts");});
d("volta/models/planning-order", function(){ return i("../models/planning-order.ts");});
d("volta/models/planning-reminder", function(){ return i("../models/planning-reminder.ts");});
d("volta/models/planning-setting", function(){ return i("../models/planning-setting.ts");});
d("volta/models/planning", function(){ return i("../models/planning.ts");});
d("volta/models/po-group", function(){ return i("../models/po-group.ts");});
d("volta/models/po-task", function(){ return i("../models/po-task.ts");});
d("volta/models/po-validation-rule", function(){ return i("../models/po-validation-rule.ts");});
d("volta/models/report", function(){ return i("../models/report.ts");});
d("volta/models/schedule", function(){ return i("../models/schedule.ts");});
d("volta/models/simulation-item-planning-report", function(){ return i("../models/simulation-item-planning-report.ts");});
d("volta/models/simulation-item-planning", function(){ return i("../models/simulation-item-planning.ts");});
d("volta/models/simulation-item-setting", function(){ return i("../models/simulation-item-setting.ts");});
d("volta/models/simulation-scenario", function(){ return i("../models/simulation-scenario.ts");});
d("volta/models/simulation", function(){ return i("../models/simulation.ts");});
d("volta/models/sku-location", function(){ return i("../models/sku-location.ts");});
d("volta/models/sku-task", function(){ return i("../models/sku-task.ts");});
d("volta/models/sku", function(){ return i("../models/sku.ts");});
d("volta/models/supplier-calendar-day", function(){ return i("../models/supplier-calendar-day.ts");});
d("volta/models/supplier", function(){ return i("../models/supplier.ts");});
d("volta/models/tag", function(){ return i("../models/tag.ts");});
d("volta/models/task", function(){ return i("../models/task.ts");});
d("volta/models/tenant", function(){ return i("../models/tenant.ts");});
d("volta/models/transaction", function(){ return i("../models/transaction.ts");});
d("volta/models/translation", function(){ return i("../models/translation.ts");});
d("volta/models/update-planning-settings-cmd", function(){ return i("../models/update-planning-settings-cmd.ts");});
d("volta/models/user-preference", function(){ return i("../models/user-preference.ts");});
d("volta/models/user-profile", function(){ return i("../models/user-profile.ts");});
d("volta/models/user-role", function(){ return i("../models/user-role.ts");});
d("volta/models/user", function(){ return i("../models/user.ts");});
d("volta/models/warehouse-calendar-day", function(){ return i("../models/warehouse-calendar-day.ts");});
d("volta/models/warehouse", function(){ return i("../models/warehouse.ts");});
d("volta/models/workshop-calendar-day", function(){ return i("../models/workshop-calendar-day.ts");});
d("volta/models/workshop", function(){ return i("../models/workshop.ts");});
d("volta/router", function(){ return i("../router.ts");});
d("volta/sentry", function(){ return i("../sentry.ts");});
d("volta/serializers/-default", function(){ return i("../serializers/-default.js");});
d("volta/serializers/-json-api", function(){ return i("../serializers/-json-api.js");});
d("volta/serializers/-rest", function(){ return i("../serializers/-rest.js");});
d("volta/serializers/daf-monthly", function(){ return i("../serializers/daf-monthly.js");});
d("volta/serializers/daf-weekly", function(){ return i("../serializers/daf-weekly.js");});
d("volta/serializers/po-task", function(){ return i("../serializers/po-task.ts");});
d("volta/serializers/simulation-item-setting", function(){ return i("../serializers/simulation-item-setting.js");});
d("volta/serializers/sku-task", function(){ return i("../serializers/sku-task.ts");});
d("volta/services/-ea-motion", function(){ return i("../services/-ea-motion.js");});
d("volta/services/-ensure-registered", function(){ return i("../services/-ensure-registered.js");});
d("volta/services/adu-ts-data", function(){ return i("../services/adu-ts-data.ts");});
d("volta/services/app-nav-state", function(){ return i("../services/app-nav-state.ts");});
d("volta/services/app-settings", function(){ return i("../services/app-settings.ts");});
d("volta/services/authorization", function(){ return i("../services/authorization.ts");});
d("volta/services/body-class", function(){ return i("../services/body-class.js");});
d("volta/services/bv-flash", function(){ return i("../services/bv-flash.ts");});
d("volta/services/bv-metrics", function(){ return i("../services/bv-metrics.ts");});
d("volta/services/calendar-service", function(){ return i("../services/calendar-service.ts");});
d("volta/services/collection-builder-alerts", function(){ return i("../services/collection-builder-alerts.ts");});
d("volta/services/collection-builder-open-orders", function(){ return i("../services/collection-builder-open-orders.ts");});
d("volta/services/collection-builder-po-groups", function(){ return i("../services/collection-builder-po-groups.ts");});
d("volta/services/collection-builder-skus", function(){ return i("../services/collection-builder-skus.ts");});
d("volta/services/collection-builder-workshops", function(){ return i("../services/collection-builder-workshops.ts");});
d("volta/services/cookies", function(){ return i("../services/cookies.js");});
d("volta/services/cron-service", function(){ return i("../services/cron-service.js");});
d("volta/services/custom-field-definitions-service", function(){ return i("../services/custom-field-definitions-service.ts");});
d("volta/services/dashboard-stats", function(){ return i("../services/dashboard-stats.ts");});
d("volta/services/event-stream", function(){ return i("../services/event-stream.js");});
d("volta/services/execution-alerts-ts-data", function(){ return i("../services/execution-alerts-ts-data.ts");});
d("volta/services/file-queue", function(){ return i("../services/file-queue.js");});
d("volta/services/in-viewport", function(){ return i("../services/in-viewport.js");});
d("volta/services/infinity", function(){ return i("../services/infinity.js");});
d("volta/services/intl", function(){ return i("../services/intl.js");});
d("volta/services/keyboard", function(){ return i("../services/keyboard.js");});
d("volta/services/label-service", function(){ return i("../services/label-service.ts");});
d("volta/services/local-preferences", function(){ return i("../services/local-preferences.ts");});
d("volta/services/localization", function(){ return i("../services/localization.ts");});
d("volta/services/media", function(){ return i("../services/media.js");});
d("volta/services/memory-scroll", function(){ return i("../services/memory-scroll.ts");});
d("volta/services/metrics", function(){ return i("../services/metrics.js");});
d("volta/services/model-enums", function(){ return i("../services/model-enums.ts");});
d("volta/services/moment", function(){ return i("../services/moment.js");});
d("volta/services/page-title-list", function(){ return i("../services/page-title-list.js");});
d("volta/services/page-title", function(){ return i("../services/page-title.js");});
d("volta/services/permissions-cache", function(){ return i("../services/permissions-cache.ts");});
d("volta/services/planning-api", function(){ return i("../services/planning-api.ts");});
d("volta/services/planning-counts", function(){ return i("../services/planning-counts.ts");});
d("volta/services/planning-order-api", function(){ return i("../services/planning-order-api.ts");});
d("volta/services/power-calendar", function(){ return i("../services/power-calendar.js");});
d("volta/services/resize-observer", function(){ return i("../services/resize-observer.js");});
d("volta/services/scroll-lock-manager", function(){ return i("../services/scroll-lock-manager.ts");});
d("volta/services/sentry-service", function(){ return i("../services/sentry-service.ts");});
d("volta/services/session-user", function(){ return i("../services/session-user.ts");});
d("volta/services/session", function(){ return i("../services/session.js");});
d("volta/services/sku-modal-service", function(){ return i("../services/sku-modal-service.ts");});
d("volta/services/socket-io", function(){ return i("../services/socket-io.js");});
d("volta/services/store", function(){ return i("../services/store.js");});
d("volta/services/store", function(){ return i("../services/store.ts");});
d("volta/services/task-service", function(){ return i("../services/task-service.ts");});
d("volta/services/text-measurer", function(){ return i("../services/text-measurer.js");});
d("volta/services/ts-base-service", function(){ return i("../services/ts-base-service.ts");});
d("volta/services/websockets", function(){ return i("../services/websockets.js");});
d("volta/session-stores/application", function(){ return i("../session-stores/application.js");});
d("volta/transforms/array", function(){ return i("../transforms/array.ts");});
d("volta/transforms/boolean", function(){ return i("../transforms/boolean.js");});
d("volta/transforms/date", function(){ return i("../transforms/date.js");});
d("volta/transforms/number", function(){ return i("../transforms/number.js");});
d("volta/transforms/string", function(){ return i("../transforms/string.js");});
d("volta/utils/adu-utils", function(){ return i("../utils/adu-utils.ts");});
d("volta/utils/api/build-url", function(){ return i("../utils/api/build-url.ts");});
d("volta/utils/api/collection-command", function(){ return i("../utils/api/collection-command.ts");});
d("volta/utils/api/index", function(){ return i("../utils/api/index.ts");});
d("volta/utils/api/jsonapi-types", function(){ return i("../utils/api/jsonapi-types.ts");});
d("volta/utils/api/resource-action", function(){ return i("../utils/api/resource-action.ts");});
d("volta/utils/api/resource-command", function(){ return i("../utils/api/resource-command.ts");});
d("volta/utils/api/serialize-and-push", function(){ return i("../utils/api/serialize-and-push.ts");});
d("volta/utils/api/types", function(){ return i("../utils/api/types.ts");});
d("volta/utils/application", function(){ return i("../utils/application.ts");});
d("volta/utils/array-utils", function(){ return i("../utils/array-utils.ts");});
d("volta/utils/calculate-position", function(){ return i("../utils/calculate-position.js");});
d("volta/utils/chart-utils", function(){ return i("../utils/chart-utils.ts");});
d("volta/utils/column-utils", function(){ return i("../utils/column-utils.ts");});
d("volta/utils/daf-utils", function(){ return i("../utils/daf-utils.ts");});
d("volta/utils/date-utils", function(){ return i("../utils/date-utils.ts");});
d("volta/utils/dom-utils", function(){ return i("../utils/dom-utils.ts");});
d("volta/utils/error-utils", function(){ return i("../utils/error-utils.ts");});
d("volta/utils/filters-utils", function(){ return i("../utils/filters-utils.ts");});
d("volta/utils/focus", function(){ return i("../utils/focus.ts");});
d("volta/utils/group-utils", function(){ return i("../utils/group-utils.ts");});
d("volta/utils/id", function(){ return i("../utils/id.ts");});
d("volta/utils/intl/missing-message", function(){ return i("../utils/intl/missing-message.js");});
d("volta/utils/intl/missing-message", function(){ return i("../utils/intl/missing-message.ts");});
d("volta/utils/math-utils", function(){ return i("../utils/math-utils.ts");});
d("volta/utils/object-utils", function(){ return i("../utils/object-utils.ts");});
d("volta/utils/style-utils", function(){ return i("../utils/style-utils.ts");});
d("volta/utils/text-utils", function(){ return i("../utils/text-utils.ts");});
d("volta/utils/titleize", function(){ return i("../utils/titleize.js");});
d("volta/utils/validate-formula", function(){ return i("../utils/validate-formula.ts");});
d("volta/utils/workshop-exceptions-utils", function(){ return i("../utils/workshop-exceptions-utils.ts");});
d("volta/validations/adu-profile", function(){ return i("../validations/adu-profile.ts");});
d("volta/validations/buffer-profile", function(){ return i("../validations/buffer-profile.ts");});
d("volta/validations/custom-field", function(){ return i("../validations/custom-field.ts");});
d("volta/validations/daf-profile", function(){ return i("../validations/daf-profile.ts");});
d("volta/validations/messages", function(){ return i("../validations/messages.js");});
d("volta/validations/planning-order", function(){ return i("../validations/planning-order.ts");});
d("volta/validations/schedule", function(){ return i("../validations/schedule.ts");});
d("volta/validations/settings-features", function(){ return i("../validations/settings-features.ts");});
d("volta/validations/supplier", function(){ return i("../validations/supplier.ts");});
d("volta/validations/task", function(){ return i("../validations/task.ts");});
d("volta/validations/update-many-sku", function(){ return i("../validations/update-many-sku.ts");});
d("volta/validations/update-planning-settings", function(){ return i("../validations/update-planning-settings.ts");});
d("volta/validations/update-sku", function(){ return i("../validations/update-sku.ts");});
d("volta/validations/user-role", function(){ return i("../validations/user-role.ts");});
d("volta/validations/user", function(){ return i("../validations/user.ts");});
d("volta/validations/warehouse", function(){ return i("../validations/warehouse.ts");});
d("volta/validations/workshop", function(){ return i("../validations/workshop.ts");});
d("volta/validators/check-date", function(){ return i("../validators/check-date.ts");});
d("volta/validators/check-formula", function(){ return i("../validators/check-formula.ts");});
d("volta/validators/check-table-columns", function(){ return i("../validators/check-table-columns.ts");});
d("volta/validators/compare-date", function(){ return i("../validators/compare-date.ts");});
d("volta/validators/on-check", function(){ return i("../validators/on-check.ts");});
d("volta/validators/validate-cron", function(){ return i("../validators/validate-cron.ts");});
d("volta/_pods/application/template", function(){ return i("../_pods/application/template.hbs");});
d("volta/_pods/application/controller", function(){ return i("../_pods/application/controller.ts");});
d("volta/routes/application", function(){ return i("../routes/application.js");});
d("volta/controllers/resource-controller", function(){ return i("../controllers/resource-controller.ts");});
d("volta/routes/base", function(){ return i("../routes/base.ts");});
d("volta/templates/loading", function(){ return i("../templates/loading.hbs");});
d("volta/templates/login-error", function(){ return i("../templates/login-error.hbs");});
d("volta/templates/login-loading", function(){ return i("../templates/login-loading.hbs");});



w._embroiderRouteBundles_ = [
  {
    names: ["login"],
    load: function() {
      return import("./_route_/login.js");
    }
  },
  {
    names: ["not-found"],
    load: function() {
      return import("./_route_/not-found.js");
    }
  },
  {
    names: ["protected.account.activities"],
    load: function() {
      return import("./_route_/protected.account.activities.js");
    }
  },
  {
    names: ["protected.account.preferences"],
    load: function() {
      return import("./_route_/protected.account.preferences.js");
    }
  },
  {
    names: ["protected.account.profile"],
    load: function() {
      return import("./_route_/protected.account.profile.js");
    }
  },
  {
    names: ["protected.account"],
    load: function() {
      return import("./_route_/protected.account.js");
    }
  },
  {
    names: ["protected.adu-profile-create"],
    load: function() {
      return import("./_route_/protected.adu-profile-create.js");
    }
  },
  {
    names: ["protected.adu-profile"],
    load: function() {
      return import("./_route_/protected.adu-profile.js");
    }
  },
  {
    names: ["protected.adu-profiles"],
    load: function() {
      return import("./_route_/protected.adu-profiles.js");
    }
  },
  {
    names: ["protected.alerts.sku"],
    load: function() {
      return import("./_route_/protected.alerts.sku.js");
    }
  },
  {
    names: ["protected.alerts"],
    load: function() {
      return import("./_route_/protected.alerts.js");
    }
  },
  {
    names: ["protected.bom.sku"],
    load: function() {
      return import("./_route_/protected.bom.sku.js");
    }
  },
  {
    names: ["protected.bom"],
    load: function() {
      return import("./_route_/protected.bom.js");
    }
  },
  {
    names: ["protected.boms.sku"],
    load: function() {
      return import("./_route_/protected.boms.sku.js");
    }
  },
  {
    names: ["protected.boms"],
    load: function() {
      return import("./_route_/protected.boms.js");
    }
  },
  {
    names: ["protected.buffer-profile-create"],
    load: function() {
      return import("./_route_/protected.buffer-profile-create.js");
    }
  },
  {
    names: ["protected.buffer-profile"],
    load: function() {
      return import("./_route_/protected.buffer-profile.js");
    }
  },
  {
    names: ["protected.buffer-profiles"],
    load: function() {
      return import("./_route_/protected.buffer-profiles.js");
    }
  },
  {
    names: ["protected.custom-field-definition"],
    load: function() {
      return import("./_route_/protected.custom-field-definition.js");
    }
  },
  {
    names: ["protected.custom-field-definitions-create"],
    load: function() {
      return import("./_route_/protected.custom-field-definitions-create.js");
    }
  },
  {
    names: ["protected.custom-field-definitions"],
    load: function() {
      return import("./_route_/protected.custom-field-definitions.js");
    }
  },
  {
    names: ["protected.daf-profile-create"],
    load: function() {
      return import("./_route_/protected.daf-profile-create.js");
    }
  },
  {
    names: ["protected.daf-profile"],
    load: function() {
      return import("./_route_/protected.daf-profile.js");
    }
  },
  {
    names: ["protected.daf-profiles"],
    load: function() {
      return import("./_route_/protected.daf-profiles.js");
    }
  },
  {
    names: ["protected.dashboard"],
    load: function() {
      return import("./_route_/protected.dashboard.js");
    }
  },
  {
    names: ["protected.import"],
    load: function() {
      return import("./_route_/protected.import.js");
    }
  },
  {
    names: ["protected.my-tasks"],
    load: function() {
      return import("./_route_/protected.my-tasks.js");
    }
  },
  {
    names: ["protected.open-orders.sku"],
    load: function() {
      return import("./_route_/protected.open-orders.sku.js");
    }
  },
  {
    names: ["protected.open-orders"],
    load: function() {
      return import("./_route_/protected.open-orders.js");
    }
  },
  {
    names: ["protected.plannings.sku"],
    load: function() {
      return import("./_route_/protected.plannings.sku.js");
    }
  },
  {
    names: ["protected.plannings"],
    load: function() {
      return import("./_route_/protected.plannings.js");
    }
  },
  {
    names: ["protected.po-workbench-validation-rule"],
    load: function() {
      return import("./_route_/protected.po-workbench-validation-rule.js");
    }
  },
  {
    names: ["protected.po-workbench-validation-rules-create"],
    load: function() {
      return import("./_route_/protected.po-workbench-validation-rules-create.js");
    }
  },
  {
    names: ["protected.po-workbench-validation-rules"],
    load: function() {
      return import("./_route_/protected.po-workbench-validation-rules.js");
    }
  },
  {
    names: ["protected.po-workbench.line.activities"],
    load: function() {
      return import("./_route_/protected.po-workbench.line.activities.js");
    }
  },
  {
    names: ["protected.po-workbench.line.index"],
    load: function() {
      return import("./_route_/protected.po-workbench.line.index.js");
    }
  },
  {
    names: ["protected.po-workbench.line.tasks"],
    load: function() {
      return import("./_route_/protected.po-workbench.line.tasks.js");
    }
  },
  {
    names: ["protected.po-workbench.line"],
    load: function() {
      return import("./_route_/protected.po-workbench.line.js");
    }
  },
  {
    names: ["protected.po-workbench.sku"],
    load: function() {
      return import("./_route_/protected.po-workbench.sku.js");
    }
  },
  {
    names: ["protected.po-workbench"],
    load: function() {
      return import("./_route_/protected.po-workbench.js");
    }
  },
  {
    names: ["protected.report"],
    load: function() {
      return import("./_route_/protected.report.js");
    }
  },
  {
    names: ["protected.reports"],
    load: function() {
      return import("./_route_/protected.reports.js");
    }
  },
  {
    names: ["protected.schedule-create"],
    load: function() {
      return import("./_route_/protected.schedule-create.js");
    }
  },
  {
    names: ["protected.schedule-duplicate"],
    load: function() {
      return import("./_route_/protected.schedule-duplicate.js");
    }
  },
  {
    names: ["protected.schedule"],
    load: function() {
      return import("./_route_/protected.schedule.js");
    }
  },
  {
    names: ["protected.schedules"],
    load: function() {
      return import("./_route_/protected.schedules.js");
    }
  },
  {
    names: ["protected.settings.admin"],
    load: function() {
      return import("./_route_/protected.settings.admin.js");
    }
  },
  {
    names: ["protected.settings.features"],
    load: function() {
      return import("./_route_/protected.settings.features.js");
    }
  },
  {
    names: ["protected.settings.general"],
    load: function() {
      return import("./_route_/protected.settings.general.js");
    }
  },
  {
    names: ["protected.settings.rules"],
    load: function() {
      return import("./_route_/protected.settings.rules.js");
    }
  },
  {
    names: ["protected.settings"],
    load: function() {
      return import("./_route_/protected.settings.js");
    }
  },
  {
    names: ["protected.simulation-create"],
    load: function() {
      return import("./_route_/protected.simulation-create.js");
    }
  },
  {
    names: ["protected.simulation-duplicate"],
    load: function() {
      return import("./_route_/protected.simulation-duplicate.js");
    }
  },
  {
    names: ["protected.simulation.simulation-scenario"],
    load: function() {
      return import("./_route_/protected.simulation.simulation-scenario.js");
    }
  },
  {
    names: ["protected.simulation"],
    load: function() {
      return import("./_route_/protected.simulation.js");
    }
  },
  {
    names: ["protected.simulations"],
    load: function() {
      return import("./_route_/protected.simulations.js");
    }
  },
  {
    names: ["protected.sku-create"],
    load: function() {
      return import("./_route_/protected.sku-create.js");
    }
  },
  {
    names: ["protected.sku-duplicate"],
    load: function() {
      return import("./_route_/protected.sku-duplicate.js");
    }
  },
  {
    names: ["protected.sku"],
    load: function() {
      return import("./_route_/protected.sku.js");
    }
  },
  {
    names: ["protected.skus.sku"],
    load: function() {
      return import("./_route_/protected.skus.sku.js");
    }
  },
  {
    names: ["protected.skus"],
    load: function() {
      return import("./_route_/protected.skus.js");
    }
  },
  {
    names: ["protected.supplier-create"],
    load: function() {
      return import("./_route_/protected.supplier-create.js");
    }
  },
  {
    names: ["protected.supplier"],
    load: function() {
      return import("./_route_/protected.supplier.js");
    }
  },
  {
    names: ["protected.suppliers"],
    load: function() {
      return import("./_route_/protected.suppliers.js");
    }
  },
  {
    names: ["protected.user-create"],
    load: function() {
      return import("./_route_/protected.user-create.js");
    }
  },
  {
    names: ["protected.user-role-create"],
    load: function() {
      return import("./_route_/protected.user-role-create.js");
    }
  },
  {
    names: ["protected.user-role"],
    load: function() {
      return import("./_route_/protected.user-role.js");
    }
  },
  {
    names: ["protected.user-roles"],
    load: function() {
      return import("./_route_/protected.user-roles.js");
    }
  },
  {
    names: ["protected.user"],
    load: function() {
      return import("./_route_/protected.user.js");
    }
  },
  {
    names: ["protected.users"],
    load: function() {
      return import("./_route_/protected.users.js");
    }
  },
  {
    names: ["protected.warehouse-create"],
    load: function() {
      return import("./_route_/protected.warehouse-create.js");
    }
  },
  {
    names: ["protected.warehouse"],
    load: function() {
      return import("./_route_/protected.warehouse.js");
    }
  },
  {
    names: ["protected.warehouses"],
    load: function() {
      return import("./_route_/protected.warehouses.js");
    }
  },
  {
    names: ["protected.workshop-create"],
    load: function() {
      return import("./_route_/protected.workshop-create.js");
    }
  },
  {
    names: ["protected.workshop"],
    load: function() {
      return import("./_route_/protected.workshop.js");
    }
  },
  {
    names: ["protected.workshops"],
    load: function() {
      return import("./_route_/protected.workshops.js");
    }
  },
  {
    names: ["protected"],
    load: function() {
      return import("./_route_/protected.js");
    }
  },
  {
    names: ["protected-controller"],
    load: function() {
      return import("./_route_/protected-controller.js");
    }
  },
]


if (!runningTests) {
  i("../app").default.create({"tenant":"VOLTA_TENANT_ID","env":"VOLTA_TENANT_ENV","customColumns":{"executionAlerts":"VOLTA_CUSTOM_COLS_EXECUTION","openOrders":"VOLTA_CUSTOM_COLS_ORDERS"},"name":"volta","version":"8.5.7+5d078d0b"});
}

