import { task } from 'ember-concurrency';
import DashboardSummary from 'volta/models/dashboard-summary';
import { PlanningEvents, PlanningResourceName } from 'volta/models/planning';
import { defaultErrorHandler } from 'volta/utils/error-utils';

import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type { IDashboardSummary } from 'volta/models/types/stats';
import type EventStreamService from './event-stream';
import type StoreService from './store';

import type { IJsonApiQuery } from 'volta/utils/api/jsonapi-types';
import type SessionUserService from './session-user';

export default class DashboardStatsService extends Service {
  // Services
  @service store!: StoreService;
  @service eventStream!: EventStreamService;
  @service sessionUser!: SessionUserService;

  @tracked dashboardSummary?: DashboardSummary;

  constructor() {
    super(...arguments);
    if (!this.sessionUser.user?.isSupplier) {
      this.eventStream.addHandler(
        PlanningResourceName.plural,
        PlanningEvents.PlanningsGlobalCalculationDone,
        this.refreshDashboardSummary,
        this
      );
    }
  }

  refreshDashboardSummary() {
    return this.task.perform();
  }

  task = task(async (query?: IJsonApiQuery) => {
    try {
      if (this.sessionUser.user?.isSupplier) {
        return;
      }
      const data: IDashboardSummary = await this.store.normalGet('stats/dashboardSummary', query);
      this.dashboardSummary = new DashboardSummary(data);
      return this.dashboardSummary;
    } catch (error) {
      return defaultErrorHandler(error);
    }
  });
}

declare module '@ember/service' {
  interface Registry {
    'dashboard-stats': DashboardStatsService;
  }
}
