import moment from 'moment-timezone';
import { IBvTableColumn } from 'volta/components/bv-table';
// @ts-ignore
import CodeDescCell from 'volta/components/cell/code-desc-cell';
// @ts-ignore
import FormatCell from 'volta/components/cell/format-cell';
// @ts-ignore
import LabelCell from 'volta/components/cell/label-cell';
import BooleanFilter from 'volta/components/filters/boolean-filter';
import InputFilter from 'volta/components/filters/input-filter';
import SelectFilter from 'volta/components/filters/select-filter';
import SkusWarehouseFilter from 'volta/components/filters/skus-warehouse-filter';
import { columnsToProps, columnsToSortOptions } from 'volta/models/collection-view';
import { buildCustomFieldColumn, parseCFApiParamKey } from 'volta/models/custom-field-definition';
import { WORKSHOP_TYPES, WorkshopResourceName } from 'volta/models/workshop';

import Service, { service } from '@ember/service';

import type {
  ICollectionDefinitionBuilder,
  ICollectionSortOption,
  ICollectionViewDefinition,
  ICreateCollectionViewCmd
} from 'volta/models/types/collection-view';
import type SessionUserService from 'volta/services/session-user';

import type CustomFieldDefinitionsService from 'volta/services/custom-field-definitions-service';
import type IntlService from 'ember-intl/services/intl';
import type LabelService from 'volta/services/label-service';
import type StoreService from 'volta/services/store';
import type RouterService from '@ember/routing/router-service';
import type Workshop from 'volta/models/workshop';

export default class WorkshopsCollectionDefinitionBuilder
  extends Service
  implements ICollectionDefinitionBuilder
{
  @service store!: StoreService;
  @service customFieldDefinitionsService!: CustomFieldDefinitionsService;
  @service intl!: IntlService;
  @service labelService!: LabelService;
  @service sessionUser!: SessionUserService;
  @service router!: RouterService;

  buildColumns() {
    const { intl, labelService, customFieldDefinitionsService } = this;
    const defs: IBvTableColumn[] = [
      {
        columnName: intl.t('name'),
        sortKey: 'description',
        valuePath: 'code',
        width: 150,
        minWidth: 100,
        cellComponent: CodeDescCell,
        isFixed: 'left',
        format: (model: Workshop) => {
          return {
            code: model.code,
            description: model.description
          };
        }
      },
      {
        columnName: intl.t('workshopType'),
        sortKey: 'workshopType',
        valuePath: 'workshopType',
        width: 80,
        isReorderable: true,
        isResizable: true,
        cellComponent: LabelCell,
        format: (model: Workshop) => {
          return model.workshopType
            ? {
                label: intl.t(`workshopTypes.${model.workshopType}`),
                iconLeft:
                  model.workshopType === 'DELIVERY'
                    ? 'workshop-delivery-outline'
                    : 'workshop-outline',
                color: model.workshopType === 'DELIVERY' ? 'purple' : 'severe'
              }
            : {};
        }
      },
      {
        columnName: intl.t('warehouse'),
        sortKey: 'warehouse.code',
        valuePath: 'warehouse.code',
        width: 70,
        minWidth: 70,
        isReorderable: true,
        isResizable: true,
        cellComponent: LabelCell,
        format: (model: Workshop) => {
          return {
            label: model.warehouse?.code,
            color: 'green',
            iconRight: 'link-outline',
            tooltipText: model.warehouse?.description,
            onClick: () =>
              model.warehouse && this.router.transitionTo('protected.warehouse', model.warehouse.id)
          };
        }
      },

      {
        columnName: intl.t('workshopsPage.capacity'),
        valuePath: 'capacity',
        sortKey: 'capacitySettings.capacity',
        width: 100,
        isReorderable: true,
        isResizable: true,
        cellComponent: FormatCell,
        format: (model: Workshop) => {
          const { capacity, capacityHours, capacityUnit } = model.capacitySettings;
          const value = capacityHours ?? capacity;
          return {
            dataType: 'number',
            value,
            unit: value && labelService.unitMeasure(capacityHours ? 'HOUR' : capacityUnit!)
          };
        }
      },
      {
        columnName: intl.t('workshopsPage.capacityUnitFrequency'),
        sortKey: 'capacityUnitFrequency',
        valuePath: 'capacitySettings.capacityUnitFrequency',
        width: 70,
        minWidth: 70,
        isReorderable: true,
        isResizable: true,
        cellComponent: LabelCell,
        format: (model: Workshop) => {
          const value = model.capacitySettings.capacityHours ?? model.capacitySettings.capacity;
          return {
            label:
              value && this.intl.t(`aduFrequencies.${model.capacitySettings.capacityUnitFrequency}`)
          };
        }
      },
      {
        columnName: intl.t('isControlPoint'),
        sortKey: 'isControlPoint',
        valuePath: 'isControlPoint',
        width: 70,
        minWidth: 70,
        isReorderable: true,
        isResizable: true,
        cellComponent: FormatCell,
        format: (model: Workshop) => {
          return {
            dataType: 'boolean',
            value: model.isControlPoint
          };
        }
      },
      {
        columnName: intl.t('timeBuffer'),
        sortKey: 'timeBuffer',
        valuePath: 'timeBuffer',
        width: 80,
        isReorderable: true,
        isResizable: true,
        format: (model: Workshop) => {
          return model.timeBuffer && model.isControlPoint
            ? moment.duration(model.timeBuffer).humanize()
            : undefined;
        }
      }
    ];

    const customFieldProps: Array<IBvTableColumn | undefined> = (
      customFieldDefinitionsService.workshopsCustomProperties() ?? []
    ).map((def) =>
      buildCustomFieldColumn(def, (model: Workshop) => {
        const dataType = def.dataType ? def.dataType.toLowerCase() : undefined;
        const { customFields } = model;
        const key = parseCFApiParamKey(def.property);

        const value = customFields ? customFields[key] : undefined;
        return {
          value,
          dataType
        };
      })
    );

    return defs.concat(customFieldProps.filter(Boolean) as IBvTableColumn[]);
  }

  buildFilters(_queryParamsValues: TQueryParams) {
    const { customFieldDefinitionsService } = this;

    const normalDefs: Array<TFilterDefinition> = [
      {
        queryParam: 'q',
        apiParam: 'q',
        comparators: ['lk'],
        component: InputFilter,
        hidden: true,
        componentArgs: {}
      },
      {
        queryParam: 'workshopType',
        apiParam: 'workshopType',
        comparators: ['eq'],
        component: SelectFilter,
        isPrimary: true,
        componentArgs: {
          horizontalPosition: 'right',
          possibleValues: WORKSHOP_TYPES
        }
      },
      {
        queryParam: 'warehouse',
        apiParam: 'warehouseId',
        comparators: ['in', 'ni'],
        component: SkusWarehouseFilter,
        componentArgs: {}
      },
      {
        queryParam: 'isControlPoint',
        apiParam: 'isControlPoint',
        comparators: ['eq'],
        component: BooleanFilter,
        clearDefaultValue: ['false'],
        componentArgs: {}
      }
    ];

    return normalDefs.concat(customFieldDefinitionsService.workshopsCustomFieldsFilters());
  }

  buildDefaultView(columns: IBvTableColumn[]): ICreateCollectionViewCmd {
    const { intl } = this;
    const properties = columnsToProps(columns);
    return {
      name: intl.t('collectionViewsPage.default.name', {
        resourceNamePlural: intl.t(WorkshopResourceName.plural)
      }),
      description: intl.t('collectionViewsPage.default.description', {
        resourceNamePlural: intl.t(WorkshopResourceName.plural)
      }),
      collectionType: 'TABLE',
      entityType: WorkshopResourceName.plural,
      properties,
      createdBy: this.sessionUser.userId,
      filters: []
    };
  }

  build(queryParams: TQueryParams, sorts: ICollectionSortOption[] = []) {
    const filterDefinitions = this.buildFilters(queryParams);
    const columns = this.buildColumns();
    const defaultView = this.buildDefaultView(columns);

    const view: ICollectionViewDefinition = {
      resourceName: WorkshopResourceName,
      sortLimit: 0,
      sorts: columnsToSortOptions(columns).concat(sorts) as ICollectionSortOption[],
      properties: columnsToProps(columns),
      filters: filterDefinitions,
      definitions: columns,
      types: ['TABLE'],
      defaultView
    };

    return view;
  }
}

declare module '@ember/service' {
  interface Registry {
    'collection-builder-workshops': WorkshopsCollectionDefinitionBuilder;
  }
}
