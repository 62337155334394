import Helper from '@ember/component/helper';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';

import type SessionUserService from 'volta/services/session-user';

export default class IamSupplierUserHelper extends Helper {
  @service sessionUser!: SessionUserService;

  onPermissionsInit = observer('sessionUser.user', () => {
    this.recompute();
  });

  compute() {
    return Boolean(this.sessionUser.user?.isSupplier);
  }
}
