import { IFormulaBuilder } from 'volta/models/types/customFieldDefinition';
import application from 'volta/utils/application';
import IntlService from "ember-intl/services/intl";

const intl = application?.instance?.lookup('service:intl') as IntlService;

export default function checkFormula() {
  return (_: string, formula: IFormulaBuilder) => {
    if (!formula) {
      return true;
    }
    const errors: { [id: string]: string[] } = {};
    if (!formula.id) {
      errors.root = [intl.t('customFieldsDefinitionsPage.formula.errors.empty')];
      return errors;
    }

    const checkNode = (node: IFormulaBuilder, parent?: IFormulaBuilder): IFormulaBuilder => {
      const { customFieldId, value, type } = node;
      const children = parent?.args;
      if (children) {
        if (node.name !== 'if' && node.type === 'OPERATOR') {
          const child = children.find((c: IFormulaBuilder) => c.resultType);
          if (
            child &&
            !children.every(
              (c: IFormulaBuilder) => c.resultType && c.resultType === child.resultType
            )
          ) {
            const index = node.id;
            if (index) {
              errors[index] = [
                ...errors[index],
                intl.t('customFieldsDefinitionsPage.formula.errors.resultType')
              ];
            }
          }
        }
      }

      if ((type === 'OPERATOR' || type === 'FUNCTION') && !node.args) {
        const index = node.id;
        if (index) {
          errors[index] = [
            ...errors[index],
            intl.t('customFieldsDefinitionsPage.formula.errors.missingValue')
          ];
        }
      }
      if ((type === 'PROPERTY' && !customFieldId) || (type === 'CONSTANT' && !value)) {
        const index = node.id;
        if (index) {
          errors[index] = [
            ...errors[index],
            intl.t('customFieldsDefinitionsPage.formula.errors.missingValue')
          ];
        }
      }

      if (customFieldId && value) {
        const index = node.id;
        if (index) {
          errors[index] = [
            ...errors[index],
            intl.t('customFieldsDefinitionsPage.formula.errors.type')
          ];
        }
      }
      return node;
    };

    const formatTree = (currentNode: IFormulaBuilder, parent?: IFormulaBuilder) => {
      const formattedNode = checkNode(currentNode, parent);
      if (!currentNode.args) {
        return formattedNode;
      }

      formattedNode.args = currentNode.args.map((arg: IFormulaBuilder) =>
        formatTree(arg, currentNode)
      );
      return formattedNode;
    };

    formatTree(formula);
    return Object.keys(errors).length ? errors : true;
  };
}
