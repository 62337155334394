import { AdapterRegistry } from 'ember-data/adapter';
import Model from 'ember-data/model';
import param from 'jquery-param';
import { Value as JSONValue } from 'json-typescript';

import { classify } from '@ember/string';

import { _getModelName, _getStore } from './build-url';
import { ApiCollectionCommandOptions, ApiCommand } from './types';

export default function collectionCmd<IN = any, OUT = any, M extends typeof Model = typeof Model>(
  cmd: string,
  options: ApiCollectionCommandOptions<IN, OUT, M> = {}
) {
  return function runCollectionCmd(this: M, payload: IN): Promise<OUT> {
    const _modelName = _getModelName(this);
    const _store = _getStore();
    const _adapter = _store.adapterFor(_modelName as keyof AdapterRegistry);
    const { ajaxOptions, before, after } = options;
    const _cmdPayload: ApiCommand = Object.assign(
      {
        _cmd: classify(cmd)
      },
      payload
    );
    let fullUrl = _adapter.buildURL(_modelName);

    let data = (before && before.call(this, _cmdPayload)) ?? _cmdPayload;
    if (ajaxOptions?.queryParams) {
      fullUrl += `?${param(ajaxOptions.queryParams)}`;
    }
    return _adapter
      .ajax(fullUrl, 'PATCH', Object.assign(ajaxOptions ?? {}, { data }))
      .then((response: JSONValue) => {
        if (after) {
          return after.call(this, response);
        }

        return response;
      });
  };
}
