import {
  validateLength,
  validateNumber,
  validatePresence
} from 'ember-changeset-validations/validators';

export default {
  skuIds: [validatePresence(true), validateLength({ min: 1 })],
  acqCode: [],
  leadTime: validateNumber({ gt: 0, allowBlank: true, integer: true }),
  orderMultiple: validateNumber({ positive: true, allowBlank: true }),
  moq: validateNumber({ positive: true, allowBlank: true }),
  maxPOQty: validateNumber({ positive: true, allowBlank: true }),
  deliveryWorkshopId: [],
  workshopId: [],
  supplierId: []
};
