import { classify } from '@ember/string';
import Model from 'ember-data/model';
import { Value as JSONValue } from 'json-typescript';
import { _getModelClass, _getModelName, _getStoreFromRecord } from './build-url';

import { normalize } from 'volta/utils/api/serialize-and-push';
import { ApiCommandOptions, ResourceApiCommand } from 'volta/utils/api/types';
import { AdapterRegistry } from 'ember-data/adapter';
import param from 'jquery-param';

interface IVersionableModel {
  version: number;
}

export default function instanceCmd<IN = any, OUT = any>(
  cmd: string,
  options: ApiCommandOptions<IN, OUT> = {}
) {
  return function runInstanceCmd(this: Model & IVersionableModel, payload?: IN): Promise<OUT> {
    const recordClass = _getModelClass(this);
    const modelName = _getModelName(recordClass);
    const store = _getStoreFromRecord(this);
    const adapter = store.adapterFor(modelName as keyof AdapterRegistry);
    const { ajaxOptions, before, after } = options;
    const cmdPayload: ResourceApiCommand = Object.assign(
      {
        _cmd: classify(cmd),
        entityId: this.id,
        version: this.version
      },
      payload ?? {}
    );
    let fullUrl = adapter.buildURL(modelName);

    if (options.query) {
      fullUrl += '?' + param(options.query);
    }

    const data = (before && before.call(this, cmdPayload)) ?? cmdPayload;
    return adapter
      .ajax(fullUrl, 'PATCH', Object.assign(ajaxOptions ?? {}, { data }))
      .then((response: JSONValue) => {
        const normalized = normalize(modelName, response);
        if (after && !this.isDestroyed) {
          return after.call(this, normalized);
        }

        return normalized;
      });
  };
}
