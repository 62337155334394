import Component from '@glimmer/component';

type TElement = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'legend';

export type TVariant =
  | 'heading-xs'
  | 'heading-sm'
  | 'heading-md'
  | 'heading-lg'
  | 'heading-xl'
  | 'heading-2xl'
  | 'heading-3xl'
  | 'heading-4xl'
  | 'body-sm'
  | 'body-md'
  | 'body-lg'
  | 'code';

type TAlignment = 'start' | 'center' | 'end' | 'justify';

type TFontWeight = 'regular' | 'medium' | 'semibold' | 'bold';

type TColor = 'success' | 'critical' | 'warning' | 'subdued' | 'hud';

const VariantFontWeightMapping: { [V in TVariant]: TFontWeight } = {
  'heading-xs': 'semibold',
  'heading-sm': 'semibold',
  'heading-md': 'semibold',
  'heading-lg': 'semibold',
  'heading-xl': 'semibold',
  'heading-2xl': 'semibold',
  'heading-3xl': 'semibold',
  'heading-4xl': 'bold',
  'body-sm': 'regular',
  'body-md': 'regular',
  'body-lg': 'regular',
  code: 'regular'
};

const VariantElementMapping: { [V in TVariant]: TElement } = {
  'heading-xs': 'h6',
  'heading-sm': 'h6',
  'heading-md': 'h6',
  'heading-lg': 'h5',
  'heading-xl': 'h4',
  'heading-2xl': 'h3',
  'heading-3xl': 'h2',
  'heading-4xl': 'h1',
  'body-sm': 'span',
  'body-md': 'span',
  'body-lg': 'span',
  code: 'span'
};

const CLASS_NAME = 'bv-text';

const cssMod = (mod?: string) => (mod ? `${CLASS_NAME}--${mod}` : undefined);

export interface IBvTextArgs {
  /** Adjust horizontal alignment of text */
  alignment?: TAlignment;
  /** The element type */
  as: TElement;
  /** Prevent text from overflowing */
  breakWord?: boolean;
  /** Text to display */
  text?: string;
  /** Adjust color of text */
  color?: TColor;
  /** Adjust weight of text */
  fontWeight?: TFontWeight;
  /** HTML id attribute */
  id?: string;
  /** Truncate text overflow with ellipsis */
  truncate?: boolean;
  /** Typographic style of text */
  variant: TVariant;
  /** Visually hide the text */
  visuallyHidden?: boolean;
}

export default class BvText extends Component<IBvTextArgs> {
  get tagName() {
    const { as, variant } = this.args;
    return as ?? VariantElementMapping[variant] ?? 'span';
  }

  get classNames() {
    const { variant, fontWeight, alignment, truncate, breakWord, color, visuallyHidden } =
      this.args;

    const classes = [
      // Root
      CLASS_NAME,
      // Variant
      cssMod(variant),
      // Font Weight
      fontWeight ? cssMod(fontWeight) : cssMod(VariantFontWeightMapping[variant]),
      // Block
      (alignment || truncate) && cssMod('block'),
      // Alignment
      alignment && cssMod(alignment),
      // Break Word
      breakWord && cssMod('break'),
      // Color
      color && cssMod(color),
      // Truncate
      truncate && cssMod('truncate'),
      // Visually Hidden
      visuallyHidden && cssMod('visually-hidden')
    ];

    return classes.filter(Boolean).join(' ');
  }
}
