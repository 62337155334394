import {
  COLLECTION_VIEW_QP_KEYS,
  DEFAULT_PAGE_SIZE
} from 'volta/components/collection-view/api';
import { WITHOUT_ATTRIBUTES_ARRAY } from 'volta/components/jsonapi-collection';
import config from 'volta/config/environment';
import CollectionView from 'volta/models/collection-view';
import { buildCFApiParamPrefix } from 'volta/models/custom-field-definition';
import {
  ICollectionArgs,
  ICollectionFilter,
  ICollectionSort,
  ICollectionViewDefinition,
  ICollectionViewSerialised
} from 'volta/models/types/collection-view';

import { isEmpty, isPresent } from '@ember/utils';

import { indexBy, indexByFn } from './array-utils';
import { defaultErrorHandler } from './error-utils';
import { parseNumber } from './math-utils';
import { withoutAttributes } from './object-utils';

import type { IJsonApiQuery } from './api/jsonapi-types';
type TArrayValue = string | boolean | number;

export const DEFAULT_FILTERS_SEPARATOR: string = config.api.filters.separator;
export const DEFAULT_FILTER_ARRAY_SEPARATOR: string = config.api.filters.arraySeparator;
export const COLLECTION_FILTER_SEPARATOR: string = '~~';

export function buildFilterQuery(array: TArrayValue[]) {
  return array.join(DEFAULT_FILTERS_SEPARATOR);
}

/**
 * Serialize an array of values
 * @param {Array<string>} array The array to serialise
 */
export function serializeArrayValues(array: TArrayValue[]): string {
  return array.join(DEFAULT_FILTER_ARRAY_SEPARATOR);
}

export function clearFilter(filter: string) {
  return `${filter.split(DEFAULT_FILTERS_SEPARATOR).lastObject}`;
}

/**
 * Create an EQ JSONAPI filter
 */
export function eqFilter(value: TArrayValue) {
  return buildFilterQuery(['eq', value]);
}

export function safeEqFilter(filterAsString: string) {
  return eqFilter(clearFilter(`${filterAsString}`));
}

/**
 * Create an NE JSONAPI filter
 */
export function neFilter(value: TArrayValue) {
  return buildFilterQuery(['ne', value]);
}

/**
 * Create an GT JSONAPI filter
 */
export function gtFilter(value: TArrayValue) {
  return buildFilterQuery(['gt', value]);
}

/**
 * Create an LT JSONAPI filter
 */
export function ltFilter(value: TArrayValue) {
  return buildFilterQuery(['lt', value]);
}

/**
 * Create an BT JSONAPI filter
 */
export function btFilter(array: TArrayValue[]) {
  return buildFilterQuery(['bt', serializeArrayValues(array)]);
}

/**
 * Create an LK JSONAPI filter
 */
export function lkFilter(value: TArrayValue) {
  return buildFilterQuery(['lk', value]);
}

/**
 * Create an IN JSONAPI filter
 */
export function inFilter(array: string[]) {
  return buildFilterQuery(['in', serializeArrayValues(array)]);
}

/**
 * Safely create an IN JSONAPI filter from a string formatted filter
 */
export function safeInFilter(filterAsString: string) {
  return inFilter(clearFilter(`${filterAsString}`).split(DEFAULT_FILTER_ARRAY_SEPARATOR));
}

export function niFilter(array: string[]) {
  return buildFilterQuery(['ni', serializeArrayValues(array)]);
}

export function anyFilter(array: string[]) {
  return buildFilterQuery(['any', serializeArrayValues(array)]);
}

export function safeAnyFilter(filterAsString: string) {
  return anyFilter(clearFilter(filterAsString).split(DEFAULT_FILTER_ARRAY_SEPARATOR));
}

export function allFilter(array: string[]) {
  return buildFilterQuery(['all', serializeArrayValues(array)]);
}

export function safeAllFilter(filterAsString: string) {
  return allFilter(clearFilter(`${filterAsString}`).split(DEFAULT_FILTER_ARRAY_SEPARATOR));
}

export function serializeFilters(
  filters: Record<string, string>,
  filterDefinitions: TFilterDefinition[]
) {
  let customFields: object | null = null;
  const queryParams = {} as {
    [key: string]: string | undefined;
  };

  filterDefinitions.forEach((filterDef) => {
    const paramKey = filterDef.queryParam;

    if (filters.hasOwnProperty(paramKey)) {
      const currentFilter = filters[paramKey];

      if (currentFilter && currentFilter.length > 0) {
        if (!paramKey.includes(buildCFApiParamPrefix())) {
          queryParams[paramKey] = currentFilter;
        } else {
          const cfKeys = paramKey.split(buildCFApiParamPrefix());
          const cfKey = cfKeys[cfKeys.length - 1];
          customFields = {
            ...(customFields ?? {}),
            [cfKey]: currentFilter
          };
        }
      } else {
        if (!paramKey.includes(buildCFApiParamPrefix())) {
          queryParams[paramKey] = undefined;
        }
      }
    }
  });

  if (customFields) {
    queryParams.customFields = customFields;
  }

  return queryParams;
}

/**
 * [serializeFilterValues Transform filter object into Query Params]
 */
export function serializeFilterValues(
  filterValues: TFilterValues,
  filterDefinitions: TFilterDefinition[]
): TQueryParams {
  let customFields: object | null = null;
  const queryParams = {} as {
    [key: string]: string | undefined;
  };

  filterDefinitions.forEach((filterDef) => {
    const paramKey = filterDef.queryParam;

    if (filterValues.hasOwnProperty(paramKey)) {
      const currentFilter = filterValues[paramKey];

      if (currentFilter && currentFilter.enabled && currentFilter.values.length > 0) {
        const filterValue = `${
          currentFilter.comparator
        }${DEFAULT_FILTERS_SEPARATOR}${currentFilter.values.join(DEFAULT_FILTER_ARRAY_SEPARATOR)}`;
        if (!paramKey.includes(buildCFApiParamPrefix())) {
          queryParams[paramKey] = filterValue;
        } else {
          const cfKeys = paramKey.split(buildCFApiParamPrefix());
          const cfKey = cfKeys[cfKeys.length - 1];
          customFields = {
            ...(customFields ?? {}),
            [cfKey]: filterValue
          };
        }
      } else {
        if (!paramKey.includes(buildCFApiParamPrefix())) {
          queryParams[paramKey] = undefined;
        }
      }
    }
  });

  if (customFields) {
    queryParams.customFields = customFields;
  }

  return queryParams;
}

export function resetFilterValues(filterDefinitions: TFilterDefinition[]) {
  const resetFilters = {} as { [key: string]: TFilterValue | undefined };

  filterDefinitions.forEach((obj) => {
    const { clearDefaultValue, queryParam } = obj;

    resetFilters[queryParam] = clearDefaultValue
      ? deserializeFilterValue(serializeArrayValues(clearDefaultValue), obj)
      : undefined;
  });

  return resetFilters;
}

export function filtersCount(filterValues: TFilterValues = {}, defaultFilters: string[] = []) {
  return Object.keys(filterValues).filter(
    (key) => filterValues[key] && filterValues[key]?.enabled && !defaultFilters.includes(key)
  ).length;
}

/**
 * Parses the filter values sent by the router and builds a new object
 * to be sent to the filter list component.
 */
export function deserializeFilterValue(
  filterValue: string,
  filterDefinition?: TFilterDefinition
): TFilterValue | undefined {
  const comparator = filterDefinition?.comparators.firstObject ?? 'in';
  // Makes sure we have a comparator and a list of values
  // before adding the filter
  const exprArray =
    `${filterValue}`.indexOf(DEFAULT_FILTERS_SEPARATOR) > -1
      ? `${filterValue}`.split(DEFAULT_FILTERS_SEPARATOR)
      : [comparator, filterValue];

  if (exprArray && exprArray.length === 2) {
    return {
      comparator: exprArray[0] as TComparator,
      values: exprArray[1] ? `${exprArray[1]}`.split(DEFAULT_FILTER_ARRAY_SEPARATOR) : [],
      enabled: true
    };
  }
  return;
}

export function getFilterValuesFromQueryParams(
  params: TQueryParams,
  definitions: TFilterDefinition[]
) {
  const values = {} as Record<string, any>;

  let { customFields = {} } = params;

  try {
    customFields =
      typeof customFields === 'object' ? customFields : JSON.parse(customFields as string);
  } catch (error) {
    defaultErrorHandler(error);
  }

  definitions.forEach((definition) => {
    const paramKey = definition.queryParam;
    if (!paramKey.includes(buildCFApiParamPrefix())) {
      // Manage normal filters
      const param = params[paramKey];
      if (param) {
        values[paramKey] = deserializeFilterValue(param, definition);
      }
    } else {
      // Manage custom fields filters
      const cfKeys = paramKey.split(buildCFApiParamPrefix());
      const cfKey = cfKeys[cfKeys.length - 1];
      const filter = customFields[cfKey as keyof typeof customFields];

      if (filter) {
        values[paramKey] = deserializeFilterValue(filter, definition);
      }
    }
  });

  return values;
}

export function collectionViewFromQueryParams(
  qp: TQueryParams
): Partial<ICollectionViewSerialised> {
  const { pageSize, filters, sortBy, groupBy, v } = qp;
  let { args } = qp;

  try {
    args = args ? JSON.parse(args) : undefined;
  } catch (error) {
    args = undefined;
  }

  return {
    id: v ?? 'default',
    filters: filters ? collectionFiltersFromQueryParams(filters) : undefined,
    sortBy: sortBy ? collectionSortByFromQueryParams(sortBy) : undefined,
    groupBy: groupBy ? stringListToArray(groupBy) : undefined,
    args,
    pageSize: pageSize ? parseNumber(pageSize) : DEFAULT_PAGE_SIZE
  };
}

type TArgsWithContent = ICollectionArgs & { content?: unknown };

export function collectionViewToQueryParams<TArgs extends TArgsWithContent = TArgsWithContent>(
  context: {
    id?: string;
    filters?: ICollectionFilter[];
    sortBy?: ICollectionSort[];
    groupBy?: string[];
    pageSize?: number;
    args?: TArgs;
  },
  queryParams: TQueryParams,
  collectionDefinition: ICollectionViewDefinition
) {
  const { page } = queryParams;
  const { filters, sortBy, pageSize, groupBy, args, id } = context;

  const filtersDefsByProp = indexBy(collectionDefinition.filters, 'queryParam');
  let cvSortBy: ICollectionSort[] = sortBy ?? [];
  const cvFilters = indexByFn(filters as ICollectionFilter[], (f) => f.property);

  const newQP = withoutAttributes(
    queryParams,
    WITHOUT_ATTRIBUTES_ARRAY.concat(COLLECTION_VIEW_QP_KEYS)
  );

  for (const key of Object.keys(newQP)) {
    if (key === 'page' || key === 'v') {
      continue;
    } else if (key === 'sort' && queryParams[key]) {
      cvSortBy = collectionSortByFromQueryParams(queryParams[key]);
    } else {
      const { comparator, values, enabled } = deserializeFilterValue(
        newQP[key]!,
        filtersDefsByProp[key]
      ) as TFilterValue;
      if (enabled && values.length) {
        cvFilters[key] = { comparator, values: values, property: key };
      }
    }
    newQP[key] = undefined;
  }

  const undefinedIfEmpty = (str?: any) => (isEmpty(str) ? undefined : `${str}`);

  const qpPage = `${parseNumber(page) ?? 1}`;
  const qpPageSize = `${parseNumber(queryParams['pageSize']) ?? DEFAULT_PAGE_SIZE}`;

  const cv = {
    ...newQP,
    v: id === 'default' ? undefined : id,
    page,
    pageSize: undefinedIfEmpty(pageSize ?? DEFAULT_PAGE_SIZE),
    filters: undefinedIfEmpty(collectionFiltersToQueryParams(Object.values(cvFilters))),
    groupBy: undefinedIfEmpty(serializeArrayValues(groupBy ?? [])),
    sortBy: undefinedIfEmpty(collectionSortByToQueryParams(cvSortBy)),
    args: undefinedIfEmpty(JSON.stringify(args?.content ?? args ?? {}))
  };

  if (
    qpPage !== '1' &&
    (cv.sortBy !== queryParams['sortBy'] ||
      cv.pageSize !== qpPageSize ||
      cv.filters !== queryParams['filters'] ||
      cv.args !== queryParams['args'] ||
      cv.groupBy !== queryParams['groupBy'])
  ) {
    cv.page = '1';
  }

  return cv;
}

export function getFiltersParamsFromCollectionView(cv: CollectionView | ICollectionViewSerialised) {
  const filters: Record<string, string> = {};
  const customFields: Record<string, string> = {};

  const buildFilter = (filter: ICollectionFilter) =>
    buildFilterQuery([filter.comparator, serializeArrayValues(filter.values)] as TArrayValue[]);

  if (cv.filters) {
    cv.filters.forEach((filter) => {
      const { property } = filter;

      if (property.includes(buildCFApiParamPrefix())) {
        const cfKeys = property.split(buildCFApiParamPrefix());
        const cfParam = cfKeys[cfKeys.length - 1];
        customFields[cfParam] = buildFilter(filter);
      } else {
        filters[property] = buildFilter(filter);
      }
    });
  }
  filters.customFields = JSON.stringify(customFields);
  return filters;
}

export function getFiltersFromQueryParams(params: TQueryParams, definitions: TFilterDefinition[]) {
  const filters: Record<string, string> = {};
  const { customFields = {} } = params;

  for (const definition of definitions) {
    const paramKey = definition.queryParam;

    if (!paramKey.includes(buildCFApiParamPrefix())) {
      // Manage normal filters
      if (params[paramKey]) {
        filters[definition.apiParam] = params[paramKey] as string;
      }
    } else {
      // Manage custom fields filters
      const cfKeys = paramKey.split(buildCFApiParamPrefix());
      const cfKey = cfKeys[cfKeys.length - 1];
      const filter = customFields[cfKey as keyof typeof customFields];

      if (filter) {
        filters[definition.apiParam] = filter;
      }
    }
  }

  return filters;
}

/**
 * Utility function for calculating an offset from a page number and limit
 */
export function offsetFromPage(page: number, limit: number) {
  // avoid page numbers to be trolled i.e.: page=string, page=-1, page=1.23
  page = isNaN(page) ? 1 : Math.floor(Math.abs(page));
  // page=1 will result into offset 0, page=2 will result into offset 10 and so on
  return (page - 1) * limit;
}

/**
 * Utility function for calculating the page limit of a API collection
 */
export function pageLimit(pageSize: number) {
  return Math.floor(Math.abs(pageSize));
}
/**
 * Converts a string to an array of values
 *
 * @param {string} str A string value formatted as an array
 * @returns {string[]} An array of values
 */
export function stringListToArray(str?: string) {
  return isPresent(str) ? str!.split(DEFAULT_FILTER_ARRAY_SEPARATOR) : [];
}

export function collectionFiltersFromQueryParams(str?: string): ICollectionFilter[] {
  const filterStrings = str ? str.split(COLLECTION_FILTER_SEPARATOR) : [];
  return filterStrings.reduce((acc, fs) => {
    const [key, comparator, values] = fs.split(DEFAULT_FILTERS_SEPARATOR);
    if (values.length) {
      acc.push({
        comparator: comparator as TComparator,
        property: key,
        values: values ? values.split(DEFAULT_FILTER_ARRAY_SEPARATOR) : []
      });
    }
    return acc;
  }, [] as ICollectionFilter[]);
}

export function collectionFiltersToQueryParams(filters: ICollectionFilter[]): string {
  return filters
    .reduce((acc, f) => {
      if (f.values.length) {
        acc.push(
          `${f.property}${DEFAULT_FILTERS_SEPARATOR}${
            f.comparator
          }${DEFAULT_FILTERS_SEPARATOR}${serializeArrayValues(f.values)}`
        );
      }
      return acc;
    }, [] as string[])
    .join(COLLECTION_FILTER_SEPARATOR);
}

export function collectionSortByFromQueryParams(str?: string): ICollectionSort[] {
  return stringListToArray(str).map((sb) => {
    const prop = sb.split('-');
    return { property: prop[prop.length - 1], asc: prop.length === 1 };
  });
}

export function collectionSortByToQueryParams(str: ICollectionSort[] = []): string {
  return serializeArrayValues(str.map((s) => `${s.asc ? '' : '-'}${s.property}`));
}

export function beforeQueryWorkshop(workshopType: string, warehouseFilter?: string) {
  return (query: IJsonApiQuery) => {
    const filter = query.filter ?? {};
    filter.workshopType = safeEqFilter(workshopType);

    if (warehouseFilter) {
      filter.warehouseId = safeEqFilter(warehouseFilter);
    }

    return { ...query, filter };
  };
}
