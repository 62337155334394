// import { defaultErrorHandler } from 'volta/utils/error-utils';

import { debug } from '@ember/debug';

export default function missingMessage(key: string, locales: string[]): string {
  // TODO: Try to differentiate between loading translations and real missing ones. Maybe by testing it back-end ?
  // defaultErrorHandler(`translation: '${key}' on locale: '${locales.join(', ')}' was not found.`);
  debug(`translation: '${key}' on locale: '${locales.join(', ')}' was not found.`);
  return `Missing translation: ${key}`;
}
