import type { BufferedChangeset } from 'ember-changeset/types';

import CollectionView, {
  collectionFiltersToFilterValues,
  viewIsLocked
} from 'volta/models/collection-view';
import BvFlashService from 'volta/services/bv-flash';

import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import { ICollectionViewSortListArgs } from './sort-list';

import type { IBvTableColumn } from '../bv-table';

import type IntlService from 'ember-intl/services/intl';
import type {
  ICollectionFilter,
  ICollectionGroupOption,
  ICollectionProperty,
  ICollectionSortOption,
  ICollectionViewDefinition,
  TCollectionType
} from 'volta/models/types/collection-view';
import type SessionUserService from 'volta/services/session-user';

const ICON_FROM_TYPE: Record<TCollectionType, string> = {
  TABLE: 'table-view',
  KANBAN: 'kanban-view',
  LIST: 'list',
  TIMELINE: 'timeline-view'
};

interface IArgs extends ICollectionViewSortListArgs {
  queryParams: TQueryParams;
  collectionDefinition: ICollectionViewDefinition;
  selectedViewId?: string;
  selectedView?: CollectionView;
  views?: CollectionView[];
  createViewChangeset?: BufferedChangeset;
  editViewChangeset?: BufferedChangeset & CollectionView;
  sortOptions: ICollectionSortOption[];
  groupByOptions: ICollectionGroupOption[];
  sorts: IBvTableColumn[];
  /**
   * Bespoke metadata settings object injected in the custom component args
   */
  metadata?: object;
  onPropertyChange: (props: ICollectionProperty[]) => void;
  onGroupByChange: (props: ICollectionGroupOption[]) => void;
  onGroupByOptionsReorder: (props: ICollectionGroupOption[]) => void;
  sendCollectionQueryParams: () => void;
  onInitCreate: () => void;
  selectView: (viewId: string) => void;
  onCreate: () => void;
  onDuplicate: () => void;
  onDelete: () => void;
  onCancelCreation: () => void;
  onSearch: (values: TFilterValues, search: string) => void;
}

export default class CollectionViewComponent extends Component<IArgs> {
  @service sessionUser!: SessionUserService;
  @service intl!: IntlService;
  @service bvFlash!: BvFlashService;

  @tracked settingsSection = 'general';
  @tracked editModalIsOpened = false;
  @tracked createModalIsOpened = false;
  @tracked confirmDeleteModalIsOpened = false;

  get sortedViews(): CollectionView[] {
    const { views } = this.args;
    if (!views) {
      return [];
    }
    return views.sort((a, b) => {
      return !b.id ? 1 : Number(b.isLocked) - Number(a.isLocked);
    });
  }

  get isGroupMin() {
    const { groupMin } = this.args.collectionDefinition;
    if (!groupMin) {
      return false;
    }

    return this.args.groupByOptions.filter((option) => option.selected).length <= groupMin;
  }

  get filterValues() {
    const showForTags: ICollectionFilter[] = this.args.editViewChangeset?.get('filters') ?? [];
    return collectionFiltersToFilterValues(showForTags.filter((f) => f.property !== 'q'));
  }

  get viewIsLocked() {
    if (this.args.selectedView) {
      return viewIsLocked(this.args.selectedView, this.sessionUser.userId);
    }
    return false;
  }

  get selectedSortOptions() {
    return this.args.sortOptions.filter((o) => o.selected === true);
  }

  get filters(): ICollectionFilter[] {
    return this.args.editViewChangeset?.get('filters') ?? [];
  }

  @cached
  get properties(): ICollectionProperty[] {
    return this.args.editViewChangeset?.get('properties') ?? [];
  }

  get showedTagsFilters() {
    return this.filters.filter((f) => f.property !== 'q');
  }

  get user() {
    return this.sessionUser.user;
  }

  get userId() {
    return this.user!.id;
  }

  get sortDirectionGlyph() {
    const glyphs = [
      ...new Set(this.selectedSortOptions.map((o) => (!o.asc ? 'arrow-up' : 'arrow-down')))
    ];
    return glyphs.length === 2 ? 'import-export' : glyphs[0];
  }

  get selectedGroups() {
    return this.args.groupByOptions.filter((g) => g.selected);
  }

  get otherGroups() {
    return this.args.groupByOptions.filter((g) => !g.selected);
  }

  // Actions

  @action
  openCreateModal() {
    this.args.onInitCreate();
    this.settingsSection = 'general';
    this.createModalIsOpened = true;
  }

  @action
  closeCreateModal() {
    this.args.onCancelCreation();
    this.createModalIsOpened = false;
  }

  @action
  openEditModal(section: string = 'general') {
    this.settingsSection = section;
    this.editModalIsOpened = true;
  }

  @action
  handleViewDeleted() {
    if (this.args.views?.length !== 1) {
      this.args.onDelete();
      this.editModalIsOpened = false;
      this.confirmDeleteModalIsOpened = false;
    }
  }

  @action
  handleGroupsDrag([options]: [ICollectionGroupOption[]]) {
    return this.args.onGroupByOptionsReorder?.(options);
  }

  @action
  handleRemoveGroup(group: ICollectionGroupOption) {
    return this.args.onGroupByOptionsReorder?.(
      this.args.groupByOptions.filter((g) => g.value != group.value)
    );
  }

  @action
  async handleCopyLinkToView() {
    await navigator.clipboard.writeText(window.location.href);
    this.bvFlash.success(this.intl.t('copied'));
  }

  // Helpers

  iconFromType = (view: CollectionView) => {
    const { types } = this.args.collectionDefinition;
    if (types.length < 2) {
      return undefined;
    }
    return view && ICON_FROM_TYPE[view.collectionType];
  };

  searchValue = (filters: ICollectionFilter[]) => {
    const q = filters.find((filter) => filter.property === 'q');
    if (!q) {
      return '';
    }
    return q.values[0];
  };
}
