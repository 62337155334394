import { IJsonApiQuery } from './../../../utils/api/jsonapi-types';
import Component from '@glimmer/component';

export interface ISkusWarehouseFilterDefinitionArgs {
  skuId?: string;
  horizontalPosition?: 'left' | 'right' | 'auto';
  beforeQuery?: (query: IJsonApiQuery) => IJsonApiQuery;
}

export default class SkusWarehouseFilter extends Component<
  IFilterArgs<ISkusWarehouseFilterDefinitionArgs>
> {}
