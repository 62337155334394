import _isNil from 'lodash/isNil';
import moment from 'moment-timezone';

import type { TBufferZone } from 'volta/models/constants/buffer-zones';

export type THue =
  | 'white'
  | 'black'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'info'
  | 'danger'
  | 'critical'
  | 'gray'
  | 'blue'
  | 'green'
  | 'yellow'
  | 'orange'
  | 'red'
  | 'purple'
  | 'teal'
  | 'indigo'
  | 'severe';

export type TIntent = 'danger' | 'success' | 'warning' | 'info';

export type TVariation = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

export type TSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export type TVariations = Record<TVariation, string> | string;

export type THueVariationString = `${THue}${`-${TVariation}` | ''}`;

export const ColorScale: Record<THue, TVariations> = {
  gray: {
    0: 'rgb(246, 248, 250)',
    1: 'rgb(234, 238, 242)',
    2: 'rgb(208, 215, 222)',
    3: 'rgb(175, 184, 193)',
    4: 'rgb(140, 149, 159)',
    5: 'rgb(110, 119, 129)',
    6: 'rgb(87, 96, 106)',
    7: 'rgb(66, 74, 83)',
    8: 'rgb(50, 56, 63)',
    9: 'rgb(36, 41, 47)'
  },
  blue: {
    0: 'rgb(230, 244, 249)',
    1: 'rgb(172, 219, 236)',
    2: 'rgb(115, 194, 223)',
    3: 'rgb(57, 169, 209)',
    4: 'rgb(0, 144, 196)',
    5: 'rgb(0, 124, 168)',
    6: 'rgb(0, 103, 140)',
    7: 'rgb(0, 83, 113)',
    8: 'rgb(0, 62, 85)',
    9: 'rgb(0, 42, 57)'
  },
  green: {
    0: 'rgb(241, 249, 245)',
    1: 'rgb(209, 235, 223)',
    2: 'rgb(177, 221, 201)',
    3: 'rgb(146, 207, 179)',
    4: 'rgb(114, 193, 157)',
    5: 'rgb(92, 172, 135)',
    6: 'rgb(71, 148, 113)',
    7: 'rgb(53, 123, 91)',
    8: 'rgb(37, 96, 69)',
    9: 'rgb(23, 66, 46)'
  },
  yellow: {
    0: 'rgb(255, 250, 238)',
    1: 'rgb(255, 240, 200)',
    2: 'rgb(255, 230, 162)',
    3: 'rgb(254, 219, 124)',
    4: 'rgb(254, 209, 86)',
    5: 'rgb(218, 179, 74)',
    6: 'rgb(182, 150, 62)',
    7: 'rgb(146, 120, 49)',
    8: 'rgb(110, 90, 37)',
    9: 'rgb(74, 61, 25)'
  },
  orange: {
    0: 'rgb(254, 244, 236)',
    1: 'rgb(251, 220, 194)',
    2: 'rgb(249, 196, 151)',
    3: 'rgb(247, 171, 109)',
    4: 'rgb(244, 147, 66)',
    5: 'rgb(209, 126, 57)',
    6: 'rgb(175, 105, 47)',
    7: 'rgb(140, 84, 38)',
    8: 'rgb(105, 64, 29)',
    9: 'rgb(71, 43, 19)'
  },
  red: {
    0: 'rgb(253, 239, 236)',
    1: 'rgb(248, 202, 193)',
    2: 'rgb(242, 165, 151)',
    3: 'rgb(237, 129, 108)',
    4: 'rgb(232, 92, 65)',
    5: 'rgb(199, 79, 56)',
    6: 'rgb(166, 66, 47)',
    7: 'rgb(133, 53, 37)',
    8: 'rgb(100, 40, 28)',
    9: 'rgb(67, 27, 19)'
  },
  purple: {
    0: 'rgb(245, 240, 252)',
    1: 'rgb(223, 207, 244)',
    2: 'rgb(201, 173, 237)',
    3: 'rgb(178, 140, 229)',
    4: 'rgb(156, 106, 222)',
    5: 'rgb(134, 91, 191)',
    6: 'rgb(112, 76, 159)',
    7: 'rgb(90, 61, 127)',
    8: 'rgb(67, 46, 96)',
    9: 'rgb(45, 31, 64)'
  },
  teal: {
    0: 'rgb(237, 250, 252)',
    1: 'rgb(202, 241, 246)',
    2: 'rgb(150, 226, 238)',
    3: 'rgb(97, 212, 229)',
    4: 'rgb(38, 195, 220)',
    5: 'rgb(31, 171, 193)',
    6: 'rgb(26, 140, 158)',
    7: 'rgb(20, 109, 123)',
    8: 'rgb(14, 78, 88)',
    9: 'rgb(8, 47, 53)'
  },
  indigo: {
    0: 'rgb(239, 240, 249)',
    1: 'rgb(202, 207, 236)',
    2: 'rgb(165, 173, 223)',
    3: 'rgb(129, 140, 209)',
    4: 'rgb(92, 106, 196)',
    5: 'rgb(79, 91, 168)',
    6: 'rgb(66, 76, 140)',
    7: 'rgb(53, 61, 113)',
    8: 'rgb(40, 46, 85)',
    9: 'rgb(27, 31, 57)'
  },
  white: 'rgb(255,255,255)',
  black: 'rgb(27, 31, 36)',
  primary: 'rgb(38, 195, 220)',
  secondary: 'rgb(0, 144, 196)',
  success: 'rgb(114, 193, 157)',
  warning: 'rgb(254, 209, 86)',
  info: 'rgb(38, 195, 220)',
  danger: 'rgb(232, 92, 65)',
  critical: 'rgb(166, 66, 47)',
  severe: 'rgb(244, 147, 66)'
};

export const createThemeColorGetter =
  (light?: string, dark?: string) =>
  (theme: 'light' | 'dark' = 'light') =>
    (theme === 'dark' ? dark : light) ?? light;

const bvSurfaceSubdued = createThemeColorGetter(getColor('gray-0'), getColor('gray-8'));
const bvText = createThemeColorGetter(getColor('gray-9'), getColor('gray-1'));
const bvTextSubdued = createThemeColorGetter(getColor('gray-5'), getColor('gray-4'));
const bvBorder = createThemeColorGetter(getColor('gray-9', 0.15), getColor('gray-1', 0.3));
const bvBorderLight = createThemeColorGetter(getColor('gray-9', 0.1), getColor('gray-3', 0.2));

export const SemanticColors = {
  bvSurfaceSubdued,
  bvText,
  bvTextSubdued,
  bvBorder,
  bvBorderLight
};

export function colorScale(hue: THue, variation?: TVariation, opacity?: number) {
  const color = ColorScale[hue];
  if (color) {
    const rgb = typeof color === 'string' ? color : color[variation ?? '4'];
    if (rgb && typeof opacity === 'number') {
      return rgb.replace('rgb(', 'rgba(').replace(')', `,${Math.min(opacity, 1)})`);
    }
    return rgb;
  }
  return undefined;
}

export function getColor(color: THueVariationString, opacity?: number) {
  const [hue, variation] = color.split('-') as [THue, TVariation | undefined];
  return colorScale(hue, variation, opacity);
}

export function colorPalette(hue: THue, variation?: TVariation, opacity?: number) {
  const suffix = _isNil(opacity) ? (variation ? `-${variation}` : '') : '-rgb';
  return `var(--bv-color-${hue}${`${suffix}`})`;
}

export function getColorCssVar(color: THueVariationString, variation?: TVariation, alpha?: number) {
  const [hue, exitingVariation] = color.split('-') as [THue, TVariation | undefined];
  return colorPalette(hue, exitingVariation ?? variation, alpha);
}

export function getCssVarValue(varName: string, prefix: string = 'bv') {
  return getComputedStyle(document.documentElement).getPropertyValue(
    `--${prefix ? prefix + '-' : ''}${varName}`
  );
}

/**
 * Translates the buffer zone to a criticality english word
 *
 * @param zone Buffer zone
 */
export function getBufferZoneCriticality(zone?: number | string): TBufferZone | undefined {
  const toStr = `${zone}`;
  if (toStr === '0') {
    return 'critical';
  } else if (toStr === '1') {
    return 'high';
  } else if (toStr === '2') {
    return 'medium';
  } else if (toStr === '3') {
    return 'low';
  } else if (toStr === '4') {
    return 'otog';
  }
  return undefined;
}

/**
 * Translates the alert crticality to a color
 *
 * @param criticality alert criticality
 */
export function colorForAlertCriticality(criticality: string | number): THue | undefined {
  const toStr = `${criticality}`;
  if (toStr === '0') {
    return 'red';
  } else if (toStr === '1') {
    return 'orange';
  } else if (toStr === '2') {
    return 'green';
  } else if (toStr === '3') {
    return 'blue';
  }
  return undefined;
}

export function colorForCategory(cat?: string): THueVariationString | undefined {
  const catLower = cat ? `${cat}`.toLowerCase() : null;

  if (!catLower) {
    return undefined;
  }

  if (['low', 'short', 'accepted', 'launched', 'done', 'ok', 'success'].includes(catLower)) {
    return 'green';
  }
  if (['medium'].includes(catLower)) {
    return 'yellow';
  }
  if (['qc', 'draft', 'warning', 'supplier_draft'].includes(catLower)) {
    return 'orange';
  }
  if (['critical', 'late'].includes(catLower)) {
    return 'red-6';
  }
  if (['high', 'long', 'rejected', 'blocked', 'error', 'danger'].includes(catLower)) {
    return 'red';
  }
  if (['ready', 'new'].includes(catLower)) {
    return 'blue';
  }
  if (['committed'].includes(catLower)) {
    return 'indigo';
  }
  if (['sent'].includes(catLower)) {
    return 'purple';
  }
  if (['finished', 'waiting'].includes(catLower)) {
    return 'gray-3';
  }
  if (['otog', 'projected', 'early', 'info', 'highlight'].includes(catLower)) {
    return 'teal';
  }
  if (['projected_firm'].includes(catLower)) {
    return 'gray-9';
  }

  return undefined;
}

export function btnGroupOptions(kv: Record<string, string>) {
  return Object.keys(kv).map((key) => {
    return {
      key,
      label: kv[key]
    };
  });
}

export function classNames(classes: Array<any> = []): string {
  return [...new Set(classes.filter(Boolean))].sort().join(' ');
}

/**
 * Gives date color criticality
 * @param date Date
 * @param constraint Other conditions if needed
 * @returns criticality
 */
export function dateCriticality(date: moment.MomentInput, constraint: boolean = true) {
  const isToday = date && moment(date).isSame(new Date(), 'day');
  const isPast = date && moment(date).isBefore(new Date());

  if (isToday && constraint) {
    return 'warning';
  } else if (isPast && constraint) {
    return 'danger';
  } else if (date && constraint) {
    return 'success';
  } else {
    return undefined;
  }
}

export function sanitizeCustomProperties(
  styles: Record<string, string>
): Record<string, string> | undefined {
  const nonNullValues = Object.entries(styles).filter(([_, value]) => value != null);

  return nonNullValues.length ? Object.fromEntries(nonNullValues) : undefined;
}
