import Controller from '@ember/controller';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

export default class ApplicationController extends Controller {
  @service router!: RouterService;

  @action
  public transitionToLoginRoute() {
    this.router.transitionTo('login');
  }
}
