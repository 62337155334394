import moment from 'moment-timezone';

import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface IDateFilterArgs extends IFilterArgs<undefined> {}

export default class DateFilter extends Component<IDateFilterArgs> {
  @tracked left?: string = this.values?.[0];
  @tracked right?: string = this.values?.[1];

  placeholder = moment.localeData().longDateFormat('L');

  get values() {
    return this.args.values ?? [];
  }

  get leftValue() {
    return this.left ?? this.value;
  }

  get value() {
    return this.values?.length ? this.values[0] : undefined;
  }

  get rightValue() {
    return this.right ?? (this.values?.length === 2 ? this.values[1] : undefined);
  }

  get dateFilter() {
    return {
      startDate: isPresent(this.left) ? moment(this.left).toDate() : undefined,
      endDate: isPresent(this.right) ? moment(this.right).toDate() : undefined
    };
  }

  @action
  valuesDidChange({ startDate, endDate }: { startDate?: Date; endDate?: Date }) {
    const values: string[] = [];
    if (startDate) {
      values.push(this.formatValue(startDate)!);
    }
    if (endDate) {
      values.push(this.formatValue(endDate)!);
    }

    if (values.length === 2) {
      this.args.onChange?.(values);
    }
  }

  @action
  valueDidChange(newValue?: Date) {
    const values = isPresent(newValue) ? [this.formatValue(newValue) as string] : [];
    this.args.onChange?.(values);
  }

  @action
  handleUpdateValues(_el: HTMLElement, [[left, right] = []]: [string[] | undefined]) {
    if (this.left !== left) {
      this.left = left;
    }
    if (this.right !== right) {
      this.right = right;
    }
  }

  private formatValue(value?: Date) {
    return isPresent(value) ? moment(value).format('YYYY-MM-DD') : undefined;
  }
}
