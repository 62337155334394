import moment from 'moment-timezone';
import { IBvTableColumn } from 'volta/components/bv-table';
// @ts-ignore
import BufferIconCell from 'volta/components/cell/buffer-icon-cell';
// @ts-ignore
import CodeDescCell from 'volta/components/cell/code-desc-cell';
// @ts-ignore
import LabelCell from 'volta/components/cell/label-cell';
// @ts-ignore
import PlanningPriorityCell from 'volta/components/cell/planning-priority-cell';
// @ts-ignore
import StatusCell from 'volta/components/cell/status-cell';
// @ts-ignore
import AlertOptionComponent from 'volta/components/filters/alert-option';
// @ts-ignore
import BufferZoneOptionComponent from 'volta/components/filters/buffer-zone-option';
import DateFilter from 'volta/components/filters/date-filter';
import InputFilter from 'volta/components/filters/input-filter';
import PriorityFilter from 'volta/components/filters/priority-filter';
import SelectFilter from 'volta/components/filters/select-filter';
import SkusPartTypeFilter from 'volta/components/filters/skus-part-type-filter';
import SkusSupplierFilter from 'volta/components/filters/skus-supplier-filter';
import SkusWarehouseFilter from 'volta/components/filters/skus-warehouse-filter';
import SkusWorkshopFilter from 'volta/components/filters/skus-workshop-filter';
import StatusFilter from 'volta/components/filters/status-filter';
import TokenFilter from 'volta/components/filters/token-filter';
import POWorkbenchCvArgsEditor from 'volta/components/po-workbench/collection-view-args-editor';
import POUserFilter from 'volta/components/po-workbench/filters/user-filter';
import CollectionView, {
  CollectionViewEvents,
  CollectionViewResourceName,
  columnsToProps,
  columnsToSortOptions
} from 'volta/models/collection-view';
import { AcquisitionCodesOptions } from 'volta/models/constants/acquisition-codes';
import { BufferZonesOptions } from 'volta/models/constants/buffer-zones';
import { PlanningMethodologiesOptions } from 'volta/models/constants/planning-methodologies';
import { buildCustomFieldColumn } from 'volta/models/custom-field-definition';
import PlanningOrder, {
  POValidationStatusesArray,
  SchedulingAlertOptions,
  Statuses,
  SupplierPortalStatuses,
  SyncAlertOptions,
  VisibleStatusOptions
} from 'volta/models/planning-order';
import { POGroupResourceName } from 'volta/models/po-group';
import {
  ICollectionDefinitionBuilder,
  ICollectionSortOption,
  ICollectionViewDefinition,
  ICreateCollectionViewCmd
} from 'volta/models/types/collection-view';
import SessionUserService from 'volta/services/session-user';
import { normalize } from 'volta/utils/api/serialize-and-push';
import { indexByFn } from 'volta/utils/array-utils';
import {
  beforeQueryWorkshop,
  collectionFiltersFromQueryParams,
  serializeArrayValues
} from 'volta/utils/filters-utils';

import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { ICollectionFilter, IPOWorkbenchCollectionArgs } from '../models/types/collection-view.d';

import type CustomFieldDefinitionsService from 'volta/services/custom-field-definitions-service';
import type IntlService from 'ember-intl/services/intl';
import type LabelService from 'volta/services/label-service';
import type StoreService from 'volta/services/store';
import type { IJsonApiQuery, SingleResourceDoc } from 'volta/utils/api/jsonapi-types';
import type RouterService from '@ember/routing/router-service';
import type EventStreamService from './event-stream';
import { restartableTask } from 'ember-concurrency';

export const DefaultSettings: Required<IPOWorkbenchCollectionArgs> = {
  showCapacity: false,
  showCapacityChart: true,
  codeDescMode: ['desc'],
  showSkuDesc: true,
  dateField: 'dueDate'
};

export const DEFAULT_STATUSES = [
  Statuses.LAUNCHED.value,
  Statuses.COMMITTED.value,
  Statuses.SENT.value,
  Statuses.DRAFT.value
];

export const DEFAULT_STATUSES_SUPPLIER = [
  Statuses.LAUNCHED.value,
  Statuses.COMMITTED.value,
  Statuses.SENT.value
];
export default class POGroupsCollectionDefinitionBuilder
  extends Service
  implements ICollectionDefinitionBuilder
{
  @service store!: StoreService;
  @service customFieldDefinitionsService!: CustomFieldDefinitionsService;
  @service intl!: IntlService;
  @service labelService!: LabelService;
  @service sessionUser!: SessionUserService;
  @service router!: RouterService;
  @service eventStream!: EventStreamService;

  @tracked myCollectionViews?: CollectionView[];

  get isSup() {
    return this.sessionUser.isSupplierUser;
  }

  constructor() {
    super(...arguments);
    this.eventStream.addHandler(
      CollectionViewResourceName.plural,
      CollectionViewEvents.CollectionViewCreated,
      this._handleCollectionViewCreated,
      this
    );
    this.eventStream.addHandler(
      CollectionViewResourceName.plural,
      CollectionViewEvents.CollectionViewUpdated,
      this._handleCollectionViewUpdated,
      this
    );
    this.eventStream.addHandler(
      CollectionViewResourceName.plural,
      CollectionViewEvents.CollectionViewDeleted,
      this._handleCollectionViewDeleted,
      this
    );
  }

  buildColumns() {
    const { intl, customFieldDefinitionsService, isSup } = this;
    const cols: IBvTableColumn[] = [];

    const forAll = (col: IBvTableColumn) => {
      cols.push(col);
    };

    const notForSupplier = (col: IBvTableColumn) => {
      if (!isSup) {
        cols.push(col);
      }
    };

    forAll({
      columnName: intl.t('priority'),
      sortKey: 'priority',
      valuePath: 'priority',
      width: 50,
      isReorderable: true,
      isResizable: false
    });

    forAll({
      columnName: intl.t('part'),
      sortKey: 'skuDescription',
      valuePath: 'sku',
      width: 250,
      minWidth: 100,
      cellComponent: CodeDescCell,
      isFixed: 'left',
      color: 'blue',
      format: (model: PlanningOrder) => {
        return {
          link: 'protected.skus.sku',
          linkParam: model.id,
          code: model.skuInfo.part.code,
          description: isSup ? model.skuInfo.supplierSkuDescription : model.skuInfo.part.description
        };
      }
    });

    notForSupplier({
      columnName: intl.t('warehouse'),
      sortKey: 'warehouseCode',
      valuePath: 'warehouse',
      width: 100,
      minWidth: 50,
      isReorderable: true,
      isResizable: true,
      isGroupable: !isSup,
      color: 'green',
      cellComponent: LabelCell,
      format: ({ skuInfo }: PlanningOrder) => {
        return {
          label: skuInfo.warehouse.code,
          color: 'green',
          iconRight: 'link-outline',
          tooltipText: skuInfo.warehouse.description,
          onClick: !isSup
            ? () => this.router.transitionTo('protected.warehouse', skuInfo.warehouse.warehouseId)
            : undefined
        };
      }
    });
    notForSupplier({
      columnName: intl.t('partType'),
      sortKey: 'partType',
      valuePath: 'partType',
      width: 100,
      minWidth: 50,
      isReorderable: true,
      isResizable: true,
      isGroupable: !isSup,
      color: 'indigo',
      cellComponent: LabelCell,
      format: (order: PlanningOrder) => {
        return {
          color: 'indigo',
          label: order.skuInfo.partType?.code,
          tooltipText: order.skuInfo.partType?.description
        };
      }
    });

    forAll({
      columnName: intl.t('status'),
      sortKey: 'status',
      valuePath: 'status',
      minWidth: 60,
      isGroupable: true,
      isStatic: true,
      cellComponent: StatusCell,
      format: (po: PlanningOrder) => {
        return po.statusOption;
      }
    });
    notForSupplier({
      columnName: intl.t('supplier'),
      sortKey: 'supplierCode',
      valuePath: 'supplier',
      width: 100,
      minWidth: 50,
      isReorderable: true,
      isResizable: true,
      isGroupable: !isSup,
      color: 'primary',
      cellComponent: LabelCell,
      format: (po: PlanningOrder) => {
        return {
          label: po.supplierInfo?.code,
          color: 'primary',
          iconRight: 'link-outline',
          tooltipText: po.supplierInfo?.description,
          onClick: !isSup
            ? () =>
                po.supplierInfo &&
                this.router.transitionTo('protected.supplier', po.supplierInfo.supplierId)
            : undefined
        };
      }
    });

    notForSupplier({
      columnName: intl.t('workshop'),
      sortKey: 'workshopCode',
      valuePath: 'workshop',
      width: 100,
      minWidth: 50,
      isReorderable: true,
      isResizable: true,
      isGroupable: !isSup,
      color: 'orange',
      cellComponent: LabelCell,
      format: (po: PlanningOrder) => {
        return {
          label: po.workshopInfo?.code,
          color: 'orange',
          iconRight: 'link-outline',
          tooltipText: po.workshopInfo?.description,
          onClick: !isSup
            ? () =>
                po.workshopInfo &&
                this.router.transitionTo('protected.workshop', po.workshopInfo.workshopId)
            : undefined
        };
      }
    });

    notForSupplier({
      columnName: intl.t('deliveryWorkshop'),
      sortKey: 'deliveryWorkshopCode',
      valuePath: 'deliveryWorkshop',
      width: 100,
      minWidth: 50,
      isReorderable: true,
      isResizable: true,
      isGroupable: !isSup,
      color: 'purple',
      cellComponent: LabelCell,
      format: (po: PlanningOrder) => {
        return {
          label: po.deliveryWorkshopInfo?.code,
          color: 'purple',
          iconRight: 'link-outline',
          tooltipText: po.deliveryWorkshopInfo?.description,
          onClick: !isSup
            ? () =>
                po.deliveryWorkshopInfo &&
                this.router.transitionTo('protected.workshop', po.deliveryWorkshopInfo.workshopId)
            : undefined
        };
      }
    });
    notForSupplier({
      columnName: intl.t('planningMethodology'),
      valuePath: 'methodology',
      sortKey: 'methodology',
      width: 60,
      cellComponent: BufferIconCell,
      isReorderable: true,
      isResizable: true,
      isGroupable: !isSup,
      isStatic: true,
      format: (po: PlanningOrder) => {
        return po;
      }
    });

    notForSupplier({
      columnName: intl.t('acqCodeShort'),
      valuePath: 'acqCode',
      sortKey: 'acqCode',
      width: 80,
      isReorderable: true,
      isResizable: true,
      isGroupable: !isSup,
      cellComponent: LabelCell,
      format: (po: PlanningOrder) => {
        return {
          label: intl.t(`acqCodes.${po.acqCode || 'UNKNOWN'}`)
        };
      }
    });

    forAll({
      columnName: intl.t('reorderQty'),
      sortKey: 'reorderQty',
      valuePath: 'reorderQty',
      width: 75,
      textAlign: 'right',
      isReorderable: true,
      isResizable: true,
      isStatic: true,
      classNames: 'u-text-mono',
      format: (po: PlanningOrder) => {
        return this.intl.formatNumber(po.reorderQty);
      }
    });

    forAll({
      columnName: intl.t('dueDate'),
      sortKey: 'dueDate',
      valuePath: 'dueDate',
      width: 75,
      isReorderable: true,
      isResizable: true,
      isStatic: true,
      format: (po: PlanningOrder) => {
        return moment(po.dueDate).format('L');
      }
    });
    forAll({
      columnName: intl.t('deliveryDate'),
      sortKey: 'deliveryDate',
      valuePath: 'deliveryDate',
      width: 75,
      isReorderable: true,
      isResizable: true,
      isStatic: true,
      format: (po: PlanningOrder) => {
        return moment(po.deliveryDate).format('L');
      }
    });
    forAll({
      columnName: intl.t('reorderDate'),
      sortKey: 'reorderDate',
      valuePath: 'reorderDate',
      width: 75,
      isReorderable: true,
      isResizable: true,
      isStatic: true,
      format: (po: PlanningOrder) => {
        return moment(po.reorderDate).format('L');
      }
    });
    forAll({
      columnName: intl.t('createdBy'),
      sortKey: 'createdBy',
      valuePath: 'createdBy',
      width: 40,
      isReorderable: true,
      isResizable: true,
      isStatic: true,
      format: (po: PlanningOrder) => {
        return po.createdBy;
      }
    });
    forAll({
      columnName: intl.t('orderNum'),
      sortKey: 'orderNum',
      valuePath: 'orderNum',
      width: 40,
      isReorderable: true,
      isResizable: true,
      isGroupable: true,
      color: 'yellow-6',
      format: (po: PlanningOrder) => {
        return po.orderNum;
      }
    });
    forAll({
      columnName: intl.t('externalOrderNum'),
      sortKey: 'externalOrderNum',
      valuePath: 'externalOrderNum',
      width: 40,
      isReorderable: true,
      isResizable: true,
      isGroupable: false,
      color: 'yellow-8',
      format: (po: PlanningOrder) => {
        return po.externalOrderNum;
      }
    });
    notForSupplier({
      columnName: intl.t('planningBuffer'),
      sortKey: 'planningBuffer',
      valuePath: 'planningBuffer',
      width: 120,
      isReorderable: true,
      isResizable: true,
      isGroupable: false,
      cellComponent: PlanningPriorityCell,
      format: (model: PlanningOrder) => {
        return {
          priorityName: model.priorityName,
          bufferPenetration: model.planningInfo.bufferPenetration,
          route: 'protected.po-workbench.sku',
          routeModel: model.skuId
        };
      }
    });
    forAll({
      columnName: intl.t('executionBuffer'),
      sortKey: 'executionBuffer',
      valuePath: 'executionBuffer',
      width: 120,
      isReorderable: true,
      isResizable: true,
      isGroupable: false,
      cellComponent: PlanningPriorityCell,
      format: (model: PlanningOrder) => {
        return {
          priorityName: model.executionPriorityName,
          bufferPenetration: model.executionInfo.oha,
          route: 'protected.po-workbench.sku',
          routeModel: model.skuId
        };
      }
    });

    const customFieldProps: Array<IBvTableColumn | undefined> = (
      customFieldDefinitionsService.poCustomProperties() ?? []
    ).map((def) =>
      buildCustomFieldColumn(def, (model: PlanningOrder) => {
        const dataType = def.dataType ? def.dataType.toLowerCase() : undefined;
        const { customFields } = model;

        const value = customFields ? customFields[def.property] : undefined;
        return {
          value,
          dataType
        };
      })
    );

    const skuCustomFieldProps: Array<IBvTableColumn | undefined> = (
      customFieldDefinitionsService.skusCustomProperties('sku') ?? []
    ).map((def) =>
      buildCustomFieldColumn(def, (model: PlanningOrder) => {
        const dataType = def.dataType ? def.dataType.toLowerCase() : undefined;
        const {
          skuInfo: { customFields }
        } = model;

        const value = customFields ? customFields[def.property] : undefined;
        return {
          value,
          dataType
        };
      })
    );

    return cols
      .concat(customFieldProps.filter(Boolean) as IBvTableColumn[])
      .concat(isSup ? [] : (skuCustomFieldProps.filter(Boolean) as IBvTableColumn[]));
  }

  buildFilters(queryParamsValues: TQueryParams) {
    const { store, customFieldDefinitionsService, isSup } = this;
    const { filters } = queryParamsValues;
    const cvFilters = indexByFn(
      collectionFiltersFromQueryParams(filters as string),
      (f) => f.property
    );
    const warehouseIds = cvFilters.warehouse?.values;
    const createdBy = cvFilters.createdBy?.values
      ? cvFilters.createdBy.values.join(',')
      : undefined;
    const normalDefs: Array<TFilterDefinition> = [];

    const notForSupplier = (filter: TFilterDefinition) => {
      if (!isSup) {
        normalDefs.push(filter);
      }
    };

    normalDefs.push({
      queryParam: 'status',
      apiParam: 'status',
      comparators: ['in'],
      component: StatusFilter,
      componentArgs: {
        hideCount: true,
        possibleValues: isSup
          ? VisibleStatusOptions.filter((o) => SupplierPortalStatuses.includes(o.key))
          : VisibleStatusOptions
      },
      clearDefaultValue: isSup ? DEFAULT_STATUSES_SUPPLIER : DEFAULT_STATUSES
    });

    normalDefs.push({
      queryParam: 'validationStatus',
      apiParam: 'validationStatus',
      comparators: ['in'],
      component: StatusFilter,
      componentArgs: {
        hideCount: true,
        possibleValues: POValidationStatusesArray.map((o) => {
          return {
            ...o,
            label: this.intl.t(this.sessionUser.user.isSupplier ? o.supplierLabel : o.customerLabel)
          };
        })
      }
    });
    notForSupplier({
      queryParam: 'orderType',
      apiParam: 'orderType',
      comparators: ['in', 'ni'],
      component: TokenFilter,
      componentArgs: {
        warehouseIds,
        searchFn: (query: IJsonApiQuery) => {
          return store.customQuery('planningOrders', 'orderTypes', undefined, {
            ...query,
            createdBy
          });
        }
      }
    });

    normalDefs.push({
      queryParam: 'orderNum',
      apiParam: 'orderNum',
      comparators: ['in', 'ni'],
      component: TokenFilter,
      componentArgs: {
        warehouseIds,
        searchFn: (query: IJsonApiQuery) => {
          return store.customQuery('planningOrders', 'orderNums', undefined, {
            ...query,
            createdBy
          });
        }
      }
    });

    normalDefs.push({
      queryParam: 'externalOrderNum',
      apiParam: 'externalOrderNum',
      comparators: ['in', 'ni'],
      component: TokenFilter,
      componentArgs: {
        warehouseIds,
        searchFn: (query: IJsonApiQuery) => {
          return store.customQuery('planningOrders', 'externalOrderNums', undefined, {
            ...query,
            createdBy
          });
        }
      }
    });

    notForSupplier({
      queryParam: 'partType',
      apiParam: 'partType',
      comparators: ['in', 'ni'],
      component: SkusPartTypeFilter,
      componentArgs: {}
    });
    notForSupplier({
      queryParam: 'acqCode',
      apiParam: 'acqCode',
      comparators: ['in'],
      component: SelectFilter,
      componentArgs: { possibleValues: AcquisitionCodesOptions }
    });
    normalDefs.push({
      queryParam: 'supplier',
      apiParam: 'supplier',
      comparators: ['in', 'ni'],
      hidden: isSup,
      component: SkusSupplierFilter,
      componentArgs: {}
    });
    notForSupplier({
      queryParam: 'workshop',
      apiParam: 'workshop',
      comparators: ['in', 'ni'],
      component: SkusWorkshopFilter,
      componentArgs: {
        beforeQuery: beforeQueryWorkshop(
          'WORKSHOP',
          serializeArrayValues(cvFilters.warehouse?.values ?? [])
        )
      }
    });
    notForSupplier({
      queryParam: 'deliveryWorkshop',
      apiParam: 'deliveryWorkshop',
      comparators: ['in', 'ni'],
      component: SkusWorkshopFilter,
      componentArgs: {
        beforeQuery: beforeQueryWorkshop(
          'DELIVERY',
          serializeArrayValues(cvFilters.warehouse?.values ?? [])
        )
      }
    });
    notForSupplier({
      queryParam: 'warehouse',
      apiParam: 'warehouse',
      comparators: ['in', 'ni'],
      component: SkusWarehouseFilter,
      componentArgs: {}
    });
    notForSupplier({
      queryParam: 'reorderDate',
      apiParam: 'reorderDate',
      comparators: ['eq', 'lt', 'gt', 'bt'],
      component: DateFilter,
      componentArgs: {}
    });
    normalDefs.push({
      queryParam: 'deliveryDate',
      apiParam: 'deliveryDate',
      comparators: ['eq', 'lt', 'gt', 'bt'],
      component: DateFilter,
      componentArgs: {}
    });
    notForSupplier({
      queryParam: 'dueDate',
      apiParam: 'dueDate',
      comparators: ['eq', 'lt', 'gt', 'bt'],
      component: DateFilter,
      componentArgs: {}
    });
    normalDefs.push({
      queryParam: 'q',
      apiParam: 'q',
      comparators: ['lk'],
      component: InputFilter,
      hidden: true,
      componentArgs: { inputType: 'text' }
    });
    notForSupplier({
      queryParam: 'methodology',
      apiParam: 'methodology',
      comparators: ['in'],
      component: SelectFilter,
      componentArgs: { possibleValues: PlanningMethodologiesOptions }
    });
    notForSupplier({
      queryParam: 'createdBy',
      apiParam: 'createdBy',
      comparators: ['in', 'ni'],
      component: POUserFilter,
      componentArgs: { multiple: true },
      required: true
    });
    notForSupplier({
      queryParam: 'planningBuffer',
      apiParam: 'planningBuffer',
      comparators: ['in'],
      component: SelectFilter,
      componentArgs: {
        possibleValues: BufferZonesOptions,
        itemComponent: BufferZoneOptionComponent
      }
    });
    notForSupplier({
      queryParam: 'executionBuffer',
      apiParam: 'executionBuffer',
      comparators: ['in'],
      component: SelectFilter,
      componentArgs: {
        possibleValues: BufferZonesOptions,
        itemComponent: BufferZoneOptionComponent
      }
    });
    normalDefs.push({
      queryParam: 'priority',
      apiParam: 'priority',
      comparators: ['in'],
      component: PriorityFilter,
      componentArgs: {}
    });
    notForSupplier({
      queryParam: 'syncAlert',
      apiParam: 'syncAlert',
      comparators: ['in'],
      component: SelectFilter,
      componentArgs: {
        possibleValues: SyncAlertOptions,
        itemComponent: AlertOptionComponent,
        valueProp: 'value'
      }
    });
    notForSupplier({
      queryParam: 'schedulingAlert',
      apiParam: 'schedulingAlert',
      comparators: ['in'],
      component: SelectFilter,
      componentArgs: {
        possibleValues: SchedulingAlertOptions,
        itemComponent: AlertOptionComponent,
        valueProp: 'value'
      }
    });
    normalDefs.push({
      queryParam: 'reorderQty',
      apiParam: 'reorderQty',
      comparators: ['lt', 'gt', 'bt'],
      component: InputFilter,
      componentArgs: {
        inputType: 'number'
      }
    });

    return normalDefs
      .concat(customFieldDefinitionsService.poCustomFieldsFilters())
      .concat(isSup ? [] : customFieldDefinitionsService.skusCustomFieldsFilters('sku'));
  }

  buildDefaultView(columns: IBvTableColumn[]): ICreateCollectionViewCmd {
    const { intl, isSup } = this;
    const properties = columnsToProps(columns);

    return {
      name: intl.t('collectionViewsPage.default.name', {
        resourceNamePlural: intl.t(POGroupResourceName.plural)
      }),
      description: intl.t('collectionViewsPage.default.description', {
        resourceNamePlural: intl.t(POGroupResourceName.plural)
      }),
      collectionType: 'LIST',
      entityType: POGroupResourceName.plural,
      properties,
      createdBy: this.sessionUser.userId,
      sortBy: [
        { property: isSup ? 'deliveryDate' : 'dueDate', asc: true },
        { property: 'priority', asc: false },
        { property: 'executionBuffer', asc: true },
        { property: 'sku', asc: true }
      ],
      pageSize: 100,
      groupBy: isSup ? ['orderNum'] : ['orderNum', 'warehouse', 'acqCode', 'supplier', 'workshop'],
      filters: [
        isSup
          ? { property: 'supplier', comparator: 'in', values: this.sessionUser.user.supplierIds }
          : { property: 'createdBy', comparator: 'in', values: [this.sessionUser.userId] },
        {
          property: 'status',
          comparator: 'in',
          values: isSup ? DEFAULT_STATUSES_SUPPLIER : DEFAULT_STATUSES
        }
      ].filter(Boolean) as ICollectionFilter[],
      args: isSup ? { ...DefaultSettings, dateField: 'deliveryDate' } : DefaultSettings
    };
  }

  build(queryParams: TQueryParams, sorts: ICollectionSortOption[] = []) {
    const filterDefinitions = this.buildFilters(queryParams);
    const columns = this.buildColumns();
    const defaultView = this.buildDefaultView(columns);

    const view: ICollectionViewDefinition = {
      resourceName: POGroupResourceName,
      sortLimit: 0,
      groupMin: 1,
      sorts: columnsToSortOptions(columns).concat(sorts) as ICollectionSortOption[],
      properties: columnsToProps(columns),
      filters: filterDefinitions,
      definitions: columns,
      types: ['LIST'],
      defaultView,
      argsComponent: POWorkbenchCvArgsEditor
    };

    return view;
  }

  fetchPoViews = restartableTask(async () => {
    if (!this.myCollectionViews) {
      const data = await this.store.rawQuery(CollectionViewResourceName.plural, [
        'mine',
        POGroupResourceName.plural
      ]);
      const collectionViews = normalize(
        CollectionViewResourceName.singular,
        data
      ) as CollectionView[];
      this.myCollectionViews = collectionViews;
    }
  });

  private _handleCollectionViewCreated(payload: SingleResourceDoc) {
    if (
      this.myCollectionViews &&
      payload.data.attributes?.entityType === POGroupResourceName.plural
    ) {
      const collectionView = normalize(
        CollectionViewResourceName.singular,
        payload
      ) as CollectionView;

      this.myCollectionViews = [...new Set([...(this.myCollectionViews ?? []), collectionView])];
    }
  }

  private _handleCollectionViewUpdated(payload: SingleResourceDoc) {
    if (
      this.myCollectionViews &&
      payload.data.attributes?.entityType === POGroupResourceName.plural
    ) {
      const collectionView = normalize(
        CollectionViewResourceName.singular,
        payload
      ) as CollectionView;

      this.myCollectionViews = (this.myCollectionViews ?? []).map((view) => {
        if (collectionView.id === view.id) {
          return collectionView;
        }
        return view;
      });
    }
  }

  private _handleCollectionViewDeleted(payload: SingleResourceDoc) {
    if (
      this.myCollectionViews &&
      payload.data.attributes?.entityType === POGroupResourceName.plural
    ) {
      const collectionView = normalize(
        CollectionViewResourceName.singular,
        payload
      ) as CollectionView;

      this.myCollectionViews = (this.myCollectionViews ?? []).filter(
        (view) => collectionView.id !== view.id
      );
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'collection-builder-po-groups': POGroupsCollectionDefinitionBuilder;
  }
}
