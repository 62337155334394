import BaseModel, { IResourceName } from 'volta/models/base-model';

import { attr, belongsTo, hasMany } from '@ember-data/model';
import { isEmpty } from '@ember/utils';

import BomNode from './bom-node';
import SkuModel from './sku';

export const ResourceName: IResourceName = {
  singular: 'bom',
  plural: 'boms'
};

export default class Bom extends BaseModel {
  @attr('number') partsCount!: number;
  @attr('number') maxLevel!: number;
  @attr('number') clt!: number;
  @belongsTo('sku', { async: false }) sku!: SkuModel;

  // not async because of https://github.com/emberjs/data/issues/5023 – also see models/bom-node.js
  @hasMany('bom-node', { async: false, inverse: 'bom' }) nodes!: BomNode[];

  get skuId() {
    return this.sku.id;
  }
  get partCode() {
    return this.sku.part.code;
  }
  get skuDescription() {
    return this.sku.skuDescription;
  }
  get warehouseCode() {
    return this.sku.warehouse.code;
  }
  get warehouseDesc() {
    return this.sku.warehouse.description;
  }

  get partCell() {
    return {
      code: this.partCode,
      description: this.skuDescription,
      link: 'protected.boms.sku',
      linkParam: this.skuId
    };
  }

  get warehouseCell() {
    return {
      code: this.sku.warehouse.code,
      description: this.sku.warehouse.description
    };
  }

  get childNodes() {
    return this.nodes.filter((node) => Boolean(node.parentNode?.id));
  }

  get hasChildren() {
    return !isEmpty(this.childNodes);
  }

  get actualMaxLevel() {
    return this.nodes.reduce((acc, node) => {
      if (node.level > acc) {
        acc = node.level;
      }
      return acc;
    }, 1);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    bom: Bom;
  }
}
