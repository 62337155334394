import SentryService from 'volta/services/sentry-service';
import application from 'volta/utils/application';

import { debug } from '@ember/debug';

export interface IGenericError {
  error: string;
}

export interface IAPIError {
  title: string;
  detail?: { customAssert?: boolean; description?: string };
  meta?: object;
}
export interface IJSONAPIErrors {
  errors: IAPIError[];
}

export interface IAjaxError {
  json: IJSONAPIErrors;
}

export type TError = Error | IAPIError | IJSONAPIErrors | IAjaxError | IGenericError;

/**
 * Logs an error and sends it to Sentry
 * @param error An application error
 */
export function defaultErrorHandler(error: TError | string) {
  debug(`${error}`);

  const sentry = application?.instance?.lookup('service:sentry-service') as SentryService;
  if (sentry) {
    sentry.logError(error);
  }
}

/**
 * Extract the error title from any sort of error
 * @param {TError} error An application error
 * @returns An error message
 */
export function jsonapiErrors(error: TError | string) {
  const msg =
    (error as Error).message ||
    (error as IGenericError).error ||
    (error as IAPIError).title ||
    ((error as IJSONAPIErrors).errors?.length &&
      (error as IJSONAPIErrors).errors.map((e: IAPIError) => e.title)) ||
    ((error as IAjaxError).json?.errors?.length &&
      (error as IAjaxError).json.errors.map((e: IAPIError) => e.title)) ||
    error;
  return typeof msg === 'string' || Array.isArray(msg) ? msg : undefined;
}
