import Service, { inject as service } from '@ember/service';
import { get, set } from '@ember/object';
import { later } from '@ember/runloop';
import { isPresent } from '@ember/utils';
import { isArray } from '@ember/array';
import config from 'volta/config/environment';
import { debug } from '@ember/debug';
import { defaultErrorHandler } from '../utils/error-utils';

const MAX_RETRIES = 3;

export default class EventStreamService extends Service {
  // Services
  // ~~~~~

  @service websockets;
  @service session;

  // Properties
  // ~~~~~

  /**
   * The WebSocket object reference
   * @type {WebSocket}
   */
  socket;

  /**
   * The registered message handlers
   * @type {Object}
   */
  handlers = {};

  /**
   * Is the connection online ?
   * @type {Boolean}
   */
  online = false;

  /**
   * Number of ms before retrying to reconnect after the connection was closed
   * @type {Number}
   */
  retryTimeout = 5000;

  retries = MAX_RETRIES;

  // Computed Properties
  // ~~~~~

  // Lifecycle Hooks
  // ~~~~~

  init() {
    super.init(...arguments);
    this.initSocket();
  }

  // Helper Function
  // ~~~~~

  initSocket() {
    if (this.session.isAuthenticated && !this.socket) {
      debug('event-stream:initSocket', 'authenticated');
      let token = this.session.data.authenticated.token;
      let host = `${config.api.wsprotocol}://${config.api.domain}:${config.api.wsport}/${config.api.namespace}/stream?token=${token}`;
      const socket = this.websockets.socketFor(host);
      socket.on('open', this.open, this);
      socket.on('message', this.handleMessage, this);
      socket.on('close', this.reconnect, this);
      this.socket = socket;
    } else {
      debug('event-stream:initSocket', 'not-authenticated');
    }

    return this.socket;
  }

  /**
   * On connection open event handler
   * @param  {Object} event The WebSocket event received
   */
  open(event) {
    debug('event-stream:open', event);
    this.online = true;
    this.retries = MAX_RETRIES;
  }

  /**
   * Websocket message handler. Tries to find messages handlers and call them
   * with the payload in parameter
   * @param {Object} msg The websocket message
   */
  handleMessage(msg) {
    let eventMsg = JSON.parse(msg.data);
    let eventKey = this.buildEventKey(eventMsg.channel, eventMsg.event);
    let handlers = this.handlers[eventKey];
    debug('event-stream:message', eventMsg);

    if (isPresent(handlers) && handlers.length) {
      handlers.forEach((data) => {
        data.handler.call(
          data.context,
          { ...(eventMsg.payload ?? {}) },
          eventMsg.event,
          eventMsg.user
        );
      });
    }
  }

  /**
   * Registers a websocket message handler
   *
   * @param {string} channel The event channel (eq. "skus")
   * @param {string|string[]} event   The event name
   * @param {Function} handler The event handler function
   * @param {Object} context The event handler context (A controller or component...)
   */
  addHandler(channel, event, handler, context) {
    this.initSocket();
    const events = isArray(event) ? event : [event];
    const keys = events.map((e) => this.buildEventKey(channel, e));
    const handlerData = { context: context, handler: handler };
    debug('event-stream:addHandler', `${keys.join(',')}`);

    keys.forEach((key) => {
      let existingHandlers = this.handlers[key];
      if (isPresent(existingHandlers)) {
        this.handlers[key].push(handlerData);
      } else {
        this.handlers[key] = [handlerData];
      }
    });
  }

  removeHandler(channel, event, handler, context) {
    const events = isArray(event) ? event : [event];
    const keys = events.map((e) => this.buildEventKey(channel, e));
    const handlerData = { context: context, handler: handler };
    debug('event-stream:removeHandler', `${keys.join(',')}`);

    keys.forEach((key) => {
      let existingHandlers = this.handlers[key];
      if (isPresent(existingHandlers)) {
        this.handlers[key].removeObject(handlerData);
      }
    });
  }

  removeHandlers(context) {
    debug('event-stream:removeHandlers', `${context}`);
    let existingHandlers = this.handlers;
    if (isPresent(existingHandlers)) {
      for (let key in existingHandlers) {
        if (existingHandlers.hasOwnProperty(key)) {
          let handlers = existingHandlers[key];
          existingHandlers[key] = handlers.filter((h) => {
            return h['context'] !== context;
          });
        }
      }
    }
  }

  /**
   * Build an event key from the channel and event name for caching handlers
   *
   * @param {String} channel The event channel
   * @param {String} event The event name
   */
  buildEventKey(channel, event) {
    return `${channel}_${event}`;
  }

  /**
   * When the socket connection is closed, tries to reconnect every
   *
   * @param {Object} closeEvent The close socket event
   */
  reconnect(closeEvent) {
    debug('event-stream:reconnect', closeEvent);
    this.online = false;

    if (closeEvent.code === 1003) {
      // 1003: unsupported data
      defaultErrorHandler('1003: unsupported data');
      return;
    }

    const { retries, socket } = this;

    if (retries <= MAX_RETRIES && retries > 0) {
      later(
        this,
        () => {
          socket && socket.reconnect();
        },
        this.retryTimeout
      );
    } else {
      if (retries === 0) {
        this.session.invalidate();
      } else {
        this.retries = retries - 1;
      }
    }
  }

  // Private Callbacks
  // ~~~~~
}
