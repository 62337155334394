import Ember from 'ember';

import { get } from '@ember/object';

/**
 * Makes sure the data structure given is an array
 */
export function toArray(data?: Ember.Array<any> | any[]) {
  return Array.isArray(data) ? data : typeof data?.toArray === 'function' ? data.toArray() : [];
}

/**
 * Get values that are present on the right array but not on the left
 *
 * @param {any[]} left
 * @param {any[]} right
 * @return {any[]} The difference of values between the right and left array
 */
export function diff(left: any[] = [], right: any[] = []) {
  const diffArr = [];

  for (let i = right.length - 1; i >= 0; i--) {
    const value = right[i];
    if (-1 === left.indexOf(value)) {
      diffArr.push(value);
    }
  }

  return diffArr;
}

/**
 * When all values of the given array are similar, this function returns the value otherwise null
 *
 * @param {[]} array The array of values to check
 * @param returnNull {boolean}
 * @return {null|any} The common value or null
 */
export function commonOrNull<T>(array: T[] = [], returnNull: boolean = true) {
  const emptyVal: null | undefined = returnNull ? null : undefined;
  if (!array.length) {
    return emptyVal;
  }
  const val = array[0];
  for (let i = 1; i < array.length; ++i) {
    if (JSON.stringify(array[i]) !== JSON.stringify(val)) {
      return emptyVal;
    }
  }
  return val;
}

/**
 * When all values of the given array are similar, this function returns the value otherwise null
 *
 * @param array The array of values to check
 * @return The common value or undefined
 */
export function commonOrUndefined<T>(array: T[] = []) {
  return commonOrNull(array, false) as T | undefined;
}

/**
 * Transforms an array to a object indexed by a property
 *
 * @param array The array to be grouped
 * @param keyPath The  path to the object key to base the grouping on
 */
export function indexBy<T = unknown>(array: T[] = [], keyPath: keyof T) {
  return array.reduce(
    (a: Record<string, T>, v: T) => ({ ...a, [`${v[keyPath]}`]: v }),
    {}
  ) as Record<string, T>;
}

/**
 * Transforms an array to a object indexed by a getter
 *
 * @param array The array to be grouped
 * @param fn The getter
 */
export function indexByFn<T = unknown, K extends string | number | symbol = string>(
  array: T[] = [],
  fn: (obj: T) => K | undefined
): Record<K, T> {
  let result: Record<K, T> = {} as Record<K, T>;

  array.forEach((v) => {
    const t = fn(v);

    if (t && ['string', 'number', 'symbol'].includes(typeof t)) {
      result[t] = v;
    }
  });

  return result;
}

/**
 * Checks that 2 arrays have exactly the same scalar values (Does not work with objects)
 *
 * @param array1
 * @param array2
 * @return Whether the two arrays have equal values
 */
export function scalarEquals<T>(array1: T[] = [], array2: T[] = []) {
  const sorted1 = (array1 && array1.length && array1.sort()) || [];
  const sorted2 = (array2 && array2.length && array2.sort()) || [];
  return (
    sorted1.length === sorted2.length &&
    sorted1.every((value, index) => {
      return value === sorted2[index];
    })
  );
}

/**
 * Swap the elements in an array at indexes x and y.
 * Taken from https://gist.github.com/eerohele/5195815
 *
 * @param arr The array.
 * @param x The index of the first element to swap.
 * @param y The index of the second element to swap.
 * @return The input array with the elements swapped.
 */
export function swapArrayElements<T>(arr: T[] = [], x: number, y: number) {
  if (arr[x] === undefined || arr[y] === undefined) {
    return arr;
  }
  const a = x > y ? y : x;
  const b = x > y ? x : y;
  return [...arr.slice(0, a), arr[b], ...arr.slice(a + 1, b), arr[a], ...arr.slice(b + 1)];
}

interface TValue<T> {
  key: string;
  value: any;
  count: number;
  children: T[];
}

export function groupBy<T>(array: T[], key: string) {
  const groups: Array<TValue<T>> = [];

  if (array.length) {
    array.forEach((item) => {
      const value: any = get(item, key as keyof T);
      const group = groups.find((g) => g.value === value) || {
        key,
        value,
        count: 0,
        children: [] as T[]
      };
      if (!groups.includes(group)) {
        groups.push(group);
      }
      group.count += 1;
      group.children.push(item);
    });
  }
  return groups;
}

/**
 * Build a comma-separated list of keys from an array of objects, numbers or strings
 *
 * @param arr Array of objects, strings or numbers
 * @param key Optional key name for when we're dealing with an array of objects
 * @returns A comma separated string with the list of keys in the order they're fed in
 */
export function keysAsString(arr: (string | number | object | undefined)[], key: string = 'id') {
  const keys: string[] = [];
  arr.forEach((o: any) => {
    if (typeof o === 'string' || typeof o === 'number') {
      keys.push(`${o}`);
    } else if (typeof o === 'object' && o[key]) {
      keys.push(`${o[key]}`);
    }
  });
  return keys.join(',');
}

/**
 * Filter duplicates
 *
 * @param arr Array of objects, strings or numbers
 * @returns An array with only unique values
 */
export function distinct(arr: any[]) {
  return arr.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
}

/**
 * Filters an array of objects for duplicates based on a property
 *
 * @param array An array of object
 * @param key A property name
 * @returns A new array with distinct list of object filtered on a property
 */
export function distinctBy<T = object>(array: T[], key: keyof T) {
  return [...new Map(array.map((item) => [get(item, key), item])).values()];
}

export function distinctProp<T = object, P = unknown>(
  array: T[],
  prop: (item: T) => P | undefined
): P[] {
  const keys: Set<P> = new Set();
  array.forEach((item) => {
    const p = prop(item);
    if (p !== undefined && p !== null) {
      keys.add(p as P);
    }
  });
  return [...keys];
}
