import BaseModel from 'volta/models/base-model';
import {
  _normalize,
  collectionCommand,
  resourceCommand as command
} from 'volta/utils/api';

import { attr } from '@ember-data/model';

import {
  IDAFMonthly,
  IDAFWeekly,
  TDAFZoneUnit
} from './types/planning-settings';

export const DafProfileResourceName = {
  singular: 'dafProfile',
  plural: 'dafProfiles'
};

export const Commands = {
  CreateDafProfile: 'CreateDafProfile',
  DeleteDafProfile: 'DeleteDafProfile',
  DeleteDafProfiles: 'DeleteDafProfiles',
  UpdateDafProfile: 'UpdateDafProfile'
};

export const DEFAULT_DAF_UNIT: TDAFZoneUnit = 'PERCENT';

export type StrNum = `${number}` | number;
export type TDAFUnitKeys =
  | 'dafUnit'
  | 'dafGreenUnit'
  | 'dafRedBaseUnit'
  | 'dafRedSafetyUnit'
  | 'dafYellowUnit'
  | 'dafLtUnit';

const EMPTY_OBJ = () => {
  return {};
};

export interface IDAFYearly {
  dafMonthly: Record<StrNum, IDAFMonthly>;
  dafWeekly: Record<StrNum, IDAFWeekly>;
  dafUnit: TDAFZoneUnit;
  dafRedBaseMonthly: Record<StrNum, IDAFMonthly>;
  dafRedBaseWeekly: Record<StrNum, IDAFWeekly>;
  dafRedBaseUnit: TDAFZoneUnit;
  dafRedSafetyMonthly: Record<StrNum, IDAFMonthly>;
  dafRedSafetyWeekly: Record<StrNum, IDAFWeekly>;
  dafRedSafetyUnit: TDAFZoneUnit;
  dafYellowMonthly: Record<StrNum, IDAFMonthly>;
  dafYellowWeekly: Record<StrNum, IDAFWeekly>;
  dafYellowUnit: TDAFZoneUnit;
  dafGreenMonthly: Record<StrNum, IDAFMonthly>;
  dafGreenWeekly: Record<StrNum, IDAFWeekly>;
  dafGreenUnit: TDAFZoneUnit;
  dafLtMonthly: Record<StrNum, IDAFMonthly>;
  dafLtWeekly: Record<StrNum, IDAFWeekly>;
  dafLtUnit: TDAFZoneUnit;
}

export default class DafProfileModel extends BaseModel {
  // Collection commands

  static createDafProfile = collectionCommand(Commands.CreateDafProfile, {
    after: _normalize
  });
  static deleteDafProfiles = collectionCommand(Commands.DeleteDafProfiles);

  // Commands

  updateDafProfile = command(Commands.UpdateDafProfile);
  deleteDafProfile = command(Commands.DeleteDafProfile);

  // Properties

  @attr('string') name?: string;
  @attr('string') description?: string;

  @attr('boolean', { defaultValue: () => true }) isCustom!: boolean;

  @attr('string') dafUnit!: TDAFZoneUnit;
  @attr('string') dafRedBaseUnit!: TDAFZoneUnit;
  @attr('string') dafRedSafetyUnit!: TDAFZoneUnit;
  @attr('string') dafYellowUnit!: TDAFZoneUnit;
  @attr('string') dafGreenUnit!: TDAFZoneUnit;
  @attr('string') dafLtUnit!: TDAFZoneUnit;

  @attr({ defaultValue: EMPTY_OBJ }) dafMonthly!: Record<StrNum, IDAFMonthly>;
  @attr({ defaultValue: EMPTY_OBJ }) dafWeekly!: Record<StrNum, IDAFWeekly>;
  @attr({ defaultValue: EMPTY_OBJ }) dafRedBaseMonthly!: Record<StrNum, IDAFMonthly>;
  @attr({ defaultValue: EMPTY_OBJ }) dafRedBaseWeekly!: Record<StrNum, IDAFWeekly>;
  @attr({ defaultValue: EMPTY_OBJ }) dafRedSafetyMonthly!: Record<StrNum, IDAFMonthly>;
  @attr({ defaultValue: EMPTY_OBJ }) dafRedSafetyWeekly!: Record<StrNum, IDAFWeekly>;
  @attr({ defaultValue: EMPTY_OBJ }) dafYellowMonthly!: Record<StrNum, IDAFMonthly>;
  @attr({ defaultValue: EMPTY_OBJ }) dafYellowWeekly!: Record<StrNum, IDAFWeekly>;
  @attr({ defaultValue: EMPTY_OBJ }) dafGreenMonthly!: Record<StrNum, IDAFMonthly>;
  @attr({ defaultValue: EMPTY_OBJ }) dafGreenWeekly!: Record<StrNum, IDAFWeekly>;
  @attr({ defaultValue: EMPTY_OBJ }) dafLtMonthly!: Record<StrNum, IDAFMonthly>;
  @attr({ defaultValue: EMPTY_OBJ }) dafLtWeekly!: Record<StrNum, IDAFWeekly>;
  @attr('array', { defaultValue: () => [] }) tags!: string[];

  get dafProfileId() {
    return this.id;
  }

  get updatePayload(): IDAFYearly & { name?: string; description?: string; tags?: string[] } {
    return {
      name: this.name,
      description: this.description,
      tags: this.tags,
      dafMonthly: this.dafMonthly,
      dafWeekly: this.dafWeekly,
      dafUnit: this.dafUnit,
      dafRedBaseMonthly: this.dafRedBaseMonthly,
      dafRedBaseWeekly: this.dafRedBaseWeekly,
      dafRedBaseUnit: this.dafRedBaseUnit,
      dafRedSafetyMonthly: this.dafRedSafetyMonthly,
      dafRedSafetyWeekly: this.dafRedSafetyWeekly,
      dafRedSafetyUnit: this.dafRedSafetyUnit,
      dafYellowMonthly: this.dafYellowMonthly,
      dafYellowWeekly: this.dafYellowWeekly,
      dafYellowUnit: this.dafYellowUnit,
      dafGreenMonthly: this.dafGreenMonthly,
      dafGreenWeekly: this.dafGreenWeekly,
      dafGreenUnit: this.dafGreenUnit,
      dafLtMonthly: this.dafLtMonthly,
      dafLtWeekly: this.dafLtWeekly,
      dafLtUnit: this.dafLtUnit
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'daf-profile': DafProfileModel;
  }
}
