import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { validatePresence } from 'ember-changeset-validations/validators';
import { restartableTask } from 'ember-concurrency';
import { IResourceName } from 'volta/models/base-model';
import CollectionView, { CollectionViewResourceName } from 'volta/models/collection-view';
import JsonApiInfinityModel from 'volta/models/jsonapi-infinity-model';
import { ICollectionArgs, ICollectionGroupOption } from 'volta/models/types/collection-view.d';
import { IJsonApiQuery } from 'volta/utils/api/jsonapi-types';
import { normalize } from 'volta/utils/api/serialize-and-push';
import { indexBy, indexByFn } from 'volta/utils/array-utils';
import { defaultErrorHandler } from 'volta/utils/error-utils';
import {
  COLLECTION_FILTER_SEPARATOR,
  collectionSortByFromQueryParams,
  collectionViewFromQueryParams,
  collectionViewToQueryParams,
  DEFAULT_FILTERS_SEPARATOR
} from 'volta/utils/filters-utils';
import { deepEqual, getChangesetValue } from 'volta/utils/object-utils';

import EmberArray from '@ember/array';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import type { BufferedChangeset } from 'ember-changeset/types';
import type { IBvTableColumn, IBvTableSort } from 'volta/components/bv-table';
import type IntlService from 'ember-intl/services/intl';
import type {
  ICollectionFilter,
  ICollectionProperty,
  ICollectionSort,
  ICollectionSortOption,
  ICollectionViewDefinition,
  ICollectionViewEditCmd,
  ICreateCollectionViewCmd
} from 'volta/models/types/collection-view';
import type StoreService from 'volta/services/store';
import type BvFlashService from 'volta/services/bv-flash';
import type SessionUserService from 'volta/services/session-user';
import type RouterService from '@ember/routing/router-service';
import type Infinity from 'ember-infinity/services/infinity';
export const DEFAULT_PAGE_SIZE = 50;

export const EMPTY_VIEW: ICollectionViewEditCmd = {
  name: '',
  description: '',
  pageSize: DEFAULT_PAGE_SIZE,
  isTemplate: false,
  args: {},
  groupBy: [],
  sortBy: [],
  showForUsers: [],
  showForRoles: []
};

const VIEW_CREATION_VALIDATOR = {
  name: validatePresence(true)
};

const VIEW_EDITION_VALIDATOR = VIEW_CREATION_VALIDATOR;

export const COLLECTION_VIEW_QP_KEYS = [
  'v',
  'sortBy',
  'page',
  'pageSize',
  'filters',
  'groupBy',
  'args'
];

interface IArgs {
  collectionDefinition: ICollectionViewDefinition;
  queryParams: TQueryParams;
  resourceName?: IResourceName;
  infiniteScroll?: boolean;
  metadata?: object;
  fixedGroups?: string[];
  onFetchJsonApi?: (response: EmberArray<any>) => void;
  formatApiQuery?: (query: IJsonApiQuery) => IJsonApiQuery;
  onComponentArgsChange?: (args: ICollectionArgs, changeset: BufferedChangeset) => void;
}

export default class CollectionViewApi extends Component<IArgs> {
  // Services
  // ~~~~~~

  @service store!: StoreService;
  @service sessionUser!: SessionUserService;
  @service intl!: IntlService;
  @service bvFlash!: BvFlashService;
  @service router!: RouterService;
  @service infinity!: Infinity;

  // Tracked Properties
  // ~~~~~~

  @tracked selectedViewId: string | 'default' = 'default';
  @tracked views: CollectionView[] = [];
  @tracked createViewChangeset?: BufferedChangeset;
  @tracked editViewChangeset?: BufferedChangeset;
  @tracked createViewCmd?: ICreateCollectionViewCmd;
  @tracked editViewCmd?: ICollectionViewEditCmd;
  @tracked sortOptions: ICollectionSortOption[] = [];
  @tracked groupByOptions: ICollectionGroupOption[] = [];
  @tracked sorts: IBvTableSort[] = [];
  @tracked viewProperties: IBvTableColumn[] = [];

  // Getters
  // ~~~~~~

  @cached
  get indexedColumnDefs() {
    return indexByFn(this.args.collectionDefinition.definitions, (d) => d.property ?? d.valuePath);
  }

  get entityType() {
    const { defaultView } = this.args.collectionDefinition;
    return defaultView.entityType;
  }

  @cached
  get hasDefaultView() {
    return !!this.views.find((v) => !v.id);
  }

  @cached
  get selectedView() {
    return this.views.find((v) =>
      this.selectedViewId !== 'default' ? v.id === this.selectedViewId : !v.id
    );
  }

  get hasUnsavedChanges() {
    return this.editViewChangeset?.changes.length && !this.createViewChangeset;
  }

  @cached
  get jsonApiQueryParams() {
    const defs = this.indexedColumnDefs;
    const { page, pageSize, filters, sortBy, groupBy } = this.args.queryParams ?? {};

    const propKey = (prop?: IBvTableColumn, isSort?: boolean) =>
      (isSort ? prop?.sortKey : undefined) ?? prop?.property ?? prop?.valuePath;

    const qp: TQueryParams = {
      page,
      pageSize,
      groupBy,
      sort: collectionSortByFromQueryParams(sortBy)
        .map((s) => `${s.asc ? '' : '-'}${propKey(defs[s.property], true)}`)
        .join(',')
    };

    const filterStrings = filters ? filters.split(COLLECTION_FILTER_SEPARATOR) : [];

    filterStrings.forEach((fs) => {
      const [key, comparator, values] = fs.split(DEFAULT_FILTERS_SEPARATOR);

      if (key && comparator && values?.length) {
        if (key) {
          qp[key] = `${comparator}${DEFAULT_FILTERS_SEPARATOR}${values}`;
        }
      }
    });

    return qp;
  }

  get publicApi() {
    return {
      jsonApiQueryParams: this.jsonApiQueryParams,
      selectedView: this.selectedView,
      views: this.views,
      sorts: this.sorts,
      filters: this.editViewChangeset?.filters ?? [],
      properties: this.viewProperties,
      pageSize: this.editViewChangeset?.pageSize,
      componentArgs: this.editViewChangeset?.args,
      collectionDefinition: this.args.collectionDefinition,
      onPropertySort: this.handlePropertySort,
      onPropertyReorder: this.handlePropertyReorder,
      onFilterValuesChange: this.handleFilterValuesChange,
      hasUnsavedChanges: this.hasUnsavedChanges,
      onPageSizeChange: this.handlePageSizeChange,
      onPageChange: this.handlePageChange,
      fetchJsonApi: this.fetchJsonApi,
      resourceName: this.args.resourceName,
      infiniteScroll: this.args.infiniteScroll,
      metadata: this.args.metadata
    };
  }

  // Lifecycle Events
  // ~~~~~~

  constructor(owner: any, args: IArgs) {
    super(owner, args);
    this.fetchViews.perform(true);
  }

  // Actions
  // ~~~~~~

  @action
  handlePropertySort(sorts: IBvTableSort[]) {
    let limitedSorts = sorts;
    if (this.args.collectionDefinition.sortLimit) {
      limitedSorts = limitedSorts.slice(-this.args.collectionDefinition.sortLimit);
    }
    const toCollectionSorts = limitedSorts.map((sort) => ({
      property: sort.valuePath,
      asc: sort.isAscending
    }));

    this.onSortEdit(toCollectionSorts);
  }

  @action
  handlePropertyReorder(_col1: IBvTableColumn, _col2: IBvTableColumn, columns: IBvTableColumn[]) {
    const propsByPath = indexBy(
      this.editViewChangeset?.get('properties').slice() ?? [],
      'property'
    );
    const propColumns = columns.map((col) => propsByPath[col.valuePath]);
    this.handlePropertyChange(propColumns);
  }

  @action
  handlePropertyChange(props: ICollectionProperty[]) {
    if (this.editViewChangeset) {
      this.editViewChangeset.set('properties', [...props]);
      this.rollbackPropIfPristine('properties');
      this.refreshSortOptions();
      this.refreshViewProperties();
      this.sendCollectionQueryParams();
    }
  }

  @action
  handleComponentArgsChange(settings: ICollectionArgs) {
    if (this.editViewChangeset) {
      this.editViewChangeset.set('args', { ...settings });
      this.rollbackPropIfPristine('args');
      this.args.onComponentArgsChange?.(settings, this.editViewChangeset);
      this.refreshGroupByOptions();
      this.refreshSortOptions();
      this.refreshViewProperties();
      this.sendCollectionQueryParams();
    }
  }

  @action
  handleQueryParamsChange(_el: HTMLElement, [queryParams]: [TQueryParams]) {
    const { v = 'default' } = queryParams ?? {};
    if (this.selectedViewId !== v) {
      this.selectedViewId = this.views.find((view) => view.id === v) ? v : 'default';
      this.initEditChangeset();
    }
    this.fuseCollectionWithQueryParams();
  }

  @action
  handleFilterValuesChange(filter: TFilterValue, field: string) {
    if (!this.editViewChangeset) {
      return;
    }

    const filters = this.editViewChangeset.get('filters')?.slice() ?? [];
    const formattedFilter = {
      property: field,
      comparator: filter.comparator,
      values: filter.values
    };
    const filterIndex = filters.findIndex((f: ICollectionFilter) => f.property === field);

    if (filterIndex === -1) {
      filters.push(formattedFilter);
    } else if (!formattedFilter.values.length) {
      filters.splice(filterIndex, 1);
    } else {
      filters[filterIndex] = formattedFilter;
    }

    this.editViewChangeset.set('filters', [...filters]);
    this.rollbackPropIfPristine('filters');
    this.sendCollectionQueryParams();
  }

  @action
  handleSearch(_: TFilterValues, search: string) {
    return this.handleFilterValuesChange(
      {
        comparator: 'lk',
        values: [search],
        enabled: true
      },
      'q'
    );
  }

  @action
  selectView(viewId: string) {
    if (this.selectedViewId === viewId || !this.views.length) {
      return;
    }

    this.selectedViewId = viewId;
    this.initEditChangeset();
    this.sendCollectionQueryParams();
  }

  @action
  handleCancelCreation() {
    this.createViewCmd = undefined;
    this.createViewChangeset = undefined;
  }

  @action
  handleDiscard() {
    this.initEditChangeset();
    this.sendCollectionQueryParams();
  }

  @action
  fuseCollectionWithQueryParams() {
    const serialised = this.getSerialisedCollectionView();
    if (serialised && this.editViewChangeset) {
      Object.entries(serialised).forEach(([key, value]) => {
        let csValue = getChangesetValue(this.editViewChangeset!, key);
        if (key === 'id' || !value) {
          return;
        }

        if (!deepEqual(csValue, value)) {
          this.editViewChangeset!.set(key, value);
        }
      });
    }
  }

  @action
  handlePageSizeChange(pageSize: number) {
    if (this.editViewChangeset) {
      this.editViewChangeset.set('pageSize', pageSize);
      this.rollbackPropIfPristine('pageSize');
      this.sendCollectionQueryParams();
    }
  }

  @action
  handlePageChange(page: number) {
    const { queryParams = {} } = this.args;
    this.router.transitionTo({ queryParams: { ...queryParams, page } });
  }

  @action
  sendCollectionQueryParams(isInitial: boolean = false) {
    try {
      const { queryParams = {}, collectionDefinition } = this.args;

      const cs = this.editViewChangeset;
      if (!cs) {
        return;
      }

      const cv = collectionViewToQueryParams(
        {
          id: this.selectedViewId,
          filters: cs.get('filters'),
          sortBy: cs.get('sortBy'),
          groupBy: cs.get('groupBy'),
          pageSize: cs.get('pageSize'),
          args: cs.get('args')
        },
        queryParams,
        collectionDefinition
      );

      const hasParams = !!Object.values(queryParams).filter(Boolean).length;

      return isInitial || !hasParams
        ? this.router.replaceWith(this.router.currentRouteName, { queryParams: cv })
        : this.router.transitionTo({ queryParams: cv });
    } catch (e) {
      defaultErrorHandler(e);
      return;
    }
  }

  @action
  createDefaultView(attrs: object = {}): CollectionView {
    const { defaultView } = this.args.collectionDefinition;
    return this.store.createRecord(CollectionViewResourceName.singular, {
      ...EMPTY_VIEW,
      ...defaultView,
      ...attrs
    });
  }

  @action
  handleInitCreate() {
    this.initCreateChangeset();
  }

  @action
  handleSortSelectionChange(sort: ICollectionSortOption, checked: boolean) {
    const { sortLimit } = this.args.collectionDefinition;
    let count = 0;

    const selectedOptions: ICollectionSortOption[] = [];
    const unseledtedOptions: ICollectionSortOption[] = [];

    this.sortOptions.forEach((o) => {
      const aboveLimit = sortLimit && count >= sortLimit;
      const multiple = () => (aboveLimit ? false : sort.value === o.value ? checked : o.selected);
      const selected = sortLimit === 1 ? (sort.value === o.value ? checked : false) : multiple();
      const option = {
        ...o,
        selected,
        asc: selected ? o.asc : true
      };

      if (selected) {
        count++;
        selectedOptions.push(option);
      } else {
        unseledtedOptions.push(option);
      }
    });
    this.onSortEdit(
      CollectionViewApi.fromSortOptionsToSorts(selectedOptions.concat(unseledtedOptions))
    );
  }

  @action
  handleGroupByChange(group: ICollectionGroupOption, checked: boolean) {
    const { groupLimit } = this.args.collectionDefinition;
    let count = 0;

    const selectedOptions: ICollectionGroupOption[] = [];
    const unseledtedOptions: ICollectionGroupOption[] = [];

    this.groupByOptions.forEach((o) => {
      const aboveLimit = groupLimit && count >= groupLimit;
      const multiple = () => (aboveLimit ? false : group.value === o.value ? checked : o.selected);
      const selected = groupLimit === 1 ? (group.value === o.value ? checked : false) : multiple();
      const option = {
        ...o,
        selected
      };

      if (selected) {
        count++;
        selectedOptions.push(option);
      } else {
        unseledtedOptions.push(option);
      }
    });
    this.onGroupEdit(
      CollectionViewApi.fromGroupOptionsToGroupBy(selectedOptions.concat(unseledtedOptions))
    );
  }

  @action
  handleSortDirectionChange(sort: ICollectionSortOption) {
    const { sortLimit } = this.args.collectionDefinition;
    let count = 0;
    const options = this.sortOptions.map((o) => {
      const aboveLimit = sortLimit && sortLimit > 0 && count >= sortLimit;
      const multiple = () =>
        aboveLimit ? false : sort.value === o.value && !sort.selected ? true : o.selected;
      const selected = sortLimit === 1 ? sort.value === o.value : multiple();

      const asc = aboveLimit || sort.value !== o.value ? o.asc : !(o.asc ?? true);

      if (selected) {
        count++;
      }

      return {
        ...o,
        selected,
        asc
      };
    });
    this.onSortEdit(CollectionViewApi.fromSortOptionsToSorts(options));
  }

  @action
  handleSortOptionsReorder(sortOptions: ICollectionSortOption[]) {
    this.onSortEdit(CollectionViewApi.fromSortOptionsToSorts(sortOptions));
  }

  @action
  handleGroupByOptionsReorder(options: ICollectionGroupOption[]) {
    this.onGroupEdit(CollectionViewApi.fromGroupOptionsToGroupBy(options));
  }

  // Tasks
  // ~~~~~~~~

  fetchJsonApi = restartableTask(async (apiQuery = {}) => {
    try {
      const { infiniteScroll, resourceName, formatApiQuery } = this.args;
      if (!resourceName) {
        return [];
      }

      const query = formatApiQuery?.(apiQuery) ?? apiQuery;
      const response: EmberArray<any> = infiniteScroll
        ? await this.infinity.model(resourceName.singular, query, JsonApiInfinityModel)
        : await this.store.query(resourceName.singular, query);
      this.args.onFetchJsonApi?.(response);
      return response;
    } catch (error) {
      return defaultErrorHandler(error);
    }
  });

  fetchViews = restartableTask(async (isInitial: boolean = false) => {
    const { entityType } = this.args.collectionDefinition.defaultView;
    try {
      const data = await this.store.rawQuery(
        CollectionViewResourceName.plural,
        ['mine', entityType as string],
        { include: 'showForUsers,showForRoles' }
      );
      const views = normalize(CollectionViewResourceName.singular, data) as CollectionView[];
      const collectionViewParams = this.getSerialisedCollectionView();
      const viewInParamExists = Boolean(
        collectionViewParams && views.find((v) => v.id === collectionViewParams.id)
      );
      this.selectedViewId =
        viewInParamExists && collectionViewParams?.id ? collectionViewParams.id : 'default';

      const defaultView = this.createDefaultView();

      if (!views || !views.length) {
        this.views = [defaultView];
      } else {
        this.views = [defaultView, ...views];
      }

      this.initEditChangeset();
      this.fuseCollectionWithQueryParams();
      this.refreshSortOptions();
      this.refreshGroupByOptions();
      this.refreshViewProperties();
      this.sendCollectionQueryParams(isInitial);
    } catch (e) {
      defaultErrorHandler(e);
      this.bvFlash.error(
        this.intl.t('bvList.query.error', { resourceNamePlural: this.intl.t('skus') })
      );
    }
  });

  createView = restartableTask(async () => {
    const cs = this.createViewChangeset;
    try {
      if (!cs) {
        throw new Error('Cannot create a view without a changeset');
      }

      cs.validate();

      if (cs.isValid) {
        cs.execute();

        const newView = await CollectionView.createCollectionView(this.createViewCmd!);
        this.views = [...this.views, newView];
        this.selectedViewId = newView.id;

        this.handleDiscard();
        this.handleCancelCreation();
        this.bvFlash.success(
          this.intl.t('bvList.singleActions.createSuccess', {
            resourceNameSingular: this.intl.t(CollectionViewResourceName.singular),
            name: newView.name
          })
        );
      }
    } catch (e) {
      defaultErrorHandler(e);
      if (this.createViewChangeset) {
        this.bvFlash.error(
          this.intl.t('bvList.singleActions.createFail', {
            resourceNameSingular: this.intl.t(CollectionViewResourceName.singular),
            name: this.createViewChangeset.name
          })
        );
      }
    }
  });

  editView = restartableTask(async () => {
    const selectedView = this.selectedView;

    if (!selectedView || !selectedView?.id || selectedView.isLocked) {
      await this.duplicateFromSelectedView.perform();
    } else {
      const cs = this.editViewChangeset;
      try {
        if (!cs) {
          throw new Error('Cannot edit a view without a changeset');
        }
        await cs.validate();

        if (cs.isValid) {
          cs.execute();

          if (
            Object.keys(cs.change).some((change) =>
              ['isTemplate', 'showForUsers', 'showForRoles'].includes(change)
            )
          ) {
            await selectedView.shareCollectionView({
              isTemplate: this.editViewCmd!.isTemplate ?? false,
              showForRoles: this.editViewCmd!.showForRoles ?? [],
              showForUsers: this.editViewCmd!.showForUsers ?? []
            });
          }
          const cmd = this.editViewCmd;

          await selectedView.updateCollectionView(cmd);
        }

        this.initEditChangeset();
        this.sendCollectionQueryParams();
        this.bvFlash.success(
          this.intl.t('bvList.singleActions.updateSuccess', {
            resourceNameSingular: this.intl.t(CollectionViewResourceName.singular),
            name: selectedView?.name
          })
        );
      } catch (e) {
        defaultErrorHandler(e);
        this.bvFlash.error(
          this.intl.t('bvList.singleActions.updateFail', {
            resourceNameSingular: this.intl.t(CollectionViewResourceName.singular),
            name: cs?.name ?? 'N/A'
          })
        );
        return;
      }
    }
  });

  deleteView = restartableTask(async () => {
    const { selectedView } = this;
    const idToDelete = selectedView?.id;
    if (!idToDelete) {
      return;
    }
    try {
      await selectedView.deleteCollectionView();
      const defaultView = this.createDefaultView();
      this.views = this.views.reduce(
        (acc, v) => {
          if (v.id && v.id !== idToDelete) {
            acc.push(v);
          }
          return acc;
        },
        [defaultView] as CollectionView[]
      );

      this.selectedViewId = 'default';

      this.bvFlash.success(
        this.intl.t('bvList.singleActions.deleteSuccess', {
          resourceNameSingular: this.intl.t(CollectionViewResourceName.singular),
          name: selectedView.name
        })
      );
      this.initEditChangeset();
      this.sendCollectionQueryParams();
    } catch (e) {
      defaultErrorHandler(e);
      this.bvFlash.error(
        this.intl.t('bvList.singleActions.deleteFail', {
          resourceNameSingular: this.intl.t(CollectionViewResourceName.singular),
          name: selectedView.name
        })
      );
      return;
    }
  });

  duplicateFromSelectedView = restartableTask(async () => {
    const cs = this.editViewChangeset;
    try {
      if (!cs) {
        throw new Error('Cannot duplicate a view without a changeset');
      }
      await cs.validate();

      if (cs.isValid) {
        cs.execute();
        const name = this.selectedView?.id
          ? `${this.editViewCmd!.name} (${this.intl.t('copySingular')})`
          : this.editViewCmd!.name;
        const cmd = {
          ...this.selectedView?.toDuplicateCmd(),
          ...this.editViewCmd,
          name,
          id: undefined
        } as ICreateCollectionViewCmd;

        if (!this.views.length || !cmd) {
          throw new Error(
            'View should not be empty. The temporary view should at least be present.'
          );
        }

        const newView = await CollectionView.createCollectionView(cmd);
        const views = [...this.views, newView];
        views.splice(0, 1, this.createDefaultView());
        this.views = views;
        this.selectedViewId = newView.id;
        this.initEditChangeset();
        this.fuseCollectionWithQueryParams();
        this.sendCollectionQueryParams();
        return newView;
      }
    } catch (e) {
      defaultErrorHandler(e);
      this.bvFlash.error(
        this.intl.t('bvList.singleActions.updateFail', {
          resourceNameSingular: this.intl.t(CollectionViewResourceName.singular),
          name: cs?.name ?? 'N/A'
        })
      );
      return;
    }
    return;
  });

  // Private functions
  // ~~~~~~

  private onSortEdit(sorts: ICollectionSort[]) {
    if (this.editViewChangeset) {
      this.editViewChangeset.set('sortBy', [...sorts]);
      this.rollbackPropIfPristine('sortBy');
      this.refreshSortOptions();
      this.sendCollectionQueryParams();
    }
  }

  private onGroupEdit(groupBy: string[]) {
    if (this.editViewChangeset) {
      this.editViewChangeset.set('groupBy', [...groupBy]);
      this.rollbackPropIfPristine('groupBy');
      this.refreshGroupByOptions();
      this.sendCollectionQueryParams();
    }
  }

  private initCreateChangeset() {
    this.createViewCmd = this.createDefaultView({ name: '', description: '' }).toCreateCmd();
    this.createViewChangeset = Changeset(
      this.createViewCmd,
      lookupValidator(VIEW_CREATION_VALIDATOR),
      VIEW_CREATION_VALIDATOR
    );
  }

  private initEditChangeset() {
    if (!this.selectedView) {
      return;
    }

    this.editViewCmd = { ...this.selectedView.toUpdateCmd(), ...this.selectedView.toShareCmd() };
    this.editViewChangeset = Changeset(
      this.editViewCmd,
      lookupValidator(VIEW_EDITION_VALIDATOR),
      VIEW_EDITION_VALIDATOR
    );
    this.refreshSortOptions();
    this.refreshGroupByOptions();
    this.refreshViewProperties();
  }

  private getSerialisedCollectionView() {
    const { queryParams } = this.args;

    try {
      if (queryParams) {
        return collectionViewFromQueryParams(this.args.queryParams);
      }
      return undefined;
    } catch (error) {
      defaultErrorHandler(error);
      return undefined;
    }
  }

  private rollbackPropIfPristine(propPath: string) {
    if (
      this.editViewChangeset &&
      deepEqual(
        this.editViewChangeset.get(propPath),
        this.editViewChangeset.get(`data.${propPath}`)
      )
    ) {
      this.editViewChangeset.rollbackProperty(propPath);
    }
    return this.editViewChangeset;
  }

  private refreshGroupByOptions() {
    const groupBy: string[] = this.editViewChangeset?.get('groupBy') ?? [];
    const { fixedGroups = [] } = this.args;

    const defs = this.indexedColumnDefs;
    const options: ICollectionGroupOption[] = [];

    groupBy.forEach((gb) => {
      const maybeGroup = defs[gb];
      if (maybeGroup && (maybeGroup.isGroupable || maybeGroup.isCustomField)) {
        options.push({
          label: maybeGroup.columnName,
          value: maybeGroup.valuePath,
          color: maybeGroup.color,
          selected: true,
          editable: !fixedGroups.includes(maybeGroup.valuePath)
        });
      }
    });

    Object.values(defs).forEach((s) => {
      if (!groupBy.includes(s.valuePath) && (s.isGroupable || s.isCustomField)) {
        options.push({
          label: s.columnName,
          value: s.valuePath,
          color: s.color,
          selected: false,
          editable: !fixedGroups.includes(s.valuePath)
        });
      }
    });

    this.groupByOptions = options;
  }

  private refreshSortOptions() {
    const sortBy: ICollectionSort[] = this.editViewChangeset?.get('sortBy') ?? [];
    const indexedSortBy = indexByFn(sortBy, (s) => s.property);
    const indexedProps = indexByFn(
      (this.editViewChangeset?.get('properties') ?? []) as ICollectionProperty[],
      (p) => p.property
    );
    const { sorts } = this.args.collectionDefinition;
    const indexedSorts = indexByFn(sorts, (s) => s.origin);
    const defs = this.indexedColumnDefs;
    const options: ICollectionSortOption[] = [];
    const newSorts: IBvTableSort[] = [];

    sortBy.forEach((sb) => {
      if (indexedProps[sb.property]) {
        const maybeSort = indexedSorts[sb.property];
        if (maybeSort) {
          options.push({
            ...maybeSort,
            selected: true,
            asc: indexedSortBy[sb.property]?.asc ?? true
          });
        }
      }

      newSorts.push({
        valuePath: sb.property,
        sortKey: defs[sb.property]?.sortKey ?? sb.property,
        isAscending: sb.asc
      });
    });

    sorts.forEach((s) => {
      if (indexedProps[s.origin]) {
        if (!indexedSortBy[s.origin]) {
          options.push({
            ...s,
            selected: false,
            asc: true
          });
        }
      }
    });

    this.sortOptions = options;
    this.sorts = newSorts;
  }

  private refreshViewProperties() {
    const defs = this.indexedColumnDefs;
    const properties: ICollectionProperty[] = this.editViewChangeset?.get('properties') ?? [];

    this.viewProperties = (properties ?? []).map((column) => ({
      ...defs[column.property],
      ...column
    }));
  }

  // Static functions

  private static fromSortOptionsToSorts(options: ICollectionSortOption[]) {
    return options.reduce((acc, o) => {
      if (o.selected) {
        acc.push({
          property: o.origin,
          asc: o.asc ?? true
        });
      }
      return acc;
    }, [] as ICollectionSort[]);
  }

  private static fromGroupOptionsToGroupBy(options: ICollectionGroupOption[]) {
    return options.reduce((acc, o) => {
      if (o.selected) {
        acc.push(o.value);
      }
      return acc;
    }, [] as string[]);
  }
}
