import { inject as service } from '@ember/service';
import DomainSelect from 'volta/components/domain-select';
import ifUndefined from 'volta/macros/if-undefined';

import JsonApiInfinityModel, { decorateApiQuery } from 'volta/models/jsonapi-infinity-model';
import { eqFilter, inFilter, lkFilter, niFilter, stringListToArray } from 'volta/utils/filters-utils';
import { ResourceName } from 'volta/models/user-role';
import { UserResourceName } from 'volta/models/user';

export default DomainSelect.extend({
  infiniteScroll: true,

  async searchPromise(payload) {
    let { search, selected, excluded } = payload;
    const query = {
      sort: 'name'
    };
    let filter = {"isActive": eqFilter(true)}

    if (search && search.length) {
      filter.q = lkFilter(search);
    }
    if (selected && selected.length) {
      filter.selectedIds = inFilter(selected);
    }
    if (excluded && excluded.length) {
      filter.excludedIds = niFilter(excluded);
    }

    query.filter = filter;

    let decoratedQuery = this.beforeQuery?.(query);

    return await this.infinity.model(
      UserResourceName.singular,
      decorateApiQuery(decoratedQuery || query),
      JsonApiInfinityModel
    );
  }
});
