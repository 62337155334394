import { restartableTask, timeout } from 'ember-concurrency';
import SkuModel, { SkuResourceName } from 'volta/models/sku';
import { IHistoryRecord } from 'volta/routes/base';
import { defaultErrorHandler } from 'volta/utils/error-utils';
import { eqFilter, lkFilter } from 'volta/utils/filters-utils';
import { strNormalizeAccent } from 'volta/utils/text-utils';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import type StoreService from 'volta/services/store';
import type AppNavState from 'volta/services/app-nav-state';
import type { IBaseMenuItem } from 'volta/services/app-nav-state';
import type RouterService from '@ember/routing/router-service';

export const TYPING_DEBOUNCE_MS = 300;

export interface IArgs {
  historyItems: IHistoryRecord[];
  skuItems: SkuModel[];
  menuItems: IBaseMenuItem[];
}

export default class BvAppSearchField extends Component<IArgs> {
  @service appNavState!: AppNavState;
  @service router!: RouterService;
  @service store!: StoreService;

  @tracked pageSearchResults: unknown[] = [];

  @tracked skus: SkuModel[] = [];
  skuResourceName = SkuResourceName;

  @action
  handleItemClick(route: string, queryParams: { [key: string]: string }, id: string) {
    try {
      this.appNavState.closeSearch();
      if (id) {
        this.router.transitionTo(route, id);
      } else if (queryParams) {
        this.router.transitionTo(route, { queryParams });
      } else {
        this.router.transitionTo(route);
      }
    } catch (e) {
      defaultErrorHandler(e);
    }
  }

  @action
  handleSearchTextChange() {
    if (this.appNavState.hasSearchText) {
      this.searchMenu();
      this.searchTask.perform(this.appNavState.searchText as string);
    }
  }

  @action
  handleSearch(query: string) {
    this.appNavState.searchText = query;
    this.searchMenu();
    if (query) {
      this.searchTask.perform(query);
    }
  }

  searchTask = restartableTask(async (q: string) => {
    try {
      const query = {
        page: {
          offset: 0,
          limit: 5
        },
        sort: 'part.code',
        filter: {
          q: lkFilter(q),
          isActive: eqFilter(true)
        }
      };
      await timeout(TYPING_DEBOUNCE_MS);
      this.skus = (
        await this.store.query(this.skuResourceName.singular, query)
      ).toArray() as SkuModel[];
      return this.skus;
    } catch (e) {
      defaultErrorHandler(e);
      return;
    }
  });

  searchMenu() {
    const pageResults: IBaseMenuItem[] = [];

    const menuLabels = Object.keys(this.appNavState.searchMenuContent);
    menuLabels.forEach((label) => {
      if (label.includes(strNormalizeAccent(this.appNavState.searchText?.toLowerCase() ?? ''))) {
        pageResults.push(this.appNavState.searchMenuContent[label]);
      }
    });

    this.pageSearchResults = pageResults.length > 10 ? pageResults.slice(-5) : pageResults;
  }
}
