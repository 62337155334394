const HAS_WINDOWS = typeof window !== 'undefined';
const HAS_NAVIGATOR = typeof navigator !== 'undefined';

const IS_TOUCH =
  // @ts-ignore
  HAS_WINDOWS && ('ontouchstart' in window || (HAS_NAVIGATOR && navigator.msMaxTouchPoints > 0));
const EVENTS = IS_TOUCH ? ['touchstart'] : ['click'];
import Modifier from 'ember-modifier';
import { closest, documentOrBodyContains } from 'volta/utils/dom-utils';

// @ts-ignore
import { registerDestructor } from '@ember/destroyable';

type TNamedParams = {
  capture?: boolean;
  exceptSelector?: string;
  event?: keyof HTMLElementEventMap;
  events?: (keyof HTMLElementEventMap)[];
};

function getEventNames({
  event,
  events
}: {
  event?: keyof HTMLElementEventMap;
  events?: (keyof HTMLElementEventMap)[];
}) {
  if (events) {
    return events;
  }

  if (event) {
    return [event];
  }

  return EVENTS;
}

interface IArgs {
  element: HTMLElement;
  positional: unknown[];
  named: TNamedParams;
}

export default class OnClickOutsideModifier extends Modifier<IArgs> {
  handlers: [keyof HTMLElementEventMap, (event: PointerEvent) => void][] = [];

  constructor(owner: any, args: IArgs) {
    super(owner, args);

    registerDestructor(this, () => {
      const { capture } = args.named;
      this.handlers.forEach(([eventName, handler]) => {
        document.documentElement.removeEventListener(eventName, handler, {
          capture
        });
      });
    });
  }

  modify(element: Element, positional: unknown[] = [undefined], named: TNamedParams = {}): void {
    const action = positional[0];
    const events = getEventNames(named);
    const { capture, exceptSelector } = named;

    const isFunction = typeof action === 'function';
    if (!isFunction) {
      throw new Error('{{click-outside}}: Action value must be a function.');
    }

    events.forEach((eventName: keyof HTMLElementEventMap) => {
      const handler = (event: PointerEvent & { path?: EventTarget[] }) => {
        if (exceptSelector && closest(event.target as HTMLElement, exceptSelector)) {
          return;
        }
        const path: EventTarget[] = event.path || (event.composedPath && event.composedPath());

        if (path) {
          path.includes(element) || (action as Function)(event);
        } else {
          // Check if the click target still is in the DOM.
          // If not, there is no way to know if it was inside the element or not.
          const isRemoved = !event.target || !documentOrBodyContains(event.target as HTMLElement);

          // Check the element is found as a parent of the click target.
          const isInside =
            element === event.target || element.contains(event.target as HTMLElement);

          if (!isRemoved && !isInside) {
            (action as Function)(event);
          }
        }
      };

      this.handlers.push([eventName, handler]);
      document.documentElement.addEventListener(eventName, handler, { capture });
    });
  }
}
