import { task } from 'ember-concurrency';
import { onceAnimationEnd } from 'volta/utils/dom-utils';

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

type ISize = 'xs' | 'sm' | 'lg' | 'xl' | 'xxl' | 'search';

interface IArgs {
  onClose: () => void;
  onInsert?: (context: TContext) => void;
  size?: ISize;
  sheet?: boolean;
  sheetSide?: 'left' | 'right';
  /**
   * Shows the close button in the header
   * or on the side of a sheet modal
   * @default true;
   **/
  showDismiss?: boolean;
  translucentBackdrop?: boolean;
  isLoading?: boolean;
  isShowing?: boolean;
  escapable?: boolean;
  closingDelay?: number;
  isFocusTrapActive?: boolean;
  shouldSelfFocus?: boolean;
  isFocusTrapPaused?: boolean;
  focusTrapOptions?: object;
}

type TContext = {
  isShowing: boolean;
  isWarning: boolean;
  isLoading: boolean;
  close: () => void;
};

export default class BvModal extends Component<IArgs> {
  modalEl!: HTMLElement;
  @tracked isShowing = true;
  @tracked isWarning = false;

  get additionalFocusElements() {
    return ['#ember-basic-dropdown-wormhole', '.ember-basic-dropdown-content'].filter(
      (e) => document.querySelector(e) !== null
    );
  }

  get context(): TContext {
    return {
      isShowing: this.isShowing,
      isWarning: this.isWarning,
      isLoading: this.args.isLoading ?? false,
      close: () => {
        return this.close.perform();
      }
    };
  }

  get escapable() {
    return this.args.escapable ?? true;
  }

  @action
  attemptEscape() {
    if (this.escapable) {
      this.close.perform();
    } else {
      this.warn.perform();
    }
  }

  insert = task(async (el: HTMLElement) => {
    this.modalEl = el;
    await onceAnimationEnd(this.modalEl, 'bv-modal--is-showing');
    this.args.onInsert?.(this.context);
  });

  close = task(async () => {
    if (this.isDestroyed || this.isDestroying) {
      return;
    }
    await onceAnimationEnd(this.modalEl, 'bv-modal--is-hiding');
    this.isShowing = false;
    this.args.onClose();
  });

  warn = task(async () => {
    if (this.isDestroyed || this.isDestroying) {
      return;
    }
    if (!this.isShowing) {
      return;
    }

    this.isWarning = true;
    await onceAnimationEnd(this.modalEl, 'bv-modal--is-warning');
    this.isWarning = false;
  });
}
