import IntlService from 'ember-intl/services/intl';
import { IJSONAPIErrors } from 'volta/utils/error-utils';

import Service, { inject as service } from '@ember/service';
import { classify } from '@ember/string';

type IUnitMeasures =
  | 'CENTIMETRE'
  | 'CUBE_CENTIMETRE'
  | 'CUBE_METRE'
  | 'DAY'
  | 'GRAM'
  | 'HOUR'
  | 'KG'
  | 'KILOGRAM'
  | 'LITRE'
  | 'LT'
  | 'METRE'
  | 'MILLIGRAM'
  | 'MILLILITER'
  | 'MILLIMETRE'
  | 'MINUTE'
  | 'MT'
  | 'PIECE'
  | 'SECOND'
  | 'SQUARE_CENTIMETRE'
  | 'SQUARE_METRE'
  | 'SQUARE_MILLIMETRE'
  | 'TICKET'
  | 'UN'
  | 'UNIT'
  | 'UNKNOWN';

export default class LabelService extends Service {
  // Services
  // ~~~~~

  @service declare intl: IntlService;

  /**
   * Given the unit measure, this function will check if the corresponding label key exists
   * if it exists, it will return the label translation otherwise it will simply format the key and return it
   *
   * @param {string|null} unit Unit measure
   * @returns {string} Translated Unit Measure, or formatted key if custom
   */
  unitMeasure(unit: string | IUnitMeasures) {
    if (!unit) {
      return '';
    }

    const intl = this.intl;
    const key = `unitMeasures.${unit.toUpperCase()}`;

    if (intl.exists(key)) {
      return this.intl.t(key);
    } else {
      return unit
        .split('_')
        .map((u) => classify(u.toLowerCase()))
        .join(' ');
    }
  }
  /**
   *  Transforms a JSONAPI error into a structure suitable for form validation
   *  @example
   *    ```javascript
   *      let error = {
   *         errors: [
   *           title: 'some.label.key',
   *         detail: {
   *           description: 'someField',
   *             otherArg: 'otherArgValue'
   *           }
   *         ]
   *       };
   *   ```
   *  @example
   *  let formError = this.labelService.formValidationsFromApiError(error);
   *  formError = { someField: ['Translated error description'] };
   *
   *  @param {Object} error JSONAPI error
   *  @returns {Object<string,string[]>|null} a form error structure if there are any errors, otherwise `null`
   */

  formValidationsFromApiError(error: IJSONAPIErrors) {
    const { errors = [] } = error;
    const formErrors: { description?: string[] } = {};
    errors.forEach((err) => {
      const { title, detail = {} } = err;
      const description = detail.description || 'default';
      if (description) {
        const newError = this.intl.t(title, {
          ...detail,
          description: this.intl.t(description)
        });
        const oldErrors: string[] = formErrors[description as keyof typeof formErrors] || [];
        oldErrors.push(newError);
        formErrors[description as keyof typeof formErrors] = oldErrors;
      }
    });
    return Object.keys(formErrors).length ? formErrors : null;
  }
}

declare module '@ember/service' {
  interface Registry {
    "label-service": LabelService;
  }
}
