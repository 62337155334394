import IntlService, { TOptions } from 'ember-intl/services/intl';
import BvFlashInstance, {
  IFlashInstanceOptions,
  IFlashMessageOptions,
  TFlashMessageType
} from 'volta/models/bv-flash';
import { indexByFn } from 'volta/utils/array-utils';

import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { cancel, later } from '@ember/runloop';
import Service, { service } from '@ember/service';
import { SafeString } from '@ember/template/-private/handlebars';
import { typeOf } from '@ember/utils';
import { cached, tracked } from '@glimmer/tracking';

import type { IAjaxError, IAPIError, IJSONAPIErrors, TError } from 'volta/utils/error-utils';
import type LocalizationService from 'volta/services/localization';
const DEFAULT_CLEAR_DURATION: number = 3200;
const DEFAULT_AUTO_CLEAR: boolean = true;
const MAX_MESSAGES: number = 3;

export default class BvFlashService extends Service {
  @service declare intl: IntlService;
  @service declare localization: LocalizationService;

  // Properties
  // ~~~~~
  defaultClearDuration = DEFAULT_CLEAR_DURATION;
  defaultAutoClear = DEFAULT_AUTO_CLEAR;
  @tracked queue: BvFlashInstance[] = [];

  @cached
  get queueMap() {
    return indexByFn(this.queue, (f) => f.id);
  }

  // Helper Function
  // ~~~~~

  // Helper methods for each type of notification
  error(message: string | SafeString, options?: IFlashMessageOptions) {
    this.add(
      Object.assign(
        {
          message,
          type: 'error'
        },
        options ?? {}
      )
    );
  }

  ajaxError(reason: IAjaxError, options: TOptions = {}) {
    const errors = reason.json?.errors ?? [];
    this._error(errors.length ? errors[0] : undefined, options);
  }

  apiError(reason?: TError, options: TOptions = {}) {
    const apiError = (reason as IJSONAPIErrors)?.errors;
    this._error(apiError?.length ? apiError[0] : undefined, options);
  }

  success(message: string | SafeString, options?: IFlashInstanceOptions) {
    this.add(
      Object.assign(
        {
          message,
          type: 'success' as TFlashMessageType
        },
        options ?? {}
      )
    );
  }

  info(message: string | SafeString, options?: IFlashMessageOptions) {
    this.add(
      Object.assign(
        {
          message,
          type: 'info' as TFlashMessageType
        },
        options ?? {}
      )
    );
  }

  warning(message: string | SafeString, options?: IFlashMessageOptions) {
    this.add(
      Object.assign(
        {
          message,
          type: 'warning' as TFlashMessageType
        },
        options ?? {}
      )
    );
  }

  add(options: IFlashMessageOptions) {
    this.enqueue(this.newFlashMessage(options));
    return this;
  }

  remove(flashInstance: BvFlashInstance) {
    if (!flashInstance) {
      return;
    }
    flashInstance.dismiss = true;
    this.dequeue(flashInstance);
  }

  @action
  clear() {
    if (this.queue.length === 0) {
      return;
    }

    this.queue = [];
    return this;
  }

  newFlashMessage(options: IFlashMessageOptions): BvFlashInstance {
    assert('The flash message cannot be empty.', options.message);

    return new BvFlashInstance({
      message: options.message,
      type: options.type ?? 'info',
      autoClear: options.autoClear ?? this.defaultAutoClear,
      clearDuration: options.clearDuration ?? this.defaultClearDuration,
      onClick: options.onClick,
      htmlContent: options.htmlContent ?? false
    });
  }

  enqueue(flashInstance: BvFlashInstance) {
    if (flashInstance.autoClear) {
      flashInstance.remaining = flashInstance.clearDuration;
      this.setupAutoClear(flashInstance);
    }
    if (this.queue.length && this.queue.length === MAX_MESSAGES) {
      this.dequeue(this.queue[this.queue.length - 1]);
    }
    this.queue = [...this.queue, flashInstance];
  }

  dequeue(flashInstance: BvFlashInstance) {
    this.queue = this.queue.filter((i) => i.id !== flashInstance.id);
  }

  setupAutoClear(flashInstance: BvFlashInstance) {
    flashInstance.startTime = Date.now();

    flashInstance.timer = later(
      this,
      () => {
        // Hasn't been closed manually
        if (this.queueMap[flashInstance.id]) {
          this.remove(flashInstance);
        }
      },
      flashInstance.remaining ?? 0
    );
  }

  pauseAutoClear(flashInstance: BvFlashInstance) {
    const { timer, clearDuration, startTime } = flashInstance;
    if (timer) {
      cancel(timer);
    }

    const elapsed = Date.now() - (startTime ?? 0);
    flashInstance.remaining = clearDuration ? clearDuration - elapsed : 0;
  }

  setDefaultAutoClear(autoClear: boolean) {
    assert('Default auto clear preference must be a boolean.', typeOf(autoClear) === 'boolean');
    this.defaultAutoClear = autoClear;
  }

  setDefaultClearNotification(clearDuration: number) {
    assert('Clear duration must be a number', typeOf(clearDuration) === 'number');
    this.defaultClearDuration = clearDuration;
  }

  // Private Functions
  // ~~~~~

  _error(error?: IAPIError, options: TOptions = {}) {
    const { fallbackMessage } = options;
    const { title = `${fallbackMessage}`, detail = {}, meta = {} } = error ?? {};
    const { customAssert = false, description, ...restDetails } = detail;
    const message = title ?? 'errors.internalServerError';
    const intlOptions: TOptions = Object.assign(
      meta,
      {
        ...restDetails,
        description: description ? this.localization.tOrElse(description) : undefined
      },
      options
    );

    this.error(
      customAssert
        ? message
        : this.localization.tOrElse(message, this.intl.t('errors.internalServerError'), intlOptions)
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'bv-flash': BvFlashService;
  }
}
