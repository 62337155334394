import { attr } from '@ember-data/model';
import BaseModel from 'volta/models/base-model';
import { TAcquisitionCode } from 'volta/models/constants/acquisition-codes';
import { TLeadTimeCategory } from 'volta/models/constants/lead-time-categories';
import { TVarCategory } from 'volta/models/constants/var-categories';
import { _normalize, collectionCommand, resourceCommand as command } from 'volta/utils/api';

export const ResourceName = {
  singular: 'bufferProfile',
  plural: 'bufferProfiles'
};

export const Commands = {
  UpdateBufferProfile: 'UpdateBufferProfile',
  CreateBufferProfile: 'CreateBufferProfile'
};

export default class BufferProfile extends BaseModel {
  /**
   * Collection commands
   */
  static createBufferProfile = collectionCommand(Commands.CreateBufferProfile, {
    after: _normalize
  });

  @attr('string') declare code: string;
  @attr('string') description?: string;
  @attr('number', { defaultValue: 1 }) varFactor?: number;
  @attr('number', { defaultValue: 0 }) varFactorMin?: number;
  @attr('number', { defaultValue: 1 }) varFactorMax?: number;
  @attr('number', { defaultValue: 1 }) leadTimeFactor?: number;
  @attr('number', { defaultValue: 0 }) leadTimeFactorMin?: number;
  @attr('number', { defaultValue: 1 }) leadTimeFactorMax?: number;
  @attr('string') varCategory?: TVarCategory;
  @attr('string') leadTimeCategory?: TLeadTimeCategory;
  @attr('string') acqCode?: TAcquisitionCode;
  @attr('boolean', { defaultValue: false }) declare isDefault: boolean;
  @attr('array', {
    defaultValue: () => []
  })
  declare tags: string[];

  get bufferProfileId() {
    return this.id;
  }

  /**
   * Commands
   */

  updateBufferProfile = command(Commands.UpdateBufferProfile);
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'buffer-profile': BufferProfile;
  }
}