import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import { TDafField } from 'volta/models/planning-setting';
import { getEveryYearWeek, getWeeksOfYearMonth } from 'volta/utils/date-utils';
import { round } from 'volta/utils/math-utils';

import { capitalize } from '@ember/string';
import { isEmpty, isNone } from '@ember/utils';

import DafProfileModel, {
  DEFAULT_DAF_UNIT,
  IDAFYearly
} from '../models/daf-profile';
import { distinct } from './array-utils';

import type {
  IDAFMonthly,
  IDAFValueU,
  IDAFWeekly,
  IYearZoneDef,
  IZoneDef,
  TDAFUnit,
  TDAFZoneUnit,
  TZone
} from 'volta/models/types/planning-settings';

const { keys, assign, values, entries } = Object;

export interface TZoneDefUnits {
  adu: TDAFZoneUnit;
  redBase: TDAFZoneUnit;
  redSafety: TDAFZoneUnit;
  yellow: TDAFZoneUnit;
  green: TDAFZoneUnit;
  lt: TDAFZoneUnit;
}

export function dafKeyFromZone(zone: string) {
  return `daf${zone === 'adu' ? '' : capitalize(zone)}` as keyof IDAFYearly;
}

export function dafIsEmpty(dafs: IDAFMonthly | IDAFWeekly, year?: number) {
  const [unitKeys, unitValues] = getKeysAndValues(dafs);

  const isEmpty = (d: number) => isNone(d);
  const isFull = year ? unitKeys.length === getEveryYearWeek(year).length : unitKeys.length === 12;

  return !unitKeys.length || (isFull && unitValues.every(isEmpty));
}

export function dafIsFullOrEmpty(dafs: IDAFMonthly | IDAFWeekly, year?: number) {
  const [unitKeys, unitValues] = getKeysAndValues(dafs);

  const is100 = (d: number) => d === 1;
  const isEmpty = (d: number) => isNone(d);
  const isFull = year ? unitKeys.length === getEveryYearWeek(year).length : unitKeys.length === 12;

  return !unitKeys.length || (isFull && (unitValues.every(is100) || unitValues.every(isEmpty)));
}

export const YEAR_ZONE_DEF: Record<TZone, IZoneDef> = {
  adu: {
    zone: 'adu',
    label: 'adu',
    weekly: {},
    monthly: {},
    bgHue: 'var(--bv-color-teal-4-rgb)',
    updatedWeeks: [],
    updatedMonths: []
  },
  redBase: {
    zone: 'redBase',
    label: 'redBase',
    weekly: {},
    monthly: {},
    bgHue: 'var(--bv-color-red-6-rgb)',
    updatedWeeks: [],
    updatedMonths: []
  },
  redSafety: {
    zone: 'redSafety',
    label: 'redSafety',
    weekly: {},
    monthly: {},
    bgHue: 'var(--bv-color-red-4-rgb)',
    updatedWeeks: [],
    updatedMonths: []
  },
  yellow: {
    zone: 'yellow',
    label: 'yellow',
    weekly: {},
    monthly: {},
    bgHue: 'var(--bv-color-yellow-4-rgb)',
    updatedWeeks: [],
    updatedMonths: []
  },
  green: {
    zone: 'green',
    label: 'green',
    weekly: {},
    monthly: {},
    bgHue: 'var(--bv-color-green-4-rgb)',
    updatedWeeks: [],
    updatedMonths: []
  },
  lt: {
    zone: 'lt',
    label: 'leadTimeShort',
    weekly: {},
    monthly: {},
    bgHue: 'var(--bv-color-purple-4-rgb)',
    updatedWeeks: [],
    updatedMonths: []
  }
};

export const ZONES_DEFS: IYearZoneDef = {
  [new Date().getFullYear()]: _cloneDeep(YEAR_ZONE_DEF)
};

export type TDafByYear = Record<number, IDAFYearly>;

export const DEFAULT_DAF: IDAFYearly = {
  dafWeekly: {},
  dafMonthly: {},
  dafUnit: DEFAULT_DAF_UNIT,
  dafRedBaseWeekly: {},
  dafRedBaseMonthly: {},
  dafRedBaseUnit: DEFAULT_DAF_UNIT,
  dafRedSafetyMonthly: {},
  dafRedSafetyWeekly: {},
  dafRedSafetyUnit: DEFAULT_DAF_UNIT,
  dafGreenMonthly: {},
  dafGreenWeekly: {},
  dafGreenUnit: DEFAULT_DAF_UNIT,
  dafYellowWeekly: {},
  dafYellowMonthly: {},
  dafYellowUnit: DEFAULT_DAF_UNIT,
  dafLtMonthly: {},
  dafLtWeekly: {},
  dafLtUnit: DEFAULT_DAF_UNIT
};

export function updateDafFromCalendar(
  value: IDAFValueU,
  dafMonthly: IDAFMonthly,
  dafWeekly: IDAFWeekly
) {
  const {} = Object;

  const dafMonthlyCopy = assign({}, dafMonthly);
  const dafWeeklyCopy = assign({}, dafWeekly);

  if (value.unit.toUpperCase() === 'MONTHLY') {
    const dafValue = round(value.value);
    const date = moment(value.date);

    dafMonthlyCopy[`${value.period}` as keyof IDAFMonthly] = dafValue;

    getWeeksOfYearMonth(date.year(), date.month()).forEach((week) => {
      dafWeeklyCopy[`${week}` as keyof IDAFWeekly] = dafValue;
    });
  } else {
    dafWeeklyCopy[`${value.period}` as keyof IDAFWeekly] = round(value.value);
  }
  return { dafMonthly: dafMonthlyCopy, dafWeekly: dafWeeklyCopy };
}

type IDAFArgs = { [K in TDafField]?: IDAFMonthly | IDAFWeekly | string };

const getKeysAndValues = (object: object) => {
  const { keys, values } = Object;

  return [keys(object), values(object)];
};

/**
 * It takes a set of zones and a set of arguments and returns a new set of zones with the arguments
 * applied.
 * @param zones - A map of the different zones.
 * @param args - TDafByYear
 * @param year - The year where to update the zone.
 * @param yearToCopy - If the zone is not filled, it can take a template year instead.
 * @returns A new object with the same keys as the original, but with the weekly and monthly properties
 * updated.
 */
export function updateZones(
  zones: IYearZoneDef,
  args: TDafByYear,
  year: number,
  yearToCopy?: number
): IYearZoneDef {
  let yearZones = zones[year];
  if (!yearZones) {
    yearZones = _cloneDeep(YEAR_ZONE_DEF);
  }

  const result = { ...yearZones };
  const dafs = (args[year] ?? {}) as IDAFArgs;

  keys(result).forEach((key: TZone) => {
    const dafKey = dafKeyFromZone(key);

    const dafsWeekly = dafs[`${dafKey}Weekly` as TDafField] ?? {};
    const dafsMonthly = dafs[`${dafKey}Monthly` as TDafField] ?? {};

    const yearUpdatedMonths = dafIsFullOrEmpty(dafsMonthly as IDAFMonthly)
      ? []
      : (keys(dafsMonthly) as Array<keyof IDAFMonthly>);
    const yearUpdatedWeeks = dafIsFullOrEmpty(dafsWeekly as IDAFWeekly, year)
      ? []
      : (keys(dafsWeekly) as Array<keyof IDAFWeekly>);

    let monthlyToCopy: IDAFMonthly = {};
    let weeklyToCopy: IDAFWeekly = {};
    let updatedCopiedMonths: Array<keyof IDAFMonthly> = [];
    let updatedCopiedWeeks: Array<keyof IDAFWeekly> = [];

    if (yearToCopy) {
      const {
        updatedMonths: yearToCopyUpdatedMonths,
        updatedWeeks: yearToCopyUpdatedWeeks,
        monthly: monthlyYearToCopy,
        weekly: weeklyYearToCopy
      } = (zones[yearToCopy] ?? YEAR_ZONE_DEF)[key];
      updatedCopiedMonths = yearToCopyUpdatedMonths;
      updatedCopiedWeeks = yearToCopyUpdatedWeeks;
      monthlyToCopy = monthlyYearToCopy;
      weeklyToCopy = weeklyYearToCopy;
    }

    const updatedMonths = distinct([...yearUpdatedMonths, ...updatedCopiedMonths]) as Array<
      keyof IDAFMonthly
    >;
    const updatedWeeks = distinct([...updatedCopiedWeeks, ...yearUpdatedWeeks]) as Array<
      keyof IDAFWeekly
    >;
    const monthly: IDAFMonthly = {};
    updatedMonths.forEach((month: keyof IDAFMonthly) => {
      monthly[month] = (dafsMonthly[month] as number) ?? monthlyToCopy[month];
    });
    const weekly: IDAFWeekly = {};

    updatedMonths.forEach((month) => {
      getWeeksOfYearMonth(year, +month - 1).forEach((week) => {
        weekly[`${week}` as keyof IDAFWeekly] =
          (dafsMonthly[month] as number) ?? monthlyToCopy[month];
      });
    });

    updatedWeeks.forEach((week: keyof IDAFWeekly) => {
      weekly[week] = ((dafsWeekly as IDAFWeekly)[week] as number) ?? weeklyToCopy[week];
    });

    result[key].weekly = weekly;
    result[key].monthly = monthly;
    result[key].updatedMonths = updatedMonths;
    result[key].updatedWeeks = updatedWeeks;
    result[key].updated = values(result[key].weekly).any(
      (v) => typeof v === 'number' && (v !== 1 || !isEmpty(v))
    );
  });

  return _cloneDeep({ ...zones, [year]: result });
}

export function initUnits(dafs: IDAFYearly | DafProfileModel | undefined): TZoneDefUnits {
  return {
    adu: dafs?.dafUnit ?? DEFAULT_DAF_UNIT,
    redBase: dafs?.dafRedBaseUnit ?? DEFAULT_DAF_UNIT,
    redSafety: dafs?.dafRedSafetyUnit ?? DEFAULT_DAF_UNIT,
    yellow: dafs?.dafYellowUnit ?? DEFAULT_DAF_UNIT,
    green: dafs?.dafGreenUnit ?? DEFAULT_DAF_UNIT,
    lt: dafs?.dafLtUnit ?? DEFAULT_DAF_UNIT
  };
}
/**
 *
 * Apply zone defs for future years depending on the last year that has been filled.
 * This data is not sent to the API - Useful for the UI.
 *
 * @param dafs - The dafs source
 * @param selectedYear - The year to copy
 * @param zoneKey - The selected zone
 * @param dafUnit - The period selected
 * @returns DAF (API values) or nothing if year is not copied
 */
export function yearToShallowCopy(
  dafs: TDafByYear,
  { dafUnit, selectedYear, zoneKey }: { dafUnit: TDAFUnit; selectedYear: number; zoneKey: TZone }
): number | undefined {
  const dafYearKeys: Record<TDAFUnit, keyof IDAFYearly> = {
    WEEKLY: (dafKeyFromZone(zoneKey) + 'Weekly') as keyof IDAFYearly,
    MONTHLY: (dafKeyFromZone(zoneKey) + 'Monthly') as keyof IDAFYearly
  };

  const selectedDafUnit = dafs[selectedYear]?.[dafYearKeys[dafUnit]] ?? {};
  const selectedDafMonthly = dafs[selectedYear]?.[dafYearKeys.MONTHLY] ?? {};
  const [dafUnitKeys, dafUnitValues] = getKeysAndValues(selectedDafUnit as object);
  const [dafMonthlyKeys, dafMonthlyValues] = getKeysAndValues(selectedDafMonthly as object);

  if (
    (dafUnitKeys.length && dafMonthlyValues.filter(Boolean).length && dafUnit === 'MONTHLY') ||
    (dafUnit === 'WEEKLY' && dafMonthlyKeys.length && dafUnitValues.filter(Boolean).length)
  ) {
    return undefined;
  }

  const filledDafYears = keys(dafs)
    .map((y) => +y)
    .filter((year) => year < selectedYear);

  if (!filledDafYears.length) {
    return undefined;
  }

  const yearToCopy = Math.max(...filledDafYears);

  if (dafs[yearToCopy]) {
    return yearToCopy;
  }

  return undefined;
}

export function dafsByYear(dafs?: IDAFYearly): TDafByYear | undefined {
  if (!dafs) {
    return undefined;
  }

  const dafsByYear = entries(dafs).reduce((acc: TDafByYear, [key, daf]) => {
    const ignore = (k: string) => k.includes('Unit') || !k.includes('daf');
    if (ignore(key)) {
      return acc;
    }

    if (!daf ||( values(daf).every((d) => typeof d === 'object' && d !== null && !keys(d).length))) {
      return acc;
    }

    const years = keys(daf).map((y) => +y);
    years.forEach((year) => {
      acc[year] = { ...(acc[year] ?? {}), [key]: daf[year] };
    });

    return acc;
  }, {});

  return dafsByYear;
}
