import BaseModel from 'volta/models/base-model';
import { resourceCommand } from 'volta/utils/api';

import { attr, belongsTo } from '@ember-data/model';

import type { TTheme } from 'volta/modifiers/theme-mode';
import type {
  IDashboardWidget,
  IMenuPreferences,
  IPagesPreferences,
  IUpdateDashboardCmd,
  IUpdateInterfaceCmd,
  IUpdatePreferencesCmd
} from './types/user';
import type User from './user';

export const UserPreferencesResourceName = {
  singular: 'userPreference',
  plural: 'userPreferences'
};

export const UserPreferencesCommands = {
  UpdateInterface: 'UpdateInterface',
  UpdatePreferences: 'UpdatePreferences',
  UpdateDashboard: 'UpdateDashboard'
};

export default class UserPreference extends BaseModel {
  /**
   * Commands
   */
  updateInterface = resourceCommand<IUpdateInterfaceCmd, UserPreference>(
    UserPreferencesCommands.UpdateInterface
  );
  updatePreferences = resourceCommand<IUpdatePreferencesCmd, UserPreference>(
    UserPreferencesCommands.UpdatePreferences
  );
  updateDashboard = resourceCommand<IUpdateDashboardCmd, UserPreference>(
    UserPreferencesCommands.UpdateDashboard
  );

  @attr('string')
  userId!: string;

  @attr('string', { defaultValue: () => 'en-gb' })
  locale?: string;

  @attr('string', { defaultValue: () => 'Europe/Paris' })
  timeZone?: string;

  @attr('string')
  avatar?: string;

  @attr('string')
  theme!: TTheme;

  @attr('array')
  dashboard!: IDashboardWidget[];

  @attr()
  menu!: IMenuPreferences;

  @attr({ defaultValue: () => ({}) })
  pages?: Record<string, IPagesPreferences>;

  @belongsTo('user', { async: false })
  user!: User;

  get profilePrefs(): IUpdatePreferencesCmd {
    return {
      locale: this.locale,
      timeZone: this.timeZone,
      avatar: this.avatar,
      theme: this.theme
    };
  }

  getPagePreferences<T = unknown>(key: string) {
    return this.pages?.[key] as IPagesPreferences<T> | undefined;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-preference': UserPreference;
  }
}
