import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import { computed, get, getProperties, set } from '@ember/object';
import Component from '@ember/component';
import ifUndefined from 'volta/macros/if-undefined';
import noopIfUndefined from 'volta/macros/noop-if-undefined';
import { task, timeout } from 'ember-concurrency';
import { Promise } from 'rsvp';

export default Component.extend({
  // Services
  // ~~~~~
  infinity: service(),
  store: service(),
  intl: service(),

  // Properties
  // ~~~~~

  tagName: '',

  /**
   * @property {string} [label] Select label
   * @type {string}
   */
  label: null,

  /**
   * @property {string} [dropdownClass] Select dropdown css classes
   * @type {string}
   */
  dropdownClass: null,

  /**
   * @property {string} [triggerClass] Select trigger css classes
   * @type {string}
   */
  triggerClass: null,

  /**
   * @property {boolean} [multiple] Render a BvSelect or BvSelectMulti
   * @type {boolean}
   */
  multiple: false,

  /**
   * @property {string} [id] The model id key
   * @type {string}
   */
  idKey: 'id',

  /**
   * Whether we should auto select the single item in the list if no selected value is specified
   * @type {boolean}
   */
  selectSingleValueIfEmpty: false,

  value: null,
  propertyKey: null,
  disabled: false,
  values: ifUndefined([]),
  options: ifUndefined([]),
  excludedOptions: ifUndefined([]),

  // Callbacks
  // ~~~~~

  onChange: noopIfUndefined,
  onCreate: noopIfUndefined,

  // Computed Properties
  // ~~~~~

  selectedOptions: computed('options', 'values.[]', 'idKey', function () {
    const { values, idKey } = getProperties(this, 'values', 'idKey');
    return this.searchSelectedOptionsPromise(values).then((result) =>
      this._onSearchSelectedOptionsSuccess(result, idKey, values)
    );
  }),

  selectedOption: computed('options', 'value', 'idKey', function () {
    const { value, idKey } = getProperties(this, 'value', 'idKey');
    return this.searchSelectedOptionPromise(value).then((result) =>
      this._onSearchSelectedOptionSuccess(result, idKey, value)
    );
  }),

  // Lifecycle Hooks
  // ~~~~~

  // Actions
  // ~~~~~

  actions: {
    valuesDidChange(values) {
      let ids = this.idKey
        ? isPresent(values) && values.length
          ? values.mapBy(this.idKey)
          : []
        : values;
      return this.onChange(ids, values);
    },

    valueDidChange(value) {
      const key = this.idKey;
      const idValue = isPresent(value) && isPresent(key) ? get(value, key) : value;
      return this.onChange(idValue, value);
    },

    /**
     * Checks whether the BvSelectCreate component should show the create button
     *
     * @param {string} term Searched term
     * @param {Object[]} options Select options
     * @returns {boolean}
     */
    showCreateWhen(term, options) {
      return isPresent(term) && !options?.includes(term);
    },

    /**
     * Performs a search task when search term is empty (avoid empty results)
     *
     * @param {string} term Searched term
     */
    onInput(term) {
      if (!term || !term.length) {
        return this.searchTask.perform(term);
      }
    }
  },

  // Helper Functions
  // ~~~~~

  searchTask: task(function* (term) {
    yield timeout(isPresent(term) ? 600 : 0);

    const options = yield this.searchPromise(this.payloadFromTerm(term));
    set(this, 'options', options);

    if (
      !term &&
      options.length === 1 &&
      this.selectSingleValueIfEmpty &&
      !this.values.length &&
      !this.value
    ) {
      const option = options.toArray()[0];
      if (this.multiple) {
        this.send('valuesDidChange', options);
      } else {
        this.send('valueDidChange', option);
      }
    }
  }).restartable(),

  payloadFromTerm(term) {
    const { values = [], value, multiple, excludedOptions = [], idKey } = this;
    return {
      search: term,
      selected: values?.length ? values : value ? [value] : [],
      excluded: excludedOptions.reduce((acc, o) => {
        if (typeof o === 'string') {
          acc.push(o);
        } else {
          const id = o[idKey ?? 'id'];
          if (id) {
            acc.push(id);
          }
        }
        return acc;
      }, [])
    };
  },

  searchSelectedOptionPromise(/*option*/) {
    return Promise.resolve(this.options);
  },

  searchSelectedOptionsPromise(/*options*/) {
    return Promise.resolve(this.options);
  },

  // Private Callbacks/Handlers
  // ~~~~~

  _onSearchSelectedOptionsSuccess(result, idKey, values) {
    if (this.isDestroyed || this.isDestroying) return [];
    return result.filter((item) => {
      return values.includes(idKey ? get(item, idKey) : item);
    });
  },

  _onSearchSelectedOptionSuccess(result, idKey, value) {
    if (this.isDestroyed || this.isDestroying) return;
    return idKey ? (result && result.length ? result.findBy(idKey, value) : null) : value;
  }
});
