import { _normalize, collectionCommand } from 'volta/utils/api';

import Task, { TaskCommands } from './task';
import { TPOStatus, TPOValidationStatus } from "volta/models/types/planning-order";

interface ILinkedEntityPayload {
  skuId: string;
  partCode: string;
  skuDescription?: string;
  warehouseCode: string;
  dueDate: string;
  reorderQty: number;
  unitMeasure: string;
  status: TPOStatus;
  validationStatus?: TPOValidationStatus;
  orderNum?: string;
  orderLine?: string;
}

export default class PoTask extends Task<Record<string, any>, ILinkedEntityPayload> {
  /**
   * Commands
   */

  static createTask = collectionCommand(TaskCommands.CreateTask, {
    after: _normalize,
    ajaxOptions: { queryParams: { include: 'createdBy,resolvedBy,assignees' } }
  });

  static updateTask = collectionCommand(TaskCommands.UpdateTask, {
    after: _normalize
  });

  static deleteTask = collectionCommand(TaskCommands.DeleteTask, {
    after: _normalize
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'po-task': PoTask;
  }
}
