import Component from '@glimmer/component';

export interface ITagsFilterDefinitionArgs {
  entityTypes: Array<
    'skus' | 'bufferProfiles' | 'dafProfiles' | 'aduProfiles' | 'parts' | 'importHistos'
  >;
  warehouseIds?: string[];
  tag?: string;
}

export default class TagsFilter extends Component<IFilterArgs<ITagsFilterDefinitionArgs>> {}
