import moment from 'moment-timezone';
import TaskService from 'volta/services/task-service';
import { localizedFormatMD } from 'volta/utils/date-utils';

import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached } from '@glimmer/tracking';

import type IntlService from 'ember-intl/services/intl';
import type { IResourceName } from 'volta/models/base-model';
import type { TSelectedView } from '../..';
import type { TTaskType, UTask } from 'volta/models/types/tasks';

interface IArgs {
  isLoading?: boolean;
  resourceName: IResourceName;
  entityResourceName: IResourceName;
  dueDateIdPrefix: string;
  tasks: UTask[];
  selectedTask?: UTask;
  onSubmit: (task: UTask) => void;
  onResolve: (task: UTask) => void;
  onDelete: (task: UTask) => void;
  onDraft: (task: UTask) => void;
  onSelect: (task: UTask) => void;
  isAutoResolvable?: boolean;
  selectedView: TSelectedView;
  taskType: TTaskType;
  newDisabled?: boolean;
  showTaskType?: boolean;
  readonlyTasks?: boolean;
  hideAddButton?: boolean;
}

export default class TasksListDateSku extends Component<IArgs> {
  @service taskService!: TaskService;
  @service intl!: IntlService;

  noDueDateId = this.intl.t('tasksPage.noDueDate');

  @cached
  get dueTasks() {
    return this.args.tasks.filter((t) => !!t.dueDate);
  }

  @cached
  get sections() {
    const { indexTasksByDateAndEntity } = TaskService;
    const today = new Date();

    return [
      {
        label: 'overdue',
        labelColor: 'danger',
        tasks: indexTasksByDateAndEntity(
          this.dueTasks.filter((t) => moment(t.dueDate).isBefore(today, 'day')),
          this.noDueDateId
        )
      },
      {
        label: 'today',
        labelColor: 'warning',
        tasks: indexTasksByDateAndEntity(
          this.dueTasks.filter((t) => moment(t.dueDate).isSame(today, 'day')),
          this.noDueDateId
        )
      },
      {
        label: 'later',
        labelColor: 'success',
        tasks: indexTasksByDateAndEntity(
          this.dueTasks.filter((t) => moment(t.dueDate).isAfter(today, 'day')),
          this.noDueDateId
        )
      }
    ].filter((section) => !!Object.keys(section.tasks).length);
  }

  skuId = (formattedLabel: string) => {
    return formattedLabel.split('|').pop();
  };

  dueDate = (formattedLabel: string) => {
    const split = formattedLabel.split('|');
    return split[1] ? localizedFormatMD(moment(split[0], 'DD/MM/YYYY')) : undefined;
  };
}
