import EmberObject, { get } from '@ember/object';

export default EmberObject.extend({
  1: 1.0,  2: 1.0,  3: 1.0,  4: 1.0,
  5: 1.0,  6: 1.0,  7: 1.0,  8: 1.0,
  9: 1.0,  10: 1.0,  11: 1.0,  12: 1.0,
  13: 1.0,  14: 1.0,  15: 1.0,  16: 1.0,
  17: 1.0,  18: 1.0,  19: 1.0,  20: 1.0,
  21: 1.0,  22: 1.0,  23: 1.0,  24: 1.0,
  25: 1.0,  26: 1.0,  27: 1.0,  28: 1.0,
  29: 1.0,  30: 1.0,  31: 1.0,  32: 1.0,
  33: 1.0,  34: 1.0,  35: 1.0,  36: 1.0,
  37: 1.0,  38: 1.0,  39: 1.0,  40: 1.0,
  41: 1.0,  42: 1.0,  43: 1.0,  44: 1.0,
  45: 1.0,  46: 1.0,  47: 1.0,  48: 1.0,
  49: 1.0,  50: 1.0,  51: 1.0,  52: 1.0,
  53: 1.0,


  toNative() {
    let obj = {};

    for(let i=1; i<54;  i++) {
      obj[`${i}`] = get(this, `${i}`);
    }

    return obj;
  }
}).reopenClass({
  fromJson(json) {
    return this.create(json && typeof json === 'object' ? json : {});
  }
});
