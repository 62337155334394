import Modifier from 'ember-modifier';

import { action } from '@ember/object';
// @ts-ignore
import { registerDestructor } from '@ember/destroyable';

interface IArgs {
  positional: [Record<string, string>];
  named: Record<string, string>;
}

export default class CssVarsModifier extends Modifier<IArgs> {
  elementToStyle?: HTMLElement;

  constructor(owner: any, args: IArgs) {
    super(owner, args);
    registerDestructor(this, () => this.execute(args.positional, args.named, true));
  }

  modify(
    element: HTMLElement,
    positional: [Record<string, string>],
    named: Record<string, string>
  ): void {
    if (!this.elementToStyle) {
      this.elementToStyle = element;
    }
    this.execute(positional, named);
  }

  @action
  execute(
    positional: [Record<string, string>],
    named: Record<string, string>,
    remove: boolean = false
  ) {
    if (this.elementToStyle) {
      Object.entries(Object.assign({}, ...positional, named)).forEach(([key, val]) => {
        const k = key.startsWith('--') ? key : `--${key}`;
        if (!val || remove) {
          this.elementToStyle?.style.removeProperty(k);
        } else {
          this.elementToStyle?.style.setProperty(k, val);
        }
      });
    }
  }
}
