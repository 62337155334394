import { attr, belongsTo } from '@ember-data/model';
import BaseModel from 'volta/models/base-model';
import UserProfile from 'volta/models/user-profile';
import Task from 'volta/models/task';
import { cached } from '@glimmer/tracking';
import { normalize } from 'volta/utils/api/serialize-and-push';

export const ResourceName = {
  singular: 'activity',
  plural: 'activities'
};

export default class Activity extends BaseModel {
  @attr('string') command!: string;
  @attr() payload: any;
  @attr('array') entityIds!: string[];
  @attr('boolean', { defaultValue: () => true }) isMultiple!: boolean;
  @attr('string') entityType?: string;
  @attr('boolean', { defaultValue: () => false }) entityIsNew?: boolean;

  @belongsTo('user-profile', { async: false }) createdBy!: UserProfile;

  get userId() {
    return this.createdBy.userId;
  }

  get userName() {
    return this.createdBy.login;
  }

  get entityId() {
    return this.isMultiple ? undefined : this.entityIds[0];
  }

  @cached
  get commentPayload() {
    if (['CreateTask'].includes(this.command) && (this.payload as Task).taskType === 'COMMENT') {
      let existing = this.store.peekRecord('task', this.payload.id);
      if (existing && existing.updatedAt >= this.payload.updatedAt) {
        return existing;
      } else {
        let newTask = normalize('task', this.payload) as Task;
        newTask.createdBy = this.createdBy;
        return newTask;
      }
    }
    return undefined;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    activity: Activity;
  }
}
