import type IntlService from 'ember-intl/services/intl';
import { TFormulaError } from 'volta/helpers/custom-field-formula-validation';
import { IFormulaBuilder } from 'volta/models/types/customFieldDefinition';

const addError = (intl: IntlService, errors: TFormulaError, index: string, i18nKey: string) => {
  const error = intl.t(`customFieldsDefinitionsPage.formula.errors.${i18nKey}`);
  if (errors[index]?.includes(error)) {
    return errors;
  }
  errors[index] = errors[index] ? [...errors[index], error] : [error];
  return errors;
};

export default function (
  intl: IntlService,
  formula: IFormulaBuilder,
  id: string,
  isPristine: boolean = false
) {
  let errors: TFormulaError = {};
  if (isPristine) {
    return false;
  }
  if (!formula.id) {
    errors = addError(intl, errors, 'root', 'empty');

    return id ? errors[id] : !!Object.keys(errors).length;
  }

  const checkNode = (node: IFormulaBuilder, parent?: IFormulaBuilder): IFormulaBuilder => {
    const { customFieldId, value, type, name } = node;
    const children = parent?.args;

    if (children && parent) {
      if (parent.name !== 'if' && parent.type === 'OPERATOR' && !!parent.resultType) {
        const hasSameResultTypes = children.every(
          (c: IFormulaBuilder) => c.resultType && c.resultType === parent.resultType
        );
        if (!hasSameResultTypes) {
          const index = parent.id;
          if (index) {
            errors = addError(intl, errors, index, 'resultType');
          }
        }
      }
    }

    if (name && (type === 'OPERATOR' || type === 'FUNCTION') && name !== 'now') {
      if (['lower', 'upper'].includes(name) && (!node.args || node.args.length < 1)) {
        const index = node.id;
        if (index) {
          errors = addError(intl, errors, index, 'missingValue');
        }
      } else if (
        ['join', 'add', 'multiply', 'subtract', 'equal', 'notEqual'].includes(name) &&
        (!node.args || node.args.length < 2)
      ) {
        const index = node.id;
        if (index) {
          errors = addError(intl, errors, index, 'missingValue');
        }
      } else if (name === 'if' && (!node.args || node.args.length < 3)) {
        const index = node.id;
        if (index) {
          errors = addError(intl, errors, index, 'missingValue');
        }
      }
    }

    if ((type === 'PROPERTY' && !customFieldId) || (type === 'CONSTANT' && !value)) {
      const index = node.id;
      if (index) {
        errors = addError(intl, errors, index, 'missingValue');
      }
    }

    if (customFieldId && type === 'CONSTANT') {
      const index = node.id;
      if (index) {
        errors = addError(intl, errors, index, 'type');
      }
    }
    return node;
  };

  const formatTree = (currentNode: IFormulaBuilder, parent?: IFormulaBuilder) => {
    const formattedNode = checkNode(currentNode, parent);
    if (!currentNode.args) {
      return formattedNode;
    }

    formattedNode.args = currentNode.args.map((arg: IFormulaBuilder) =>
      formatTree(arg, currentNode)
    );
    return formattedNode;
  };

  formatTree(formula);

  return id ? errors[id] : !!Object.keys(errors).length;
}
