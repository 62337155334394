import { pluralize } from 'ember-inflector';

import JSONAPISerializer from '@ember-data/serializer/json-api';
import { camelize } from '@ember/string';

import type ModelRegistry from 'ember-data/types/registries/model';
export default class ApplicationSerializer extends JSONAPISerializer {
  keyForAttribute(key: string) {
    return key;
  }

  keyForRelationship(key: string /*, relationship, method*/) {
    return key;
  }

  payloadKeyFromModelName<K extends keyof ModelRegistry>(modelName: K) {
    return pluralize(camelize(modelName as string));
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    application: ApplicationSerializer;
  }
}
