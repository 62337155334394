import { btnGroupOptions } from 'volta/utils/style-utils';

export type TAcquisitionCode = 'BUY' | 'DISTRIBUTE' | 'MAKE';

export const AcquisitionCodes: Record<TAcquisitionCode, string> = {
  BUY: 'acqCodes.BUY',
  DISTRIBUTE: 'acqCodes.DISTRIBUTE',
  MAKE: 'acqCodes.MAKE'
};

export const AcquisitionCodesOptions = btnGroupOptions(AcquisitionCodes);

export const AcquisitionCodesArray: TAcquisitionCode[] = Object.keys(
  AcquisitionCodes
) as TAcquisitionCode[];

export default AcquisitionCodes;
