const hhmmss = {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric'
};
const hhmm = {
  hour: 'numeric',
  minute: 'numeric'
};

export default {
  time: {
    hhmmss: hhmmss,
    hhmm: hhmm
  },
  date: {
    hhmmss: hhmmss,
    normal: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }
  },
  number: {
    EUR: {
      symbol: '€',
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    },
    USD: {
      symbol: '$',
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    },
    GBP: {
      symbol: '£',
      style: 'currency',
      currency: 'GBP',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    },
    JPY: {
      symbol: '¥',
      style: 'currency',
      currency: 'JPY',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    },
    CNY: {
      symbol: '元',
      style: 'currency',
      currency: 'CNY',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    },
    INTEGER: {
      symbol: '123',
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    },
    DECIMAL: {
      symbol: '0.01',
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2
    },
    PERCENT: { symbol: '%', style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 2 }
  }
};
