import {
  validateFormat,
  validateLength,
  validateNumber,
  validatePresence
} from 'ember-changeset-validations/validators';
import moment from 'moment-timezone';
import { TCustomFieldTableValue, TFieldType } from 'volta/models/types/customFieldDefinition';
import { isNaN, parseNumber } from 'volta/utils/math-utils';
import checkTableColumns from 'volta/validators/check-table-columns';
import validateCompareDate from 'volta/validators/compare-date';
import validateOnCheck from 'volta/validators/on-check';

const checkDefaultValue = (
  defaultValue: TCustomFieldTableValue,
  fieldType: TFieldType,
  before: Date,
  after: Date,
  gte: number,
  lte: number,
  list: string[]
) => {
  if (!defaultValue) {
    return true;
  }
  switch (fieldType) {
    case 'TEXT':
      if (list && list.length) {
        return typeof defaultValue === 'string' && list.includes(defaultValue);
      }
      return typeof defaultValue === 'string';
    case 'ARRAY':
      const val = defaultValue as string[];
      return val.length ? val.every((v) => list.includes(v)) : true;
    case 'NUMBER':
      const numberValidations = [] as boolean[];
      if (gte) {
        numberValidations.push(+defaultValue >= +gte);
      }
      if (lte) {
        numberValidations.push(+defaultValue <= +lte);
      }

      numberValidations.push(!isNaN(defaultValue));
      return !numberValidations.includes(false);
    case 'BOOLEAN':
      return typeof defaultValue === 'boolean';
    case 'DATE':
      const dateValidations = [] as boolean[];
      if (before) {
        dateValidations.push(moment(defaultValue as Date).isSameOrBefore(before));
      }
      if (after) {
        dateValidations.push(moment(defaultValue as Date).isSameOrAfter(after));
      }
      dateValidations.push(moment(defaultValue as Date).isValid());

      return !dateValidations.includes(false);
    default:
      return true;
  }
};

export const tableFieldValidations = {
  name: validatePresence(true),
  fieldType: validatePresence(true)
};

export default {
  name: validatePresence(true),
  helpText: [],
  acqCode: [],
  warehouses: [],
  fieldType: validatePresence(true),
  slug: [validatePresence(true), validateFormat({ regex: /^[a-z]+(_[a-z]+)*$/g })],
  defaultValue: [
    validateOnCheck({
      checkKeys: [
        'fieldType',
        'fieldConstraints.onOrBefore',
        'fieldConstraints.onOrAfter',
        'fieldConstraints.gte',
        'fieldConstraints.lte',
        'fieldConstraints.list'
      ],
      checkCondition: checkDefaultValue,
      i18nKey: 'customFieldsDefinitionsPage.defaultValue'
    })
  ],
  fieldConstraints: {
    lte: [
      validateNumber({ allowBlank: true }),
      validateOnCheck({
        checkKeys: ['fieldConstraints.gte'],
        checkCondition: (lte: number, gte: number) =>
          parseNumber(lte) && parseNumber(gte) ? Number(gte) <= Number(lte) : true,
        i18nKey: 'customFieldsDefinitionsPage.constraints.lessThan'
      })
    ],
    gte: [validateNumber({ allowBlank: true })],
    onOrBefore: [
      validateCompareDate({
        comparedKey: 'fieldConstraints.onOrAfter',
        i18nKey: 'customFieldsDefinitionsPage.constraints.beforeDate',
        direction: 'AFTER',
        canBeEqual: true,
        allowBlankDates: true
      })
    ],
    onOrAfter: [
      validateCompareDate({
        comparedKey: 'fieldConstraints.onOrBefore',
        i18nKey: 'customFieldsDefinitionsPage.constraints.afterDate',
        direction: 'BEFORE',
        canBeEqual: true,
        allowBlankDates: true
      })
    ],
    list: [validateLength({ min: 1, allowBlank: true })],
    columns: [checkTableColumns(), validateLength({ min: 1 })]
  }
};
