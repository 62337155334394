import moment from 'moment-timezone';
import { IPriorityCount } from 'volta/models/types/stats';
import { IHistoryRecord, NAVIGATION_HISTORY_KEY } from 'volta/routes/base';
import { collectionFiltersToQueryParams, inFilter } from 'volta/utils/filters-utils';
import { pick, shallowEqual } from 'volta/utils/object-utils';
import { strNormalizeAccent } from 'volta/utils/text-utils';

import { action } from '@ember/object';
import Service, { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { cached, tracked } from '@glimmer/tracking';

import type AppSettings from './app-settings';
import type AuthorizationService from './authorization';
import type DashboardStatsService from './dashboard-stats';
import type LocalPreferencesService from './local-preferences';
import type PlanningApiService from './planning-api';
import type SessionUserService from './session-user';

import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type PlanningOrderApi from './planning-order-api';

export interface IRoutableMenuItem {
  route?: string;
  currentWhen?: string;
  queryParams?: TQueryParams;
  hidden?: boolean;
  subItems?: IRoutableMenuItem[];
  subdued?: boolean;
  secondaryAction?: {
    route: string;
    accessibilityLabel?: string;
    icon?: string;
  };
}

export interface IBaseMenuItem extends IRoutableMenuItem {
  label: string;
  slug: string;
  icon?: string;
  collapsible?: boolean;
  favoritable?: boolean;
  isExpanded?: boolean;
  subItems?: ISubItem[];
}

export interface ISubItem extends IBaseMenuItem {
  isRunning?: boolean;
  badge?: string;
  badgeColor?: string;
  disabled?: boolean;
  isToggleItem?: boolean;
  subdued?: boolean;
}

interface INavigationStatus {
  title: string;
  description?: string;
  loading?: number;
}

export default class AppNavState extends Service {
  @service router!: RouterService;
  @service authorization!: AuthorizationService;
  @service planningApi!: PlanningApiService;
  @service dashboardStats!: DashboardStatsService;
  @service intl!: IntlService;
  @service localPreferences!: LocalPreferencesService;
  @service appSettings!: AppSettings;
  @service planningOrderApi!: PlanningOrderApi;
  @service sessionUser!: SessionUserService;

  @tracked status?: INavigationStatus;
  @tracked error?: string | string[];
  @tracked warning?: string | string[];
  @tracked searchText?: string;
  @tracked isLoading: boolean = false;
  @tracked generalLoadingMessage?: string;

  @tracked searchResultsAreOpened: boolean = false;
  @tracked history: IHistoryRecord[] = Object.values(
    this.localPreferences.getItem(NAVIGATION_HISTORY_KEY) ?? {}
  );
  @tracked prefersDark: boolean =
    this.theme !== 'light' && window.matchMedia('(prefers-color-scheme: dark)').matches;

  searchKeyBinding = 'cmd+k';

  @cached
  get navIsExpanded() {
    return this.sessionUser.preferences?.menu.expanded === true;
  }

  get navIsDocked() {
    return this.sessionUser.preferences?.menu.docked === true;
  }

  get expandedNavSections() {
    return this.sessionUser.preferences?.menu.expandedSections ?? [];
  }

  get navFavorites() {
    return this.sessionUser.preferences?.menu.favorites ?? [];
  }

  get theme() {
    return this.sessionUser.preferences?.theme ?? 'light';
  }

  get appliedTheme() {
    return this.prefersDark || this.theme === 'dark' ? 'dark' : 'light';
  }

  get hasSearchText() {
    return isPresent(this.searchText) && this.searchText !== '';
  }

  get stateParams(): TQueryParams {
    // Unsafe (Private API)
    // @ts-ignore
    return this.router._router._routerMicrolib.state.fullQueryParams;
  }

  get defaultStatus() {
    return this.planningApi.globalCalculationIsRunning
      ? {
          title: this.intl.t('settingsPage.admin.planningCalculation.title'),
          loading: this.loading
        }
      : undefined;
  }

  get defaultError() {
    return this.planningApi.globalCalculationIsError
      ? this.intl.t('settingsPage.admin.planningCalculation.error')
      : undefined;
  }

  get loading() {
    const total = this.planningApi.globalCalculationState.total;
    const calculated = this.planningApi.globalCalculationState.calculated;
    return total && total !== 0 ? calculated / total : undefined;
  }

  @cached
  get searchMenuContent() {
    if (this.sessionUser.user.isSupplier) return {};

    type MenuItemWithParent = IBaseMenuItem & { parent?: IBaseMenuItem };

    const addMenuItems = (
      acc: { [label: string]: MenuItemWithParent },
      menu: IBaseMenuItem & { parent?: IBaseMenuItem }
    ) => {
      const items = menu.subItems;
      if (!menu.hidden) {
        if (items) {
          items.forEach((item) => {
            if (!item.hidden) {
              const itemLabel = strNormalizeAccent(this.intl.t(item.label).toLowerCase());
              acc[itemLabel] = {
                ...item,
                icon: item.icon ?? menu.icon,
                parent: menu
              } as MenuItemWithParent;
              acc = addMenuItems(acc, item);
            }
          });
        } else {
          const menuLabel = strNormalizeAccent(this.intl.t(menu.label).toLowerCase());
          if (!acc[menuLabel]) {
            acc[menuLabel] = menu;
          }
        }
      }

      return acc;
    };
    return this.content.reduce(addMenuItems, {});
  }

  @cached
  get homeLink() {
    const firstLevelSubItems = this.content.map((c) => (c.hidden ? [] : c.subItems)).flat();
    let link;
    for (const nav of firstLevelSubItems) {
      if (!nav || nav.hidden) {
        continue;
      }
      link = nav.route;
      break;
    }
    return link ?? 'protected.dashboard';
  }

  @cached
  get content(): IBaseMenuItem[] {
    const { authorization } = this;
    const { dashboardSummary } = this.dashboardStats;

    const canView = (view: string) => authorization.canView(view);
    const cannotView = (view: string) => !canView(view);
    const cannotViewMany = (...views: string[]) => views.every((view) => cannotView(view));
    const cannotViewAny = (...views: string[]) =>
      views.every((view) => !authorization.canViewAny(view));

    function plStatus(key: keyof IPriorityCount): string | undefined {
      return dashboardSummary?.planningCounts[key]
        ? `${dashboardSummary?.planningCounts[key] ?? 0}`
        : undefined;
    }

    function eaStatus(key: keyof IPriorityCount): string | undefined {
      return dashboardSummary?.ohaCounts[key]
        ? `${dashboardSummary?.ohaCounts[key] ?? 0}`
        : undefined;
    }

    function alertsQP(
      property: string,
      values: string[],
      comparator: TComparator = 'in'
    ): TQueryParams {
      return {
        v: undefined,
        filters: collectionFiltersToQueryParams([{ property, values, comparator }])
      };
    }

    return [
      {
        label: 'plan',
        slug: 'plan',
        route: 'protected.dashboard',
        icon: 'plan',
        collapsible: true,
        hidden: cannotViewMany('dashboard', 'plannings', 'planningOrders'),
        subItems: [
          {
            label: 'dashboardPage.title',
            route: 'protected.dashboard',
            slug: 'dashboard',
            hidden: cannotView('dashboard'),
            icon: 'stats-chart-outline',
            favoritable: true
          },
          {
            label: 'planningsPage.title',
            route: 'protected.plannings',
            queryParams: { bufferZone: undefined },
            slug: 'plannings',
            hidden: cannotView('plannings'),
            badge: dashboardSummary?.planningMediumAndAbove
              ? `${dashboardSummary?.planningMediumAndAbove}`
              : undefined,
            badgeColor: 'red-6',
            icon: 'calendar-number-outline',
            favoritable: true,
            subItems: [
              {
                slug: 'plannings-critical',
                label: 'critical',
                route: 'protected.plannings',
                queryParams: { bufferZone: inFilter(['0']) },
                badge: plStatus('critical'),
                badgeColor: 'red-6'
              },
              {
                slug: 'plannings-high',
                label: 'high',
                route: 'protected.plannings',
                queryParams: { bufferZone: inFilter(['1']) },
                badge: plStatus('high'),
                badgeColor: 'red'
              },
              {
                slug: 'plannings-medium',
                label: 'medium',
                route: 'protected.plannings',
                queryParams: { bufferZone: inFilter(['2']) },
                badge: plStatus('medium'),
                badgeColor: 'yellow-6'
              }
            ]
          },
          {
            label: 'poWorkbench.title',
            route: 'protected.po-workbench',
            slug: 'po-workbench',
            hidden: cannotView('planningOrders'),
            icon: 'bag-outline',
            favoritable: true,
            badgeColor: 'primary'
          }
        ]
      },
      {
        label: 'operate',
        slug: 'operate',
        route: 'protected.alerts',
        icon: 'operate',
        collapsible: true,
        hidden: cannotViewMany('executionAlerts', 'orders'),
        subItems: [
          {
            label: 'execution',
            route: 'protected.alerts',
            queryParams: { ohaZone: undefined },
            slug: 'alerts',
            hidden: cannotView('executionAlerts'),
            badge: `${dashboardSummary?.ohaHighAndAbove ?? 0}`,
            badgeColor: 'red-6',
            icon: 'alarm-outline',
            favoritable: true,
            subItems: [
              {
                slug: 'alerts-critical',
                label: 'critical',
                route: 'protected.alerts',
                queryParams: alertsQP('ohaZone', ['0']),
                badge: eaStatus('critical'),
                badgeColor: 'red-6'
              },
              {
                slug: 'alerts-high',
                label: 'high',
                route: 'protected.alerts',
                queryParams: alertsQP('ohaZone', ['1']),
                badge: eaStatus('high'),
                badgeColor: 'red'
              },
              {
                slug: 'alerts-medium',
                label: 'medium',
                route: 'protected.alerts',
                queryParams: alertsQP('ohaZone', ['2']),
                badge: eaStatus('medium'),
                badgeColor: 'yellow-6'
              }
            ]
          },
          {
            label: 'openOrders',
            route: 'protected.open-orders',
            slug: 'open-orders',
            hidden: cannotView('orders'),
            icon: 'receipt-outline',
            favoritable: true
          }
        ]
      },
      {
        label: 'masterData',
        slug: 'master-data',
        route: 'protected.skus',
        icon: 'category',
        collapsible: true,
        hidden: cannotViewMany(
          'skus',
          'warehouses',
          'suppliers',
          'workshops',
          'boms',
          'bufferProfiles',
          'aduProfiles',
          'dafProfiles'
        ),
        subItems: [
          {
            label: 'SKUs',
            route: 'protected.skus',
            slug: 'skus',
            hidden: cannotView('skus'),
            currentWhen: 'protected.sku protected.sku-duplicate protected.sku-create',
            icon: 'pricetags-outline',
            secondaryAction: authorization.can('users', 'CreateUser')
              ? {
                  route: 'protected.sku-create',
                  accessibilityLabel: this.intl.t('bvList.create', {
                    resourceNameSingular: this.intl.t('sku').toLowerCase()
                  }),
                  icon: 'add-circle-outline'
                }
              : undefined,
            favoritable: true
          },
          {
            label: 'warehouses',
            slug: 'warehouses',
            route: 'protected.warehouses',
            hidden: cannotView('warehouses'),
            currentWhen: 'protected.warehouse protected.warehouse-create',
            icon: 'warehouse-outline',
            favoritable: true
          },
          {
            label: 'suppliers',
            slug: 'suppliers',
            route: 'protected.suppliers',
            hidden: cannotView('suppliers'),
            currentWhen: 'protected.supplier protected.supplier-create',
            icon: 'storefront-outline',
            favoritable: true
          },
          {
            label: 'workshops',
            slug: 'workshops',
            route: 'protected.workshops',
            hidden: cannotView('workshops'),
            currentWhen: 'protected.workshop protected.workshop-create',
            icon: 'workshop-outline',
            favoritable: true
          },
          {
            label: 'boms',
            route: 'protected.boms',
            slug: 'boms',
            hidden: cannotView('boms'),
            currentWhen: 'protected.bom',
            icon: 'git-network-outline',
            favoritable: true
          },
          {
            label: 'profiles',
            slug: 'profiles',
            icon: 'file-tray-stacked-outline',
            hidden: cannotViewMany('bufferProfiles', 'aduProfiles', 'dafProfiles'),
            isToggleItem: true,
            subItems: [
              {
                label: 'bufferProfiles',
                route: 'protected.buffer-profiles',
                currentWhen: 'protected.buffer-profile protected.buffer-profile-create',
                slug: 'buffer-profiles',
                hidden: cannotView('bufferProfiles'),
                favoritable: true
              },
              {
                label: 'aduProfilesPage.title',
                route: 'protected.adu-profiles',
                slug: 'adu-profiles',
                currentWhen: 'protected.adu-profile protected.adu-profile-create',
                hidden: cannotView('aduProfiles'),
                favoritable: true
              },
              {
                label: 'dafProfilesPage.title',
                route: 'protected.daf-profiles',
                slug: 'daf-profiles',
                currentWhen: 'protected.daf-profile protected.daf-profile-create',
                hidden: cannotView('dafProfiles'),
                favoritable: true
              }
            ]
          }
        ]
      },
      {
        label: 'pilot',
        route: 'protected.simulations',
        icon: 'pilot',
        slug: 'pilot',
        collapsible: true,
        hidden:
          cannotView('simulations') &&
          (cannotView('reports') || !this.appSettings.features?.experimental.embeddedTableauReport),
        subItems: [
          {
            label: 'simulations',
            route: 'protected.simulations',
            slug: 'simulation',
            currentWhen: 'protected.simulation protected.simulation.simulation-scenario',
            hidden: cannotView('simulations'),
            icon: 'film-outline',
            favoritable: true
          },
          {
            label: 'reports',
            route: 'protected.reports',
            slug: 'reports',
            hidden:
              cannotView('reports') ||
              !this.appSettings.features?.experimental.embeddedTableauReport,
            currentWhen: 'protected.report',
            icon: 'newspaper-outline',
            favoritable: true
          }
        ]
      },
      {
        label: 'administration',
        slug: 'administration',
        icon: 'settings-outline',
        route: 'protected.settings.index',
        collapsible: true,
        subdued: true,
        hidden:
          cannotViewMany('userRoles', 'users') &&
          cannotViewAny('configs', 'importHistos', 'appFeatures', 'customFieldDefinitions'),
        subItems: [
          {
            label: 'settingsPage.title',
            slug: 'settings',
            icon: 'build-outline',
            isToggleItem: true,
            subItems: [
              {
                label: 'settingsPage.general.title',
                route: 'protected.settings.general',
                slug: 'settings-general',
                hidden: cannotView('configs'),
                favoritable: true
              },
              {
                label: 'settingsPage.businessRules.title',
                route: 'protected.settings.rules',
                slug: 'settings-rules',
                hidden: cannotView('configs'),
                favoritable: true
              },
              {
                label: 'settingsPage.admin.title',
                route: 'protected.settings.admin',
                slug: 'settings-admin',
                hidden: cannotView('configs'),
                favoritable: true
              },
              {
                label: 'settingsPage.features.title',
                route: 'protected.settings.features',
                slug: 'features',
                hidden: cannotView('appFeatures'),
                favoritable: true
              }
            ]
          },
          {
            label: 'importsPage.title',
            route: 'protected.import',
            currentWhen: 'protected.import',
            slug: 'import',
            hidden: cannotView('importHistos'),
            icon: 'download-outline',
            favoritable: true
          },
          {
            label: 'customFieldDefinitions',
            slug: 'custom-field-definitions',
            hidden: !authorization.canViewAny('customFieldDefinitions'),
            route: 'protected.custom-field-definitions',
            currentWhen: 'protected.custom-field-definition',
            icon: 'shapes-outline',
            favoritable: true
          },
          {
            label: 'schedules',
            slug: 'schedules',
            hidden: !authorization.canViewAny('schedules'),
            route: 'protected.schedules',
            currentWhen: 'protected.schedule',
            icon: 'reload-outline',
            favoritable: true
          },
          {
            label: 'rolesPage.title',
            route: 'protected.user-roles',
            slug: 'roles',
            hidden: cannotView('userRoles'),
            icon: 'key-outline',
            currentWhen: 'protected.user-role protected.user-role-create',
            favoritable: true
          },
          {
            label: 'usersPage.title',
            route: 'protected.users',
            slug: 'users',
            hidden: cannotView('users'),
            icon: 'people-outline',
            currentWhen: 'protected.user protected.user-create',
            favoritable: true
          }
        ]
      }
    ];
  }

  @cached
  get favItems(): IBaseMenuItem[] {
    if (this.sessionUser.user.isSupplier) return [];
    const favs: IBaseMenuItem[] = [];
    const { content, navFavorites } = this;

    const extract = (item: IBaseMenuItem) => {
      if (navFavorites.includes(item.slug) && !item.collapsible && item.favoritable === true) {
        favs.push({ ...item, subItems: undefined });
      }
      item.subItems?.forEach(extract);
    };
    content.forEach(extract);
    return favs;
  }

  constructor() {
    super(...arguments);
    // Listen for color theme change
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      this.prefersDark = this.theme !== 'light' && e.matches;
    });
  }

  @action
  expand() {
    this.sessionUser.setMenuProperty('expanded', true);
  }

  @action
  collapse(clickOutside: boolean = false) {
    if (!clickOutside || !this.navIsDocked) {
      this.sessionUser.setMenuProperty('expanded', false);
    }
  }

  @action
  toggle() {
    if (this.navIsExpanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  @action
  toggleNavDocking() {
    this.sessionUser.setMenuProperty('docked', !this.navIsDocked);
  }

  @action
  openSearch() {
    if (!this.searchResultsAreOpened) {
      this.searchResultsAreOpened = true;
    }
  }

  @action
  closeSearch() {
    this.searchResultsAreOpened = false;
    this.searchText = undefined;
  }

  @action
  toggleNavSection(slug: string) {
    this.sessionUser.setMenuProperty(
      'expandedSections',
      this.expandedNavSections.includes(slug)
        ? this.expandedNavSections.filter((s) => s !== slug)
        : [...this.expandedNavSections, slug]
    );
  }

  @action
  fillHistory(newHistory: { [key: string]: IHistoryRecord }) {
    const history = newHistory
      ? Object.values(newHistory).sort((a: IHistoryRecord, b: IHistoryRecord) => {
          if (a.visitCount < b.visitCount) {
            return 1;
          } else if (a.visitCount > b.visitCount) {
            return -1;
          } else {
            if (moment(a.lastVisitedAt).isSameOrAfter(b.lastVisitedAt)) {
              return 1;
            } else {
              return -1;
            }
          }
        })
      : [];

    this.history = history.length > 10 ? history.slice(0, 10) : history;
  }

  @action
  handleToggleFav(slug: string) {
    this.sessionUser.setMenuProperty(
      'favorites',
      this.navFavorites.includes(slug)
        ? this.navFavorites.filter((s) => s !== slug)
        : [...this.navFavorites, slug]
    );
  }

  // Helper functions
  // ~~~~~~

  checkParams(qp: TQueryParams) {
    if (!qp) {
      return false;
    }
    const { stateParams } = this;
    const cleanedParams = pick(stateParams, Object.keys(qp));
    return shallowEqual(cleanedParams, qp);
  }

  checkActiveRoute(route?: string, currentWhen?: string, queryParams?: TQueryParams) {
    const routeIsActive =
      route && queryParams
        ? this.router.isActive(route) && this.checkParams(Object.assign({}, queryParams))
        : route
        ? this.router.isActive(route)
        : false;
    const currentWhenRoutes = currentWhen?.split(' ') ?? [];
    const currentWhenRoutesActive = currentWhenRoutes.some((r) => this.router.isActive(r));
    return routeIsActive || currentWhenRoutesActive;
  }

  checkIsActive(item: IRoutableMenuItem): boolean {
    const itemIsActive =
      item.hidden !== true && this.checkActiveRoute(item.route, item.currentWhen, item.queryParams);
    const hasSubItems = item.subItems ? item.subItems.length > 0 : false;
    return (
      itemIsActive || (hasSubItems && Boolean(item.subItems?.some((i) => this.checkIsActive(i))))
    );
  }

  checkIsHidden(item: IRoutableMenuItem): boolean {
    const hasSubItems = item.subItems ? item.subItems.length > 0 : false;
    return (
      item.hidden === true ||
      (hasSubItems && Boolean(item.subItems?.every((i) => this.checkIsHidden(i))))
    );
  }
}

declare module '@ember/service' {
  interface Registry {
    'app-nav-state': AppNavState;
  }
}
