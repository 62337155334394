export type TOrderStatus =
  | 'PROJECTED'
  | 'NEW'
  | 'DRAFT'
  | 'PENDING'
  | 'CANCELLED'
  | 'COMMITTED'
  | 'LAUNCHED'
  | 'UNKNOWN'
  | 'SENT'
  | 'ERROR'
  | 'BLOCKED'
  | 'FINISHED';

const OrderStatuses: Record<TOrderStatus, string> = {
  PROJECTED: 'ordersStatuses.PROJECTED',
  NEW: 'ordersStatuses.NEW',
  DRAFT: 'ordersStatuses.DRAFT',
  PENDING: 'ordersStatuses.PENDING',
  CANCELLED: 'ordersStatuses.CANCELLED',
  COMMITTED: 'ordersStatuses.COMMITTED',
  LAUNCHED: 'ordersStatuses.LAUNCHED',
  UNKNOWN: 'ordersStatuses.UNKNOWN',
  SENT: 'ordersStatuses.SENT',
  ERROR: 'ordersStatuses.ERROR',
  BLOCKED: 'ordersStatuses.BLOCKED',
  FINISHED: 'ordersStatuses.FINISHED'
};

export const OrderStatusesOptions = Object.keys(OrderStatuses).map((key: TOrderStatus) => {
  return { key, label: OrderStatuses[key] };
});

export default OrderStatuses;
