import { clamp } from 'volta/utils/math-utils';
import { lower } from 'volta/utils/text-utils';

import { get } from '@ember/object';
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

type ISize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const SIZES: Record<ISize, number> = {
  xs: 12,
  sm: 20,
  md: 30,
  lg: 50,
  xl: 100
};

const DEFAULT_SIZE = 'md';

// see http://stackoverflow.com/a/18473154/3124288 for calculating arc path
const R = 45;

// unitless total length of SVG path, to which stroke-dash* properties are relative.
// https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/pathLength
// this value is the result of `<path d={SPINNER_TRACK} />.getTotalLength()` and works in all browsers:
const PATH_LENGTH = 280;

const STROKE_WIDTH = 4;
const MIN_STROKE_WIDTH = 16;
const TRACK_PATH = htmlSafe(
  `M 50,50 m 0,-${R} a ${R},${R} 0 1 1 0,${R * 2} a ${R},${R} 0 1 1 0,-${R * 2}`
);

interface IArgs {
  /**
   * A value between 0 and 1 (inclusive) representing how far along the operation is.
   * Values below 0 or above 1 will be interpreted as 0 or 1 respectively.
   * Omitting this prop will result in an "indeterminate" spinner where the head spins indefinitely.
   */
  value?: number;
  intent: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'white' | 'critical';
  size: ISize;
}

export default class BvSpinner extends Component<IArgs> {
  // Attributes
  // ~~~~~

  PATH_LENGTH = PATH_LENGTH;
  TRACK_PATH = TRACK_PATH;

  // CPs
  // ~~~~~

  get validatedSize() {
    const { size } = this.args;
    if (size && SIZES[lower(size) as ISize]) {
      return size;
    } else {
      return DEFAULT_SIZE;
    }
  }

  /**
   * Resolve size to a pixel value.
   */
  get sizePx() {
    return get(SIZES, this.validatedSize);
  }

  set sizePx(value) {
    this.sizePx = value;
  }

  // keep spinner track width consistent at all sizes (down to about 10px).
  get strokeWidth() {
    return Math.min(MIN_STROKE_WIDTH, (STROKE_WIDTH * 100) / this.sizePx);
  }

  get strokeWidthRounded() {
    return this.strokeWidth.toFixed(2);
  }

  get strokeOffset() {
    const { value } = this.args;
    return (
      PATH_LENGTH -
      PATH_LENGTH *
        (value === null || typeof value === 'undefined' ? 0.25 : clamp(value, 0, 1) || 0)
    );
  }

  /** Compute viewbox such that stroked track sits exactly at edge of image frame. */
  get viewBox() {
    const radius = R + this.strokeWidth / 2;
    const viewBoxX = (50 - radius).toFixed(2);
    const viewBoxWidth = (radius * 2).toFixed(2);
    return `${viewBoxX} ${viewBoxX} ${viewBoxWidth} ${viewBoxWidth}`;
  }
}
