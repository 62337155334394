import { task, timeout } from 'ember-concurrency';
import { isThenable } from 'volta/utils/object-utils';
import { TSize } from 'volta/utils/style-utils';

import { debug } from '@ember/debug';
import { action } from '@ember/object';
import Component from '@glimmer/component';

type ISize = Extract<TSize, 'xs' | 'sm'>;

const Sizes: Record<ISize, ISize> = {
  sm: 'sm',
  xs: 'xs'
};

type IColor = 'primary' | 'danger' | 'hud';

const Colors: Record<IColor, IColor> = {
  primary: 'primary',
  danger: 'danger',
  hud: 'hud'
};

type IAlignment = 'left' | 'right';

const Alignments: Record<IAlignment, IAlignment> = {
  left: 'left',
  right: 'right'
};

interface IArgs {
  color?: IColor;
  size?: ISize;
  alignText?: IAlignment;
  iconLeft?: string;
  iconRight?: string;
  text?: string;
  disclosure?: boolean;
  submit?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
  active?: boolean;
  pressed?: boolean;
  outline?: boolean;
  rounded?: boolean;
  subtle?: boolean;
  plain?: boolean;
  fullWidth?: boolean;
  class?: string;
  tooltipText?: string;
  tooltipSide?: 'auto' | 'left' | 'right' | 'bottom' | 'top';
  loadingProgress?: number;
  route?: string;
  routeModel?: any;
  accessibilityLabel?: string;
  onClick: (event: MouseEvent) => void | Promise<any> | any;
  onFocus?: (event: FocusEvent) => void;
  onBlur?: (event: FocusEvent) => void;
}

const BvBtnColors: IColor[] = [Colors.primary, Colors.danger, Colors.hud];
const BvBtnSizes: ISize[] = [Sizes.sm, Sizes.xs];
const BvBtnAlignments: IAlignment[] = [Alignments.left, Alignments.right];

/**
 * Timeout used in async action to simulated a minimum loading time in milliseconds
 */
const DefaultTaskTimeout: number = 300;

export default class BvBtn extends Component<IArgs> {
  promise?: Promise<any>;

  /**
   * Button color class name
   */
  get colorClassName() {
    const { color } = this.args;
    return color && BvBtnColors.includes(color) ? `bv-btn--${color}` : undefined;
  }

  /**
   * Button size class modifier
   */
  get sizeClassName() {
    const { size } = this.args;
    return size && BvBtnSizes.includes(size) ? `bv-btn--${size}` : undefined;
  }

  /**
   * BvBtn alignment class modifier
   */
  get alignClassName() {
    const { alignText } = this.args;
    return alignText && BvBtnAlignments.includes(alignText)
      ? `bv-btn--align-${alignText}`
      : undefined;
  }

  /**
   * BvBtn has only one icon defined in its args
   * true if one icon only is defined and no text
   */
  get hasOnlyOneIcon() {
    const { iconLeft, iconRight, text, disclosure } = this.args;
    return (
      !Boolean(text) &&
      ((Boolean(iconLeft) && !Boolean(iconRight) && !Boolean(disclosure)) ||
        (!Boolean(iconLeft) && (Boolean(iconRight) || Boolean(disclosure))))
    );
  }

  @action
  handleRouteClick(event: MouseEvent): boolean | { event: MouseEvent } {
    if (this.args.disabled) {
      return false;
    }
    event.stopPropagation();
    return this.handleClick(event);
  }

  /**
   * BvBtn click event handler. If the passed onClick function returns a promise
   * the asyncAction will be performed
   * @param event HTML element click event
   * @return {boolean|*}
   */
  @action
  handleClick(event: MouseEvent): boolean | { event: MouseEvent } {
    const { submit, onClick, disabled } = this.args;

    if (disabled) {
      return false;
    }

    if (submit) {
      return { event };
    }

    const maybePromise: Promise<any> | undefined = onClick?.(event);

    if (maybePromise && isThenable(maybePromise)) {
      this.promise = maybePromise;
      this.asyncAction.perform(maybePromise);
    }
    return false;
  }

  /**
   * Asynchronous action performed when the passed onClick argument returns a promise
   * @param promise A promise returned by an async function passed in the onClick argument
   */
  asyncAction = task(async (promise?: Promise<any>) => {
    try {
      if (!promise) {
        return;
      }
      await timeout(DefaultTaskTimeout);
      return await promise;
    } catch (error) {
      debug(error);
    }
  });
}
