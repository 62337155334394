import BaseModel, { IResourceName } from 'volta/models/base-model';

import { attr, belongsTo } from '@ember-data/model';

import type { TOrderStatus } from './constants/order-statuses';
import type { TTimeBufferAlert } from './constants/time-buffer-alerts';
import type SkuModel from './sku';
import { IWorkshopIdentityInfo } from './types/workshop';
import { ISupplierIdentityInfo } from "volta/models/types/supplier";

export const ResourceName: IResourceName = {
  singular: 'order',
  plural: 'orders'
};

export interface IOrderExecutionAlert {
  executionAlertId: string;
  oha: number;
  ohaZone: number;
}

export interface IOrderParentSku {
  id: string;
  warehouseId: string;
  warehouseCode: string;
  warehouseDescription?: string;
  partId: string;
  partCode: string;
  description?: string;
}

export default class OrderModel extends BaseModel {
  @attr('string') skuId!: string;
  @attr('string') warehouseId!: string;
  @attr('string') planningOrderId?: string;
  @attr('string') copiedFrom?: string;
  @attr('date') dueDate!: Date;
  @attr('date') deliveryDate?: Date;
  @attr('number') qty!: number;
  @attr('string') status!: TOrderStatus;
  @attr('number') priority?: number;
  @attr('string') orderType?: string;
  @attr('string') orderNum?: string;
  @attr('string') orderLine?: string;
  @attr('string') voltaOrderNum?: string;
  @attr('string') voltaOrderLine?: string;
  @attr('string') vendorCode?: string;
  @attr('string') vendorName?: string;
  @attr('string') source?: string;
  @attr('string') timeBufferAlert?: TTimeBufferAlert;
  @attr('string') externalId?: string;
  @attr() custom?: object;
  @attr() executionAlert?: IOrderExecutionAlert;
  @attr() parentSku?: IOrderParentSku;
  @attr() supplier?: ISupplierIdentityInfo;
  @attr() workshop?: IWorkshopIdentityInfo;
  @attr() deliveryWorkshop?: IWorkshopIdentityInfo;
  @belongsTo('sku', { async: false }) sku!: SkuModel;

  get isDemand() {
    return this.qty < 0;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    order: OrderModel;
  }
}
