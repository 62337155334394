import _sortBy from 'lodash/sortBy';
import BaseModel from 'volta/models/base-model';

import { attr } from '@ember-data/model';

import { IQuantityUnit } from './types/planning-order';
import { IBufferInfo } from './types/stats';

export const POGroupResourceName = {
  singular: 'poGroup',
  plural: 'poGroups'
};

export type TPOField =
  | 'id'
  | 'g'
  | 'groupId'
  | 'parentPoId'
  | 'planningId'
  | 'sku'
  | 'acqCode'
  | 'createdBy'
  | 'deliveryDate'
  | 'dueDate'
  | 'reorderDate'
  | 'deliveryWorkshop'
  | 'orderNum'
  | 'externalOrderNum'
  | 'planningBuffer'
  | 'executionBuffer'
  | 'orderType'
  | 'syncAlert'
  | 'schedulingAlert'
  | 'reorderQty'
  | 'priority'
  | 'status'
  | 'validationStatus'
  | 'supplier'
  | 'warehouse'
  | 'workshop'
  | 'partType'
  | 'sku.customFields'
  | 'customFields';

export type TPOGroupFields = {
  key: TPOField;
  id?: string;
  value?: string;
  desc?: string;
};

export type TPOGroupFieldsKey =  Partial<Record<TPOField, TPOGroupFields>> & { id: string };

export default class POGroup extends BaseModel {
  @attr({ defaultValue: () => [] }) fields!: TPOGroupFields[];
  @attr() reorderQty!: Record<string, number>;
  @attr() reorderQtyValue!: Record<string, number>;
  @attr('number') totalCount!: number;
  @attr('number') filteredCount!: number;
  @attr('number') comments!: number;
  @attr('number') schedulingAlerts!: number;
  @attr('number') syncAlerts!: number;
  @attr() planningBuffer!: IBufferInfo<number>;
  @attr() executionBuffer!: IBufferInfo<number>;
  @attr('array') unitMeasures!: string[];

  get planningBufferDisplay() {
    return toBufferPercent(this.planningBuffer);
  }

  get executionBufferDisplay() {
    return toBufferPercent(this.executionBuffer);
  }

  get groupBy() {
    return this.fields.map((f) => f.key);
  }

  get workshopId() {
    return this.fields.find((f) => f.key === 'workshop')?.id;
  }

  get keyObject() {
    const o: TPOGroupFieldsKey = { id: this.id } as TPOGroupFieldsKey;
    this.fields.forEach((f) => {
      o[f.key] = f as string & TPOGroupFields;
    });
    return o;
  }

  get alertsCount() {
    return this.syncAlerts + this.schedulingAlerts;
  }

  get counts() {
    return this.alertsCount + this.comments;
  }

  get reorderQtyValueOptions() {
    return _sortBy(
      Object.entries(this.reorderQtyValue).map(([currency, value]) => {
        return {
          currency,
          value
        };
      }),
      'currency'
    );
  }

  get convertedQtiesOptions(): IQuantityUnit[] {
    return _sortBy(
      Object.entries(this.reorderQty).map(([unit, value]) => {
        return {
          unit,
          value
        };
      }),
      'value'
    );
  }
}

const toBufferPercent = (bufferInfo: IBufferInfo<number>) => {
  const { critical, high, medium, low, otog } = bufferInfo;
  const total = critical + high + medium + low + otog;
  return {
    critical: total === 0 ? 0 : critical / total,
    high: total === 0 ? 0 : high / total,
    medium: total === 0 ? 0 : medium / total,
    low: total === 0 ? 0 : low / total,
    otog: total === 0 ? 0 : otog / total
  };
};

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'po-group': POGroup;
  }
}
