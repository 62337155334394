import { attr } from '@ember-data/model';
import BaseModel from 'volta/models/base-model';

export const TranslationResourceName = {
  singular: 'translation',
  plural: 'translations'
};

interface IMessages {
  [key: string]: string | object;
}

export default class Translation extends BaseModel {
  @attr('string') declare locale: string;
  @attr() messages?: IMessages;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    translation: Translation;
  }
}
