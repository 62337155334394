import { attr, belongsTo } from '@ember-data/model';
import BaseModel from 'volta/models/base-model';
import SimulationItemSetting from 'volta/models/simulation-item-setting';
import { ISimulationItemScenarioKPI } from "volta/models/types/simulation";

export default class SimulationItemPlanning extends BaseModel {
  @attr('string') simulationItemSettingId!: string;
  @attr('string') scenarioId!: string;
  @attr('string') skuId!: string;
  @attr('array') inventoryDate!: string[];
  @attr('array') demandQty!: number[];
  @attr('array') supplyQty!: number[];
  @attr('array') onHand!: number[];
  @attr('array') onHandSimulated!: number[];
  @attr('array') adu!: number[];
  @attr('array') reorderQty!: number[];
  @attr('array') receptionQty!: number[];
  @attr('array') bufferTOG!: number[];
  @attr('array') bufferTOY!: number[];
  @attr('array') bufferTOR!: number[];
  @attr('array') bufferGreen!: number[];
  @attr('array') bufferYellow!: number[];
  @attr('array') bufferRedBase!: number[];
  @attr('array') bufferRedSafety!: number[];
  @attr('array') netFlow!: number[];
  @attr('array') demandSpikeQty!: number[];
  @attr('array') daf!: number[];
  @attr('array') forecastQty!: number[];
  @attr('array') forecastQtyCleaned!: number[];
  @attr('array') forecastQtyNetFlow!: number[];
  @attr() stats?: ISimulationItemScenarioKPI;

  // Relationships

  @belongsTo('simulation-item-setting', { async: false })
  itemSetting!: SimulationItemSetting;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'simulation-item-planning': SimulationItemPlanning;
  }
}
