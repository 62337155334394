import { TAduFrequency, TAduType } from 'volta/models/types/planning-settings';
import { btnGroupOptions } from 'volta/utils/style-utils';

/**
 * ADU types
 */
export const ADU_TYPES: Record<TAduType, string> = {
  ROLLING: 'aduTypes.ROLLING',
  FIXED: 'aduTypes.FIXED'
};

type TMapAduType<T> = Record<TAduFrequency, T>;
/**
 * Possible ADU frequencies
 */
export const ADU_FREQUENCIES: Partial<TMapAduType<string>> = {
  DAILY: 'aduFrequencies.DAILY',
  WEEKLY: 'aduFrequencies.WEEKLY',
  MONTHLY: 'aduFrequencies.MONTHLY'
};

export const ADU_FREQUENCIES_KEYS: TAduFrequency[] = Object.keys(
  ADU_FREQUENCIES
) as TAduFrequency[];

export const ADU_FREQUENCIES_OPTIONS = btnGroupOptions(ADU_FREQUENCIES);

/**
 * ADU frequencies conversion mapping
 */
export const ADU_FREQUENCIES_CONVERSIONS: Partial<TMapAduType<Partial<TMapAduType<number>>>> = {
  DAILY: { DAILY: 1, WEEKLY: 1 / 7, MONTHLY: 1 / 30 },
  WEEKLY: { DAILY: 7, WEEKLY: 1, MONTHLY: 1 / 4 },
  MONTHLY: { DAILY: 30, WEEKLY: 4, MONTHLY: 1 }
};

/**
 * ADU horizon ranges
 */
export const ADU_HORIZON_RANGES: Partial<TMapAduType<{ min: number; max: number }>> = {
  DAILY: { min: 1, max: 365 },
  WEEKLY: { min: 1, max: 52 },
  MONTHLY: { min: 1, max: 12 }
};
