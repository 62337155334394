import JSONSerializer from '@ember-data/serializer/json';

export default JSONSerializer.extend({
  serialize(/*snapshot, options*/) {
    let json = this._super(...arguments);

    let result = {};

    for (let key in json) {
      if (json.hasOwnProperty(key)) {
        if (json[key]) {
          result[key] = json[key];
        }
      }
    }
    return result;
  }
});
