import { helper } from '@ember/component/helper';
import { isEmpty } from '@ember/utils';

export function startWith([str, prefix]: [string, string] /*, hash*/) {
  return (
    !isEmpty(str) &&
    !isEmpty(prefix) &&
    `${str}`.toLowerCase().startsWith(`${prefix}`.toLowerCase())
  );
}

export default helper(startWith);
