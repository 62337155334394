import BaseModel from 'volta/models/base-model';

import { attr } from '@ember-data/model';
import { isPresent } from '@ember/utils';
import type { IImportResult, IPersistedImportFile } from './types/data-import';

export const ResourceName = {
  singular: 'importHisto',
  plural: 'importHistos'
};

export const buildFileURL = (file: IPersistedImportFile) =>
  `importHistos/files/${file.bucket}/${file.key}`;

export default class ImportHisto extends BaseModel {
  @attr('string') tblName!: string;
  @attr('boolean') isSuccess!: boolean;
  @attr('date') prevImportTs?: Date;
  @attr('date') importTs?: Date;
  @attr('date') startedAt?: Date;
  @attr('date') endedAt?: Date;
  @attr() result?: IImportResult;
  @attr('string') errorMsg?: string;
  @attr() errorContext?: object;
  @attr('string') importedBy?: string;
  @attr('string') delimiter?: string;
  @attr('string') encoding?: string;
  @attr('string') context?: string;
  @attr() sourceFile?: IPersistedImportFile;
  @attr() errorsFile?: IPersistedImportFile;
  @attr({ defaultValue: () => [] }) tags!: string[];

  get isImportLoading() {
    return !isPresent(this.endedAt);
  }

  get isImportError() {
    return !this.isSuccess && isPresent(this.errorMsg);
  }

  get isImportWarning() {
    const { result, isSuccess } = this;
    return isSuccess && result?.errorRows === result?.totalRows;
  }

  get sourceFileURL() {
    return this.sourceFile ? buildFileURL(this.sourceFile) : undefined;
  }

  get errorsFileURL() {
    return this.errorsFile ? buildFileURL(this.errorsFile) : undefined;
  }

  get errorCount() {
    return this.result?.errors
      ? Object.values(this.result?.errors ?? {}).reduce((acc, val) => acc + val, 0)
      : this.result?.errorRows ?? 0;
  }

  get errorsAsText() {
    const errors: Record<string, any> = this.result?.errors ?? {};
    return Object.keys(errors)
      .map((k) => {
        return `${k}: ${errors[k]}`;
      })
      .join('\n');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'import-histo': ImportHisto;
  }
}
