import application from 'volta/utils/application';

const IntlService = application.instance.lookup('service:intl');

export default {
  inclusion: IntlService.t('errors.inclusion', { description: '{description}' }), // '{description} is not included in the list',
  exclusion: IntlService.t('errors.exclusion', { description: '{description}' }), // '{description} is reserved',
  invalid: IntlService.t('errors.invalid', { description: '{description}' }), // '{description} is invalid',
  confirmation: IntlService.t('errors.confirmation', { description: '{description}', on: '{on}' }), // "{description} doesn't match {on}",
  accepted: IntlService.t('errors.accepted', { description: '{description}' }), // '{description} must be accepted',
  empty: IntlService.t('errors.empty', { description: '{description}' }), // "{description} can't be empty",
  blank: IntlService.t('errors.blank', { description: '{description}' }), // '{description} must be blank',
  present: IntlService.t('errors.present', { description: '{description}' }), // "{description} can't be blank",
  collection: IntlService.t('errors.collection', { description: '{description}' }), // '{description} must be a collection',
  singular: IntlService.t('errors.singular', { description: '{description}' }), // "{description} can't be a collection",
  tooLong: IntlService.t('errors.tooLong', { description: '{description}', max: '{max}' }), // '{description} is too long (maximum is {max} characters)',
  tooShort: IntlService.t('errors.tooShort', { description: '{description}', min: '{min}' }), // '{description} is too short (minimum is {min} characters)',
  between: IntlService.t('errors.between', {
    description: '{description}',
    min: '{min}',
    max: '{max}'
  }), // '{description} must be between {min} and {max} characters',
  before: IntlService.t('errors.before', { description: '{description}', before: '{before}' }), // '{description} must be before {before}',
  onOrBefore: IntlService.t('errors.onOrBefore', {
    description: '{description}',
    onOrBefore: '{onOrBefore}'
  }), // '{description} must be on or before {onOrBefore}',
  after: IntlService.t('errors.after', { description: '{description}', after: '{after}' }), // '{description} must be after {after}',
  onOrAfter: IntlService.t('errors.onOrAfter', {
    description: '{description}',
    onOrAfter: '{onOrAfter}'
  }), // '{description} must be on or after {onOrAfter}',
  wrongDateFormat: IntlService.t('errors.wrongDateFormat', {
    description: '{description}',
    format: '{format}'
  }), // '{description} must be in the format of {format}',
  notInPast: IntlService.t('errors.notInPast', {
    description: '{description}'
  }), //  '{description} should not be in the past',
  wrongLength: IntlService.t('errors.wrongLength', { description: '{description}', is: '{is}' }), // '{description} is the wrong length (should be {is} characters)',
  notANumber: IntlService.t('errors.notANumber', { description: '{description}' }), // '{description} must be a number',
  notAnInteger: IntlService.t('errors.notAnInteger', { description: '{description}' }), // '{description} must be an integer',
  greaterThan: IntlService.t('errors.greaterThan', { description: '{description}', gt: '{gt}' }), // '{description} must be greater than {gt}',
  greaterThanOrEqualTo: IntlService.t('errors.greaterThanOrEqualTo', {
    description: '{description}',
    gte: '{gte}'
  }), // '{description} must be greater than or equal to {gte}',
  equalTo: IntlService.t('errors.equalTo', { description: '{description}', is: '{is}' }), // '{description} must be equal to {is}',
  lessThan: IntlService.t('errors.lessThan', { description: '{description}', lt: '{lt}' }), // '{description} must be less than {lt}',
  lessThanOrEqualTo: IntlService.t('errors.lessThanOrEqualTo', {
    description: '{description}',
    lte: '{lte}'
  }), // '{description} must be less than or equal to {lte}',
  otherThan: IntlService.t('errors.otherThan', { description: '{description}', value: '{value}' }), // '{description} must be other than {value}',
  odd: IntlService.t('errors.odd', { description: '{description}' }), // '{description} must be odd',
  even: IntlService.t('errors.even', { description: '{description}' }), // '{description} must be even',
  positive: IntlService.t('errors.positive', { description: '{description}' }), // '{description} must be positive',
  multipleOf: IntlService.t('errors.multipleOf', {
    description: '{description}',
    multipleOf: '{multipleOf}'
  }), // '{description} must be a multiple of {multipleOf}',
  date: IntlService.t('errors.date', { description: '{description}' }), // '{description} must be a valid date',
  email: IntlService.t('errors.email', { description: '{description}' }), // '{description} must be a valid email address',
  phone: IntlService.t('errors.phone', { description: '{description}' }), // '{description} must be a valid phone number',
  url: IntlService.t('errors.url', { description: '{description}' }), // '{description} must be a valid url',
  alreadyExist: IntlService.t('errors.alreadyExist', { description: '{description}' }) // '{description} already exists'
};
