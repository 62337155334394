import { inject as service } from '@ember/service';
import DomainSelect from 'volta/components/domain-select';
import ifUndefined from 'volta/macros/if-undefined';
import { WarehouseResourceName } from 'volta/models/warehouse';
import JsonApiInfinityModel, { decorateApiQuery } from 'volta/models/jsonapi-infinity-model';
import { inFilter, niFilter, lkFilter, stringListToArray } from 'volta/utils/filters-utils';

export default DomainSelect.extend({
  infiniteScroll: true,

  idKey: 'id',

  filterFn: ifUndefined(() => {
    return true;
  }),

  async searchPromise(payload) {
    let { search, selected, excluded } = payload;

    const query = {
      sort: 'description,code'
    };
    let filter = {};
    if (search && search.length) {
      filter.q = lkFilter(search);
    }
    if (selected && selected.length) {
      filter.selectedIds = inFilter(selected);
    } else if (excluded && excluded.length) {
      // This case should happen only when selected is empty
      filter.excludedIds = niFilter(excluded);
    }
    query.filter = filter;

    let decoratedQuery = this.beforeQuery?.(query);

    return await this.infinity.model(
      WarehouseResourceName.singular,
      decorateApiQuery(decoratedQuery || query),
      JsonApiInfinityModel
    );
  },

  _onSearchSuccess(warehouses) {
    return warehouses.filter(this.filterFn);
  }
});
