import AduProfile from 'volta/models/adu-profile';
import BaseModel from 'volta/models/base-model';
import BufferProfile from 'volta/models/buffer-profile';
import DafProfileModel from 'volta/models/daf-profile';
import {
  _normalize,
  collectionCommand,
  resourceCommand
} from 'volta/utils/api';

import { attr, belongsTo } from '@ember-data/model';

import { IPlanningSettingData, IUpdateManyPlanningSettings } from './types/planning-settings';
import { HookClass } from 'volta/utils/api/types';

export const DAF_FIELDS = [
  'dafProfileId',
  'dafWeekly',
  'dafMonthly',
  'dafRedBaseWeekly',
  'dafRedBaseMonthly',
  'dafRedSafetyWeekly',
  'dafRedSafetyMonthly',
  'dafYellowWeekly',
  'dafYellowMonthly',
  'dafGreenWeekly',
  'dafGreenMonthly',
  'dafLtWeekly',
  'dafLtMonthly',
  'dafUnit',
  'dafRedBaseUnit',
  'dafRedSafetyUnit',
  'dafGreenUnit',
  'dafYellowUnit',
  'dafLtUnit'
];

export type TDafField =
  | 'dafProfileId'
  | 'dafWeekly'
  | 'dafMonthly'
  | 'dafRedBaseWeekly'
  | 'dafRedBaseMonthly'
  | 'dafRedSafetyWeekly'
  | 'dafRedSafetyMonthly'
  | 'dafYellowWeekly'
  | 'dafYellowMonthly'
  | 'dafGreenWeekly'
  | 'dafGreenMonthly'
  | 'dafLtWeekly'
  | 'dafLtMonthly'
  | 'dafUnit'
  | 'dafRedBaseUnit'
  | 'dafRedSafetyUnit'
  | 'dafGreenUnit'
  | 'dafYellowUnit'
  | 'dafLtUnit';

export const ResourceName = {
  singular: 'planningSetting',
  plural: 'planningSettings'
};

export const Events = {
  ManyPlanningSettingsUpdated: 'ManyPlanningSettingsUpdated',
  PlanningSettingsUpdated: 'PlanningSettingsUpdated'
};

export const Commands = {
  FetchPlanningSettings: 'FetchPlanningSettings',
  UpdatePlanningSettings: 'UpdatePlanningSettings',
  UpdateManyPlanningSettings: 'UpdateManyPlanningSettings'
};

export default class PlanningSetting extends BaseModel {
  // Static class methods
  // ~~~~~

  // TODO - Remove when investigation on
  static modelName = 'planning-setting';

  static fetchPlanningSettings = collectionCommand(Commands.FetchPlanningSettings, {
    after: _normalize
  });

  static updateManyPlanningSettings = collectionCommand<IUpdateManyPlanningSettings>(
    Commands.UpdateManyPlanningSettings,
    {
      after: _normalize as HookClass<any, PlanningSetting[], typeof PlanningSetting>
    }
  );

  // Properties
  // ~~~~~

  @attr('string')
  declare skuId: string;

  @attr()
  settings!: IPlanningSettingData;

  @belongsTo('buffer-profile', { async: false })
  bufferProfile?: BufferProfile;

  @belongsTo('adu-profile', { async: false })
  aduProfile?: AduProfile;

  @belongsTo('daf-profile', { async: false })
  dafProfile?: DafProfileModel;

  // Getters
  // ~~~~~

  get isBuffered() {
    return this.settings.methodology === 'BUFFER';
  }

  get isMrp() {
    return this.settings.methodology === 'MRP';
  }

  get displayMethodology() {
    return this.settings.methodology ?? 'NONE';
  }

  get actualLeadTimeFactor() {
    return this.settings.leadTimeFactor ?? this.bufferProfile?.leadTimeFactor;
  }

  get actualVarFactor() {
    return this.settings.varFactor ?? this.bufferProfile?.varFactor;
  }

  get actualAduType() {
    return this.aduProfile?.aduType ?? this.settings.aduType;
  }

  get actualAduHorizon() {
    return this.aduProfile?.aduHorizon ?? this.settings.aduHorizon;
  }

  get actualAduHorizonOffset() {
    return this.aduProfile?.aduHorizonOffset ?? this.settings.aduHorizonOffset;
  }

  get actualAduOverride() {
    return this.aduProfile?.aduOverride ?? this.settings.aduOverride;
  }

  get actualDiscontinuedDemand() {
    return this.aduProfile?.discontinuedDemand ?? this.settings.discontinuedDemand;
  }

  updatePlanningSettings = resourceCommand(Commands.UpdatePlanningSettings);
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'planning-setting': PlanningSetting;
  }
}
