import { attr } from '@ember-data/model';
import BaseModel from 'volta/models/base-model';
import { IWorkshopIdentityInfo } from './types/workshop';

type TSkuLocationStatus = 'ACCEPTED' | 'QC' | 'REJECTED' | 'UNKNOWN';

export default class SkuLocation extends BaseModel {
  @attr('string') declare skuId: string;
  @attr('number') declare onHand: number;
  @attr('string') declare status: TSkuLocationStatus;
  @attr('string') location?: string;
  @attr('string') zone?: string;
  @attr('string') batchNumber?: string;

  @attr() declare workshopInfo: IWorkshopIdentityInfo;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sku-location': SkuLocation;
  }
}
