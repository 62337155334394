import ScrollLockManager from 'volta/services/scroll-lock-manager';

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface IArgs {
  selector?: string;
}

export default class BvScrollLock extends Component<IArgs> {
  @service scrollLockManager!: ScrollLockManager;

  constructor(owner: any, args: {}) {
    super(owner, args);
    this.scrollLockManager.setSelector(this.args.selector);
    this.scrollLockManager.registerScrollLock();
  }

  willDestroy() {
    this.scrollLockManager.unregisterScrollLock();
  }
}
