import { htmlSafe } from '@ember/template';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { round } from 'volta/utils/math-utils';

type TPosition = 'top' | 'bottom' | 'left' | 'right';
const LEVEL_POSITIONS: TPosition[] = ['top', 'bottom', 'left', 'right'];

interface IArgs {
  level: number;
  criticality?: string;
  showLevel?: boolean;
  showLevelInTooltip?: boolean;
  levelPosition?: TPosition;
  displayEmptyText?: boolean;
  emptyTextLabel?: string;
  bordered?: boolean;
  rounded?: boolean;
  small?: boolean;
}

export default class PercentGauge extends Component<IArgs> {
  // Services
  // ~~~~~

  // Properties
  // ~~~~~

  get showLevel() {
    return this.args.showLevel ?? true;
  }

  get levelPosition() {
    return this.args.levelPosition ?? 'top';
  }

  get emptyTextLabel() {
    return this.args.emptyTextLabel ?? 'none';
  }

  get criticalityClass() {
    const { level, criticality } = this.args;
    return isPresent(level)
      ? isPresent(criticality)
        ? `percent-gauge--${criticality}`
        : undefined
      : 'percent-gauge--none';
  }

  get levelClass() {
    const { levelPosition } = this;
    if (levelPosition && LEVEL_POSITIONS.includes(levelPosition.toLowerCase() as TPosition)) {
      return `percent-gauge--level-${levelPosition.toLowerCase()}`;
    }
    return undefined;
  }

  get tooltipClass() {
    return isPresent(this.args.criticality) ? `u-bg-${this.args.criticality}` : undefined;
  }

  get isFilled() {
    return this.args.level >= 1;
  }

  get fillStyle() {
    const level = this.args.level ?? 0;
    const width = level > 1 ? 1 : level < 0 ? 0 : level;
    return htmlSafe(`width:${(round(width, 2) ?? 0) * 100.0}%;`);
  }

  get isInverse() {
    return this.args.level <= 0.5;
  }
}
