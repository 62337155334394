import { inject as service } from '@ember/service';
import DomainSelect from 'volta/components/domain-select';
import ifUndefined from 'volta/macros/if-undefined';
import { PartTypeResourceName } from 'volta/models/part-type';
import JsonApiInfinityModel, { decorateApiQuery } from 'volta/models/jsonapi-infinity-model';
import { lkFilter, inFilter, niFilter } from 'volta/utils/filters-utils';

export default DomainSelect.extend({
  idKey: 'id',
  infiniteScroll: true,

  filterFn: ifUndefined(() => {
    return true;
  }),

  searchPromise(payload) {
    let { search, selected, excluded } = payload;

    const query = {
      sort: 'description,code'
    };
    let filter = {};
    if (search && search.length) {
      filter.q = lkFilter(search);
    }

    if (selected && selected.length) {
      filter.selectedIds = inFilter(selected);
    } else if (excluded && excluded.length) {
      // This case should happen only when selected is empty
      filter.excludedIds = niFilter(excluded);
    }
    query.filter = filter;

    let decoratedQuery = this.beforeQuery?.(query);
    return this.infinity.model(
      PartTypeResourceName.singular,
      decorateApiQuery(payload),
      JsonApiInfinityModel
    );
  },

  _onSearchSuccess(partTypes) {
    return partTypes.filter(this.filterFn);
  }
});
