import { sanitizeCustomProperties } from 'volta/utils/style-utils';

import {
  getResponsiveProps,
  getResponsiveValue,
  ResponsiveProp,
  ResponsiveValue,
  SpaceScale
} from '@bevolta/design-tokens';
import Component from '@glimmer/component';

type ColumnsAlias = 'oneThird' | 'oneHalf' | 'twoThirds';
type ColumnsType = number | string | ColumnsAlias[];
type Columns = ResponsiveProp<ColumnsType>;
type Gap = ResponsiveProp<SpaceScale>;
type ColumnsAlignItems = 'start' | 'end' | 'center';

function formatColumns(columns?: Columns): ResponsiveValue {
  if (typeof columns === 'object' && columns !== null && !Array.isArray(columns)) {
    return Object.fromEntries(
      Object.entries(columns).map(([breakpointAlias, breakpointColumns]) => [
        breakpointAlias,
        getColumnValue(breakpointColumns)
      ])
    );
  }

  return getColumnValue(columns);
}

function getColumnValue(columns?: ColumnsType) {
  if (!columns) return undefined;

  if (typeof columns === 'number' || !isNaN(Number(columns))) {
    return `repeat(${Number(columns)}, minmax(0, 1fr))`;
  }

  if (typeof columns === 'string') return columns;

  return columns
    .map((column) => {
      switch (column) {
        case 'oneThird':
          return 'minmax(0, 1fr)';
        case 'oneHalf':
          return 'minmax(0, 1fr)';
        case 'twoThirds':
          return 'minmax(0, 2fr)';
      }
    })
    .join(' ');
}

interface IArgs {
  /** The number of columns to display. Accepts either a single value or an object of values for different screen sizes.
   * @example
   * @columns={6}
   * @columns={{hash xs=1 sm=1 md=3 lg=6 xl=6}}
   */
  columns?: Columns;
  /** The spacing between children. Accepts a spacing token or an object of spacing tokens for different screen sizes.
   * @example
   * @gap='2'
   * @gap={{hash xs='1' sm='2' md='3' lg='4' xl='5'}}
   */
  gap?: Gap;
  /** Vertical alignment of children. If not set, inline elements will stretch to the height of the parent.
   * @example
   * @alignItems='start'
   */
  alignItems?: ColumnsAlignItems;
  className?: string;
}

export default class BvColumns extends Component<IArgs> {
  get style() {
    const { columns, gap, alignItems } = this.args;
    return sanitizeCustomProperties({
      ...getResponsiveValue('columns', 'grid-template-columns', formatColumns(columns)),
      ...getResponsiveProps('columns', 'gap', 'spacing', gap),
      '--bv-columns-align-items': alignItems ?? ''
    });
  }
}
