import loadInitializers from 'ember-load-initializers';

import Application from '@ember/application';
import EmberObject from '@ember/object';

import config from './config/environment';
import { initSentry } from './sentry';

import Resolver from 'ember-resolver';

initSentry();
class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);

EmberObject.reopen({
  toNative() {
    return Object.assign({}, this);
  },
  toJSON() {
    return JSON.stringify(this.toNative());
  }
});

export default App;
