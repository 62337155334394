import { helper } from '@ember/component/helper';

import { getOrElse } from '../utils/object-utils';

export function orElse([value, elseValue]: [unknown, unknown]) {
  return getOrElse(value, elseValue);
}

export function argOrDefault<T>(
  defaultValue: T
): PropertyDecorator {
  return (_target: any, key: string) => {
    return {
      get(this: { args: Record<string, T> }): T {
        const value = this.args[key];
        return value !== undefined ? value : defaultValue;
      }
    };
  };
}

export default helper(orElse);
