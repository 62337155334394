import { TImportTableName } from 'volta/models/types/data-import';

import Model, { attr } from '@ember-data/model';

export interface IResourceName {
  singular: string;
  plural: string;
  table?: TImportTableName;
}
interface ISerialized {
  data?: { attributes: {}; relationships?: { [key: string]: { data: { id: string } } } };
}
export default class BaseModel extends Model {
  @attr('number', { defaultValue: () => 1 })
  declare version: number;

  @attr('date', { defaultValue: () => new Date() })
  declare createdAt: Date;

  @attr('date', { defaultValue: () => new Date() })
  declare updatedAt: Date;

  toJSON() {
    const serialized: ISerialized = this.serialize();
    const { data } = serialized;
    const json: { [key: string]: any } = data?.attributes ?? {};
    if (data?.relationships) {
      Object.keys(data?.relationships).forEach((k: string) => {
        const rel = data?.relationships?.[k];
        json[k] = {
          id: rel?.data?.id
        };
      });
    }
    return json;
  }
}
