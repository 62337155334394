import { IEmptyCustomFieldTableColumn } from 'volta/models/types/customFieldDefinition';

export default function checkTableColumns() {
  return (_: string, columns: IEmptyCustomFieldTableColumn[]) => {
    const checks: number[] = [];

    if (columns?.length) {
      columns.forEach((c: IEmptyCustomFieldTableColumn, index: number) => {
        if (!c.name || !c.fieldType) {
          checks.push(index);
        } else if (c.fieldType === 'FORMULA' && !c.formula) {
          checks.push(index);
        }
      });
    }

    return !checks.length || checks;
  };
}
