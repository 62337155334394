import Ember from 'ember';
import { dropTask, restartableTask } from 'ember-concurrency';
import moment from 'moment-timezone';
import PlanningOrder, { POQueries, POResourceName, Statuses } from 'volta/models/planning-order';
import { defaultErrorHandler, IJSONAPIErrors } from 'volta/utils/error-utils';
import { btFilter, gtFilter, inFilter, ltFilter } from 'volta/utils/filters-utils';

import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import type { IActivePlanner, IPOWorkshopCapacity } from '../models/types/planning-order.d';
import type RouterService from '@ember/routing/router-service';
import type IntlService from 'ember-intl/services/intl';
import type StoreService from 'volta/services/store';
import type { TPOGroupFieldsKey } from 'volta/models/po-group';

export const PO_API_PAGE_LIMIT = 20000;
export const NB_PROJECTION_MONTHS = 12 * 5;
export default class PlanningOrderApi extends Service {
  @service store!: StoreService;
  @service intl!: IntlService;
  @service router!: RouterService;

  @tracked workshops: IPOWorkshopCapacity[] = [];
  @tracked users: IActivePlanner[] = [];

  @tracked addedPOCount: number = 0;
  @tracked calculatingSkus: string[] = [];

  // Getters
  // ~~~~~~

  // Lifecycle functions
  // ~~~~~~

  // Actions
  // ~~~~~~~~

  resetCount() {
    this.addedPOCount = 0;
  }

  // Ember concurrency Tasks
  // ~~~~~~

  /**
   * This function calls the CreateDraftOrder command from planning ids and assume
   * an event will be pushed to the websocket.
   * An event handler in this class will catch it and reload the created POs in the workbench
   * @param {Array<string>} planningIds A list of planning ids coming form the planning cards list
   */
  draftManyTask = dropTask(async (planningIds?: string[]) => {
    if (!planningIds || !planningIds.length) {
      return;
    }
    try {
      const response = await PlanningOrder.createDraftOrder({ planningIds });
      this.addedPOCount += planningIds.length;
      return response;
    } catch (error) {
      this.throwAssertOrFallback(error, 'poWorkbench.draftManyPloError');
      return;
    }
  });

  draftPlanningProjectionsTask = dropTask(async (skuId?: string, dates?: string[]) => {
    if (!skuId || !dates?.length) {
      return;
    }
    try {
      const createdPOs: PlanningOrder[] = await PlanningOrder.draftPlanningProjections({
        skuId,
        dates
      });
      this.addedPOCount += createdPOs.length;
      return createdPOs;
    } catch (error) {
      this.throwAssertOrFallback(error, 'poWorkbench.draftManyPloError');
      return;
    }
  });

  /**
   * This task fetch users that have a planned order in their workbench
   */
  usersTask = restartableTask(async () => {
    try {
      const response: Ember.Array<IActivePlanner> = await this.store.customQuery(
        POResourceName.singular,
        POQueries.Users
      );
      this.users = response ? response.toArray() : [];
      return this.users;
    } catch (error) {
      return defaultErrorHandler(error);
    }
  });

  projectionTask = restartableTask(
    async (
      poKeyObject: TPOGroupFieldsKey,
      groupOnly: boolean = false,
      capacity?: IPOWorkshopCapacity,
      workshopId?: string
    ) => {
      if (!workshopId) {
        return undefined;
      }

      try {
        const query = {
          page: {
            offset: 0,
            limit: PO_API_PAGE_LIMIT
          },
          sort: undefined as string | undefined,
          filter: {}
        };
        if (groupOnly) {
          const filter: Record<string, string | undefined> = {
            workshop: inFilter([workshopId]),
            status: inFilter([Statuses.NEW.value, Statuses.PROJECTED.value]),
            reorderQty: gtFilter('0')
          };

          if (capacity) {
            const { warehouse, supplier, acqCode, deliveryWorkshop } = poKeyObject;
            const warehouseId = warehouse?.id;
            if (warehouseId) {
              filter.warehouse = inFilter([warehouseId]);
            }

            const supplierId = supplier?.id as string | undefined;
            if (supplierId) {
              filter.supplier = inFilter([supplierId]);
            }

            const deliveryWorkshopId = deliveryWorkshop?.id as string | undefined;

            if (deliveryWorkshopId) {
              filter.deliveryWorkshop = inFilter([deliveryWorkshopId]);
            }

            const { startPeriod, endPeriod } = capacity;
            filter.reorderDate = btFilter([
              moment(startPeriod).format('YYYY-MM-DD'),
              moment(endPeriod).add(NB_PROJECTION_MONTHS, 'months').format('YYYY-MM-DD')
            ]);

            if (acqCode?.id) {
              filter.acqCode = inFilter([acqCode.id]);
            }

            query.sort = 'reorderDate';
          }

          query.filter = filter;
        } else {
          query.filter = {
            workshop: inFilter([workshopId]),
            status: inFilter([
              Statuses.NEW.value,
              Statuses.PROJECTED.value,
              Statuses.PROJECTED_FIRM.value
            ]),
            reorderQty: gtFilter('0'),
            dueDate: ltFilter(moment().add(NB_PROJECTION_MONTHS, 'months').format('YYYY-MM-DD'))
          };
        }

        return await this.store
          .query(POResourceName.singular, query)
          .then((response) => response.toArray());
      } catch (error) {
        return defaultErrorHandler(error);
      }
    }
  );

  // Private Helpers
  // ~~~~~~~

  // Helpers
  // ~~~~~~~~

  filterProjectionsByCapacity(
    projection: PlanningOrder[],
    { capacity, reorderQty, capacityUnit }: IPOWorkshopCapacity
  ): PlanningOrder[] {
    if (capacity) {
      const quantityToFill = capacity - reorderQty;
      const toleranceCeiling = quantityToFill * 0.1;
      let reorderQtySum = 0;
      const _projection: PlanningOrder[] = [];

      for (const p of projection) {
        const cadence = capacityUnit === 'HOUR' ? p.cadence ?? 0 : p.cadence ?? 1;
        reorderQtySum += cadence > 0 ? p.reorderQty / cadence : 0;

        if (
          reorderQtySum <= quantityToFill ||
          (reorderQtySum > quantityToFill && reorderQtySum <= quantityToFill + toleranceCeiling)
        ) {
          _projection.push(p);
        } else {
          break;
        }
      }

      return _projection;
    } else {
      return projection;
    }
  }

  private throwAssertOrFallback(error: IJSONAPIErrors, fallbackMessage: string) {
    defaultErrorHandler(error);
    const firstError = error.errors?.[0];
    if (firstError?.detail?.customAssert) {
      throw firstError.title;
    }
    throw this.intl.t(fallbackMessage);
  }
}
