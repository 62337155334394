import IntlService from 'ember-intl/services/intl';
import { IResourceName } from 'volta/models/base-model';
import { parseCFApiParamKey } from 'volta/models/custom-field-definition';
import { ICollectionFilter } from 'volta/models/types/collection-view';
import LocalizationService from 'volta/services/localization';
import { indexByFn } from 'volta/utils/array-utils';
import { strNormalizeAccent } from 'volta/utils/text-utils';

import { service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

const sanitized = (str: string) => strNormalizeAccent(str).toLowerCase();

interface ICollectionViewFilters {
  filters: TFilterDefinition<unknown>[];
  filterValues: ICollectionFilter[];
  initialValue: unknown;
  resourceName: IResourceName;
  onChange: (filterValue: TFilterValue, queryParam: string) => void;
}

export default class CollectionViewFilters extends Component<ICollectionViewFilters> {
  @service intl!: IntlService;
  @service localization!: LocalizationService;

  @tracked search?: string;

  get filtersWithTitle() {
    return this.args.filters.reduce((acc: (TFilterDefinition & { title: string })[], p) => {
      const labelKey = (p.isCustomField && parseCFApiParamKey(p.queryParam)) || p.queryParam;
      const title = (
        p.isCustomField
          ? this.localization.tOrElse(labelKey, p.title)
          : p.title ??
            this.localization.tOrElse(
              p.queryParam && 'filterDefinitions.' + p.queryParam,
              this.intl.t(p.queryParam)
            )
      ) as string;
      if (!p.hidden) {
        acc.push({ ...p, title });
      }

      return acc;
    }, []);
  }

  @cached
  get filteredFilters() {
    const { search, filtersWithTitle } = this;
    if (!search || search === '') {
      return filtersWithTitle;
    }
    const _search = sanitized(search);
    return filtersWithTitle.filter((p) => {
      return p.title && sanitized(p.title as string).includes(_search);
    });
  }

  @cached
  get indexedFilterValues() {
    return indexByFn(this.args.filterValues, (f) => f.property);
  }

  // helpers
  initialValue(filters: ICollectionFilter[] = [], queryParam: string) {
    return filters.find((filter: ICollectionFilter) => filter.property === queryParam);
  }
}
