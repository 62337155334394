import { attr, belongsTo } from '@ember-data/model';
import BaseModel, { IResourceName } from 'volta/models/base-model';
import PartType from 'volta/models/part-type';
import { resourceAction } from 'volta/utils/api';

export const PartQueries = {
  Tags: 'Tags'
};

export const PartResourceName: IResourceName = {
  singular: 'part',
  plural: 'parts'
};

export default class Part extends BaseModel {
  static getUnitMeasures = resourceAction({
    type: 'GET',
    path: 'unitMeasures'
  });

  @attr('string') code!: string;
  @attr('string') description?: string;
  @belongsTo('part-type', { async: false }) partType?: PartType;
  @attr('string') unitMeasure!: string;
  @attr('array') tags!: string[];
  @attr('string') externalId?: string;

  get partId() {
    return this.id;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    part: Part;
  }
}
