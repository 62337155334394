// @ts-ignore
import buildMessage from 'ember-changeset-validations/utils/validation-errors';
import IntlService from 'ember-intl/services/intl';
import _merge from 'lodash/merge';
import application from 'volta/utils/application';
import { safeGet } from 'volta/utils/object-utils';

const intl = application?.instance?.lookup('service:intl') as IntlService;

function buildErrorMessage(key: string, i18nType: string) {
  return buildMessage(i18nType, { type: i18nType, context: { description: key } });
}

export default function validateOnCheck({
  checkKeys,
  checkCondition,
  i18nKey,
  i18nType = 'invalid'
}: {
  checkKeys: string[];
  checkCondition: (currentValue: any, ...values: any) => boolean;
  i18nKey?: string;
  i18nType?: string;
}) {
  return (key: string, newValue: any, __: any, changes: any, content: any) => {
    const valuesToCheck = _merge({}, content, changes);
    const check = checkKeys.map((k: string) => safeGet(valuesToCheck, k));

    return (
      checkCondition(newValue, ...check) ||
      buildErrorMessage(i18nKey ? intl.t(i18nKey) : key, i18nType)
    );
  };
}
