import { inject as service } from '@ember/service';
import DomainSelect from 'volta/components/domain-select';
import ifUndefined from 'volta/macros/if-undefined';

import JsonApiInfinityModel, { decorateApiQuery } from 'volta/models/jsonapi-infinity-model';
import { inFilter, lkFilter, stringListToArray } from 'volta/utils/filters-utils';
import { ResourceName } from 'volta/models/user-role';

export default DomainSelect.extend({
  propertyKey: 'name',
  infiniteScroll: true,

  filterFn: ifUndefined(() => {
    return true;
  }),

  async searchPromise(payload) {
    let { search, selected } = payload;
    const query = {
      sort: 'name'
    };
    let filter = {};
    if (search && search.length) {
      filter.name = lkFilter(search);
    }
    if (selected && selected.length) {
      filter.selectedIds = inFilter(selected);
    }
    query.filter = filter;

    let decoratedQuery = this.beforeQuery?.(query);

    return await this.infinity.model(
      ResourceName.singular,
      decorateApiQuery(decoratedQuery || query),
      JsonApiInfinityModel
    );
  },

  _onSearchSuccess(userRoles) {
    return userRoles.filter(this.filterFn);
  }
});
