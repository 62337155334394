import { set } from '@ember/object';
import Days from 'ember-power-calendar/components/power-calendar-range/days';
import layout from 'ember-power-calendar/templates/components/power-calendar/days';

export default Days.extend({
  layout,

  _updateFocused(id) {
    if (!this.isDestroyed) {
      set(this, 'focusedId', id);
    }
  }
});
