
import { enqueueTask } from 'ember-concurrency';
import type IntlService from 'ember-intl/services/intl';
import moment from 'moment-timezone';
import PlanningModel, { PlanningEvents, PlanningResourceName } from 'volta/models/planning';
import type EventStreamService from 'volta/services/event-stream';
import type StoreService from 'volta/services/store';
import { defaultErrorHandler } from 'volta/utils/error-utils';
import { abbreviateNumber } from 'volta/utils/text-utils';

import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';
import type AppNavState from './app-nav-state';

type TCalculationStatus =
  | 'Idle'
  | 'Calculating'
  | 'Error'
  | 'Started'
  | 'Success'
  | 'WaitingForImport';

export const CalculationStatus: Record<TCalculationStatus, TCalculationStatus> = {
  Idle: 'Idle',
  Calculating: 'Calculating',
  Error: 'Error',
  Started: 'Started',
  Success: 'Success',
  WaitingForImport: 'WaitingForImport'
};

export interface IPlanningCalculationState {
  status: TCalculationStatus;
  skuIds: string[];
  warehouseIds: string[];
  calculated: number;
  total: number;
  error?: string;
  currentLLC: number;
  maxLLC: number;
  launchedBy?: { id: string; login: string };
}

const InitialState: IPlanningCalculationState = {
  status: CalculationStatus.Idle,
  warehouseIds: [],
  skuIds: [],
  calculated: 0,
  total: 0,
  error: undefined,
  currentLLC: 0,
  maxLLC: 0,
  launchedBy: undefined
};

export default class PlanningApiService extends Service {
  @service intl!: IntlService;
  @service store!: StoreService;
  @service eventStream!: EventStreamService;
  @service appNavState!: AppNavState;

  // Attributes

  @tracked globalCalculationState: IPlanningCalculationState = InitialState;

  get globalCalculationIsError() {
    return this.globalCalculationState.status === CalculationStatus.Error;
  }

  get globalCalculationIsSuccess() {
    return this.globalCalculationState.status === CalculationStatus.Success;
  }

  get globalCalculationIsStarted() {
    return this.globalCalculationState.status === CalculationStatus.Started;
  }

  get globalCalculationIsCalculating() {
    return this.globalCalculationState.status === CalculationStatus.Calculating;
  }

  get globalCalculationIsRunning() {
    return this.globalCalculationIsStarted || this.globalCalculationIsCalculating;
  }

  get calculatedPercent() {
    const { calculated, total } = this.globalCalculationState;
    if (isPresent(calculated) && isPresent(total) && total > 0) {
      return calculated / total;
    }
    return 0;
  }

  get globalCalculationDescription() {
    const { calculated, total, status } = this.globalCalculationState;
    return (
      this.intl.t(`planningStatuses.${status}`) +
      (this.globalCalculationIsCalculating
        ? `: ${this.intl.t('planningStatuses.progression', {
            calculated: abbreviateNumber(calculated, 1, (n: number) => this.intl.formatNumber(n)),
            total: abbreviateNumber(total, 1, (n: number) => this.intl.formatNumber(n))
          })}`
        : '')
    );
  }

  constructor() {
    super(...arguments);
    this.eventStream.addHandler(
      PlanningResourceName.plural,
      PlanningEvents.PlanningsGlobalCalculationStarted,
      this.setCalculationState,
      this
    );
    this.eventStream.addHandler(
      PlanningResourceName.plural,
      PlanningEvents.PlanningsGlobalCalculationProgress,
      this.setCalculationState,
      this
    );
    this.eventStream.addHandler(
      PlanningResourceName.plural,
      PlanningEvents.PlanningsGlobalCalculationDone,
      this.setCalculationState,
      this
    );
  }

  /**
   * Launch planning calculation
   *
   * we specify ember-concurrency enqueue modifier so that the calculation
   * can only be run one at the time but we don't loose any
   *
   * @param skuIds {Array} A list of specific SKU ids to calculate, if empty calculate all
   * @param warehouseIds {Array} A list of specific warehouse ids to calculate, if empty calculate all
   */
  launchCalculation = enqueueTask(async (skuIds: string[] = [], warehouseIds: string[] = []) => {
    try {
      const date = moment.utc().format('YYYY-MM-DD');
      const payload = { date, skuIds, warehouseIds };
      return await PlanningModel.generateMany(payload);
    } catch (error) {
      defaultErrorHandler(error);
      throw error;
    }
  });

  // Private handlers

  setCalculationState(state: IPlanningCalculationState) {
    if (state) {
      this.globalCalculationState = state;
      this.appNavState.isLoading = this.globalCalculationIsRunning;
      this.appNavState.generalLoadingMessage = this.globalCalculationDescription;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'planning-api': PlanningApiService;
  }
}
