import { restartableTask, timeout } from 'ember-concurrency';

import Component from '@glimmer/component';

const DEBOUNCE_MS = 250;
interface IArgs {
  filterValues: TFilterValues;
  onSearchChange?: (filterValues: TFilterValues, query: string) => Promise<unknown>;
  searchValue: string;
  placeholder?: string;
}

export default class BvFiltersSearchInput extends Component<IArgs> {
  searchTask = restartableTask(async (query: string) => {
    const { onSearchChange, filterValues } = this.args;

    await timeout(DEBOUNCE_MS);
    return (await onSearchChange?.({ ...filterValues }, query)) as unknown;
  });
}
