import { get } from '@ember/object';
import _isEqual from 'lodash/isEqual';

import { isArray } from '@ember/array';
import { isNone, isPresent, typeOf } from '@ember/utils';

import { isNaN } from './math-utils';
import { BufferedChangeset } from 'ember-changeset/types';
import { isChangeset } from 'validated-changeset';

export const NOOP = () => {
  // empty
};

/**
 * Flatten an object by concatenating key paths of descendent objects
 *
 * ```javascript
 * import { flattenObject } from 'volta/utils/object-utils';
 *
 * const obj = {a: {aa: 'AAA'}};
 * const result = flattenObject(obj); // {a_aa: 'AAA'}
 * ```
 * @method flattenObject
 * @static
 * @for volta/utils/object-utils
 * @param {Object} obj The object to flatten
 * @param {String} sep Separator string used in the key paths concatenation
 * @param {Boolean} keepArrays false if array values are not keeped in the resulting object
 * @public
 */
export function flattenObject(obj: object, sep = '_', keepArrays = true) {
  type TKey = keyof typeof obj;
  const toReturn = {};

  for (const i in obj) {
    if (!obj.hasOwnProperty(i)) {
      continue;
    }
    const key = i as TKey;
    const current = obj[key];

    if (typeof current === 'object' && current !== null && keepArrays && !isArray(current)) {
      const flatObject = flattenObject(current, sep, keepArrays);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) {
          continue;
        }

        toReturn[(i + sep + x) as TKey] = flatObject[x as TKey];
      }
    } else {
      toReturn[key] = current;
    }
  }
  return toReturn;
}

/**
 * Get keys from an object excluding a given set
 *
 * @param {Object} obj Object to get keys from
 * @param {Array} excludeKeys Set of keys to exclude
 * @return {Array} The given object keys except keys from {{excludedKeys}}
 */
export function keysOtherThan(obj: object, excludeKeys: string[]) {
  const res = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (!excludeKeys.includes(key)) {
        res.push(key);
      }
    }
  }
  return res;
}

/**
 * Copy a object without some given attributes
 *
 * @param {Object} obj The object to copy
 * @param {Array} attrs A set of attributes to remove from the copied object
 * @return {Object} An object without the given attributes
 */
export function withoutAttributes(obj: object, attrs: string[]): Record<string, any> {
  const res = {} as any;
  const keys = keysOtherThan(obj, attrs);
  for (const key of keys) {
    type TKey = keyof typeof obj;
    res[key] = obj[key as TKey] as any;
  }
  return res;
}

/**
 * This function removes keys with undefined or null values from an object
 *
 * @param {Object} obj Any object
 * @returns {Object}
 */
export function withoutUndefined(obj = {}) {
  const ret = {};
  Object.keys(obj).forEach((key: keyof typeof obj) => {
    if (isPresent(obj[key])) {
      ret[key] = obj[key];
    }
  });
  return ret;
}

/**
 * Creates an object composed of the object properties predicate returns truthy for.
 * @param obj Source object
 * @param keys Object keys to keep
 * @returns A new object
 */
export function pick<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
  const ret: any = {};
  keys.forEach((key) => {
    ret[key] = obj[key];
  });
  return ret;
}

export function shallowEqual(objA: any, objB: any): boolean {
  if (objA === objB) {
    return true;
  }

  if (!objA || !objB) {
    return false;
  }

  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);
  const len = aKeys.length;

  if (bKeys.length !== len) {
    return false;
  }

  for (let i = 0; i < len; i++) {
    const key = aKeys[i];

    if (objA[key] !== objB[key] || !Object.prototype.hasOwnProperty.call(objB, key)) {
      return false;
    }
  }

  return true;
}

export function deepEqual(objA: any, objB: any): boolean {
  return _isEqual(objA, objB);
}

/**
 * Replaces undefined or empty values form an object by a default value
 * If no default value is given, the object will just be cloned as is.
 *
 * @param {Object} object The object in which to replace missing values
 * @param {Object|Number|String|Array} defaultValue The default value to replace
 * @return {Object} The cloned object with replaced undefined values
 */
export function coalesceObject(object = {}, defaultValue: object | number | string | any[]) {
  if (!isPresent(defaultValue)) {
    return Object.assign({}, object);
  }

  const newObject = {} as any;
  type TKey = keyof typeof object;
  Object.keys(object).forEach((key: TKey) => {
    const value = object[key];
    newObject[key] =
      typeOf(value) === 'object'
        ? coalesceObject(value, defaultValue)
        : getOrElse(value, defaultValue);
  });
  return newObject;
}

/**
 * Function for safely getting deeply nested value from an object
 * @param object
 * @param {String} path
 * @param defaultValue
 * @return {string}
 */
export function safeGet(object = {}, path: string, defaultValue?: any) {
  return `${path}`.split('.').reduce((xs, x) => {
    const keyIsNumber = Number(x);
    const key = Number.isNaN(keyIsNumber) ? x : keyIsNumber;
    type TKey = keyof typeof object;
    return xs && isPresent(xs[key as TKey]) ? xs[key as TKey] : defaultValue;
  }, object);
}

/**
 * Return the given value if present (not undefined or null)
 * or else return the default value
 *
 * @param {*} value A value to check for existence
 * @param {*} defaultValue A default value to use instead
 * @return {*} value or defaultValue
 */
export function getOrElse(value: any, defaultValue: any) {
  return !isNone(value) && (typeof value === 'string' || typeof value === 'object' || !isNaN(value))
    ? value
    : defaultValue;
}

/**
 * Checks if a given value is a function
 *
 * @param {*} fn Function to test
 * @return {boolean} true if the given value is a function
 */
export function isFunction(fn: any): boolean {
  return typeOf(fn) === 'function';
}

/**
 * Returns an no-op function if the given value is undefined
 *
 * @param {function} fn The function to test
 * @return {function} Either the given function if valid or a NOOP function
 */
export function noopIfUndefined(fn?: (...args: any) => void) {
  return isPresent(fn) && isFunction(fn) ? fn : NOOP;
}

/**
 * Check if the given object is a promise
 *
 * @param {Promise} promise The promise to check
 * @return {boolean} true if it's a promise
 */
export function isThenable(promise?: Promise<any>): boolean {
  return isFunction((promise || {}).then);
}

export function getChangesetValue(cs?: BufferedChangeset, key?: string): unknown | undefined {
  if (!cs || !key) {
    return undefined;
  }
  const csValue = isChangeset(cs) ? cs.get(key) : get(cs, key);
  return csValue?.content ?? csValue;
}
