import { handleMouseUpByBlurring } from 'volta/utils/focus';

import Component from '@glimmer/component';
import { TIntent, TSize } from 'volta/utils/style-utils';

type TTagSize = Extract<TSize, 'sm' | 'md' | 'lg'>;
const SIZES: TTagSize[] = ['sm', 'md', 'lg'];

type IColor = TIntent | 'primary';
const COLORS: IColor[] = ['primary', 'success', 'danger', 'warning', 'info'];

interface IArgs {
  size: TTagSize;
  color: IColor;
}

export default class BvTag extends Component<IArgs> {
  handleMouseUpByBlurring = handleMouseUpByBlurring;

  get sizeClass() {
    const { size } = this.args;
    return `bv-tag--${SIZES.includes(size) ? size : 'md'}`;
  }

  get colorClass() {
    const { color } = this.args;
    return color && COLORS.includes(color) && `bv-tag--${color}`;
  }
}
