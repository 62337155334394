import { scheduleOnce } from '@ember/runloop';
import Modifier from 'ember-modifier';

// @ts-ignore
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { capitalize } from '@ember/string';

// Props to https://github.com/jelhan/ember-autoresize-modifier

type IMode = 'height' | 'width';
interface INamed {
  mode: IMode;
}
interface IArgs {
  positional: [string, boolean];
  named: INamed;
}

export default class AutoresizeModifier extends Modifier<IArgs> {
  el: HTMLElement | null = null;
  disabled = false;
  mode?: IMode;
  value?: string;

  modify(element: HTMLElement, [value, disabled]: [string, boolean], { mode }: INamed): void {
    this.el = element;
    this.mode = mode;
    this.value = value;
    this.disabled = disabled;

    if (!this.disabled) {
      // resize on every input event
      this.el.addEventListener('input', this.scheduleResize);
    }

    this.scheduleResize();
    registerDestructor(this, () => {
      this.el?.removeEventListener('input', this.scheduleResize);
    });
  }

  @action
  resize() {
    if (!this.el) {
      return;
    }
    const { el, mode = 'height' } = this;

    let previousWrap = el.style.whiteSpace;

    if (this.mode === 'width') {
      // disable default wrapping
      el.style.whiteSpace = 'pre';
    }

    let capitalizeDimension = capitalize(mode);

    // height / width must be calculated independently from height / width previously enforced
    el.style[mode] = 'auto';

    let isBorderBox = window.getComputedStyle(el).boxSizing === 'border-box';
    let requiredDimension = el[`scroll${capitalizeDimension}` as keyof HTMLElement] as number;

    if (isBorderBox) {
      // borders must be added on top of scrollHeight / scrollWidth if box-sizing is border-box
      let borderDimension =
        (el[`offset${capitalizeDimension}` as keyof HTMLElement] as number) -
        (el[`client${capitalizeDimension}` as keyof HTMLElement] as number);
      requiredDimension += borderDimension;
    }

    el.style[mode] = `${requiredDimension}px`;

    el.style.whiteSpace = previousWrap;
  }

  @action
  scheduleResize() {
    scheduleOnce('afterRender', this, this.resize);
  }
}
