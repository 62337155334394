import { StockBlockItemStats } from 'volta/models/app-feature';
import {
  AcquisitionCodes,
  AcquisitionCodesArray,
  AcquisitionCodesOptions
} from 'volta/models/constants/acquisition-codes';
import { BvTableFeaturesIds } from 'volta/models/constants/app-features-columns';
import BufferZones, {
  BufferZonesIndex,
  BufferZonesOptions
} from 'volta/models/constants/buffer-zones';

import { helper } from '@ember/component/helper';
import { ADU_FREQUENCIES, ADU_FREQUENCIES_OPTIONS } from 'volta/models/constants/adu';
import { Statuses } from 'volta/models/planning-order';

const Constants = {
  AcquisitionCodes,
  AcquisitionCodesOptions,
  AcquisitionCodesArray,
  BufferZones,
  BufferZonesIndex,
  BufferZonesOptions,
  StockBlockItemStats,
  BvTableFeaturesIds,
  ADU_FREQUENCIES,
  ADU_FREQUENCIES_OPTIONS,
  POStatusOptions: Statuses
};

export default helper(function constant([key]: [key?: keyof typeof Constants] /*, hash*/) {
  return key ? Constants[key] : undefined;
});
