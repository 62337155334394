export type TBufferZone = 'critical' | 'high' | 'medium' | 'low' | 'otog';

const BufferZones: Array<TBufferZone> = ['critical', 'high', 'medium', 'low', 'otog'];

export const BufferZonesIndex: Record<TBufferZone, number> = BufferZones.reduce(
  (acc, zone, index) => {
    acc[zone] = index;
    return acc;
  },
  {} as Record<TBufferZone, number>
);

export const BufferZonesOptions = BufferZones.map((zone: string, index: number) => {
  return { key: index, value: zone };
});

export default BufferZones;
