import { attr, belongsTo } from '@ember-data/model';
import BaseModel from 'volta/models/base-model';
import { collectionCommand, resourceCommand as command, _normalize } from 'volta/utils/api';
import { ISettings } from "volta/models/types/settings";
import Warehouse from "volta/models/warehouse";

/**
 * @type {{singular: string, plural: string}}
 */
export const ConfigResourceName = {
  singular: 'config',
  plural: 'configs'
};

const Commands = {
  OverrideForWarehouse: 'OverrideForWarehouse'
};

/**
 * @class Config
 * @extends BaseModel
 */
export default class Config extends BaseModel {

  @belongsTo('warehouse', { async: false }) warehouse!: Warehouse;

  /**
   * @property settings Actual settings payload
   */
  @attr() settings!: ISettings;

  /** Commands  */

  updateSetting = command('UpdateSetting');
  updateTimeZone = command('UpdateTimeZone');
  updateLocale = command('UpdateLocale');
  updateWeekendDays = command('UpdateWeekendDays');
  updateAlert = command('UpdateAlert');
  updateDemandCalculation = command('UpdateDemandCalculation');
  updatePlanningProjection = command('UpdatePlanningProjection');
  updateSupplyGeneration = command('UpdateSupplyGeneration');
  updateDefaultBuffer = command('UpdateDefaultBuffer');
  updateLtCategoryMake = command('UpdateLtCategoryMake');
  updateLtCategoryBuy = command('UpdateLtCategoryBuy');
  updateLtCategoryDistribute = command('UpdateLtCategoryDistribute');
  updateVarCategoryMake = command('UpdateVarCategoryMake');
  updateVarCategoryBuy = command('UpdateVarCategoryBuy');
  updateVarCategoryDistribute = command('UpdateVarCategoryDistribute');
  updatePoWorkbench = command('UpdatePOWorkbench');
  updateBomExplosion = command('UpdateBomExplosion');

  /**
   * Collection commands
   */

  static overrideForWarehouse = collectionCommand(Commands.OverrideForWarehouse, {
    after: _normalize
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'config': Config;
  }
}
