import { TIntent } from 'volta/utils/style-utils';

import { isArray } from '@ember/array';
import { assert } from '@ember/debug';
import Component from '@glimmer/component';

export interface IBvMenuItem {
  hasArrow?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  active?: boolean;
  href?: string;
  model?: object;
  icon?: string;
  iconColor?: string;
  content?: string | Component;
  suffix?: string | Component;
  color?: TIntent;
  selected?: boolean;
  onClick?: (event: Event) => void;
}

export interface IBvMenuSection {
  title?: string;
  items: IBvMenuItem[];
}

interface IArgs {
  // Collection of actions for list
  items?: IBvMenuItem[];
  // Collection of sectioned action items
  sections?: IBvMenuSection[];
  // Callback when any item is clicked or keypressed
  onAnyItemAction?: () => void;
}

export default class BvMenu extends Component<IArgs> {
  get hasMultipleSections() {
    return this.finalSections.length > 1;
  }

  /**
   * It takes the sections array and adds the items array to it.
   * @returns The finalSections array.
   */
  get finalSections() {
    const { items = [], sections = [] } = this.args;
    const validItems = items.filter(Boolean);
    const validSections = sections.reduce((acc, s) => {
      if (s) {
        const items = s.items.filter(Boolean);
        if (items.length > 0) {
          acc.push({ ...s, items });
        }
      }
      return acc;
    }, [] as IBvMenuSection[]);
    const finalSections = [];

    if (validItems.length > 0) {
      finalSections.push({ items: validItems });
    }

    assert(
      `bevolta::bv-menu - sections must be an array, you passed ${sections}`,
      isArray(sections)
    );

    if (validSections.length > 0) {
      finalSections.push(...validSections);
    }

    return finalSections;
  }
}
