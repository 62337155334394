import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface IArgs {
  disabled?: boolean;
  onChange?: (e: Event) => any;
  onFocus?: (e: Event) => any;
  onBlur?: (e: Event) => any;
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export default class BvChoiceBlocksItem extends Component<IArgs> {
  @tracked
  focused: boolean = false;

  @action
  onChange(e: InputEvent) {
    if (this.args.onChange && !this.args.disabled) {
      this.args.onChange(e);
    }
  }

  @action
  onFocus(e: InputEvent) {
    this.focused = true;
    if (this.args.onFocus) {
      this.args.onFocus(e);
    }
  }

  @action
  onBlur(e: InputEvent) {
    this.focused = false;
    if (this.args.onBlur) {
      this.args.onBlur(e);
    }
  }
}
