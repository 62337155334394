import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { defaultErrorHandler } from 'volta/utils/error-utils';
import StoreService from './store';
import { Queries as TransactionQueries } from 'volta/models/transaction';
import { indexByFn } from 'volta/utils/array-utils';

const BaseCurrencies = ['EUR', 'USD', 'GBP', 'CHF', 'JPY', 'CAD', 'AUD', 'CNY', 'HKD'];

export default class ModelEnumService extends Service {
  @service store!: StoreService;

  @tracked forecastTypes?: string[];
  @tracked orderTypes?: string[];
  @tracked currencies?: TCurrency[];

  getCurrencies = task(async (force: boolean = false) => {
    try {
      if (!this.currencies || force) {
        const currencies: TCurrency[] = await this.store.normalGet('currencies');
        const currencyMap = indexByFn(currencies, (c) => c.code);

        this.currencies = BaseCurrencies.map((c) => currencyMap[c])
          .filter(Boolean)
          .concat(currencies.filter((c) => !BaseCurrencies.includes(c.code)));
      }
      return this.currencies;
    } catch (error) {
      defaultErrorHandler(error);
      return;
    }
  });

  getForecastTypes = task(async (force: boolean = false) => {
    try {
      if (!this.forecastTypes || force) {
        this.forecastTypes = await this.store.normalGet('demandForecasts/forecastTypes');
      }
      return this.forecastTypes;
    } catch (error) {
      defaultErrorHandler(error);
      return;
    }
  });

  getOrderTypes = task(async (force: boolean = false) => {
    try {
      if (!this.orderTypes || force) {
        this.orderTypes = await this.store.customQuery(
          'transaction',
          TransactionQueries.OrderTypes
        );
      }
      return this.orderTypes;
    } catch (error) {
      defaultErrorHandler(error);
      return;
    }
  });
}

declare module '@ember/service' {
  interface Registry {
    'model-enum': ModelEnumService;
  }
}
