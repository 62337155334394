import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import { service } from '@ember/service';
import { IBvMenuItem } from 'volta/components/bv-menu';
import { isPresent } from '@ember/utils';
import { TPOPriorityOption, TPOPriorityOptionValue } from 'volta/models/types/planning-order';
import { POPriorityOptions, POPriorityOptionsDict } from 'volta/models/planning-order';

interface IArgs {
  value?: TPOPriorityOptionValue;
  values?: TPOPriorityOptionValue[];
  onChange: (value: TPOPriorityOptionValue) => void;
  onChangeMany: (values: TPOPriorityOptionValue[]) => void;
}

export default class PoWorkbenchPrioritySelect extends Component<IArgs> {
  @service intl!: IntlService;

  options = POPriorityOptions;
  optionsByValue = POPriorityOptionsDict;

  get selectedOption() {
    const { value } = this.args;
    return isPresent(value) ? POPriorityOptionsDict[`${value}`] : undefined;
  }

  get selectedOptions() {
    const { values = [] } = this.args;
    const valuesAsStr = values.map((v) => v.toString());
    return values.length
      ? this.options.filter((o) => valuesAsStr.includes(o.value.toString()))
      : [];
  }

  getOptions = (keys: string[], afterAction?: () => void) =>
    POPriorityOptions.reduce((acc, { key, icon, iconColor, value }) => {
      if (keys.length && !keys.includes(key)) {
        return acc;
      }
      acc.push({
        icon,
        iconColor,
        content: this.intl.t(key),
        disabled: false,
        selected: this.selectedOption?.value === value,
        onClick: async (_event: MouseEvent) => {
          await this.args.onChange(value);
          afterAction?.();
        }
      });
      return acc;
    }, [] as IBvMenuItem[]);

  handleChange = (option: TPOPriorityOption) => {
    return this.args.onChange?.(option.value);
  };

  handleChangeMany = (values: TPOPriorityOption[]) => {
    return this.args.onChangeMany?.(values.map((v) => v.value));
  };
}
