import { THueVariationString, TVariation } from './../utils/style-utils';
import { helper } from '@ember/component/helper';

import { getColorCssVar } from '../utils/style-utils';
import { isPresent } from '@ember/utils';

export default helper(function classNamesHelper(
  [color]: [THueVariationString],
  { variant, alpha, rgba }: { variant?: TVariation; alpha?: number; rgba?: boolean } = {}
) {
  const cssVar = getColorCssVar(color, variant, alpha);
  return isPresent(alpha) && rgba ? `rgba(${cssVar}, ${alpha})` : cssVar;
});
