import EmberPowerSelectMultipleTrigger from 'ember-power-select/components/power-select-multiple/trigger';

import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class BvSelectMultiTrigger extends EmberPowerSelectMultipleTrigger {
  @tracked focused = false;

  @action
  deleteOption(option: any) {
    let { select } = this.args;
    select && select.actions.choose(option);
    return false;
  }
}
