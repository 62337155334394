import Task from 'volta/models/task';
import { _normalize, collectionCommand } from 'volta/utils/api';

import { TaskCommands } from './task';
import { TPlanningMethodology } from "volta/models/types/planning-settings";
import { TAcquisitionCode } from "volta/models/constants/acquisition-codes";

interface ILinkedEntityPayload {
  partCode: string;
  description?: string;
  warehouseCode: string;
  methodology?: TPlanningMethodology;
  isProtected: boolean;
  acqCode: TAcquisitionCode;
  isActive: boolean;
}

export default class SkuTask extends Task<Record<string, any>, ILinkedEntityPayload> {
  /**
   * Commands
   */

  static createTask = collectionCommand(TaskCommands.CreateTask, {
    after: _normalize,
    ajaxOptions: { queryParams: { include: 'createdBy,resolvedBy,assignees' } }
  });

  static updateTask = collectionCommand(TaskCommands.UpdateTask, {
    after: _normalize
  });

  static deleteTask = collectionCommand(TaskCommands.DeleteTask, {
    after: _normalize
  });

  static resolveTask = collectionCommand(TaskCommands.ResolveTask, {
    after: _normalize
  });

  static reopenTask = collectionCommand(TaskCommands.ReopenTask, {
    after: _normalize
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sku-task': SkuTask;
  }
}
