import Modifier from 'ember-modifier';

// @ts-ignore
import { registerDestructor } from '@ember/destroyable';
import autoAnimate, {
  AnimationController,
  AutoAnimateOptions,
  AutoAnimationPlugin
} from '@formkit/auto-animate';

interface IArgs {
  positional: [boolean, Partial<AutoAnimateOptions> | AutoAnimationPlugin];
  named: {};
}

export default class AutoAnimateModifier extends Modifier<IArgs> {
  controller: AnimationController | undefined;

  constructor(owner: any, args: IArgs) {
    super(owner, args);

    registerDestructor(this, () => {
      this.controller = undefined;
    });
  }

  modify(
    element: Element | null,
    [enabled, options]: [boolean, Partial<AutoAnimateOptions> | AutoAnimationPlugin]
  ) {
    if (element instanceof HTMLElement) {
      this.controller = autoAnimate(element, options);
      enabled ? this.controller.enable() : this.controller.disable();
    } else {
      this.controller = undefined;
    }
  }
}
