import { inject as service } from '@ember/service';
import DomainSelect from 'volta/components/domain-select';
import JsonApiInfinityModel, { decorateApiQuery } from 'volta/models/jsonapi-infinity-model';
import {
  anyFilter,
  eqFilter,
  inFilter,
  lkFilter,
  niFilter,
  stringListToArray
} from 'volta/utils/filters-utils';
import { WorkshopResourceName } from 'volta/models/workshop';

/**
 * @class WorkshopSelect
 * @extends DomainSelect
 */
export default DomainSelect.extend({
  infiniteScroll: true,
  workshopType: undefined,
  warehouseId: undefined,
  simulationId: undefined,
  skuId: undefined,

  async searchPromise(payload) {
    let { search, selected, excluded } = payload;
    const query = {
      sort: 'description,code'
    };
    let filter = {};

    if (search?.length) {
      filter.q = lkFilter(search);
    }

    if (selected?.length) {
      filter.selectedIds = inFilter(selected);
    } else if (excluded?.length) {
      filter.id = niFilter(excluded);
    }

    if (this.workshopType?.length) {
      filter.workshopType = eqFilter(`${this.workshopType}`.toUpperCase());
    }

    if (this.warehouseId?.length) {
      filter.warehouseId = eqFilter(this.warehouseId);
    }

    if (this.simulationId?.length) {
      filter.simulationId = eqFilter(this.simulationId);
    }

    if (this.skuId?.length) {
      filter.skuId = eqFilter(this.skuId);
    }

    query.filter = filter;

    let decoratedQuery = this.beforeQuery?.(query);

    return await this.infinity.model(
      WorkshopResourceName.singular,
      decorateApiQuery(decoratedQuery || query),
      JsonApiInfinityModel
    );
  }
});
