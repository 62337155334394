import { restartableTask } from 'ember-concurrency';
import moment from 'moment-timezone';
import SupplierSelect from 'volta/components/supplier-select';
import WarehouseSelect from 'volta/components/warehouse-select';
import workshopSelect from 'volta/components/workshop-select';
import { SupplierResourceName } from 'volta/models/supplier';
import SupplierCalendarDay, {
  SupplierCalendarDayResourceName
} from 'volta/models/supplier-calendar-day';
import { WarehouseResourceName } from 'volta/models/warehouse';
import WarehouseCalendarDay, {
  WarehouseCalendarDayResourceName
} from 'volta/models/warehouse-calendar-day';
import WorkshopCalendarDay, {
  WorkshopCalendarDayResourceName
} from 'volta/models/workshop-calendar-day';
import { indexBy } from 'volta/utils/array-utils';
import { isWeekendDay } from 'volta/utils/date-utils';
import { defaultErrorHandler } from 'volta/utils/error-utils';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import type IntlService from 'ember-intl/services/intl';
import type { IResourceName } from 'volta/models/base-model';
import type AppSettings from 'volta/services/app-settings';
import type AuthorizationService from 'volta/services/authorization';
import type BvFlashService from 'volta/services/bv-flash';
import type { TCalendarDay } from 'volta/services/calendar-service';
import type CalendarService from 'volta/services/calendar-service';

type IIndexedCalendarDay = TCalendarDay & {
  date: string;
};

type TEntityIdKey = 'warehouseId' | 'supplierId' | 'workshopId';
type TOpeningKeys = 'openForReception' | 'openForDemand' | 'openForProduction';
type TEntityCalendarDay = Record<string, Partial<Record<TOpeningKeys, boolean>>>;

type TDate = Date | moment.Moment;
type TResetDayPayload = { [key in TEntityIdKey]: string } & { dates: string[] };
type TUpdateDayPayload = { [key in TEntityIdKey]: string } & { days: Partial<TEntityCalendarDay> };

const TODAY = new Date();

interface ICalendarConfig {
  label: string;
  entityId: TEntityIdKey;
  Model: typeof WarehouseCalendarDay | typeof SupplierCalendarDay | typeof WorkshopCalendarDay;
  Selector: typeof WarehouseSelect | typeof SupplierSelect | typeof workshopSelect;
  permission: string;
  parentEntityName: IResourceName;
  payloadKeys: Array<TOpeningKeys>;
}

export const CALENDAR_CONFIG_BY_RESOURCENAME = {
  [WarehouseCalendarDayResourceName.singular]: {
    label: 'warehouse',
    entityId: 'warehouseId',
    parentEntityName: WarehouseResourceName,
    Model: WarehouseCalendarDay,
    Selector: WarehouseSelect,
    permission: 'UpdateWarehouse',
    payloadKeys: ['openForReception', 'openForDemand', 'openForProduction']
  },
  [SupplierCalendarDayResourceName.singular]: {
    label: 'supplier',
    entityId: 'supplierId',
    parentEntityName: SupplierResourceName,
    Model: SupplierCalendarDay,
    Selector: SupplierSelect,
    permission: 'UpdateSupplier',
    payloadKeys: ['openForReception']
  },
  [WorkshopCalendarDayResourceName.singular]: {
    label: 'workshop',
    entityId: 'workshopId',
    parentEntityName: WorkshopCalendarDayResourceName,
    Model: WorkshopCalendarDay,
    Selector: workshopSelect,
    permission: 'UpdateWorkshop',
    payloadKeys: ['openForReception', 'openForDemand']
  }
} as Record<string, ICalendarConfig>;

type PowerCalendarDateRange<T = Date> = { start: T; end: T | null };

interface IArgs {
  entityId: string;
  resourceName: IResourceName;
  withReception?: boolean;
  withDemand?: boolean;
  closedWeekDays?: number[];
  onSuccess?: (result: any) => void;
}

export default class CalendarEditor extends Component<IArgs> {
  @service calendarService!: CalendarService;
  @service appSettings!: AppSettings;
  @service authorization!: AuthorizationService;

  @service bvFlash!: BvFlashService;
  @service intl!: IntlService;

  @tracked calCenter = TODAY;
  @tracked selectedRange?: PowerCalendarDateRange = { start: TODAY, end: TODAY };

  @tracked importedCalendar?: string;

  minDate = moment().subtract(1, 'year').toDate();

  get calYear(): number {
    return moment(this.calCenter).year();
  }

  get calendarConfig() {
    return CALENDAR_CONFIG_BY_RESOURCENAME[this.args.resourceName.singular];
  }

  get notAllowed() {
    const { resourceName } = this.args;
    return this.authorization.cannot(resourceName.plural, this.calendarConfig.permission);
  }

  get calendarDays() {
    return (
      (this.calendarService.cache[this.importedCalendar ?? this.args.entityId] ?? {})[
        this.calYear
      ] ?? []
    );
  }

  get weekendDays() {
    if (this.args.resourceName.singular === WarehouseCalendarDayResourceName.singular) {
      return this.appSettings.byWarehouseOrDefault(this.args.entityId)?.settings.weekendDays;
    }
    return this.args.closedWeekDays;
  }

  get indexedCalendarDays(): Record<string, IIndexedCalendarDay> {
    if (!this.calendarDays?.length) {
      return {};
    }

    return indexBy<IIndexedCalendarDay>(
      this.calendarDays.map(
        (calD) =>
          ({
            ...calD.toJSON(),
            date: CalendarEditor.formattedDate(calD.date)
          } as IIndexedCalendarDay)
      ),
      'date'
    );
  }

  get showWeekendTag() {
    const { selectedRange } = this;

    if (selectedRange) {
      const { start, end } = selectedRange;

      if (start && !end) {
        return this.isAWeekendDay(start);
      }

      if (start && end) {
        const days: boolean[] = [];
        this.whileBrowsingSelectedDays((day) => {
          days.push(this.isAWeekendDay(day));
        });
        return days.every((day) => Boolean(day));
      }
    }

    return this.isAWeekendDay(this.calCenter);
  }

  get selectedRangeTitle() {
    if (!this.selectedRange) {
      return moment().format('L');
    } else {
      let title = '';
      if (this.selectedRange.start) {
        title += moment(this.selectedRange.start).format('L');
      }
      if (this.selectedRange.end) {
        title += ' — ' + moment(this.selectedRange.end).format('L');
      }
      return title;
    }
  }

  get calendarOpeningStates() {
    const { selectedRange, indexedCalendarDays } = this;

    if (!selectedRange) {
      const calendarDay = indexedCalendarDays[CalendarEditor.formattedDate()];
      return calendarDay || this.defaultState(new Date());
    }
    const { start, end } = selectedRange;

    if (start && !end) {
      const calendarDay = indexedCalendarDays[CalendarEditor.formattedDate(start)];
      return calendarDay || this.defaultState(start);
    }
    if (start && end) {
      const state: { openForDemand: boolean[]; openForReception: boolean[] } = {
        openForDemand: [],
        openForReception: []
      };

      this.whileBrowsingSelectedDays((day) => {
        const calendarDay = indexedCalendarDays[CalendarEditor.formattedDate(day)];
        Object.keys(state).forEach((key: keyof typeof state) => {
          calendarDay
            ? state[key].push(calendarDay[key])
            : state[key].push(!this.isAWeekendDay(day));
        });
      });

      const isOpened = (key: keyof typeof state) =>
        state[key].every((d) => d) || (state[key].every((d) => !d) ? false : 'indeterminate');

      return {
        openForDemand: isOpened('openForDemand'),
        openForReception: isOpened('openForReception')
      };
    }
    return this.defaultState(new Date());
  }

  @action
  onCalendarSelect(days: {
    date: PowerCalendarDateRange;
    moment: PowerCalendarDateRange<moment.Moment>;
  }) {
    if (days.moment.start.isSame(days.moment.end, 'days')) {
      this.selectedRange = { start: days.date.start, end: null };
    } else {
      this.selectedRange = days.date;
    }
  }

  @action
  handleCenterChange(center: { date: Date }) {
    this.calCenter = center.date;
  }

  @action
  resetCenter() {
    this.calCenter = TODAY;
    this.selectedRange = { start: TODAY, end: TODAY };
  }

  private whileBrowsingSelectedDays(fn: (day: moment.Moment) => void) {
    const { start, end } = this.selectedRange ?? {};
    if (!start || !end) {
      return;
    }

    const day = moment(start);

    while (!day.isAfter(moment(end), 'days')) {
      fn(day);
      day.add(1, 'day');
    }
  }

  importCalendar = restartableTask(async (entityId: string) => {
    const { resourceName } = this.args;
    const entityIdKey = this.calendarConfig.entityId;
    try {
      this.importedCalendar = entityId;

      await this.calendarService.fetchCalendarDays.perform(resourceName, entityId, this.calYear);
      const currentCalDays = this.calendarDays;
      if (currentCalDays.length) {
        const payload = {
          [entityIdKey]: this.args.entityId,
          dates: currentCalDays.map((calDay) => CalendarEditor.formattedDate(calDay.date))
        } as TResetDayPayload;

        await this.resetCalendarDays.perform(payload);
      }

      const importedDays = this.calendarDays;
      if (!importedDays.length) {
        return;
      }
      const days = importedDays.reduce((acc, day) => {
        return { ...acc, ...day.indexedDay };
      }, {});

      await this.updateCalendar.perform({
        [entityIdKey]: this.args.entityId,
        days
      } as TUpdateDayPayload);
      this.bvFlash.success(this.intl.t('calendarEditor.copySuccess'));
    } catch (error) {
      defaultErrorHandler(error);
      this.bvFlash.error(this.intl.t('calendarEditor.copyError'));
    }
  });

  initCalendarDays = restartableTask(async () => {
    const { entityId, resourceName } = this.args;
    (await this.calendarService.fetchCalendarDays.perform(resourceName, entityId, this.calYear)) ??
      [];
  });

  handleOpeningChange = restartableTask(async (key: TOpeningKeys, checked: boolean) => {
    const { selectedRange } = this;
    this.unselectImportedCalendar();
    if (!selectedRange) {
      return await this.updateForADay.perform(undefined, key, checked);
    } else {
      const { start, end } = selectedRange;

      if (start && !end) {
        return await this.updateForADay.perform(start, key, checked);
      }

      if (start && end) {
        const { entityId } = this.calendarConfig;
        const payload: Partial<TUpdateDayPayload> = {
          [entityId]: this.args.entityId,
          days: {}
        };

        let updatedDays = {};
        this.whileBrowsingSelectedDays((day) => {
          const updatedDay = this.generatePayloadForADay(day, key, checked);
          updatedDays = { ...updatedDays, ...updatedDay };
        });
        payload.days = { ...updatedDays };
        await this.updateCalendar.perform(payload as TUpdateDayPayload);

        return this.calendarDays;
      }
    }

    return;
  });

  resetDays = restartableTask(async () => {
    const { selectedRange } = this;
    this.unselectImportedCalendar();

    if (!selectedRange) {
      return await this.removeADay.perform();
    }

    const { start, end } = selectedRange;

    if (start && !end) {
      return await this.removeADay.perform(start);
    }

    if (start && end) {
      const { entityId } = this.calendarConfig;

      const payload = {
        [entityId]: this.args.entityId,
        dates: [] as string[]
      } as TResetDayPayload;

      let daysToReset: string[] = [];
      this.whileBrowsingSelectedDays((day) => {
        daysToReset.push(CalendarEditor.formattedDate(day));
      });
      payload.dates = daysToReset;

      await this.resetCalendarDays.perform(payload);
      return this.calendarDays;
    }

    return;
  });

  updateForADay = restartableTask(
    async (day: TDate | undefined, key: TOpeningKeys, checked: boolean) => {
      this.unselectImportedCalendar();
      const { entityId } = this.calendarConfig;

      const payload = {
        [entityId]: this.args.entityId,
        days: {
          ...this.generatePayloadForADay(moment(day), key, checked)
        }
      } as TUpdateDayPayload;

      await this.updateCalendar.perform(payload);

      return this.calendarDays;
    }
  );

  updateCalendar = restartableTask(async (payload: TUpdateDayPayload) => {
    const { Model } = this.calendarConfig;

    try {
      const result = await Model.updateCalendar(payload as any);
      this.args.onSuccess?.(result);
      return result;
    } catch (e) {
      defaultErrorHandler(e);
      return;
    }
  });

  removeADay = restartableTask(async (day?: TDate) => {
    this.unselectImportedCalendar();

    const dayToRemove = this.calendarDays.find(
      (c) => CalendarEditor.formattedDate(c.date) === CalendarEditor.formattedDate(day)
    );
    if (dayToRemove) {
      await this.resetCalendarDay.perform(dayToRemove);
    }
  });

  resetCalendarDay = restartableTask(async (calendarDay: TCalendarDay) => {
    try {
      const result = await calendarDay.resetOne(undefined);
      this.args.onSuccess?.(result);
      return result;
    } catch (e) {
      defaultErrorHandler(e);
      return;
    }
  });

  resetCalendarDays = restartableTask(async (payload: TResetDayPayload) => {
    try {
      const { Model } = this.calendarConfig;
      const result = await Model.resetDays(payload);
      this.args.onSuccess?.(result);
      return result;
    } catch (e) {
      defaultErrorHandler(e);
      return;
    }
  });

  private defaultState(date: TDate) {
    return this.calendarService.buildDefaultState(date, this.weekendDays);
  }

  private generatePayloadForADay(
    day: moment.Moment | Date,
    key: TOpeningKeys,
    checked: boolean
  ): TEntityCalendarDay {
    const calendarDay = this.indexedCalendarDays[CalendarEditor.formattedDate(day)];
    const { payloadKeys } = this.calendarConfig;
    const payload: Partial<Record<TOpeningKeys, boolean>> = {};
    for (const payloadKey of payloadKeys) {
      payload[payloadKey] = calendarDay ? calendarDay[payloadKey] : !this.isAWeekendDay(day);
    }

    payload[key] = checked;

    return { [CalendarEditor.formattedDate(day)]: payload as Record<TOpeningKeys, boolean> };
  }

  private static formattedDate(date?: moment.Moment | Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  private unselectImportedCalendar() {
    this.importedCalendar = undefined;
  }

  // Template Helpers
  // ~~~~~~

  findCalendarDate = (date: Date) => {
    return this.indexedCalendarDays[CalendarEditor.formattedDate(date)] ?? this.defaultState(date);
  };

  isAWeekendDay = (date: TDate) => {
    return isWeekendDay(date, this.weekendDays);
  };
}
