import { helper } from '@ember/component/helper';

export function isTrue([bool]: [boolean] /* , hash*/) {
  if (`${bool}`.toLowerCase() === 'false') {
    return false;
  }
  return Boolean(bool);
}

export default helper(isTrue);
