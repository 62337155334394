import type ApplicationInstance from '@ember/application/instance';
import appInst from 'volta/utils/application';

/**
 * This instance initialiser is needed
 * to make the application instance available
 * by a simple import from 'volta/utils/application'
 *
 * @param appInstance
 */
export function initialize(appInstance: ApplicationInstance) {
  appInst.instance = appInstance;
}

export default {
  name: 'volta',
  initialize
};
