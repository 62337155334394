import { inject as service } from '@ember/service';
import DomainSelect from 'volta/components/domain-select';
import JsonApiInfinityModel, { decorateApiQuery } from 'volta/models/jsonapi-infinity-model';
import {
  eqFilter,
  inFilter,
  niFilter,
  lkFilter,
  stringListToArray
} from 'volta/utils/filters-utils';
import { SupplierResourceName } from 'volta/models/supplier';

export default DomainSelect.extend({
  infiniteScroll: true,
  idKey: 'id',
  skuId: undefined,

  async searchPromise(payload) {
    const { search, selected, excluded } = payload;
    const query = {
      sort: 'description,code'
    };
    let filter = {};

    if (search?.length) {
      filter.q = lkFilter(search);
    }

    if (selected?.length) {
      filter.selectedIds = inFilter(selected);
    } else if (excluded?.length) {
      filter.id = niFilter(excluded);
    }

    if (this.skuId?.length) {
      filter.skuId = eqFilter(`${this.skuId}`);
    }

    query.filter = filter;

    const decoratedQuery = this.beforeQuery?.(query);

    return await this.infinity.model(
      SupplierResourceName.singular,
      decorateApiQuery(decoratedQuery || query),
      JsonApiInfinityModel
    );
  }
});
