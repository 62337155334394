import { attr } from '@ember-data/model';
import BaseModel from 'volta/models/base-model';
import { TAcquisitionCode } from 'volta/models/constants/acquisition-codes';
import { TLeadTimeCategory } from 'volta/models/constants/lead-time-categories';
import { TVarCategory } from 'volta/models/constants/var-categories';
import { _normalize, collectionCommand, resourceCommand } from 'volta/utils/api';
import { TAduType } from './types/planning-settings';

export const ResourceName = {
  singular: 'aduProfile',
  plural: 'aduProfiles'
};

export const Commands = {
  CreateAduProfile: 'CreateAduProfile',
  UpdateAduProfile: 'UpdateAduProfile'
};
export default class AduProfile extends BaseModel {
  // Collection commands
  // ~~~~~

  static createAduProfile = collectionCommand(Commands.CreateAduProfile, {
    after: _normalize
  });

  // Properties
  // ~~~~~

  @attr('string') declare code: string;
  @attr('string') description?: string;
  @attr('number') aduOverride?: number;
  @attr('number') aduMin?: number;
  @attr('string', { defaultValue: 'ROLLING' }) declare aduType: TAduType;
  @attr('number') aduHorizon?: number;
  @attr('number') aduHorizonOffset?: number;
  @attr('boolean', { defaultValue: () => false }) declare discontinuedDemand: boolean;
  @attr('string') varCategory?: TVarCategory;
  @attr('string') leadTimeCategory?: TLeadTimeCategory;
  @attr('string') acqCode?: TAcquisitionCode;
  @attr('boolean', { defaultValue: false }) declare isDefault: boolean;
  @attr('array', {
    defaultValue: () => []
  })
  declare tags: string[];

  // Computed Properties
  // ~~~~~

  get aduProfileId() {
    return this.id;
  }

  // Commands
  // ~~~~~

  updateAduProfile = resourceCommand('UpdateAduProfile');
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'adu-profile': AduProfile;
  }
}
