import IntlService from 'ember-intl/services/intl';

import { classify as EmberClassify } from '@ember/string';
import { isPresent } from '@ember/utils';
import RouterService from '@ember/routing/router-service';
import RouteInfo from '@ember/routing/route-info';

/**
 * This function transforms a given string by making the characters upper case
 * @param {string} text The text to transform
 * @return The uppercased string if the string exists otherwise null
 */
export function upper(text?: string): string | null {
  return isPresent(text) ? `${text}`.toUpperCase() : null;
}

export function lower(text?: string): string | null {
  return isPresent(text) ? `${text}`.toLowerCase() : null;
}

export function abbreviateNumber(
  num: number | null,
  fixed: number,
  formatFn?: (d: string | number) => any
) {
  if (num === null) {
    return null;
  } // terminate early
  if (num === 0) {
    return '0';
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  const b = num.toPrecision(2).split('e'); // get power
  const k = b.length === 1 ? 0 : Math.floor(Math.min(+b[1].slice(1), 14) / 3); // floor at decimals, ceiling at trillions
  const c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed); // divide by power
  const d = +c < 0 ? c : Math.abs(+c); // enforce -0 is 0
  const e = (typeof formatFn === 'function' ? formatFn(d) : d) + ['', 'K', 'M', 'B', 'T'][k]; // append power
  return e;
}

export const makeNumberFormatter = (
  format: 'shortScale' | 'percentage' | undefined,
  intl: IntlService
) => {
  const options: Intl.NumberFormatOptions | undefined =
    format === 'shortScale'
      ? {
          notation: 'compact',
          compactDisplay: 'short'
        }
      : format === 'percentage'
      ? { style: 'percent' }
      : undefined;
  return (value: number) => intl.formatNumber(value, options);
};

export function classify(word: string) {
  return EmberClassify(word);
}

/**
 * Javascript implementation of Java's String.hashCode() method
 * Source : https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 * @param str input string
 * @returns Hash code 32bit integer
 */
export function hashCode(str: string): number {
  let hash: number = 0;
  let chr: number;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    /* tslint:disable:no-bitwise */
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
    /* tslint:enable:no-bitwise */
  }
  return hash;
}

/**
 * Removes every accent of a word
 */
export function strNormalizeAccent(str: string) {
  return str.normalize('NFD').replace(/\p{Diacritic}/gu, '');
}

export const getCurrencySymbol = (locale: string, currency: string) =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
    .replace(/\d/g, '')
    .trim();

export function userName(firstName?: string, lastName?: string, login?: string) {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return login;
}

export const tryParseRouteInfo = (url: URL, router: RouterService) => {
  try {
    const routeInfo = router.recognize(url.pathname + url.search);
    return routeInfo.name === 'not-found' ? undefined : (routeInfo as RouteInfo);
  } catch {
    return undefined;
  }
};

export const tryParseURL = (url: string) => {
  try {
    return new URL(url);
  } catch {
    return undefined;
  }
};

// https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
export const decodeBase64 = (base64: string): string => {
  const binString = atob(base64);
  // @ts-ignore
  const bytes = Uint8Array.from(binString, (m) => m.codePointAt(0));
  return new TextDecoder().decode(bytes);
};

export const splitToLines = (text: string) => {
  return text.split(/[\r\n]+/);
};
