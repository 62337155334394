import {
  validateInclusion,
  validateNumber,
  validatePresence
} from 'ember-changeset-validations/validators';
import { ADU_TYPES } from 'volta/models/constants/adu';
import PlanningMethodologies from 'volta/models/constants/planning-methodologies';
import validateOnCheck from 'volta/validators/on-check';

import { isPresent } from '@ember/utils';

export default {
  settings: {
    methodology: validateInclusion({
      list: Object.keys(PlanningMethodologies),
      allowBlank: true
    }),
    leadTimeFactor: validateNumber({ positive: true, allowBlank: true }),
    varFactor: validateNumber({ positive: true, allowBlank: true }),
    aduType: validateInclusion({ list: Object.keys(ADU_TYPES) }),
    aduHorizon: validateNumber({ positive: true, allowBlank: true }),
    aduHorizonOffset: validateNumber({ positive: true, allowBlank: true }),
    aduMin: validateNumber({ positive: true, allowBlank: true }),
    aduOverride: [
      validateNumber({ positive: true, allowBlank: true }),
      validateOnCheck({
        checkKeys: ['settings.aduType', 'settings.methodology'],
        checkCondition: (aduOverride: number, aduType: string, methodology: string) =>
          methodology === 'BUFFER' && aduType === 'FIXED' ? isPresent(aduOverride) : true,
        i18nKey: 'aduOverride'
      })
    ],
    discontinuedDemand: validatePresence(true),
    orderCycle: validateNumber({ positive: true, allowBlank: true }),
    orderSpikeHorizon: validateNumber({ positive: true, allowBlank: true }),
    orderSpikeThreshold: validateNumber({
      positive: true,
      allowBlank: true
    }),
    orderSpikeLtHorizon: validateNumber({ positive: true, allowBlank: true }),
    orderSpikeLtThreshold: validateNumber({ positive: true, allowBlank: true }),
    demandHorizon: validateNumber({ positive: true, allowBlank: true })
  }
};
