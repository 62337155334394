import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import DomainSelect from 'volta/components/domain-select';
import JsonApiInfinityModel, { decorateApiQuery } from 'volta/models/jsonapi-infinity-model';
import { anyFilter, niFilter, inFilter, lkFilter } from 'volta/utils/filters-utils';
import { TagResourceName } from 'volta/models/tag';

/**
 * @class TagSelect
 * @extends DomainSelect
 */
export default DomainSelect.extend({
  infiniteScroll: true,
  idKey: 'id',
  placeholder: computed('intl.locale', function () {
    return this.intl.t('addATag');
  }),

  /**
   * Here we override the `searchPromise` function to enabled infinite loading
   * and custom filters for entityTypes and warehouseIds.
   * Selected ids filter is also made JSONAPI compatible
   *
   * @param {{search: string, selected: string}} payload Initial payload built by DomainSelect
   * @returns {InfinityModel}
   */
  async searchPromise(payload) {
    const { search, excluded } = payload;

    const query = {
      sort: 'tag'
    };
    const filter = {};

    if (search?.length) {
      filter.tag = lkFilter(search);
    }
    if (excluded && excluded.length) {
      // This case should happen only when selected is empty
      filter.excludedIds = niFilter(excluded);
    }
    if (this.entityTypes?.length) {
      filter.entityTypes = anyFilter(this.entityTypes);
    }

    if (this.warehouseIds?.length) {
      filter.warehouseIds = anyFilter(this.warehouseIds);
    }
    if (this.values?.length) {
      filter.selectedIds = inFilter(this.values);
    }
    query.filter = filter;

    const decoratedQuery = this.beforeQuery?.(query);

    return await this.infinity.model(
      TagResourceName.singular,
      decorateApiQuery(decoratedQuery || query),
      JsonApiInfinityModel
    );
  }
});
