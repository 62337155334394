import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { ref } from 'ember-ref-bucket';

interface IArgs {
  id?: string;
  name?: string;
  value?: string;
  required?: boolean;
  checked?: boolean | 'indeterminate';
  disabled?: boolean;
  onChange?: (checked: boolean, event: PointerEvent) => void;
}

export default class BvCheckbox extends Component<IArgs> {
  /**
   * The input element.
   */
  @ref('inputRef') inputRef?: HTMLInputElement;

  /**
   * The id of the checkbox.
   */
  get id() {
    return this.args.id ?? `${guidFor(this)}BvCheckbox`;
  }

  /**
   * Whether the checkbox is in an indeterminate state.
   */
  get isIndeterminate() {
    const { checked = false } = this.args;
    return checked === 'indeterminate';
  }

  /**
   * Whether the checkbox is checked.
   */
  get isChecked() {
    const { checked } = this.args;
    return !this.isIndeterminate && Boolean(checked);
  }

  @action
  handleOnClick(e: PointerEvent) {
    const { onChange, disabled } = this.args;
    const { isChecked, isIndeterminate } = this;

    if (disabled || !this.inputRef || !onChange) {
      return;
    }
    // Checked and indeterminate state have been changed, but that's not DDAU!
    // Reset the change, send the action and wait for it to be changed manually
    const checked = this.inputRef.checked;
    this.inputRef.checked = isChecked;
    this.inputRef.indeterminate = isIndeterminate;

    onChange(checked, e);

    this.inputRef.focus();
  }
}
