import { ref } from 'ember-ref-bucket';
import { normalizeAutoComplete } from 'volta/utils/dom-utils';
import { parseNumber, round } from 'volta/utils/math-utils';

import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { next } from '@ember/runloop';
import { htmlSafe } from '@ember/template';
import { isPresent, typeOf } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

/**
 * Returns the length of decimal places in a number
 *
 * @param {number} num The number to test
 * @returns {number} The length of decimal places in a number
 */
const dpl = (num: number) => (num.toString().split('.')[1] || []).length;

type TInputType =
  | 'text'
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'url'
  | 'date'
  | 'datetime-local'
  | 'month'
  | 'time'
  | 'week'
  | 'currency'
  | 'percent';

/**
 * BV text-field component.
 */
interface IArgs {
  /**
   * ID for the input
   */
  id?: string;

  /**
   * Text to display before value
   */
  prefix?: string | Component;

  /**
   * Text to display after value
   */
  suffix?: string | Component;

  /**
   * Hint text to display
   */
  placeholder?: string;

  /**
   * Initial value for the input
   */
  value?: string | number;

  /**
   * Additional hint text to display
   */
  helpText?: string | Component;

  /**
   * Label for the input
   */
  label?: string;

  /**
   * Adds an action to the label
   *
   * Currently supports:
   * { onClick, text, accessibilityLabel }
   */
  labelAction?: { onClick: () => void; text: string; accessibilityLabel?: string };

  /**
   * Visually hide the label
   */
  labelHidden: boolean;

  /**
   * Disable the input
   */
  disabled: boolean;

  /**
   * Disable editing of the input
   */
  readOnly: boolean;

  /**
   * Automatically focus the input
   */
  autoFocus: boolean;

  /**
   * Force the focus state on the input
   */
  focused: boolean;

  /**
   * Allow for multiple lines of input
   */
  multiline: boolean | number;

  /**
   * Error to display beneath the label
   */
  error?: string | Component | boolean | Array<string | Component>;

  /**
   * An element connected to the right of the input
   */
  connectedRight?: string | Component;

  /**
   * An element connected to the left of the input
   */
  connectedLeft?: string | Component;

  /**
   * Determine type of input
   *
   * @default text
   */
  type: TInputType;

  /**
   * Name of the input
   */
  name?: string;

  /**
   * Defines a specific role attribute for the input
   */
  role?: string;

  /**
   * Limit increment value for numeric and date-time inputs
   */
  step?: number;

  /**
   * Enable automatic completion by the browser
   */
  autoComplete?: boolean;

  /**
   * Mimics the behavior of the native HTML attribute,
   * limiting how high the spinner can increment the value
   */
  max?: number;

  /**
   * Maximum character length for an input
   */
  maxLength?: number;

  /**
   * Mimics the behavior of the native HTML attribute,
   * limiting how low the spinner can decrement the value
   */
  min?: number;

  /**
   * Minimum character length for an input
   */
  minLength?: number;

  /**
   * A regular expression to check the value against
   */
  pattern?: string;

  /**
   * Indicate whether value should have spelling checked
   */
  spellCheck?: boolean;

  /**
   * Indicates the id of a component owned by the input
   */
  ariaOwns?: string;

  /**
   * Indicates the id of a component controlled by the input
   */
  ariaControls?: string;

  /**
   * Indicates the id of a related component's visually focused element ot the input
   */
  ariaActiveDescendant?: string;

  /**
   * Indicates what kind of user input completion suggestions are provided
   */
  ariaAutocomplete?: string;

  height?: number;

  /**
   * Indicates whether we should return the value as a number
   */
  parseToFloat?: boolean;

  /**
   * Callback when value is changed
   */
  onChange?: (value: string | number, id: string, event?: InputEvent) => void;

  /**
   * Callback when key is pressed
   */
  onKeyPress?: (event: KeyboardEvent) => void;

  /**
   * Callback when Enter is pressed
   */
  onEnter?: (value: string, id: string, event: KeyboardEvent) => void;

  /**
   * Callback when Escape key is triggered
   */
  onEscape?: (event: KeyboardEvent, value: string, id: string) => void;

  /**
   * Callback when input is focused
   */
  onFocus?: (e: InputEvent) => void;

  /**
   * Callback when focus is removed
   */
  onBlur?: (e: InputEvent) => void;

  onClearButtonClick?: (id: string, event: MouseEvent) => void;
}

export default class BvTextField extends Component<IArgs> {
  @tracked value?: number | string;
  @tracked height = this.args.height;
  @tracked focus = this.args.focused;

  dataTestTextField = 'bv-text-field';
  id: string = this.args.id ?? `BvTextField-${guidFor(this)}`;
  @ref('input') input?: HTMLElement;

  constructor(owner: any, args: IArgs) {
    super(owner, args);
    this.initValue();
  }

  initValue() {
    const { type, percentRatio, inputType } = this;
    const { value } = this.args;
    const newRawInputValue = isPresent(value)
      ? `${type === 'percent' ? round((parseNumber(value) as number) / percentRatio, 6) : value}`
      : '';
    const hasRawValueChanged =
      inputType === 'number'
        ? parseNumber(this.value) !== parseNumber(newRawInputValue)
        : this.value !== newRawInputValue;

    if (hasRawValueChanged) {
      next(() => {
        this.value = newRawInputValue;
      });
    }
  }

  get type() {
    return this.args.type ?? 'text';
  }

  /**
   * Value as string or empty string
   */
  get normalizedValue() {
    return isPresent(this.value) ? `${this.value}` : '';
  }

  get ariaInvalid() {
    return Boolean(this.args.error);
  }

  get autoCompleteInputs() {
    return normalizeAutoComplete(this.args.autoComplete);
  }

  get ariaDescribedBy() {
    const { error, helpText } = this.args;
    const { id } = this;
    const describedBy = [];

    if (error) {
      describedBy.push(`${id}Error`);
    }

    if (helpText) {
      describedBy.push(`${id}HelpText`);
    }

    return describedBy.join(' ');
  }

  get ariaLabelledBy() {
    const { prefix, suffix } = this.args;
    const { id } = this;
    const labelledBy = [`${id}Label`];

    if (prefix) {
      labelledBy.push(`${id}Prefix`);
    }

    if (suffix) {
      labelledBy.push(`${id}Suffix`);
    }

    return labelledBy.join(' ');
  }

  get inputType() {
    const { type } = this;
    switch (type) {
      case 'currency':
        return 'text';
      case 'percent':
        return 'number';
      default:
        return type;
    }
  }

  get minimumLines() {
    const { multiline } = this.args;
    return typeOf(multiline) === 'number' ? multiline : 1;
  }

  get heightStyle() {
    return this.height && this.args.multiline ? htmlSafe(`height: ${this.height}px`) : null;
  }

  get shouldShowSpinner() {
    return this.inputType === 'number' && !this.args.disabled && !this.args.readOnly;
  }

  get percentRatio() {
    return this.type === 'percent' ? 0.01 : 1;
  }

  checkFocus() {
    // To avoid focus and conflicts when rendering
    if (this.input && this.args.focused) {
      this.focus = true;
      next(() => {
        this.input?.focus();
      });
    }
  }

  @action
  handleFocusChange(/*element, [focused]*/) {
    this.checkFocus();
  }

  @action
  handleValueChange() {
    this.initValue();
  }

  @action
  handleDidInsert(/*element*/) {
    this.checkFocus();
  }

  @action
  handleNumberChange(steps: number) {
    let { step, min, max } = this.args;
    const { parseToFloat = true } = this.args;

    step = step ?? 1;
    min = (isPresent(min) ? (min as number) / this.percentRatio : -Infinity) as number;
    max = (isPresent(max) ? (max as number) / this.percentRatio : Infinity) as number;

    const numericValue = this.value ? parseNumber(this.value) : min;

    if (typeof numericValue === 'undefined') {
      return;
    }

    // Making sure the new value has the same length of decimal places as the
    // step / value has.
    const infinityAdjusted = numericValue + steps * step;
    const decimalPlaces = Math.max(dpl(numericValue), dpl(step));
    const newValue = Math.min(
      max,
      Math.max(infinityAdjusted === -Infinity ? steps * step : infinityAdjusted, min)
    );
    this.value = newValue.toFixed(decimalPlaces);

    this.args.onChange?.(
      parseToFloat
        ? newValue * this.percentRatio
        : String((newValue * this.percentRatio).toFixed(decimalPlaces)),
      this.id
    );
  }

  @action
  handleExpandingResize(height: number) {
    next(this, () => {
      this.height = height;
    });
  }

  @action
  handleChange(event?: InputEvent) {
    if (!event) {
      return;
    }
    const { parseToFloat = true } = this.args;
    const { value, valueAsNumber } = event.currentTarget as HTMLInputElement;

    this.value = value;

    this.args.onChange?.(
      parseToFloat && this.inputType === 'number'
        ? (round(valueAsNumber * this.percentRatio, 6) as number)
        : value,
      this.id,
      event
    );
  }

  @action
  handleInputFocus(event: InputEvent) {
    if (this.inputType === 'number') {
      this.input?.addEventListener('wheel', (e: WheelEvent) => {
        e.preventDefault();
      });
    }
    this.args.onFocus?.(event);
  }

  @action
  handleInputBlur(event: InputEvent) {
    if (this.inputType === 'number') {
      this.input?.removeEventListener('wheel', (e: WheelEvent) => {
        e.preventDefault();
      });
    }
    this.args.onBlur?.(event);
  }

  @action
  handleFocus() {
    this.focus = true;
  }

  @action
  handleBlur() {
    this.focus = false;
  }

  @action
  handleClick() {
    this.input?.focus();
  }

  @action
  handleEscape(event: KeyboardEvent) {
    const { target } = event;

    return this.args.onEscape?.(event, (target as HTMLInputElement).value, this.id);
  }

  @action
  handleEnter(event: KeyboardEvent) {
    const { onEnter, multiline } = this.args;

    if (onEnter) {
      event.preventDefault();
      if (multiline && event.shiftKey) {
        return;
      }
    } else if (multiline) {
      return;
    }

    return this.args.onEnter?.((event.target as HTMLInputElement).value, this.id, event);
  }

  @action
  handleClearButtonPress(event: MouseEvent) {
    if (this.args.disabled) {
      return;
    }
    this.args.onClearButtonClick?.(this.id, event);
  }
}
