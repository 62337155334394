import type LocalizationService from 'volta/services/localization';
import type IntlService from 'ember-intl/services/intl';
import { restartableTask, timeout } from 'ember-concurrency';
import { TYPING_DEBOUNCE_MS } from 'volta/components/bv-app-search';
import { IResourceName } from 'volta/models/base-model';
import { parseCFApiParamKey } from 'volta/models/custom-field-definition';
import { scalarEquals } from 'volta/utils/array-utils';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { cached, tracked } from '@glimmer/tracking';

import { TComparatorOption } from '../comparator-select';

interface IBaseFilterArgs {
  resourceName: IResourceName;
  initialValue: unknown;
  definition: TFilterDefinition<object>;
  onChange: (filterValue: TFilterValue, queryParam: string) => void;
  clearDefaultValue?: string[];
}

export default class BaseFilter extends Component<IBaseFilterArgs> {
  /**
   * Services
   */

  @service intl!: IntlService;
  @service localization!: LocalizationService;

  /**
   * Tracked properties
   */

  @tracked enabled: boolean = false;
  @tracked values: string[] = [];
  @tracked comparator?: TComparator;

  /**
   * Getters
   */

  get hasSingleComparator() {
    return this.args.definition.comparators.length < 2;
  }

  get isMultipleComparator() {
    return this.checkIsMultipleComparator(this.comparator);
  }

  get firstComparator() {
    const { comparators } = this.args.definition;
    return comparators.length ? comparators[0] : undefined;
  }

  @cached
  get title() {
    const {
      definition: { isCustomField = false, queryParam, title }
    } = this.args;
    const labelKey = (isCustomField && parseCFApiParamKey(queryParam)) || queryParam;
    return isCustomField
      ? this.localization.tOrElse(labelKey, title)
      : title ??
          this.localization.tOrElse(
            queryParam && 'filterDefinitions.' + queryParam,
            this.intl.t(queryParam)
          );
  }

  /**
   * Actions
   */

  @action
  handleInitialValueChange(_element: HTMLElement, [initialValue]: [TFilterValue]) {
    this.initValues(initialValue);
  }

  valuesDidChange = restartableTask(async (values?: string[]) => {
    this.enableClear(values);
    this.values = values ?? [];
    const filterValue = this.buildFilterValue();

    if (filterValue) {
      await timeout(TYPING_DEBOUNCE_MS);
      this.args.onChange?.(filterValue, this.args.definition.queryParam);
    }
  });

  @action
  selectComparator(newComparator: TComparatorOption) {
    const oldComparator = this.comparator;

    if (oldComparator !== newComparator.key) {
      const wasMultiple = this.isMultipleComparator;
      const isMultiple = this.checkIsMultipleComparator(newComparator.key);

      // Only keep the first value if we're passing
      // from multiple to single value
      if (wasMultiple && !isMultiple) {
        this.values = this.values.slice(0, 1);
      }

      // Make sure bt filter has 2 values
      if (newComparator.key === 'bt' && this.values.length === 1) {
        this.values = [this.values[0], this.values[0]];
      }

      this.comparator = newComparator.key;
      const filterValue = this.buildFilterValue();
      if (filterValue) {
        this.args.onChange?.(filterValue, this.args.definition.queryParam);
      }
    }
  }

  @action
  initValues(initialValue?: TFilterValue) {
    this.enableClear(initialValue?.values);
    this.values = (initialValue?.values ?? []) as string[];
    this.comparator = initialValue?.comparator ?? this.comparator ?? this.firstComparator;
  }

  /**
   * Private utils
   */

  private checkIsMultipleComparator(comparator?: TComparator) {
    return comparator && ['in', 'ni', 'all', 'any', 'bt'].includes(comparator);
  }

  private buildFilterValue(): TFilterValue | undefined {
    const comparator = this.comparator ?? this.firstComparator;
    return comparator
      ? {
          comparator,
          values: this.values,
          enabled: this.enabled
        }
      : undefined;
  }

  private enableClear(values?: string[]): void {
    const { clearDefaultValue } = this.args;
    if (clearDefaultValue) {
      this.enabled = !scalarEquals(values, clearDefaultValue);
    } else {
      this.enabled = values ? values.length > 0 : false;
    }
  }
}
