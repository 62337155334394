import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

const REPLACE_REGEX = /[\n&<>]/g;

const ENTITIES_TO_REPLACE: Record<string, string> = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '\n': '<br>'
};

function replaceEntity(entity: string) {
  return ENTITIES_TO_REPLACE[entity] ?? entity;
}

interface IArgs {
  /**
   * The value of the textarea
   *
   * @property contents
   * @public
   * @type {String}
   * @default null
   */
  contents: string;
  /**
   * The height (in px) of the textarea
   *
   * @property currentHeight
   * @public
   * @type {Number}
   * @default null
   */
  currentHeight: number;
  /**
   * The multiline value of the textarea if
   * a numeric value was passed-in to the polaris-text-field
   *
   * @property minimumLines
   * @public
   * @type {Number}
   * @default null
   */
  minimumLines: number;
  /**
   * Callback when the height of the resize container changes
   *
   * @property onHeightChange
   * @public
   * @type {Function}
   * @default noop
   */
  onHeightChange: (height: number) => void;
}

export default class BvTextFieldResizer extends Component<IArgs> {
  'data-test-text-field-resizer' = true;

  get finalContents() {
    let { contents } = this.args;

    contents = contents ? `${`${contents}`.replace(REPLACE_REGEX, replaceEntity)}<br>` : '<br>';

    return htmlSafe(contents);
  }

  get contentsForMinimumLines() {
    let { minimumLines } = this.args;
    let content = '';

    for (let line = 0; line < minimumLines; line++) {
      content = `${content}<br>`;
    }

    return htmlSafe(content);
  }
  handleResize = (resizeObserverEntry: ResizeObserverEntry) => {
    this.handleHeightCheck(resizeObserverEntry.target);
  };

  handleHeightCheck = (element: Element) => {
    const [contentNode, minimumLinesNode] = element.querySelectorAll('.bv-text-field__dummy-input');

    if (!contentNode || !minimumLinesNode) {
      return;
    }

    const contentHeight = (contentNode as HTMLElement).offsetHeight;
    const minimumHeight = (minimumLinesNode as HTMLElement).offsetHeight;
    const newHeight = Math.max(contentHeight, minimumHeight);
    const { currentHeight, onHeightChange } = this.args;

    if (newHeight !== currentHeight) {
      onHeightChange?.(newHeight);
    }
  };
}
