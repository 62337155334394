import AppSerializer from 'volta/_pods/application/serializer';
import PlanningSettings from 'volta/models/planning-setting';
import { get } from '@ember/object';

export default AppSerializer.extend({
  normalize(typeClass, hash) {
    const planningSettings = get(hash, 'attributes.planningSettings');

    if (planningSettings) {
      hash.attributes.planningSettings = PlanningSettings.create(planningSettings);
    }

    return this._super(typeClass, hash);
  }
});
