import moment from 'moment-timezone';
import { dateCriticality } from 'volta/utils/style-utils';

import { attr, belongsTo, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';

import BaseModel, { IResourceName } from './base-model';
import { POResourceName as PoResourceName } from './planning-order';
import { SkuResourceName } from './sku';

import type {
  IAggTasksView,
  IAggTasksViewMap,
  ITask,
  TAssignTo,
  TTaskResolveIf,
  TTaskType,
  UTask
} from './types/tasks';
import type UserProfile from './user-profile';

export const TASK_TYPES: TTaskType[] = [
  'COMMENT',
  'SYNCHRONISATION',
  'SCHEDULING',
  'PLANNING_TASK',
  'EXECUTION_TASK',
  'PO_TASK',
  'SKU_TASK'
];

export const SKU_TASK_TYPES: TTaskType[] = ['SKU_TASK', 'EXECUTION_TASK', 'PLANNING_TASK'];

export enum TaskCommands {
  CreateTask = 'CreateTask',
  UpdateTask = 'UpdateTask',
  DeleteTask = 'DeleteTask',
  ResolveTask = 'ResolveTask',
  ReopenTask = 'ReopenTask'
}

export enum TaskEvents {
  TaskCreated = 'TaskCreated',
  TaskUpdated = 'TaskUpdated',
  TaskDeleted = 'TaskDeleted',
  TaskResolved = 'TaskResolved',
  TaskReopened = 'TaskReopened'
}

export const TaskListChangeEvents = [
  TaskEvents.TaskUpdated,
  TaskEvents.TaskResolved,
  TaskEvents.TaskReopened,
  TaskEvents.TaskCreated,
  TaskEvents.TaskDeleted
];

export const TaskResourceName: IResourceName = {
  singular: 'task',
  plural: 'tasks'
};

export const SkuTaskResourceName: IResourceName = {
  singular: 'skuTask',
  plural: 'skuTasks'
};

export const PoTaskResourceName: IResourceName = {
  singular: 'poTask',
  plural: 'poTasks'
};

export function TaskResourceNameFromParentRecordResourceName(parentResourceName?: IResourceName) {
  if (!parentResourceName) {
    return undefined;
  }
  if (parentResourceName.singular === PoResourceName.singular) {
    return PoTaskResourceName;
  }
  if (parentResourceName.singular === SkuResourceName.singular) {
    return SkuTaskResourceName;
  }
  return undefined;
}

export function taskToCommand(task: Task): Partial<ITask> {
  const cmd: Partial<ITask> = {
    id: task.id,
    taskType: task.taskType,
    parentId: task.parentId,
    dueDate: task.dueDate ? moment(task.dueDate).format('YYYY-MM-DD') : task.dueDate,
    comment: task.comment,
    payload: task.payload,
    assigneeIds: task.assigneeIds,
    criticality: task.criticality,
    priority: task.priority,
    resolveIf: task.resolveIf,
    linkedEntityId: task.linkedEntityId,
    resolvedAt:
      task.resolvedAt === null
        ? null
        : task.resolvedAt
        ? moment(task.resolvedAt).toISOString()
        : undefined
  };

  if (!task.id) {
    cmd.createdById = task.createdBy?.id;
  } else {
    cmd.taskId = task.id;
  }
  return cmd;
}

const DefaultAggTasksView: IAggTasksView = {
  total: 0,
  due: 0,
  maxCriticality: 0,
  minDueDate: undefined
};

export const LabelsForAssignTo: Record<TAssignTo, { title: string; empty: string }> = {
  assignments: { title: 'tasksPage.assignTo.assignments', empty: 'tasksPage.noAssignments' },
  assignings: { title: 'tasksPage.assignTo.assignings', empty: 'tasksPage.noAssignedTasks' },
  unassigned: { title: 'tasksPage.assignTo.unassigned', empty: 'tasksPage.noUnassignedTasks' }
};

export function tasksToAgg(tasks: Task[]): IAggTasksViewMap {
  let grouped: IAggTasksViewMap = {};
  tasks.forEach((t) => {
    if (t) {
      const group = grouped[t.taskType] ?? DefaultAggTasksView;
      group.total++;
      if (t.dueDate) {
        group.due++;
      }
      if (t.criticality && (group.maxCriticality ?? -1 < t.criticality)) {
        group.maxCriticality = t.criticality;
      }
      if (t.dueDate && t.dueDate < (group.minDueDate ?? '9999-01-01')) {
        group.minDueDate = t.dueDate;
      }
    }
  });

  return grouped;
}

export function indexedEntities(tasks: UTask[]) {
  const ix: Record<string, unknown> = {};
  tasks.forEach((c) => {
    ix[c.linkedEntityId] = { ...c.linkedEntityPayload, linkedEntityType: c.linkedEntityType };
  });

  return ix;
}

export default class Task<T = Record<string, any>, E = Record<string, any>>
  extends BaseModel
  implements ITask<T, E>
{
  /**
   * Attributes
   */
  @attr('string') linkedEntityId!: string;
  @attr('string') linkedEntityType!: string;
  @attr() linkedEntityPayload!: E;
  @attr('string') taskType!: TTaskType;
  @attr('string') parentId?: string;
  @attr('string') resolveIf?: TTaskResolveIf;
  @attr('array') assigneeIds?: string[];
  @attr('string') dueDate?: string | null;
  @attr('date') resolvedAt?: Date | null;
  @attr('string') comment?: string;
  @attr('string') createdById!: string;
  @attr('string') archivedById?: string;
  @attr('string') updatedById?: string;
  @attr('string') resolvedById?: string;
  @attr() payload?: T;
  @attr('string') action?: string;
  @attr('number') priority?: number;
  @attr('number') criticality?: number;

  @hasMany('user-profile', { async: false }) assignees!: UserProfile[];
  @belongsTo('user-profile', { async: false }) createdBy?: UserProfile;
  @belongsTo('user-profile', { async: false }) updatedBy?: UserProfile;
  @belongsTo('user-profile', { async: false }) archivedBy?: UserProfile;
  @belongsTo('user-profile', { async: false }) resolvedBy?: UserProfile;

  /**
   * Tracked properties
   */
  @tracked showCommentAuthor: boolean = false;
  @tracked isFirstComment: boolean = false;

  /**
   * Getters
   */

  get isResolved() {
    return !!this.resolvedAt;
  }

  get dueDateAsDate() {
    return this.dueDate ? moment(this.dueDate).toDate() : undefined;
  }

  get newWithoutComment() {
    return !this.id && !this.comment;
  }

  get todoCriticality() {
    return dateCriticality(this.dueDate, !this.resolvedAt);
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    task: Task;
  }
}
