import moment from 'moment-timezone';
// @ts-ignore
import ActionCell from 'volta/components/cell/action-cell';
// @ts-ignore
import CodeDescCell from 'volta/components/cell/code-desc-cell';
// @ts-ignore
import LabelCell from 'volta/components/cell/label-cell';
// @ts-ignore
import PlanningPriorityCell from 'volta/components/cell/planning-priority-cell';
import TaskCell from 'volta/components/cell/task-cell';
import InputFilter from 'volta/components/filters/input-filter';
import SelectFilter from 'volta/components/filters/select-filter';
import SkusPartTypeFilter from 'volta/components/filters/skus-part-type-filter';
import SkusSupplierFilter from 'volta/components/filters/skus-supplier-filter';
import SkusWarehouseFilter from 'volta/components/filters/skus-warehouse-filter';
import SkusWorkshopFilter from 'volta/components/filters/skus-workshop-filter';
import TagsFilter from 'volta/components/filters/tags-filter';
import TokenFilter from 'volta/components/filters/token-filter';
import Config from 'volta/config/environment';
import { columnsToProps, columnsToSortOptions } from 'volta/models/collection-view';
import { AcquisitionCodesOptions } from 'volta/models/constants/acquisition-codes';
import { BufferZonesOptions } from 'volta/models/constants/buffer-zones';
import { PlanningMethodologiesManagedOptions } from 'volta/models/constants/planning-methodologies';
import { buildCustomFieldColumn, parseCFApiParamKey } from 'volta/models/custom-field-definition';
import { ResourceName } from 'volta/models/execution-alert';
import { SkuResourceName } from 'volta/models/sku';
import { emberTableCustomColumns, getCustomColumns } from 'volta/utils/column-utils';
import { beforeQueryWorkshop, clearFilter, stringListToArray } from 'volta/utils/filters-utils';

import Service, { service } from '@ember/service';
import { isPresent } from '@ember/utils';

import type {
  ICollectionDefinitionBuilder,
  ICollectionSortOption,
  ICollectionViewDefinition,
  ICreateCollectionViewCmd
} from 'volta/models/types/collection-view';
import type { IBvTableColumn } from 'volta/components/bv-table';
import type ExecutionAlertModel from 'volta/models/execution-alert';
import type AppSettings from 'volta/services/app-settings';
import type LabelService from 'volta/services/label-service';
import type SkuModalService from 'volta/services/sku-modal-service';
import type LocalizationService from 'volta/services/localization';
import type IntlService from 'ember-intl/services/intl';
import type StoreService from 'volta/services/store';
import type CustomFieldDefinitionsService from 'volta/services/custom-field-definitions-service';
import type SessionUserService from 'volta/services/session-user';
import type RouterService from '@ember/routing/router-service';

const CUSTOM_COLUMNS = Config.APP.customColumns.executionAlerts;

export default class AlertsCollectionDefinitionBuilder
  extends Service
  implements ICollectionDefinitionBuilder
{
  @service store!: StoreService;
  @service router!: RouterService;
  @service customFieldDefinitionsService!: CustomFieldDefinitionsService;
  @service intl!: IntlService;
  @service labelService!: LabelService;
  @service localization!: LocalizationService;

  @service skuModalService!: SkuModalService;
  @service appSettings!: AppSettings;
  @service sessionUser!: SessionUserService;

  buildColumns() {
    const { intl, skuModalService, labelService, appSettings, localization } = this;
    const defaultColumns: { [key: string]: IBvTableColumn } = {
      'sku.part': {
        columnName: intl.t('part'),
        sortKey: 'sku.partCode',
        valuePath: 'sku.part',
        minWidth: 25,
        width: 250,
        isResizable: true,
        isReorderable: true,
        cellComponent: CodeDescCell,
        format: (model: ExecutionAlertModel) => {
          return {
            link: 'protected.alerts.sku',
            linkParam: model.sku?.id,
            code: model.sku?.part.code,
            description: model.sku?.skuDescription
          };
        }
      },
      'sku.warehouse.code': {
        columnName: intl.t('warehouse'),
        sortKey: 'sku.warehouseCode',
        valuePath: 'sku.warehouse.code',
        minWidth: 25,
        width: 100,
        isResizable: true,
        isReorderable: true,
        cellComponent: LabelCell,
        format: (model: ExecutionAlertModel) => {
          return {
            label: model.sku.warehouse.code,
            color: 'green',
            iconRight: 'link-outline',
            tooltipText: model.sku.warehouse.description,
            onClick: () =>
              this.router.transitionTo('protected.warehouse', model.sku.warehouse.warehouseId)
          };
        }
      },
      'sku.partType': {
        columnName: intl.t('partType'),
        sortKey: 'sku.partType.code',
        valuePath: 'sku.partType.code',
        width: 100,
        minWidth: 50,
        isReorderable: true,
        isResizable: true,
        cellComponent: LabelCell,
        format: (order: ExecutionAlertModel) => {
          return {
            label: order.sku.partType?.code,
            tooltipText: order.sku.partType?.description
          };
        }
      },
      'sku.supplier': {
        columnName: intl.t('supplier'),
        sortKey: 'sku.supplierCode',
        valuePath: 'sku.supplier',
        width: 150,
        isResizable: true,
        isReorderable: true,
        cellComponent: LabelCell,
        format: ({ sku }: ExecutionAlertModel) => {
          return {
            label: sku.supplier?.code,
            color: 'primary',
            iconRight: 'link-outline',
            tooltipText: sku.supplier?.description,
            onClick: () =>
              sku.supplier &&
              this.router.transitionTo('protected.supplier', sku.supplier?.supplierId)
          };
        }
      },
      'sku.workshop': {
        columnName: intl.t('workshop'),
        sortKey: 'sku.workshopCode',
        valuePath: 'sku.workshop.code',
        width: 100,
        minWidth: 50,
        isReorderable: true,
        isResizable: true,
        disabled: true,
        cellComponent: LabelCell,
        format: ({ sku }: ExecutionAlertModel) => {
          return {
            label: sku.workshop?.code,
            color: 'severe',
            iconRight: 'link-outline',
            tooltipText: sku.workshop?.description,
            onClick: () =>
              sku.workshop &&
              this.router.transitionTo('protected.workshop', sku.workshop.workshopId)
          };
        }
      },
      'sku.deliveryWorkshop': {
        columnName: intl.t('deliveryWorkshop'),
        sortKey: 'sku.deliveryWorkshopCode',
        valuePath: 'sku.deliveryWorkshop.code',
        width: 100,
        minWidth: 50,
        isReorderable: true,
        isResizable: true,
        disabled: true,
        cellComponent: LabelCell,
        format: ({ sku }: ExecutionAlertModel) => {
          return {
            label: sku.deliveryWorkshop?.code,
            color: 'purple',
            iconRight: 'link-outline',
            tooltipText: sku.deliveryWorkshop?.description,
            onClick: () =>
              sku.deliveryWorkshop &&
              this.router.transitionTo('protected.workshop', sku.deliveryWorkshop.workshopId)
          };
        }
      },
      'reminder.dueDate': {
        columnName: intl.t('tasksPage.title'),
        valuePath: 'sku.tasks',
        sortKey: 'reminder.dueDate',
        width: 120,
        isReorderable: true,
        isResizable: true,
        isSortable: true,
        cellComponent: TaskCell,
        format: (model: ExecutionAlertModel) => {
          return {
            entityId: model.sku?.id,
            taskType: 'EXECUTION_TASK',
            resourceName: SkuResourceName,
            aggStats: model.tasks,
            showDueDate: true
          };
        }
      },
      priority: {
        columnName: intl.t('onHandAlert'),
        valuePath: 'priority',
        sortKey: 'priority',
        width: 130,
        isResizable: true,
        isReorderable: true,
        isSortable: true,
        cellComponent: PlanningPriorityCell,
        format: (model: ExecutionAlertModel) => {
          return {
            priorityName: model.priorityName,
            bufferPenetration: model.oha,
            route: 'protected.alerts.sku',
            hasDemandSpike: model.hasDemandSpike,
            routeModel: model.sku.id
          };
        }
      },
      onHand: {
        columnName: intl.t('onHand'),
        sortKey: 'onHand',
        valuePath: 'onHand',
        width: 85,
        textAlign: 'right',
        isResizable: true,
        isReorderable: true,
        cellComponent: ActionCell,
        format: (model: ExecutionAlertModel) => {
          return {
            model,
            context: this,
            visible: true,
            text: intl.formatNumber(model.onHand ?? 0),
            action: (model: ExecutionAlertModel) => {
              const { part } = model.sku ?? {};
              skuModalService.showWithSku('stock', model.sku ?? {}, {
                unitMeasure: part?.unitMeasure
              });
            },
            size: 'sm',
            color: 'subtle',
            classNames: 'u-text-mono'
          };
        }
      },
      demand: {
        columnName: intl.t('demandOverDLT'),
        sortKey: 'demand',
        valuePath: 'demand',
        width: 80,
        textAlign: 'right',
        isResizable: true,
        isReorderable: true,
        classNames: 'u-text-mono',
        format: (model: ExecutionAlertModel) => {
          return intl.formatNumber(model.demand ?? 0);
        }
      },
      openSupply: {
        columnName: intl.t('openSupplyOverDLT'),
        sortKey: 'openSupply',
        valuePath: 'openSupply',
        width: 100,
        textAlign: 'right',
        isResizable: true,
        isReorderable: true,
        classNames: 'u-text-mono',
        format: (model: ExecutionAlertModel) => {
          return intl.formatNumber(model.openSupply ?? 0);
        }
      },
      avgUsage: {
        columnName: intl.t('aduOverDLT'),
        sortKey: 'avgUsage',
        valuePath: 'avgUsage',
        width: 100,
        textAlign: 'right',
        isResizable: true,
        isReorderable: true,
        classNames: 'u-text-mono',
        format: (model: ExecutionAlertModel) => {
          return intl.formatNumber(model.avgUsage ?? 0);
        }
      },
      'sku.part.unitMeasure': {
        columnName: intl.t('unit'),
        sortKey: 'sku.unitMeasure',
        valuePath: 'sku.part.unitMeasure',
        width: 45,
        isResizable: true,
        isReorderable: true,
        format: (model: ExecutionAlertModel) => {
          return labelService.unitMeasure(model.sku?.part.unitMeasure);
        }
      },
      projectedPriority: {
        columnName: intl.t('projectedOnHandAlert'),
        sortKey: 'projectedPriority',
        valuePath: 'projectedPriority',
        width: 130,
        isResizable: true,
        isReorderable: true,
        cellComponent: PlanningPriorityCell,
        format: (model: ExecutionAlertModel) => {
          const name = model.projectedPriorityName;
          return {
            priorityName: name,
            bufferPenetration: model.projectedOha,
            route: 'protected.alerts.sku',
            hasDemandSpike: model.hasDemandSpike,
            routeModel: model.sku.id,
            hide: name === 'low' || name === 'otog'
          };
        }
      },
      projectedOhaDate: {
        columnName: intl.t('projectedOhaDate'),
        sortKey: 'projectedOnHandAlertDate',
        valuePath: 'projectedOhaDate',
        width: 80,
        isResizable: true,
        isReorderable: true,
        format: (model: ExecutionAlertModel) => {
          const priority = model.projectedPriorityName;
          if (priority === 'low' || priority === 'otog' || !model.projectedOhaDate) {
            return '';
          } else {
            const projectedOhaDate = moment.utc(model.projectedOhaDate);
            if (moment.utc().isSame(projectedOhaDate, 'day')) {
              return intl.t('today');
            } else {
              return projectedOhaDate.from(moment.utc().format('YYYY-MM-DD'));
            }
          }
        }
      },
      'sku.planner': {
        columnName: intl.t('planner'),
        valuePath: 'sku.planner',
        sortKey: 'sku.planner',
        width: 80,
        isResizable: true,
        isReorderable: true
      }
    };

    const columnsConfig = appSettings.features?.execution.columns ?? [];
    const columns: IBvTableColumn[] = [];
    columnsConfig.forEach((config) => {
      const column = config.key && defaultColumns[config.key];
      if (column && config.enabled) {
        if (config.title) {
          column.columnName = localization.tOrElse(config.title) as string;
        }
        columns.push(column);
      }
    });

    const appSettingsCustomColumns = appSettings.features?.execution.customColumns;
    let customCols = '';

    if (isPresent(appSettingsCustomColumns)) {
      customCols = appSettingsCustomColumns!;
    } else if (isPresent(CUSTOM_COLUMNS)) {
      customCols = CUSTOM_COLUMNS!;
    }

    if (customCols) {
      emberTableCustomColumns(getCustomColumns(customCols), intl).forEach((c: IBvTableColumn) =>
        columns.push(c)
      );
    }

    const customFieldProps: Array<IBvTableColumn | undefined> = (
      this.customFieldDefinitionsService.skusCustomProperties('sku') ?? []
    ).map((def) =>
      buildCustomFieldColumn(def, (model: ExecutionAlertModel) => {
        const dataType = def.dataType ? def.dataType.toLowerCase() : undefined;
        const { customFields } = model.sku;
        const key = parseCFApiParamKey(def.property);

        const value = customFields ? customFields[key] : undefined;
        return {
          value,
          dataType
        };
      })
    );

    return columns.concat(customFieldProps.filter(Boolean) as IBvTableColumn[]);
  }

  buildFilters(queryParamsValues: TQueryParams) {
    const { store, customFieldDefinitionsService } = this;
    const warehouseIds =
      typeof queryParamsValues.warehouse === 'string'
        ? stringListToArray(clearFilter(queryParamsValues.warehouse))
        : [];
    const normalDefs: Array<TFilterDefinition> = [
      {
        queryParam: 'partType',
        apiParam: 'sku.partTypeId',
        comparators: ['in', 'ni'],
        component: SkusPartTypeFilter,
        componentArgs: {}
      },
      {
        queryParam: 'warehouse',
        apiParam: 'sku.warehouseId',
        comparators: ['in', 'ni'],
        component: SkusWarehouseFilter,
        componentArgs: {}
      },
      {
        queryParam: 'q',
        apiParam: 'sku.q',
        comparators: ['lk'],
        hidden: true,
        component: InputFilter,
        componentArgs: { inputType: 'text' }
      },
      {
        queryParam: 'deliveryWorkshop',
        apiParam: 'sku.deliveryWorkshopId',
        comparators: ['in', 'ni'],
        component: SkusWorkshopFilter,
        componentArgs: {
          beforeQuery: beforeQueryWorkshop('DELIVERY', queryParamsValues['warehouse'])
        }
      },
      {
        queryParam: 'workshop',
        apiParam: 'sku.workshopId',
        comparators: ['in', 'ni'],
        component: SkusWorkshopFilter,
        componentArgs: {
          beforeQuery: beforeQueryWorkshop('WORKSHOP', queryParamsValues['warehouse'])
        }
      },
      {
        queryParam: 'supplier',
        apiParam: 'sku.supplierId',
        comparators: ['in', 'ni'],
        component: SkusSupplierFilter,
        componentArgs: {}
      },
      {
        queryParam: 'ohaZone',
        apiParam: 'ohaZone',
        comparators: ['in'],
        component: SelectFilter,
        componentArgs: { possibleValues: BufferZonesOptions }
      },
      {
        queryParam: 'projectedOhaZone',
        apiParam: 'projectedOhaZone',
        comparators: ['in'],
        component: SelectFilter,
        componentArgs: { possibleValues: BufferZonesOptions }
      },
      {
        queryParam: 'acqCode',
        apiParam: 'sku.acqCode',
        comparators: ['in'],
        component: SelectFilter,
        componentArgs: { possibleValues: AcquisitionCodesOptions }
      },
      {
        queryParam: 'methodology',
        apiParam: 'methodology',
        comparators: ['in'],
        component: SelectFilter,
        componentArgs: { possibleValues: PlanningMethodologiesManagedOptions }
      },
      {
        queryParam: 'tags',
        apiParam: 'sku.tags',
        comparators: ['any', 'all', 'ni'],
        component: TagsFilter,
        componentArgs: {
          entityTypes: ['parts', 'skus'],
          warehouseIds
        }
      },
      {
        queryParam: 'demand',
        apiParam: 'demand',
        comparators: ['lt', 'eq'],
        component: InputFilter,
        componentArgs: { inputType: 'number' }
      },
      {
        queryParam: 'planner',
        apiParam: 'sku.planner',
        comparators: ['in', 'ni'],
        component: TokenFilter,
        componentArgs: {
          warehouseIds,
          searchFn: (query: object) => {
            return store.customQuery('sku', 'planners', undefined, query);
          }
        }
      },
      {
        queryParam: 'buyer',
        apiParam: 'sku.buyer',
        comparators: ['in', 'ni'],
        component: TokenFilter,
        componentArgs: {
          warehouseIds,
          searchFn: (query: object) => {
            return store.customQuery('sku', 'buyers', undefined, query);
          }
        }
      }
    ];

    return normalDefs.concat(customFieldDefinitionsService.skusCustomFieldsFilters('sku'));
  }

  buildDefaultView(columns: IBvTableColumn[]): ICreateCollectionViewCmd {
    const { intl } = this;
    const properties = columnsToProps(columns).filter((c) => c.property !== 'sku.partType.code');
    return {
      name: intl.t('collectionViewsPage.default.name', {
        resourceNamePlural: intl.t(ResourceName.plural)
      }),
      description: intl.t('collectionViewsPage.default.description', {
        resourceNamePlural: intl.t(ResourceName.plural)
      }),
      collectionType: 'TABLE',
      entityType: ResourceName.plural,
      properties,
      createdBy: this.sessionUser.userId,
      sortBy: [{ property: 'priority', asc: false }]
    };
  }

  build(queryParams: TQueryParams, sorts: ICollectionSortOption[] = []) {
    const filters = this.buildFilters(queryParams);
    const columns = this.buildColumns();
    const defaultView = this.buildDefaultView(columns);
    const view: ICollectionViewDefinition = {
      resourceName: ResourceName,
      sortLimit: 0,
      sorts: columnsToSortOptions(columns).concat(sorts) as ICollectionSortOption[],
      properties: columnsToProps(columns),
      filters,
      definitions: columns,
      types: ['TABLE'],
      defaultView
    };

    return view;
  }
}

declare module '@ember/service' {
  interface Registry {
    'collection-builder-alerts': AlertsCollectionDefinitionBuilder;
  }
}
