import { ModelRegistry } from 'ember-data/model';
import IntlService from 'ember-intl/services/intl';
import { IBvTableColumn } from 'volta/components/bv-table';
import BaseModel, { IResourceName } from 'volta/models/base-model';
import SessionUserService from 'volta/services/session-user';
import { _normalize, collectionCommand, resourceCommand } from 'volta/utils/api';

import { attr, hasMany } from '@ember-data/model';
import { service } from '@ember/service';

import type UserProfile from './user-profile';
import type UserRole from './user-role';
import type {
  ICollectionArgs,
  ICollectionFilter,
  ICollectionProperty,
  ICollectionSort,
  ICollectionSortOption,
  ICreateCollectionViewCmd,
  TCollectionType
} from 'volta/models/types/collection-view';
import type { HookClass } from 'volta/utils/api/types';
import type {
  IShareCollectionViewCmd,
  IUpdateCollectionViewCmd
} from 'volta/models/types/collection-view';

export enum CollectionViewEvents {
  CollectionViewCreated = 'CollectionViewCreated',
  CollectionViewUpdated = 'CollectionViewUpdated',
  CollectionViewDeleted = 'CollectionViewDeleted',
  CollectionViewShared = 'CollectionViewShared'
}

export const CollectionViewResourceName: IResourceName = {
  singular: 'collectionView',
  plural: 'collectionViews'
};

export const Commands = {
  UpdateCollectionView: 'UpdateCollectionView',
  DeleteCollectionView: 'DeleteCollectionView',
  CreateCollectionView: 'CreateCollectionView',
  ShareCollectionView: 'ShareCollectionView'
};

export const columnsToProps = (
  columns: IBvTableColumn[],
  filterFn: (c: IBvTableColumn) => boolean = (c) => !c.isCustomField && !c.disabled
): ICollectionProperty[] => {
  return columns.reduce((acc, d) => {
    if (filterFn(d)) {
      acc.push({
        title: d.columnName,
        property: d.valuePath,
        isFixed: d.isFixed,
        isCustomField: d.isCustomField ?? false,
        isGroupable: d.isGroupable,
        isStatic: d.isStatic ?? false,
        color: d.color
      });
    }

    return acc;
  }, [] as ICollectionProperty[]);
};

export const collectionFiltersToFilterValues = (filters: ICollectionFilter[]): TFilterValues => {
  return filters.reduce((acc, f) => {
    acc[f.property] = {
      comparator: f.comparator,
      values: f.values,
      enabled: true
    };
    return acc;
  }, {} as TFilterValues);
};

export const columnsToSortOptions = (columns: IBvTableColumn[]): ICollectionSortOption[] => {
  return columns.reduce((acc, d) => {
    if (d.isSortable ?? true) {
      acc.push({
        label: d.columnName,
        value: d.sortKey ?? d.valuePath,
        origin: d.valuePath,
        color: d.color
      });
    }
    return acc;
  }, [] as ICollectionSortOption[]);
};

export const viewIsLocked = (view: CollectionView, sessionUserId: string) => {
  return (
    (view.showForRoles.length > 0 || view.showForUsers.length > 0) &&
    !view.isTemplate &&
    view.createdBy !== sessionUserId
  );
};

export default class CollectionView<
  TArgs extends ICollectionArgs = ICollectionArgs
> extends BaseModel {
  @service sessionUser!: SessionUserService;
  @service intl!: IntlService;

  static createCollectionView = collectionCommand<ICreateCollectionViewCmd, CollectionView>(
    Commands.CreateCollectionView,
    {
      after: _normalize as HookClass<any, CollectionView, typeof CollectionView>
    }
  );
  updateCollectionView = resourceCommand<IUpdateCollectionViewCmd, CollectionView>(
    Commands.UpdateCollectionView,
    { query: { include: 'showForRoles,showForUsers' } }
  );
  shareCollectionView = resourceCommand<IShareCollectionViewCmd, CollectionView>(
    Commands.ShareCollectionView,
    { query: { include: 'showForRoles,showForUsers' } }
  );
  deleteCollectionView = resourceCommand<any, CollectionView>(Commands.DeleteCollectionView);

  @attr('string')
  name!: string;

  @attr('string')
  description!: string;

  @attr('string')
  collectionType!: TCollectionType;

  @attr('number')
  pageSize?: number;

  @attr('string')
  entityType!: keyof ModelRegistry | string;

  @attr('string')
  createdBy!: string;

  @attr('string')
  copiedFrom?: string;

  @attr('boolean', { defaultValue: false })
  isTemplate?: boolean;

  @attr('array')
  properties?: ICollectionProperty[];

  @attr('array', { defaultValue: () => [] })
  filters?: ICollectionFilter[];

  @attr('array', { defaultValue: () => [] })
  groupBy?: string[];

  @attr('array', { defaultValue: () => [] })
  sortBy?: ICollectionSort[];

  @attr({
    defaultValue: () => {}
  })
  args?: TArgs;

  @hasMany('user-profile', { async: false })
  showForUsers!: UserProfile[];

  @hasMany('user-role', { async: false })
  showForRoles!: UserRole[];

  get isLocked(): boolean {
    return viewIsLocked(this, this.sessionUser.userId);
  }

  toCreateCmd(): ICreateCollectionViewCmd {
    return {
      name: this.name,
      entityType: this.entityType,
      description: this.description,
      collectionType: this.collectionType,
      properties: this.properties?.slice() ?? [],
      pageSize: this.pageSize,
      filters: this.filters?.slice(),
      groupBy: this.groupBy?.slice(),
      sortBy: this.sortBy?.slice(),
      args: this.args ? { ...this.args } : {},
      createdBy: this.sessionUser.userId,
      copiedFrom: this.copiedFrom
    };
  }

  toDuplicateCmd(): ICreateCollectionViewCmd {
    const cmd = this.toCreateCmd();
    return { ...cmd, name: `${cmd.name} (${this.intl.t('copySingular')})` };
  }

  toUpdateCmd(): IUpdateCollectionViewCmd {
    return {
      name: this.name,
      description: this.description,
      collectionType: this.collectionType,
      properties: this.properties?.slice(),
      pageSize: this.pageSize,
      filters: this.filters?.slice(),
      groupBy: this.groupBy?.slice(),
      sortBy: this.sortBy?.slice(),
      args: this.args ? { ...this.args } : {}
    };
  }

  toShareCmd(): IShareCollectionViewCmd {
    const byId = (m: BaseModel) => m.id;
    return {
      showForRoles: this.showForRoles.toArray
        ? this.showForRoles.toArray().map(byId)
        : this.showForRoles.map(byId),
      showForUsers: this.showForUsers.toArray
        ? this.showForUsers.toArray().map(byId)
        : this.showForUsers.map(byId),
      isTemplate: Boolean(this.isTemplate)
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'collection-view': CollectionView;
  }
}
