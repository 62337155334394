import { helpTextId, idVariation } from 'volta/utils/id';

import { guidFor } from '@ember/object/internals';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';

interface IArgs {
  text?: string;
  condensed?: boolean;
  title?: string;
  helpText?: string;
  id?: string;
}

export default class BvFormLayoutGroup extends Component<IArgs> {
  'data-test-form-layout-group' = true;

  get id() {
    return this.args.id ?? guidFor(this);
  }

  get titleId() {
    if (isBlank(this.args.title)) {
      return;
    }
    return idVariation(this.id, 'Title');
  }

  get helpTextId() {
    if (isBlank(this.args.helpText)) {
      return;
    }

    return helpTextId(this.id);
  }
}
