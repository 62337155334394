import { ref } from 'ember-ref-bucket';
import config from 'volta/config/environment';

import { action } from '@ember/object';
import Component from '@glimmer/component';

interface IArgs {
  onClick?: () => void;
}

export const BvBodyHasBackdropClassName = 'bv-body--has-backdrop';

export default class BvBackdrop extends Component<IArgs> {
  destinationElement = config.APP.rootElement ? document.querySelector(config.APP.rootElement) : document.body;
  lastMouseDownElement?: HTMLElement;
  BvBodyHasBackdropClassName = BvBodyHasBackdropClassName;

  @ref('backdropElement')
  backdropElement?: HTMLElement;

  @action
  handleMouseDown(e: MouseEvent) {
    this.lastMouseDownElement = e.target as HTMLElement;
  }

  @action
  handleMouseUp(_e: MouseEvent) {
    if (this.lastMouseDownElement === this.backdropElement) {
      this.args.onClick?.();
    }
  }
}
