import moment from 'moment-timezone';

import { action } from '@ember/object';
import Component from '@glimmer/component';

import { ICalendar } from './nav';

// @ts-ignore
import type Range from 'ember-power-calendar/components/power-calendar-range';
type TDate = Date | moment.Moment | string;
interface IArgs {
  selected?: TDate;
  center?: TDate;
  withRange?: boolean;
  onSelect?: (
    day: any,
    calendar: ICalendar,
    e: InputEvent,
    afterSelect?: (e: InputEvent) => void
  ) => void;
  afterSelect?: (e: InputEvent) => void;
}

export default class BvCalendar extends Component<IArgs> {
  get center(): Date {
    return moment(this.args.center, 'YYYY-MM-DD').toDate();
  }

  get selected(): Date | Range | undefined {
    const { selected, withRange } = this.args;
    if (!selected) {
      return undefined;
    }
    if (withRange) {
      return selected;
    }

    return moment(selected as Date, 'YYYY-MM-DD').toDate();
  }

  get nextMonthsCenter() {
    return moment(this.center).add(1, 'month');
  }

  @action
  handleSelect(day: any, calendar: any, e: InputEvent) {
    this.args.onSelect?.(day, calendar, e, this.args.afterSelect);
  }
}
