import DS from 'ember-data';
import moment from 'moment-timezone';
import { IDateFilter } from 'volta/_pods/protected/bom/controller';
import BaseModel, { IResourceName } from 'volta/models/base-model';
import { getOrElse } from 'volta/utils/object-utils';

import { tracked } from '@glimmer/tracking';

import Bom from './bom';
import SkuModel from './sku';

const { attr, belongsTo, hasMany } = DS;

const START_INTERVAL = moment('1970-01-01');
const END_INTERVAL = moment('9999-01-01');

export const ResourceName: IResourceName = {
  singular: 'bomNode',
  plural: 'bomNodes'
};

export default class BomNode extends BaseModel {
  @attr('string') bomId!: string;
  @attr('number') bomLeadTime?: number;
  @attr('number') voltaLeadTime?: number;
  @attr('number') cumulatedLeadTime?: number;
  @attr('number') level!: number;
  @attr('number') qty!: number;
  @attr('number') originalQty!: number;
  @attr('number') fixedQty?: number;
  @attr('number') pcb?: number;
  @attr('string') partUnitMeasure!: string;
  @attr('number') sequence?: number;
  @attr('boolean') onCriticalPath!: boolean;
  @attr('string') parentSkuId?: string;
  @attr('date') startDate?: Date;
  @attr('date') endDate?: Date;
  @attr('number') ltOffset?: number;

  // not async because of https://github.com/emberjs/data/issues/5023 – also see models/bom.ts
  @belongsTo('bom', { async: false, inverse: 'nodes' }) bom!: Bom;
  @belongsTo('sku', { async: false }) sku!: SkuModel;
  @belongsTo('bom-node', { async: false, inverse: 'childNodes' }) parentNode?: BomNode;
  @hasMany('bom-node', { async: false, inverse: 'parentNode' }) childNodes!: BomNode[];

  @tracked _manualChildren?: BomNode[];

  get skuId() {
    return this.sku.id;
  }

  get partCode() {
    return this.sku.part.code;
  }

  get skuDescription() {
    return this.sku.skuDescription;
  }

  // uses the originalQty if available, qty otherwise
  get displayQty() {
    return getOrElse(this.originalQty, this.qty);
  }

  // Used in BvTable tree mode
  get children(): BomNode[] {
    return this._manualChildren ?? (this.childNodes ? this.childNodes.toArray() : []) ?? [];
  }

  set children(childNodes: BomNode[] | undefined) {
    this._manualChildren = childNodes;
  }

  get hasChildren() {
    return this.children.length > 0;
  }

  get formattedLTO() {
    if (this.ltOffset) {
      return '(' +  (this.ltOffset > 0 ? '+' + this.ltOffset : + this.ltOffset) + ')'
    } else return ''
  }

  get vltDisplay() {
    return this.sku.vltDisplay + this.formattedLTO
  }

  isActiveAtDate(filter: IDateFilter): boolean {
    const { startDate: filterStartDate, endDate: filterEndDate } = filter;
    const { startDate, endDate } = this;
    if ((!startDate && !endDate) || (!filterStartDate && !filterEndDate)) {
      return true;
    }

    return !(
      moment(endDate ?? END_INTERVAL).isBefore(filterStartDate ?? START_INTERVAL) ||
      moment(startDate ?? START_INTERVAL).isAfter(filterEndDate ?? END_INTERVAL)
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'bom-node': BomNode;
  }
}
