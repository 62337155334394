import Ember from 'ember';

interface TEntry {
  groupName?: string;
  items?: TEntry[];
  item?: TEntry;
  disabled?: boolean;
  [key: string]: any;
}

type TItem = TEntry | Extract<TEntry, Ember.Object>;

export function isGroup(entry?: TItem) {
  return !!entry && !!entry.groupName && !!entry.items;
}

export function indexOfItem(
  originalCollection: TItem[] | Ember.Array<TItem> | undefined,
  item: unknown
) {
  let index = 0;
  return (function walk(collection?: TItem[] | Ember.Array<TItem>): number | undefined {
    if (!collection) {
      return undefined;
    }
    for (let i = 0; i < collection.length; i++) {
      const entry = collection.objectAt ? collection.objectAt(i) : (collection as TItem[])[i];
      if (entry && isGroup(entry as TItem)) {
        const result = walk(entry?.items);
        if (result && result > -1) {
          return result;
        }
      } else if (entry === item) {
        return index;
      } else {
        index++;
      }
    }
    return -1;
  })(originalCollection);
}
