import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const SIZES = ['sm', 'md', 'lg', 'xs', 'xl', 'xxl'];

interface Args {
  firstName?: string;
  lastName?: string;
  login?: string;
  isFocus?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  tooltip?: string;
  tooltipSide?: string;
  size?: 'sm' | 'md' | 'lg' | 'xs' | 'xl' | 'xxl';
  presence?: 'online';
  background?: string;
  img?: string;
  onClick?: (e: MouseEvent) => void;
}

export default class BvAvatar extends Component<Args> {
  // Properties
  // ~~~~~
  @tracked isFocus: boolean = this.args.isFocus ?? false;

  get initials() {
    const { firstName, lastName, login } = this.args;
    if (firstName && lastName) {
      return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
    }
    return login && login.substring(0, 2).toUpperCase();
  }

  get sizeClass() {
    const { size = 'md' } = this.args;
    return size && SIZES.includes(size) && `bv-avatar--${size}`;
  }
}
