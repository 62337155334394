import { computed } from '@ember/object';
import ComputedProperty from '@ember/object/computed';

type TCPBuilder = (idPath: string, variation: string) => ComputedProperty<string, string>;

function makeComputedIdGenerator(
  idGenerator: (id: string, variation?: string) => string
): TCPBuilder {
  return (idPath: string, variation: string): ComputedProperty<string, string> => {
    return computed(idPath, function () {
      return idGenerator(this.get(idPath), variation);
    });
  };
}

export function labelId(id: string): string {
  return idVariation(id, 'Label');
}
export const computedLabelId: TCPBuilder = makeComputedIdGenerator(labelId);

export function errorId(id: string): string {
  return idVariation(id, 'Error');
}
export const computedErrorId: TCPBuilder = makeComputedIdGenerator(errorId);

export function helpTextId(id: string): string {
  return idVariation(id, 'HelpText');
}
export const computedHelpTextId: TCPBuilder = makeComputedIdGenerator(helpTextId);

export function idVariation(id: string, suffix: string): string {
  return `${id}${suffix}`;
}
export const computedIdVariation: TCPBuilder = makeComputedIdGenerator(idVariation);
