import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';

export interface ISelectFilterDefinitionArgs {
  possibleValues: TSelectOption[];
  searchField?: string;
  renderInPlace?: boolean;
  horizontalPosition?: 'left' | 'right' | 'auto';
  valueProp?: keyof TSelectOption;
}

export default class SelectFilter extends Component<IFilterArgs<ISelectFilterDefinitionArgs>> {
  get searchField() {
    return this.args.componentArgs.searchField ?? 'key';
  }

  get selected() {
    const { componentArgs, values = [], multiple } = this.args;
    const valuesStr = values.map(String);

    if (multiple) {
      return componentArgs?.possibleValues?.filter((o) => {
        const v = this.getValue(o);
        return v && valuesStr.includes(v);
      });
    } else {
      if (values.length > 0) {
        return componentArgs?.possibleValues?.find(
          (o) => this.getValue(o) === values[0].toString()
        );
      } else {
        return undefined;
      }
    }
  }

  getValue(o: TSelectOption) {
    const v = o[this.args.componentArgs.valueProp ?? 'key'];
    return isPresent(v) ? `${v}` : undefined;
  }

  getValues(options: (TSelectOption | undefined)[]) {
    return options.reduce((acc: string[], o) => {
      const v = o && this.getValue(o);
      if (isPresent(v)) {
        acc.push(v as string);
      }
      return acc;
    }, []);
  }

  @action
  valuesDidChange(options: TSelectOption[]) {
    this.args.onChange?.(this.getValues(options));
  }

  @action
  singleValueDidChange(option?: TSelectOption) {
    this.args.onChange?.(this.getValues([option]));
  }
}
