import EmberObject, { get } from '@ember/object';

export default EmberObject.extend({
  1: 1.0,
  2: 1.0,
  3: 1.0,
  4: 1.0,
  5: 1.0,
  6: 1.0,
  7: 1.0,
  8: 1.0,
  9: 1.0,
  10: 1.0,
  11: 1.0,
  12: 1.0,

  toNative() {
    let obj = {};

    for(let i=1; i<13; i++) {
      obj[`${i}`] = get(this, `${i}`);
    }

    return obj;
  }
}).reopenClass({

  fromJson(json) {
    return this.create(json && typeof json === 'object' ? json : {});
  }
});
