import { TASK_TYPES } from 'volta/models/task';

import Component from '@glimmer/component';

import type { TTaskType, UTask } from 'volta/models/types/tasks';

interface IArgs {
  tasks: UTask[];
  taskTypes?: TTaskType[];
}

export default class TasksKitFilters extends Component<IArgs> {
  get selectedFilters() {
    if (!this.args.taskTypes) {
      return [];
    }
    return TASK_TYPES.filter((type) => this.args.taskTypes!.includes(type));
  }
}
