import { next } from '@ember/runloop';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export type TSkuDetailModalTab =
  | 'settings'
  | 'planning'
  | 'execution'
  | 'stock'
  | 'transactions'
  | 'bom'
  | 'forecasts';

interface IState {
  visible: boolean;
  tab: TSkuDetailModalTab;
  skuId?: string;
  partCode?: string;
  skuDescription?: string;
  acqCode?: string;
  sku?: any;
  options: object;
}

export default class SkuModalService extends Service {
  @tracked state: IState;
  @tracked unsavedSkuEdits: boolean = false;

  constructor() {
    super(...arguments);
    this.state = {
      visible: false,
      tab: 'settings',
      sku: undefined,
      skuId: undefined,
      partCode: undefined,
      skuDescription: undefined,
      acqCode: undefined,
      options: {}
    };
  }

  show(
    tab: TSkuDetailModalTab = 'settings',
    skuId?: string,
    partCode?: string,
    skuDescription?: string,
    acqCode?: string,
    options: object = {}
  ) {
    this.state = {
      visible: true,
      tab,
      skuId,
      partCode,
      skuDescription,
      acqCode,
      sku: undefined,
      options
    };
  }

  showWithSku(tab: TSkuDetailModalTab = 'settings', sku: any, options: object = {}) {
    const { skuId, partCode, skuDescription, acqCode } = sku ?? {};
    this.state = {
      visible: true,
      tab,
      skuId,
      partCode,
      skuDescription,
      acqCode,
      sku,
      options
    };
  }

  hide() {
    this.state = {
      visible: false,
      tab: 'settings',
      skuId: undefined,
      sku: undefined,
      partCode: undefined,
      skuDescription: undefined,
      acqCode: undefined,
      options: {}
    };
  }

  switchTab(tab: TSkuDetailModalTab) {
    if (tab) {
      next(this, () => {
        this.state = { ...this.state, tab };
      });
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'sku-modal-service': SkuModalService;
  }
}
