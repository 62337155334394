import { isPresent } from '@ember/utils';

/**
 * Sum an array of values
 *
 * @param {Array} values An array of values to sum
 * @return {number} A summed value
 */
export function sum(values: number[]) {
  return values.reduce((agg, value) => {
    if (Number.isNaN(Number(value))) {
      return agg;
    }
    return agg + (+value ?? 0);
  }, 0);
}

/**
 * Round a value to the nearest x decimals (default to 2)
 *
 * @param {number} value The value to round
 * @param {number} decimals The number of decimals to round to
 * @return {number} A rounded value
 */
export function round(value?: number | null, decimals: number = 2) {
  if (value === null || value === undefined || isNaN(value)) {
    return undefined;
  }
  const rounding = Math.pow(10, decimals);
  return Math.round(value * rounding) / rounding;
}

/**
 * Check if a value is NaN
 *
 * @param value The value to check
 * @return true if the value is NaN
 */
export function isNaN(value: any) {
  return typeof value === 'number' && Number.isNaN(Number(value));
}

export function parseNumber(value?: any): number | undefined {
  // If value is an array starting with a number
  if (value && typeof value !== 'string' && value.length) {
    return undefined;
  }
  const parsedToFloat = parseFloat(`${value}`);
  return isNaN(parsedToFloat) ? undefined : parsedToFloat;
}

export function isNumber(value: any) {
  if (!isPresent(value)) {
    return false;
  }
  const nb = Number(`${value}`);
  return Number.isNaN(nb) ? false : typeof nb === 'number';
}

/**
 * Clamps the given number between min and max values. Returns value if within
 * range, or closest bound.
 */
export function clamp(val: number | null | undefined, min: number, max: number) {
  if (val == null || typeof val === 'undefined') {
    return val;
  }
  if (max < min) {
    throw new Error('[Bevolta] clamp: max cannot be less than min');
  }
  return Math.min(Math.max(val, min), max);
}

export function toPercent(percent: number, total: number, apiCompliant: boolean = true) {
  return apiCompliant ? +(percent / total).toFixed(2) : Math.round((percent / total) * 100);
}

export function average(arr: number[], rounded: boolean = false) {
  const avr = arr.reduce((p, c) => p + c, 0) / arr.length;
  return rounded ? round(avr) : avr;
}

export function mod(dividend: number, divisor: number) {
  return dividend - divisor * Math.floor(dividend / divisor)
}
