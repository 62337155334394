import BaseModel from 'volta/models/base-model';
import { _normalize, collectionCommand, resourceCommand as command } from 'volta/utils/api';
import { HookClass } from 'volta/utils/api/types';

import { attr } from '@ember-data/model';

export const PoValidationRuleResourceName = {
  singular: 'poValidationRule',
  plural: 'poValidationRules'
};

export enum PoValidationRuleCommands {
  CreatePOValidationRule = 'CreatePOValidationRule',
  UpdatePOValidationRule = 'UpdatePOValidationRule',
  DeletePOValidationRule = 'DeletePOValidationRule',
  ReorderPOValidationRules = 'ReorderPOValidationRules'
}

export interface ValidationRuleCommand {
  name?: string;
  ruleOrder?: number;
  warehouseIds?: string[];
  skuIds?: string[];
  supplierIds?: string[];
  workshopIds?: string[];
  skuCf?: Record<string, unknown>;
  supplierCf?: Record<string, unknown>;
  workshopCf?: Record<string, unknown>;
  daysBefore?: number;
  daysAfter?: number;
  isActive?: boolean;
}

export default class PoValidationRule extends BaseModel {
  static modelName = 'po-validation-rule';

  @attr('string') name!: string;
  @attr('number') ruleOrder!: number;
  @attr('array', { defaultValue: () => [] }) warehouseIds!: string[];
  @attr('array', { defaultValue: () => [] }) skuIds!: string[];
  @attr('array', { defaultValue: () => [] }) supplierIds!: string[];
  @attr('array', { defaultValue: () => [] }) workshopIds!: string[];
  @attr({ defaultValue: () => {} }) skuCf!: Record<string, unknown>;
  @attr({ defaultValue: () => {} }) supplierCf!: Record<string, unknown>;
  @attr({ defaultValue: () => {} }) workshopCf!: Record<string, unknown>;
  @attr('number') daysBefore!: number;
  @attr('number') daysAfter!: number;
  @attr('boolean', { defaultValue: () => true }) isActive!: boolean;

  static createPoValidationRule = collectionCommand<ValidationRuleCommand, PoValidationRule>(
    PoValidationRuleCommands.CreatePOValidationRule,
    {
      after: _normalize as HookClass<any, PoValidationRule, typeof PoValidationRule>
    }
  );

  static reorderPoValidationRules = collectionCommand<{ ids: string[] }, PoValidationRule[]>(
    PoValidationRuleCommands.ReorderPOValidationRules,
    {
      after: _normalize as HookClass<any, PoValidationRule[], typeof PoValidationRule>
    }
  );

  updatePoValidationRule = command(PoValidationRuleCommands.UpdatePOValidationRule);
  deletePoValidationRule = command(PoValidationRuleCommands.DeletePOValidationRule);

  toCmd() {
    return {
      name: this.name,
      ruleOrder: this.ruleOrder,
      warehouseIds: this.warehouseIds,
      skuIds: this.skuIds,
      supplierIds: this.supplierIds,
      workshopIds: this.workshopIds,
      skuCf: this.skuCf,
      supplierCf: this.supplierCf,
      workshopCf: this.workshopCf,
      daysBefore: this.daysBefore,
      daysAfter: this.daysAfter,
      isActive: this.isActive
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    PoValidationRule: PoValidationRule;
  }
}
